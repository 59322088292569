import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import {Grid, FormLabel, Typography} from '@material-ui/core'
import Bundle from '../../../../../models/Bundle'
import Project, { PROJECT_ERROR } from '../../../../../models/Project'
import ProjectEnvironment from '../../../../../models/ProjectEnvironment'
import DeploymentEnvironment from '../../../../../models/DeploymentEnvironment'
import ActionTypes from '../../../../../store/actions'
import IStoreState from '../../../../../store/IStoreState'
import MessageService from '../../../../../services/MessageService'
import { SectionLayout } from '../../shared'
import ProjectEnvironmentLinksCard from '../code/project-environment-links-card.component'
import '../../../shared/project.scss'

export interface IProjectEditLinksProps {
  handleInputChange?: (event: any) => void
  project?: Project
  deploymentEnvironments?: DeploymentEnvironment[]
  error?: PROJECT_ERROR
  loading?: boolean
  bundle?: Bundle
}

export interface IProjectEditLinksState {}

export class ProjectEditLinks extends React.Component<IProjectEditLinksProps, IProjectEditLinksState> {
  handleProjectEnvironmentUpdate = (updatedEnv: ProjectEnvironment): void => {
    this.props.handleInputChange({
      target: {
        name: 'projectEnvironments',
        value: this.props.project.projectEnvironments.map((projEnv: ProjectEnvironment) => {
          if (projEnv.environmentId === updatedEnv.environmentId) {
            projEnv = updatedEnv
            return projEnv
          }
          return projEnv
        }),
      }
    })
  }

  render(): React.ReactNode {
    const { project, loading, error } = this.props
    return (
      <SectionLayout>
        <Grid container spacing={4} justify="center">
          <Grid item xs={12}>
            <Typography variant="body1">{MessageService.get('project.wizard.step6.details')}</Typography>
          </Grid>
          <Grid item xs={11} className={'project-link'}>
            <Grid container spacing={2} justify="center">
              {project.projectEnvironments
                .sort((env1: ProjectEnvironment, env2: ProjectEnvironment) => env2.order - env1.order)
                .map((env: ProjectEnvironment, i: number) => {
                  return (
                    <Grid item xs={12} key={i}>
                      <ProjectEnvironmentLinksCard
                        key={i}
                        projectEnvironment={env}
                        loading={loading}
                        error={error}
                        handleProjectEnvironmentUpdate={this.handleProjectEnvironmentUpdate}
                        className={i < project.projectEnvironments.length -1 ? 'project-card-step' : ''}
                      />
                    </Grid>
                  )
                })}
            </Grid>
          </Grid>
        </Grid>
      </SectionLayout>
    )
  }
}

const mapStateToProps: any = (state: IStoreState): any => ({
  project: state.selectedProject.project,
  error: state.selectedProject.error,
  loading: state.selectedProject.loading,
  bundle: state.bundle.data,
})

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  handleInputChange: (event: any): void => {
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
      payload: {
        key: event.target.name,
        value: event.target.value,
      },
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditLinks)
