import React from 'react'
import {FormControl, InputLabel, FormHelperText, FormLabel} from '@material-ui/core'
import MessageService from '../../../../services/MessageService'

interface UnderlinedFormInputProps {
  label?: string
  message?: string
  error?: boolean
}

export const UnderlinedInput: React.FC<UnderlinedFormInputProps> = ({ label, message, error = false, children }) => {
  return (
    <>
      {label && <InputLabel>{MessageService.get(label)}<FormLabel style={{ color: 'red' ,marginLeft: '5px'}}>*</FormLabel></InputLabel>}
      <FormControl error={error} fullWidth>
        {children}
        {message && <FormHelperText />}
      </FormControl>
    </>
  )
}
