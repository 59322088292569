import React from 'react'
import Pipeline from 'src/models/pipeline/Pipeline'
import STATUS from 'src/models/pipeline/Status'
import LatestPipelineRunTimeRunningInfo from './LatestPipelineRunTimeRunningInfo'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStopwatch} from '@fortawesome/free-solid-svg-icons'
import Nbsp from 'src/components/common/Nbsp'

export interface ILatestPipelineRunTimeInfoProps {
  latestPipeline: Pipeline,
}

export class LatestPipelineRunTimeInfo extends React.Component<ILatestPipelineRunTimeInfoProps> {
  getDuration(latestPipeline: Pipeline): React.ReactNode {
    return (
      <>
        <FontAwesomeIcon icon={faStopwatch} size='xs'/><Nbsp/>
        {latestPipeline.duration}
      </>
    )
  }

  render(): React.ReactNode {
    const {latestPipeline} = this.props

    const running: boolean = [STATUS.PENDING, STATUS.STARTED, STATUS.CANCELLING].includes(latestPipeline.status)

    return running ? <LatestPipelineRunTimeRunningInfo latestPipeline={latestPipeline}/> : this.getDuration(latestPipeline)
  }
}

export default LatestPipelineRunTimeInfo
