import 'reflect-metadata'
import {assign} from 'lodash'

export enum PIPELINE_TRIGGER_TYPE {
  ACTION_TYPE = 'ACTION_TYPE',
  SOURCE_ENV = 'SOURCE_ENV',
  TARGET_ENV = 'TARGET_ENV'
}


export class PipelineTrigger {
  type: PIPELINE_TRIGGER_TYPE
  value: string

  constructor(props?: Partial<PipelineTrigger>) {
    props = props || {}

    const defaultProps: Partial<PipelineTrigger> = {
      type: undefined,
      value: undefined
    }
    assign(this, defaultProps, props)
  }
}

export default PipelineTrigger
