import React from 'react'
import { FormStep } from './form-step.component'
import { Grid } from '@material-ui/core'
import { FormStepConfig, FORM_STEP_TYPE } from '../forms'

export interface FormStepsProps {
  formSteps: FormStepConfig[]
  activeStep: FORM_STEP_TYPE
}

export const FormSteps: React.FC<FormStepsProps> = ({ formSteps, activeStep }) => {
  const renderSteps = (steps: FormStepConfig[]) => {
    return steps.map((step, i) => {
      const isActive = step.step === activeStep
      const stepNumber = i + 1
      const stepLabel = `${stepNumber}. ${step.name}`
      return <FormStep stepLabel={stepLabel} isActive={isActive} key={i} />
    })
  }
  return (
    <Grid container item spacing={3}>
      {renderSteps(formSteps)}
    </Grid>
  )
}
