import React from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@material-ui/core/Snackbar'
import Fade from '@material-ui/core/Fade'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import SnackbarService, {SNACKBAR_TYPES} from '../services/SnackbarService'

class GenericSnackbar extends React.Component {
  static AUTO_HIDE_DURATION = 5000

  onClose = () => {
    SnackbarService.hide()
  }

  render() {
    const {open, message, type} = this.props
    const duration = type !== SNACKBAR_TYPES.FAILURE ? GenericSnackbar.AUTO_HIDE_DURATION : null
    const icon = type !== SNACKBAR_TYPES.FAILURE ? faCheckCircle : faTimesCircle
    const iconColorStyle = type !== SNACKBAR_TYPES.FAILURE ? 'green' : 'red'

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={duration}
        onClose={this.onClose}
        TransitionComponent={Fade}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span id="message-id">
            <FontAwesomeIcon icon={icon} style={{color: iconColorStyle}}/> {message}
          </span>
        }
      />
    )
  }
}

GenericSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf([SNACKBAR_TYPES.SUCCESS, SNACKBAR_TYPES.FAILURE]).isRequired,
}

export default GenericSnackbar
