import React from 'react'
import PropTypes from 'prop-types'
import GenericTable, {sortDirections} from '../../common/GenericTable'
import DeploymentTargetsTableRow from './DeploymentTargetsTableRow'
import DeploymentTarget from '../../../models/DeploymentTarget'

export interface IDeploymentTargetsTableProps {
  deploymentTargets: DeploymentTarget[],
  handleDeploymentTargetRowClick: any,
}

export interface IDeploymentTargetsTableState {}

export class DeploymentTargetsTable extends React.Component<IDeploymentTargetsTableProps, IDeploymentTargetsTableState> {

  static propTypes: any = {
    deploymentTargets: PropTypes.arrayOf(PropTypes.instanceOf(DeploymentTarget)).isRequired,
    handleDeploymentTargetRowClick: PropTypes.func.isRequired
  }

  columns:any[] = [
    {id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true},
    {id: 'fqdn', numeric: false, disablePadding: false, label: 'Hostname', sortable: true},
    {id: 'remoteUser', numeric: false, disablePadding: false, label: 'Connection URI', sortable: true},
    {id: 'escalationUser', numeric: false, disablePadding: false, label: 'Escalation', sortable: true},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status', sortable: true},
    {id: 'actions', numeric: false, disablePadding: true, label: '', sortable: false},
  ]

  render():React.ReactNode {
    return (
      <GenericTable
        rowComponent={DeploymentTargetsTableRow}
        items={this.props.deploymentTargets}
        columns={this.columns}
        handleRowClick={this.props.handleDeploymentTargetRowClick}
        order={sortDirections.ASC}
        orderBy='id'
        comparator={null}
        componentField={null}/>
    )
  }
}

export default DeploymentTargetsTable
