import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InitialDeploymentButtonConfig, InitialDeploymentNotificationConfig } from '../project-details.config'
import { SectionLayout } from './section.layout'
import { useStyles, resolveIcon } from './initial-deployment-notification.config'

export interface InitialDeploymentNotificationProps {
    deployConfig: InitialDeploymentButtonConfig
    config: InitialDeploymentNotificationConfig
}

export const InitialDeploymentNotification: React.FC<InitialDeploymentNotificationProps> = ({ deployConfig, config }) => {
    const styles = useStyles()
    const renderDeployButton = () => {
        if (deployConfig.showButton) {
            return (
                <Button
                    onClick={deployConfig.onClick}
                    disabled={deployConfig.isDisabled}
                    variant="contained"
                    color="primary"
                    disableElevation
                >
                    {deployConfig.message}
                </Button>
            )
        }
        return null
    }

    const resolveStyle = (config: InitialDeploymentNotificationConfig) => {
        return config.isError ? styles.deployError : styles.deployInfo
    }

    const icon = resolveIcon(config)
    const messageStyle = resolveStyle(config)
    return (
        <SectionLayout>
            <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap" spacing={2}>
                <Grid item xs>
                    <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <FontAwesomeIcon size="lg" icon={icon} className={messageStyle} />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" className={messageStyle}>
                                {config.message}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>{renderDeployButton()}</Grid>
            </Grid>
        </SectionLayout>
    )
}
