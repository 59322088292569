import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {FormLabel, Grid, MenuItem, Select, Tooltip, TextField, Typography} from '@material-ui/core'
import FormField from '../../../../common/FormField'
import Project, { PROJECT_ERROR, PROJECT_TYPE } from '../../../../../models/Project'
import ActionTypes from '../../../../../store/actions'
import IStoreState from '../../../../../store/IStoreState'
import ProjectType from '../../../../../models/ProjectType'
import MessageService from '../../../../../services/MessageService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { SectionLayout } from '../../shared'
import '../../../shared/project.scss'

export interface IProjectEditDetailsProps {
  handleInputChange?: (event: any) => void
  handleTypeChange?: (event: any) => void
  project?: Project
  projectTypes?: ProjectType[]
  error?: PROJECT_ERROR
  loading?: boolean
}

export class ProjectEditDetails extends React.Component<IProjectEditDetailsProps> {
  render(): React.ReactNode {
    const { project, projectTypes, error, loading, handleInputChange } = this.props

    const showDomain: boolean = project.type !== PROJECT_TYPE.WORKFLOW

    return (
      <SectionLayout>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12}>
            <Typography variant="body1">{MessageService.get('project.wizard.step1.details')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="Name"
              error={error === PROJECT_ERROR.PROJECT_EXISTS}
              mandatory={true}
              message={
                error === PROJECT_ERROR.PROJECT_EXISTS &&
                MessageService.get('project.wizard.error.details.name.duplicate')
              }
            >
              <TextField
                name="name"
                disabled={loading}
                style={{ marginTop: '50px', marginBottom: '10px' }}
                value={project.name}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormField>
          </Grid>
          {showDomain && (
            <Grid item xs={12}>
              <Grid container>
                <FormLabel style={{ marginRight: '3px' }}>Domain</FormLabel>
                <FormLabel style={{ color: 'red' ,marginRight: '10px'}}>*</FormLabel>
                <Tooltip
                  title={MessageService.get('project.wizard.details.domain.tooltip')}
                  placement="top"
                  enterDelay={50}
                >
                  <div>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </div>
                </Tooltip>
              </Grid>
              <FormField
                error={error === PROJECT_ERROR.DOMAIN_CONTAINS_PROTOCOL}
                message={
                  error === PROJECT_ERROR.DOMAIN_CONTAINS_PROTOCOL &&
                  MessageService.get('project.wizard.error.details.domain.contains.protocol')
                }
              >
                <TextField
                  name="domain"
                  disabled={loading}
                  value={project.domain}
                  onChange={handleInputChange}
                  style={{ marginTop: '8px', marginBottom: '10px' }}
                  variant="outlined"
                />
              </FormField>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid item xs={12}>
              <FormLabel>Type</FormLabel>
            </Grid>
            <FormField>
              <Select
                variant="outlined"
                disabled={true}
                value={project.type}
                inputProps={{ name: 'type' }}
                style={{ marginTop: '8px', marginBottom: '10px' }}
              >
                {projectTypes.map((projectType: ProjectType) => {
                  return (
                    <MenuItem key={projectType.value} value={projectType.value}>
                      {projectType.displayName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormField>
          </Grid>
        </Grid>
      </SectionLayout>
    )
  }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IProjectEditDetailsProps): any => ({
  project: state.selectedProject.project,
  projectTypes: state.projectTypes.data,
  error: state.selectedProject.error,
  loading: state.selectedProject.loading,
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IProjectEditDetailsProps): any => ({
  handleInputChange: (event: any): void => {
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
      payload: {
        key: event.target.name,
        value: event.target.value,
      },
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditDetails)
