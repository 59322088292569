import React from 'react'

import PropTypes from 'prop-types'
import RoleSelectionRow from './RoleSelectionRow'
import ProjectNameHelper from '../../../helpers/ProjectHelper'
import EditUserTable from "./EditUserTable";

const sortDirections = {
  ASC: 'asc',
  DESC: 'desc',
}

class RoleSelectionMatrix extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order: sortDirections.ASC,
      orderBy: 'projectName',
    }
  }

  columnData = [
    {id: 'projectName', numeric: false, disablePadding: true, label: '', selectAll: false, global: false},
    {id: 'admin', numeric: false, disablePadding: true, label: 'Admin', groupId: 'GLOBAL_ADMINISTRATOR_GROUP', selectAll: true, global: true},
    {id: 'manager', numeric: false, disablePadding: true, label: 'Manager', groupId: 'GLOBAL_MANAGER_GROUP', selectAll: true, global: true},
    {id: 'developer', numeric: false, disablePadding: true, label: 'Developer', groupId: 'GLOBAL_DEVELOPER_GROUP', selectAll: true, global: true},
    {id: 'deployer', numeric: false, disablePadding: true, label: 'Deployer', groupId: 'GLOBAL_DEPLOYER_GROUP', selectAll: true, global: true},
    {id: 'observer', numeric: false, disablePadding: true, label: 'Observer', groupId: 'GLOBAL_OBSERVER_GROUP', selectAll: true, global: true},
  ]

  render() {
    const rolesByProjectAsArray = Object.keys(this.props.rolesByProject)
      .map((projectId) => {
        return {
          projectId,
          projectName: ProjectNameHelper.getProjectNameById(projectId),
          projectRoles: this.props.rolesByProject[projectId],
        }
      })
    if (this.props.rolesByProject) {
      return (
        <EditUserTable items={rolesByProjectAsArray} rowComponent={RoleSelectionRow}
                       handleUserRoleGroupChange={this.props.handleUserRoleGroupChange}
                       handleUserSelectAllChange={this.props.handleUserSelectAllChange}
                       userRoleGroups={this.props.userRoleGroups}
          columns={this.columnData} order={this.state.order} orderBy={this.state.orderBy}
          headerProps= {{
            style: {fontSize: '10pt'},
          }}
          rowProps={{
            handleCheckboxChange: this.props.handleCheckboxChange,
          }}/>
      )
    } else {
      return <div>Loading...</div>
    }
  }
}

RoleSelectionMatrix.propTypes = {
  handleCheckboxChange: PropTypes.func.isRequired,
  rolesByProject: PropTypes.object.isRequired,
}

export default RoleSelectionMatrix
