import React from 'react'
import { useEffect } from 'react'
import { Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import { ConnectedSelectedEnvironmentsList, ConnectedUnselectedEnvironmentsList } from '../shared'
import { useStyles } from './workflow-environments.config'
import { FORM_STEP_TYPE } from '../forms'
import { createProjectFormSetIsSaveDisabled } from 'src/store/create-project-form/create-project-form.actions'
import DeploymentEnvironmentHelper from "../../../../helpers/DeploymentEnvironmentHelper";
import IStoreState from "../../../../store/IStoreState";

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {
  setActiveStep: (step: FORM_STEP_TYPE) => void
}
export type WorkflowEnvironmentsFormProps = ComponentProps & PropsFromRedux

export const WorkflowEnvironmentsForm: React.FC<WorkflowEnvironmentsFormProps> = ({ project, setActiveStep, setIsSaveDisabled }) => {
  useEffect(() => {
    const hasNonactiveEnv = DeploymentEnvironmentHelper.containsNonActiveEnvironments(project)
    setIsSaveDisabled(hasNonactiveEnv)
    return
  }, [])

  const spacing = 3
  return (
    <Grid container item direction="column" spacing={spacing}>
      <SelectedList />
      <UnselectedList />
    </Grid>
  )
}

interface SelectedListProps {}

const SelectedList: React.FC<SelectedListProps> = () => {
  const styles = useStyles()
  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1">
          Add, remove, or reorder your environments. The development and production environments are required.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={styles.titleText}>
          Selected Environments
        </Typography>
      </Grid>
      <Grid item>
        <ConnectedSelectedEnvironmentsList />
      </Grid>
    </Grid>
  )
}

interface UnselectedListProps {}

const UnselectedList: React.FC<UnselectedListProps> = () => {
  const styles = useStyles()
  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1" className={styles.titleText}>
          Deselected Environment
        </Typography>
      </Grid>
      <Grid item>
        <ConnectedUnselectedEnvironmentsList />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state: IStoreState, ownProps: ComponentProps) => ({
  project: state.createProjectData.project,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsSaveDisabled: (value: boolean) => {
    dispatch(createProjectFormSetIsSaveDisabled(value))
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedWorkflowEnvironmentsForm = connector(WorkflowEnvironmentsForm)