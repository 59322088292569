import {IPipelineTemplatesState} from '../IStoreState'
import initialState from '../initialState'
import ActionTypes from '../actions'

export default function pipelineTemplatesReducer(state: IPipelineTemplatesState = initialState.pipelineTemplates, action: any): IPipelineTemplatesState {
  switch (action.type) {
    case ActionTypes.PIPELINE_TEMPLATES_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ActionTypes.PIPELINE_TEMPLATES_LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.pipelineTemplates,
        loading: false,
        error: null
      }
    case ActionTypes.PIPELINE_TEMPLATES_LOAD_FAIL:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: true
      }
    case ActionTypes.PIPELINE_TEMPLATE_SAVE:
      return {
        ...state,
        saving: true,
        error: null
      }
    case ActionTypes.PIPELINE_TEMPLATE_SAVE_SUCCESS:
      return {
        ...state,
        data: undefined,
        saving: false
      }
    case ActionTypes.PIPELINE_TEMPLATE_SAVE_FAIL:
      return {
        ...state,
        data: undefined,
        saving: false,
        error: true
      }
    case ActionTypes.PIPELINE_TEMPLATE_DELETE:
      return {
        ...state,
        data: undefined,
        deleting: true
      }
    case ActionTypes.PIPELINE_TEMPLATE_DELETE_SUCCESS:
      return {
        ...state,
        data: undefined,
        deleting: false
      }
    case ActionTypes.PIPELINE_TEMPLATE_DELETE_FAIL:
      return {
        ...state,
        data: undefined,
        deleting: false,
        error: true
      }
    case ActionTypes.PIPELINE_TEMPLATE_CLEAR:
      return {
        ...state,
        data: undefined,
        deleting: false,
        error: false
      }
    default:
      return state
  }
}
