import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const defaultEnvironmentNonActiveMessage = 'Environment add disabled due to missing deployment targets.'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[300],
      padding: theme.spacing(2),
      borderRadius: '4px',
      borderColor: theme.palette.grey[500],
      borderWidth: '1px',
      borderStyle: 'solid',
      cursor:'pointer'
    },
    icon: {
      color: theme.palette.grey[500],
    },
    titleText: {},
    errorText: {
      color: 'darkred',
    }
  }),
)
