import React from 'react'
import Moment from 'react-moment'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock, faUser} from '@fortawesome/free-solid-svg-icons'
import Pipeline from 'src/models/pipeline/Pipeline'
import Nbsp from 'src/components/common/Nbsp'
import LatestPipelineRunTimeInfo from './LatestPipelineRunTimeInfo'

export interface ILatestPipelineRunInfoProps {
  latestPipeline: Pipeline,
}

export class LatestPipelineRunInfo extends React.Component<ILatestPipelineRunInfoProps> {
  render(): React.ReactNode {
    const {latestPipeline} = this.props

    if (!latestPipeline) {
      return 'Never'
    }

    return (
      <>
        <FontAwesomeIcon icon={faClock} className='xsIcon' size='xs'/><Nbsp/>
        <Moment format='M/D/YY h:mma' date={latestPipeline.startTime}/>
        <br/>
        <LatestPipelineRunTimeInfo latestPipeline={latestPipeline}/>
        <br/>
        <FontAwesomeIcon icon={faUser} className='xsIcon' size='xs'/> {latestPipeline.createdBy}
      </>
    )
  }
}

export default LatestPipelineRunInfo
