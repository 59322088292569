import React from 'react'
import Paper from '@material-ui/core/Paper'
import { useStyles } from './form-layout.config'
import { Box } from '@material-ui/core'

export const FormLayout: React.FC = ({ children }) => {
  const styles = useStyles()
  return (
    <Paper className={styles.root} elevation={2} variant="elevation">
      <Box display="flex" flexDirection="column" className={styles.layout} style={{ clear: 'both' }}>
        {children}
      </Box>
    </Paper>
  )
}
