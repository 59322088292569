import {assign} from 'lodash'
import {PROJECT_TYPE} from './Project'

export class DefaultAssetConfig {
  title:string
  assetPathSuffix:string
  constructor(title: string, assetPathSuffix: string) {
    this.title = title
    this.assetPathSuffix = assetPathSuffix
  }
}

export default class ProjectType {
  value: PROJECT_TYPE
  displayName: string
  defaultImportRepoUrl: string
  defaultImportBranchOrTag: string
  defaultAssetConfigurations: DefaultAssetConfig[]

  constructor(props?: Partial<ProjectType>) {
    props = props || {}
    const defaultProps: Partial<ProjectType> = {
      value: undefined,
      displayName: undefined,
      defaultImportRepoUrl: undefined,
      defaultImportBranchOrTag: undefined,
      defaultAssetConfigurations: undefined
    }

    assign(this, defaultProps, props)
  }
}
