import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {Checkbox, Divider, TextField, FormControlLabel, FormLabel, Grid, Paper, Typography} from '@material-ui/core'
import IStoreState from '../../../../../store/IStoreState'
import ActionTypes from '../../../../../store/actions'
import Bundle from '../../../../../models/Bundle'
import Branch from '../../../../../models/Branch'
import { WEB_SERVER } from '../../../../../models/WEB_SERVER'
import CodeRepository from '../../../../../models/CodeRepository'
import Project, { PROJECT_ERROR } from '../../../../../models/Project'
import ProjectEnvironment from '../../../../../models/ProjectEnvironment'
import DeploymentEnvironment from '../../../../../models/DeploymentEnvironment'
import ProjectEnvironmentCode from '../../../../../models/ProjectEnvironmentCode'
import DeploymentTargetGroup from '../../../../../models/DeploymentTargetGroup'
import { IMPORT_REPOSITORY_STATUS } from '../../../../../models/ImportRepository'
import ProjectEnvironmentCodeWebroot from '../../../../../models/ProjectEnvironmentCodeWebroot'
import ProjectHelper from '../../../../../helpers/ProjectHelper'
import DeploymentEnvironmentHelper from '../../../../../helpers/DeploymentEnvironmentHelper'
import MessageService from '../../../../../services/MessageService'
import {closeBranchConfirmation} from '../../../../../store/actions/selectedProjectActions'
import FormField from '../../../../common/FormField'
import ConfirmDialog from '../../../../common/ConfirmDialog'
import { SectionLayout } from '../../shared'
import ImportRepositoryWidget from './import-repository-widget.component'
import ProjectEnvironmentCodeCard from './project-environment-code-card.component'
import '../../../shared/project.scss'

export interface IProjectEditCodeProps {
  handleInputChange?: (event: any) => void
  handleSaveProject?: (confirmAction: () => any) => void
  handleCloseBranchConfirmation?: () => void
  project?: Project
  deploymentEnvironments?: DeploymentEnvironment[]
  error?: PROJECT_ERROR
  loading?: boolean
  bundle?: Bundle
  branchConfirmation?: boolean
  confirmActionCreator?: () => any
}

export class ProjectEditCode extends React.Component<IProjectEditCodeProps> {
  storedCodeRepository: CodeRepository
  storedProjectEnvironments: ProjectEnvironment[]
  webroots: Map<string, ProjectEnvironmentCodeWebroot> = new Map<string, ProjectEnvironmentCodeWebroot>()
  disableImportFields: boolean = false
  importError: boolean = false
  isRepoInit: boolean = false
  private readonly featureBranchConfirmation: string

  constructor(props: IProjectEditCodeProps) {
    super(props)
    this.featureBranchConfirmation = MessageService.get('project.wizard.code.feature.branch')
  }

  componentWillMount() {
    const { project } = this.props
    this.initializeWebroots()
    if (project?.hasCode) {
      if (!project.codeRepository) {
        this.initializeCodeRepository()
      } else if (project.codeRepository.importRepository && project.codeRepository.importRepository.status) {
        if (project.codeRepository.importRepository.status === IMPORT_REPOSITORY_STATUS.FAILURE) {
          this.importError = true
        } else {
          this.disableImportFields = true
        }
      } else {
        this.isRepoInit = true
      }
      this.initializeProjectEnvironmentsCode()
    }
  }

  initializeWebroots = (): void => {
    const projectEnvironments = this.props.project?.projectEnvironments || []
    projectEnvironments.forEach((projEnv: ProjectEnvironment) => {
      if (projEnv.projectEnvironmentCode && projEnv.projectEnvironmentCode.webroot) {
        const webrootArr: string[] = projEnv.projectEnvironmentCode.webroot.split('/')
        webrootArr.splice(0, 1) // remove first index to account for leading '/'
        let abDirIndex: number = webrootArr.indexOf('ab')
        if (abDirIndex < 0) {
          abDirIndex = webrootArr.indexOf('cascade')
        }
        const dir1: string = webrootArr
          .slice(0, abDirIndex)
          .reduce((acc: string, dir: string) => (acc === '' ? dir : `${acc}/${dir}`), '')
        const dir2: string = webrootArr
          .slice(abDirIndex + 3, webrootArr.length)
          .reduce((acc: string, dir: string) => (acc === '' ? dir : `${acc}/${dir}`), '')
        this.webroots.set(
          projEnv.environmentId,
          new ProjectEnvironmentCodeWebroot({
            dir1,
            cascade: webrootArr[abDirIndex],
            env: webrootArr[abDirIndex + 1],
            site: webrootArr[abDirIndex + 2],
            dir2,
          }),
        )
      } else {
        this.webroots.set(
          projEnv.environmentId,
          ProjectHelper.getDefaultWebrootForEnvironment(this.cleanedProjectName(), projEnv.environmentId),
        )
      }
    })
  }

  initializeProjectEnvironmentsCode = (): void => {
    const projectEnvironments = this.props.project?.projectEnvironments || []
    const finishedProjectEnvironments: ProjectEnvironment[] = projectEnvironments.filter(
      (projEnv: ProjectEnvironment) => projEnv.projectEnvironmentCode,
    )
    if (finishedProjectEnvironments.length !== projectEnvironments.length) {
      const updatedProjectEnvironments: ProjectEnvironment[] = projectEnvironments
        .filter((projEnv: ProjectEnvironment) => !projEnv.projectEnvironmentCode)
        .map(
          (projEnv: ProjectEnvironment, index: number): ProjectEnvironment => {
            const defaultDeploymentTargetGroup: DeploymentTargetGroup =
              DeploymentEnvironmentHelper.getDeploymentEnvironmentById(
                projEnv.environmentId,
              ).deploymentTargetGroups.find((group: DeploymentTargetGroup) =>
                group.name.toLowerCase().includes('code'),
              ) ||
              DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projEnv.environmentId).deploymentTargetGroups[0]
            return {
              ...projEnv,
              projectEnvironmentCode: new ProjectEnvironmentCode({
                branch: new Branch({
                  name: 'master',
                  guarded: !DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projEnv.environmentId).isDev,
                }),
                deploymentTargetGroupId: defaultDeploymentTargetGroup.id,
                webroot: this.getFullWebroot(projEnv.environmentId),
                webServer: WEB_SERVER.APACHE_HTTPD,
              }),
            }
          },
        )
        .concat(finishedProjectEnvironments)
        .sort((a: ProjectEnvironment, b: ProjectEnvironment) => a.order - b.order)
      this.updateProjectEnvironments(updatedProjectEnvironments)
    }
  }

  initializeCodeRepository = (): void => {
    this.props.handleInputChange({
      target: {
        name: 'codeRepository',
        value: new CodeRepository({
          gitHost: this.props.bundle.gitHost,
          group: this.cleanedProjectName(),
          name: this.cleanedProjectName(),
        }),
      },
    })
  }

  cleanedProjectName = (): string => {
    const projectName = this.props.project?.name
    if (projectName) {
      return ProjectHelper.getCleanedProjectName(this.props.project.name)
    }
    return 'default'
  }

  toggleNoCode = (): void => {
    if (this.props.project.hasCode) {
      this.storedCodeRepository = this.props.project.codeRepository
      this.storedProjectEnvironments = this.props.project.projectEnvironments.map((env: ProjectEnvironment) => env)
      this.props.handleInputChange({
        target: {
          name: 'hasCode',
          value: false,
        },
      })
      this.props.handleInputChange({
        target: {
          name: 'projectEnvironments',
          value: this.props.project.projectEnvironments.map((env: ProjectEnvironment) => {
            return { ...env, projectEnvironmentCode: null }
          }),
        },
      })
      this.props.handleInputChange({
        target: {
          name: 'codeRepository',
          value: null,
        },
      })
    } else {
      this.props.handleInputChange({
        target: {
          name: 'hasCode',
          value: true,
        },
      })
      if (!!this.storedProjectEnvironments) {
        this.props.handleInputChange({
          target: {
            name: 'projectEnvironments',
            value: this.storedProjectEnvironments,
          },
        })
      } else {
        this.initializeProjectEnvironmentsCode()
      }
      if (!!this.storedCodeRepository) {
        this.props.handleInputChange({
          target: {
            name: 'codeRepository',
            value: this.storedCodeRepository,
          },
        })
      } else {
        this.initializeCodeRepository()
      }
    }
  }

  handleCodeRepositoryUpdate = (event: any): void => {
    const updateCodeRepository: CodeRepository = this.props.project.codeRepository
    updateCodeRepository[event.target.name] = event.target.value
    this.props.handleInputChange({
      target: {
        name: 'codeRepository',
        value: updateCodeRepository,
      },
    })
  }

  getFullWebroot = (id: string): string => {
    return this.webroots.get(id).fullWebroot
  }

  updateProjectEnvironments = (projectEnvironments: ProjectEnvironment[]): void => {
    this.props.handleInputChange({
      target: {
        name: 'projectEnvironments',
        value: projectEnvironments,
      },
    })
  }

  getRepositoryErrorMessage = (error: PROJECT_ERROR): string => {
    switch (error) {
      case PROJECT_ERROR.GIT_REPOSITORY_NOT_SAVED:
        return MessageService.get('project.wizard.error.code.repository.notSaved')
      case PROJECT_ERROR.DUPLICATE_REPOSITORY_PROJECT_NAME:
        return MessageService.get('project.wizard.error.code.repository.project.name.duplicate')
      case PROJECT_ERROR.DUPLICATE_REPOSITORY_GROUP:
        return MessageService.get('project.wizard.error.code.repository.group.duplicate')
      default:
        return null
    }
  }

  handleSaveProject = (): void => {
    this.props.handleCloseBranchConfirmation()
    this.props.handleSaveProject(this.props.confirmActionCreator)
  }

  render(): React.ReactNode {
    const { project, error, loading, branchConfirmation, handleCloseBranchConfirmation } = this.props
    const inputStyle = { backgroundColor: 'white', marginTop: '50px', marginBottom: '10px' }
    return (
      <SectionLayout>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">{MessageService.get('project.wizard.step3.details')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={!project.hasCode} onClick={this.toggleNoCode} />}
                  label="This project does not use code"
                />
              </Grid>
              {project.hasCode && project.codeRepository && (
                <Grid item xs={12}>
                  <Grid container spacing={2} justify="space-between">
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={5}>
                      <Paper elevation={2} className="p1 MuiPaper-root1">
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                <h5>Repository</h5>
                              </Grid>
                              <Grid item xs={12}>
                                <FormField
                                  label="Group"
                                  error={!!this.getRepositoryErrorMessage(error)}
                                  mandatory={true}
                                  message={this.getRepositoryErrorMessage(error)}
                                >
                                  <TextField
                                    name="group"
                                    disabled={loading}
                                    value={project.codeRepository.group}
                                    onChange={this.handleCodeRepositoryUpdate}
                                    variant="outlined"
                                    style={inputStyle}
                                  />
                                </FormField>
                              </Grid>
                              <Grid item xs={12}>
                                <FormField
                                  label="Name"
                                  error={!!this.getRepositoryErrorMessage(error)}
                                  mandatory={true}
                                  message={this.getRepositoryErrorMessage(error)}
                                >
                                  <TextField
                                    name="name"
                                    disabled={loading}
                                    value={project.codeRepository.name}
                                    onChange={this.handleCodeRepositoryUpdate}
                                    variant="outlined"
                                    style={inputStyle}
                                  />
                                </FormField>
                              </Grid>
                              <Grid item xs={12} style={{ wordBreak: 'break-all', marginTop:'25px'}}>
                                <Grid container direction="column" spacing={2}>
                                  <Grid item>
                                    <FormLabel component="legend">Git URL</FormLabel>
                                  </Grid>
                                  <Grid item>
                                    {`${project.codeRepository.gitHost}/${project.codeRepository.group}/${project.codeRepository.name}.git`}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            {!this.isRepoInit && (
                              <ImportRepositoryWidget
                                disabled={this.disableImportFields}
                                importError={this.importError}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={7} className={'project-link project-code-m'}>
                      <Grid container direction="column" spacing={4} justify="center">
                        {project.projectEnvironments
                          .sort((env1: ProjectEnvironment, env2: ProjectEnvironment) => env2.order - env1.order)
                          .map((projEnv: ProjectEnvironment, index: number) => {
                            return !!projEnv.projectEnvironmentCode ? (
                              <Grid item xs={12} key={projEnv.environmentId}>
                                <ProjectEnvironmentCodeCard
                                  projectEnvironment={projEnv}
                                  webroots={this.webroots}
                                  isDev={
                                    DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projEnv.environmentId)
                                      .isDev
                                  }
                                  getFullWebroot={this.getFullWebroot}
                                  disableImportFields={this.disableImportFields}
                                  importError={this.importError}
                                  className={index < project.projectEnvironments.length-1 ? 'project-card-step' : ''}
                                />
                              </Grid>
                            ) : null
                          })}
                      </Grid>
                    </Grid>
                    <ConfirmDialog
                      title={'Feature Branch Confirmation'}
                      onConfirm={this.handleSaveProject}
                      onCancel={handleCloseBranchConfirmation}
                      message={this.featureBranchConfirmation}
                      open={branchConfirmation}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </SectionLayout>
    )
  }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IProjectEditCodeProps): any => ({
  project: state.selectedProject.project,
  error: state.selectedProject.error,
  loading: state.selectedProject.loading,
  bundle: state.bundle.data,
  branchConfirmation: state.selectedProject.branchConfirmation,
  confirmActionCreator: state.selectedProject.confirmActionCreator,
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IProjectEditCodeProps): any => ({
  handleInputChange: (event: any): void => {
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
      payload: {
        key: event.target.name,
        value: event.target.value,
      },
    })
  },
  handleSaveProject: (confirmAction: () => any): void => dispatch(confirmAction()),
  handleCloseBranchConfirmation: (): void => dispatch(closeBranchConfirmation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditCode)
