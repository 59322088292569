import { ObjectID } from 'bson'
import { resolveAssetGroupBasePath } from 'src/helpers/project/environment/asset/env-asset-transformation'
import { resolveDefaultDeploymentTargetGroupId, replaceSpaceWithUnderscore } from 'src/helpers/shared'
import AssetGroup from 'src/models/AssetGroup'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import ProjectEnvironmentAssets from 'src/models/ProjectEnvironmentAssets'
import ProjectType, { DefaultAssetConfig } from 'src/models/ProjectType'

const searchString = 'asset'

export function createDefaultProjectEnvironmentAssetsForCreateProjectDataWorkflow(
  projectName: string,
  deploymentEnvironment: DeploymentEnvironment,
  hasCode: boolean,
  webroot?: string,
  typeData?: ProjectType,
): ProjectEnvironmentAssets {
  const deploymentTargetGroupId = resolveDefaultDeploymentTargetGroupId(deploymentEnvironment, searchString)
  const environmentTitle = replaceSpaceWithUnderscore(deploymentEnvironment.title)
  const assetGroups = createAssetGroupsFromDefaultAssetConfiguration(
    environmentTitle,
    projectName,
    hasCode,
    webroot,
    typeData,
  )
  return new ProjectEnvironmentAssets({
    deploymentTargetGroupId,
    assetGroups,
  })
}

export function createAssetGroupsFromDefaultAssetConfiguration(
  environmentTitle: string,
  projectName: string,
  hasCode: boolean,
  webroot?: string,
  typeData?: ProjectType,
): AssetGroup[] {
  if (typeData) {
    const assetGroups = typeData.defaultAssetConfigurations.map((assetConfig: DefaultAssetConfig, index: number) => {
      return createDefaultAssetGroupWithProjectTypeConfig(index, environmentTitle, projectName, assetConfig, hasCode, webroot)
    })
    return assetGroups
  }
  return []
}


// TODO: Test
export function createDefaultAssetGroupWithProjectTypeConfig(
  associationId: number,
  environmentTitle: string,
  projectName: string,
  assetConfig: DefaultAssetConfig,
  hasCode: boolean,
  webroot?: string,
): AssetGroup {
  const title = assetConfig.title
  const basePath = resolveAssetGroupBasePath(projectName, environmentTitle, hasCode, webroot)
  const path = `${basePath}/assets${assetConfig.assetPathSuffix}`
  const assetGroupId = new ObjectID().toHexString()
  return new AssetGroup({
    assetGroupId: assetGroupId,
    associationId,
    title,
    path,
  })
}
