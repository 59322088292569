import React from 'react'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import {cloneDeep} from 'lodash'
import {Grid} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import IStoreState from 'src/store/IStoreState'
import {ActionConfigurationActions} from 'src/store/actions/actionConfigurationActions'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExchangeAlt, faInfoCircle, faPlus} from '@fortawesome/free-solid-svg-icons'
import {PipelineTemplateActions} from 'src/store/actions/pipelineTemplateActions'
import PipelineService from 'src/services/PipelineService'
import SnackbarService, {SNACKBAR_TYPES} from 'src/services/SnackbarService'
import Project from 'src/models/Project'
import {ActionConfiguration} from 'src/models/pipeline/ActionConfiguration'
import RetryLoading from 'src/components/common/RetryLoading'
import {PipelineTemplate} from 'src/models/pipeline/PipelineTemplate'
import ActionConfigurationTable from './ActionConfigurationTable'
import LoadingOverlay from 'src/components/common/LoadingOverlay'
import Spinner from 'src/components/common/Spinner'
import ActionConfigEditor from './ActionConfigEditor'

export interface IActionConfigurationSectionProps {
  project?: Project
  loadProjectPipelineTemplates?: (id: string) => void
  pipelineTemplates?: PipelineTemplate[]
  loading?: boolean
  error?: any
  loadProjectById?: (id: string) => void
  loadProjectActionConfigurations?: (id: string) => void
}

export interface IActionConfigurationSectionState {
  selectedTab: number
  hasInitiallyLoaded: boolean
  openNewActionDialog: boolean
  currentActionConfig: ActionConfiguration
  actionConfigurations?: ActionConfiguration[]
}

export class ActionConfigurationSection extends React.Component<IActionConfigurationSectionProps, IActionConfigurationSectionState> {
  constructor(props: IActionConfigurationSectionProps) {
    super(props)
    this.state = {
      selectedTab: 0,
      hasInitiallyLoaded: false,
      openNewActionDialog: false,
      currentActionConfig: null,
      actionConfigurations:null
    }
  }

  openActionConfigEditor = (actionConfiguration?: ActionConfiguration): void => {
    const copiedAC: ActionConfiguration = cloneDeep(actionConfiguration)
    this.setState({openNewActionDialog: true, currentActionConfig: copiedAC})
  }

  componentWillMount(): void {
    this.loadProjectActionConfigurations()
  }

  loadProjectActionConfigurations = async (): Promise<void> => {
    const {project} = this.props
    const actionConfigurations: ActionConfiguration[] =  await PipelineService.getProjectActionConfigurations(project.id)
    this.setState({actionConfigurations:actionConfigurations})
  }

  loadProjectPipelineTemplates = (): void => {
    const {loadProjectPipelineTemplates, project} = this.props
    loadProjectPipelineTemplates(project.id)
    this.setState({actionConfigurations:undefined})
  }

  handleActionConfigEditorClose = async (actionConfiguration?: ActionConfiguration, project?: Project): Promise<void> => {
    if (actionConfiguration) {
      this.loadProjectPipelineTemplates()
      this.loadProjectActionConfigurations()
   }
    this.setState({openNewActionDialog: false})
  }

  handleAddActionConfigurationClick = (): void => {
    this.openActionConfigEditor()
  }

  handleDeleteClick = async (actionConfiguration: ActionConfiguration): Promise<void> => {
    try {
      await PipelineService.deleteProjectActionConfiguration(actionConfiguration)
      this.loadProjectPipelineTemplates()
      this.loadProjectActionConfigurations()
    } catch (e) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Action is used in a Pipeline')
    }
  }

  render = (): React.ReactNode => {
    const {loading, error} = this.props
    const {openNewActionDialog, currentActionConfig} = this.state

    if (error) {
      return <RetryLoading message='Could not load action configurations.'
                           reloadData={this.loadProjectActionConfigurations}/>
    } else if (loading || !this.state.actionConfigurations) {
      return <div className='loading-overlay-parent-full'><LoadingOverlay/></div>
    } else {
      return (
        <div>
          <Grid container>
            <Grid container>
              <Grid container>
                <Grid item>
                  <Grid container direction='row' alignItems='center' spacing={4}>
                    <Grid item>
                      <FontAwesomeIcon icon={faExchangeAlt} size='2x'/>
                    </Grid>
                    <Grid item>
                      <h2>Actions</h2>
                    </Grid>
                    <Grid item style={{color: '#ccc'}}>
                      <Tooltip placement='top' enterDelay={50}
                               title='The configuration instructions that determine the behavior of an action.'>
                        <FontAwesomeIcon icon={faInfoCircle}/>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <IconButton id='ab-dt-add-button'
                                  style={{marginBottom: '4px'}} title='Create New Action Configuration'
                                  onClick={this.handleAddActionConfigurationClick}>
                        <FontAwesomeIcon
                          icon={faPlus} size='sm'
                          className={'clickable'}/>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                {loading && <Grid container justify='center'><Spinner large/></Grid>}

                {error && <RetryLoading message='Could not load action configurations.'
                                        reloadData={this.loadProjectActionConfigurations}/>}

                {!error && (this.state.actionConfigurations && !this.state.actionConfigurations.length) &&
                <RetryLoading message='There were no actions' reloadData={this.loadProjectActionConfigurations}/>
                }
                {!loading && !error && this.state.actionConfigurations?.length > 0 &&
                <ActionConfigurationTable actionConfigurations={this.state.actionConfigurations}
                                          handleActionConfigurationRowClick={this.openActionConfigEditor}
                                          handleActionConfigurationRowDeleteClick={this.handleDeleteClick}/>
                }
              </Grid>
            </Grid>
          </Grid>
          {openNewActionDialog &&
          <ActionConfigEditor
            onClose={this.handleActionConfigEditorClose} actionConfiguration={currentActionConfig}
          />
          }
        </div>
      )
    }
  }
}


const mapStateToProps: any = (state: IStoreState, ownProps: IActionConfigurationSectionProps): any => ({
  project: state.selectedProject.project,
  loading: state.selectedProject.loading,
  error: state.selectedProject.error,
  pipelineTemplates: state.pipelineTemplates.data,
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IActionConfigurationSectionProps): any => ({
  loadProjectPipelineTemplates: (id: string): void => dispatch(PipelineTemplateActions.loadPipelineTemplates(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionConfigurationSection)
