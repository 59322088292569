import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewSourceLink: {
      color: theme.palette.common.white,
      textTransform: 'none'
    },
    container: {},
    linkContainer: {},
  })
)
