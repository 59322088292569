import DeploymentTarget, {DEPLOYMENT_TARGET_STATUS} from '../../../models/DeploymentTarget'
import messageService from '../../../services/MessageService'

enum MESSAGE_STATE {
  WAITING = 'WAITING',
  ACTIVE = 'ACTIVE',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL'
}

class DeploymentTargetStatusMessageHelper {

  statusRelationshipMap: Map<DEPLOYMENT_TARGET_STATUS, DeploymentTargetStatusRelationship>

  constructor() {
    this.statusRelationshipMap = new Map([
      [DEPLOYMENT_TARGET_STATUS.INCOMPLETE, new DeploymentTargetStatusRelationship (0, null, null)],
      [DEPLOYMENT_TARGET_STATUS.PENDING_IP_PORT_VERIFICATION,
        new DeploymentTargetStatusRelationship(1, DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_IP_PORT_VERIFICATION, [DEPLOYMENT_TARGET_STATUS.FAILED_IP_PORT_VERIFICATION,  DEPLOYMENT_TARGET_STATUS.FAILED_IP_UNIQUE_CONSTRAINT])],
      [DEPLOYMENT_TARGET_STATUS.PENDING_PASSWORD_LOGIN,
        new DeploymentTargetStatusRelationship(2, DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_PASSWORD_LOGIN, [DEPLOYMENT_TARGET_STATUS.FAILED_PASSWORD_LOGIN])],
      [DEPLOYMENT_TARGET_STATUS.PENDING_KEY_GENERATION,
        new DeploymentTargetStatusRelationship(3, DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_KEY_GENERATION, [DEPLOYMENT_TARGET_STATUS.FAILED_KEY_GENERATION])],
      [DEPLOYMENT_TARGET_STATUS.PENDING_KEY_LOGIN,
        new DeploymentTargetStatusRelationship(4, DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_KEY_LOGIN, [DEPLOYMENT_TARGET_STATUS.FAILED_KEY_LOGIN])],
      [DEPLOYMENT_TARGET_STATUS.PENDING_ESCALATION_LOGIN,
        new DeploymentTargetStatusRelationship(5, DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_ESCALATION_LOGIN, [DEPLOYMENT_TARGET_STATUS.FAILED_ESCALATION_LOGIN])],
      [DEPLOYMENT_TARGET_STATUS.PENDING_INSTALL,
        new DeploymentTargetStatusRelationship(6, DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_INSTALL, [DEPLOYMENT_TARGET_STATUS.FAILED_INSTALL])],
      [DEPLOYMENT_TARGET_STATUS.READY, new DeploymentTargetStatusRelationship (7, null, null)]
    ])
  }

  getMessage = (status: DEPLOYMENT_TARGET_STATUS, target: DeploymentTarget): string => {
    switch(status) {
      case DEPLOYMENT_TARGET_STATUS.PENDING_IP_PORT_VERIFICATION:
        if (target.ipAddress && target.port) { return messageService.get('admin.deploymenttarget.wizard.status.ip', {port: target.port, ipAddress: target.ipAddress}) }
        else { return messageService.get('admin.deploymenttarget.wizard.status.ip.default') }
      case DEPLOYMENT_TARGET_STATUS.PENDING_PASSWORD_LOGIN:
        if (target.remoteUser) { return messageService.get('admin.deploymenttarget.wizard.status.passwordLogin', {remoteUser: target.remoteUser}) }
        else { return messageService.get('admin.deploymenttarget.wizard.status.passwordLogin.default') }
      case DEPLOYMENT_TARGET_STATUS.PENDING_KEY_GENERATION:
        if (target.name) { return messageService.get('admin.deploymenttarget.wizard.status.keygen', {name: target.name}) }
        else { return messageService.get('admin.deploymenttarget.wizard.status.keygen.default') }
      case DEPLOYMENT_TARGET_STATUS.PENDING_KEY_LOGIN:
        if (target.remoteUser) { return messageService.get('admin.deploymenttarget.wizard.status.keyLogin', {remoteUser: target.remoteUser}) }
        else { return messageService.get('admin.deploymenttarget.wizard.status.keyLogin.default') }
      case DEPLOYMENT_TARGET_STATUS.PENDING_ESCALATION_LOGIN:
        if (target.escalationUser) { return messageService.get('admin.deploymenttarget.wizard.status.escalation', {escalationUser: target.escalationUser}) }
        else { return messageService.get('admin.deploymenttarget.wizard.status.escalation.default') }
      case DEPLOYMENT_TARGET_STATUS.PENDING_INSTALL:
        return messageService.get('admin.deploymenttarget.wizard.status.install')
      default:
        return null
    }
  }

  getMessageStates = (statuses: DEPLOYMENT_TARGET_STATUS[], targetStatus: DEPLOYMENT_TARGET_STATUS): MESSAGE_STATE[] => {
    let statusOrder: number
    if (!this.statusRelationshipMap.has(targetStatus)) {
      this.statusRelationshipMap.forEach((relationship: DeploymentTargetStatusRelationship, key: DEPLOYMENT_TARGET_STATUS) => {
        if (relationship.successful === targetStatus || (relationship.failed && relationship.failed.includes(targetStatus))) {
          statusOrder = relationship.order
        }
      })
    } else {
      statusOrder = this.statusRelationshipMap.get(targetStatus).order
    }
    return statuses.map((status: DEPLOYMENT_TARGET_STATUS) => {
      if (status === targetStatus) {
        return MESSAGE_STATE.ACTIVE
      }
      if (this.statusRelationshipMap.get(status).failed.includes(targetStatus)) {
        return MESSAGE_STATE.FAIL
      }
      if (this.statusRelationshipMap.get(status).order <= statusOrder) {
        return MESSAGE_STATE.SUCCESS
      } 
      return MESSAGE_STATE.WAITING
    })
  }
}

class DeploymentTargetStatusRelationship {
  order: number
  successful: DEPLOYMENT_TARGET_STATUS
  failed: DEPLOYMENT_TARGET_STATUS[]

  constructor(order: number, successful: DEPLOYMENT_TARGET_STATUS, failed: DEPLOYMENT_TARGET_STATUS[]) {
    this.order = order
    this.successful = successful
    this.failed = failed
  }
}

export default new DeploymentTargetStatusMessageHelper()
export {MESSAGE_STATE}
