import IStoreState from './IStoreState'
import Project, {PROJECT_STATUS, PROJECT_TYPE} from '../models/Project'
import { defaultProjectCreationFormState } from './create-project-form/create-project-form.state'
import { defaultCreateProjectDataState } from './create-project-data/create-project-data.state'
import {HeaderTabs} from '../components/layout/header/Header.component'

const initialState: IStoreState = {
  currentUser: null,
  createProjectForm: defaultProjectCreationFormState,
  createProjectData: defaultCreateProjectDataState,
  deploymentEnvironments: {
    data: [],
    loading: false
  },
  deploymentTargets: {
    data: [],
    loading: false
  },
  selectedEnvironment: {
    deploymentTargetGroups: [],
    loading: false
  },
  selectedEnvironmentList:{
    data: [],
    loading: false
  },
  projects: {
    data: [],
    loading: false
  },
  selectedProject: {
    project: new Project({
      name: undefined,
      domain: undefined,
      status: PROJECT_STATUS.INCOMPLETE,
      type: PROJECT_TYPE.DRUPAL_7,
      projectEnvironments: undefined,
      codeRepository: undefined,
      hasCode: true,
      hasDatabases: true,
      hasAssets: true,
      disabled: false
    }),
    latestEvents: [],
    unsavedChanges: false,
    persistedProject: null,
    branchConfirmation: false,
    confirmActionCreator: undefined,
    errorMessage: null,
    loading: false,
    error: null,
  },
  projectTypes: {
    data: [],
    loading: false
  },
  bundle: {
    data: null,
    loading: false
  },
  actionConfigurations: {
    data: [],
    loading:false
  },
  pipelineTemplates: {
    data: undefined,
    loading: false,
    saving: false,
    deleting: false
  },
  projectWizard: {
    project: new Project({
      name: undefined,
      domain: undefined,
      status: PROJECT_STATUS.INCOMPLETE,
      type: PROJECT_TYPE.DRUPAL_7,
      projectEnvironments: undefined,
      codeRepository: undefined,
      hasCode: true,
      hasDatabases: true,
      hasAssets: true,
      disabled: false
    }),
    completedSteps: [],
    activeStep: 0,
    error: null,
    errorMessage: null,
    open: false,
    loading: false,
    unsavedChanges: false,
    persistedProject: null,
    branchConfirmation: false,
    confirmActionCreator: undefined,
    cloneFromProject: undefined,
    initialDeploy: false,
    isRepoPublic: false
  },
  pipelineTemplateEditor: {
    open: false,
    pipelineTemplate: undefined,
    touched: false,
    errors: new Map<string, string>()
  },
  history: {
    data: undefined,
    loading: false,
  },
  pipelines: {
    data: undefined,
    loading: false
  },
  header: {
    selectedTab: HeaderTabs.Projects
  }
}

export default initialState
