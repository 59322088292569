import { DropResult } from 'react-beautiful-dnd'
import { Dispatch } from 'redux'
import {
    EnvironmentSelectionConfig,
} from 'src/components/project/project-details/section/environments/project-environment.config'
import {UpdateProjectDataActionTypes,Update_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS} from 'src/store/update-project-data/project-details-environment.types'
import IStoreState from 'src/store/IStoreState'
import ProjectEnvironment from "../../models/ProjectEnvironment";
import {updateProjectEnvironmentsAction} from "../actions/project/update-environments.actions";
import {differenceBy} from "lodash";
import DeploymentEnvironment from "../../models/DeploymentEnvironment";

export function setProjectSelectedEnvironmentsDetails(configs: EnvironmentSelectionConfig[]): UpdateProjectDataActionTypes {
  return {
    type: Update_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS,
    payload: configs,
  }
}
interface EnvironmentIdExtension {
    environmentId: string
    environment: DeploymentEnvironment
}
function createProjectEnvFromDeploymentEnv (deploymentEnvironment: DeploymentEnvironment, order?: number,): ProjectEnvironment {
    return new ProjectEnvironment({
        environmentId: deploymentEnvironment.id,
        order,
        symLinks: [],
    })
}
export function addSelectedEnvironmentDetails(config: EnvironmentSelectionConfig): any {
  return async (dispatch: Dispatch<UpdateProjectDataActionTypes>, getState: () => IStoreState): Promise<void> => {
    const appState = getState()

    // Determine the order of the project environments before dispatching the update
    if (!config.order) {
      const sortedProjects = appState.selectedProject.project.projectEnvironments.sort((a, b) => a.order - b.order)
      const newOrder: number = sortedProjects[sortedProjects.length - 1].order
      config.order = newOrder
      sortedProjects[sortedProjects.length - 1].order = newOrder + 1
    }

    const selectedEnvironments: EnvironmentSelectionConfig[] = [
      ...appState.selectedEnvironmentList.data,
      config,
    ]

    dispatch(setProjectSelectedEnvironmentsDetails(selectedEnvironments))
    const currentEnvironments = appState.selectedProject.project?.projectEnvironments || []
    const index = currentEnvironments.findIndex((e) => e.environmentId === config.id)
    const deploymentEnvironments = appState.deploymentEnvironments.data?.map<EnvironmentIdExtension>((e) => {
      return { environmentId: e.id, environment: e }
    })
    const unselected = differenceBy<EnvironmentIdExtension, ProjectEnvironment>(
      deploymentEnvironments,
      currentEnvironments,
      'environmentId',
    )
    const converted = unselected.map((extension) => createProjectEnvFromDeploymentEnv(extension.environment))
    const isNotIncluded = index === -1
    const addedEnv = converted.filter((e) => e.environmentId === config.id)

    if (isNotIncluded) {
      const updatedEnvironments = [...currentEnvironments]
      updatedEnvironments.splice(currentEnvironments.length - 1, 0, addedEnv[0])
      updatedEnvironments.forEach((env: ProjectEnvironment, i: number) => {
          env.order = i
      })
      dispatch(updateProjectEnvironmentsAction(updatedEnvironments))
    }
  }
}

export function removeSelectedEnvironmentDetails(config: EnvironmentSelectionConfig): any {
    return async (dispatch: Dispatch<UpdateProjectDataActionTypes>, getState: () => IStoreState): Promise<void> => {
        const appState = getState()
        const selectedEnvironments: EnvironmentSelectionConfig[] = appState.selectedEnvironmentList.data.filter(
            (envConfig) => config.id !== envConfig.id,
        )
        dispatch(setProjectSelectedEnvironmentsDetails(selectedEnvironments))
        const environments = appState.selectedProject.project?.projectEnvironments || []
        const index = environments.findIndex((e) => e.environmentId === config.id)
        const isIncluded = index !== -1
        if (isIncluded) {
            const updatedEnvironments = environments.filter((e) => e.environmentId !== config.id)
            updatedEnvironments.forEach((env: ProjectEnvironment, i: number) => {
                env.order = i
            })
            dispatch(updateProjectEnvironmentsAction(updatedEnvironments))
        }
    }
}
export function reorderSelectedEnvironmentDetails(result: DropResult): any {
    return async (dispatch: Dispatch<UpdateProjectDataActionTypes>, getState: () => IStoreState): Promise<void> => {
        const { source, destination } = result
        if (destination && source) {
            const hasNewIndex = !(destination.droppableId === source.droppableId && destination.index === source.index)
            if (hasNewIndex) {
                const appState = getState()
                const reorderedSelectedEnvironments: EnvironmentSelectionConfig[] = [
                    ...appState.selectedEnvironmentList.data,
                ]
                const removed = reorderedSelectedEnvironments.splice(source.index, 1)
                reorderedSelectedEnvironments.splice(destination.index, 0, ...removed)
                dispatch(setProjectSelectedEnvironmentsDetails(reorderedSelectedEnvironments))
                const reorderedProjectEnvironments: ProjectEnvironment[] = appState.selectedProject.project.projectEnvironments
                let reorderedProjects: ProjectEnvironment[] = appState.selectedProject.project.projectEnvironments.filter(projectEnvironment => reorderedSelectedEnvironments.every(projectEnv => projectEnvironment.environmentId !== projectEnv.id))
                const prodEnvironment:ProjectEnvironment = !!reorderedProjects && reorderedProjects.length > 0 ? reorderedProjects[0] : null
                reorderedProjects = []
                reorderedSelectedEnvironments.forEach((projectEnvironment :EnvironmentSelectionConfig,indexEnv): void =>{
                    reorderedProjectEnvironments.forEach((projectEnvironmentList: ProjectEnvironment, index: number): void => {
                        if(projectEnvironment.id === projectEnvironmentList.environmentId){
                            projectEnvironmentList.order = indexEnv + 1
                            reorderedProjects.push(projectEnvironmentList)
                        }
                    })
                })
                if(!!prodEnvironment){
                    reorderedProjects.push(prodEnvironment)
                }
                reorderedProjects.forEach((projectEnvironment: ProjectEnvironment, index: number): void => {
                    projectEnvironment.order = index
                })
                dispatch(updateProjectEnvironmentsAction(reorderedProjects))
            }
        }
    }
}
