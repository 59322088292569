import { Grid, Button, Tooltip } from '@material-ui/core'
import React from 'react'
import { SaveAndCancelConfig } from '../project-create.scene'

import { useStyles } from './project-create-banner.config'

export interface SaveAndCancelButtonsProps {
  config: SaveAndCancelConfig
}

export const SaveAndCancelButtons: React.FC<SaveAndCancelButtonsProps> = ({ config }) => {
  const style = useStyles()
  const { handleSubmit, handleCancel, isSaveDisabled, isError, tooltip } = config
  return (
    <Grid container alignItems="center" alignContent="center" justify="center" spacing={2}>
      <Grid item>
        <Button
          onClick={handleCancel}
          className={style.cancelButton}
          color="primary"
          variant="contained"
          disableElevation
        >
          Discard
        </Button>
      </Grid>
      <Grid item>
        <Tooltip title={isSaveDisabled && isError ? tooltip : ''}>
          <span>
            <Button
              onClick={handleSubmit}
              className={style.saveButton}
              variant="contained"
              color="primary"
              disableElevation
              disabled={isSaveDisabled}
            >
              Save Project
            </Button>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
