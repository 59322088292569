import React, {useState} from 'react'
import ProjectTable from './ProjectTable'
import {Button, Grid} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome, faPlus} from '@fortawesome/free-solid-svg-icons'
import User from '../../models/User'
import {Dispatch} from 'redux'
import IStoreState from '../../store/IStoreState'
import {connect} from 'react-redux'
import Project from '../../models/Project'
import LoadingOverlay from '../common/LoadingOverlay'
import ConfirmDialog from '../common/ConfirmDialog'
import ProjectActions from '../../store/actions/projectActions'
import ProjectWizardActions from '../../store/actions/projectWizardActions'
import {PageLayout} from '../layout/shared'
import {Breadcrumb} from '../project/project-details/shared'
import SearchField from '../common/SearchField'
import {SortDirections} from '../../models/SortDirections'
import {ProjectFilters, useStyles} from './DashboardPage.config'
import { router } from '../../router'
import { appStates } from '../../appStates'

export interface IDashboardProps {
  currentUser: User,
  openProjectWizard: boolean,
  loadingProjects: boolean,
  loadingProjectTypes: boolean,
  handleOpenProjectWizard: (project?: Project, cloneToNew?: boolean) => void,
  disableProject?: (projectId: string) => void
  enableProject?: (projectId: string) => void
}

const sortDirections: any = {
  ASC: 'asc',
  DESC: 'desc',
}

export const DashboardPage: React.FC<IDashboardProps> =({
  currentUser,
  openProjectWizard,
  loadingProjects,
  loadingProjectTypes,
  handleOpenProjectWizard,
  disableProject,
  enableProject}) => {
  const [projectToDisable, setProjectToDisable] = useState(null)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState(SortDirections.ASC)
  const [orderBy, setOrderBy] = useState('name')
  const [columns, setColumns] = useState([
    {id: 'statusicon', numeric: false, disablePadding: true, label: '', sortable: false,order:sortDirections.ASC},
    {id: 'name', numeric: false, disablePadding: false, label: 'Project', sortable: true,order:sortDirections.ASC},
    {id: 'type', numeric: false, disablePadding: false, label: 'Type', sortable: true,order:sortDirections.ASC},
    {id: 'edit', numeric: false, disablePadding: false, label: '', sortable: false,order:sortDirections.ASC},
  ])
  const handleCreateProject = (project: Project): void => {
    router.stateService.go(appStates.PROJECT_CREATE)
  }

  const openDisableConfirmModal = (project: Project): void => {
    setProjectToDisable(project)
  }

  const closeDisableConfirmModal = (): void => {
    setProjectToDisable(null)
  }

  const handleDisableConfirm = (event: any): void => {
    disableProject(projectToDisable.id)
    closeDisableConfirmModal()
  }

  const handleEnableProject = (project: Project): void => {
    enableProject(project.id)
    closeDisableConfirmModal()
  }

  const cloneProject = (project: Project): void => {
    handleOpenProjectWizard(project, true)
    router.stateService.go(appStates.PROJECT_CLONE)
  }

  const updateSearchCriteria = (event: any): void => {
    setSearch(event.target.value)
  }

  const classes = useStyles()
    const confirmDisableMessage: string = projectToDisable && `Are you sure you want to disable <b>${projectToDisable.name}</b>? All sites will remain live, but the project will no longer be accessible and its repository will be archived. <b>You may re-enabled the project at any time.</b>`
    if (!openProjectWizard && (loadingProjects || loadingProjectTypes)) {
      return <div className='loading-overlay-parent-full' ><LoadingOverlay/></div>
    } else {
      return (
        <PageLayout>
          <Grid container direction='column'>
            {/*First row*/}
            <Grid item xs={12}>
              <Grid container direction='row' justify='space-evenly'>
                <Grid item xs>
                  <Breadcrumb icon={faHome} context='Dashboard' path=''/>
                </Grid>
                <Grid item xs>
                  {currentUser.canAddProject() &&
                    <Button  onClick={handleCreateProject.bind(null, null)}
                             className={classes.button}>Add New Project
                      <FontAwesomeIcon icon={faPlus} size='sm' className={classes.button_icon}/>
                    </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
            {/*Second Row - All Project link*/}
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='stretch'>
                <Grid item xs>
                  <label className={classes.filters} >All Projects</label>
                </Grid>
                <Grid item xs>
                  <SearchField id='search' value={search} className={classes.search}
                               label='Search' onChange={updateSearchCriteria} iconColor='white'
                               autoComplete='off'/>
                </Grid>
              </Grid>
            </Grid>
            <ProjectTable handleEditClick={handleOpenProjectWizard} handleEnableClick={handleEnableProject}
                              handleDisableClick={openDisableConfirmModal} handleCloneClick={cloneProject}
                              search={search} order={order} orderBy={orderBy} setOrder={setOrder} setOrderBy={setOrderBy}
                              projectFilter={ProjectFilters.ALL} columns={columns} setColumns={setColumns}/>
            {projectToDisable && <ConfirmDialog open message={confirmDisableMessage} onCancel={closeDisableConfirmModal} onConfirm={handleDisableConfirm}/>}
          </Grid>
        </PageLayout>
      )
    }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IDashboardProps): any => ({
  currentUser: state.currentUser,
  openProjectWizard: state.projectWizard.open,
  projectsError: state.projects.error,
  loadingProjects: state.projects.loading,
  loadingProjectTypes: state.projectTypes.loading
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IDashboardProps): any => ({
  handleOpenProjectWizard: (project?: Project, cloneToNew: boolean = false): void => {
    dispatch(ProjectWizardActions.openProject(project, cloneToNew))
  },
  disableProject: (projectId: string): void => dispatch(ProjectActions.disableProject(projectId)),
  enableProject: (projectId: string): void => dispatch(ProjectActions.enableProject(projectId))
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
