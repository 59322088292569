import Project from 'src/models/Project'
import ProjectEnvironment from 'src/models/ProjectEnvironment'

export const CREATE_PROJECT_DATA_SET_PROJECT = 'CREATE_PROJECT_DATA_SET_PROJECT'
export const CREATE_PROJECT_DATA_SET_PROJECT_FIELD = 'CREATE_PROJECT_DATA_SET_PROJECT_FIELD'
export const CREATE_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS = 'CREATE_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS'

export const CREATE_PROJECT_DATA_RESET = 'CREATE_PROJECT_DATA_RESET'
export const CREATE_PROJECT_DATA_SAVE = 'CREATE_PROJECT_DATA_SAVE'
export const CREATE_PROJECT_DATA_LOADING = 'CREATE_PROJECT_DATA_LOADING'
export const CREATE_PROJECT_DATA_SUCCESS = 'CREATE_PROJECT_DATA_SUCCESS'
export const CREATE_PROJECT_DATA_ERROR = 'CREATE_PROJECT_DATA_ERROR'

export interface CreateProjectDataSetProjectAction {
  type: typeof CREATE_PROJECT_DATA_SET_PROJECT
  payload: Project
}

export interface CreateProjectDataSetProjectFieldAction {
  type: typeof CREATE_PROJECT_DATA_SET_PROJECT_FIELD
  payload: { field: string; value: string }
}

export interface CreateProjectDataSetProjectEnvironmentsAction {
  type: typeof CREATE_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS
  payload: ProjectEnvironment[]
}

export interface CreateProjectDataSaveProjectAction {
  type: typeof CREATE_PROJECT_DATA_SAVE
  payload: undefined
}

export interface CreateProjectDataResetAction {
  type: typeof CREATE_PROJECT_DATA_RESET
  payload: undefined
}

export interface CreateProjectDataLoadingAction {
  type: typeof CREATE_PROJECT_DATA_LOADING
  payload: undefined
}

export interface CreateProjectDataSuccessAction {
  type: typeof CREATE_PROJECT_DATA_SUCCESS
  payload: undefined
}

export interface CreateProjectDataErrorAction {
  type: typeof CREATE_PROJECT_DATA_ERROR
  payload: { error: string; errorMessage: string }
}

export type CreateProjectDataActionTypes =
  | CreateProjectDataSetProjectAction
  | CreateProjectDataSetProjectFieldAction
  | CreateProjectDataSetProjectEnvironmentsAction
  | CreateProjectDataSaveProjectAction
  | CreateProjectDataLoadingAction
  | CreateProjectDataSuccessAction
  | CreateProjectDataErrorAction
  | CreateProjectDataResetAction
