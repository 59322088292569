import React from 'react'
import {Grid, Input, Tooltip} from '@material-ui/core'

export interface IInLineEditableTextProps {
  text: string
  toolTipMessage?: string
  handleChange: (value: any) => void
  handleSubmit: (value: any) => void
}

export interface IInLineEditableTextState {
  editing: boolean
}

export class InLineEditableText extends React.Component <IInLineEditableTextProps, IInLineEditableTextState> {

  constructor(props: IInLineEditableTextProps) {
    super(props)
    this.state = {
      editing: false,
    }
  }

  startEditing = (): void => {
    this.setState({editing: true})
  }

  handleInput = (event: any): void => {
    this.props.handleChange(event.target.value)
  }

  handleOnBlur = async (event: any): Promise<void> => {
    this.setState({editing: false})
    this.props.handleSubmit(event.target.value)
  }

  handleFormSubmit = async (event: any): Promise<void> => {
    this.setState({editing: false})
    this.props.handleSubmit(event.target.title.value)
  }

  render(): React.ReactNode {
    const {editing} = this.state
    return (
        <Grid item onBlur={this.handleOnBlur}>
          { editing ?
            <form onSubmit={this.handleFormSubmit}>
              <Input name='title' value={this.props.text} onChange={this.handleInput} autoFocus/>
            </form>
            :
            <Tooltip title={this.props.toolTipMessage} placement='top-start' enterDelay={500} disableHoverListener={!this.props.toolTipMessage}>
              <span className='inline clickable' onClick={this.startEditing} >
                {this.props.text}
              </span>
            </Tooltip>
          }
        </Grid>
    )
  }
}

export default InLineEditableText
