import {Grid, IconButton} from '@material-ui/core'
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FileTemplateActionConfiguration} from 'src/models/pipeline/FileTemplateActionConfiguration'
import {ActionConfiguration} from 'src/models/pipeline/ActionConfiguration'
import {cloneDeep} from 'lodash'
import FTACVar from 'src/models/pipeline/FTACVar'
import {faPlusCircle, faMinusCircle} from '@fortawesome/free-solid-svg-icons'
import {OutlinedTextField} from '../../../../../common/OutlinedTextField'
import '../Pipelines.scss'

export interface IFileTemplateActionConfigEditorProps {
  actionConfiguration: FileTemplateActionConfiguration
  onActionConfigChanged: (actionConfiguration: ActionConfiguration) => void
}

export class FileTemplateActionConfigEditor extends React.Component<IFileTemplateActionConfigEditorProps> {

  handleAddKeyValueItem = (): void => {
    const copiedFTAC: FileTemplateActionConfiguration = cloneDeep(this.props.actionConfiguration)
    copiedFTAC.templateVariables.set('', '')
    this.props.onActionConfigChanged(copiedFTAC)
  }

  handleDeleteKeyValueItem = (ftac: FTACVar): void => {
    const copiedFTAC: FileTemplateActionConfiguration = cloneDeep(this.props.actionConfiguration)
    copiedFTAC.templateVariables.delete(ftac.key)
    this.props.onActionConfigChanged(copiedFTAC)
  }

  onKeyValueChange = (keyValueItems: FTACVar[], index: number, event: any): void => {
    const copiedFTAC: FileTemplateActionConfiguration = cloneDeep(this.props.actionConfiguration)
    if (event.target.name === 'key') {
      keyValueItems[index].key = event.target.value
    } else {
      keyValueItems[index].value = event.target.value
    }

    copiedFTAC.templateVariables = new Map()

    keyValueItems.forEach((keyValueItem: FTACVar) => {
        copiedFTAC.templateVariables.set(keyValueItem.key, keyValueItem.value)
    })

    this.props.onActionConfigChanged(copiedFTAC)
  }

  onFilePathChange = (event: any): void => {
    const copiedFTAC: FileTemplateActionConfiguration = cloneDeep(this.props.actionConfiguration)
    copiedFTAC.templatePath = event.target.value
    this.props.onActionConfigChanged(copiedFTAC)
  }

  getKeyValueRows = (): React.ReactNode => {
    const keyValueItems: FTACVar[] = []

    this.props.actionConfiguration.templateVariables.forEach((value: string, key: string) => {
      keyValueItems.push(new FTACVar({key, value}))
    })
    return keyValueItems.map((keyValueItem: FTACVar, index: number) => {
      return (
        <Grid container key={index} spacing={2}>
          <Grid item xs={5}>
            <OutlinedTextField name='key' label='Search Key' id='search-key' value={keyValueItem.key}
                               handleChange={this.onKeyValueChange.bind(this, keyValueItems, index)}/>
          </Grid>
          <Grid item xs={5}>
            <OutlinedTextField name='value' label='Replace Value' id='replace-value' value={keyValueItem.value}
                               handleChange={this.onKeyValueChange.bind(this, keyValueItems, index)}/>
          </Grid>
          <Grid item xs={2} id='action-config-add-remove-buttons' className='add-remove-buttons'>
            {index !== 0 &&
            <IconButton onClick={this.handleDeleteKeyValueItem.bind(this, keyValueItem)}>
              <FontAwesomeIcon icon={faMinusCircle} size='lg' className={'clickable'}/>
            </IconButton>
            }
            {index === keyValueItems.length - 1 &&
            <IconButton disabled={!this.props.actionConfiguration.hasFullyPopulatedMapFields()}
                        title='Create New Find and Replace'
                        onClick={this.handleAddKeyValueItem}>
              <FontAwesomeIcon icon={faPlusCircle} size='lg' className={'clickable'}/>
            </IconButton>
            }
          </Grid>
        </Grid>
      )
    })
  }

  render(): React.ReactNode {
    const {actionConfiguration} = this.props

    return (
      <Grid>
        <Grid container direction='column'>
          <Grid item>
            <OutlinedTextField name='name' label='File Path' id='file-path' value={actionConfiguration.templatePath}
                               handleChange={this.onFilePathChange} message='Path must be absolute'/>
          </Grid>
          <Grid item>
            <h3>Template Variables</h3>
          </Grid>
          <Grid item>
            {this.getKeyValueRows()}
          </Grid>
        </Grid>
        <br/>
      </Grid>
    )
  }
}

export default FileTemplateActionConfigEditor
