import Project from '../models/Project'
import ProjectEnvironment from '../models/ProjectEnvironment'
import PipelineService from './PipelineService'
import ProjectEvent from '../models/pipeline/ProjectEvent'

class ProjectEventService {
  async loadLatestProjectEvents(project: Project): Promise<ProjectEvent[]> {
    const latestProjectEventPromises: Array<Promise<ProjectEvent>> = project.projectEnvironments.reduce(
      (promises: Array<Promise<ProjectEvent>>, projEnv: ProjectEnvironment): Array<Promise<ProjectEvent>> => {
        if (project.hasCode) {
          promises.push(PipelineService.getMostRecentCodeDeployment(project.id, projEnv.environmentId).catch(() => null))
          promises.push(PipelineService.getMostRecentCodeMerge(project.id, projEnv.environmentId).catch(() => null))
          promises.push(PipelineService.getMostRecentCodePromotion(project.id, projEnv.environmentId).catch(() => null))
        }

        if (project.hasAssets) {
          promises.push(PipelineService.getMostRecentAssetMerge(project.id, projEnv.environmentId).catch(() => null))
        }

        if (project.hasDatabases) {
          promises.push(PipelineService.getMostRecentDatabaseCopy(project.id, projEnv.environmentId).catch(() => null))
          promises.push(PipelineService.getMostRecentDatabaseSnapshot(project.id, projEnv.environmentId).catch(() => null))
          promises.push(PipelineService.getMostRecentDatabaseImport(project.id, projEnv.environmentId).catch(() => null))
        }

        return promises
      }, [])


    return (await Promise.all(latestProjectEventPromises)).filter((projectEvent: ProjectEvent) => !!projectEvent)
  }
}

export default new ProjectEventService()
export {ProjectEventService}
