import {ACTION_TYPE} from './Action'
import 'reflect-metadata'
import {assign} from 'lodash'
import {ActionConfiguration} from './ActionConfiguration'

class CodeMergeActionConfiguration extends ActionConfiguration {
  tag: string

  constructor(props?: Partial<CodeMergeActionConfiguration>) {
    super()
    this.actionType = ACTION_TYPE.CODE_MERGE

    props = props || {}

    const defaultProps: Partial<CodeMergeActionConfiguration> = {
      tag: undefined
    }
    assign(this, defaultProps, props)
  }

  isValid(): boolean {
    return false
  }
}

export default CodeMergeActionConfiguration
