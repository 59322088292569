import { DataProcessIO, DataProcessorError, ProjectValidationError, createProcessIO } from 'src/helpers/shared'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import Project from 'src/models/Project'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import { CodeProcessContext, createProjectEnvironmentWithCodeDefaults } from '../project/environment/code/env-code-processor'
import { DatabaseProcessContext } from '../project/environment/database/env-database-processor'
import { resolveProjectName, resolveDeploymentEnvironmentById } from '../shared'
import { LinkProcessContext } from '../project/environment/link/env-link-processor'
import ProjectType from 'src/models/ProjectType'
import { CreateProjectAssetProcessContext, createProjectEnvironmentWithAssetDefaultsFromProjectTypeConfig } from './asset/create-project-env-asset-processor'
import { ProjectEnvironmentProcessConfig } from '../project/environment/environment-processor'
import { createProjectEnvironmentWithDefaultSymlinksForCreateProjectWorkflow } from './symlink/create-project-env-symlink-processor'
import { createProjectEnvironmentWithDatabaseDefaultsForCreateProjectWorkflow } from './database/create-project-env-database-processor'

export interface CreateProjectProcessContext {
  project: Project
  deploymentEnvironments: DeploymentEnvironment[]
  config: ProjectEnvironmentProcessConfig
  projectTypeConfig?: ProjectType
}

export type ProjectProcessIO = DataProcessIO<Project, DataProcessorError>

export type EnvironmentProcessIO = DataProcessIO<ProjectEnvironment, DataProcessorError>

export function createProjectEnvironmentDefaultsForUninitializedProject(
  env: ProjectEnvironment,
  projectName: string,
  deploymentEnvironments: DeploymentEnvironment[],
  inputProject: Project,
  config: ProjectEnvironmentProcessConfig,
  index: number,
  projectTypeConfig?: ProjectType,
): EnvironmentProcessIO {
  const { code, asset, database, links } = config
  let output: DataProcessIO<ProjectEnvironment, DataProcessorError> = createProcessIO(env, [])
  const deploymentEnvironment = resolveDeploymentEnvironmentById(env.environmentId, deploymentEnvironments)
  const prodEnv = deploymentEnvironments.find((env) => {
    return env.isProd
  })
  if (code) {
    const codeInput = output
    const codeContext: CodeProcessContext = {
      index,
      projectName,
      deploymentEnvironment,
    }
    output = createProjectEnvironmentWithCodeDefaults(codeInput, codeContext)
  }
  if (asset) {
    const assetInput = output
    if (prodEnv) {
      const assetContext: CreateProjectAssetProcessContext = {
        index,
        projectName,
        deploymentEnvironment,
        projectTypeConfig,
      }
      output = createProjectEnvironmentWithAssetDefaultsFromProjectTypeConfig(assetInput, assetContext)
    }
  }
  if (database) {
    const databaseInput = output
    if (prodEnv) {
      const prodProjectEnvironment = inputProject.projectEnvironments.find((env) => {
        return env.environmentId === prodEnv.id
      })
      const prodDatabaseAssociationIds: number[] = [] 
      prodProjectEnvironment?.projectEnvironmentDatabases?.databases?.forEach((database) => {
        prodDatabaseAssociationIds.push(database.associationId)
      })
      
      const databaseContext: DatabaseProcessContext = {
        index,
        projectName,
        deploymentEnvironment,
        associationIds: prodDatabaseAssociationIds,
      }
      output = createProjectEnvironmentWithDatabaseDefaultsForCreateProjectWorkflow(databaseInput, databaseContext)
    }
  }
  if (links) {
    const linksInput = output
    const linksContext: LinkProcessContext = {
      index,
      projectName,
      deploymentEnvironment,
    }
    output = createProjectEnvironmentWithDefaultSymlinksForCreateProjectWorkflow(linksInput, linksContext)
  }
  return output
}

export function transformProjectEnvironmentsForCreateProjectWorkflow(
  input: ProjectProcessIO,
  context: CreateProjectProcessContext,
): ProjectProcessIO {
  const project = input.value
  if (project) {
    const projectName = resolveProjectName(project)
    const { deploymentEnvironments, config, projectTypeConfig } = context
    const updatedEnvironments = project.projectEnvironments.map((env: ProjectEnvironment, index: number) => {
      const environmentOutput = createProjectEnvironmentDefaultsForUninitializedProject(
        env,
        projectName,
        deploymentEnvironments,
        project,
        config,
        index,
        projectTypeConfig,
      )
      return environmentOutput.value
    })
    project.projectEnvironments = updatedEnvironments
    return createProcessIO(project, input.errors)
  }
  return createProcessIO(null, [...input.errors, ProjectValidationError.UNDEFINED_FUNC_INPUT])
}
