import { makeStyles, Theme, createStyles } from "@material-ui/core"

const borderRadius = '4px'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[100],
      borderRadius,
      minHeight: '340px',
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
    },
  }),
)