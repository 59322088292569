import { assign } from 'lodash'
import {Expose} from "class-transformer";

export default class AssetGroup {
  assetGroupId: string
  path: string
  title: string
  associationId: number
  symLinkId: string

  constructor(props?: Partial<AssetGroup>) {
    props = props || {}
    const defaultProps: Partial<AssetGroup> = {
      assetGroupId: undefined,
      path: undefined,
      title: undefined,
      associationId: undefined,
      symLinkId: undefined,
    }
    assign(this, defaultProps, props)
  }
}
