import React from 'react'
import {FormControl, FormHelperText, FormLabel, TextField, Typography} from "@material-ui/core"
import MessageService from "../../services/MessageService"

interface IOutlinedTextFieldProps {
    label?: string
    message?: string
    error?: boolean
    required?: boolean
    id?: string
    name?: string
    value?: string
    multiLine?: boolean
    handleChange?: (event: any) => void
    placeholder?: string
}

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            width: `100%`,
            marginTop: '3px'
        },
        helperText: {
            position: 'relative',
            left: '3px',
            top: '-8px'
        }
    }),
)

export const OutlinedTextField: React.FC<IOutlinedTextFieldProps> = ({
     label,
     message = '',
     error = false,
     required = false,
     id,
     name,
     value,
     multiLine= false,
     placeholder,
     handleChange
     }) => {
    const styles = useStyles()

    return (
        <FormControl error={error} className='form-field'>
            <FormLabel><Typography>{MessageService.get(label)}</Typography></FormLabel>
            <TextField
                variant="outlined"
                className={styles.textField}
                required={required}
                id={id}
                name={name}
                style={{marginTop: '3px'}}
                margin="normal"
                placeholder={placeholder ? placeholder : "Enter " + label}
                value={value}
                multiline={multiLine}
                onChange={handleChange}/>
            {message && <FormHelperText className={styles.helperText}>{message}</FormHelperText>}
        </FormControl>
    )
}

