import initialState from '../initialState'
import ActionTypes from '../actions'
import {IDeploymentTargetsState} from '../IStoreState'

export default function deploymentTargetsReducer(state: IDeploymentTargetsState = initialState.deploymentTargets, action: any): IDeploymentTargetsState {
  switch (action.type) {
    case ActionTypes.DEPLOYMENT_TARGETS_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ActionTypes.DEPLOYMENT_TARGETS_LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.deploymentTargets,
        loading: false,
        error: null
      }
    case ActionTypes.DEPLOYMENT_TARGETS_LOAD_FAIL:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error
      }
    case ActionTypes.DEPLOYMENT_TARGET_DELETE:
      return {
        ...state,
        data: [],
        loading: false,
        error: null
      }
    case ActionTypes.DEPLOYMENT_TARGET_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case ActionTypes.DEPLOYMENT_TARGET_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}
