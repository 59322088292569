import { makeStyles, Theme, createStyles } from "@material-ui/core"

const borderRadius = '4px'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            boxShadow:'0px 2px 4px -1px #dde3eb, 0px 4px 5px 0px #dde3eb, 0px 1px 10px 0px #dde3eb',
            borderRadius,
            minHeight: '340px',
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
        },
    }),
)
