import React from 'react'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import {cloneDeep} from 'lodash'
import {Grid} from '@material-ui/core'
import PipelineTrigger from 'src/models/pipeline/PipelineTrigger'
import PipelineTriggerGroup from 'src/models/pipeline/PipelineTriggerGroup'
import PipelineTemplateEditorTrigger from './PipelineTemplateEditorTrigger'
import IStoreState from 'src/store/IStoreState'
import './PipelineTemplateEditorTriggerGroup.scss'

export interface IPipelineTemplateEditorTriggerGroupProps {
  pipelineTriggerGroup: PipelineTriggerGroup,
  handleTriggerGroupChange: (oldTriggerGroup: PipelineTriggerGroup, newTriggerGroup: PipelineTriggerGroup) => void
}

export interface IPipelineTemplateEditorTriggerGroupState {
}


export class PipelineTemplateEditorTriggerGroup extends React.Component<IPipelineTemplateEditorTriggerGroupProps, IPipelineTemplateEditorTriggerGroupState> {
  constructor(props: IPipelineTemplateEditorTriggerGroupProps) {
    super(props)
    this.state = {}
  }

  handleTriggerChange: (oldTrigger: PipelineTrigger, newTrigger: PipelineTrigger) => void = (oldTrigger: PipelineTrigger, newTrigger: PipelineTrigger) => {
    const i: number = this.props.pipelineTriggerGroup.triggers.indexOf(oldTrigger)
    const duplicateTriggerGroup: PipelineTriggerGroup = cloneDeep(this.props.pipelineTriggerGroup)
    duplicateTriggerGroup.triggers[i] = i > -1 ? newTrigger : oldTrigger
    this.props.handleTriggerGroupChange(this.props.pipelineTriggerGroup, duplicateTriggerGroup)
  }

  handleAddTrigger: () => void = () => {
    const duplicateTriggerGroup: PipelineTriggerGroup = cloneDeep(this.props.pipelineTriggerGroup)
    duplicateTriggerGroup.triggers.push(new PipelineTrigger({}))
    this.props.handleTriggerGroupChange(this.props.pipelineTriggerGroup, duplicateTriggerGroup)
  }

  handleRemoveTrigger: (pipelineTrigger: PipelineTrigger) => void = (pipelineTrigger: PipelineTrigger) => {
    const i: number = this.props.pipelineTriggerGroup.triggers.indexOf(pipelineTrigger)
    const duplicateTriggerGroup: PipelineTriggerGroup = cloneDeep(this.props.pipelineTriggerGroup)
    duplicateTriggerGroup.triggers.splice(i, 1)
    this.props.handleTriggerGroupChange(this.props.pipelineTriggerGroup, duplicateTriggerGroup)
  }

  canAddTrigger: (index: number) => boolean = (index: number) => {
    const numberOfTriggers: number = this.props.pipelineTriggerGroup.triggers.length - 1
    const allTriggersAreValid: boolean = this.props.pipelineTriggerGroup.triggers.reduce((previousTriggerValue: boolean, currentTrigger: PipelineTrigger) => previousTriggerValue && this.triggerIsValid(currentTrigger), true)
    return index === (numberOfTriggers) && allTriggersAreValid
  }

  triggerIsValid = (trigger: PipelineTrigger): boolean => {
    return !!trigger.type && !!trigger.value
  }

  render = (): React.ReactNode => {
    const {} = this.state
    const {pipelineTriggerGroup} = this.props
    return (
      <Grid className='triggerGroupCard' container spacing={2}>
        {pipelineTriggerGroup.triggers.map((trigger: PipelineTrigger, index: number) => (
          <PipelineTemplateEditorTrigger
            key={index}
            trigger={trigger}
            handleTriggerChange={this.handleTriggerChange}
            handleAddTrigger={this.canAddTrigger(index) ? this.handleAddTrigger : null}
            handleRemoveTrigger={this.handleRemoveTrigger}
          />
        ))}
      </Grid>
    )
  }
}


const mapStateToProps: any = (state: IStoreState, ownProps: IPipelineTemplateEditorTriggerGroupState): any => ({})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IPipelineTemplateEditorTriggerGroupProps): any => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PipelineTemplateEditorTriggerGroup)

