import React from 'react'
import PropTypes from 'prop-types'
import './DeploymentTrack.scss'
import {Button, Tooltip} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class NoPermissionButton extends React.Component {
  render() {
    const {icon, text} = this.props

    return (
      <Tooltip title="You don't have permission to take this action" placement='top' enterDelay={50}>
        <span>
          <Button color='primary' disabled={true}>
            <FontAwesomeIcon icon={icon} className='buttonIcon'/> {text}
          </Button>
        </span>
      </Tooltip>
    )
  }
}

NoPermissionButton.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
}

export default NoPermissionButton
