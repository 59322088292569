import {assign} from 'lodash'

export default class ProjectEnvironmentCodeWebroot {
  dir1: string
  cascade: string
  env: string
  site: string
  dir2: string

  constructor(props?: Partial<ProjectEnvironmentCodeWebroot>) {
    props = props || {}
    const defaultProps: Partial<ProjectEnvironmentCodeWebroot> = {
      dir1: 'var/www',
      cascade: 'cascade',
      env: undefined,
      site: undefined,
      dir2: 'htdocs'
    }

    assign(this, defaultProps, props)
  }

  get fullWebroot(): string {
    return `/${this.dir1}/${this.cascade}/${this.env}/${this.site}/${this.dir2}`
  }
}
