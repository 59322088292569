import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import keycloakService from '../../../services/KeycloakService'
import {
  ListItem,
  ListItemText,
  Divider,
  List,
  Drawer,
} from '@material-ui/core'
import { UISref } from '@uirouter/react'
import { menuEntries } from './header.config'
import { linkReferences } from '../../../config/links.config'
import User from '../../../models/User'
import '../../../styles/base/header.scss'

interface IStyles { [key: string]: string }

const iconStyles: IStyles = { cursor: 'pointer', color: 'white', marginLeft: '10px' }

export interface IDropdownProps {
  currentUser: User
}

export interface IDropdownState {
  isOpen: boolean
}

export class Dropdown extends Component<IDropdownProps, IDropdownState> {
  constructor(props: IDropdownProps) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  onLogOut = (): void => {
    keycloakService.logout()
  }

  goToAccount = (): void => {
    // @ts-ignore
    window.open(keycloakService.idTokenParsed.iss + '/account')
    this.setState({ isOpen: false })
  }

  toggleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render(): React.ReactNode {
    const { currentUser } = this.props
    const { isOpen } = this.state
    const { PROJECTS, ADMIN, USERS, ACCOUNT, SUPPORT, LOGOUT } = menuEntries
    const { SUPPORTLINK } = linkReferences
    const showProjectLink: boolean = currentUser.canViewProjects()
    const showAdministrationLink: boolean = currentUser.canManageDeploymentTarget() || currentUser.canManageUser()
    const showUsersLink: boolean = currentUser.canManageUser()
    return (
      <div className='dropdown-menu'>
        <React.Fragment>
          <div className='dropdown-menu__menu-icon' onClick={this.toggleMenu}>
            <FontAwesomeIcon
              icon={faBars}
              size='lg'
              style={iconStyles}
            />
          </div>
          <Drawer anchor='top' open={isOpen} onClose={this.toggleMenu}>
            <div role='presentation' onClick={this.toggleMenu}>
              <List>
                {showProjectLink &&
                  <UISref to={PROJECTS.uiRouterState}>
                    <ListItem button>
                      <ListItemText primary={PROJECTS.title} />
                    </ListItem>
                  </UISref>
                }
                {showAdministrationLink &&
                  <UISref to={ADMIN.uiRouterState} params={{ currentUser }}>
                    <ListItem button>
                      <ListItemText primary={ADMIN.title} />
                    </ListItem>
                  </UISref>
                }
                {showUsersLink &&
                  <UISref to={USERS.uiRouterState} params={{ currentUser, pathParam: USERS.pathParam }}>
                    <ListItem button>
                      <ListItemText primary={USERS.title} />
                    </ListItem>
                  </UISref>
                }
              </List>
              <Divider />
              <List>
                <ListItem onClick={this.goToAccount}>
                  <ListItemText primary={ACCOUNT.title} />
                </ListItem>
                <ListItem
                  button
                  href={SUPPORTLINK.href}
                  component='a'
                  target='_blank'
                >
                  <ListItemText primary={SUPPORT.title} />
                </ListItem>
                <ListItem button onClick={this.onLogOut}>
                  <ListItemText primary={LOGOUT.title} />
                </ListItem>
              </List>
            </div>
          </Drawer>
        </React.Fragment>
      </div>
    )
  }
}

// @ts-ignore
Dropdown.propTypes = {
  currentUser: PropTypes.instanceOf(User).isRequired,
}
