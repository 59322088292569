import React from 'react'
import {Grid, TextField} from '@material-ui/core'
import RetryLoading from '../../common/RetryLoading'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSlidersH} from '@fortawesome/free-solid-svg-icons'
import BundleService from '../../../services/BundleService'
import Nbsp from '../../common/Nbsp'
import SnackbarService, {SNACKBAR_TYPES} from '../../../services/SnackbarService'
import {debounce} from 'lodash'
import Spinner from '../../common/Spinner'

class SubscriptionSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bundle: null,
      loading: true,
      error: false,
    }
  }

  componentWillMount() {
    this.loadBundle()
  }

  async loadBundle() {
    try {
      const bundle = await BundleService.getBundle()
      this.setState({bundle, loading: false, error: false})
    } catch (error) {
      this.setState({error: true, loading: false})
    }
  }

  reloadData = () => {
    this.setState({error: false, loading: true})
    this.loadBundle()
  }

  handleChange = (event) => {
    const {bundle} = this.state
    bundle[event.target.id] = event.target.value
    // maxDeploymentTargets must be an int greater than 0
    if (event.target.id === 'maxDeploymentTargets' && (event.target.value < 1 || event.target.value % 1 !== 0)) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Invalid number of Deployment Targets!')
    } else {
      this.setState({bundle})
      this.updateBundle(bundle)
    }
  }

  updateBundle = debounce( async (updatedBundle) => {
    try {
      await BundleService.updateBundle(updatedBundle)
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Updated subscription')
    } catch (e) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to update subscription!')
    }
  }, 500)

  render() {
    return (
      <Grid container>
        <Grid item sm={12}>
          <Grid container alignItems='center'>
            <Grid item sm={6}>
              <Grid container direction='row' alignItems='center' spacing={3}>
                <Grid item>
                  <FontAwesomeIcon icon={faSlidersH} style={{transform: 'scale(1.6)'}} />
                </Grid>
                <Grid item>
                  <h2>Subscription</h2>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          { this.state.loading && <Grid container justify='center'><Spinner large/></Grid> }
          { this.state.error &&
            <RetryLoading message='Could not load subscription information.' reloadData={this.reloadData}/>
          }
          { !this.state.loading && !this.state.error &&
            <Grid container spacing={16}>
              <Grid item xs={6}>
                Maximum Number of Deployment Targets: <Nbsp/>
                <TextField
                  id="maxDeploymentTargets"
                  value={this.state.bundle.maxDeploymentTargets}
                  onChange={this.handleChange}
                  className="numberInput"
                  type="number"
                  inputProps={{min: '1'}}
                />
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    )
  }
}

export default SubscriptionSection
