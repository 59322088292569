import initialState from '../initialState'
import ActionTypes from '../actions'
import {IPipelinesState} from '../IStoreState'

export default function pipelinesReducer(state: IPipelinesState = initialState.pipelines, action: any): IPipelinesState {
  switch (action.type) {
    case ActionTypes.EXECUTE_PIPELINE_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ActionTypes.EXECUTE_PIPELINE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case ActionTypes.EXECUTE_PIPELINE_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case ActionTypes.LATEST_PIPELINES_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ActionTypes.LATEST_PIPELINES_LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.pipelines,
        loading: false,
        error: null
      }
    case ActionTypes.LATEST_PIPELINES_LOAD_FAIL:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.error
      }
    case ActionTypes.CANCEL_PIPELINE_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ActionTypes.CANCEL_PIPELINE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case ActionTypes.CANCEL_PIPELINE_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case ActionTypes.EXECUTE_PIPELINE_CLEAR:
      return {
        ...state,
        data: undefined,
        error: false
      }
    default:
      return state
  }
}
