export * from './projectActions'

enum ActionTypes {
  SIGN_IN_SUCCESSFUL = 'SIGN_IN_SUCCESSFUL',

  // Pipeline Template Action Types
  PIPELINE_TEMPLATES_LOAD = 'PIPELINE_TEMPLATES_LOAD',
  PIPELINE_TEMPLATES_LOAD_SUCCESS = 'PIPELINE_TEMPLATES_LOAD_SUCCESS',
  PIPELINE_TEMPLATES_LOAD_FAIL = 'PIPELINE_TEMPLATES_LOAD_FAIL',
  PIPELINE_TEMPLATE_SAVE = 'PIPELINE_TEMPLATE_SAVE',
  PIPELINE_TEMPLATE_SAVE_SUCCESS = 'PIPELINE_TEMPLATE_SAVE_SUCCESS',
  PIPELINE_TEMPLATE_SAVE_FAIL = 'PIPELINE_TEMPLATE_SAVE_FAIL',
  PIPELINE_TEMPLATE_DELETE = 'PIPELINE_TEMPLATE_DELETE',
  PIPELINE_TEMPLATE_DELETE_SUCCESS = 'PIPELINE_TEMPLATE_DELETE_SUCCESS',
  PIPELINE_TEMPLATE_DELETE_FAIL = 'PIPELINE_TEMPLATE_DELETE_FAIL',
  PIPELINE_TEMPLATE_CLEAR = 'PIPELINE_TEMPLATE_CLEAR',

  // Action Configuration Action Types
  ACTION_CONFIGURATIONS_LOAD = 'ACTION_CONFIGURATIONS_LOAD',
  ACTION_CONFIGURATIONS_LOAD_SUCCESS = 'ACTION_CONFIGURATIONS_LOAD_SUCCESS',
  ACTION_CONFIGURATIONS_LOAD_FAIL = 'ACTION_CONFIGURATIONS_LOAD_FAIL',

  // Project Wizard Action Types
  CLOSE_PROJECT_WIZARD = 'CLOSE_PROJECT_WIZARD',
  OPEN_PROJECT_WIZARD = 'OPEN_PROJECT_WIZARD',
  UPDATE_PROJECT_WIZARD_FIELD = 'UPDATE_PROJECT_WIZARD_FIELD',
  UPDATE_PROJECT_WIZARD_TYPE = 'UPDATE_PROJECT_WIZARD_TYPE',
  PROJECT_WIZARD_STEP_CHANGE = 'PROJECT_WIZARD_STEP_CHANGE',
  UPDATE_PERSISTED_PROJECT = 'UPDATE_PERSISTED_PROJECT',
  PROJECT_WIZARD_RESET_DEFAULT = 'PROJECT_WIZARD_RESET_DEFAULT',
  PROJECT_WIZARD_UPDATE_PROPERTY = 'PROJECT_WIZARD_UPDATE_PROPERTY',

  // Deployment Environment Action Types
  DEPLOYMENT_ENVIRONMENTS_LOAD = 'DEPLOYMENT_ENVIRONMENTS_LOAD',
  DEPLOYMENT_ENVIRONMENTS_LOAD_SUCCESS = 'DEPLOYMENT_ENVIRONMENTS_LOAD_SUCCESS',
  DEPLOYMENT_ENVIRONMENTS_LOAD_FAIL = 'DEPLOYMENT_ENVIRONMENTS_LOAD_FAIL',
  DEPLOYMENT_ENVIRONMENT_SAVE = 'DEPLOYMENT_ENVIRONMENT_SAVE',
  DEPLOYMENT_ENVIRONMENT_SAVE_SUCCESS = 'DEPLOYMENT_ENVIRONMENT_SAVE_SUCCESS',
  DEPLOYMENT_ENVIRONMENT_SAVE_FAIL = 'DEPLOYMENT_ENVIRONMENT_SAVE_FAIL',
  DEPLOYMENT_ENVIRONMENT_UPDATE = 'DEPLOYMENT_ENVIRONMENT_UPDATE',
  DEPLOYMENT_ENVIRONMENT_UPDATE_SUCCESS = 'DEPLOYMENT_ENVIRONMENT_UPDATE_SUCCESS',
  DEPLOYMENT_ENVIRONMENT_UPDATE_FAIL = 'DEPLOYMENT_ENVIRONMENT_UPDATE_FAIL',
  DEPLOYMENT_ENVIRONMENT_DELETE = 'DEPLOYMENT_ENVIRONMENT_DELETE',
  DEPLOYMENT_ENVIRONMENT_DELETE_SUCCESS = 'DEPLOYMENT_ENVIRONMENT_DELETE_SUCCESS',
  DEPLOYMENT_ENVIRONMENT_DELETE_FAIL = 'DEPLOYMENT_ENVIRONMENT_DELETE_FAIL',

  // Deployment Target Action Types
  DEPLOYMENT_TARGETS_LOAD = 'DEPLOYMENT_TARGETS_LOAD',
  DEPLOYMENT_TARGETS_LOAD_SUCCESS = 'DEPLOYMENT_TARGETS_LOAD_SUCCESS',
  DEPLOYMENT_TARGETS_LOAD_FAIL = 'DEPLOYMENT_TARGETS_LOAD_FAIL',
  DEPLOYMENT_TARGET_DELETE = 'DEPLOYMENT_TARGET_DELETE',
  DEPLOYMENT_TARGET_DELETE_SUCCESS = 'DEPLOYMENT_TARGET_DELETE_SUCCESS',
  DEPLOYMENT_TARGET_DELETE_FAIL = 'DEPLOYMENT_TARGET_DELETE_FAIL',

  // Selected Environment Action Types
  SELECTED_ENVIRONMENT_LOAD = 'SELECTED_ENVIRONMENT_LOAD',
  SELECTED_ENVIRONMENT_LOAD_SUCCESS = 'SELECTED_ENVIRONMENT_LOAD_SUCCESS',
  SELECTED_ENVIRONMENT_LOAD_FAIL = 'SELECTED_ENVIRONMENT_LOAD_FAIL',
  SELECTED_ENVIRONMENT_GROUPS_LOAD = 'SELECTED_ENVIRONMENT_GROUPS_LOAD',
  SELECTED_ENVIRONMENT_GROUPS_LOAD_SUCCESS = 'SELECTED_ENVIRONMENT_GROUPS_LOAD_SUCCESS',
  SELECTED_ENVIRONMENT_GROUPS_LOAD_FAIL = 'SELECTED_ENVIRONMENT_GROUPS_LOAD_FAIL',

  // Persisted Project Action Types
  PERSISTED_PROJECT_SUCCESS = 'PERSISTED_PROJECT_SUCCESS',

  // Deployment Target Group Action Types
  DEPLOYMENT_TARGET_GROUP_CREATE = 'DEPLOYMENT_TARGET_GROUP_CREATE',
  DEPLOYMENT_TARGET_GROUP_CREATE_SUCCESS = 'DEPLOYMENT_TARGET_GROUP_CREATE_SUCCESS',
  DEPLOYMENT_TARGET_GROUP_CREATE_FAIL = 'DEPLOYMENT_TARGET_GROUP_CREATE_FAIL',
  DEPLOYMENT_TARGET_GROUP_UPDATE = 'DEPLOYMENT_TARGET_GROUP_UPDATE',
  DEPLOYMENT_TARGET_GROUP_UPDATE_SUCCESS = 'DEPLOYMENT_TARGET_GROUP_UPDATE_SUCCESS',
  DEPLOYMENT_TARGET_GROUP_UPDATE_FAIL = 'DEPLOYMENT_TARGET_GROUP_UPDATE_FAIL',
  DEPLOYMENT_TARGET_GROUP_DELETE = 'DEPLOYMENT_TARGET_GROUP_DELETE',
  DEPLOYMENT_TARGET_GROUP_DELETE_SUCCESS = 'DEPLOYMENT_TARGET_GROUP_DELETE_SUCCESS',
  DEPLOYMENT_TARGET_GROUP_DELETE_FAIL = 'DEPLOYMENT_TARGET_GROUP_DELETE_FAIL',

  PROJECTS_LOAD = 'PROJECTS_LOAD',
  PROJECTS_LOAD_SUCCESS = 'PROJECTS_LOAD_SUCCESS',
  PROJECTS_LOAD_FAIL = 'PROJECTS_LOAD_FAIL',

  DISABLE_PROJECT = 'DISABLE_PROJECT',
  DISABLE_PROJECT_SUCCESS = 'DISABLE_PROJECT_SUCCESS',
  DISABLE_PROJECT_FAIL = 'DISABLE_PROJECT_FAIL',

  ENABLE_PROJECT = 'ENABLE_PROJECT',
  ENABLE_PROJECT_SUCCESS = 'ENABLE_PROJECT_SUCCESS',
  ENABLE_PROJECT_FAIL = 'ENABLE_PROJECT_FAIL',

  SELECTED_PROJECT_LOAD = 'SELECTED_PROJECT_LOAD',
  SELECTED_PROJECT_SUCCESS = 'SELECTED_PROJECT_SUCCESS',
  SELECTED_PROJECT_FAIL = 'SELECTED_PROJECT_FAIL',
  SELECTED_PROJECT_RESET_DEFAULT = 'SELECTED_PROJECT_RESET_DEFAULT',

  SELECTED_PROJECT_FIELD_UPDATE = 'SELECTED_PROJECT_FIELD_UPDATE',
  SELECTED_PROJECT_SAVE_SUCCESS = 'SELECTED_PROJECT_SAVE_SUCCESS',
  SELECTED_PROJECT_SAVE_FAIL = 'SELECTED_PROJECT_SAVE_FAIL',
  SELECTED_PROJECT_OPEN_BRANCH_CONFIRMATION = 'SELECTED_PROJECT_OPEN_BRANCH_CONFIRMATION',
  SELECTED_PROJECT_CLOSE_BRANCH_CONFIRMATION = 'SELECTED_PROJECT_CLOSE_BRANCH_CONFIRMATION',

  SELECTED_PROJECT_EVENTS_LOAD = 'SELECTED_PROJECT_EVENTS_LOAD',
  SELECTED_PROJECT_EVENTS_LOAD_SUCCESS = 'SELECTED_PROJECT_EVENTS_LOAD_SUCCESS',
  SELECTED_PROJECT_EVENTS_LOAD_FAIL = 'SELECTED_PROJECT_EVENTS_LOAD_FAIL',

  PROJECT_SAVE = 'PROJECT_SAVE',
  PROJECT_SAVE_SUCCESS = 'PROJECT_SAVE_SUCCESS',
  PROJECT_SAVE_FAIL = 'PROJECT_SAVE_FAIL',

  PROJECT_TYPES_LOAD = 'PROJECT_TYPES_LOAD',
  PROJECT_TYPES_LOAD_SUCCESS = 'PROJECT_TYPES_LOAD_SUCCESS',
  PROJECT_TYPES_LOAD_FAIL = 'PROJECT_TYPES_LOAD_FAIL',

  PROJECT_DEPLOY_INITIAL = 'PROJECT_DEPLOY_INITIAL',
  PROJECT_DEPLOY_INITIAL_SUCCESS = 'PROJECT_DEPLOY_INITIAL_SUCCESS',
  PROJECT_DEPLOY_INITIAL_FAIL = 'PROJECT_DEPLOY_INITIAL_FAIL',

  PROJECT_WIZARD_OPEN_BRANCH_CONFIRMATION = 'PROJECT_WIZARD_OPEN_BRANCH_CONFIRMATION',
  PROJECT_WIZARD_CLOSE_BRANCH_CONFIRMATION = 'PROJECT_WIZARD_CLOSE_BRANCH_CONFIRMATION',

  BUNDLE_LOAD = 'BUNDLE_LOAD',
  BUNDLE_LOAD_SUCCESS = 'BUNDLE_LOAD_SUCCESS',
  BUNDLE_LOAD_FAIL = 'BUNDLE_LOAD_FAIL',

  // Pipeline Template Editor
  PIPELINE_TEMPLATE_EDITOR_OPEN = 'PIPELINE_TEMPLATE_EDITOR_OPEN',
  PIPELINE_TEMPLATE_EDITOR_CLOSE = 'PIPELINE_TEMPLATE_EDITOR_CLOSE',
  UPDATE_PIPELINE_TEMPLATE_EDITOR_FIELD = 'UPDATE_PIPELINE_TEMPLATE_EDITOR_FIELD',

  // ProjectHistory
  PROJECT_HISTORY_LOAD = 'PROJECT_HISTORY_LOAD',
  PROJECT_HISTORY_LOAD_SUCCESS = 'PROJECT_HISTORY_LOAD_SUCCESS',
  PROJECT_HISTORY_LOAD_FAIL = 'PROJECT_HISTORY_LOAD_FAIL',

  // Pipelines
  EXECUTE_PIPELINE_LOAD = 'EXECUTE_PIPELINE_LOAD',
  EXECUTE_PIPELINE_LOAD_SUCCESS = 'EXECUTE_PIPELINE_LOAD_SUCCESS',
  EXECUTE_PIPELINE_LOAD_FAIL = 'EXECUTE_PIPELINE_LOAD_FAIL',
  EXECUTE_PIPELINE_CLEAR = 'EXECUTE_PIPELINE_CLEAR',

  LATEST_PIPELINES_LOAD = 'LATEST_PIPELINES_LOAD',
  LATEST_PIPELINES_LOAD_SUCCESS = 'LATEST_PIPELINES_LOAD_SUCCESS',
  LATEST_PIPELINES_LOAD_FAIL = 'LATEST_PIPELINES_LOAD_FAIL',

  CANCEL_PIPELINE_LOAD = 'CANCEL_PIPELINE_LOAD',
  CANCEL_PIPELINE_LOAD_SUCCESS = 'CANCEL_PIPELINE_LOAD_SUCCESS',
  CANCEL_PIPELINE_LOAD_FAIL = 'CANCEL_PIPELINE_LOAD_FAIL',

  // Project Environment details
  Update_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS = 'Update_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS',

  HEADER_SET_ACTIVE_TAB = 'HEADER_SET_ACTIVE_TAB'
}

export default ActionTypes
