import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[400],
      borderRadius: '4px',
      padding: theme.spacing(2),
    },
    titleText: {
      fontWeight: 'bold',
    },
  }),
)
