import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import IStoreState from 'src/store/IStoreState'
import { ProjectEnvironmentCodeCard } from './code-card.component'
import {
  createProjectDataUpdateProjectDataOnWebrootChange,
  createProjectDataSetEnvironments,
  createProjectDataSetProjectField,
} from 'src/store/create-project-data/create-project-data.actions'
import ProjectEnvironment from 'src/models/ProjectEnvironment'

const mapStateToProps = (state: IStoreState): any => ({
  project: state.createProjectData.project,
  error: state.createProjectData.error,
  loading: state.createProjectData.loading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleInputChange: (event: { target: { name: string; value: any } }) => {
    const key = event.target.name
    const value = event.target.value
    dispatch(createProjectDataSetProjectField(key, value))
  },
  setProjectField: (key: string, value: any) => {
    dispatch(createProjectDataSetProjectField(key, value))
  },
  setProjectEnvironments: (environments: ProjectEnvironment[]) => {
    dispatch(createProjectDataSetEnvironments(environments))
  },
  onWebrootChanged: (envId: string, oldWebroot: string, newWebroot: string): void => {
    dispatch(createProjectDataUpdateProjectDataOnWebrootChange(envId, oldWebroot, newWebroot))
  },
})

export const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedProjectEnvironmentCodeCard = connector(ProjectEnvironmentCodeCard)
