import React from 'react'
import DeploymentTarget, {DEPLOYMENT_TARGET_STATUS} from '../../../models/DeploymentTarget'
import {Grid} from '@material-ui/core'
import statusMessageHelper, {MESSAGE_STATE} from './DeploymentTargetStatusMessageHelper'
import {FontAwesomeIcon}from '@fortawesome/react-fontawesome'
import {faCaretLeft, faCheck, faTimes, faEllipsisH} from '@fortawesome/free-solid-svg-icons'
import Nbsp from '../../common/Nbsp'
import './DeploymentTargetWizard.scss'
import wizardHelper from './DeploymentTargetWizardHelper'

export interface IDeploymentTargetStatusPanelProps {
  submitting?: boolean
  isNewDeploymentTarget?: boolean
}

export interface IDeploymentTargetStatusPanelState {
  deploymentTarget: DeploymentTarget,
  messages: Map<DEPLOYMENT_TARGET_STATUS, string>
}

export class DeploymentTargetStatusPanel extends React.Component<IDeploymentTargetStatusPanelProps, IDeploymentTargetStatusPanelState> {

  static defaultProps: any = {
    submitting: false,
    isNewDeploymentTarget: false
  }

  constructor(props: IDeploymentTargetStatusPanelProps) {
    super(props)
    this.state = {
      deploymentTarget: wizardHelper.deploymentTarget,
      messages: wizardHelper.messages,
    }
    //@ts-ignore
    wizardHelper.subscribeToDeploymentTarget(this.handleDeploymentTargetUpdated.bind(this))
    //@ts-ignore
    wizardHelper.subscribeToStatusMessages(this.handleStatusMessagesUpdated.bind(this))
  }

  componentWillUnmount(): void {
    wizardHelper.stopPolling()
    //@ts-ignore
    wizardHelper.unsubscribeFromStatusMessages(this.handleStatusMessagesUpdated.bind(this))
  }

  handleDeploymentTargetUpdated = (deploymentTarget: DeploymentTarget): void => {
    this.setState({deploymentTarget})
  }

  handleStatusMessagesUpdated = (messages: Map<DEPLOYMENT_TARGET_STATUS, string>): void => {
    this.setState({messages})
  }

  render(): React.ReactNode {
    const filteredMessages: Array<[DEPLOYMENT_TARGET_STATUS, string]> =
      Array.from(this.state.messages).filter(([status, message]:[DEPLOYMENT_TARGET_STATUS, string]) => message)
    
    const messageStates: MESSAGE_STATE[] = statusMessageHelper.getMessageStates(
      filteredMessages.map(([status, message]:[DEPLOYMENT_TARGET_STATUS, string]) => status), this.state.deploymentTarget.status)

    return(
      <Grid container className='full-height helpText status-panel' direction='column' justify='flex-end' alignItems='flex-end' spacing={0}>
        <Grid item><h5>Progress</h5></Grid>
        {
          filteredMessages.map(([status, message]: [DEPLOYMENT_TARGET_STATUS, string], index: number) => {
            switch (messageStates[index]) {
              case MESSAGE_STATE.WAITING:
                return (<Grid item key={status}>{message} <Nbsp/><FontAwesomeIcon icon={faCaretLeft} size='sm'/></Grid>)
              case MESSAGE_STATE.ACTIVE:
                return (<Grid item key={status} className='active-status-message'>{message} <Nbsp/><FontAwesomeIcon icon={faEllipsisH} size='sm'/></Grid>)
              case MESSAGE_STATE.SUCCESS:
                return (<Grid item key={status} className='success-status-message'>{message} <Nbsp/><FontAwesomeIcon icon={faCheck} size='sm'/></Grid>)
              case MESSAGE_STATE.FAIL:
                return (<Grid item key={status} className='fail-status-message'>{message} <Nbsp/><FontAwesomeIcon icon={faTimes} size='sm'/></Grid>)
              default:
                return null
            }
          })
        }
      </Grid>
    )
  }
}

export default DeploymentTargetStatusPanel
