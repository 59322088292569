import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft, faChevronCircleRight, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import {Checkbox, Divider, FormControlLabel,  Grid, IconButton, Paper, Typography} from '@material-ui/core'
import ActionTypes from '../../../../../store/actions'
import IStoreState from '../../../../../store/IStoreState'
import Bundle from '../../../../../models/Bundle'
import Project from '../../../../../models/Project'
import ProjectEnvironment from '../../../../../models/ProjectEnvironment'
import Database, { DATABASE_TYPE } from '../../../../../models/Database'
import DeploymentTargetGroup from '../../../../../models/DeploymentTargetGroup'
import DeploymentEnvironment from '../../../../../models/DeploymentEnvironment'
import ProjectHelper from '../../../../../helpers/ProjectHelper'
import ProjectEnvironmentDatabases from '../../../../../models/ProjectEnvironmentDatabases'
import DeploymentEnvironmentHelper from '../../../../../helpers/DeploymentEnvironmentHelper'
import MessageService from '../../../../../services/MessageService'
import { SectionLayout } from '../../shared'
import ConfirmDialog from '../../../../common/ConfirmDialog'
import ComboBox, { ComboBoxEntry } from '../../../../common/ComboBox'
import ProjectEnvironmentDatabasesCard from './project-database-card.component'
import './project-databases.scss'

export interface IProjectEditDatabaseState {
  activeAssociationGroup: number
  numberOfPages: number
  listOfDatabasesOptions: ComboBoxEntry[]
  confirmDelete: boolean
}

export interface IProjectEditDatabaseProps {
  handleInputChange?: (event: any) => void
  project?: Project
  deploymentEnvironments?: DeploymentEnvironment[]
  error?: object
  loading?: boolean
  bundle?: Bundle
}

export class ProjectEditDatabase extends React.Component<IProjectEditDatabaseProps, IProjectEditDatabaseState> {
  uniqueDatabaseNames: boolean
  uniqueDatabaseUsernames: boolean
  storedProjectEnvironments: ProjectEnvironment[]
  private readonly confirmDeleteMsg: string

  constructor(props: IProjectEditDatabaseProps) {
    super(props)
    this.confirmDeleteMsg = MessageService.get('project.wizard.database.confirm.delete')

    const firstEnvironmentDbs: ProjectEnvironmentDatabases = props.project.projectEnvironments[0].projectEnvironmentDatabases
    const initialDatabaseOptions: ComboBoxEntry[] = this.getInitialDatabaseOptions()

    this.state = {
      activeAssociationGroup: 0,
      numberOfPages: firstEnvironmentDbs ? firstEnvironmentDbs.databases.length : 1,
      listOfDatabasesOptions: initialDatabaseOptions,
      confirmDelete: false,
    }
  }

  getInitialDatabaseOptions = (): ComboBoxEntry[] => {
    const { project } = this.props
    const listOfDatabasesOptions: ComboBoxEntry[] = []
    project.projectEnvironments.forEach((projEnv: ProjectEnvironment) => {
      project.hasDatabases &&
        !!projEnv.projectEnvironmentDatabases &&
        projEnv.projectEnvironmentDatabases.databases
          .sort((a: Database, b: Database) => a.associationId - b.associationId)
          .forEach((db: Database) => this.addOption(projEnv, db, listOfDatabasesOptions))
    })
    return listOfDatabasesOptions
  }

  initializeProjectEnvironmentsDatabases = (): void => {
    const { project } = this.props
    const initialProjectEnvironments: ProjectEnvironment[] = this.getInitialProjectEnvironmentsWithDatabases()
    this.determineIfNamesAndUsernamesAreUnique(initialProjectEnvironments)
    if (initialProjectEnvironments !== project.projectEnvironments) {
      this.props.handleInputChange({
        target: {
          name: 'projectEnvironments',
          value: initialProjectEnvironments,
        },
      })
    }
  }

  // TODO: we're relying on there being a DeploymentTargetGroup called 'Database'. We need to refactor this.
  // We also shouldn't be returning the first group found as that could have unintended consequences.
  getDefaultDeploymentTargetGroup = (environmentId: string): DeploymentTargetGroup => {
    const deploymentEnvironment: DeploymentEnvironment = DeploymentEnvironmentHelper.getDeploymentEnvironmentById(environmentId)
    return deploymentEnvironment.deploymentTargetGroups.find((group: DeploymentTargetGroup) => group.name.toLowerCase().includes('database'))
  }

  getInitialProjectEnvironmentsWithDatabases = (): ProjectEnvironment[] => {
    const { projectEnvironments } = this.props.project
    const { listOfDatabasesOptions } = this.state
    const finishedProjectEnvironments: ProjectEnvironment[] = projectEnvironments.filter((projEnv: ProjectEnvironment) => projEnv.projectEnvironmentDatabases)

    if (finishedProjectEnvironments.length === projectEnvironments.length) {
      projectEnvironments.forEach((projEnv: ProjectEnvironment) => {
        projEnv.projectEnvironmentDatabases.databases.sort(
          (a: Database, b: Database) => a.associationId - b.associationId,
        )
      })
      return projectEnvironments
    }

    return projectEnvironments
      .filter((projEnv: ProjectEnvironment) => !projEnv.projectEnvironmentDatabases)
      .map(
        (projEnv: ProjectEnvironment, index: number): ProjectEnvironment => {
          const defaultDeploymentTargetGroup: DeploymentTargetGroup = this.getDefaultDeploymentTargetGroup(projEnv.environmentId)
          const newDatabase: Database = this.createDatabaseWithInitialInfo(projEnv)
          this.addOption(projEnv, newDatabase, listOfDatabasesOptions)
          this.setState({ listOfDatabasesOptions })
          return {
            ...projEnv,
            projectEnvironmentDatabases: new ProjectEnvironmentDatabases({
              databases: [newDatabase],
              deploymentTargetGroupId: defaultDeploymentTargetGroup.id,
            }),
          }
        },
      )
      .concat(finishedProjectEnvironments)
      .sort((a: ProjectEnvironment, b: ProjectEnvironment) => b.order - a.order)
  }

  createDatabaseWithInitialInfo = (projectEnvironment: ProjectEnvironment) => {
    const { project } = this.props
    const projectName: string = this.removeSpecialCharacters(project.name)
    const nextAssociationId: number = this.nextDatabaseAssociationId(projectEnvironment.projectEnvironmentDatabases?.databases)
    const nextDatabaseNumber: number = this.nextDatabaseNameNumber(projectEnvironment.projectEnvironmentDatabases?.databases)
    const databaseName: string = this.generateDefaultDatabaseName(projectName, projectEnvironment.environmentId, nextDatabaseNumber)

    return new Database({
      name: databaseName,
      username: databaseName,
      password: ProjectHelper.getRandomDatabasePassword(),
      allowedIP: null,
      associationId: nextAssociationId,
      databaseType: DATABASE_TYPE.MYSQL,
    })
  }

  nextDatabaseAssociationId = (databases: Database[]): number => {
    const associationIds: number[] = []
    databases?.forEach(db => associationIds.push(db.associationId))
    return associationIds.length > 0 ? associationIds.sort((a, b) => { return a - b }).pop() + 1 : 0
  }

  nextDatabaseNameNumber = (databases: Database[]): number => {
    const databaseNameNumbers: number[] = []

    databases?.map((db) => {
      const databaseNameSplit: string[] = db.name.split("_")
      const parsedDatabaseNameNumber: number = parseInt(databaseNameSplit[databaseNameSplit.length - 1])
      // Pushing 1 if NaN to avoid names ending in _1.
      isNaN(parsedDatabaseNameNumber) ? databaseNameNumbers.push(1) : databaseNameNumbers.push(parsedDatabaseNameNumber)
    })

    if (databaseNameNumbers.length === 0) {
      return 0
    }

    databaseNameNumbers.sort(function(a, b) {
      return b - a
    })

    return databaseNameNumbers[0] + 1
  }

  generateDefaultDatabaseName = (projectName: string, environmentId: string, databaseNumber: number): string => {
    const sanitizedProjectName =  this.removeSpecialCharacters(projectName)
    const databaseName: string = ProjectHelper.getDefaultDatabaseNameForEnvironment(sanitizedProjectName, environmentId)
    return (!!databaseNumber && databaseNumber > 1) ? `${databaseName}_${databaseNumber}` : databaseName
  }

  determineIfNamesAndUsernamesAreUnique = (projectEnvironments: ProjectEnvironment[]): void => {
    // projEnvs sorted in inverse order
    const { activeAssociationGroup } = this.state
    const prodDatabaseName: string = projectEnvironments[0].projectEnvironmentDatabases.databases[activeAssociationGroup].name
    const prodDatabaseUsername: string = projectEnvironments[0].projectEnvironmentDatabases.databases[activeAssociationGroup].username
    this.uniqueDatabaseNames = projectEnvironments.filter((projEnv: ProjectEnvironment) => {
        const database: Database = projEnv.projectEnvironmentDatabases.databases[activeAssociationGroup]
        return database.name.includes(prodDatabaseName)
      }).length !== projectEnvironments.length
    this.uniqueDatabaseUsernames =
      projectEnvironments.filter((projEnv: ProjectEnvironment) => {
        const database: Database = projEnv.projectEnvironmentDatabases.databases[activeAssociationGroup]
        return database.username && database.username.includes(prodDatabaseUsername)
      }).length !== projectEnvironments.length
  }

  toggleNoDatabases = (): void => {
    if (this.props.project.hasDatabases) {
      this.storedProjectEnvironments = this.props.project.projectEnvironments
      this.props.handleInputChange({
        target: {
          name: 'hasDatabases',
          value: false,
        },
      })
      this.props.handleInputChange({
        target: {
          name: 'projectEnvironments',
          value: this.props.project.projectEnvironments.map((env: ProjectEnvironment) => {
            return { ...env, projectEnvironmentDatabases: null }
          }),
        },
      })
    } else {
      this.props.handleInputChange({
        target: {
          name: 'hasDatabases',
          value: true,
        },
      })
      if (!!this.storedProjectEnvironments) {
        this.props.handleInputChange({
          target: {
            name: 'projectEnvironments',
            value: this.storedProjectEnvironments,
          },
        })
      } else {
        this.initializeProjectEnvironmentsDatabases()
      }
    }
  }

  private removeSpecialCharacters = (str: string): string => {
    return str.replace(/([^a-z_0-9]+)/gi, '');
  }

  enableUniqueDatabaseNames = (): void => {
    this.uniqueDatabaseNames = true
  }

  enableUniqueDatabaseUsernames = (): void => {
    this.uniqueDatabaseUsernames = true
  }

  handleCreateNewEnvironmentDB = (): void => {
    const { project } = this.props
    if (!project.hasDatabases) {
      return
    }

    const { numberOfPages, listOfDatabasesOptions } = this.state
    const updatedProjectEnvironments: ProjectEnvironment[] = project.projectEnvironments

    updatedProjectEnvironments.forEach((projectEnvironment: ProjectEnvironment) => {
      const newDatabase: Database = this.createDatabaseWithInitialInfo(projectEnvironment)
      projectEnvironment.projectEnvironmentDatabases.databases.push(newDatabase)
      this.addOption(projectEnvironment, newDatabase, listOfDatabasesOptions)
    })

    this.setState({
      numberOfPages: numberOfPages + 1,
      activeAssociationGroup: numberOfPages,
      listOfDatabasesOptions
    })

    this.props.handleInputChange({
      target: {
        name: 'projectEnvironments',
        value: updatedProjectEnvironments,
      }
    })
  }



  handleDeleteEnvironmentDBS = (): void => {
    const { activeAssociationGroup, numberOfPages, listOfDatabasesOptions } = this.state
    const { projectEnvironments, hasDatabases } = this.props.project
    const updatedProjectEnvironments: ProjectEnvironment[] = projectEnvironments
    hasDatabases &&
      updatedProjectEnvironments.forEach((projEnv: ProjectEnvironment) => {
        projEnv.projectEnvironmentDatabases.databases.splice(activeAssociationGroup, 1)
        projEnv.projectEnvironmentDatabases.databases.forEach((db: Database, index: number) => {
          db.associationId = index
        })
      })
    const updatedDatabaseOptions: ComboBoxEntry[] = this.getInitialDatabaseOptions()
    const currentActiveAssocGroup: number = activeAssociationGroup - 1 >= 0 ? activeAssociationGroup - 1 : 0
    this.setState({
      numberOfPages: numberOfPages - 1,
      activeAssociationGroup: currentActiveAssocGroup,
      listOfDatabasesOptions: updatedDatabaseOptions,
      confirmDelete: false,
    })
    this.props.handleInputChange({
      target: {
        name: 'projectEnvironments',
        value: updatedProjectEnvironments,
      },
    })
  }

  addOption = (projEnv: ProjectEnvironment, db: Database, listOfDatabasesOptions: ComboBoxEntry[]): void => {
    DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projEnv.environmentId).isProd &&
      listOfDatabasesOptions.push(new ComboBoxEntry(db.name, db.associationId.toString()))
  }

  handleNext = (): void => {
    const { activeAssociationGroup, numberOfPages } = this.state
    const nextActiveStep: number = activeAssociationGroup === numberOfPages - 1 ? 0 : activeAssociationGroup + 1
    this.setState({
      activeAssociationGroup: nextActiveStep,
    })
  }

  handleBack = (): void => {
    const { activeAssociationGroup, numberOfPages } = this.state
    const nextActiveStep: number = activeAssociationGroup === 0 ? numberOfPages - 1 : activeAssociationGroup - 1
    this.setState({
      activeAssociationGroup: nextActiveStep,
    })
  }

  handleDeleteClick = (): void => {
    this.setState({ confirmDelete: true })
  }

  closeConfirmDeleteModal = (): void => {
    this.setState({ confirmDelete: false })
  }

  handleSelect = (selectedEntry: ComboBoxEntry): void => {
    const firstEnvironmentDbs: ProjectEnvironmentDatabases = this.props.project.projectEnvironments[0].projectEnvironmentDatabases
    const selectedAssociationGroup: number = firstEnvironmentDbs.databases.findIndex(
      (db: Database) => db.associationId.toString() === selectedEntry.value,
    )
    this.setState({ activeAssociationGroup: selectedAssociationGroup })
  }

  render(): React.ReactNode {
    const { project } = this.props
    const { activeAssociationGroup, numberOfPages, listOfDatabasesOptions, confirmDelete } = this.state
    const disableRemoveButton: boolean = numberOfPages <= 1
    return (
      <SectionLayout>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">{MessageService.get('project.wizard.step4.details')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={!project.hasDatabases} onClick={this.toggleNoDatabases} />}
                  label="This project does not use databases"
                />
              </Grid>
              {project.hasDatabases && (
                <Grid item xs={12}>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify="center">
                        <Grid item xs={11}>
                          <Grid container spacing={2} justify="center" alignItems="center">
                            <Grid item xs={6}>
                              <ComboBox
                                creatable
                                label="Database Group"
                                overrideValue={listOfDatabasesOptions[activeAssociationGroup]}
                                suggestions={listOfDatabasesOptions}
                                handleSelect={this.handleSelect}
                                handleCreate={this.handleCreateNewEnvironmentDB}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                  <Grid container justify="space-between" alignContent="center" alignItems="center">
                                    <Grid item>
                                      <IconButton style={{ marginTop: '4px' }}
                                                  title="Create New Database"
                                                  onClick={this.handleCreateNewEnvironmentDB.bind(this, null)}
                                                  color="primary">
                                        <div>
                                          <FontAwesomeIcon icon={faPlus} size="sm" />
                                        </div>
                                      </IconButton>
                                    </Grid>
                                    <Grid item>
                                      <IconButton style={{ marginTop: '4px' }}
                                                  title="Delete Database"
                                                  onClick={this.handleDeleteClick}
                                                  disabled={disableRemoveButton}>
                                        <div>
                                          <FontAwesomeIcon icon={faTrash} size="xs" />
                                        </div>
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item className="right">
                                  <span>
                                    Database group {activeAssociationGroup + 1} of {numberOfPages}
                                  </span>
                                </Grid>
                                <Grid item>
                                  {numberOfPages > 1 && (
                                    <IconButton onClick={this.handleBack} color="primary">
                                      <div>
                                        <FontAwesomeIcon icon={faChevronCircleLeft} />
                                      </div>
                                    </IconButton>
                                  )}
                                </Grid>
                                <Grid item>
                                  {numberOfPages > 1 && (
                                    <IconButton onClick={this.handleNext} color="primary">
                                      <div>
                                        <FontAwesomeIcon icon={faChevronCircleRight} />
                                      </div>
                                    </IconButton>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} justify="space-around" alignItems="center" alignContent="center">

                        <Grid item xs={11}>
                          <Paper elevation={4} className={'MuiPaper-root1'}>
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                              <Grid item xs={12}>
                                <Grid container
                                      direction="column"
                                      spacing={4}
                                      justify="center">
                                  {project.projectEnvironments
                                    .sort((env1: ProjectEnvironment, env2: ProjectEnvironment) => env2.order - env1.order)
                                    .map((projEnv: ProjectEnvironment, index: number) => {
                                      return !!projEnv.projectEnvironmentDatabases ? (
                                        <Grid item xs={12} key={projEnv.environmentId}>
                                          <ProjectEnvironmentDatabasesCard
                                            database={projEnv.projectEnvironmentDatabases.databases[activeAssociationGroup]}
                                            environmentId={projEnv.environmentId}
                                            deploymentTargetGroupId={projEnv.projectEnvironmentDatabases.deploymentTargetGroupId}
                                            isProd={DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projEnv.environmentId).isProd}
                                            uniqueDatabaseNames={this.uniqueDatabaseNames}
                                            uniqueDatabaseUsernames={this.uniqueDatabaseUsernames}
                                            enableUniqueDatabaseNames={this.enableUniqueDatabaseNames}
                                            enableUniqueDatabaseUsernames={this.enableUniqueDatabaseUsernames}
                                            className={index < project.projectEnvironments.length - 1 ? 'project-card-step':''}
                                          />
                                        </Grid>
                                      ) : null
                                    })}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>

                      </Grid>
                    </Grid>
                    {confirmDelete && (
                      <ConfirmDialog open
                                     title={`Delete Database?`}
                                     message={this.confirmDeleteMsg}
                                     onConfirm={this.handleDeleteEnvironmentDBS}
                                     onCancel={this.closeConfirmDeleteModal} />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </SectionLayout>
    )
  }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IProjectEditDatabaseProps): any => ({
  project: state.selectedProject.project,
  error: state.selectedProject.error,
  loading: state.selectedProject.loading,
  bundle: state.bundle.data,
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IProjectEditDatabaseProps): any => ({
  handleInputChange: (event: any): void => {
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
      payload: {
        key: event.target.name,
        value: event.target.value,
      },
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditDatabase)
