import initialState from '../initialState'
import ActionTypes from '../actions'
import {IBundleState} from '../IStoreState'

export default function projectTypesReducer(state: IBundleState = initialState.bundle, action: any): IBundleState {
  switch (action.type) {
    case ActionTypes.BUNDLE_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ActionTypes.BUNDLE_LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.bundle,
        loading: false,
        error: null
      }
    case ActionTypes.BUNDLE_LOAD_FAIL:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}
