import { PROJECT_TYPE } from 'src/models/Project'

export interface ICreateProjectFormState {
  selectedWorkflowType: PROJECT_TYPE | null
  isBranchConfirmationShown: boolean
  selectedEnvironments: any[]
  productionEnvironmentId: string | undefined
  isSaveDisabled: boolean
}

export const defaultProjectCreationFormState: ICreateProjectFormState = {
  selectedWorkflowType: null,
  selectedEnvironments: [],
  isBranchConfirmationShown: false,
  isSaveDisabled: true,
  productionEnvironmentId: undefined,
}
