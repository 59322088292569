import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import User from '../../../../../models/User'
import Project from '../../../../../models/Project'
import ProjectEvent from '../../../../../models/pipeline/ProjectEvent'
import ProjectEnvironment from '../../../../../models/ProjectEnvironment'
import IStoreState from '../../../../../store/IStoreState'
import PipelineService from '../../../../../services/PipelineService'
import {  loadProjectEvents } from '../../../../../store/actions/selectedProjectActions'
import DeploymentTrack from '../../../project-details-legacy/environments-overview/DeploymentTrack'

export interface ProjectOverviewEnvironmentsProps {
  currentUser?: User
  project?: Project
  latestEvents?: ProjectEvent[]
  loadProjectEvents?: () => void
}

export interface ProjectOverviewEnvironmentsState {}

export class ProjectOverviewEnvironments extends React.Component<
  ProjectOverviewEnvironmentsProps,
  ProjectOverviewEnvironmentsState
> {

  componentDidMount(): void {
    PipelineService.subscribeToProjectActions(this.loadProjectEvents)
  }

  componentWillUnmount(): void {
    PipelineService.unsubscribeFromProjectActions(this.loadProjectEvents)
  }

  loadProjectEvents = (): void => {
    this.props.loadProjectEvents()
  }

  getLatestActionsForDeploymentTrack(environmentId: string): ProjectEvent[] {
    const { latestEvents } = this.props

    if (latestEvents) {
      return latestEvents.filter(
        (event: ProjectEvent) => event.action.actionConfiguration.targetEnvironment === environmentId,
      )
    }

    return []
  }

  render() {
    const { project } = this.props

    if (!project) {
      return null
    }

    const filteredEnvironments = project.projectEnvironments || []
    
    const sortedEnvironments = filteredEnvironments
      .sort((env1: ProjectEnvironment, env2: ProjectEnvironment) => env1.order - env2.order)

    return (
      <Grid container style={{ marginTop: '0.5rem'}} spacing={4}>
        {sortedEnvironments
          .map((env: ProjectEnvironment, index: number) => {
            return (
              <Grid item sm={12} key={index}>
                <DeploymentTrack
                  environment={env}
                  environments={project.projectEnvironments}
                  project={project}
                  latestEvents={this.getLatestActionsForDeploymentTrack(env.environmentId)}
                />
              </Grid>
            )
          })}
      </Grid>
    )
  }
}

const mapStateToProps = (state: IStoreState) => ({
  currentUser: state.currentUser,
  project: state.selectedProject.persistedProject,
  error: state.selectedProject.error,
  latestEvents: state.selectedProject.latestEvents,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadProjectEvents: (): void => dispatch(loadProjectEvents()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOverviewEnvironments)
