import React from 'react'
import PropTypes from 'prop-types'
import { TableHead, TableSortLabel } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { StyledTableRow, StyledTableCell } from '../dashboard/ProjectTable.config'
import { assign } from 'lodash'
import './TableHeader.scss'

class TableHeader extends React.Component {

  createSortHandler = (property,order) => (event) => {
    this.props.onRequestSort(event, property,order)
  }

  getHeadingLabel(column) {
    const {order, orderBy} = this.props

    if (column.sortable) {
      return (
        <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
          <TableSortLabel active={true} direction={column.order} onClick={this.createSortHandler(column.id,column.order)} className={orderBy === column.id? 'ActiveSort':''}>
            {column.label}
          </TableSortLabel>
        </Tooltip>
      )
    } else {
      return <TableSortLabel active={false} hideSortIcon={true}>{column.label}</TableSortLabel>
    }
  }

  render() {
    const {columns, order, orderBy, style} = this.props
    const headerStyle = assign({textTransform: 'uppercase', fontSize: '14pt'}, style)
    return (
      <TableHead>
        <StyledTableRow>
          {columns.map((column) => {
            return (
              <StyledTableCell key={column.id} numeric={column.numeric} padding={column.disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === column.id ? order : false} style={headerStyle}>
                {this.getHeadingLabel(column)}
              </StyledTableCell>
            )
          }, this)}
        </StyledTableRow>
      </TableHead>
    )
  }
}

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    numeric: PropTypes.bool.isRequired,
    disablePadding: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    sortable: PropTypes.bool.isRequired,
  })).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  style: PropTypes.object,
}

TableHeader.defaultProps = {
  style: {},
}

export default TableHeader
