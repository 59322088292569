import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'
import { Grid } from '@material-ui/core'
import IStoreState from 'src/store/IStoreState'
import { createProjectDataSetProjectField } from 'src/store/create-project-data/create-project-data.actions'
import MessageService from 'src/services/MessageService'
import { PROJECT_ERROR } from 'src/models/Project'
import { CreateFormTextField } from '../shared'

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {}
export type CreateProjectDetailsFormProps = PropsFromRedux & ComponentProps

export const CreateProjectDetailsForm: React.FC<CreateProjectDetailsFormProps> = ({
  error,
  loading,
  project,
  updateProjectName,
  updateProjectDomain,
}) => {
  const fieldSpacing = 3
  const domainError = error === PROJECT_ERROR.DOMAIN_CONTAINS_PROTOCOL
  const domainTooltip = MessageService.get('project.wizard.details.domain.tooltip')
  const domainHelperText = domainError
    ? MessageService.get('project.wizard.error.details.domain.contains.protocol')
    : null
  const nameError = error === PROJECT_ERROR.PROJECT_EXISTS
  const nameHelperText = nameError ? MessageService.get('project.wizard.error.details.name.duplicate') : null
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updateProjectName(event.target.name, event.target.value)
  }
  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updateProjectDomain(event.target.name, event.target.value)
  }
  return (
    <Grid item container direction="column" spacing={fieldSpacing}>
      <CreateFormTextField
        textFieldLabel="Name *"
        name="name"
        value={project.name}
        helperText={nameHelperText}
        onChange={handleNameChange}
        disabled={loading}
        autoComplete="off"
      />
      <CreateFormTextField
        textFieldLabel="Domain *"
        name="domain"
        value={project.domain}
        tooltipMessage={domainTooltip}
        helperText={domainHelperText}
        onChange={handleDomainChange}
        disabled={loading}
        autoComplete="off"
      />
    </Grid>
  )
}

const mapStateToProps = (state: IStoreState, ownProps: ComponentProps) => ({
  project: state.createProjectData.project,
  loading: state.createProjectData.loading,
  error: state.createProjectData.error,
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: ComponentProps) => ({
  updateProjectName: (field: string, value: string): void => {
    dispatch(createProjectDataSetProjectField(field, value))
  },
  updateProjectDomain: (field: string, value: any): void => {
    dispatch(createProjectDataSetProjectField(field, value))
  },
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedCreateProjectDetailsForm = connector(CreateProjectDetailsForm)
