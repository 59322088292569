import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Grid, Tooltip, Typography} from '@material-ui/core'
import React from 'react'
import { EnvironmentSelectionConfig } from '../drupal8/drupal8-environments.config'
import {defaultEnvironmentNonActiveMessage, useStyles} from './environment-unselected-item.config'
import {DEPLOYMENT_ENVIRONMENT_STATUS} from '../../../../models/DeploymentEnvironment'

export interface UnselectedEnvironmentItem {
  config: EnvironmentSelectionConfig
  add: (config: EnvironmentSelectionConfig) => void
}

export const UnselectedEnvironmentItem: React.FC<UnselectedEnvironmentItem> = ({ config, add }) => {
  const styles = useStyles()
  const environmentError = config.status !== DEPLOYMENT_ENVIRONMENT_STATUS.ACTIVE
  const selectEnvironment = () => {
    add(config)
  }
  const errorSelect = () => {

  }
  return (
    <div className={styles.container} onClick={environmentError ? errorSelect : selectEnvironment}>
      <Tooltip title={environmentError ? defaultEnvironmentNonActiveMessage : ''}>
      <Grid container spacing={2} alignItems="center" alignContent="center">
        <Grid item>
          <Typography variant="body1" className={[styles.titleText, environmentError ? styles.errorText : ''].join(' ')}>
            {config.title}
          </Typography>
        </Grid>
        <Grid item>
          <FontAwesomeIcon icon={faPlus} size="lg" color="darkgray" />
        </Grid>
      </Grid>
      </Tooltip>
    </div>
  )
}
