import React from 'react'
import TextField from '@material-ui/core/TextField'
import {Button, Grid, Typography, FormLabel} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import UserService from '../../../services/UserService'
import SnackbarService, {SNACKBAR_TYPES} from '../../../services/SnackbarService'
import PropTypes from 'prop-types'
import User from "../../../models/User";
import * as EmailValidator from 'email-validator'

class CreateUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emailAsUsernameChecked: true,
      email: '',
      username: '',
      givenName: '',
      familyName: '',
    }
  }
  handleAddUser = () => {
    let errorMessages = this.validateCreateUser()
    if(errorMessages.length === 0){
      let preparedCreateUser = this.prepareCreateUser()
      UserService.createUser(preparedCreateUser)
        .then((user) => {
          SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'User created.')
          this.props.handleNewUser(user)
        })
        .catch(() => {
          SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to create user!')
        })
      } else {
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, errorMessages.join(' '))
      }
  }
  handleInputChange = (key) => (event) => {
    this.setState({
      [key]: event.target.value,
    })
    if (key === 'email' && this.state.emailAsUsernameChecked) {
      this.setState({username: event.target.value})
    }
  }
  handleEmailAsUsernameCheckbox = () => {
    const emailAsUsernameCheckedValue = !this.state.emailAsUsernameChecked
    this.setState({emailAsUsernameChecked: emailAsUsernameCheckedValue})

    if ((this.state.email !== '' || this.state.email !== null) && emailAsUsernameCheckedValue) {
      this.setState({username: this.state.email})
    }
  }
  userEmailInUse = () => {
    return this.props.users.filter((val) => val.email === this.state.email).length > 0
  }
  usernameIsValid = () => {
    const pattern = /^[a-zA-Z0-9_\-.]+$/g
    let regex = new RegExp(pattern)
    return this.state.emailAsUsernameChecked || regex.test(this.state.username)
  }
  emailIsValid = () => {
    return EmailValidator.validate(this.state.email)
  }
  emailIsLowercase = () => {
    return this.state.email === this.state.email.toLowerCase() && this.state.email !== this.state.email.toUpperCase()
  }
  validateCreateUser = () => {
    let returnValue = []
    let emailInUse = this.userEmailInUse()
    let usernameValid = this.usernameIsValid()
    let emailValid = this.emailIsValid()

    if(emailInUse) {
      returnValue.push('Another user is registered with that email. Please use a different email address.')
    }

    if(!usernameValid) {
      returnValue.push('User name must only contain alpha-numeric, underscores, hyphens, or periods.')
    }

    if(!emailValid) {
      returnValue.push('Email address does not appear to be valid.')
    }

    return returnValue
  }
  prepareCreateUser = () => {
    this.state.email = this.state.email.toLowerCase()
    if (this.state.emailAsUsernameChecked) {
      this.state.username = this.state.email
    }
    this.state.username = this.state.username.toLowerCase()

    const userToCreate = {
      email: this.state.email,
      username: this.state.username,
      name: {
        givenName: this.state.givenName,
        familyName: this.state.familyName,
      },
    }

    return userToCreate
  }
  render() {
    const allowedToAddUser = this.state.email &&
      this.state.username &&
      this.state.givenName &&
      this.state.familyName
    return (
      <Grid container direction='column' style={{width: '50vw', flexWrap: 'nowrap'}}>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item sm={12}>
              <Typography variant='h5' style={{color: 'black'}}>Add User</Typography>
            </Grid>
          </Grid>
          <Grid item sm={11} style={{maxWidth:'100%'}}>
            <form autoComplete="off">
              <br/>
            <FormLabel style={{color: 'black'}}>E-mail</FormLabel>
            <TextField
                type="search"
                variant="outlined"
                className="inputTextField"
                required
                id="email"
                style={{marginTop: '3px'}}
                margin="normal"
                placeholder="Enter E-mail"
                value={this.state.email}

                onChange={this.handleInputChange('email')}/>
                <br />
                <br />
              <FormLabel style={{color: 'grey'}}>Username</FormLabel>
              <TextField
                className="inputTextField"
                variant="outlined"
                required
                id="username"
                margin="normal"
                style={{marginTop: '3px', marginBottom: '10px'}}
                placeholder="Enter Username"
                disabled={this.state.emailAsUsernameChecked}
                value={this.state.username}
                onChange={this.handleInputChange('username')}
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    value="emailAsUsername"
                    checked={this.state.emailAsUsernameChecked}
                    onChange={this.handleEmailAsUsernameCheckbox}
                    color="primary"
                  />
                }
                label="Same as email"
              />
              <br />
              <br />
              <FormLabel style={{color: 'black'}}>First Name</FormLabel>
              <TextField
                className="inputTextField"
                required
                id="givenName"
                style={{marginTop: '3px'}}
                placeholder="Enter a First Name"
                variant="outlined"
                margin="normal"
                value={this.state.givenName}
                onChange={this.handleInputChange('givenName')}
              /><br />
              <br />
              <FormLabel style={{color: 'black'}}>Last Name</FormLabel>
              <TextField
                className="inputTextField"
                required
                id="familyName"
                style={{marginTop: '3px'}}
                placeholder="Enter a Last Name"
                variant="outlined"
                margin="normal"
                value={this.state.familyName}
                onChange={this.handleInputChange('familyName')}
              />
            </form>
          </Grid>
          <Grid style={{marginTop: '1rem'}} item container justify="flex-end">
            <Button onClick={this.props.handleModalClose} variant='outlined' disableElevation style={{marginRight:'23px'}}>Cancel</Button>
            <Button
                onClick={this.handleAddUser}
                disabled={!allowedToAddUser}
                variant="contained"
                color="primary">Add User</Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

CreateUser.propTypes = {
  users:PropTypes.instanceOf(User),
  handleNewUser: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
}

export default CreateUser
