import React from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(4),
    },
  }),
)

export const SectionLayout: React.FC = ({ children }) => {
  const styles = useStyles()
  return (
    <Paper className={styles.root} elevation={2} variant="elevation">
      {children}
    </Paper>
  )
}
