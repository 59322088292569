import React, {useEffect} from 'react'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import IStoreState from 'src/store/IStoreState'
import ProjectActions from 'src/store/actions/projectActions'
import {SectionMenu} from './menu/section-menu.component'
import {BannerContent, InfoSection} from './shared'
import {MenuSection} from './menu/section-menu.config'
import {
  isValidSection,
  resolveInitialDeployButtonConfig,
  resolveInitialDeploymentNotificationConfig,
  resolveIsMenuCollapsed,
  resolveLandingSection,
  resolveMenuEntryConfigs,
  resolveNotificationConfig,
  resolveProjectName,
  resolveSaveButtonConfig,
  resolveViewSourceCodeConfig,
} from './project-details.config'
import { useRouter } from '@uirouter/react'
import { appStates } from 'src/appStates'
import Project, {PROJECT_STATUS} from "../../../models/Project";
import {loadPersistedProjectById} from "../../../store/actions/selectedProjectActions";
import DeploymentEnvironmentHelper from "../../../helpers/DeploymentEnvironmentHelper";

export interface ProjectDetailsSceneProps {
  section: string
}

export const ProjectDetailsScene: React.FC<ProjectDetailsSceneProps> = ({ section }) => {
  const selectedProject = useSelector((state: IStoreState) => state.selectedProject.project)
  const persistedProject = useSelector((state: IStoreState) => state.selectedProject.persistedProject)
  const isProjectLoading = useSelector((state: IStoreState) => state.selectedProject.loading)
  const unsavedChanges = useSelector((state: IStoreState) => state.selectedProject.unsavedChanges)
  const currentUser = useSelector((state: IStoreState) => state.currentUser)
  const error = useSelector((state: IStoreState) => state.selectedProject.error)
  const errorMessage = useSelector((state: IStoreState) => state.selectedProject.errorMessage)
  const dispatch = useDispatch()
  const router = useRouter()

  //Polling timer in MS
  const POLLING_TIMER = 5000

  //Start polling for project updates
  //componentDidMount
  useEffect(() => {
    let poll = setInterval(() => {
      dispatch(loadPersistedProjectById(selectedProject.id))
    }, POLLING_TIMER)

    //componentWillUnmount
    return function cleanup() {
      clearInterval(poll)
    }
  });

  const handleSave = () => {
    dispatch(ProjectActions.updateProject(selectedProject))
  }

  const handleInitialDeploy = () => {
    dispatch(ProjectActions.deployInitialProject(selectedProject))
    const redirectAppState = appStates.PROJECT + '.' + appStates.PROJECT_DETAILS
    router.stateService.go(redirectAppState, { section: MenuSection.OVERVIEW })
  }

  const hasRequiredData = (): boolean => {
    return currentUser && selectedProject && selectedProject.id !== undefined
  }

  const handleViewSourceCodeClick = () => {
    if (viewSourceCodeConfig.url && viewSourceCodeConfig.url.length > 0) {
      window.open(viewSourceCodeConfig.url, '_blank')
    }
  }

  const handleSetActiveSection = (section: MenuSection) => {}

  const resolveNonactiveEnvironment = (project: Project): boolean => {
    let nonActiveEnvironmentFound = false
    let projectEnvironmentIds = project.projectEnvironments ? project.projectEnvironments.map(it => it.environmentId) : []
    for (let value of projectEnvironmentIds.values()) {
      if (!nonActiveEnvironmentFound && !DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(value)) {
        nonActiveEnvironmentFound = true
      }
    }

    return nonActiveEnvironmentFound
  }

  const elementSpacing = 3
  const hasEditPermission = currentUser?.canEditProject(selectedProject.id) || false
  const projectName = resolveProjectName(selectedProject.name)
  const menuEntries = resolveMenuEntryConfigs(selectedProject.type, hasEditPermission)
  const notValidSection = !isValidSection(menuEntries, section)
  const activeSection = resolveLandingSection(menuEntries, section)
  if (notValidSection) {
    const redirectAppState = appStates.PROJECT + '.' + appStates.PROJECT_DETAILS
    router.stateService.go(redirectAppState, { section: activeSection })
  }
  const isMenuCollapsed = resolveIsMenuCollapsed(activeSection)
  const viewSourceCodeConfig = resolveViewSourceCodeConfig(selectedProject, handleViewSourceCodeClick)
  const hasNonactiveEnv = resolveNonactiveEnvironment(selectedProject)

  const saveConfig = resolveSaveButtonConfig(activeSection, selectedProject, isProjectLoading, hasNonactiveEnv, handleSave)
  const notificationConfig = resolveNotificationConfig(unsavedChanges, error, errorMessage)
  const isDataLoaded = hasRequiredData()
  const initialDeploymentNotificationConfig = resolveInitialDeploymentNotificationConfig(
    persistedProject?.status !== PROJECT_STATUS.ACTIVE && !unsavedChanges ? false : true, error, errorMessage)


  let environmentTitle = ''
  if(selectedProject.projectEnvironments) {
    const environmentId = !!selectedProject.projectEnvironments.find(x => x.order === 0) ? selectedProject.projectEnvironments.find(x => x.order === 0).environmentId : ""
    environmentTitle = DeploymentEnvironmentHelper.getEnvironmentTitleById(environmentId)
  }

  const initialDeploymentConfig = resolveInitialDeployButtonConfig(activeSection, selectedProject, environmentTitle, isProjectLoading, handleInitialDeploy)

  return (
      <Grid container direction="column" spacing={elementSpacing}>
        <Grid item xs>
          <BannerContent
            projectName={projectName}
            saveConfig={saveConfig}
            viewSourceCodeConfig={viewSourceCodeConfig}
            notificationConfig={notificationConfig}
            initialDeploymentConfig={initialDeploymentConfig}
            initialDeploymentNotificationConfig={initialDeploymentNotificationConfig}/>
        </Grid>
        <Grid item xs>
          <Grid container direction="row" spacing={elementSpacing} wrap="nowrap">
            <Grid item>
              <SectionMenu
                activeSection={activeSection}
                setActiveSection={handleSetActiveSection}
                isCollapsed={isMenuCollapsed}
                entries={menuEntries}
              />
            </Grid>
            <Grid item xs>
              <InfoSection activeSection={activeSection} isDataLoaded={isDataLoaded} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

   )
}
