import React from 'react'
import PropTypes from 'prop-types'
import GenericTable, {sortDirections} from '../../common/GenericTable'
import UsersTableRow from './UsersTableRow'
import User from '../../../models/User'

class UsersTable extends React.Component {

  columns = [
    {id: 'username', numeric: false, disablePadding: false, label: 'Username', sortable: true, order:sortDirections.DESC},
    {id: 'givenName', numeric: false, disablePadding: false, label: 'First Name', sortable: true, order:sortDirections.ASC},
    {id: 'familyName', numeric: false, disablePadding: false, label: 'Last Name', sortable: true, order:sortDirections.ASC},
  ]

  reAlignUsers = (Users)=>{
    let UsersAligned = []
    if(Users !== undefined && Users !== null){
      if(Users.length > 0){
         Users.map((a)=>{
          let UserObj = {
            username:a.username,
            email: a.email,
            name:a.name,
            familyName: a.name.familyName,
            givenName: a.name.givenName,
            roleGroups: a.roleGroups,
            roles: a.roles,
          }
          UsersAligned.push(UserObj);
        })
      }
    }
    return UsersAligned;
  }

  render() {
    return (
      <GenericTable
        rowComponent={UsersTableRow}
        items={this.reAlignUsers(this.props.users)}
        columns={this.columns}
        handleRowClick={this.props.handleUserRowClick}
        filter={this.props.filter}
        filterBy={this.props.filterBy}
        order={sortDirections.ASC}
        orderBy='username'
        comparator={null}
        componentField={null}/>
    )
  }
}

UsersTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.instanceOf(User)).isRequired,
  filter: PropTypes.string.isRequired,
  filterBy: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleUserRowClick: PropTypes.func.isRequired,
}

export default UsersTable
