import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles( {
  hide_clear_button: {
    visibility: 'hidden'
  },
  show_clear_button: {
    visibility: 'visible'
  },
  white_text: {
    color: 'white'
  }
})
