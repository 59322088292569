import React from 'react'
import { Container } from '@material-ui/core'

export const SceneLayout: React.FC = ({ children }) => {
  return (
    <Container maxWidth="lg">
      {children}
    </Container>
  )
}
