import {makeStyles} from '@material-ui/core/styles'
import '../../styles/base/variables.scss'
import '../../theme/lib/color/custom.color'
import {CUSTOM} from '../../theme/lib/color'

const rowBottomMargin = '14px'
export enum ProjectFilters {
  ALL,
  STARRED
}

export const useStyles = makeStyles( {
  filters: {
    color: 'white',
    fontSize: '16px',
    marginBottom: rowBottomMargin
  },
  search: {
    color: 'white',
    marginBottom: rowBottomMargin,
    marginRight: '4px',
    float: 'right',
    cursor: 'vertical-text'
  },
  button: {
    color: 'white',
    backgroundColor: CUSTOM.blue[600],
    padding: '9px 15px',
    borderRadius: '6px',
    float: 'right',
    '&:hover': {
      backgroundColor: CUSTOM.blue[900]
    }
  },
  button_icon: {
    marginLeft: '10px'
  }
})
