import React from 'react'
import { Grid } from '@material-ui/core'
import { ProjectCreateBreadcrumb } from './project-create-breadcrumb.component'
import {faFile,faHome} from '@fortawesome/free-solid-svg-icons'
import { SaveAndCancelButtons } from './save-and-cancel-buttons.component'
import { SaveAndCancelConfig } from '../project-create.scene'
import {
  createProjectFormSubmit,
  createProjectFormReset,
} from 'src/store/create-project-form/create-project-form.actions'
import { Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { createProjectDataReset } from 'src/store/create-project-data/create-project-data.actions'
import IStoreState from 'src/store/IStoreState'
import {Breadcrumb} from "../../project-details/shared";

const spacing = 3
const pageName = 'Create New Project'

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {}
export type ProjectCreateBannerProps = PropsFromRedux & ComponentProps

export const ProjectCreateBanner: React.FC<ProjectCreateBannerProps> = ({ isSaveDisabled, isError, tooltip, handleSubmitForm, resetForm }) => {
  const saveAndCancelConfig: SaveAndCancelConfig = {
    isSaveDisabled,
    isError,
    tooltip,
    handleSubmit: handleSubmitForm,
    handleCancel: resetForm,
  }
  
  return (
    <Grid container direction="column" wrap="nowrap" spacing={spacing}>
      <Breadcrumb context={'Dashboard'} path={pageName} icon={faHome} background={'Blue'} resetForm={resetForm} applyPadding={true}/>
      <Grid item container wrap="nowrap" justify="space-between" alignItems="center" style={{marginTop:'10px'}}>
        <Grid item>
          <ProjectCreateBreadcrumb path={pageName} icon={faFile} />
        </Grid>
        <Grid item>
          <SaveAndCancelButtons config={saveAndCancelConfig} />
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state: IStoreState, ownProps: ComponentProps) => ({
  isSaveDisabled: state.createProjectForm.isSaveDisabled,
  isError: false,
  tooltip: 'bah',
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ComponentProps) => ({
  handleSubmitForm: () => {
    dispatch(createProjectFormSubmit())
  },
  resetForm: () => {
    dispatch(createProjectFormReset())
    dispatch(createProjectDataReset())
  },
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedProjectCreateBanner = connector(ProjectCreateBanner)
