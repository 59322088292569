import {Action as ReduxAction, Dispatch} from 'redux'
import ActionTypes from './index'
import HistoryService from '../../services/HistoryService'
import Action from '../../models/pipeline/Action'
import Pipeline from "../../models/pipeline/Pipeline";
import {PipelineTemplate} from "../../models/pipeline/PipelineTemplate";

export default class HistoryActions {
  static loadProjectHistory(projectId: string): any {
    return async (dispatch: Dispatch<ReduxAction>): Promise<any> => {
      dispatch({type: ActionTypes.PROJECT_HISTORY_LOAD})
      try {
        const items: Action[] = await HistoryService.getHistoricActionsForProject(projectId)
        dispatch({type: ActionTypes.PROJECT_HISTORY_LOAD_SUCCESS, payload: {items}})
      } catch (error) {
        dispatch({type: ActionTypes.PROJECT_HISTORY_LOAD_FAIL, payload: {error}})
      }
    }
  }

  static loadProjectPipelineHistory(projectId: string): any {
    return async (dispatch: Dispatch<ReduxAction>): Promise<any> => {
      dispatch({type: ActionTypes.LATEST_PIPELINES_LOAD})
      try {
        const pipelines: Pipeline[] = await HistoryService.getHistoricPipelinesForProject(projectId)
        dispatch({type: ActionTypes.LATEST_PIPELINES_LOAD_SUCCESS, payload: {pipelines}})
      } catch (error) {
        dispatch({type: ActionTypes.LATEST_PIPELINES_LOAD_FAIL, payload: {error}})
      }
    }
  }

  static loadProjectPipelineTemplatesHistory(projectId: string): any {
    return async (dispatch: Dispatch<ReduxAction>): Promise<any> => {
      dispatch({type: ActionTypes.PIPELINE_TEMPLATES_LOAD})
      try {
        const pipelineTemplates: PipelineTemplate[] = await HistoryService.getHistoricPipelineTemplatesForProject(projectId)
        dispatch({type: ActionTypes.PIPELINE_TEMPLATES_LOAD_SUCCESS, payload: {pipelineTemplates}})
      } catch (error) {
        dispatch({type: ActionTypes.PIPELINE_TEMPLATES_LOAD_FAIL, payload: {error}})
      }
    }
  }

  static clearPipelineTemplates(): any {
    return async (dispatch: Dispatch<ReduxAction>): Promise<any> => {
      dispatch({type: ActionTypes.PIPELINE_TEMPLATE_CLEAR})
    }
  }

  static clearPipelines(): any {
    return async (dispatch: Dispatch<ReduxAction>): Promise<any> => {
      dispatch({type: ActionTypes.EXECUTE_PIPELINE_CLEAR})
    }
  }

}
