import {Action, Dispatch} from 'redux'
import ActionTypes from '.'
import Project from '../../models/Project'
import IStoreState from '../IStoreState'
import ProjectActions from './projectActions'
import ProjectService from '../../services/ProjectService'
import ProjectEnvironment from '../../models/ProjectEnvironment'
import SymLink from '../../models/SymLink'
import ProjectCloner from '../../helpers/ProjectCloner'

export let projectStatusPoll: any
const POLL_RATE: number = 2000

export default class ProjectWizardActions {
  static pollProjectStatus(): any {
    return async (dispatch: Dispatch<Action>, getState: () => IStoreState): Promise<void> => {
      const project: Project = getState().projectWizard.project
      if (project) {
        ProjectWizardActions.processPolling(project.id, dispatch)
      }
    }
  }

  static async processPolling(id: string, dispatch: Dispatch<Action>): Promise<void> {
    await ProjectWizardActions.updateStatus(id, dispatch)
    await new Promise((resolve: any): any => {
      projectStatusPoll = setTimeout(() => {
        resolve(ProjectWizardActions.processPolling(id, dispatch))
      }, POLL_RATE)
    })
  }

  static async updateStatus(id: string, dispatch: Dispatch<Action>): Promise<void> {
    const persistedProject: Project = await ProjectService.getProjectDetails(id)
    dispatch({type: ActionTypes.UPDATE_PERSISTED_PROJECT, payload: {persistedProject}})
  }

  static stopProjectStatusPoll(): any {
    clearTimeout(projectStatusPoll)
    projectStatusPoll = null
  }

  static finishProcessed(): any {
    return async (dispatch: Dispatch<Action>, getState: () => IStoreState): Promise<any> => {
      const {error} = getState().projectWizard

      if (!error) {
        dispatch({type: ActionTypes.CLOSE_PROJECT_WIZARD})
        dispatch(ProjectActions.loadProjects())
      }
    }
  }

  static goToPreviousStepProcessed(): any {
    return async (dispatch: Dispatch<Action>, getState: () => IStoreState): Promise<any> => {
      const {activeStep, error} = getState().projectWizard

      if (!error) {
        const step: number = activeStep - 1
        dispatch({
          type: ActionTypes.PROJECT_WIZARD_STEP_CHANGE,
          payload: {step}
        })
      }
    }
  }

  static goToStepProcessed(step: number): any {
    return async (dispatch: Dispatch<Action>, getState: () => IStoreState): Promise<any> => {
      const {error} = getState().projectWizard

      if (!error) {
        dispatch({
          type: ActionTypes.PROJECT_WIZARD_STEP_CHANGE,
          payload: {step}
        })
      }
    }
  }

  static cloneProject(): any {
    return async (dispatch: Dispatch<Action>, getState: () => IStoreState): Promise<any> => {
      const {project, cloneFromProject} = getState().projectWizard
      let projectToSave: Project = project
      const cloneFromProjectId: string = cloneFromProject && cloneFromProject.id

      if (cloneFromProject) {
        projectToSave = ProjectCloner.cloneProject(project, cloneFromProject)
      }

      dispatch(ProjectActions.saveNewProject(projectToSave, cloneFromProjectId, ProjectWizardActions.finishProcessed))
    }
  }

  static saveProject(project: Project, cloneFromProjectId?: string, successAction?: () => any): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      if (!project.id) {
        dispatch(ProjectActions.saveNewProject(project, cloneFromProjectId, successAction))
      } else {
        dispatch(ProjectActions.updateProject(project, successAction))
      }
    }
  }

  static openBranchConfirmation(confirmActionCreator: () => any): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({
        type: ActionTypes.PROJECT_WIZARD_OPEN_BRANCH_CONFIRMATION,
        payload: {confirmActionCreator}
      })
    }
  }

  static closeBranchConfirmation(): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.PROJECT_WIZARD_CLOSE_BRANCH_CONFIRMATION})
    }
  }

  static onWebRootChange(envId: string, oldWebroot: string, newWebroot: string): any {
    return async (dispatch: Dispatch<Action>, goToState: () => IStoreState): Promise<any> => {
      const {project} = goToState().projectWizard
      const updatedProjectEnvironments: ProjectEnvironment[] = project.projectEnvironments
      const projectEnvironmentCodeToUpdate: ProjectEnvironment = updatedProjectEnvironments.find((projEnv: ProjectEnvironment) => projEnv.environmentId === envId)

      if (projectEnvironmentCodeToUpdate && projectEnvironmentCodeToUpdate.symLinks && projectEnvironmentCodeToUpdate.symLinks.length > 0) {
        projectEnvironmentCodeToUpdate.symLinks.forEach((symLink: SymLink) => {
          symLink.from = symLink.from.replace(oldWebroot, newWebroot)
        })

        dispatch({
          type: ActionTypes.UPDATE_PROJECT_WIZARD_FIELD,
          payload: {
            key: 'projectEnvironments',
            value: updatedProjectEnvironments
          }
        })
      }
    }
  }

  static openProject(project: Project, cloneToNew: boolean = false): any {
    return (dispatch: Dispatch<Action>, getState: () => IStoreState): void => {
      dispatch({type: ActionTypes.OPEN_PROJECT_WIZARD, payload: {project, cloneToNew}})
    }
  }

  static setProjectVisibility(value: boolean, dispatch: Dispatch<Action>): any {
    dispatch({type: ActionTypes.PROJECT_WIZARD_UPDATE_PROPERTY,
      payload: {
        key: 'isRepoPublic',
        value: value
      }})
  }

}


