import { EnvironmentSelectionConfig } from "../../components/project/project-details/section/environments/project-environment.config";

export const Update_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS = 'Update_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS'


export interface UpdateProjectDataSetProjectEnvironmentsAction {
    type: typeof Update_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS
    payload: EnvironmentSelectionConfig[]
}

export type UpdateProjectDataActionTypes =
    | UpdateProjectDataSetProjectEnvironmentsAction
