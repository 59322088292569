import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { CUSTOM } from 'src/theme/lib/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: CUSTOM.breadcrumbFadedBlue,
      fontSize:'17px'
    },
    context: {
      color: CUSTOM.breadcrumbFadedBlue,
      paddingLeft: '20px',
      fontSize: '22px',
    },
    path: {
      color: theme.palette.common.white,
      marginLeft: '20px',
      fontSize:'22px',
    },
    icon2: {
        color: '#8995a1',
        fontSize:'17px'
    },
    context2: {
        color: '#8995a1',
        paddingLeft: '20px',
        fontSize:'22px',
    },
    path2: {
        color: '#2e4362',
        marginLeft: '20px',
        fontSize:'22px',
    },
    padding: {
        paddingLeft:'10px',
        paddingTop:'10px'
    },
  }),
)
