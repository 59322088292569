import React from 'react'
import {Grid} from '@material-ui/core'
import {ButtonConfig} from './project-users.config'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {UsersButton} from "./project-users-button.component";


export interface IProjectUsersTitleProps {
  style?: any,
  resetButtonConfig: ButtonConfig,
  saveButtonConfig: ButtonConfig,

}

export const ProjectUsersTitleBar: React.FC<IProjectUsersTitleProps> = ({style, resetButtonConfig, saveButtonConfig}) => {

  const styles = style

  return (
    <Grid container direction='row' alignItems='center' justifyContent="space-between" style={styles.component}>

      <Grid item>
        <FontAwesomeIcon icon={faUsers} size='2x' style={styles.title.icon}/>
        <h2 style={styles.title.text}>Users</h2>
      </Grid>

      <Grid item>
        <UsersButton buttonConfig={resetButtonConfig}/>
        <span style={styles.buttonGroup}/>
        <UsersButton buttonConfig={saveButtonConfig}/>
      </Grid>

    </Grid>
  )

}
