import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(1),
    },
    layout: {
      padding: theme.spacing(3),
    }
  }),
)
