import React from 'react'
import './Spinner.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons'
import {SizeProp} from '@fortawesome/fontawesome-svg-core'

interface ISpinnerProps {
  small?: boolean,
  large?: boolean,
  xlarge?: boolean
}

class Spinner extends React.Component<ISpinnerProps> {
  static defaultProps: Partial<ISpinnerProps> = {
    small: false,
    large: false,
    xlarge: false
  }

  getSize =(): SizeProp => {
    const {small, large, xlarge} = this.props
    if (small) {
      return 'sm'
    }
    if (large) {
      return '3x'
    }
    if (xlarge) {
      return '6x'
    }
    return '1x'
  }

  render(): React.ReactNode {
    return (
      <FontAwesomeIcon icon={faCircleNotch} className='spinner' spin size={this.getSize()}/>
    )
  }
}

export default Spinner
