import { hasUndefinedProjectEnvironmentAssets } from 'src/helpers/project/environment/asset/env-asset-validation'
import { hasUndefinedProjectEnvironmentCode } from 'src/helpers/project/environment/code/env-code-validation'
import { EnvironmentProcessIO } from 'src/helpers/project/environment/environment-processor'
import { createProcessIO } from 'src/helpers/shared'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import ProjectType from 'src/models/ProjectType'
import { createDefaultProjectEnvironmentAssetsForCreateProjectDataWorkflow } from './create-project-env-asset-transformation'

export interface CreateProjectAssetProcessContext {
  index: number
  projectName: string
  deploymentEnvironment: DeploymentEnvironment
  projectTypeConfig?: ProjectType
}

export function createProjectEnvironmentWithAssetDefaultsFromProjectTypeConfig(
  input: EnvironmentProcessIO,
  context: CreateProjectAssetProcessContext,
): EnvironmentProcessIO {
  const result = hasUndefinedProjectEnvironmentAssets(input.value)
  if (result) {
    const env = input.value
    const { deploymentEnvironment, projectName, projectTypeConfig } = context
    const hasCode = !hasUndefinedProjectEnvironmentCode(env)
    const webroot = env?.projectEnvironmentCode?.webroot || ''
    const projectEnvironmentAssets = createDefaultProjectEnvironmentAssetsForCreateProjectDataWorkflow(
      projectName,
      deploymentEnvironment,
      hasCode,
      webroot,
      projectTypeConfig,
    )
    const updatedEnvironment = new ProjectEnvironment({
      projectEnvironmentAssets,
      order: env.order,
      environmentId: env.environmentId,
      projectEnvironmentCode: env.projectEnvironmentCode,
      projectEnvironmentDatabases: env.projectEnvironmentDatabases,
      symLinks: env.symLinks,
    })
    return createProcessIO(updatedEnvironment, input.errors)
  }
  return input
}
