import React from 'react'
import {filter, find, maxBy} from 'lodash'

import {Grid, IconButton} from '@material-ui/core'

import {PROJECT_ACTION_TYPES, PROJECT_PAGE_ACTIONS} from '../ProjectPageConstants'
import PreviewModal from './PreviewModal'
import MergeCodeModal from './MergeCodeModal'
import MergeAssetsModal from './MergeAssetsModal'
import CopyDatabaseModal from './CopyDatabaseModal'
import DatabaseSnapshotModal from './DatabaseSnapshotModal'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCodeBranch, faExternalLinkSquareAlt, faTag} from '@fortawesome/free-solid-svg-icons'

import ConfirmDialog from 'src/components/common/ConfirmDialog'
import SnackbarService, {SNACKBAR_TYPES} from 'src/services/SnackbarService'
import EnvironmentCodeSection from './code-section/EnvironmentCodeSection'
import EnvironmentAssetsSection from './assets-section/EnvironmentAssetsSection'
import EnvironmentDatabasesSection from './databases-section/EnvironmentDatabasesSection'
import DeploymentEnvironmentHelper from 'src/helpers/DeploymentEnvironmentHelper'

import Project from 'src/models/Project'
import {ACTION_TYPE} from 'src/models/pipeline/Action'
import PipelineService from 'src/services/PipelineService'
import ProjectEvent from 'src/models/pipeline/ProjectEvent'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import PipelineTrigger, {PIPELINE_TRIGGER_TYPE} from 'src/models/pipeline/PipelineTrigger'

import './DeploymentTrack.scss'

export interface IDeploymentTrackState {
  action: string,
  actionPayload: any
}

export interface IDeploymentTrackProps {
  environment: ProjectEnvironment,
  environments: ProjectEnvironment[],
  project: Project,
  latestEvents: ProjectEvent[]
}

class DeploymentTrack extends React.Component<IDeploymentTrackProps, IDeploymentTrackState> {
  constructor(props: IDeploymentTrackProps) {
    super(props)
    this.state = {
      action: null,
      actionPayload: null
    }
  }

  handlePreviewClick = (): void => this.setState({action: PROJECT_PAGE_ACTIONS.PREVIEW})
  handleMergeCodeClick = (): void => this.setState({action: PROJECT_PAGE_ACTIONS.MERGE_CODE})
  handleCopyDatabaseClick = (): void => this.setState({action: PROJECT_PAGE_ACTIONS.COPY_DATABASE})
  handleMergeAssetsClick = (): void => this.setState({action: PROJECT_PAGE_ACTIONS.MERGE_ASSETS})
  handleModalClose = (): void => this.setState({action: null})
  handleCodeDeployClick = (): void => this.setState({action: PROJECT_PAGE_ACTIONS.DEPLOY_CODE})
  handlePromoteCodeClick = (): void => this.setState({action: PROJECT_PAGE_ACTIONS.PROMOTE_CODE})
  handleDatabaseSnapshotClick = (): void => this.setState({action: PROJECT_PAGE_ACTIONS.DATABASE_SNAPSHOT})
  handleCancelPipeline = (pipelineId: string, actionType: ACTION_TYPE): void => this.setState({
    action: PROJECT_PAGE_ACTIONS.CANCEL_PIPELINE,
    actionPayload: {pipelineId, actionType}
  })

  deployCode = async (): Promise<void> => {
    const {project, environment} = this.props

    this.handleModalClose()

    const triggers: PipelineTrigger[] = [
      new PipelineTrigger({type: PIPELINE_TRIGGER_TYPE.ACTION_TYPE, value: ACTION_TYPE.CODE_DEPLOY}),
      new PipelineTrigger({type: PIPELINE_TRIGGER_TYPE.TARGET_ENV, value: environment.environmentId})
    ]

    try {
      await PipelineService.triggerPipeline(project.id, triggers)
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Triggered Code Deploy Pipeline.')
    } catch (error) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, error || 'Failed to trigger Code Deploy Pipeline!')
    }
  }

  promoteCode = async (): Promise<void> => {
    const {project, environment} = this.props
    const nextEnv: ProjectEnvironment = project.projectEnvironments.find((projEnv: ProjectEnvironment) =>
      projEnv.order === environment.order + 1)

    const triggers: PipelineTrigger[] = [
      new PipelineTrigger({type: PIPELINE_TRIGGER_TYPE.ACTION_TYPE, value: ACTION_TYPE.CODE_PROMOTE}),
      new PipelineTrigger({type: PIPELINE_TRIGGER_TYPE.SOURCE_ENV, value: environment.environmentId}),
      new PipelineTrigger({type: PIPELINE_TRIGGER_TYPE.TARGET_ENV, value: nextEnv.environmentId})
    ]

    this.handleModalClose()
    try {
      await PipelineService.triggerPipeline(project.id, triggers)
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Triggered Code Promote Pipeline.')
    } catch (error) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, error || 'Failed to trigger Code Promote Pipeline!')
    }
  }

  cancelPipeline = async (): Promise<void> => {
    const {actionPayload} = this.state

    this.handleModalClose()
    try {
      await PipelineService.cancelPipeline(actionPayload.pipelineId)
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Triggered Pipeline Cancellation.')
    } catch (error) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, error || 'Failed to trigger Pipeline Cancellation!')
    }
  }

  getLatestCodeEvent(): ProjectEvent {
    const deployment: ProjectEvent = this.getLatestEventOfType(ACTION_TYPE.CODE_DEPLOY)
    const promotion: ProjectEvent = this.getLatestEventOfType(ACTION_TYPE.CODE_PROMOTE)
    const merge: ProjectEvent = this.getLatestEventOfType(ACTION_TYPE.CODE_MERGE)

    const codeProjectEvents: ProjectEvent[] = filter([deployment, promotion, merge], (projectEvent: ProjectEvent) => !!projectEvent)
    return maxBy(codeProjectEvents, (projectEvent: ProjectEvent) => projectEvent.action.startTime || projectEvent.pipeline.startTime)
  }

  getLatestAssetEvent(): ProjectEvent {
    return this.getLatestEventOfType(ACTION_TYPE.ASSET_MERGE)
  }

  getLatestDatabaseEvent(): ProjectEvent {
    const deployment: ProjectEvent = this.getLatestEventOfType(ACTION_TYPE.DATABASE_COPY)
    const snapshot: ProjectEvent = this.getLatestEventOfType(ACTION_TYPE.DATABASE_SNAPSHOT)
    const dbimport: ProjectEvent = this.getLatestEventOfType(ACTION_TYPE.DATABASE_IMPORT)

    const dbProjectEvents: ProjectEvent[] = filter([deployment, snapshot, dbimport], (projectEvent: ProjectEvent) => !!projectEvent)
    return maxBy(dbProjectEvents, (projectEvent: ProjectEvent) => projectEvent.action.startTime || projectEvent.pipeline.startTime)
  }

  getLatestEventOfType(actionType: ACTION_TYPE): ProjectEvent {
    return find(this.props.latestEvents, (event: ProjectEvent) => event.action.actionConfiguration.actionType === actionType)
  }

  getActionTypeDisplayName(actionType: ACTION_TYPE): string {
    return PROJECT_ACTION_TYPES[actionType.toString()].displayName
  }

  render = (): React.ReactNode => {
    const {project, environment, environments} = this.props
    const {action, actionPayload} = this.state

    const environmentTitle: string = DeploymentEnvironmentHelper.getEnvironmentTitleById(environment.environmentId)
    const nextEnv: ProjectEnvironment = environments.find((env: ProjectEnvironment) => env.order === environment.order + 1)
    const nextEnvTitle: string = nextEnv && DeploymentEnvironmentHelper.getEnvironmentTitleById(nextEnv.environmentId)
    const deployConfirmMessage: string = environment.projectEnvironmentCode ? `Are you sure you want to checkout <b>${environment.projectEnvironmentCode.branch.name}</b> onto all machines in 
      the <b>${environmentTitle}</b> environment? Any local changes will be lost.` : ''
    const textColor = 'white'
    return (
      <div className="deploymentTrack" key={environment.environmentId}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <Grid className="deploymentTrackHeader" container alignItems='center' justify="space-between" wrap="nowrap">
              <Grid item>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                  <Grid item>
                    <h3 style={{ color: textColor }}>{environmentTitle}</h3>
                  </Grid>
                  <Grid item>
                    <IconButton title='Preview' onClick={this.handlePreviewClick}>
                      <FontAwesomeIcon icon={faExternalLinkSquareAlt} size='sm'
                                       style={{cursor: 'pointer', color: textColor, padding: '0'}}/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {environment.projectEnvironmentCode &&
                <Grid container spacing={2}>
                  {environment.projectEnvironmentCode.currentTag &&
                  <Grid item><FontAwesomeIcon icon={faTag}/> {environment.projectEnvironmentCode.currentTag}</Grid>
                  }
                  <Grid item><FontAwesomeIcon icon={faCodeBranch}/> {environment.projectEnvironmentCode.branch.name}</Grid>
                </Grid>
                }
              </Grid>
             </Grid>
          </Grid>

          <Grid container item style={{ padding: '8px' }}>
            <Grid className="deployment-track__body" container spacing={2} alignItems="stretch">
              <Grid item xs={4}>
                {project.hasCode && environment.projectEnvironmentCode && (
                  <EnvironmentCodeSection project={project}
                                          projectEnvironment={environment}
                                          latestEvent={this.getLatestCodeEvent()}
                                          handleMergeCodeClick={this.handleMergeCodeClick}
                                          handlePromoteCodeClick={this.handlePromoteCodeClick}
                                          handleCodeDeployClick={this.handleCodeDeployClick}
                                          onCancelPipeline={this.handleCancelPipeline}/>
                )}
              </Grid>
              <Grid item xs={4}>
                {project.hasAssets && environment.projectEnvironmentAssets && (
                  <EnvironmentAssetsSection project={project}
                                            environmentId={environment.environmentId}
                                            latestEvent={this.getLatestAssetEvent()}
                                            handleMergeAssetsClick={this.handleMergeAssetsClick}
                                            onCancelPipeline={this.handleCancelPipeline}/>
                )}
              </Grid>
              <Grid item xs={4}>
                {project.hasDatabases && environment.projectEnvironmentDatabases && (
                  <EnvironmentDatabasesSection project={project}
                                               environmentId={environment.environmentId}
                                               latestEvent={this.getLatestDatabaseEvent()}
                                               handleCopyDatabaseClick={this.handleCopyDatabaseClick}
                                               handleDatabaseSnapshotClick={this.handleDatabaseSnapshotClick}
                                               onCancelPipeline={this.handleCancelPipeline}/>
                )}
                </Grid>
            </Grid> 
          </Grid>
        </Grid>

        {action === PROJECT_PAGE_ACTIONS.PREVIEW &&
        <PreviewModal
          openModal={action === PROJECT_PAGE_ACTIONS.PREVIEW}
          environment={environment}
          projectUrl={project.domain}
          handleModalClose={this.handleModalClose}
        />}

        {action === PROJECT_PAGE_ACTIONS.MERGE_CODE &&
        <MergeCodeModal
          openModal={action === PROJECT_PAGE_ACTIONS.MERGE_CODE}
          environment={environment}
          handleModalClose={this.handleModalClose}
          environments={environments}
          project={project}
        />}

        {action === PROJECT_PAGE_ACTIONS.PROMOTE_CODE &&
        <ConfirmDialog open onCancel={this.handleModalClose} onConfirm={this.promoteCode} title='Promote Code'
                       message={`Are you sure you want to promote code from <b>${environmentTitle}</b> to <b>${nextEnvTitle}</b>?`}/>
        }

        {action === PROJECT_PAGE_ACTIONS.DEPLOY_CODE &&
        <ConfirmDialog
          title={'Code Deploy'}
          open={action === PROJECT_PAGE_ACTIONS.DEPLOY_CODE}
          message={deployConfirmMessage}
          onConfirm={this.deployCode}
          onCancel={this.handleModalClose}
        />}

        {action === PROJECT_PAGE_ACTIONS.CANCEL_PIPELINE &&
        <ConfirmDialog
          title={'Cancel Pipeline'}
          open={action === PROJECT_PAGE_ACTIONS.CANCEL_PIPELINE}
          message={`Are you sure want to cancel this ${this.getActionTypeDisplayName(actionPayload.actionType)}? Actions that have already been processed will not be rolled back`}
          onConfirm={this.cancelPipeline}
          onCancel={this.handleModalClose}
        />}

        {action === PROJECT_PAGE_ACTIONS.MERGE_ASSETS &&
        <MergeAssetsModal
          openModal={action === PROJECT_PAGE_ACTIONS.MERGE_ASSETS}
          environment={environment}
          handleModalClose={this.handleModalClose}
          environments={environments}
          project={project}
        />}

        {action === PROJECT_PAGE_ACTIONS.COPY_DATABASE &&
        <CopyDatabaseModal
          openModal={action === PROJECT_PAGE_ACTIONS.COPY_DATABASE}
          environment={environment}
          handleModalClose={this.handleModalClose}
          environments={environments}
          project={project}
        />}

        {action === PROJECT_PAGE_ACTIONS.DATABASE_SNAPSHOT &&
        <DatabaseSnapshotModal
          openModal={action === PROJECT_PAGE_ACTIONS.DATABASE_SNAPSHOT}
          environment={environment}
          handleModalClose={this.handleModalClose}
          project={project}
        />}
      </div>
    )
  }
}

export default DeploymentTrack
