import initialState from '../initialState'
import User from '../../models/User'
import ActionTypes from '../actions'

export default function currentUserReducer(state: User = initialState.currentUser, action: any): User {
  switch  (action.type) {
    case ActionTypes.SIGN_IN_SUCCESSFUL:
      return action.payload.currentUser
    default:
      return state
  }
}
