import React from 'react'
import User from 'src/models/User'
import { Grid } from '@material-ui/core'
import { PageLayout } from 'src/components/layout/shared'
import { ConnectedProjectCreateSection, ConnectedProjectCreateBanner } from './shared'

export interface SaveAndCancelConfig {
  isSaveDisabled: boolean
  isError: boolean
  tooltip: string
  handleSubmit: () => void
  handleCancel: () => void
}

export interface ProjectCreateSceneProps {
  currentUser: User
}

const spacing = 3

export const ProjectCreateScene: React.FC<ProjectCreateSceneProps> = () => {
  return (
    <PageLayout>
      <Grid container direction="column" spacing={spacing}>
        <Grid item>
          <ConnectedProjectCreateBanner />
        </Grid>
        <Grid item>
          <ConnectedProjectCreateSection />
        </Grid>
      </Grid>
    </PageLayout>
  )
}
