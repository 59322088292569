import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { NotificationConfig } from '../project-details.config'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: {
      color: theme.palette.primary.dark,
    },
    saveButton: {
      backgroundColor: theme.palette.primary.dark,
    },
    error: {
      color: theme.palette.error.main,
    },
  }),
)

export function resolveIcon(config: NotificationConfig): IconDefinition {
  return config.isError ? faTimesCircle : faInfoCircle
}