import React from "react";
import {FormLabel, Grid, Tooltip} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

export interface ITooltipLabel {
  label: string
  description: string
}

export const TooltipLabel: React.FC<ITooltipLabel> = ({label, description}) => {
  return (
    <Grid container spacing={1} wrap={'nowrap'}>
      <Grid item>
        <FormLabel>{label}</FormLabel>
      </Grid>
      <Grid item>
        <Tooltip title={description} placement='right'>
          <div style={{display: 'inline'}}>
            <FontAwesomeIcon icon={faInfoCircle} className='helpText'/>
          </div>
        </Tooltip>
          <FormLabel style={{ color: 'red' ,marginLeft: '5px'}}>*</FormLabel>
      </Grid>
    </Grid>
  )
}
