import React from 'react'
import { Dispatch } from "redux"
import { connect, ConnectedProps } from "react-redux"
import { Button, Grid, Tooltip, Typography } from '@material-ui/core'
import { ConnectedSelectedEnvironmentsList, ConnectedUnselectedEnvironmentsList } from '../shared'
import { useStyles } from './drupal7-environments.config'
import { FORM_STEP_TYPE } from '../forms'
import IStoreState from "../../../../store/IStoreState"
import { createProjectFormSetIsSaveDisabled } from "../../../../store/create-project-form/create-project-form.actions"
import DeploymentEnvironmentHelper, {defaultEnvironmentNonActiveMessage} from "../../../../helpers/DeploymentEnvironmentHelper"

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = { setActiveStep: (step: FORM_STEP_TYPE) => void }
export type Drupal7EnvironmentsFormProps = ComponentProps & PropsFromRedux

export const Drupal7EnvironmentsForm: React.FC<Drupal7EnvironmentsFormProps> = ({ project, setActiveStep }) => {
  const spacing = 3
  const hasNonactiveEnv = DeploymentEnvironmentHelper.containsNonActiveEnvironments(project)
  return (
    <Grid container item direction="column" spacing={spacing}>
      <SelectedList />
      <UnselectedList />
      <StepButtons setActiveStep={setActiveStep} isDisabled={hasNonactiveEnv} tooltip={defaultEnvironmentNonActiveMessage} />
    </Grid>
  )
}

interface SelectedListProps {}

const SelectedList: React.FC<SelectedListProps> = () => {
  const styles = useStyles()
  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1">
          Add, remove, or reorder your environments. The development and production environments are required.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={styles.titleText}>
          Selected Environments
        </Typography>
      </Grid>
      <Grid item>
        <ConnectedSelectedEnvironmentsList />
      </Grid>
    </Grid>
  )
}

interface UnselectedListProps {}

const UnselectedList: React.FC<UnselectedListProps> = () => {
  const styles = useStyles()
  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1" className={styles.titleText}>
          Deselected Environment
        </Typography>
      </Grid>
      <Grid item>
        <ConnectedUnselectedEnvironmentsList />
      </Grid>
    </Grid>
  )
}

export interface StepButtonProps {
  setActiveStep: (type: FORM_STEP_TYPE) => void
  isDisabled: boolean
  tooltip: string
}

const StepButtons: React.FC<StepButtonProps> = ({ setActiveStep, isDisabled, tooltip }) => {
  const goToNext = () => {
    setActiveStep(FORM_STEP_TYPE.CODE)
  }
  const styles = useStyles()

  return (
    <Grid item container spacing={2} justify="flex-end">
      <Grid item>
        <Tooltip title={isDisabled ? tooltip : ''}>
          <span>
            <Button className={styles.nextButton}
                    onClick={goToNext}
                    color="primary"
                    variant="contained"
                    disableElevation
                    disabled={isDisabled}>
              Next: Code
            </Button>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state: IStoreState, ownProps: ComponentProps) => ({
  project: state.createProjectData.project,
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ComponentProps) => ({
  setIsSaveDisabled: (value: boolean) => {
    dispatch(createProjectFormSetIsSaveDisabled(value))
  },
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedDrupal7EnvironmentsForm = connector(Drupal7EnvironmentsForm)