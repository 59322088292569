import axios, {AxiosResponse} from 'axios'
import {classToPlain, plainToClass} from 'class-transformer'
import ProjectType from '../models/ProjectType'
import Project from '../models/Project'

class ProjectService {
  static PROJECT_API_ROOT: string = '/api/project/v5'
  static PROJECT_RESOURCE_ROOT: string = '/projects'
  static PIPELINES_API_ROOT: string = '/api/projectEvent/v1'
  static PIPELINES_RESOURCE_ROOT: string = '/pipelines'
  static PIPELINE_TEMPLATES_RESOURCE_ROOT: string = '/pipelinetemplates'
  static PROJECT_TYPE_RESOURCE_ROOT: string = '/projecttypes'
  static ACTION_CONFIGURATION_RESOURCE_ROOT: string = '/actionconfigurations'

  async getProjects(): Promise<Project[]> {
    const res: any = await axios.get(`${ProjectService.PROJECT_API_ROOT}${ProjectService.PROJECT_RESOURCE_ROOT}`)
    if (!res.data._embedded) {
      return []
    }
    return plainToClass(Project, res.data._embedded.projectResources)
  }

  async createProject(project: Project, cloneFromProjectId?: string): Promise<Project> {
    let params: string = ''
    if (cloneFromProjectId) {
      params = `?cloneFrom=${cloneFromProjectId}`
    }
    const res: AxiosResponse<Project> = await axios.post(`${ProjectService.PROJECT_API_ROOT}${ProjectService.PROJECT_RESOURCE_ROOT}${params}`, classToPlain(project))
    return plainToClass(Project, res.data)
  }

  async updateProject(project: Project): Promise<Project> {
    const plainProject: any = classToPlain(project)
    plainProject.id = plainProject._id
    const res: AxiosResponse<Project> = await axios.put(`${ProjectService.PROJECT_API_ROOT}${ProjectService.PROJECT_RESOURCE_ROOT}/${project.id}`, plainProject)
    return plainToClass(Project, res.data)
  }

  async disableProject(projectId: string): Promise<Project> {
    const res: AxiosResponse<Project> =
      await axios.patch(`${ProjectService.PROJECT_API_ROOT}${ProjectService.PROJECT_RESOURCE_ROOT}/${projectId}`, new Project({disabled: true}))
    return plainToClass(Project, res.data)
  }

  async enableProject(projectId: string): Promise<Project> {
    const res: AxiosResponse<Project> =
      await axios.patch(`${ProjectService.PROJECT_API_ROOT}${ProjectService.PROJECT_RESOURCE_ROOT}/${projectId}`, new Project({disabled: false}))
    return plainToClass(Project, res.data)
  }

  async getProjectDetails(id: string): Promise<Project> {
    const res: AxiosResponse<Project> = await axios.get(`${ProjectService.PROJECT_API_ROOT}${ProjectService.PROJECT_RESOURCE_ROOT}/${id}`)
    return plainToClass(Project, res.data)
  }

  async getProjectTypes(): Promise<ProjectType[]> {
    const res: any = await axios.get(`${ProjectService.PROJECT_API_ROOT}${ProjectService.PROJECT_TYPE_RESOURCE_ROOT}`)
    return plainToClass(ProjectType, res.data)
  }
}

export default new ProjectService()
