import {TableBody, Grid} from '@material-ui/core'

import React from 'react'
import {connect} from 'react-redux'
import Table from '@material-ui/core/Table'
import TableHeader from '../common/TableHeader'
import RetryLoading from '../common/RetryLoading'
import ProjectActions from '../../store/actions/projectActions'
import Project from '../../models/Project'
import {Dispatch} from 'redux'
import IStoreState from '../../store/IStoreState'
import ProjectTableRow from './ProjectTableRow'
import { useStyles } from './ProjectTable.config'
import {ProjectFilters} from './DashboardPage.config'

const sortDirections: any = {
  ASC: 'asc',
  DESC: 'desc',
}

interface IProjectTableProps {
  projects?: Project[],
  projectsLoading?: boolean,
  projectTypesLoading?: boolean,
  error?: any,
  loadProjects?: () => void,
  handleEditClick: (project: Project) => void
  handleDisableClick: (project: Project) => void
  handleEnableClick: (project: Project) => void
  handleCloneClick: (project: Project) => void
  projectFilter: ProjectFilters
  setOrder: any
  setOrderBy: any
  order: string
  orderBy: string
  search: string
  columns:any[]
  setColumns:any
}

export interface IProjectTableState {
  order: string,
  orderBy: string,
  search: string,
}

export const ProjectTable: React.FC<IProjectTableProps> =({
  projects, projectsLoading, projectTypesLoading, error, loadProjects,
  handleEditClick, handleDisableClick, handleEnableClick, handleCloneClick, projectFilter, setOrder, setOrderBy, order, orderBy, search,columns,setColumns}) => {

  const reloadData = (): void => {
    loadProjects()
  }

  const updateSortCriteria = (event: any, property: string, direction: string): void => {
    const orderBy: string = property
    let order: string = direction === sortDirections.DESC ? sortDirections.ASC : sortDirections.DESC

    columns.forEach((column, index: number): void => {
      if(column.id === orderBy){
        column.order = order
      }
    })

    setOrder(order)
    setOrderBy(orderBy)
    setColumns(columns)
  }

  const sortAndFilterProjects = (projects: Project[]): Project[] => {
    projects = order === sortDirections.DESC
      ? projects.sort((a: Project, b: Project) => (b[orderBy].toLowerCase() < a[orderBy].toLowerCase() ? -1 : 1))
      : projects.sort((a: Project, b: Project) => (a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1))

    // move disabled project to the end of the list
    projects.sort((a: Project, b: Project) => (!a.disabled && b.disabled) ? -1 : 1)

    //TODO: projectFilter currently only clears the search filter when ProjectFilters.ALL is selected.
    //      Once starred projects and possibly other filters are added we'll have some additional coding to do here.
    const searchText = search.toLowerCase()
    return projects.filter((project: Project) => project.name.toLowerCase().includes(searchText) || project.type.toLowerCase().includes(searchText))
  }

    const sortedProjects: Project[] = sortAndFilterProjects(projects)
    const classes = useStyles()
    return (
      <Grid container justify='center'>
        <Grid item xs={12}>
          {error && <RetryLoading message='Could not load projects.' reloadData={reloadData}/>}
          {!error && sortedProjects.length === 0 && !search &&
          <RetryLoading message='There are currently no projects.' reloadData={reloadData}/>}
          {!error && sortedProjects.length === 0 && search && search.length > 0 &&
          <Grid container justify='center'>No projects match your search</Grid>
          }
          {!error && sortedProjects.length > 0 &&
            <Table className={classes.table} style={{borderRadius: '8px'}}>
              <TableHeader columns={columns} onRequestSort={updateSortCriteria} order={order} orderBy={orderBy}/>
              <TableBody>
                {sortedProjects.map((project: Project) => {
                  return <ProjectTableRow key={project.id} project={project} handleEditClick={handleEditClick}
                                          handleEnableClick={handleEnableClick} handleDisableClick={handleDisableClick}
                                          handleCloneClick={handleCloneClick}/>
                })}
              </TableBody>
            </Table>
          }
        </Grid>
      </Grid>
    )
}

const mapStateToProps: any = (state: IStoreState, ownProps: IProjectTableProps): any => ({
  projects: state.projects.data,
  projectsLoading: state.projects.loading,
  projectTypesLoading: state.projectTypes.loading,
  error: state.projects.error
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IProjectTableProps): any => ({
  loadProjects: (): void => dispatch(ProjectActions.loadProjects())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectTable)

