import React from 'react'
import Moment from 'react-moment'
import {Grid, Tooltip} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleNotch, faClock, faStopwatch, faUser} from '@fortawesome/free-solid-svg-icons'

import Nbsp from 'src/components/common/Nbsp'
import STATUS from 'src/models/pipeline/Status'
import Action from 'src/models/pipeline/Action'
import Pipeline from 'src/models/pipeline/Pipeline'
import TimerHelper from 'src/helpers/TimerHelper'
import './StatusCard.scss'

export interface IInProgressStatusCardProps {
  pipeline: Pipeline,
  actionType: string,
  actionIcon: any
}

export interface IInProgressStatusCardState {
  timer: number
}

class InProgressStatusCard extends React.Component<IInProgressStatusCardProps, IInProgressStatusCardState> {
  constructor(props: IInProgressStatusCardProps) {
    super(props)
    this.state = {
      timer: 0,
    }
    this.startCalculationsInterval()
  }

  componentWillMount = (): void => {
    const {pipeline} = this.props
    const estimatedDuration: number = pipeline.actions.reduce((duration: number, action: Action) => duration + action.estimatedDuration, 0)
    let timerInS: number = (pipeline.startTime.getTime() - Date.now()) / 1000
    timerInS += estimatedDuration
    this.setState({timer: Math.round(timerInS)})
  }

  startCalculationsInterval = (): void => {
    setInterval(() => {
      const {timer} = this.state

      if (timer > 0) {
        this.setState({
          timer: timer - 1
        })
      }
    }, 1000)
  }

  render(): React.ReactNode {
    const {actionIcon, actionType, pipeline} = this.props
    const {timer} = this.state
    const showTimeRemaining: boolean = timer > 0
    const showRunning: boolean = timer <= 0 && pipeline.status === STATUS.STARTED

    return (
      <Grid container className='full-height'>
        <Grid item sm={12}>
          <h5>In Progress</h5>
        </Grid>
        <Grid item sm={12} className='statusCardBody inProgress'>
          <Grid container alignItems='stretch' justify='space-between'>
            <Grid item sm={8}>
              <Grid container direction='column' spacing={2}>
                <Grid item className='actionType'>
                  <FontAwesomeIcon icon={actionIcon}/> {actionType.toLowerCase()}
                </Grid>
                <Grid item className='actionDetails'>
                  <FontAwesomeIcon icon={faClock} className='xsIcon' size='xs'/><Nbsp/>
                  <Moment format='M/D/YY h:mma' date={pipeline.startTime}/>
                  <br/>
                  <FontAwesomeIcon icon={faUser} className='xsIcon' size='xs'/> {pipeline.createdBy}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction='column' alignItems='flex-end' justify='space-between' sm={4}>
              <Grid item sm={12}>
                <FontAwesomeIcon icon={faCircleNotch} size='2x' spin/>
              </Grid>
              <Grid item className='timer'>
                {showTimeRemaining &&
                <Tooltip title='Estimated time remaining' placement='top' enterDelay={50}>
                  <span><FontAwesomeIcon icon={faStopwatch} className='xsIcon' size='sm'/> {TimerHelper.getTime(this.state.timer)}</span>
                </Tooltip>
                }
                {showRunning && 'Running...'}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default InProgressStatusCard
