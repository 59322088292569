
import { hasUndefinedProjectEnvironmentDatabases } from 'src/helpers/project/environment/database/env-database-validation'
import { EnvironmentProcessIO } from 'src/helpers/project/environment/environment-processor'
import { createProcessIO } from 'src/helpers/shared'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import { createDefaultProjectEnvironmentDatabases } from './create-project-env-database-transformation'

export interface DatabaseProcessContext {
  index: number
  projectName: string
  deploymentEnvironment: DeploymentEnvironment
  associationIds: number[]
}

export function createProjectEnvironmentWithDatabaseDefaultsForCreateProjectWorkflow(
  input: EnvironmentProcessIO,
  context: DatabaseProcessContext,
): EnvironmentProcessIO {
  const result = hasUndefinedProjectEnvironmentDatabases(input.value)
  if (result) {
    const env = input.value
    const { deploymentEnvironment, projectName } = context
    const projectEnvironmentDatabases = createDefaultProjectEnvironmentDatabases(
      projectName,
      deploymentEnvironment,
    )
    const updatedEnvironment = new ProjectEnvironment({
      projectEnvironmentDatabases,
      order: env.order,
      environmentId: env.environmentId,
      projectEnvironmentCode: env.projectEnvironmentCode,
      projectEnvironmentAssets: env.projectEnvironmentAssets,
      symLinks: env.symLinks,
    })
    return createProcessIO(updatedEnvironment, input.errors)
  }
  return input
}
