import React from 'react'
import {map} from 'lodash'
import Project from 'src/models/Project'
import RetryLoading from 'src/components/common/RetryLoading'
import LoadingOverlay from 'src/components/common/LoadingOverlay'
import IStoreState from 'src/store/IStoreState'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Spinner from 'src/components/common/Spinner'
import {PipelineTemplate} from 'src/models/pipeline/PipelineTemplate'
import PipelineTemplateTable from './PipelineTemplateTable'
import {PipelineTemplateActions} from 'src/store/actions/pipelineTemplateActions'
import PipelineTemplateEditor from './PipelineTemplateEditor'
import {Grid} from '@material-ui/core'
import {faInfoCircle, faPlus, faWaveSquare} from '@fortawesome/free-solid-svg-icons'
import ActionTypes from 'src/store/actions'
import PipelineActions from 'src/store/actions/pipelineActions'
import PipelineService from 'src/services/PipelineService'
import Pipeline from 'src/models/pipeline/Pipeline'
import User from 'src/models/User'

export interface IPipelineTemplateSectionProps {
  project?: Project
  pipelineTemplates?: PipelineTemplate[]
  projectLoading?: boolean
  pipelineTemplatesLoading?: boolean
  error?: any
  latestPipelines?: Pipeline[],
  currentUser?: User,
  loadProjectById?: (id: string) => void
  loadProjectPipelineTemplates?: (id: string) => void
  openPipelineTemplateEditor?: boolean
  handleCreatePipelineTemplate?: (pipelineTemplate: PipelineTemplate) => void,
  loadLatestPipelines?: (projectId: string, pipelineTemplateIds: string[]) => void,
  onRunPipeline?: (pipeline: Pipeline,id: string,pipelineTemplateIds: string[]) => void,
  onCancelPipeline?: (pipelineId: string,id: string) => void
}

export interface IPipelineTemplateSectionState {
  hasInitiallyLoaded: boolean
}

export class PipelineTemplateSection extends React.Component<IPipelineTemplateSectionProps, IPipelineTemplateSectionState> {
  private POLL_RATE: number = 5000
  private poll: any

  constructor(props: IPipelineTemplateSectionProps) {
    super(props)
    this.state = {
      hasInitiallyLoaded: false
    }
  }

  componentWillMount(): void {
    this.loadProjectPipelineTemplates()
    this.loadLatestPipelines()
    this.pollRecentPipelines()
    PipelineService.subscribeToProjectActions(this.loadLatestPipelines)
  }

  componentWillUnmount(): void {
    clearInterval(this.poll)
    PipelineService.unsubscribeFromProjectActions(this.loadLatestPipelines)
  }

  componentDidUpdate(prevProps: Readonly<IPipelineTemplateSectionProps>, prevState: Readonly<IPipelineTemplateSectionState>): void {
    if (!prevProps.pipelineTemplates && this.props.pipelineTemplates) {
      this.loadLatestPipelines()
    }
  }

  openNewPipelineConfigEditor = (): void => {
    const {project, handleCreatePipelineTemplate} = this.props

    const pipelineTemplate: PipelineTemplate = new PipelineTemplate({projectId: project.id})
    handleCreatePipelineTemplate(pipelineTemplate)
  }

  loadLatestPipelines = (): void => {
    const {project, pipelineTemplates, loadLatestPipelines} = this.props
    if (pipelineTemplates) {
      loadLatestPipelines(project.id, map(pipelineTemplates, 'id'))
    }
  }

  pollRecentPipelines(): void {
    this.poll = setInterval(() => {
      this.loadLatestPipelines()
    }, this.POLL_RATE)
  }

  onRunPipeline = (pipeline: Pipeline): void => {
    const {onRunPipeline,project,pipelineTemplates} = this.props
    onRunPipeline(pipeline,project.id,map(pipelineTemplates, 'id'))
  }

  onCancelPipeline = (pipelineId: string): void => {
    const {onCancelPipeline,project} = this.props
    onCancelPipeline(pipelineId,project.id)
  }
  loadProjectPipelineTemplates = (): void => {
    this.props.loadProjectPipelineTemplates(this.props.project.id)
  }

  render = (): React.ReactNode => {
    const {projectLoading, pipelineTemplatesLoading, error, pipelineTemplates, currentUser, project, latestPipelines} = this.props
    const loading: boolean = projectLoading || pipelineTemplatesLoading

    if (error) {
      return <RetryLoading message='Could not load pipeline templates.'
                           reloadData={this.loadProjectPipelineTemplates}/>
    } else if (loading || !pipelineTemplates || !latestPipelines) {
      return <div className='loading-overlay-parent-full'><LoadingOverlay/></div>
    } else {
      return (
        <div>
          <Grid container>
            <Grid container>
              <Grid container>
                <Grid item>
                  <Grid container direction='row' alignItems='center' spacing={3}>
                    <Grid item>
                      <FontAwesomeIcon icon={faWaveSquare} size='2x'/>
                    </Grid>
                    <Grid item>
                      <h2>Pipelines</h2>
                    </Grid>
                    <Grid item style={{color: '#ccc'}}>
                      <Tooltip placement='top' enterDelay={50}
                               title='A pipeline template contains triggers and configurations'>
                        <FontAwesomeIcon icon={faInfoCircle}/>
                      </Tooltip>
                    </Grid>
                    {
                      currentUser.canAdministerPipelinesForProject(project.id) &&
                      <Grid item>
                        <IconButton id='ab-dt-add-button'
                                    style={{marginBottom: '4px'}} title='Create New Pipeline Template'
                                    onClick={this.openNewPipelineConfigEditor}>
                          <FontAwesomeIcon
                            icon={faPlus} size='sm'
                            className={'clickable'}/>
                        </IconButton>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                {loading && <Grid container justify='center'><Spinner large/></Grid>}
                {error && <RetryLoading message='Could not load pipeline templates.'
                                        reloadData={this.loadProjectPipelineTemplates}/>}
                {!error && (pipelineTemplates && !pipelineTemplates.length) &&
                <RetryLoading message='There were no pipeline templates.'
                              reloadData={this.loadProjectPipelineTemplates}/>
                }
                {!loading && !error && pipelineTemplates && pipelineTemplates.length > 0 &&
                <PipelineTemplateTable pipelineTemplates={pipelineTemplates} latestPipelines={latestPipelines || []} currentUser={currentUser}
                                       onRunPipeline={this.onRunPipeline} onCancelPipeline={this.onCancelPipeline}/>
                }
              </Grid>
            </Grid>
          </Grid>
          {this.props.openPipelineTemplateEditor &&
          <PipelineTemplateEditor onClose={this.loadProjectPipelineTemplates}/>}
        </div>
      )
    }
  }
}


const mapStateToProps: any = (state: IStoreState): any => ({
  project: state.selectedProject.project,
  projectLoading: state.selectedProject.loading,
  error: state.selectedProject.error,
  pipelineTemplates: state.pipelineTemplates.data,
  pipelineTemplatesLoading: state.pipelineTemplates.loading,
  openPipelineTemplateEditor: state.pipelineTemplateEditor.open,
  latestPipelines: state.pipelines.data,
  currentUser: state.currentUser,
})

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  handleCreatePipelineTemplate: (pipelineTemplate: PipelineTemplate): void => {
    dispatch({type: ActionTypes.PIPELINE_TEMPLATE_EDITOR_OPEN, payload: {pipelineTemplate}})
  },
  loadProjectPipelineTemplates: (projectId: string): void => dispatch(PipelineTemplateActions.loadPipelineTemplates(projectId)),
  loadLatestPipelines: (projectId: string, pipelineTemplateIds: string[]): void => dispatch(PipelineActions.loadLatestPipelinesForPipelineTemplates(projectId, pipelineTemplateIds)),
  onRunPipeline: (pipeline: Pipeline): void => dispatch(PipelineActions.executePipeline(pipeline)),
  onCancelPipeline: (pipelineId: string,projectId: string): void => dispatch(PipelineActions.cancelPipeline(pipelineId,projectId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PipelineTemplateSection)
