import React from 'react'
import PropTypes from 'prop-types'
import Project from '../../../../../models/Project'
import '../DeploymentTrack.scss'
import {faFileArchive} from '@fortawesome/free-solid-svg-icons'
import {Button, Tooltip} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import NoPermissionButton from '../NoPermissionButton'
import {PermissionHelper} from '../../../../../helpers/PermissionHelper'
import {ACTION_TYPE} from '../../../../../models/pipeline/Action'
import DeploymentEnvironmentHelper from "../../../../../helpers/DeploymentEnvironmentHelper"
import ProjectHelper from "../../../../../helpers/ProjectHelper";

export interface DatabaseSnapshotButtonProps {
  project: Project
  environmentId: string
  handleDatabaseSnapshotClick: any
  initializationRequired: boolean
}

class DatabaseSnapshotButton extends React.Component<DatabaseSnapshotButtonProps, { }> {

  render() {
    const {project, environmentId, handleDatabaseSnapshotClick, initializationRequired} = this.props
    const buttonText = initializationRequired ? 'Initialize Database' : 'Snapshot'

    if (PermissionHelper.hasPermissionForAction(ACTION_TYPE.DATABASE_SNAPSHOT, project, environmentId)) {
      const envIsDisabled = !ProjectHelper.isProjectEnvironmentDatabaseActionValid(project, environmentId)
      return (
        <Tooltip title={envIsDisabled ? DeploymentEnvironmentHelper.getInactiveEnvironmentTooltipTitle(environmentId) : ''}>
          <span>
            <Button onClick={handleDatabaseSnapshotClick}
                    variant='contained'
                    color='primary'
                    disabled={envIsDisabled}>
              <FontAwesomeIcon icon={faFileArchive} className='buttonIcon'/> {buttonText}
            </Button>
          </span>
        </Tooltip>
      )
    }

    return <NoPermissionButton icon={faFileArchive} text={buttonText}/>
  }
}

// @ts-ignore
DatabaseSnapshotButton.propTypes = {
  handleDatabaseSnapshotClick: PropTypes.func.isRequired,
  environmentId: PropTypes.string.isRequired,
  project: PropTypes.instanceOf(Project).isRequired,
  initializationRequired: PropTypes.bool
}

// @ts-ignore
DatabaseSnapshotButton.defaultProps = {
  initializationRequired: false
}

export default DatabaseSnapshotButton