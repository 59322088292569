import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: '4px',
      padding: theme.spacing(2),
      minHeight: '88px',
    },
    textLabel: {},
  }),
)
