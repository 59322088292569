import React from 'react'
import {
  Collapse,
  IconButton, Table, TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Action from "../../../../../models/pipeline/Action";
import {HISTORY_ITEM_TYPES} from "../../../project-details-legacy/history/HistoryItemTypes";
import Moment from "react-moment";
import DeploymentEnvironmentHelper from "../../../../../helpers/DeploymentEnvironmentHelper";
import {TaskRow} from "./TaskRow.component";


interface IActionRowProps {
  action: Action
}

interface IActionRowState {
  task: any
  showTask: boolean,
  action: Action
}


class ActionRow extends React.Component<IActionRowProps, IActionRowState> {
  constructor(props: IActionRowProps) {
    super(props)
    this.state = {
      task: [],
      showTask: false,
      action: this.props.action
    }
  }


  openTaskLogs = (value: boolean): void => {
    this.setState({showTask: value})
  }

  render = (): React.ReactNode => {
    const {showTask, action} = this.state
    const combinedLogs = action.logs.length ? action.logs : []

    return (
      <React.Fragment>
        <TableRow hover style={{background: 'whitesmoke'}}>
          <TableCell padding='default'>{HISTORY_ITEM_TYPES[action.actionConfiguration.actionType]}</TableCell>

          <TableCell padding='none'>
            {action.startTime && (
              <>
                <Moment format='hh:mm:ss a' date={action.startTime}/><br/>
                <Moment format='MM-DD-YYYY' date={action.startTime}/>
              </>
            )}
          </TableCell>

          <TableCell padding='none'>
            {action.endTime && (
              <>
                <Moment format='hh:mm:ss a' date={action.endTime}/><br/>
                <Moment format='MM-DD-YYYY' date={action.endTime}/>
              </>
            )}
          </TableCell>

          <TableCell padding='none'>{action.duration}</TableCell>

          <TableCell padding='none'>
            {DeploymentEnvironmentHelper.getEnvironmentTitleById(action.actionConfiguration.sourceEnvironment)}
          </TableCell>

          <TableCell padding='none'>
            {DeploymentEnvironmentHelper.getEnvironmentTitleById(action.actionConfiguration.targetEnvironment)}
          </TableCell>

          <TableCell padding='none'>{action.status}</TableCell>

          <TableCell padding='none'>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => this.openTaskLogs(!this.state.showTask)}
            >
              {showTask ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </IconButton>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{padding: 0}} colSpan={8}>

            <Collapse in={showTask} timeout="auto" unmountOnExit>

              <Table aria-label={'task-log'}>
                <TableBody>
                  {combinedLogs.length ?
                    TaskRow(combinedLogs)
                    :
                    <TableRow>
                      <TableCell style={{background: 'black'}} colSpan={8}>
                          <span style={{
                            background: 'black',
                            color: 'whitesmoke',
                            fontWeight: 700,
                            fontSize: '14px',
                          }}>Logs not available for pending actions</span>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>

            </Collapse>

          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

}

export default ActionRow
