import React from 'react'
import {Card, CardActions, CardContent, CardHeader, Grid} from '@material-ui/core'
import Project from 'src/models/Project'
import STATUS from 'src/models/pipeline/Status'
import ProjectEvent from 'src/models/pipeline/ProjectEvent'
import {ACTION_TYPE} from 'src/models/pipeline/Action'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import DeploymentEnvironmentHelper from 'src/helpers/DeploymentEnvironmentHelper'
import CancelButton from '../CancelButton'
import MergeCodeButton from './MergeCodeButton'
import DeployCodeButton from './DeployCodeButton'
import StatusCard from '../status/StatusCard'
import '../DeploymentTrack.scss'

export interface IEnvironmentCodeSectionProps {
  latestEvent?: ProjectEvent
  handleMergeCodeClick: () => void
  handleCodeDeployClick: () => void
  handlePromoteCodeClick: () => void
  projectEnvironment: ProjectEnvironment
  project: Project
  onCancelPipeline: (pipelineId: string, actionType: ACTION_TYPE) => void
}

export interface IEnvironmentCodeSectionState {

}

class EnvironmentCodeSection extends React.Component<IEnvironmentCodeSectionProps, IEnvironmentCodeSectionState> {
  onCancelPipeline = (): void => {
    const {latestEvent, onCancelPipeline} = this.props

    onCancelPipeline(latestEvent.pipeline.id, latestEvent.action.actionConfiguration.actionType)
  }

  render(): React.ReactNode {
    const {project, projectEnvironment, latestEvent, handleMergeCodeClick, handleCodeDeployClick, handlePromoteCodeClick} = this.props
    const {CANCELLING, PENDING, STARTED} = STATUS

    const pipelineActive: boolean = !!latestEvent && [PENDING, STARTED].includes(latestEvent.pipeline.status)
    const pipelineCancelling: boolean = !!latestEvent && latestEvent.pipeline.status === CANCELLING
    const initializationRequired: boolean = !project.environmentInitializedForCode(projectEnvironment.environmentId)
    const envHasBeenDeployedTo: boolean = !!project.projectEnvironments.find((projEnv: ProjectEnvironment) =>
      projEnv.environmentId === projectEnvironment.environmentId).projectEnvironmentCode.currentTag
    const isCurrentEnvTagBased: boolean = project.isEnvironmentTagBased(projectEnvironment)
    const isProd: boolean = DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projectEnvironment.environmentId).isProd

    const showDeploy: boolean = !pipelineActive && !pipelineCancelling
    const showMerge: boolean = !pipelineActive && !pipelineCancelling && envHasBeenDeployedTo && !(isCurrentEnvTagBased && isProd)
    const showCancel: boolean = pipelineActive
    const height = '180px'
    return (
      <Card className="full-height deployment-sub-section" elevation={2}>
        <CardHeader title='Code'/>
        <CardContent style={{ minHeight: height, maxHeight: height, height }}>
          {latestEvent &&
          <StatusCard projectEvent={latestEvent}/>
          }
          {!latestEvent &&
          <Grid container justify='center' alignItems='center'>
            There are no recent actions
          </Grid>
          }
        </CardContent>
        <CardActions>
          <Grid container spacing={2} justify="flex-end" style={{ padding: '8px' }}>
            {showMerge &&
            <Grid item>
              <MergeCodeButton isTagBased={isCurrentEnvTagBased}
                               project={project}
                               environmentId={projectEnvironment.environmentId}
                               handleMergeClick={handleMergeCodeClick}
                               handlePromoteClick={handlePromoteCodeClick}/>
            </Grid>
            }
            {showDeploy &&
            <Grid item>
              <DeployCodeButton project={project}
                                environmentId={projectEnvironment.environmentId}
                                handleCodeDeployClick={handleCodeDeployClick}
                                initializationRequired={initializationRequired}/>
            </Grid>
            }
            {showCancel &&
            <Grid item>
              <CancelButton handleCancelClick={this.onCancelPipeline}
                            environmentId={projectEnvironment.environmentId}
                            project={project}
                            latestEvent={latestEvent}/>
            </Grid>
            }
          </Grid>
        </CardActions>
      </Card>
    )
  }
}

export default EnvironmentCodeSection
