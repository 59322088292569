import React from 'react'
import PropTypes from 'prop-types'
import Project from '../../../../../models/Project'
import {faUndoAlt} from '@fortawesome/free-solid-svg-icons'
import {Button, Tooltip} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import NoPermissionButton from '../NoPermissionButton'
import {PermissionHelper} from '../../../../../helpers/PermissionHelper'
import {ACTION_TYPE} from '../../../../../models/pipeline/Action'
import DeploymentEnvironmentHelper from 'src/helpers/DeploymentEnvironmentHelper'
import '../DeploymentTrack.scss'
import ProjectHelper from "../../../../../helpers/ProjectHelper";

export interface DeployCodeButtonProps {
  project: Project
  environmentId: string
  initializationRequired: boolean
  handleCodeDeployClick: any
}

class DeployCodeButton extends React.Component<DeployCodeButtonProps, {}> {
  render() {
    const {project, environmentId, handleCodeDeployClick, initializationRequired} = this.props
    const buttonText = initializationRequired ? 'Deploy' : 'Redeploy'

    if (PermissionHelper.hasPermissionForAction(ACTION_TYPE.CODE_DEPLOY, project, environmentId)) {
      const envIsDisabled = !ProjectHelper.isProjectEnvironmentCodeActionValid(project, environmentId)
      return (
        <Tooltip title={envIsDisabled ? DeploymentEnvironmentHelper.getInactiveEnvironmentTooltipTitle(environmentId) : ''}>
          <span>
            <Button onClick={handleCodeDeployClick}
                    variant='contained'
                    color='primary'
                    disabled={envIsDisabled}>
              <FontAwesomeIcon icon={faUndoAlt} className='buttonIcon'/> {buttonText}
            </Button>
          </span>
        </Tooltip>
      )
    }

    return <NoPermissionButton icon={faUndoAlt} text={buttonText}/>
  }
}

// @ts-ignore
DeployCodeButton.defaultProps = {
  initializationRequired: false
}

// @ts-ignore
DeployCodeButton.propTypes = {
  handleCodeDeployClick: PropTypes.func.isRequired,
  environmentId: PropTypes.string.isRequired,
  project: PropTypes.instanceOf(Project).isRequired,
  initializationRequired: PropTypes.bool
}

export default DeployCodeButton
