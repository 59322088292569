import axios from 'axios'
import User from '../models/User'
import {get} from 'lodash'
import UserRolesAndRoleGroups from "../models/UserRolesAndRoleGroups";
import {plainToClass} from "class-transformer";

class UserService {
  static USER_API_ROOT: string = '/api/user/v2/'

  async getUsers(): Promise<User[]>{
    const res: any  = await axios.get(`${UserService.USER_API_ROOT}users`)
    return get(res.data,'_embedded.userResources', []).map((userResourceJson : User) => new User(userResourceJson))
  }

  async getUser(username: string): Promise<User> {
    const res: any = await axios.get(`${UserService.USER_API_ROOT}users/${username}`)
    return new User(res.data)
  }

  async createUser(user: User): Promise<User> {
    const res: any  = await axios.post(`${UserService.USER_API_ROOT}users`, user)
    return new User(res.data)
  }

  async updateUser(user: User): Promise<User> {
    const username: string = user.username
    delete user.username
    const res: any  = await axios.patch(`${UserService.USER_API_ROOT}users/${username}`, user)
    return new User(res.data)
  }

  async batchUpdateUsers(users: User[], projectId: String): Promise<User[]> {
    const res: any  = await axios.patch(`${UserService.USER_API_ROOT}users/batchupdate/${projectId}`, users)
    return res.data
  }

  async getRoles(): Promise<any[]> {
    const res: any  = await axios.get(`${UserService.USER_API_ROOT}roles`)
    return res.data
  }

  async getRoleGroups(): Promise<any[]> {
    const res: any  = await axios.get(`${UserService.USER_API_ROOT}rolegroups`)
    return res.data
  }

  async getUserRolesAndRoleGroups(username: string): Promise<UserRolesAndRoleGroups> {
    const res: any = await axios.get(`${UserService.USER_API_ROOT}users/${username}/userroles`)
    return res.data
  }

  async getUserRolesByProjectId(projectId: string): Promise<User[]> {
    const res: any = await axios.get(`${UserService.USER_API_ROOT}users/project/${projectId}`)
    return res.data.map((each:User) => plainToClass(User, each))
  }

  async deleteUser(username: User): Promise<any> {
    const res: any = await axios.delete(`${UserService.USER_API_ROOT}users/${username}`)
    return res.data
  }

}

export default new UserService()
