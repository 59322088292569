import React from 'react'
import Grid from '@material-ui/core/Grid'
import { faCodeBranch, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NotificationConfig, SaveButtonConfig, ViewSourceCodeConfig, InitialDeploymentButtonConfig, InitialDeploymentNotificationConfig } from '../project-details.config'
import { Link } from '@material-ui/core'
import { Breadcrumb } from './breadcrumb.component'
import { SaveNotification } from './save-notification.component'
import { useStyles } from './banner-content.config'
import {InitialDeploymentNotification} from "./initial-deployment-notification.component";

export interface BannerContentProps {
  projectName: string
  saveConfig: SaveButtonConfig
  viewSourceCodeConfig: ViewSourceCodeConfig
  notificationConfig: NotificationConfig
  initialDeploymentNotificationConfig: InitialDeploymentNotificationConfig
  initialDeploymentConfig: InitialDeploymentButtonConfig
}

export const BannerContent: React.FC<BannerContentProps> = ({
  projectName,
  initialDeploymentConfig,
  initialDeploymentNotificationConfig,
  saveConfig,
  notificationConfig,
  viewSourceCodeConfig,
}) => {
  const styles = useStyles()
  const spacing = 2

  const renderNotification = () => {
    return notificationConfig.isShown ? (
      <Grid item>
        <SaveNotification config={notificationConfig} saveConfig={saveConfig} />
      </Grid>
    ) : null
  }

  const renderInitialDeploymentNotification = () => {
    return initialDeploymentNotificationConfig.isShown ? (
        <Grid item>
          <InitialDeploymentNotification deployConfig={initialDeploymentConfig} config={initialDeploymentNotificationConfig} />
        </Grid>
    ) : null
  }

  const renderLinks = () => {
    return (
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        wrap="nowrap"
        spacing={2}
        className={styles.linkContainer}
      >
        {renderViewSourceCodeLink()}
      </Grid>
    )
  }

  const renderViewSourceCodeLink = () => {
    return viewSourceCodeConfig.isShown ? (
      <Grid item container alignItems="center" wrap="nowrap" justify="flex-end" spacing={1}>
        <Grid item>
          <Link
            component="button"
            variant="button"
            className={styles.viewSourceLink}
            onClick={viewSourceCodeConfig.onClick}
          >
            View Source Code
          </Link>
        </Grid>
        <Grid item>
          <FontAwesomeIcon color="white" icon={faCodeBranch} size="1x" />
        </Grid>
      </Grid>
    ) : null
  }

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      wrap="nowrap"
      spacing={spacing}
      className={styles.container}
    >
      <Grid item>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <Breadcrumb icon={faHome} context="Dashboard" path={projectName} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="column" wrap="nowrap" spacing={spacing}>
        {renderLinks()}
        {renderNotification()}
        {renderInitialDeploymentNotification()}
      </Grid>
    </Grid>
  )
}
