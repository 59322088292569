import React from 'react'
import InProgressStatusCard from './InProgressStatusCard'
import {PROJECT_ACTION_TYPES} from '../../ProjectPageConstants'

import {faCode, faDatabase, faFileArchive, faFolderOpen, faLevelDownAlt, faUndoAlt, faFileImport} from '@fortawesome/free-solid-svg-icons'
import LastUpdateStatusCard from './LastUpdateStatusCard'
import FailureStatusCard from './FailureStatusCard'
import STATUS from 'src/models/pipeline/Status'
import {ACTION_TYPE} from 'src/models/pipeline/Action'
import PendingStatusCard from './PendingStatusCard'
import ProjectEvent from 'src/models/pipeline/ProjectEvent'
import CancelledStatusCard from './CancelledStatusCard'
import CancellingStatusCard from './CancellingStatusCard'

export interface IStatusCardProps {
  projectEvent: ProjectEvent
}

export interface IStatusCardState {

}

class StatusCard extends React.Component<IStatusCardProps, IStatusCardState> {
  render(): React.ReactNode {
    const {projectEvent} = this.props
    const {PENDING, STARTED, FAILURE, CANCELLING, CANCELLED} = STATUS

    const showPending: boolean = projectEvent.pipeline.status === PENDING
    const showCancelling: boolean = projectEvent.pipeline.status === CANCELLING
    const showInProgress: boolean = projectEvent.pipeline.status === STARTED
    const showFailed: boolean = projectEvent.pipeline.status === FAILURE
    const showCancelled: boolean = projectEvent.pipeline.status === CANCELLED
    const showLastUpdated: boolean = !showPending && !showCancelling && !showInProgress && !showFailed && !showCancelled

    return (
      <div className='status-card'>
        {showPending &&
        <PendingStatusCard pipeline={projectEvent.pipeline} actionType={this.actionTypeDisplayName}
                           actionIcon={this.actionIcon}/>
        }
        {showInProgress &&
        <InProgressStatusCard pipeline={projectEvent.pipeline} actionType={this.actionTypeDisplayName}
                              actionIcon={this.actionIcon}/>
        }
        {showCancelling &&
        <CancellingStatusCard pipeline={projectEvent.pipeline} actionType={this.actionTypeDisplayName}
                              actionIcon={this.actionIcon}/>
        }
        {showFailed &&
        <FailureStatusCard pipeline={projectEvent.pipeline} actionType={this.actionTypeDisplayName}
                           actionIcon={this.actionIcon}/>
        }
        {showCancelled &&
        <CancelledStatusCard pipeline={projectEvent.pipeline} actionType={this.actionTypeDisplayName}
                             actionIcon={this.actionIcon}/>
        }
        {showLastUpdated &&
        <LastUpdateStatusCard pipeline={projectEvent.pipeline} actionType={this.actionTypeDisplayName}
                              actionIcon={this.actionIcon}/>
        }
      </div>
    )
  }

  get actionTypeDisplayName(): string {
    const {projectEvent} = this.props

    return PROJECT_ACTION_TYPES[projectEvent.action.actionConfiguration.actionType.toString()].displayName
  }

  get actionIcon(): any {
    switch (this.props.projectEvent.action.actionConfiguration.actionType) {
      case ACTION_TYPE.CODE_MERGE:
        return faCode
      case ACTION_TYPE.CODE_DEPLOY:
        return faUndoAlt
      case ACTION_TYPE.CODE_PROMOTE:
        return faLevelDownAlt
      case ACTION_TYPE.ASSET_MERGE:
        return faFolderOpen
      case ACTION_TYPE.DATABASE_COPY:
        return faDatabase
      case ACTION_TYPE.DATABASE_SNAPSHOT:
        return faFileArchive
      case ACTION_TYPE.DATABASE_IMPORT:
        return faFileImport
      default:
        return null
    }
  }
}

export default StatusCard
