import 'reflect-metadata'
import {Type} from 'class-transformer'
import {assign} from 'lodash'
import {ACTION_TYPE} from './Action'

export abstract class ActionConfiguration {
  id: string
  name: string
  actionType: ACTION_TYPE
  projectId: string
  sourceEnvironment: string
  targetEnvironment: string
  sourceDeploymentTargetGroupId: string
  targetDeploymentTargetGroupId: string
  updatedBy: string
  @Type(() => Date)
  lastUpdated: Date
  hasUserEditor: boolean

  constructor(props?: Partial<ActionConfiguration>) {
    props = props || {}

    const defaultProps: Partial<ActionConfiguration> = {
      name: undefined,
      actionType: undefined,
      sourceEnvironment: undefined,
      targetEnvironment: undefined,
      sourceDeploymentTargetGroupId: undefined,
      targetDeploymentTargetGroupId: undefined,
      updatedBy: undefined,
      hasUserEditor: false
    }
    assign(this, defaultProps, props)
  }

  abstract isValid(): boolean
}
