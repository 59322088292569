import React from 'react'
import {
  TableBody
} from '@material-ui/core'
import Action from "../../../../../models/pipeline/Action";
import TableHeader from "../../../../common/TableHeader";
import ActionRow from "./ActionRow.component";


interface IActionTableProps {
  actions: Action[]
}

interface IActionTableState {
  actions: Action[]
}


class ActionTable extends React.Component<IActionTableProps, IActionTableState> {
  constructor(props: IActionTableProps) {
    super(props)
    this.state = {
      actions: this.props.actions
    }
  }

  requestSort = () => {}

  render = (): React.ReactNode => {

    const {actions} = this.state
    const columns: any[] = [
      {id: 'type', numeric: false, disablePadding: false, label: 'Type', sortable: false},
      {id: 'startTime', numeric: false, disablePadding: true, label: 'Started', sortable: false},
      {id: 'endTime', numeric: false, disablePadding: true, label: 'Finished', sortable: false},
      {id: 'duration', numeric: false, disablePadding: true, label: 'Duration', sortable: false},
      {id: 'sourceEnv', numeric: false, disablePadding: true, label: 'Source Env', sortable: false},
      {id: 'targetEnv', numeric: false, disablePadding: true, label: 'Target Env', sortable: false},
      {id: 'status', numeric: false, disablePadding: true, label: 'Status', sortable: false},
      {id: 'logs', numeric: false, disablePadding: true, label: 'Logs', sortable: false},
    ]

    return (
      <React.Fragment>
        <TableHeader style={{fontSize: '14px', fontWeight: 550, background: '#ececec'}} columns={columns} onRequestSort={this.requestSort} order={''} orderBy={''}/>

        <TableBody>
          {actions?.length && actions.map((action: Action, index: number) => {
            return (
              <ActionRow key={index} action={action}/>
            )
          })}
        </TableBody>
      </React.Fragment>
    )
  }

}

export default ActionTable
