import {ACTION_TYPE} from './Action'
import 'reflect-metadata'
import {assign} from 'lodash'
import {ActionConfiguration} from './ActionConfiguration'

class CodeDeployActionConfiguration extends ActionConfiguration {
  tag: string

  constructor(props?: Partial<CodeDeployActionConfiguration>) {
    super()
    this.actionType = ACTION_TYPE.CODE_DEPLOY

    props = props || {}

    const defaultProps: Partial<CodeDeployActionConfiguration> = {
      tag: undefined
    }
    assign(this, defaultProps, props)
  }

  isValid(): boolean {
    return false
  }
}

export default CodeDeployActionConfiguration
