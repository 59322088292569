export const GREY = {
  50: '#fafafa',
  100: '#f4f4f4',
  200: '#ececec',
  300: '#dedede',
  400: '#d4d4d4',
  500: '#aaa',
  600: '#727272',
  700: '#5e5e5e',
  800: '#404040',
  900: '#1f1f1f',
}
