import React from 'react'
import {
  Grid,
  Table,
  TableBody
} from '@material-ui/core'
import TableHeader from 'src/components/common/TableHeader'
import Pipeline from "../../../../../models/pipeline/Pipeline";
import PipelineRow from "./PipelineRow.component";
import {PipelineTemplate} from "../../../../../models/pipeline/PipelineTemplate";
import {HISTORY_ITEM_TYPES} from "../../../project-details-legacy/history/HistoryItemTypes";

export interface IPipelineHistoryTableProps {
  pipelines: Pipeline[]
  pipelineTemplates: PipelineTemplate[]
}

export interface IPipelineHistoryTableState {
  order: string,
  orderBy: string,
  pipelines: Pipeline[],
  selectedItem: any,
  showActions: boolean,
  pipelineTemplates: PipelineTemplate[]
}


const sortDirections: any = {
  ASC: 'asc',
  DESC: 'desc',
}

class PipelineHistoryTable extends React.Component<IPipelineHistoryTableProps, IPipelineHistoryTableState> {
  constructor(props: IPipelineHistoryTableProps) {
    super(props)
    this.state = {
      order: sortDirections.DESC,
      orderBy: 'startTime',
      pipelines: props.pipelines,
      selectedItem: null,
      showActions: false,
      pipelineTemplates: props.pipelineTemplates
    }
  }

  setOpen = (value: boolean): void => {
    this.setState({showActions: value})

  }

  getPipelineName = (pipeline: Pipeline, pipelineTemplates: PipelineTemplate[]): string => {
    if (pipeline.pipelineTemplateId) {
      const pipelineTemplate: PipelineTemplate = pipelineTemplates.find((pt) => pt.id === pipeline.pipelineTemplateId)
      if (pipelineTemplate) {
        return pipelineTemplate.name
      }
    }
    return pipeline?.actions.length ? HISTORY_ITEM_TYPES[pipeline.actions[0].actionConfiguration.actionType] : ''
  }

  TableBody = (): React.ReactNode => {
    const {pipelines, pipelineTemplates} = this.state

    return (
      <TableBody>
        {pipelines.map((pipeline: Pipeline, index: number) => {
          return (
            <PipelineRow key={index} pipeline={pipeline} pipelineName={this.getPipelineName(pipeline, pipelineTemplates)} index={index}/>
          )
        })}
      </TableBody>
    )
  }

  requestSort = () => {}

  render = (): React.ReactNode => {
    const {pipelines} = this.state
    const columns: any[] = [
      {id: 'status', numeric: false, disablePadding: false, label: '', sortable: false},
      {id: 'activity', numeric: false, disablePadding: true, label: 'Activity', sortable: false},
      {id: 'startTime', numeric: false, disablePadding: true, label: 'Started', sortable: false},
      {id: 'endTime', numeric: false, disablePadding: true, label: 'Finished', sortable: false},
      {id: 'initiator', numeric: false, disablePadding: true, label: 'Initiator', sortable: false},
      {id: 'logs', numeric: false, disablePadding: true, label: '', sortable: false},
    ]

    return (
      <Grid container style={{marginTop: '1rem'}}>
        <Grid item sm={12} className='centered'>
          {pipelines.length ?
            <Table>
              <TableHeader className={'pipeline-history-header'} columns={columns} onRequestSort={this.requestSort} order={''} orderBy={''}/>
              {this.TableBody()}
            </Table>
            : 'No records were found'}
        </Grid>
      </Grid>
    )
  }
}

export default PipelineHistoryTable
