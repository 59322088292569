import React from 'react'
import { Grid, MenuItem, Select, Typography } from '@material-ui/core'
import { PROJECT_ERROR, PROJECT_TYPE } from 'src/models/Project'
import {  FORM_STEP_TYPE } from '../forms'
import { useStyles } from './workflow-details.config'
import { Dispatch } from 'redux'
import { createProjectDataSetProjectField } from 'src/store/create-project-data/create-project-data.actions'
import { connect, ConnectedProps } from 'react-redux'
import IStoreState from 'src/store/IStoreState'
import { DetailsFormNextButton } from '../shared/details-form-next-button.component'
import { CreateFormTextField } from '../shared'
import MessageService from 'src/services/MessageService'

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {
  setActiveStep: (step: FORM_STEP_TYPE) => void
}
export type WorkflowFormProps = ComponentProps & PropsFromRedux

export const WorkflowDetailsForm: React.FC<WorkflowFormProps> = ({ projectName, loading, error, setActiveStep, updateProjectName }) => {
  const fieldSpacing = 3
  const goToNextStep = () => {
    const nextStep = FORM_STEP_TYPE.ENV
    setActiveStep(nextStep)
  }
  const nameError = error === PROJECT_ERROR.PROJECT_EXISTS
  const nameHelperText = nameError ? MessageService.get('project.wizard.error.details.name.duplicate') : null
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updateProjectName(event.target.name, event.target.value)
  }
  const isNextDisabled = !(projectName)
  return (
    <Grid container item direction="column" spacing={fieldSpacing} justify="space-between">
      <Grid item>
        <CreateFormTextField
          textFieldLabel="Name *"
          name="name"
          value={projectName}
          helperText={nameHelperText}
          onChange={handleNameChange}
          disabled={loading}
          autoComplete="off"
        />
      </Grid>
      <ProjectTypeField typeTitle="Workflow" projectType={PROJECT_TYPE.WORKFLOW} />
      <Grid item container justify="flex-end">
        <DetailsFormNextButton onClick={goToNextStep} disabled={isNextDisabled} />
      </Grid>
    </Grid>
  )
}

interface ProjectTypeFieldProps {
  projectType: PROJECT_TYPE
  typeTitle: string
}

const ProjectTypeField: React.FC<ProjectTypeFieldProps> = ({ projectType, typeTitle }) => {
  const styles = useStyles()
  const textFieldVariant = 'subtitle2'
  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography variant={textFieldVariant} className={styles.textFieldLabel}>
          Type
        </Typography>
      </Grid>
      <Grid item>
        <Select variant="outlined" disabled={true} value={projectType} inputProps={{ name: 'type' }} fullWidth>
          <MenuItem value={projectType}>{typeTitle}</MenuItem>
        </Select>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state: IStoreState) => ({
  error: state.createProjectData.error,
  loading: state.createProjectData.loading,
  projectName: state.createProjectData.project.name,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({

  updateProjectName: (field: string, value: string): void => {
    dispatch(createProjectDataSetProjectField(field, value))
  },
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedWorkflowDetailsForm = connector(WorkflowDetailsForm)
