import React, { useState } from 'react'
import { drupal7FormSteps, useStyles } from './create-drupal7-workflow.config'
import { FormSteps } from '../shared/form-steps.component'
import { ConnectedDrupal7EnvironmentsForm } from './drupal7-environments.component'
import { ConnectedDrupal7DetailsForm } from './drupal7-details.component'
import { FORM_STEP_TYPE } from '../forms'
import { ConnectedCreateProjectCodeForm } from '../forms/code-form.component'

export type Drupal7CreateWorkflowProps = {}

export const Drupal7CreateWorkflow: React.FC<Drupal7CreateWorkflowProps> = ({}) => {
  const styles = useStyles()
  const defaultStep = drupal7FormSteps[0].step
  const [activeStep, setActiveStep] = useState<FORM_STEP_TYPE>(defaultStep)
  return (
    <div className={styles.rootContainer}>
      <div className={styles.stepContainer}>
        <FormSteps activeStep={activeStep} formSteps={drupal7FormSteps} />
      </div>
      <Drupal7CreateForm activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )
}

export interface Drupal7CreateFormProps {
  activeStep: FORM_STEP_TYPE
  setActiveStep: (step: FORM_STEP_TYPE) => void
}

export const Drupal7CreateForm: React.FC<Drupal7CreateFormProps> = ({ activeStep, setActiveStep }) => {
  switch (activeStep) {
    case FORM_STEP_TYPE.DETAILS:
      return <ConnectedDrupal7DetailsForm setActiveStep={setActiveStep} />
    case FORM_STEP_TYPE.ENV:
      return <ConnectedDrupal7EnvironmentsForm setActiveStep={setActiveStep} />
    case FORM_STEP_TYPE.CODE:
      return <ConnectedCreateProjectCodeForm />
    default:
      return <ConnectedDrupal7DetailsForm setActiveStep={setActiveStep} />
  }
}
