import { assign } from 'lodash'

export default class Database {
  name: string
  username: string
  password: string
  allowedIP: string
  databaseType: DATABASE_TYPE
  associationId: number

  constructor(props?: Partial<Database>) {
    props = props || {}
    const defaultProps: Partial<Database> = {
      name: undefined,
      username: undefined,
      password: undefined,
      allowedIP: undefined,
      databaseType: undefined,
      associationId: undefined
    }
    assign(this, defaultProps, props)
  }
}

export enum DATABASE_TYPE {
  MYSQL = 'MYSQL'
}
