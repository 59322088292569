import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import DeploymentTargetGroup from 'src/models/DeploymentTargetGroup'
import Project from 'src/models/Project'
import { preInitializedProjectName } from 'src/store/create-project-form/create-project-form.helpers'
import { ProjectEnvironmentProcessConfig, ProjectProcessContext } from '../project/environment/environment-processor'
import ProjectHelper from '../ProjectHelper'

export const defaultProjectName = preInitializedProjectName

export function createProjectProcessContext(
  project: Project,
  deploymentEnvironments: DeploymentEnvironment[],
  config: ProjectEnvironmentProcessConfig,
): ProjectProcessContext {
  return {
    project,
    deploymentEnvironments,
    config,
  }
}

export function resolveDeploymentEnvironmentById(
  environmentId: string,
  deploymentEnvironments: DeploymentEnvironment[],
): DeploymentEnvironment | undefined {
  return deploymentEnvironments.find((env: DeploymentEnvironment) => env.id === environmentId)
}

export function resolveProjectName(project: Project): string {
  const projectName = project.name
  if (projectName) {
    return ProjectHelper.getCleanedProjectName(projectName)
  }
  return defaultProjectName
}

export function resolveDefaultDeploymentTargetGroup(
  targetGroups: DeploymentTargetGroup[],
  searchString: string,
): DeploymentTargetGroup | undefined {
  const targetGroup: DeploymentTargetGroup = targetGroups.find(
    (group: DeploymentTargetGroup) => group.name.toLowerCase() === searchString,
  )
  return targetGroup
}

export function resolveDefaultDeploymentTargetGroupId(
  deploymentEnvironment: DeploymentEnvironment,
  searchString: string,
): string | undefined {
  const targetGroups = deploymentEnvironment?.deploymentTargetGroups || []
  const targetGroup: DeploymentTargetGroup | undefined = resolveDefaultDeploymentTargetGroup(targetGroups, searchString)
  return targetGroup?.id
}

export function replaceSpaceWithUnderscore(text: string): string {
  return text.toLowerCase().replace(/ /g, '_')
}

export function appendNumberByAssociationId(baseText: string, associationId: number): string {
  const appendedNumber = associationId + 1
  return `${baseText}_${appendedNumber}`
}
