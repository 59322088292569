import {PipelineTemplate} from '../models/pipeline/PipelineTemplate'
import messageService from './MessageService'
import PipelineTriggerGroup from '../models/pipeline/PipelineTriggerGroup'
import PipelineTrigger from '../models/pipeline/PipelineTrigger'
import Cron from 'node-cron'

class PipelineTemplateEditorErrorHelper {
  static getErrorsForPipelineTemplate(pipelineTemplate: PipelineTemplate): Map<string, string> {
    const errors: Map<string, string> = new Map<string, string>()

    if(!pipelineTemplate.name || !pipelineTemplate.name.length) {
      errors.set('name', messageService.get('pipeline.template.editor.error.name.required'))
    }

    if(!pipelineTemplate.actionConfigurations || !pipelineTemplate.actionConfigurations.length) {
      errors.set('actionConfigurations', messageService.get('pipeline.template.editor.error.actionConfigurations.required'))
    }

    if(!PipelineTemplateEditorErrorHelper.triggerGroupsAreValid(pipelineTemplate)) {
      errors.set('triggerGroups', messageService.get('pipeline.template.editor.error.triggerGroups.triggers'))
    }

    if(pipelineTemplate.schedule && !Cron.validate(pipelineTemplate.schedule)) {
      errors.set('schedule', 'Invalid cron expression')
    }

    return errors
  }

  private static triggerGroupsAreValid(pipelineTemplate: PipelineTemplate): boolean {
    const allGroupsHaveTriggers: boolean = !pipelineTemplate.pipelineTriggerGroups.find((group: PipelineTriggerGroup) => !group.triggers || group.triggers.length < 1)
    const allTriggersAreValid: boolean = pipelineTemplate.pipelineTriggerGroups.reduce((previousValue: boolean, currentValue: PipelineTriggerGroup) => {
      return previousValue && currentValue.triggers.reduce((previousTriggerValue: boolean, currentTrigger: PipelineTrigger) => previousTriggerValue && this.triggerIsValid(currentTrigger), true)
    }, true)

    return allGroupsHaveTriggers && allTriggersAreValid
  }

  private static triggerIsValid(trigger: PipelineTrigger): boolean {
    return !!trigger.type && !!trigger.value
  }
}

export default PipelineTemplateEditorErrorHelper
