import axios from 'axios'
import Bundle from '../models/Bundle'

class BundleService {
  static BUNDLE_API_ROOT = '/api/bundle/v1/bundle'

  async getBundle() {
    const res = await axios.get(BundleService.BUNDLE_API_ROOT)
    return new Bundle(res.data)
  }

  async updateBundle(updatedBundle) {
    const res = await axios.patch(BundleService.BUNDLE_API_ROOT, updatedBundle)
    return new Bundle(res.data)
  }
}

export default new BundleService()
