import {ActionConfiguration} from './ActionConfiguration'
import {assign} from 'lodash'
import {ACTION_TYPE} from './Action'

export class ScriptActionConfiguration extends ActionConfiguration {
  scriptContents: string

  constructor(props?: Partial<ScriptActionConfiguration>) {
    super(props)
    props = props || {}

    const defaultProps: Partial<ScriptActionConfiguration> = {
      scriptContents: null,
      actionType: ACTION_TYPE.SCRIPT,
      hasUserEditor: true
    }
    assign(this, defaultProps, props)
  }

  hasFullyPopulatedScriptFields(): boolean {
    const hasPopulatedScriptFields: boolean = this.scriptContents !== null
    if (hasPopulatedScriptFields){
      return hasPopulatedScriptFields
    }
    return false
  }

  isValid(): boolean {
    return this.hasFullyPopulatedScriptFields()
  }
}
