import { hasUndefinedProjectEnvironmentAssets } from 'src/helpers/project/environment/asset/env-asset-validation'
import { EnvironmentProcessIO } from 'src/helpers/project/environment/environment-processor'
import { hasUndefinedProjectEnvironmentSymlinks } from 'src/helpers/project/environment/link/env-link-validation'
import { createProcessIO } from 'src/helpers/shared'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import { createDefaultProjectEnvironmentSymlinks } from './create-project-env-symlink-transformation'

export interface LinkProcessContext {
  index: number
  projectName: string
  deploymentEnvironment: DeploymentEnvironment
}

export function createProjectEnvironmentWithDefaultSymlinksForCreateProjectWorkflow(
  input: EnvironmentProcessIO,
  context: LinkProcessContext,
): EnvironmentProcessIO {
  const env = input.value
  const hasUndefinedSymlinks = hasUndefinedProjectEnvironmentSymlinks(env)
  const hasAssetGroups = !hasUndefinedProjectEnvironmentAssets(env)
  if (hasUndefinedSymlinks && hasAssetGroups) {
    const env = input.value
    const assetGroups = env.projectEnvironmentAssets.assetGroups
    const webroot = env?.projectEnvironmentCode?.webroot || ''
    if (assetGroups) {
      const symlinks = createDefaultProjectEnvironmentSymlinks(assetGroups, webroot)
      const updatedEnvironment = new ProjectEnvironment({
        symLinks: symlinks,
        order: env.order,
        environmentId: env.environmentId,
        projectEnvironmentCode: env.projectEnvironmentCode,
        projectEnvironmentAssets: env.projectEnvironmentAssets,
        projectEnvironmentDatabases: env.projectEnvironmentDatabases,
      })
      return createProcessIO(updatedEnvironment, [...input.errors])
    }
  }
  return input
}
