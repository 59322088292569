import React from 'react'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import {Grid, Tab, Tabs, Tooltip} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLock} from '@fortawesome/free-solid-svg-icons'
import Nbsp from './Nbsp'
import './SortableTable.scss'

export interface ISortableTabsProps {
  titles: string[],
  handleReorder: (oldIndex: number, newIndex: number)=>void,
  handleTabClick: (selectedTab: number)=>void,
  selectedTab: number,
  lockIndexes?: number[],
  lockMessage?: string,
  reorderMessage?: string
}

export class SortableTabs extends React.Component <ISortableTabsProps> {
  static defaultProps: ISortableTabsProps = {
    titles: null,
    handleReorder: null,
    handleTabClick: null,
    selectedTab: 0,
    lockIndexes: [],
    lockMessage: null,
    reorderMessage: null
  }

  /*
  This function is causing an undefined crash in the production build, so we're not calling it directly for now.
  See: https://jira.contegix.com/browse/AB-1295
   */
  private sortableTab: React.ComponentClass<any> = SortableElement(({value, title, selected}: any) => {
    return <Tab
      className={value === selected ? 'sortable-tab sortable-tab-active' : 'sortable-tab'}
      label={title}
      onClick={this.props.handleTabClick.bind(this, value)}
      textColor='secondary'/>
  })

  private sortableTabsList: React.ComponentClass<any> = SortableContainer(({lockIndexes, lockMessage, titles, selectedTab}: any) => {
      return (
        <Tabs variant='scrollable' value={selectedTab} indicatorColor='secondary' scrollButtons='auto'>
          {titles.map((title: any, index: number) => {
            if (!lockIndexes.includes(index)) {
              /*
              We would normally cll the sortableTab here, if not for the production build failing.
              return <this.sortableTab key={index} index={index} value={index} title={title}/>
              */
              return <Tab
                className={index === selectedTab ? 'sortable-tab sortable-tab-active' : 'sortable-tab'}
                label={title}
                onClick={this.props.handleTabClick.bind(this, index)}
                textColor='secondary'/>
            }

            return <Tab key={index} onClick={this.props.handleTabClick.bind(this, index)} textColor='secondary' label={
                <span>{titles[index]}<Nbsp count={2}/>
                  <Tooltip title={lockMessage} enterDelay={50} placement='right'>
                    <FontAwesomeIcon icon={faLock}/>
                  </Tooltip>
                </span>
              } className={selectedTab === index ? 'sortable-tab-active' : ''} />
          })}
        </Tabs>
      )
  })

  handleReorder = (event: any): void => {
    this.props.handleReorder(event.oldIndex, event.newIndex)
  }

  render(): React.ReactNode {
    const {titles, selectedTab, lockIndexes, lockMessage, reorderMessage} = this.props
    return (
      <Grid container spacing={2}>
        {reorderMessage && <Grid item xs={12} className='helpText'>{reorderMessage}</Grid>}
        <Grid item xs={12} id={'tabsList'}>
          <this.sortableTabsList axis='x' lockAxis='x' titles={titles} selectedTab={selectedTab} lockIndexes={lockIndexes}
                                 lockMessage={lockMessage} onSortEnd={this.handleReorder} useDragHandle={false} pressDelay={200}/>
        </Grid>
      </Grid>
    )
  }
}

export default SortableTabs
