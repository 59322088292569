import initialState from '../initialState'
import ActionTypes from '../actions'
import {ISelectedEnvironmentListState} from '../IStoreState'

export default function selectedEnvironmentReducer(state: ISelectedEnvironmentListState = initialState.selectedEnvironmentList, action: any): ISelectedEnvironmentListState {
    switch (action.type) {
        case ActionTypes.Update_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null
            }
        default:
            return state
    }
}
