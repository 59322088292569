import React from 'react'
import PropTypes from 'prop-types'
import {Table, TableBody} from '@material-ui/core'
import TableHeader from '../../common/TableHeader'
import {assign, get} from 'lodash'
import EditUserTableHeader from "./EditUserTableHeader";
import { CUSTOM } from '../../../theme/lib/color/custom.color'

export const sortDirections = {
  ASC: 'asc',
  DESC: 'desc',
}

class EditUserTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: this.props.items,
    }
  }

  componentDidMount = () => {
    this.updateItems(this.state.items)
  }

  componentWillReceiveProps = (newProps) => {
    let {items} = this.state
    if (newProps.items !== items) { // must have a new reference
      items = newProps.items
    }
    this.updateItems(items)
  }

  updateItems = (items) => {
    this.setState({items})
  }

  handleRowClick = (item) => {
    this.props.handleRowClick && this.props.handleRowClick(item)
  }

  getRowColor = (rowNumber) => {
    return rowNumber % 2 ? 'white' : CUSTOM.gray["200"]
  }

  render() {
    return (
      <Table>
        <EditUserTableHeader columns={this.props.columns}
                             userRoleGroups={this.props.userRoleGroups}
                             handleUserRoleGroupChange={this.props.handleUserRoleGroupChange}
                             handleUserSelectAllChange={this.props.handleUserSelectAllChange}
                             {...this.props.headerProps}/>
        <TableBody>
          {this.state.items.map((item, index) => {
            const CustomRow = this.props.rowComponent
            return (
              <CustomRow key={index} item={item} handleRowActions={this.props.handleRowActions}
                         handleClick={this.handleRowClick} rowBackgroundColor={this.getRowColor(index)}
                         {...this.props.rowProps}/>
            )
          })}
        </TableBody>
      </Table>
    )
  }
}

EditUserTable.propTypes = {
  items: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    label: PropTypes.string,
    selectAll: PropTypes.bool,
    global: PropTypes.bool
  })).isRequired,
  handleRowClick: PropTypes.func,
  handleRowActions: PropTypes.object,
  rowComponent: PropTypes.func.isRequired,
  headerProps: PropTypes.object,
  rowProps: PropTypes.object,
}

EditUserTable.defaultProps = {
  headerProps: null,
  rowProps: null,
}

export default EditUserTable
