import React from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'

export interface DroppableColumnWrapperProps {
  droppableId: string
  onDragEnd: (result: DropResult) => void
}

export const DroppableColumnWrapper: React.FC<DroppableColumnWrapperProps> = ({ onDragEnd, droppableId, children }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => {
          return (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {children}
              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}
