import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { CUSTOM } from 'src/theme/lib/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(2),
      borderRadius: '4px',
      borderColor: theme.palette.grey[500],
      borderWidth: '1px',
      borderStyle: 'solid',
      marginBottom: '16px',
    },
    icon: {
      color: CUSTOM.app_header_blue,
    },
    titleText: {
      fontWeight: 'bold',
    },
    errorText: {
      color: 'darkred',
    }
  }),
)
