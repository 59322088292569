import {Action, Dispatch} from 'redux'
import ActionTypes from './index'
import Project from '../../models/Project'
import ProjectService from '../../services/ProjectService'
import ProjectEventService from '../../services/ProjectEventService'
import IStoreState from '../IStoreState'
import ProjectEvent from '../../models/pipeline/ProjectEvent'
import ProjectEnvironment from "../../models/ProjectEnvironment";
import SymLink from "../../models/SymLink";
import { PROJECT_ENVIRONMENTS_FIELD } from 'src/components/project/project-create/shared/project-field-names'

export const loadProjectById: (id: string) => any = (id: string): any => {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.SELECTED_PROJECT_LOAD})
    try {
      const project: Project = await ProjectService.getProjectDetails(id)
      dispatch({type: ActionTypes.SELECTED_PROJECT_SUCCESS, payload: {project, persistedProject: project}})
      dispatch(loadProjectEvents())
    } catch (error) {
      dispatch({type: ActionTypes.SELECTED_PROJECT_FAIL, payload: {error}})
    }
  }
}

export const loadPersistedProjectById: (id: string) => any = (id: string): any => {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    try {
      const project: Project = await ProjectService.getProjectDetails(id)
      dispatch({type: ActionTypes.PERSISTED_PROJECT_SUCCESS, payload: {project}})
      dispatch(loadProjectEvents())
    } catch (error) {
      console.error('Failed to fetch persisted project.')
    }
  }
}

export const clearProject: () => any = (): any => {
  return (dispatch: Dispatch<Action>): any => {
    dispatch({type: ActionTypes.SELECTED_PROJECT_SUCCESS, payload: {project: undefined}})
  }
}

export const loadProjectEvents: () => any = (): any => {
  return async (dispatch: Dispatch<Action>, getState: () => IStoreState): Promise<any> => {
    const project: Project = getState().selectedProject.project
    if (project) {
      dispatch({type: ActionTypes.SELECTED_PROJECT_EVENTS_LOAD})
      try {
        const latestEvents: ProjectEvent[] = await ProjectEventService.loadLatestProjectEvents(project)
        dispatch({type: ActionTypes.SELECTED_PROJECT_EVENTS_LOAD_SUCCESS, payload: {latestEvents}})
      } catch (error) {
        dispatch({type: ActionTypes.SELECTED_PROJECT_EVENTS_LOAD_FAIL, payload: {error}})
      }
    }
  }
}

export function openBranchConfirmation(confirmActionCreator: () => any): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_OPEN_BRANCH_CONFIRMATION,
      payload: {confirmActionCreator}
    })
  }
}

export function closeBranchConfirmation(): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.SELECTED_PROJECT_CLOSE_BRANCH_CONFIRMATION})
  }
}

export function onWebRootChange(envId: string, oldWebroot: string, newWebroot: string): any {
  return async (dispatch: Dispatch<Action>, goToState: () => IStoreState): Promise<any> => {
    const {project} = goToState().selectedProject
    const updatedProjectEnvironments: ProjectEnvironment[] = project.projectEnvironments
    const projectEnvironmentCodeToUpdate: ProjectEnvironment = updatedProjectEnvironments.find((projEnv: ProjectEnvironment) => projEnv.environmentId === envId)

    if (projectEnvironmentCodeToUpdate && projectEnvironmentCodeToUpdate.symLinks && projectEnvironmentCodeToUpdate.symLinks.length > 0) {
      projectEnvironmentCodeToUpdate.symLinks.forEach((symLink: SymLink) => {
        symLink.from = symLink.from.replace(oldWebroot, newWebroot)
      })

      dispatch({
        type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
        payload: {
          key: PROJECT_ENVIRONMENTS_FIELD,
          value: updatedProjectEnvironments
        }
      })
    }
  }
}