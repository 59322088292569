import React from 'react'
import { DropResult } from 'react-beautiful-dnd'
import {StepsItem} from './PipelineActionItem.component'
import { PipelineActionConfig } from './PipelineActionList.config'
import { DroppableColumnWrapper } from '../../../../project-create/shared/droppable-column-wrapper.component'
import {PipelineTemplate} from "../../../../../../models/pipeline/PipelineTemplate";

type ComponentProps = {
    droppableId: string
    isSelected:boolean
    actionList: PipelineActionConfig[]
    pipelineTemplate:PipelineTemplate
    updateOrder: (result: DropResult) => void
    remove: (config: PipelineActionConfig,pipelineTemplate:PipelineTemplate) => void
}

export type DraggablePipelineActionListProps = ComponentProps

export const DraggablePipelineActionList: React.FC<DraggablePipelineActionListProps> = ({
                                                                                            droppableId,
                                                                                            actionList,
                                                                                            pipelineTemplate,
                                                                                            updateOrder,
                                                                                            remove,
                                                                                            isSelected
                                                                                        }) => {
    return (
        <DroppableColumnWrapper onDragEnd={updateOrder} droppableId={droppableId}>
            {actionList.map((config, index) => {
                return <StepsItem position={index} config={config} remove={remove} key={config.id} pipelineTemplate={pipelineTemplate} actionLength={!!actionList ? actionList.length:0} isSelected={isSelected}/>})
            }
        </DroppableColumnWrapper>
    )
}

