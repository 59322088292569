import React from 'react'
import './LoadingOverlay.scss'
import Spinner from './Spinner'

interface ILoadingOverlayProps {
}

class LoadingOverlay extends React.Component<ILoadingOverlayProps> {
  render(): React.ReactNode {
    return (
      <div className='loading-overlay'>
        <Spinner large/>
      </div>
    )
  }
}

export default LoadingOverlay
