import React from 'react'
import Project, {PROJECT_STATUS, PROJECT_TYPE} from '../../models/Project'
import {TableCell, TableRow, Tooltip} from '@material-ui/core'
import ProjectTypeHelper from '../../helpers/ProjectTypeHelper'
import {UISref} from '@uirouter/react'
import './ProjectTable.scss'
import {appStates} from '../../appStates'
import ProjectActionsMenu from './ProjectActionsMenu'
import ProjectStatusIcon from '../common/ProjectStatusIcon'
import classNames from 'classnames'
import MessageService from '../../services/MessageService'
import {MenuSection} from '../project/project-details/menu/section-menu.config'

interface IProjectTableRowProps {
  project: Project,
  handleEditClick: (project: Project) => void
  handleDisableClick: (project: Project) => void
  handleEnableClick: (project: Project) => void
  handleCloneClick: (project: Project) => void
}

const ProjectTableRow: React.FC<IProjectTableRowProps> = ({
  project,
  handleEditClick,
  handleEnableClick,
  handleDisableClick,
  handleCloneClick,
}) => {
  const redirectAppState = appStates.PROJECT + '.' + appStates.PROJECT_DETAILS
  
  const handleEdit = (): void => {
    handleEditClick(project)
  }

  const handleEnable = (): void => {
    handleEnableClick(project)
  }

  const handleDisable = (): void => {
    handleDisableClick(project)
  }

  const handleClone = (): void => {
    handleCloneClick(project)
  }

  const getSectionForProjectType = (): string => {
    let section = MenuSection.OVERVIEW
    if (PROJECT_TYPE.WORKFLOW === project.type) {
      section = MenuSection.PIPELINES
    }
    return section
  }

  const landingSection = getSectionForProjectType()

  const canOpenProject: boolean = !project.disabled && project.status !== PROJECT_STATUS.INCOMPLETE && project.status !== PROJECT_STATUS.AWAITING_REPO_INITIALIZATION
  
  const renderRow = () => {
    return (
      <TableRow key={project.id} hover={canOpenProject} className={classNames({'disabled-project-row': project.disabled})}>
        <TableCell className='project-status-col'>
          <ProjectStatusIcon project={project}/>
        </TableCell>
        <TableCell>{project.name}</TableCell>
        <TableCell>{ProjectTypeHelper.getProjectTypeDisplayByValue(project.type)}</TableCell>
        <TableCell className={'project-actionColumn'}>
          <ProjectActionsMenu project={project} handleEditClick={handleEdit} handleDisableClick={handleDisable}
                              handleEnableClick={handleEnable} handleCloneClick={handleClone}/>
        </TableCell>
      </TableRow>
    )
  }

  if (project.disabled) {
    return <Tooltip placement='left' title={MessageService.get('project.disabled')} enterDelay={50}>{renderRow()}</Tooltip>
  }
  if (canOpenProject) {
    return (
      <UISref to={redirectAppState} params={{projectId: project.id, section: landingSection}} className='complete-project-row'>
        {renderRow()}
      </UISref>
    )
  }
  return renderRow()
}

export default ProjectTableRow
