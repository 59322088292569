import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStopwatch} from '@fortawesome/free-solid-svg-icons'
import Pipeline from 'src/models/pipeline/Pipeline'
import Nbsp from 'src/components/common/Nbsp'
import Action from 'src/models/pipeline/Action'
import TimerHelper from 'src/helpers/TimerHelper'
import './LatestPipelineRunTimeRunningInfo.scss'
import STATUS from 'src/models/pipeline/Status'
import {Tooltip} from '@material-ui/core'
import classNames from 'classnames'

export interface ILatestPipelineRunTimeRunningInfoProps {
  latestPipeline: Pipeline,
}

export interface ILatestPipelineRunTimeRunningInfoState {
  timer: number
}

export class LatestPipelineRunTimeRunningInfo extends React.Component<ILatestPipelineRunTimeRunningInfoProps, ILatestPipelineRunTimeRunningInfoState> {
  constructor(props: ILatestPipelineRunTimeRunningInfoProps) {
    super(props)

    this.state = {
      timer: 0,
    }
    this.startCalculationsInterval()
  }

  componentWillMount = (): void => {
    const {latestPipeline} = this.props

    if (latestPipeline.startTime) {
      const estimatedDuration: number = latestPipeline.actions.reduce((duration: number, action: Action) => duration + action.estimatedDuration, 0)
      let timerInS: number = (latestPipeline.startTime.getTime() - Date.now()) / 1000
      timerInS += estimatedDuration
      this.setState({timer: Math.round(timerInS)})
    }
  }

  startCalculationsInterval = (): void => {
    setInterval(() => {
      const {timer} = this.state

      if (timer > 0) {
        this.setState({timer: timer - 1})
      }
    }, 1000)
  }

  render(): React.ReactNode {
    const {latestPipeline} = this.props
    const {timer} = this.state

    const showTimeRemaining: boolean = timer > 0
    const showRunning: boolean = timer <= 0 && [STATUS.STARTED, STATUS.CANCELLING].includes(latestPipeline.status)
    const isCanceling: boolean = latestPipeline.status === STATUS.CANCELLING

    const classes: string = classNames('latest-pipeline-run-time-running', {'canceling': isCanceling})

    return (
      <span className={classes}>
        {
          showTimeRemaining &&
          <Tooltip title='Estimated time remaining' placement='top' enterDelay={50}>
            <span><FontAwesomeIcon icon={faStopwatch} size='xs'/><Nbsp/>{TimerHelper.getTime(this.state.timer)}</span>
          </Tooltip>
        }
        {showRunning && 'Running...'}
      </span>
    )
  }
}

export default LatestPipelineRunTimeRunningInfo
