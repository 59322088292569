import { replaceSpaceWithUnderscore, resolveDefaultDeploymentTargetGroupId } from 'src/helpers/shared'
import Branch from 'src/models/Branch'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import ProjectEnvironmentCode from 'src/models/ProjectEnvironmentCode'
import ProjectEnvironmentCodeWebroot from 'src/models/ProjectEnvironmentCodeWebroot'
import { WEB_SERVER } from 'src/models/WEB_SERVER'

export const defaultBranchName = 'master'
const searchString = 'code'

export function createDefaultProjectEnvironmentCode(
  env: ProjectEnvironment,
  deploymentEnvironment: DeploymentEnvironment,
  projectName: string,
): ProjectEnvironmentCode {
  const guarded = resolveIsGuarded(deploymentEnvironment)
  const environmentTitle = replaceSpaceWithUnderscore(deploymentEnvironment.title)
  const webrootObject = resolveWebrootObject(env, projectName, environmentTitle)
  const webrootString = webrootObject.fullWebroot.toLowerCase()
  const targetGroupId = resolveDefaultDeploymentTargetGroupId(deploymentEnvironment, searchString)
  return new ProjectEnvironmentCode({
    branch: new Branch({ name: defaultBranchName, guarded }),
    deploymentTargetGroupId: targetGroupId,
    webroot: webrootString,
    webServer: WEB_SERVER.APACHE_HTTPD,
    deploymentsToKeep: 3,
    failedDeploymentsToKeep: 3
  })
}

export function resolveIsGuarded(deploymentEnvironment: DeploymentEnvironment): boolean {
  const isDev = deploymentEnvironment ? deploymentEnvironment.isDev : false
  return isDev === false
}

export function resolveWebrootObject(
  env: ProjectEnvironment,
  projectName: string,
  environmentTitle: string,
): ProjectEnvironmentCodeWebroot {
  if (env.projectEnvironmentCode && env.projectEnvironmentCode.webroot) {
    // Question: How relevant is generating a separate webroot based on the code.webroot?
    // TODO: (tguid-1) Consider adding validation to the form input that allows the user to define the "dir1" when not "var/www".
    // Note: This has serious errors if the word "ab" or "cascade" is not included in the path.
    const result = createWebrootObject(env)
    // TODO: update tests to handle this ternary
    return result ? result : createDefaultWebrootObject(environmentTitle, projectName)
  } else {
    return createDefaultWebrootObject(environmentTitle, projectName)
  }
}

// Note: This function may need checks for undefined values
// TODO: Update tests to handle null value returns
export function createWebrootObject(env: ProjectEnvironment): ProjectEnvironmentCodeWebroot | null {
  const cache: string[] = env.projectEnvironmentCode?.webroot?.split('/')
  if (cache && cache.length > 0) {
    cache.splice(0, 1)
    let abDirIndex = cache.indexOf('ab')
    if (abDirIndex < 0) {
      abDirIndex = cache.indexOf('cascade')
    }
    const dir1 = cache.slice(0, abDirIndex).reduce((acc, dir) => (acc === '' ? dir : `${acc}/${dir}`), '')
    const dir2 = cache.slice(abDirIndex + 3, cache.length).reduce((acc, dir) => (acc === '' ? dir : `${acc}/${dir}`), '')
    const result = new ProjectEnvironmentCodeWebroot({
      dir1,
      cascade: cache[abDirIndex],
      env: cache[abDirIndex + 1],
      site: cache[abDirIndex + 2],
      dir2,
    })
    return result
  }
  return null
}

// TODO: Test
export function createWebrootObjectFromWebrootString(webroot: string): ProjectEnvironmentCodeWebroot | null {
  const cache: string[] = webroot?.split('/')
  if (cache && cache.length > 0) {
    cache.splice(0, 1)
    let abDirIndex = cache.indexOf('ab')
    if (abDirIndex < 0) {
      abDirIndex = cache.indexOf('cascade')
    }
    const dir1 = cache.slice(0, abDirIndex).reduce((acc, dir) => (acc === '' ? dir : `${acc}/${dir}`), '')
    const dir2 = cache.slice(abDirIndex + 3, cache.length).reduce((acc, dir) => (acc === '' ? dir : `${acc}/${dir}`), '')
    const result = new ProjectEnvironmentCodeWebroot({
      dir1,
      cascade: cache[abDirIndex],
      env: cache[abDirIndex + 1],
      site: cache[abDirIndex + 2],
      dir2,
    })
    return result
  }
  return null
}

export function createDefaultWebrootObject(
  environmentTitle: string,
  cleanedProjectName: string,
): ProjectEnvironmentCodeWebroot {
  return new ProjectEnvironmentCodeWebroot({
    dir1: 'var/www',
    cascade: 'cascade',
    env: environmentTitle,
    site: cleanedProjectName,
    dir2: 'htdocs',
  })
}
