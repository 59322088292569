import React from 'react'
import PropTypes from 'prop-types'

import {TableRow, TableCell} from '@material-ui/core'

class UsersTableRow extends React.Component {
  constructor(props) {
    super(props)
  }

  handleClick = () => {
    this.props.handleClick(this.props.item)
  }

  render() {
    const user = this.props.item
    return (
      <TableRow hover className='clickable' onClick={this.handleClick}>
        <TableCell>{user.username}</TableCell>
        <TableCell>{user.name.givenName}</TableCell>
        <TableCell>{user.name.familyName}</TableCell>
      </TableRow>
    )
  }
}

UsersTableRow.propTypes = {
  item: PropTypes.shape({
    username: PropTypes.string.isRequired,
    name: PropTypes.shape({
      givenName: PropTypes.string,
      familyName: PropTypes.string,
    }),
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default UsersTableRow
