import React from 'react'
import {Button, Tooltip} from '@material-ui/core'
import { ButtonConfig } from './project-users.config'


export interface IUsersButtonProps {
  buttonConfig: ButtonConfig
}

export const UsersButton: React.FC<IUsersButtonProps> = ({ buttonConfig }) => {

  const styles = buttonConfig.style

  const renderButton = () => {
    if (buttonConfig.showButton) {
      return (
        <Tooltip title={buttonConfig.showTooltip ? buttonConfig.tooltip : ''}>
          <span>
            <Button
              onClick={buttonConfig.onClick}
              disabled={buttonConfig.isDisabled}
              className={styles.saveButton}
              variant="contained"
              color="primary"
              disableElevation
            >
              {buttonConfig.text}
            </Button>
          </span>
        </Tooltip>
      )
    }
    return null
  }

  return (
     renderButton()
  )
}
