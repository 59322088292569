export const CUSTOM = {
  app_header_blue: '#1E4056',
  app_banner_blue: '#326098',
  active_button: 'rgb(50, 67, 96)',
  danger_button: 'rgb(249, 242, 242)',
  breadcrumbFadedBlue: '#97abc5',
  green: {
    500: '#69b77e',
  },
  orange: {
    500: '#f2a225',
  },
  red: {
    300: '#d1534c',
  },
  gray: {
    200: '#e0e6ee',
    600: '#8b97aa',
    700: '#8995a1',
    800: '#20272c',
  },
  blue: {
    100: '#f4f6f8',
    200: '#e0e6ee',
    300: '#dde3eb',
    400: '#75b2e8',
    500: '#5799db',
    600: '#4682c8',
    700: '#3b6dab',
    800: '#326098',
    900: '#2e4362',
  },
}
