import React, { useState } from 'react'
import Project from '../../../../../models/Project'
import { Grid, Tab, Tabs } from '@material-ui/core'
import { SectionLayout } from '../../shared'
import PipelineTemplateSection from './pipelineTemplates/PipelineTemplateSection'
import ActionConfigurationSection from './actionConfigurations/ActionConfigurationSection'

export interface ProjectPipelinesProps {
  project?: Project
}

enum PipelineTab {
  PIPELINE_TEMPLATES,
  ACTIONS,
}

export const ProjectPipelines: React.FC<ProjectPipelinesProps> = ({ project }) => {
  const [activeTab, setActiveTab] = useState(PipelineTab.PIPELINE_TEMPLATES)

  return (
    <SectionLayout>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Tabs value={activeTab} indicatorColor="primary" textColor="primary">
            <Tab label="Pipelines" onClick={() => setActiveTab(PipelineTab.PIPELINE_TEMPLATES)} />
            <Tab label="Actions" onClick={() => setActiveTab(PipelineTab.ACTIONS)} />
          </Tabs>
        </Grid>
        <Grid item xs={12} className="tabContainer">
          {activeTab === PipelineTab.ACTIONS && <ActionConfigurationSection />}
          {activeTab === PipelineTab.PIPELINE_TEMPLATES && <PipelineTemplateSection />}
        </Grid>
      </Grid>
    </SectionLayout>
  )
}
