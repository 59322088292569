import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

export const makeSettingBarStyles = (width: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
      },
    }),
  )
