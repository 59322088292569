import {ACTION_TYPE} from './Action'
import {assign} from 'lodash'

export class ActionType {
  value: ACTION_TYPE
  displayName: string
  hasUserEditor: boolean

  constructor(props?: Partial<ActionType>) {
    props = props || {}
    const defaultProps: Partial<ActionType> = {
      value: undefined,
      displayName: undefined,
      hasUserEditor: false
    }
    assign(this, defaultProps, props)
  }
}
