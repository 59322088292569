import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { FormStepConfig, FORM_STEP_TYPE } from '../forms/form.config'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    stepContainer: {
      marginBottom: theme.spacing(4),
    },
  }),
)

const OTHER_DETAILS = 'Details'
const OTHER_ENV = 'Environments'
const OTHER_CODE = 'Code'

export const otherFormSteps: FormStepConfig[] = [
  {
    step: FORM_STEP_TYPE.DETAILS,
    name: OTHER_DETAILS,
  },
  {
    step: FORM_STEP_TYPE.ENV,
    name: OTHER_ENV,
  },
  {
    step: FORM_STEP_TYPE.CODE,
    name: OTHER_CODE,
  },
]
