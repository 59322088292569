import React from 'react'
import User from 'src/models/User'
import { Grid } from '@material-ui/core'
import { PageLayout } from 'src/components/layout/shared'
import { ConnectedProjectCloneBanner } from  "./project-clone-banner.component"
import ProjectCloneDetails from "./project-clone-details";

export interface SaveAndCancelConfig {
  isSaveDisabled: boolean
  isError: boolean
  tooltip: string
  handleSubmit: () => void
  handleCancel: () => void
}

export interface ProjectCloneSceneProps {
  currentUser: User
}

const spacing = 3

export const ProjectCloneScene: React.FC<ProjectCloneSceneProps> = () => {
  return (
    <PageLayout>
      <Grid container direction="column" spacing={spacing} >
        <Grid item>
          <ConnectedProjectCloneBanner/>
        </Grid>
        <Grid item>
          <ProjectCloneDetails/>
        </Grid>
      </Grid>
    </PageLayout>
  )
}