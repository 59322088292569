import {InputAdornment, TextField} from '@material-ui/core'
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {useStyles} from './SearchField.config'

interface ISearchFieldProps {
  id?: string,
  value: string,
  onChange: (event: any)=>void,
  style?: object,
  label?: string,
  className?: string
  iconColor?: string
  autoComplete?: string
}

const SearchField: React.FC<ISearchFieldProps> = ({
  id = '',
  value = '',
  onChange,
  style = null,
  label = 'Search',
  className = '',
  iconColor = 'grey',
  autoComplete= 'on'}) => {

  const handleClearSearch = (): void => {
    value = ''
    onChange({target: {value: ''}})
  }

  const classes = useStyles()
  let clearButtonVisible = value?.length > 0 ? classes.show_clear_button : classes.hide_clear_button
  return (
    <TextField id={id} placeholder={label} type='text' className={className}
               onChange={onChange} style={style} value={value} autoComplete={autoComplete}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start' color={iconColor}>
            <FontAwesomeIcon icon={faSearch} color={iconColor}/>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end' color={iconColor} onClick={handleClearSearch} className={'clickable' + ' ' + clearButtonVisible}>
            <FontAwesomeIcon icon={faTimesCircle} color={iconColor}/>
          </InputAdornment>
        ),
        classes:{
          root: classes.white_text
        }
      }}/>
  )
}

export default SearchField
