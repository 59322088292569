import STATUS from './Status'
import Action from './Action'
import 'reflect-metadata'
import {Type} from 'class-transformer'
import {assign} from 'lodash'
import TimerHelper from '../../helpers/TimerHelper'

export class Pipeline {
  id: string
  @Type(() => Action)
  actions: Action[]
  projectId: string
  status: STATUS
  @Type(() => Date)
  startTime: Date
  @Type(() => Date)
  endTime: Date
  createdBy: string
  parentPipelineId: string
  pipelineTemplateId: string

  constructor(props?: Partial<Pipeline>) {
    props = props || {}

    const defaultProps: Partial<Pipeline> = {
      actions: undefined,
      status: undefined,
      startTime: undefined,
      endTime: undefined
    }
    assign(this, defaultProps, props)
  }

  get duration(): string {
    let duration: string = null

    if (this.startTime && this.endTime) {
      const durationInSeconds: number = Math.round((this.endTime.getTime() - this.startTime.getTime()) / 1000)
      duration = TimerHelper.getTime(durationInSeconds)
    }

    return duration
  }
}

export default Pipeline
