import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Tabs, Tab, Grid } from '@material-ui/core'
import IStoreState from '../../../../../store/IStoreState'
import { SectionLayout } from '../../shared'
import Project, { PROJECT_TYPE } from '../../../../../models/Project'
import ProjectOverviewEnvironments from './project-overview-environments.component'
import ProjectPipelineHistory from './project-pipeline-overview-history.component'

const envTabLabel = 'Environments'
const historyTabLabel = 'History'

enum SubTabs {
  ENVIRONMENTS_OVERVIEW,
  HISTORY,
}

export interface ProjectOverviewProps {
  project?: Project
}

export const ProjectOverview: React.FC<ProjectOverviewProps> = ({ project }) => {
  const showEnvironments: boolean = !!project && project.type !== PROJECT_TYPE.WORKFLOW
  const defaultTab = showEnvironments ? SubTabs.ENVIRONMENTS_OVERVIEW : SubTabs.HISTORY
  const [activeTab, setActiveTab] = useState(defaultTab)
  const handleSubTabChange = (event: React.ChangeEvent<{}>, tab: SubTabs): void => {
    setActiveTab(tab)
  }

  return (
    <SectionLayout>
      <Grid item sm={12}>
        <Tabs value={activeTab} onChange={handleSubTabChange} indicatorColor="primary" textColor="primary">
          {showEnvironments && <Tab label={envTabLabel} value={SubTabs.ENVIRONMENTS_OVERVIEW} />}
          <Tab label={historyTabLabel} value={SubTabs.HISTORY} />
        </Tabs>
      </Grid>
      <Grid item sm={12} className="tabContainer">
        {activeTab === SubTabs.ENVIRONMENTS_OVERVIEW && <ProjectOverviewEnvironments />}
        {activeTab === SubTabs.HISTORY && <ProjectPipelineHistory />}
      </Grid>
    </SectionLayout>
  )
}

const mapStateToProps: any = (state: IStoreState): any => ({
  project: state.projectWizard.project,
})

export default connect(mapStateToProps, null)(ProjectOverview)
