import { createMuiTheme, Theme } from '@material-ui/core'
import { buildFontSetting } from './util'
import { GREY, COMMON, PRIMARY, SUCCESS, WARNING, DANGER, PAPER, CUSTOM } from './color'
import { INTER } from './font'

const fontSetting = buildFontSetting(INTER.family, INTER.sans)

export const CascadeTheme: Theme = createMuiTheme({
  palette: {
    common: COMMON,
    background: {
      paper: PAPER[500],
      default: PAPER[500],
    },
    primary: {
      light: CUSTOM.blue[500],
      main: CUSTOM.blue[500],
      dark: CUSTOM.blue[700],
      contrastText: PAPER[500],
    },
    secondary: {
      light: PAPER[500],
      main: PAPER[500],
      dark: PAPER[500],
      contrastText: CUSTOM.gray[700],
    },
    error: {
      light: DANGER[300],
      main: CUSTOM.red[300],
      dark: DANGER[700],
      contrastText: PAPER[500],
    },
    warning: {
      light: WARNING[300],
      main: CUSTOM.orange[500],
      dark: WARNING[700],
      contrastText: '#f2a225',
    },
    info: {
      light: PRIMARY[300],
      main: PRIMARY[500],
      dark: PRIMARY[700],
      contrastText: PAPER[500],
    },
    success: {
      light: SUCCESS[300],
      main: CUSTOM.green[500],
      dark: SUCCESS[700],
      contrastText: PAPER[500],
    },
    grey: { ...GREY },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: fontSetting,
  },
  shape: {
    borderRadius: 4,
  },
})
