import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { CUSTOM } from 'src/theme/lib/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      backgroundColor: CUSTOM.blue[900],
      textTransform: 'none',
    },
    saveButton: {
      textTransform: 'none',
    }
  }),
)
