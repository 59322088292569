import React from 'react'
import { Grid, TextField, TextFieldProps, Tooltip, Typography } from '@material-ui/core'
import { useStyles } from './create-form-field.config'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type CreateFormTextFieldProps = TextFieldProps & {
  textFieldLabel: string
  tooltipMessage?: string
}

export const CreateFormTextField: React.FC<CreateFormTextFieldProps> = ({ textFieldLabel, tooltipMessage, ...rest }) => {
  const styles = useStyles()
  const textFieldVariant = 'subtitle2'
  const tooltipSpacing = 1
  const labelSpacing = 1
  return (
    <Grid item container direction="column" spacing={labelSpacing}>
      <Grid item container alignItems="center" spacing={tooltipSpacing}>
        <Grid item>
          <Typography variant={textFieldVariant} className={styles.textFieldLabel}>
            {textFieldLabel}
          </Typography>
        </Grid>
        {tooltipMessage && (
          <Grid item>
            <Tooltip title={tooltipMessage} placement="right" enterDelay={50}>
              <div>
                <FontAwesomeIcon icon={faInfoCircle} className={styles.infoIcon} />
              </div>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <TextField variant="outlined" fullWidth {...rest} />
      </Grid>
    </Grid>
  )
}
