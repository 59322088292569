
import {assign} from 'lodash'
import {SERVICE_ACTION} from './ServiceActionConfiguration'

export class ServiceActionType {
    id: SERVICE_ACTION
    value: string

    constructor(props?: Partial<ServiceActionType>) {
        props = props || {}
        const defaultProps: Partial<ServiceActionType> = {
            id: undefined,
            value: undefined
        }
        assign(this, defaultProps, props)
    }
}
