import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'
import {addSelectedEnvironmentDetails} from 'src/store/update-project-data/project-details-environment.action'
import IStoreState from 'src/store/IStoreState'
import { EnvironmentSelectionConfig, resolveUnselectedEnvironmentConfigs } from './project-environment.config'
import { UnselectedEnvironmentItem } from 'src/components/project/project-create/shared/environment-unselected-item.component'
import { Grid } from '@material-ui/core'
import { useStyles } from './environment-unSelected.config'

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {}

type UnselectedEnvironmentsDetailsListProps = PropsFromRedux & ComponentProps

export const UnselectedEnvironmentsDetailsList: React.FC<UnselectedEnvironmentsDetailsListProps> = ({ unselected, add }) => {
    const styles = useStyles()
    return (
        <div className={styles.container}>
            <Grid container spacing={2}>
                {unselected.map((config, index) => {
                    return (
                        <Grid item key={index}>
                            <UnselectedEnvironmentItem config={config} add={add} />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

const mapStateToProps = (state: IStoreState, ownProps: ComponentProps) => ({
    unselected: resolveUnselectedEnvironmentConfigs(
        state.deploymentEnvironments.data,
        state.selectedEnvironmentList.data,
    ),
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ComponentProps) => ({
    add: (config: EnvironmentSelectionConfig) => {
        dispatch(addSelectedEnvironmentDetails(config))
    },
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedUnselectedEnvironmentsDetailsList = connector(UnselectedEnvironmentsDetailsList)
