import initialState from '../initialState'
import ActionTypes from '../actions'
import {IHeaderState} from '../IStoreState'

export default function headerReducer(state: IHeaderState = initialState.header, action: any): IHeaderState {
  switch (action.type) {
    case ActionTypes.HEADER_SET_ACTIVE_TAB:
      return {
        ...state,
        selectedTab: action.payload.tab
      }
    default:
      return state
  }
}
