import React from 'react'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import SnackbarService, {SNACKBAR_TYPES} from 'src/services/SnackbarService'
import GenericModal from 'src/components/common/GenericModal'
import DeploymentEnvironmentHelper from 'src/helpers/DeploymentEnvironmentHelper'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import Project from 'src/models/Project'
import {ACTION_TYPE} from 'src/models/pipeline/Action'
import PipelineService from 'src/services/PipelineService'
import PipelineTrigger, {PIPELINE_TRIGGER_TYPE} from 'src/models/pipeline/PipelineTrigger'

export interface IMergeCodeModalState {
  fromEnvId: string,
  toEnvId: string
}

export interface IMergeCodeModalProps {
  environment: ProjectEnvironment,
  environments: ProjectEnvironment[],
  project: Project,
  handleModalClose: () => void,
  openModal: boolean
}

class MergeCodeModal extends React.Component<IMergeCodeModalProps, IMergeCodeModalState> {
  constructor(props: IMergeCodeModalProps) {
    super(props)

    const toEnv: ProjectEnvironment = props.project.projectEnvironments.find((env: ProjectEnvironment) => env.order === props.environment.order + 1)

    this.state = {
      fromEnvId: props.environment.environmentId,
      toEnvId: toEnv ? toEnv.environmentId : null,
    }
  }

  handleChange = (event: any): void => {
    this.setState({[event.target.name]: event.target.value} as any)
  }

  handleFromEnvChange = (event: any): void => {
    const newFromEnvId: string = event.target.value
    if (event.target.value === this.state.toEnvId) {
      this.setState({fromEnvId: newFromEnvId, toEnvId: null})
    } else {
      this.setState({fromEnvId: newFromEnvId})
    }
  }

  createCodeDeployment = async (): Promise<void> => {
    const {project} = this.props

    const triggers: PipelineTrigger[] = [
      new PipelineTrigger({type: PIPELINE_TRIGGER_TYPE.ACTION_TYPE, value: ACTION_TYPE.CODE_MERGE}),
      new PipelineTrigger({type: PIPELINE_TRIGGER_TYPE.SOURCE_ENV, value: this.state.fromEnvId}),
      new PipelineTrigger({type: PIPELINE_TRIGGER_TYPE.TARGET_ENV, value: this.state.toEnvId})
    ]

    try {
      await PipelineService.triggerPipeline(project.id, triggers)
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Triggered Code Merge Pipeline.')
    } catch (error) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, error || 'Failed to trigger Code Merge Pipeline!')
    }
  }

  handleMergeClick = (): void => {
    this.createCodeDeployment()
    this.props.handleModalClose()
  }

  render(): React.ReactNode {
    const {project, environment, environments, openModal, handleModalClose} = this.props

    const envsToDeployTo: ProjectEnvironment[] = environments.filter((targetEnv: ProjectEnvironment) =>
      targetEnv.environmentId !== this.state.fromEnvId && project.canMergeCodeTo(targetEnv.environmentId) && project.canMergeEnvironments(environment, targetEnv))

    if (environment) {
      return (
        <GenericModal open={openModal} onClose={handleModalClose}>
          <h2>Merge Code</h2>
          <Grid container>
            <Grid item xs={12}>
              <h4>From</h4>
              <Select className='select' value={this.state.fromEnvId} onChange={this.handleFromEnvChange}
                      name='fromEnvId'>
                {environments
                  .filter((env: ProjectEnvironment) => project.canMergeCodeFrom(env.environmentId))
                  .map((env: ProjectEnvironment) =>
                    <MenuItem key={env.environmentId}
                              value={env.environmentId}>{DeploymentEnvironmentHelper.getEnvironmentTitleById(env.environmentId)}</MenuItem>)
                }
              </Select>
              <FormHelperText>{project.projectEnvironments.find((env: ProjectEnvironment) => env.environmentId === this.state.fromEnvId).projectEnvironmentCode.branch.name}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <h4>Into</h4>
              <Select className='select' value={this.state.toEnvId} onChange={this.handleChange} name='toEnvId'>
                {envsToDeployTo.map((env: ProjectEnvironment) =>
                  <MenuItem key={env.environmentId}
                            value={env.environmentId}>{DeploymentEnvironmentHelper.getEnvironmentTitleById(env.environmentId)}</MenuItem>)
                }
              </Select>
              <FormHelperText>
                {this.state.toEnvId ? project.projectEnvironments.find((env: ProjectEnvironment) => env.environmentId === this.state.toEnvId).projectEnvironmentCode.branch.name : 'Select an environment'}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} className='modal-actions'>
              <Grid container spacing={8} justify='flex-end'>
                <Grid item>
                  <Button onClick={handleModalClose} variant='contained' color='secondary' disableElevation>Cancel</Button>
                </Grid>
                <Grid item>
                  <Button onClick={this.handleMergeClick} variant='contained' color='primary'
                          disabled={!this.state.toEnvId}>Merge</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </GenericModal>
      )
    } else {
      return null
    }
  }
}

export default MergeCodeModal
