import axios from 'axios'
import DeploymentTarget, { DEPLOYMENT_TARGET_STATUS } from '../models/DeploymentTarget'

class DeploymentTargetService {
  static DEPLOYMENT_TARGET_API_ROOT:string = '/api/deploymenttarget/v1/deploymenttargets'

  async getDeploymentTargets(all: boolean): Promise<DeploymentTarget[]> {
    const res: any = await axios.get(DeploymentTargetService.DEPLOYMENT_TARGET_API_ROOT, {params: {all}})
    return res.data.map((deploymentTargetJSON: DeploymentTarget) => new DeploymentTarget(deploymentTargetJSON))
  }

  async getDeploymentTarget(id: string): Promise<DeploymentTarget> {
    const res: any = await axios.get(`${DeploymentTargetService.DEPLOYMENT_TARGET_API_ROOT}/${id}`)
    return new DeploymentTarget(res.data)
  }

  async createDeploymentTarget(deploymentTarget: DeploymentTarget): Promise<DeploymentTarget> {
    const res: any = await axios.post(`${DeploymentTargetService.DEPLOYMENT_TARGET_API_ROOT}`, deploymentTarget)
    return new DeploymentTarget(res.data)
  }

  async updateDeploymentTarget(deploymentTarget: DeploymentTarget): Promise<DeploymentTarget> {
    const id: string = deploymentTarget.id
    delete deploymentTarget.id
    if (deploymentTarget.status && deploymentTarget.status.includes('FAILED')) {
      deploymentTarget.status = DEPLOYMENT_TARGET_STATUS.INCOMPLETE
    }
    const res: any = await axios.patch(`${DeploymentTargetService.DEPLOYMENT_TARGET_API_ROOT}/${id}`, deploymentTarget)
    return new DeploymentTarget(res.data)
  }

  async putDeploymentTarget(deploymentTarget: DeploymentTarget): Promise<DeploymentTarget> {
    const res: any = await axios.put(`${DeploymentTargetService.DEPLOYMENT_TARGET_API_ROOT}/${deploymentTarget.id}`, deploymentTarget)
    return new DeploymentTarget(res.data)
  }

  deleteDeploymentTarget(id: string): Promise<any> {
    return axios.delete(`${DeploymentTargetService.DEPLOYMENT_TARGET_API_ROOT}/${id}`)
  }
}

export default new DeploymentTargetService()
