import React from 'react'
import { MenuEntryConfig, MenuSection } from './section-menu.config'
import { MenuOpen } from './menu-open.component'
import { MenuClosed } from './menu-closed.component'

export interface SectionMenuProps {
  entries: MenuEntryConfig[]
  isCollapsed: boolean
  activeSection: MenuSection
  setActiveSection: (section: MenuSection) => void
}

export const SectionMenu: React.FC<SectionMenuProps> = ({ isCollapsed, activeSection, setActiveSection, entries }) => {
  return isCollapsed ? (
    <MenuClosed activeSection={activeSection} setActiveSection={setActiveSection} entries={entries} />
  ) : (
    <MenuOpen activeSection={activeSection} setActiveSection={setActiveSection} entries={entries} />
  )
}
