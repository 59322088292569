import {assign} from 'lodash'
import 'reflect-metadata'
import {Type} from 'class-transformer'
import DeploymentTargetGroup from './DeploymentTargetGroup'

export enum DEPLOYMENT_ENVIRONMENT_ERROR {
  ENVIRONMENT_IN_USE = 'ENVIRONMENT_IN_USE',
  ENVIRONMENT_DUPLICATE_TITLE = 'ENVIRONMENT_DUPLICATE_TITLE',
}

export enum DEPLOYMENT_ENVIRONMENT_STATUS {
  ACTIVE = 'ACTIVE',
  MISSING_DEPLOYMENT_TARGET = 'MISSING_DEPLOYMENT_TARGET',
}

class DeploymentEnvironment {

  id: string
  title: string
  @Type(() => DeploymentTargetGroup)
  deploymentTargetGroups: DeploymentTargetGroup[]
  isDev: boolean
  isProd: boolean
  order: number
  status:DEPLOYMENT_ENVIRONMENT_STATUS

  constructor(props?: Partial<DeploymentEnvironment>) {
    props = props || {}

    const defaultProps: Partial<DeploymentEnvironment> = {
      id: undefined,
      title: undefined,
      deploymentTargetGroups: undefined,
      isDev: undefined,
      isProd: undefined,
      order: undefined,
      status: undefined
    }
    assign(this, defaultProps, props)
  }

  get webrootTitle(): string {
    return this.title.toLowerCase().replace(' ', '_')
  }

  get deploymentTitle(): string {
    return this.title.toLowerCase().replace(' ', '')
  }

}

export default DeploymentEnvironment
