import React from 'react'
import { faTimesCircle, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@material-ui/core'
import { CUSTOM } from 'src/theme/lib/color'
import { EnvironmentSelectionConfig } from '../drupal8/drupal8-environments.config'
import { useStyles } from './environment-item.config'
import { Draggable } from 'react-beautiful-dnd'
import {DEPLOYMENT_ENVIRONMENT_STATUS} from '../../../../models/DeploymentEnvironment'

type ComponentProps = {
  position: number
  config: EnvironmentSelectionConfig
  remove: (config: EnvironmentSelectionConfig) => void
}

export type EnvironmentItemProps = ComponentProps

export const EnvironmentItem: React.FC<EnvironmentItemProps> = ({ config, position, remove }) => {
  const styles = useStyles()
  const environmentError = config.status !== DEPLOYMENT_ENVIRONMENT_STATUS.ACTIVE
  const deselectEnvironment = () => {
    remove(config)
  }
  const renderRemoveIcon = () => {
    if (!(config.isDev || config.isProd)) {
      return (
        <Grid item>
          <div onClick={deselectEnvironment}>
            <FontAwesomeIcon icon={faTimesCircle} size="lg" color={CUSTOM.blue[600]} />
          </div>
        </Grid>
      )
    }
    return null
  }
  return (
    <Draggable draggableId={config.id} index={position}>
      {(provided) => {
        return (
          <div
            className={styles.container}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Grid container justify="space-between" alignItems="center" alignContent="center" spacing={2} wrap="nowrap">
              <Grid item container spacing={2}>
                <Grid item>
                  <div>
                    <FontAwesomeIcon icon={faBars} size="lg" color={CUSTOM.gray[800]} />
                  </div>
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={[styles.titleText, environmentError ? styles.errorText : ''].join(' ')}>
                    {config.title}
                  </Typography>
                </Grid>
              </Grid>
              {renderRemoveIcon()}
            </Grid>
          </div>
        )
      }}
    </Draggable>
  )
}
