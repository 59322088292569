import React, { useState } from 'react'
import { drupal8FormSteps, useStyles } from './create-drupal8-workflow.config'
import { FormSteps } from '../shared/form-steps.component'
import {ConnectedDrupal8EnvironmentsForm, Drupal8EnvironmentsForm} from './drupal8-environments.component'
import { ConnectedDrupal8DetailsForm } from './drupal8-details.component'
import { FORM_STEP_TYPE } from '../forms'
import { ConnectedCreateProjectCodeForm } from '../forms/code-form.component'

export type Drupal8CreateWorkflowProps = {}

export const Drupal8CreateWorkflow: React.FC<Drupal8CreateWorkflowProps> = ({}) => {
  const styles = useStyles()
  const defaultStep = drupal8FormSteps[0].step
  const [activeStep, setActiveStep] = useState<FORM_STEP_TYPE>(defaultStep)
  return (
    <div className={styles.rootContainer}>
      <div className={styles.stepContainer}>
        <FormSteps activeStep={activeStep} formSteps={drupal8FormSteps} />
      </div>
      <Drupal8CreateForm activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )
}

export interface Drupal8CreateFormProps {
  activeStep: FORM_STEP_TYPE
  setActiveStep: (step: FORM_STEP_TYPE) => void
}

export const Drupal8CreateForm: React.FC<Drupal8CreateFormProps> = ({ activeStep, setActiveStep }) => {
  switch (activeStep) {
    case FORM_STEP_TYPE.DETAILS:
      return <ConnectedDrupal8DetailsForm setActiveStep={setActiveStep} />
    case FORM_STEP_TYPE.ENV:
      return <ConnectedDrupal8EnvironmentsForm setActiveStep={setActiveStep} />
    case FORM_STEP_TYPE.CODE:
      return <ConnectedCreateProjectCodeForm />
    default:
      return <ConnectedDrupal8DetailsForm setActiveStep={setActiveStep} />
  }
}
