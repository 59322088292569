import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.common.white,
    },
    path: {
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize * 2.5,
    },
  }),
)
