import React from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './project-create-breadcrumb.config'

const variant = 'body1'

export interface ProjectCreateBreadcrumbProps {
  path: string
  icon: IconDefinition
}

export const ProjectCreateBreadcrumb: React.FC<ProjectCreateBreadcrumbProps> = ({ icon, path }) => {
  const styles = useStyles()
  return (
    <Grid container alignItems="center" spacing={3} wrap="nowrap">
      <Grid item>
        <div>
          <FontAwesomeIcon icon={icon} className={styles.icon} size="2x" />
        </div>
      </Grid>
      <Grid item>
        <Typography variant={variant} className={styles.path} noWrap>
          {path}
        </Typography>
      </Grid>
    </Grid>
  )
}
