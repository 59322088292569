import React from 'react'
import Project from "../../../../../models/Project";
import User from "../../../../../models/User";
import UserService from "../../../../../services/UserService";
import ProjectUsersTableRow from "./project-users-tablerow";
import {Table, TableBody} from '@material-ui/core'
import {ButtonConfig, useStyles} from './project-users.config'
import {Grid} from '@material-ui/core'
import ProjectUsersTableHeader from "./project-users-table-header";
import {ProjectUsersTitleBar} from "./project-users-title.component";
import Spinner from "../../../../common/Spinner";
import SnackbarService, {SNACKBAR_TYPES} from "../../../../../services/SnackbarService";
import RetryLoading from "../../../../common/RetryLoading";


export const sortDirections = {
  ASC: 'asc',
  DESC: 'desc',
}

export type IProjectUsersDetailsState = {
  error: boolean,
  project: Project,
  updatedUserList: User[],
  allUsers: User[],
  usersWithRoles: User[],
  roles: any[],
  columns: any[],
  usersWithAndWithoutRoles: User[],
  roleTitlesList: string[],
  userListForRows: any[],
  modifiedUsers: string[],
  sortDirection: any,
  usersLoading: boolean,
}

class ProjectUsersDetails extends React.Component<{ project?: Project }, IProjectUsersDetailsState> {

  constructor(props: any) {
    super(props)
    this.state = {
      error: false,
      project: this.props.project,
      updatedUserList: [],
      allUsers: [],
      usersWithRoles: [],
      roles: [],
      columns: [],
      usersWithAndWithoutRoles: [],
      roleTitlesList: ['admin', 'manager', 'deployer', 'developer', 'observer'],
      userListForRows: [],
      modifiedUsers: [],
      sortDirection: sortDirections.ASC,
      usersLoading: true,
    }

  }

  componentWillMount = () => {
    this.setProjectUsersState()
  }

  setProjectUsersState = () => {
    this.loadUsersWithRolesAndRoleGroups(this.props.project.id)
    this.createColumnsForTable()
    this.loadRoles()
  }

  resetUserRoleState = () => {
    this.setState({
      updatedUserList: [],
      allUsers: [],
      usersWithRoles: [],
      usersWithAndWithoutRoles: [],
      userListForRows: [],
      modifiedUsers: [],
      usersLoading: true,
      error: false,
    })
    this.loadUsersWithRolesAndRoleGroups(this.props.project.id)
  }

  loadUsersWithRolesAndRoleGroups = (projectId: string) => {
    UserService.getUserRolesByProjectId(projectId)
      .then((res: User[]) => {
        this.setState({usersWithRoles: res})
        this.loadAllUsers()
      })
      .catch(() => this.setState({error: true}))
  }

  loadAllUsers = () => {
    UserService.getUsers()
      .then((res: User[]) => {
        this.setState({allUsers: res})
        this.createListOfUsersWithAndWithoutRoles(res, this.state.usersWithRoles)
      })
      .catch(() => this.setState({error: true}))
  }

  createListOfUsersWithAndWithoutRoles = (allUsers: User[], usersWithRoles: User[]): void => {
    let tempArray: User[] = []

    usersWithRoles.forEach((each) => {
      tempArray.push(each)
    })

    // add the remaining users who do not have a role for this project
    allUsers.forEach((each) => {
      let count = 0
      tempArray.forEach((existingUser) => {
        if (existingUser.username == each.username) {
          count++
        }
      })
      if (count == 0) {
        tempArray.push(each)
      }

    })

    this.setState({usersWithAndWithoutRoles: tempArray})
    this.createUserListForRows()
  }

  createUserListForRows = () => {
    const users = this.state.usersWithAndWithoutRoles
    const listOfRoles = this.state.roleTitlesList
    const projectId = this.state.project.id

    let userRowArray: any[] = [] // this will contain objects to be used for rows

    users.forEach((each) => {
      let rolesTypeArray: any[] = []

      listOfRoles.forEach((each) => {
        let tempObj = {
          'type': each,
          'value': false,
          'globalRole': false
        }
        rolesTypeArray.push(tempObj)
      })

      if (each.roles.length > 0 ) {
        each.roles.forEach((r) => {
          listOfRoles.forEach((roleTitle) => {
            if (roleTitle.toLowerCase() === r.name.toLowerCase()) {
              rolesTypeArray.forEach((arr) => {
                if (arr.type.toLowerCase() == roleTitle.toLowerCase()) {
                  if (r.projectId == projectId) {
                    arr.value = true
                  }
                }
              })
            }
          })
        })
      }

      if (each.roleGroups.length > 0) {
        each.roleGroups.forEach((rg: any) => {
          listOfRoles.forEach((roleTitle) => {
            if (this.roleTypeToRoleGroupType(roleTitle) === rg.name) {
              rg.roles.forEach((r: any) => {
                if (projectId === r.projectId) {
                  rolesTypeArray.forEach((arr) => {
                    if (arr.type.toLowerCase() == roleTitle.toLowerCase()) {
                      arr.globalRole = true
                    }
                  })
                }
              })
            }
          })
        })
      }

      const userDto = {
        'username': each.username,
        'rolesList': rolesTypeArray,
        'tooltip': 'User has Global Role'
      }
      userRowArray.push(userDto)
    })

    this.sortItems(userRowArray, this.state.sortDirection, 'username')
  }

  loadRoles = () => {
    const projectId = this.state.project.id
    UserService.getRoles()
      .then((res: any[]) => {
        let roleList: any[] = []
        res.forEach((r) => {
          if (r.projectId === projectId) {
            roleList.push(r)
          }
        })
        this.setState({roles: roleList})
      })
      .catch(() => this.setState({error: true}))
  }

  createColumnsForTable = () => {
    const localRoleList = this.state.roleTitlesList
    let columnsList: any[] = []

    columnsList.push({
      "id": "username",
      "numeric": false,
      "disablePadding": false,
      "label": "Username",
      "sortable": true,
      "order": this.state.sortDirection
    })

    localRoleList.forEach((eachRole) => {
      columnsList.push({
        "id": eachRole,
        "numeric": false,
        "disablePadding": false,
        "label": eachRole,
        "sortable": false,
        "order": this.state.sortDirection
      })
    })

    this.setState({columns: columnsList})
  }

  handleCheckboxChange = (e: any) => {
    const checked = e.target.checked
    const role = e.target.value
    const userName = e.target.name

    this.updateUserListForRows(this.state.userListForRows, userName, role, checked)
    this.addUserNameToModifiedUserList(this.state.modifiedUsers, userName)
  }

  addUserNameToModifiedUserList = (modifiedUsers: string[], username: string) => {
    const index = modifiedUsers.findIndex(each => each === username)
    if (index === -1) {
      modifiedUsers.push(username)
      this.setState({modifiedUsers: modifiedUsers})
    }
  }

  updateUserListForRows = (usersList: any[], username: string, roleType: string, value: boolean) => {
    const index = usersList.findIndex(user => user.username === username)
    const roleIndex = usersList[index].rolesList.findIndex((role: { type: string }) => role.type === roleType)
    usersList[index].rolesList[roleIndex].value = value
    this.setState({userListForRows: usersList})
  }

  handleSave = () => {
    const usersToUpdate = this.populateUpdatedUserList()
    this.setState({modifiedUsers: []})

    UserService.batchUpdateUsers(usersToUpdate, this.state.project.id)
      .then(res => {
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'User permissions saved')
        this.resetUserRoleState()
      })
      .catch(e => {
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to save users permissions!')
        this.setState({error: true})
      })
  }

  populateUpdatedUserList = (): User[] => {
    const listWithUsersNewRoles = this.state.userListForRows  // list with the most recent changes
    const usersWithAndWithoutRoles = this.state.usersWithAndWithoutRoles
    const modifiedUsers = this.state.modifiedUsers  // list that has usernames which were modified
    const projectRoles = this.state.roles

    let listToUpdate: User[] = []

    modifiedUsers.forEach((username: string) => {
      let previousUserObj = usersWithAndWithoutRoles.find(user => user.username === username)
      const changesForUser = listWithUsersNewRoles.find(u => u.username.toLowerCase() === username.toLowerCase())
      const user = this.addRoleToUser(previousUserObj, changesForUser.rolesList, projectRoles)
      listToUpdate.push(user)
    })

    return listToUpdate
  }

  addRoleToUser = (user: User, rolesToUpdateList: any[], rolesList: any[]): User => {
    let newRoleList: any[] = []

    rolesToUpdateList.forEach((update) => {
      if (update.value) {
        newRoleList.push(rolesList.find(r => r.name.toLowerCase() === update.type.toLowerCase()))
      }
    })

    user.roles = newRoleList
    return user
  }

  roleTypeToRoleGroupType = (roleName: string) => {
    switch (roleName) {
      case 'manager':
        return 'Global Project Manager'
      case 'admin':
        return 'Global Project Administrator'
      case 'deployer':
        return 'Global Project Deployer'
      case 'developer':
        return 'Global Project Developer'
      case 'observer':
        return 'Global Project Observer'
      default:
        return ''
    }
  }

  saveButtonDisable = (): boolean => {
    const length = this.state.modifiedUsers.length
    return length == 0;
  }

  resetButtonDisable = (): boolean => {
    const length = this.state.modifiedUsers.length
    return length == 0;
  }

  handleSort = (e: any, property: string, order: any) => {
    const newDirection = order === sortDirections.DESC ? sortDirections.ASC : sortDirections.DESC
    this.setState({sortDirection: newDirection})

    const userList = this.state.userListForRows
    this.sortItems(userList, newDirection, property)
  }

  sortItems = (items: any[], order: any, orderBy: string) => {
    items = order === sortDirections.DESC ?
      items.sort((a, b) => (a[orderBy] < b[orderBy]) ? 1 : -1) :
      items.sort((a, b) => (b[orderBy] < a[orderBy]) ? 1 : -1)

    this.setState({userListForRows: items, usersLoading: false})
  }

  selectAllOrNoUsersHandler = (label: string, value: boolean) => {
    const roleType = label
    const newValue = value
    const userListForRows = this.state.userListForRows
    const modifiedUsers = this.state.modifiedUsers
    const projectId = this.state.project.id

    userListForRows.forEach((user) => {
      if (!this.userHasRoleGroupOfTypeByProject(user, roleType, projectId)) {
        this.updateUserListForRows(userListForRows, user.username, roleType, newValue)
        this.addUserNameToModifiedUserList(modifiedUsers, user.username)
      }
    })
  }

  userHasRoleGroupOfTypeByProject = (user: User, roleType: string, projectId: string): boolean => {
    user.roleGroups?.forEach((roleGroup) => {
      if (roleGroup.name === this.roleTypeToRoleGroupType(roleType)) {
        roleGroup.roles?.forEach((role: any) => {
          return role.projectId === projectId;
        })
      }
    })
    return false
  }

  resetUsersHandler = () => {
    this.resetUserRoleState()
  }

  render() {

    const saveButtonDisabled = this.saveButtonDisable()
    const resetButtonDisable = this.resetButtonDisable()
    const saveButtonConfig: ButtonConfig = {
      showTooltip: saveButtonDisabled,
      showButton: true,
      isDisabled: saveButtonDisabled,
      tooltip: 'No change made to a user',
      onClick: this.handleSave,
      text: 'Save',
      style: useStyles.saveButton
    }
    const resetButtonConfig: ButtonConfig = {
      showTooltip: !resetButtonDisable,
      showButton: true,
      isDisabled: resetButtonDisable,
      tooltip: 'Undo all changes since last save',
      onClick: this.resetUsersHandler,
      text: 'Reset',
      style: useStyles.resetButton
    }
    const usersTitleBarStyle = useStyles.userTitleBar
    const {usersLoading, error} = this.state

    return (
      <Grid container alignItems="center" alignContent="center" justify="center" spacing={1}>

        <ProjectUsersTitleBar
          style={usersTitleBarStyle}
          resetButtonConfig={resetButtonConfig}
          saveButtonConfig={saveButtonConfig}
        />

        {error && <RetryLoading message='Could not load users.'
                                reloadData={this.resetUserRoleState}/>}

        {!error &&usersLoading && <Grid container justify='center'><Spinner large/></Grid>}

        {!error && !usersLoading && <Grid container alignItems="center" alignContent="center" justify="center" spacing={1}>
          <Table>
            <ProjectUsersTableHeader
              order={this.state.sortDirection}
              orderBy={'username'}
              columns={this.state.columns}
              onRequestSort={this.handleSort}
              onSelectAll={this.selectAllOrNoUsersHandler}
            >
            </ProjectUsersTableHeader>

            <TableBody>
              {this.state.userListForRows.length > 0 && this.state.userListForRows.map((item, index) => {
                return <ProjectUsersTableRow key={index} user={item} handleClick={this.handleCheckboxChange}/>
              })}
            </TableBody>
          </Table>

        </Grid>
        }
      </Grid>
    )
  }
}

export default ProjectUsersDetails
