import {Dispatch} from 'redux'
import {createMiddleware, MiddlewareFn, registerMiddleware} from '../store/middlewares'
import Project from '../models/Project'
import IStoreState from '../store/IStoreState'
import ActionTypes from '../store/actions'
import {random, times} from 'lodash'
import DeploymentEnvironmentHelper from './DeploymentEnvironmentHelper'
import ProjectEnvironmentCodeWebroot from '../models/ProjectEnvironmentCodeWebroot'
import DeploymentEnvironment from '../models/DeploymentEnvironment'
import DeploymentTargetGroup from "../models/DeploymentTargetGroup";

export const DEFAULT_PASSWORD_LENGTH: number = 16

class ProjectHelper {
  static getProjectNameById(id: string): string {
    const fetchedProject: Project = ProjectHelper.projects.find((project: Project) => id === project.id)

    if (fetchedProject) {
      return fetchedProject.name
    }

    return null
  }

  static handleProjectsAction: MiddlewareFn = (action: any, state: IStoreState, next: Dispatch) => {
    if (action.type === ActionTypes.PROJECTS_LOAD_SUCCESS) {
      ProjectHelper.projects = action.payload.projects
    }

    return next(action)
  }

  static getProjects(): Project[] {
    return ProjectHelper.projects
  }

  static getCleanedProjectName(name: string): string {
    return name.trim().toLowerCase().replace(/([^a-z_0-9]+)/gi, '');
  }

  static getRandomDatabasePassword(): string {
    return times(DEFAULT_PASSWORD_LENGTH, () => random(35).toString(36)).join('')
  }

  static getDefaultWebrootForEnvironment(projectName: string, environmentId: string): ProjectEnvironmentCodeWebroot {
    return new ProjectEnvironmentCodeWebroot({
      dir1: 'var/www',
      cascade: 'cascade',
      env: DeploymentEnvironmentHelper.getDeploymentEnvironmentById(environmentId).webrootTitle,
      site: ProjectHelper.getCleanedProjectName(projectName),
      dir2: 'htdocs'
    })
  }

  static getDefaultDatabaseNameForEnvironment(projectName: string, environmentId: string, dbName?: string): string {
    const cleanedProjectName: string = !!dbName ? dbName : ProjectHelper.getCleanedProjectName(projectName)
    const deploymentEnvironment: DeploymentEnvironment = DeploymentEnvironmentHelper.getDeploymentEnvironmentById(environmentId)
    return deploymentEnvironment.isProd ? cleanedProjectName :
      `${deploymentEnvironment.title.toLowerCase().replace(' ', '_')}_${cleanedProjectName}`
  }

  static getDefaultAssetPathForEnvironment(projectName: string, webroot: string, environmentId: string): string {
    return webroot
      ? `${webroot}/assets`
      : `/var/www/cascade/${DeploymentEnvironmentHelper.getEnvironmentTitleById(environmentId).toLowerCase()}/${ProjectHelper.getCleanedProjectName(projectName)}/assets`
  }

  static isProjectEnvironmentCodeActionValid(project: Project, environmentId: string) {
    return this.isProjectEnvironmentActionValid(environmentId, project.getCodeDeploymentGroupIdByEnv(environmentId))
  }

  static isProjectEnvironmentAssetActionValid(project: Project, environmentId: string) {
    return this.isProjectEnvironmentActionValid(environmentId, project.getAssetTargetGroupIdByEnv(environmentId))
  }

  static isProjectEnvironmentDatabaseActionValid(project: Project, environmentId: string) {
    return this.isProjectEnvironmentActionValid(environmentId, project.getDatabaseTargetGroupIdByEnv(environmentId))
  }

  private static isProjectEnvironmentActionValid(environmentId: string, deploymentGroupId: string): boolean {
    const deploymentEnvironmentIsActive = DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(environmentId)

    // TODO: currently the user will need to re-save the project if the deployment group gets deleted and recreated
    // as the id reference will no longer be the same.
    if (deploymentEnvironmentIsActive) {
      const env = DeploymentEnvironmentHelper.getDeploymentEnvironmentById(environmentId)
      const groups = env.deploymentTargetGroups.filter((group: DeploymentTargetGroup) => group.id === deploymentGroupId)
      if (groups.length === 0) {
        return false
      }
    }

    return deploymentEnvironmentIsActive
  }

  private static projects: Project[]
}

registerMiddleware(createMiddleware(ProjectHelper.handleProjectsAction))

export default ProjectHelper
