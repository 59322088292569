import React from 'react'
import PropTypes from 'prop-types'
import Project from '../../../../../models/Project'
import {faDatabase} from '@fortawesome/free-solid-svg-icons'
import {Button, Tooltip} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import NoPermissionButton from '../NoPermissionButton'
import {PermissionHelper} from '../../../../../helpers/PermissionHelper'
import {ACTION_TYPE} from '../../../../../models/pipeline/Action'
import '../DeploymentTrack.scss'
import DeploymentEnvironmentHelper from "../../../../../helpers/DeploymentEnvironmentHelper";
import ProjectHelper from "../../../../../helpers/ProjectHelper";

export interface CopyDatabaseButtonProps {
  project: Project
  environmentId: string
  handleCopyDatabaseClick: any
}

class CopyDatabaseButton extends React.Component<CopyDatabaseButtonProps, {}> {
  render() {
    const {project, environmentId, handleCopyDatabaseClick} = this.props

    if (PermissionHelper.hasPermissionForAction(ACTION_TYPE.DATABASE_COPY, project, environmentId)) {
      const envIsDisabled = !ProjectHelper.isProjectEnvironmentDatabaseActionValid(project, environmentId)
      return (
        <Tooltip title={envIsDisabled ? DeploymentEnvironmentHelper.getInactiveEnvironmentTooltipTitle(environmentId) : ''}>
          <span>
            <Button onClick={handleCopyDatabaseClick}
                    variant='contained'
                    color='primary'
                    disabled={envIsDisabled}>
              <FontAwesomeIcon icon={faDatabase} className='buttonIcon'/> Copy
            </Button>
          </span>
        </Tooltip>
      )
    }

    return <NoPermissionButton icon={faDatabase} text='Copy'/>
  }
}

// @ts-ignore
CopyDatabaseButton.propTypes = {
  handleCopyDatabaseClick: PropTypes.func.isRequired,
  environmentId: PropTypes.string.isRequired,
  project: PropTypes.instanceOf(Project).isRequired,
}

export default CopyDatabaseButton
