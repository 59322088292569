import {IPipelineTemplateEditorState} from '../IStoreState'
import initialState from '../initialState'
import ActionTypes from '../actions'
import {cloneDeep} from 'lodash'
import PipelineTemplateEditorErrorHelper from '../../services/PipelineTemplateEditorErrorHelper'

export default function pipelineTemplateEditorReducer(state: IPipelineTemplateEditorState = initialState.pipelineTemplateEditor, action: any): IPipelineTemplateEditorState {
  switch (action.type) {
    case ActionTypes.PIPELINE_TEMPLATE_EDITOR_OPEN:
      return {...state, open: true, touched: false, pipelineTemplate: action.payload.pipelineTemplate, errors: new Map<string, string>()}
    case ActionTypes.PIPELINE_TEMPLATE_EDITOR_CLOSE:
      return {...state, open: false, pipelineTemplate: null, errors: new Map<string, string>()}
    case ActionTypes.UPDATE_PIPELINE_TEMPLATE_EDITOR_FIELD: {
      const updatedState: IPipelineTemplateEditorState = cloneDeep(state)
      const {key, value} = action.payload
      updatedState.pipelineTemplate[key] = value
      updatedState.touched = true
      updatedState.errors = PipelineTemplateEditorErrorHelper.getErrorsForPipelineTemplate(updatedState.pipelineTemplate)
      return updatedState
    }
    default:
      return state
  }
}
