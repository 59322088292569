import React, { useState } from 'react'
import { otherFormSteps, useStyles } from './create-other-workflow.config'
import { FormSteps } from '../shared/form-steps.component'
import { ConnectedOtherDetailsForm } from './other-details.component'
import { FORM_STEP_TYPE } from '../forms'
import { ConnectedOtherProjectCodeForm } from './other-code.component'
import {ConnectedOtherEnvironmentsForm} from "./other-environments.component";

export type OtherCreateWorkflowProps = {}

export const OtherCreateWorkflow: React.FC<OtherCreateWorkflowProps> = ({}) => {
  const styles = useStyles()
  const defaultStep = otherFormSteps[0].step
  const [activeStep, setActiveStep] = useState<FORM_STEP_TYPE>(defaultStep)
  return (
    <div className={styles.rootContainer}>
      <div className={styles.stepContainer}>
        <FormSteps activeStep={activeStep} formSteps={otherFormSteps} />
      </div>
      <OtherCreateForm activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )
}

export interface OtherCreateFormProps {
  activeStep: FORM_STEP_TYPE
  setActiveStep: (step: FORM_STEP_TYPE) => void
}

export const OtherCreateForm: React.FC<OtherCreateFormProps> = ({ activeStep, setActiveStep }) => {
  switch (activeStep) {
    case FORM_STEP_TYPE.DETAILS:
      return <ConnectedOtherDetailsForm setActiveStep={setActiveStep} />
    case FORM_STEP_TYPE.ENV:
      return <ConnectedOtherEnvironmentsForm setActiveStep={setActiveStep} />
    case FORM_STEP_TYPE.CODE:
      return <ConnectedOtherProjectCodeForm />
    default:
      return <ConnectedOtherDetailsForm setActiveStep={setActiveStep} />
  }
}
