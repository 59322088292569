import {cloneDeep} from 'lodash'
import {Action, Dispatch} from 'redux'
import { PROJECT_ENVIRONMENTS_FIELD } from 'src/components/project/project-create/shared/project-field-names'
import {removeAssetGroupsAndSymlinksFromEnvironments} from 'src/helpers/project/environment/asset/env-asset-transformation'
import {transformProjectEnvironments} from 'src/helpers/project/environment/environment-processor'
import {addNewAssetGroupAndSymlinks} from 'src/helpers/project/environment/new-asset-symlink-processor'
import {createProcessIO, createProjectProcessContext, ProjectValidationError} from 'src/helpers/shared'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import Project from 'src/models/Project'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import IStoreState from 'src/store/IStoreState'
import ActionTypes from '..'

export function updateProjectEnvironmentsAction(selectedEnvironments: ProjectEnvironment[]): any {
  return async (dispatch: Dispatch<Action>, getState: () => IStoreState): Promise<any> => {
    const appState = getState()
    const project: Project = cloneDeep(appState.selectedProject.project)
    const deploymentEnvironments: DeploymentEnvironment[] = cloneDeep(appState.deploymentEnvironments.data)
    const config = {
      code: project.hasCode,
      asset: project.hasAssets,
      database: project.hasDatabases,
      links: project.hasAssets, // TODO: needs better links checking
    }
    project.projectEnvironments = selectedEnvironments
    const errors: ProjectValidationError[] = []
    const input = createProcessIO(project, errors)
    const context = createProjectProcessContext(project, deploymentEnvironments, config)
    const output = transformProjectEnvironments(input, context)

    const updateKey = PROJECT_ENVIRONMENTS_FIELD
    const projectEnvironmentsWithDefaults = output.value.projectEnvironments
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
      payload: { key: updateKey, value: projectEnvironmentsWithDefaults },
    })
  }
}

export function createNewDefaultAssetGroupsAndSymlinks(): any {
  return async (dispatch: Dispatch<Action>, getState: () => IStoreState): Promise<any> => {
    const appState = getState()
    const project: Project = cloneDeep(appState.selectedProject.project)
    const deploymentEnvironments: DeploymentEnvironment[] = cloneDeep(appState.deploymentEnvironments.data)
  
    const errors: ProjectValidationError[] = []
    const input = createProcessIO(project, errors)
    const context = {
      project,
      deploymentEnvironments
    }
    const output = addNewAssetGroupAndSymlinks(input, context)

    const updateKey = PROJECT_ENVIRONMENTS_FIELD
    const environmentsWithNewAssetGroupAndLink = output.value.projectEnvironments
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
      payload: { key: updateKey, value: environmentsWithNewAssetGroupAndLink },
    })
  }
}

export function createNewDefaultAssetGroupsAndSymlinksWizard(): any {
  return async (dispatch: Dispatch<Action>, getState: () => IStoreState): Promise<any> => {
    const appState = getState()
    const project: Project = cloneDeep(appState.projectWizard.project)
    const deploymentEnvironments: DeploymentEnvironment[] = cloneDeep(appState.deploymentEnvironments.data)

    const errors: ProjectValidationError[] = []
    const input = createProcessIO(project, errors)
    const context = {
      project,
      deploymentEnvironments
    }
    const output = addNewAssetGroupAndSymlinks(input, context)

    const updateKey = PROJECT_ENVIRONMENTS_FIELD
    const environmentsWithNewAssetGroupAndLink = output.value.projectEnvironments
    dispatch({
      type: ActionTypes.UPDATE_PROJECT_WIZARD_FIELD,
      payload: { key: updateKey, value: environmentsWithNewAssetGroupAndLink },
    })
  }
}

export function deleteAssetGroupAndSymlinkByAssociationId(associationId: number): any {
  return async (dispatch: Dispatch<Action>, getState: () => IStoreState): Promise<any> => {
    const appState = getState()
    const project: Project = cloneDeep(appState.selectedProject.project)
    const environmentsWithoutAssetGroupAndSymlink = removeAssetGroupsAndSymlinksFromEnvironments(project.projectEnvironments, associationId)
    const updateKey = PROJECT_ENVIRONMENTS_FIELD
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
      payload: { key: updateKey, value: environmentsWithoutAssetGroupAndSymlink },
    })
  }
}

export function deleteAssetGroupAndSymlinkByAssociationIdWizard(associationId: number): any {
  return async (dispatch: Dispatch<Action>, getState: () => IStoreState): Promise<any> => {
    const appState = getState()
    const project: Project = cloneDeep(appState.projectWizard.project)
    const environmentsWithoutAssetGroupAndSymlink = removeAssetGroupsAndSymlinksFromEnvironments(project.projectEnvironments, associationId)
    const updateKey = PROJECT_ENVIRONMENTS_FIELD
    dispatch({
      type: ActionTypes.UPDATE_PROJECT_WIZARD_FIELD,
      payload: { key: updateKey, value: environmentsWithoutAssetGroupAndSymlink },
    })
  }
}