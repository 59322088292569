import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faServer,
  faTerminal,
  faSlidersH,
  faWindowRestore,
  faCode,
  faDatabase,
  faFolder,
  faLink,
  faUser
} from '@fortawesome/free-solid-svg-icons'

export enum MenuSection {
  OVERVIEW = 'overview',
  PIPELINES = 'pipelines',
  HISTORY = 'history',
  PROJECT_DETAILS = 'details',
  USERS = 'users',
  ENVIRONMENT = 'environments',
  CODE = 'code' ,
  DATABASES = 'databases',
  ASSETS = 'assets',
  LINKS = 'links'
}

export enum MenuEntryType {
  Entry,
  Divider,
}

export interface MenuEntryConfig {
  label: string
  IconDefinition: IconDefinition
  type: MenuEntryType
  section: MenuSection
}

export const divider: MenuEntryConfig = {
  label: '',
  IconDefinition: null,
  type: MenuEntryType.Divider,
  section: null,
}

export const overview: MenuEntryConfig = {
  label: 'Overview',
  IconDefinition: faServer,
  type: MenuEntryType.Entry,
  section: MenuSection.OVERVIEW,
}

export const pipelines: MenuEntryConfig = {
  label: 'Pipelines',
  IconDefinition: faTerminal,
  type: MenuEntryType.Entry,
  section: MenuSection.PIPELINES,
}

export const users: MenuEntryConfig = {
  label: 'Users',
  IconDefinition: faUser,
  type: MenuEntryType.Entry,
  section: MenuSection.USERS,
}

export const details: MenuEntryConfig = {
  label: 'Project Details',
  IconDefinition: faSlidersH,
  type: MenuEntryType.Entry,
  section: MenuSection.PROJECT_DETAILS,
}

export const environments: MenuEntryConfig = {
  label: 'Environment',
  IconDefinition: faWindowRestore,
  type: MenuEntryType.Entry,
  section: MenuSection.ENVIRONMENT,
}

export const code: MenuEntryConfig = {
  label: 'Code',
  IconDefinition: faCode,
  type: MenuEntryType.Entry,
  section: MenuSection.CODE,
}

export const databases: MenuEntryConfig = {
  label: 'Databases',
  IconDefinition: faDatabase,
  type: MenuEntryType.Entry,
  section: MenuSection.DATABASES,
}

export const assets: MenuEntryConfig = {
  label: 'Assets',
  IconDefinition: faFolder,
  type: MenuEntryType.Entry,
  section: MenuSection.ASSETS,
}

export const links: MenuEntryConfig = {
  label: 'Links',
  IconDefinition: faLink,
  type: MenuEntryType.Entry,
  section: MenuSection.LINKS,
}

export const fullEditMenuEntries: MenuEntryConfig[] = [
  overview,
  pipelines,
  users,
  divider,
  details,
  environments,
  code,
  databases,
  assets,
  links
]

export const workflowMenuEntries: MenuEntryConfig[] = [
  pipelines,
  users,
  divider,
  details,
  environments
]

export const viewOnlyEntries: MenuEntryConfig[] = [overview, pipelines]
