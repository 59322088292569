import React from 'react'
import PropTypes from 'prop-types'

import IconButton from '@material-ui/core/IconButton'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSync} from '@fortawesome/free-solid-svg-icons'

class RetryLoading extends React.Component {
  render = () => {
    return (
      <div className="centered">
        {this.props.message} Retry?
        <br/><br/>
        <IconButton style={{marginRight: '1rem'}}
          onClick={this.props.reloadData}
          variant="fab"
          color="primary"
          title="Retry">
          <FontAwesomeIcon icon={faSync} size='sm' style={{cursor: 'pointer', padding: '0'}}/>
        </IconButton>
      </div>
    )
  }
}

RetryLoading.propTypes = {
  message: PropTypes.string.isRequired,
  reloadData: PropTypes.func.isRequired,
}


export default RetryLoading
