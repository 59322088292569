import STATUS from './Status'
import 'reflect-metadata'
import {Type} from 'class-transformer'
import {assign} from 'lodash'
import Task from './Task'
import {ActionConfiguration} from './ActionConfiguration'
import AssetMergeActionConfiguration from './AssetMergeActionConfiguration'
import CodeDeployActionConfiguration from './CodeDeployActionConfiguration'
import CodeMergeActionConfiguration from './CodeMergeActionConfiguration'
import CodePromoteActionConfiguration from './CodePromoteActionConfiguration'
import DatabaseCopyActionConfiguration from './DatabaseCopyActionConfiguration'
import DatabaseSnapshotActionConfiguration from './DatabaseSnapshotActionConfiguration'
import DatabaseImportActionConfiguration from './DatabaseImportActionConfiguration'
import {FileTemplateActionConfiguration} from './FileTemplateActionConfiguration'
import {ScriptActionConfiguration} from './ScriptActionConfiguration'
import {ServiceActionConfiguration} from './ServiceActionConfiguration'
import TimerHelper from '../../helpers/TimerHelper'

export enum ACTION_TYPE {
  DATABASE_SNAPSHOT = 'DATABASE_SNAPSHOT',
  DATABASE_COPY = 'DATABASE_COPY',
  DATABASE_IMPORT = 'DATABASE_IMPORT',
  DEPLOYMENT_TARGET_KEY_LOGIN = 'DEPLOYMENT_TARGET_KEY_LOGIN',
  DEPLOYMENT_TARGET_PASSWORD_LOGIN = 'DEPLOYMENT_TARGET_PASSWORD_LOGIN',
  DEPLOYMENT_TARGET_ESCALATION_LOGIN = 'DEPLOYMENT_TARGET_ESCALATION_LOGIN',
  DEPLOYMENT_TARGET_INSTALL = 'DEPLOYMENT_TARGET_INSTALL',
  DEPLOYMENT_TARGET_IP_PORT_VERIFICATION = 'DEPLOYMENT_TARGET_IP_PORT_VERIFICATION',
  ASSET_MERGE = 'ASSET_MERGE',
  CODE_MERGE = 'CODE_MERGE',
  CODE_PROMOTE = 'CODE_PROMOTE',
  CODE_DEPLOY = 'CODE_DEPLOY',
  FILE_TEMPLATE = 'FILE_TEMPLATE',
  SCRIPT = 'SCRIPT',
  SERVICE = 'SERVICE'
}


type ActionConfigurationType = AssetMergeActionConfiguration | CodeDeployActionConfiguration | CodeMergeActionConfiguration |
  CodePromoteActionConfiguration | DatabaseCopyActionConfiguration | DatabaseSnapshotActionConfiguration | DatabaseImportActionConfiguration |
  FileTemplateActionConfiguration |ScriptActionConfiguration | ServiceActionConfiguration

class Action {
  id: string

  @Type(() => ActionConfiguration, {
    discriminator: {
      property: 'actionType',
      subTypes: [
        {value: AssetMergeActionConfiguration, name: ACTION_TYPE.ASSET_MERGE},
        {value: CodeDeployActionConfiguration, name: ACTION_TYPE.CODE_DEPLOY},
        {value: CodeMergeActionConfiguration, name: ACTION_TYPE.CODE_MERGE},
        {value: CodePromoteActionConfiguration, name: ACTION_TYPE.CODE_PROMOTE},
        {value: DatabaseCopyActionConfiguration, name: ACTION_TYPE.DATABASE_COPY},
        {value: DatabaseSnapshotActionConfiguration, name: ACTION_TYPE.DATABASE_SNAPSHOT},
        {value: DatabaseImportActionConfiguration, name: ACTION_TYPE.DATABASE_IMPORT},
        {value: FileTemplateActionConfiguration, name: ACTION_TYPE.FILE_TEMPLATE},
        {value: ScriptActionConfiguration, name: ACTION_TYPE.SCRIPT},
        {value: ServiceActionConfiguration, name: ACTION_TYPE.SERVICE}
      ]
    }
  })
  actionConfiguration: ActionConfigurationType
  order: number
  status: STATUS
  @Type(() => Date)
  startTime: Date
  @Type(() => Date)
  endTime: Date
  @Type(() => Task)
  tasks: Task[]
  parentPipelineId: string
  estimatedDuration: number

  constructor(props?: Partial<Action>) {
    props = props || {}

    const defaultProps: Partial<Action> = {
      actionConfiguration: undefined,
      order: undefined,
      status: undefined,
      startTime: undefined,
      endTime: undefined,
      estimatedDuration: undefined
    }
    assign(this, defaultProps, props)
  }

  get logs(): string[] {
    return this.tasks.map((task: Task) => task.log)
  }

  get duration(): string {
    let duration: string = null

    if (this.startTime && this.endTime) {
      const durationInSeconds: number = Math.round((this.endTime.getTime() - this.startTime.getTime()) / 1000)
      duration = TimerHelper.getTime(durationInSeconds)
    }

    return duration
  }
}

export default Action
