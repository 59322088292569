import React from 'react'
import {Grid, IconButton, Tooltip} from '@material-ui/core'
import RetryLoading from '../../common/RetryLoading'
import DeploymentTargetsTable from './DeploymentTargetsTable'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHdd, faInfoCircle, faPlus} from '@fortawesome/free-solid-svg-icons'
import DeploymentTargetWizard from './DeploymentTargetWizard'
import DeploymentTarget, {LOGIN_AUTH_TYPE} from '../../../models/DeploymentTarget'
import wizardHelper from './DeploymentTargetWizardHelper'
import User from '../../../models/User'
import Spinner from '../../common/Spinner'
import IStoreState from '../../../store/IStoreState'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import {loadDeploymentTargets} from '../../../store/actions/deploymentTargetActions'

export interface IDeploymentTargetsSectionProps {
  currentUser: User,
  deploymentTargets?: DeploymentTarget[],
  loadDeploymentTargets?: ()=>void,
  loading?: boolean,
  error?: boolean,
}

export interface IDeploymentTargetsSectionState {
  openDeploymentTargetWizard: boolean,
  selectedDeploymentTarget: DeploymentTarget,
}

export class DeploymentTargetsSection extends React.Component<IDeploymentTargetsSectionProps, IDeploymentTargetsSectionState> {
  constructor(props: IDeploymentTargetsSectionProps) {
    super(props)
    this.state = {
      openDeploymentTargetWizard: false,
      selectedDeploymentTarget: null,
    }
    wizardHelper.subscribeToWizardClose(this.reloadData)
  }

  componentWillUnmount(): void {
    wizardHelper.unsubscribeFromWizardClose(this.reloadData)
  }

  reloadData = (): void => {
    this.setState({selectedDeploymentTarget: null, openDeploymentTargetWizard: false})
    this.props.loadDeploymentTargets()
  }

  handleAddDeploymentTargetClick = (): void => {
    const newTarget: DeploymentTarget = new DeploymentTarget({
      port: 22,
      remoteUser: 'contegix_ab',
      loginAuthType: LOGIN_AUTH_TYPE.PASSWORD
    })
    this.setState({selectedDeploymentTarget: newTarget, openDeploymentTargetWizard: true})

  }

  handleDeploymentTargetRowClick = (deploymentTarget:DeploymentTarget):void => {
    this.setState({selectedDeploymentTarget: deploymentTarget.clone(), openDeploymentTargetWizard: true})
  }

  render():React.ReactNode {
    const {deploymentTargets, loading, error} = this.props
    return (
      <Grid container>
        <Grid container>
          <Grid container>
            <Grid item sm={12}>
              <Grid container direction='row' alignItems='center' spacing={3}>
                <Grid item>
                  <FontAwesomeIcon icon={faHdd} size='2x' />
                </Grid>
                <Grid item>
                  <h2>Deployment Targets</h2>
                </Grid>
                <Grid item style={{color: '#ccc'}}>
                  <Tooltip placement='top' enterDelay={50}
                    title='A Deployment Target is a computing resource that parts of an application can be deployed to.'>
                    <div>
                      <FontAwesomeIcon icon={faInfoCircle}/>
                    </div>
                  </Tooltip>
                </Grid>
                {this.props.currentUser.canAdministerDeploymentTarget() &&
                  <Grid item>
                    <IconButton id='ab-dt-add-button'
                      style={{marginBottom: '4px'}} title='Create New Deployment Target'
                      onClick={this.handleAddDeploymentTargetClick}>
                      <FontAwesomeIcon
                        icon={faPlus} size='sm'
                        className={'clickable'}/>
                    </IconButton>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            { loading && <Grid container justify='center'><Spinner large/></Grid> }
            { error &&
              <RetryLoading message='Could not load deployment targets.' reloadData={this.reloadData}/>
            }
            {!loading && !error && deploymentTargets.length < 1 &&
              <RetryLoading message='There are currently no deployment targets.' reloadData={this.reloadData}/>
            }
            { !loading && !error && deploymentTargets.length > 0 &&
              <DeploymentTargetsTable
                deploymentTargets={deploymentTargets}
                handleDeploymentTargetRowClick={this.handleDeploymentTargetRowClick}/>
            }
          </Grid>
        </Grid>
        {this.state.openDeploymentTargetWizard &&
          <DeploymentTargetWizard deploymentTarget={this.state.selectedDeploymentTarget}/>
        }
      </Grid>
    )
  }
}

const mapStateToProps: any = (state: IStoreState): any => ({
  deploymentTargets: state.deploymentTargets.data,
  loading: state.deploymentTargets.loading,
  error: state.deploymentTargets.error,
})

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  loadDeploymentTargets: (): void => dispatch(loadDeploymentTargets())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentTargetsSection)
