import { Grid, Icon, Input, Paper, Switch } from '@material-ui/core'
import * as React from 'react'
import { find } from 'lodash'
import IStoreState from '../../../../../store/IStoreState'
import { Dispatch } from 'redux'
import ActionTypes from '../../../../../store/actions'
import { connect } from 'react-redux'
import FormField from '../../../../common/FormField'
import Project, { PROJECT_TYPE } from '../../../../../models/Project'
import ImportRepository from '../../../../../models/ImportRepository'
import ProjectEnvironment from '../../../../../models/ProjectEnvironment'
import ProjectType from '../../../../../models/ProjectType'
import MessageService from '../../../../../services/MessageService'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'

export interface IImportRepositoryWidgetState {
  repoRequiresAuthentication: boolean
}

export interface IImportRepositoryWidgetProps {
  project?: Project
  projectTypes?: ProjectType[]
  handleInputChange?: (event: any) => void
  disabled?: boolean
  importError?: boolean
}

export class ImportRepositoryWidget extends React.Component<IImportRepositoryWidgetProps, IImportRepositoryWidgetState> {
  static defaultProps: any = {
    disabled: false,
    importError: false
  }

  storedImportRepository: ImportRepository
  userEditedRepoUrl: boolean

  constructor(props: IImportRepositoryWidgetProps) {
    super(props)
    const { importRepository } = props.project.codeRepository
    this.state = {
      repoRequiresAuthentication: importRepository && !!importRepository.username
    }
    this.userEditedRepoUrl = false

    // NOTE: This component needs to extract logic and this is a band-aid for an edge case of adding envs after a failed import
    if (props.importError) {
      const newImportRepository: ImportRepository = this.generateInitialImportRepository()
      const existingImportRepo: ImportRepository = props.project.codeRepository.importRepository
      newImportRepository.url = existingImportRepo.url
      newImportRepository.keepHistory = existingImportRepo.keepHistory
      this.updateImportRepository(newImportRepository)
    }
  }

  generateInitialImportRepository = (): ImportRepository => {
    const { project, projectTypes } = this.props

    const projectType: ProjectType = find(projectTypes, { value: project.type })
    const importRepository: ImportRepository = new ImportRepository({
      url: projectType.defaultImportRepoUrl,
      keepHistory: projectType.value === PROJECT_TYPE.OTHER
    })
    const importBranchName: string = projectType.defaultImportBranchOrTag

    importRepository.abBranchToImportBranch = new Map()
    this.props.project.projectEnvironments.forEach((projEnv: ProjectEnvironment) => {
      importRepository.abBranchToImportBranch.set(projEnv.projectEnvironmentCode.branch.name, importBranchName)
    })

    return importRepository
  }

  toggleImportRepository = (): void => {
    const { codeRepository } = this.props.project
    if (codeRepository.importRepository) {
      this.storedImportRepository = codeRepository.importRepository
      this.updateImportRepository(null)
    } else {
      this.updateImportRepository(this.storedImportRepository || this.generateInitialImportRepository())
    }
  }

  toggleKeepHistory = (): void => {
    const { codeRepository } = this.props.project
    this.updateImportRepository({ ...codeRepository.importRepository, keepHistory: !codeRepository.importRepository.keepHistory })
  }

  handleInput = (event: any): void => {
    const { codeRepository } = this.props.project
    if (event.target.name === 'url') {
      if (!this.userEditedRepoUrl) {
        this.userEditedRepoUrl = true
        codeRepository.importRepository.keepHistory = true
      }
      this.updateImportRepository({ ...codeRepository.importRepository, url: event.target.value })
    } else if (event.target.name === 'username') {
      this.updateImportRepository({ ...codeRepository.importRepository, username: event.target.value })
    } else if (event.target.name === 'password') {
      this.updateImportRepository({ ...codeRepository.importRepository, password: event.target.value })
    }
  }

  updateImportRepository = (importRepository: ImportRepository): void => {
    this.props.handleInputChange({
      target: {
        name: 'codeRepository',
        value: { ...this.props.project.codeRepository, importRepository }
      }
    })
  }

  toggleRepoRequiresAuthentication = (): void => {
    const { codeRepository } = this.props.project
    this.updateImportRepository({ ...codeRepository.importRepository, password: null, username: null })
    this.setState({ repoRequiresAuthentication: !this.state.repoRequiresAuthentication })
  }

  render(): React.ReactNode {
    const { repoRequiresAuthentication } = this.state
    const { project, disabled, importError } = this.props
    if (disabled && !importError) {
      const disabledImportMessage: string = MessageService.get('project.wizard.code.import.disabled', { url: project.codeRepository.importRepository.url })
      return (
        <Grid container spacing={2} justify='flex-end'>
          <Grid item>
            <Tooltip title={disabledImportMessage} placement='left'>
              <Icon><FontAwesomeIcon icon={faDownload} size='xs' /></Icon>
            </Tooltip>
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <Switch checked={!!project.codeRepository.importRepository} onChange={this.toggleImportRepository} />Clone from an existing
          repository
        </Grid>
        {project.codeRepository.importRepository &&
          <Grid item xs={12}>
            <Paper className={classNames('import-repository-widget', { 'import-error': importError })}>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <FormField label='Git Clone URL'>
                    <Input name='url' value={project.codeRepository.importRepository.url} onChange={this.handleInput} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <Switch checked={project.codeRepository.importRepository.keepHistory} onChange={this.toggleKeepHistory} />Keep repository
                history
              </Grid>
                <Grid item xs={12}>
                  <Switch checked={repoRequiresAuthentication} onChange={this.toggleRepoRequiresAuthentication} />Repository requires
                authentication
              </Grid>
                {repoRequiresAuthentication &&
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormField label='Username'>
                          <Input name='username' value={project.codeRepository.importRepository.username} onChange={this.handleInput} />
                        </FormField>
                      </Grid>
                      <Grid item xs={6}>
                        <FormField label='Password'>
                          <Input name='password' type='password' value={project.codeRepository.importRepository.password}
                            onChange={this.handleInput} />
                        </FormField>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Paper>
          </Grid>
        }
      </Grid>
    )
  }
}


const mapStateToProps: any = (state: IStoreState, ownProps: IImportRepositoryWidgetProps): any => ({
  project: state.selectedProject.project,
  projectTypes: state.projectTypes.data,
  error: state.selectedProject.error,
  loading: state.selectedProject.loading
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IImportRepositoryWidgetProps): any => ({
  handleInputChange: (event: any): void => {
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
      payload: {
        key: event.target.name,
        value: event.target.value
      }
    })
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportRepositoryWidget)
