import React from 'react'
import GenericDialog from '../../common/GenericDialog'
import {Button, DialogActions, Grid, Switch} from '@material-ui/core'
import DeploymentEnvironment from '../../../models/DeploymentEnvironment'
import DeploymentTargetGroup from '../../../models/DeploymentTargetGroup'
import DeploymentEnvironmentHelper from '../../../helpers/DeploymentEnvironmentHelper'
import ComboBox, {ComboBoxEntry} from '../../common/ComboBox'
import {DEPLOYMENT_TARGET_GROUP_TYPE} from "../../../models/DeploymentTargetGroup";

export interface ICreateDeploymentEnvironmentProps {
  onClose: (environment?: DeploymentEnvironment, environmentIdToClone?: string) => void,
  environments: DeploymentEnvironment[],
}

export interface ICreateDeploymentEnvironmentState {
  suggestedNamesOptions: ComboBoxEntry[],
  environmentOptions: ComboBoxEntry[],
  isClone: boolean,
  environmentIdToClone: string,
  newEnvironment: DeploymentEnvironment,
}

export class CreateDeploymentEnvironment extends React.Component<ICreateDeploymentEnvironmentProps, ICreateDeploymentEnvironmentState> {

  private readonly suggestedNames: string[] = [
    'Development',
    'Sandbox',
    'Testing',
    'Integration',
    'Hotfix',
    'QA',
    'Staging',
    'Preproduction',
    'Acceptance',
    'Production'
  ]

  constructor(props: ICreateDeploymentEnvironmentProps) {
    super(props)

    this.suggestedNames = this.suggestedNames.filter((it: string):boolean =>
        props.environments.every((it2: DeploymentEnvironment):boolean => it2.title !== it))
    const suggestedNamesOptions: ComboBoxEntry[] = this.suggestedNames.map((name: string) =>
        new ComboBoxEntry(name))
    const environmentOptions: ComboBoxEntry[] = props.environments.map((env: DeploymentEnvironment) =>
        new ComboBoxEntry(env.title, env.id))

    this.state = {
      suggestedNamesOptions,
      environmentOptions,
      isClone: false,
      environmentIdToClone: null,
      newEnvironment: new DeploymentEnvironment({isProd: false, isDev: false, deploymentTargetGroups: [
          new DeploymentTargetGroup({name: 'Database', type: DEPLOYMENT_TARGET_GROUP_TYPE.DATABASE}),
          new DeploymentTargetGroup({name: 'Asset', type: DEPLOYMENT_TARGET_GROUP_TYPE.ASSET}),
          new DeploymentTargetGroup({name: 'Code', type: DEPLOYMENT_TARGET_GROUP_TYPE.CODE})
        ]})
    }
  }

  cancel = (): void => {
    this.props.onClose()
  }

  handleCloneSelect = (selectedEntry: ComboBoxEntry): void => {
    this.setState({environmentIdToClone: selectedEntry.value})
  }

  handleSelect = (selectedEntry: ComboBoxEntry): void => {
    const newEnvironment: DeploymentEnvironment = this.state.newEnvironment
    newEnvironment.title = selectedEntry.value
    this.setState({newEnvironment})
  }

  handleCreate = (newEnvTitle: string): void => {
    const newEnvironment: DeploymentEnvironment = this.state.newEnvironment
    newEnvironment.title = newEnvTitle
    this.setState({newEnvironment})
  }

  handleCloneToggle = (): void => {
    const {isClone} = this.state
    if (isClone) {
      this.setState({isClone: false, environmentIdToClone: null})
    } else {
      this.setState({isClone: true})
    }
  }

  onClose = (): void => {
    if (this.canSubmit()) {
      const newEnvironment: DeploymentEnvironment = this.state.newEnvironment
      this.props.onClose(newEnvironment, this.state.environmentIdToClone)
    }
  }

  canSubmit = (): boolean => {
    if (DeploymentEnvironmentHelper.validateEnvironmentTitle(this.state.newEnvironment.title) && !this.state.isClone) {
      return true
    }
    return !!(this.state.isClone && this.state.environmentIdToClone && DeploymentEnvironmentHelper.validateEnvironmentTitle(this.state.newEnvironment.title))
  }

  render():React.ReactNode {
    const {isClone, environmentOptions, suggestedNamesOptions, newEnvironment} = this.state
    const value:ComboBoxEntry = !!newEnvironment.title && new ComboBoxEntry(newEnvironment.title)

    const actions: React.ReactNode =
        <DialogActions>
          <Grid container className='actionsContainer' alignItems='center' justify='space-between'>
            <Grid item>
              <Button onClick={this.cancel} variant='contained' color='secondary' disableElevation>Cancel</Button>
            </Grid>
            <Grid item>
              <Button onClick={this.onClose} variant='contained' color='primary' disabled={!this.canSubmit()}>Create</Button>
            </Grid>
          </Grid>
        </DialogActions>

    return (
      <GenericDialog title='Create New Deployment Environment' actions={actions} onClose={this.cancel}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
              <ComboBox
                label='New Environment Name (valid characters include alpha-numeric and underscores)'
                creatable overrideValue={value}
                suggestions={suggestedNamesOptions}
                handleSelect={this.handleSelect}
                handleCreate={this.handleCreate}
                placeholder='Enter the name of the new environment...'/>
            </Grid>
            <Grid item xs={12} style={{zIndex: 0}}>
              <Switch checked={isClone} onChange={this.handleCloneToggle}/>Clone from existing environment
            </Grid>
            { isClone &&
              <Grid item xs={12}>
                <ComboBox suggestions={environmentOptions} label='Environment to Clone'
                          placeholder='Select an environment to clone from...' handleSelect={this.handleCloneSelect}/>
              </Grid>
            }
          </Grid>
      </GenericDialog>
    )
  }
}

export default CreateDeploymentEnvironment
