import React from 'react'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import {Grid, IconButton, Tooltip} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle, faPlus, faServer} from '@fortawesome/free-solid-svg-icons'
import DeploymentEnvironment from '../../../models/DeploymentEnvironment'
import './DeploymentEnvironmentsSection.scss'
import DeploymentEnvironmentDetail from './DeploymentEnvironmentDetail'
import RetryLoading from '../../common/RetryLoading'
import Spinner from '../../common/Spinner'
import {arrayMove} from 'react-sortable-hoc'
import SortableTabs from '../../common/SortableTabs'
import CreateDeploymentEnvironment from './CreateDeploymentEnvironment'
import IStoreState from '../../../store/IStoreState'
import {
  createDeploymentEnvironment,
  deleteDeploymentEnvironmentById,
  loadDeploymentEnvironments,
  updateDeploymentEnvironmentById
} from '../../../store/actions/deploymentEnvironmentActions'
import {setSelectedEnvironment} from '../../../store/actions/selectedEnvironmentActions'

export interface IDeploymentEnvironmentSectionProps {
  environments?: DeploymentEnvironment[]
  selectedEnvironment?: DeploymentEnvironment
  loadDeploymentEnvironments?: () => void
  updateEnvironment?: (id: string, env: DeploymentEnvironment) => void
  createEnvironment?: (env: DeploymentEnvironment, environmentIdToClone?: string) => void
  deleteEnvironment?: (id: string) => void
  selectEnvironment?: (id: string) => void
  error?: boolean
  loading?: boolean
}

export interface IDeploymentEnvironmentSectionState {
  selectedTab: number
  openNewEnvironmentDialog: boolean
}

export class DeploymentEnvironmentsSection extends React.Component<IDeploymentEnvironmentSectionProps,
  IDeploymentEnvironmentSectionState> {

  constructor(props: IDeploymentEnvironmentSectionProps) {
    super(props)
    this.state = {
      selectedTab: 0,
      openNewEnvironmentDialog: false
    }
    if (this.sortedEnvironments.length) {
      props.selectEnvironment(this.sortedEnvironments[0].id)
    }
  }

  get sortedEnvironments(): DeploymentEnvironment[] {
    return this.props.environments.sort((a: DeploymentEnvironment, b: DeploymentEnvironment) => a.order - b.order)
  }

  handleTabClick = (selectedTab: number): void => {
    this.props.selectEnvironment(this.sortedEnvironments[selectedTab].id)
    this.setState({selectedTab})
  }

  handleEnvironmentReorder = async (oldIndex: number, newIndex: number): Promise<void> => {
    let environments: DeploymentEnvironment[] = this.sortedEnvironments
    environments = arrayMove(environments, oldIndex, newIndex)
    this.setState({selectedTab: newIndex})
    const selectedEnvironment: DeploymentEnvironment = environments[newIndex]
    this.props.selectEnvironment(selectedEnvironment.id)
    this.props.updateEnvironment(selectedEnvironment.id, new DeploymentEnvironment({order: newIndex}))
  }

  handleNewEnvironmentDialogClose = async (environment?: DeploymentEnvironment, environmentIdToClone?: string): Promise<void> => {
    let {selectedTab} = this.state
    const {createEnvironment, environments} = this.props
    if (environment) {
      await createEnvironment(environment, environmentIdToClone)
      selectedTab = environments.length - 1
    }
    this.setState({openNewEnvironmentDialog: false, selectedTab})
  }

  handleAddDeploymentEnvironmentClick = (): void => {
    this.setState({openNewEnvironmentDialog: true})
  }

  handleEnvDelete = async (id: string): Promise<void> => {
    const {deleteEnvironment} = this.props
    await deleteEnvironment(id)
  }

  render(): React.ReactNode {
    const {loading, error, selectedEnvironment} = this.props
    const {selectedTab, openNewEnvironmentDialog} = this.state
    const environments: DeploymentEnvironment[] = this.sortedEnvironments
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction='row' alignItems='center' spacing={3}>
            <Grid item>
              <FontAwesomeIcon icon={faServer} size='2x'/>
            </Grid>
            <Grid item>
              <h2>Environments</h2>
            </Grid>
            <Grid item style={{color: '#ccc'}}>
              <Tooltip placement='top' enterDelay={50}
                       title='A Deployment Environment is a group of Deployment Targets application can be deployed to.'>
                <div>
                  <FontAwesomeIcon icon={faInfoCircle} className='deployment-environment-tooltip-icon'/>
                </div>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton id='ab-dt-add-button'
                          style={{marginBottom: '4px'}} title='Create New Deployment Environment'
                          onClick={this.handleAddDeploymentEnvironmentClick}>
                <FontAwesomeIcon
                  icon={faPlus} size='sm'
                  className={'clickable'}/>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        {loading && <Grid container justify='center'><Spinner large/></Grid>}
        {error && <RetryLoading message='Could not load environments.'
                                reloadData={this.props.loadDeploymentEnvironments}/>}
        {!loading && !error && environments.length > 0 &&
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <SortableTabs titles={environments.map((env: DeploymentEnvironment) => env.title)}
                            selectedTab={selectedTab}
                            handleReorder={this.handleEnvironmentReorder}
                            handleTabClick={this.handleTabClick}
                            reorderMessage='Click and hold a tab to reorder environments'
                            lockIndexes={[(environments.length - 1)]}
                            lockMessage='The production environment cannot be reordered'/>
            </Grid>
            <Grid item xs={12} className='tabContainer'>
              {selectedEnvironment &&
              <DeploymentEnvironmentDetail deleteEnvironment={this.handleEnvDelete}/>
              }
            </Grid>
          </Grid>
        </Grid>
        }
        {openNewEnvironmentDialog &&
        <CreateDeploymentEnvironment
          onClose={this.handleNewEnvironmentDialogClose}
          environments={environments}/>
        }
      </Grid>
    )
  }
}

const mapStateToProps: any = (state: IStoreState): any => ({
  environments: state.deploymentEnvironments.data,
  selectedEnvironment: state.selectedEnvironment.environment,
  error: state.deploymentEnvironments.error,
  loading: state.deploymentEnvironments.loading
})

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  loadDeploymentEnvironments: (): void => dispatch(loadDeploymentEnvironments()),
  updateEnvironment: (id: string, env: DeploymentEnvironment): void => dispatch(updateDeploymentEnvironmentById(id, env)),
  createEnvironment: (env: DeploymentEnvironment, environmentIdToClone?: string): void => dispatch(createDeploymentEnvironment(env, environmentIdToClone)),
  deleteEnvironment: (id: string): void => dispatch(deleteDeploymentEnvironmentById(id)),
  selectEnvironment: (id: string): void => dispatch(setSelectedEnvironment(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentEnvironmentsSection)
