import React from 'react'
import GenericTable, {sortDirections} from 'src/components/common/GenericTable'
import {ActionConfiguration} from 'src/models/pipeline/ActionConfiguration'
import ActionConfigurationTableRow from './ActionConfigurationTableRow'

export interface IActionConfigurationTableProps {
  actionConfigurations: ActionConfiguration[],
  handleActionConfigurationRowClick: any
  handleActionConfigurationRowDeleteClick: any
}

export interface IActionConfigurationTableState {
}

export class ActionConfigurationTable extends React.Component<IActionConfigurationTableProps, IActionConfigurationTableState> {


  columns: any[] = [
    {id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true, order:sortDirections.DESC},
    {id: 'actionType', numeric: false, disablePadding: false, label: 'Action Type', sortable: true, order:sortDirections.ASC},
    {id: 'sourceEnvironment', numeric: false, disablePadding: false, label: 'Source Environment', sortable: true, order:sortDirections.ASC},
    {id: 'targetEnvironment', numeric: false, disablePadding: false, label: 'Target Environment', sortable: true, order:sortDirections.ASC},
    {id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true, order:sortDirections.ASC},
    {id: 'lastUpdated', numeric: false, disablePadding: false, label: 'Last Updated', sortable: true, order:sortDirections.ASC}
  ]

  render(): React.ReactNode {
    return (
      <GenericTable
        handleRowClick={this.props.handleActionConfigurationRowClick}
        handleRowActions={{delete: this.props.handleActionConfigurationRowDeleteClick}}
        rowComponent={ActionConfigurationTableRow}
        items={this.props.actionConfigurations}
        columns={this.columns}
        order={sortDirections.ASC}
        orderBy='name'
        comparator={null}
        componentField={null}/>
    )
  }
}

export default ActionConfigurationTable

