import React from 'react'
import { Grid } from '@material-ui/core'
import RoleSelectionMatrix from './RoleSelectionMatrix'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import RetryLoading from '../../common/RetryLoading'
import messageService from "../../../services/MessageService"
import Spinner from "../../common/Spinner";
import "./EditUser.scss"

class EditUserTabMenu extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let noProjectRolesMessage
    const hasNoProjectRoles = isEmpty(this.props.rolesByProject)
    if (hasNoProjectRoles) {
      noProjectRolesMessage = messageService.get('admin.users.noProjectRoles')
    }

    return (
      <Grid container direction='column'>
        <Grid item className='edit-user-item'>
          <div className='tabContainer' style={{maxHeight: '50vh', padding: '1rem', overflowY: 'auto'}}>
            {this.props.loading &&
            <div className='centered'>
              <Grid direction='column' justify='center' id='editUserLoading' className='edit-user-loading' container >
                <Grid item><span>Loading user permissions, please wait...</span></Grid>
                <Grid item><Spinner large/></Grid>
              </Grid>
            </div>
            }
            {!this.props.loading && !hasNoProjectRoles &&
            <RoleSelectionMatrix
              rolesByProject={this.props.rolesByProject}
              handleCheckboxChange={this.props.handleCheckboxChange}
              handleUserRoleGroupChange={this.props.handleUserRoleGroupChange}
              handleUserSelectAllChange={this.props.handleUserSelectAllChange}
              userRoleGroups={this.props.userRoleGroups}/>}
            {!this.props.loading && hasNoProjectRoles &&
              <Grid container direction='column'>
                <Grid item style={{flex: 'none'}}>
                  <RetryLoading message={noProjectRolesMessage} reloadData={this.props.reloadData}/>
                </Grid>
              </Grid>}
          </div>
        </Grid>
      </Grid>
    )
  }
}

EditUserTabMenu.propTypes = {
  handleCheckboxChange: PropTypes.func.isRequired,
  rolesByProject: PropTypes.object.isRequired,
  handleUserRoleGroupChange: PropTypes.func.isRequired,
  userRoleGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  reloadData: PropTypes.func.isRequired,
}

export default EditUserTabMenu
