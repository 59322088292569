import React from 'react'
import { Tooltip, Button } from '@material-ui/core'
import { useStyles } from './details-form-next-button.config'

export interface DetailsFormNextButtonProps {
  disabled: boolean
  onClick: () => void
}

export const DetailsFormNextButton: React.FC<DetailsFormNextButtonProps> = ({ disabled, onClick }) => {
  const styles = useStyles()
  const tooltipText = 'Name and Domain are required.'
  const renderButton = () => {
    return (
      <Button
        onClick={onClick}
        disabled={disabled}
        variant="contained"
        color="primary"
        className={styles.nextButton}
        disableElevation
      >
        Next: Environments
      </Button>
    )
  }
  return disabled ? (
    <Tooltip title={tooltipText} placement="left" enterDelay={50}>
      <div>
        {renderButton()}
      </div>
    </Tooltip>
  ) : renderButton()
}
