import axios from 'axios'
import { get as _get } from 'lodash'

class MessageService {

  static BUNDLE_API_ROOT: string = '/api/bundle/v1/bundle/'
  static MESSAGE_API_ROOT: string = '/message-pack'

  messages: object

  get(key: string, args?: object): string {
    let templatedMessage: string = _get(this.messages, key, key)

    if (args) {
      Object.keys(args).forEach((argKey: string) => {
        templatedMessage = templatedMessage.split(`{${argKey}}`).join(args[argKey])
      })
    }

    return templatedMessage
  }

  async initMessageService(bundleId : string): Promise<void> {
    const res: any = await axios.get(`${MessageService.BUNDLE_API_ROOT}${bundleId}${MessageService.MESSAGE_API_ROOT}`)
    this.messages = res.data.messages
  }

}

export default new MessageService()
export {MessageService}
