import {assign} from 'lodash'

export default class Bundle {
  maxDeploymentTargets: number
  id: string
  gitHost: string
  gitProvider: string

  constructor(props?: Partial<Bundle>) {
    props = props || {}

    this.maxDeploymentTargets = undefined
    this.id = undefined
    this.gitHost = undefined
    this.gitProvider = undefined

    assign(this, props)
  }
}
