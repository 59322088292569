import React from 'react'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash, faInfoCircle, faLink} from '@fortawesome/free-solid-svg-icons'
import {FormLabel, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Paper, Select, TextField, Tooltip} from '@material-ui/core'
import Database from '../../../../../models/Database'
import Project, {PROJECT_ERROR} from '../../../../../models/Project'
import ProjectEnvironment from '../../../../../models/ProjectEnvironment'
import DeploymentTargetGroup from '../../../../../models/DeploymentTargetGroup'
import IStoreState from '../../../../../store/IStoreState'
import ActionTypes from '../../../../../store/actions'
import MessageService from '../../../../../services/MessageService'
import DeploymentEnvironmentHelper from '../../../../../helpers/DeploymentEnvironmentHelper'
import FormField from '../../../../common/FormField'
import '../../../shared/project.scss'
import {DatabaseCardCommandSelect} from "./project-database-card-command-select.component";

const OPEN_GRANTS_MESSAGE: string = 'Please make sure you have firewall rules in place to prevent unauthorized access. You may also restrict by IP or hostname.'
const DATABASE_NAME_TOOLTIP: string = 'This database name is based on the production database name - edit to disable.'
const DATABASE_USERNAME_TOOLTIP: string = 'This database username is based on the production database username - edit to disable.'

export interface IProjectEnvironmentDatabasesCardState {
  showDatabasePassword: boolean
}

export interface IProjectEnvironmentDatabasesCardProps {
  handleInputChange?: (event: any)=>void,
  project?: Project,
  error?: PROJECT_ERROR,
  loading?: boolean,
  className?: string,
  database: Database,
  environmentId: string,
  deploymentTargetGroupId: string,
  isProd: boolean,
  uniqueDatabaseNames: boolean,
  uniqueDatabaseUsernames: boolean,
  enableUniqueDatabaseNames: ()=>void,
  enableUniqueDatabaseUsernames: ()=> void
}

export class ProjectEnvironmentDatabasesCard extends React.Component <IProjectEnvironmentDatabasesCardProps, IProjectEnvironmentDatabasesCardState> {
  storedIP: string = ''

  constructor(props: IProjectEnvironmentDatabasesCardProps) {
    super(props)
    this.state = {
      showDatabasePassword: false
    }
  }

  handleProjectEnvironmentDatabasesUpdate = (id: string, event: any): void => {
    const { uniqueDatabaseNames, uniqueDatabaseUsernames, isProd, enableUniqueDatabaseNames, enableUniqueDatabaseUsernames, database } = this.props
    const updatedProjectEnvironments: ProjectEnvironment[] = this.props.project.projectEnvironments
    const projectEnvironmentToUpdate: ProjectEnvironment = updatedProjectEnvironments
      .find((projEnv: ProjectEnvironment) => projEnv.environmentId === id)
    const databaseToUpdate: Database = projectEnvironmentToUpdate.projectEnvironmentDatabases.databases
      .find((db:Database) => db.associationId === database.associationId)

    if (event.target.name === 'deploymentTargetGroupId') {
      projectEnvironmentToUpdate.projectEnvironmentDatabases.deploymentTargetGroupId = event.target.value
    } else if (event.target.name === 'name' && !uniqueDatabaseNames) {
      if (isProd) {
        updatedProjectEnvironments.forEach((projEnv: ProjectEnvironment) => {
          if (projEnv.environmentId !== id) {
            const currentEnvDB: Database = projEnv.projectEnvironmentDatabases.databases.find((db:Database) => db.associationId === database.associationId)
            currentEnvDB.name = currentEnvDB.name.replace(new RegExp(this.escapeSpecialCharacters(database.name) + '$'), event.target.value)
          }
        })
      } else {
        enableUniqueDatabaseNames()
      }
      databaseToUpdate.name = event.target.value
    } else if (event.target.name === 'username' && !uniqueDatabaseUsernames) {
      if (isProd) {
        updatedProjectEnvironments.forEach((projEnv: ProjectEnvironment) => {
          if (projEnv.environmentId !== id) {
            const currentEnvDB:Database = projEnv.projectEnvironmentDatabases.databases.find((db:Database) => db.associationId === database.associationId)
            currentEnvDB.username = currentEnvDB.username.replace(new RegExp(this.escapeSpecialCharacters(database.username) + '$'), event.target.value) // $ == at end of string
          }
        })
      } else {
        enableUniqueDatabaseUsernames()
      }
      databaseToUpdate.username = event.target.value
    } else {
      databaseToUpdate[event.target.name] = event.target.value
    }
    this.props.handleInputChange({
      target: {
        name: 'projectEnvironments',
        value: updatedProjectEnvironments
      }
    })
  }

  toggleAllowAllIPs = (id: string): void => {
    const { allowedIP } = this.props.database
    if (allowedIP || allowedIP === '') {
      this.storedIP = allowedIP
      this.handleProjectEnvironmentDatabasesUpdate(id, { target: { name: 'allowedIP', value: null } } )
    } else {
      this.handleProjectEnvironmentDatabasesUpdate(id, { target: { name: 'allowedIP', value: this.storedIP } } )
    }
  }

  toggleShowDatabasePassword = (): void => {
    this.setState({showDatabasePassword: !this.state.showDatabasePassword})
  }

  getErrorMessage = (error: PROJECT_ERROR): string => {
    switch (error) {
      case PROJECT_ERROR.DUPLICATE_DATABASE_NAMES:
        return MessageService.get('project.wizard.error.database.name.duplicate')
      case PROJECT_ERROR.NO_DATABASE_NAME:
        return MessageService.get('project.wizard.error.database.name.none')
      case PROJECT_ERROR.DUPLICATE_USER_NAMES:
        return MessageService.get('project.wizard.error.database.username.duplicate')
      case PROJECT_ERROR.NO_DATABASE_USERNAME:
        return MessageService.get('project.wizard.error.database.username.none')
      case PROJECT_ERROR.NO_DATABASE_PASSWORD:
        return MessageService.get('project.wizard.error.database.password.none')
      default:
        return null
    }
  }

  render(): React.ReactNode {
    const { project, loading, error, database, environmentId, deploymentTargetGroupId , isProd , className } = this.props
    const fieldStyles = { marginTop: '50px', marginBottom: '10px' };
    const projectName = project.name
    const environmentTitle = DeploymentEnvironmentHelper.getEnvironmentTitleById(environmentId)

    return (
      <Paper className={'project-environment-card input-white project-card ' + className}>
        <Grid container spacing={2} alignContent='center'>
          <Grid item xs={12}>
            <strong>{environmentTitle}</strong>
          </Grid>
          <Grid item xs={6} >
            <FormField label='Database Name'
                       mandatory={true}
                       error={error === PROJECT_ERROR.DUPLICATE_DATABASE_NAMES || error === PROJECT_ERROR.NO_DATABASE_NAME}
                       message={(error === PROJECT_ERROR.DUPLICATE_DATABASE_NAMES || error === PROJECT_ERROR.NO_DATABASE_NAME) && this.getErrorMessage(error)}>
                <TextField name='name'
                           disabled={loading}
                           value={database.name || ''}
                           onChange={this.handleProjectEnvironmentDatabasesUpdate.bind(this, environmentId)}
                           variant='outlined'
                           style={fieldStyles}
                           inputProps={{
                             endAdornment: !this.props.uniqueDatabaseNames && !isProd &&
                               <InputAdornment position='end' className='adornment'>
                                 <Tooltip title={DATABASE_NAME_TOOLTIP} placement='top' enterDelay={50}>
                                   <div><FontAwesomeIcon icon={faLink}/></div>
                                 </Tooltip>
                               </InputAdornment>
                           }} />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <Grid style={{ marginTop: '7px' }} container justify='center' direction='column'>
                <Grid item style={{ marginTop: '14px' }} xs={12}>
                    <FormLabel>Deployment Group</FormLabel><FormLabel style={{ color: 'red' ,marginLeft: '5px'}}>*</FormLabel>
                </Grid>
                <Grid item style={{ marginTop: '8px' }} xs={12}>
                    <Select disabled={loading}
                            value={deploymentTargetGroupId}
                            variant='outlined'
                            style={{ width: '100%' }}
                            onChange={this.handleProjectEnvironmentDatabasesUpdate.bind(this, environmentId)}
                            inputProps={{name: 'deploymentTargetGroupId'}}>
                        {
                          DeploymentEnvironmentHelper.getDeploymentEnvironmentById(environmentId).deploymentTargetGroups.map((group: DeploymentTargetGroup) => {
                            return <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                          })
                        }
                    </Select>
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormField label='Database Username'
                       mandatory={true}
                       error={error === PROJECT_ERROR.DUPLICATE_USER_NAMES || error === PROJECT_ERROR.NO_DATABASE_USERNAME}
                       message={(error === PROJECT_ERROR.DUPLICATE_USER_NAMES || error === PROJECT_ERROR.NO_DATABASE_USERNAME) && this.getErrorMessage(error)}>
              <TextField name='username'
                         disabled={loading}
                         value={database.username || ''}
                         variant='outlined'
                         onChange={this.handleProjectEnvironmentDatabasesUpdate.bind(this, environmentId)}
                         style={fieldStyles}
                         inputProps={{
                           endAdornment: !this.props.uniqueDatabaseUsernames && !isProd &&
                             <InputAdornment position='end' className='adornment'>
                               <Tooltip title={DATABASE_USERNAME_TOOLTIP} placement='top' enterDelay={50}>
                                 <div><FontAwesomeIcon icon={faLink}/></div>
                               </Tooltip>
                             </InputAdornment>
                         }} />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField label='Database Password'
                       mandatory={true}
                       error={error === PROJECT_ERROR.NO_DATABASE_PASSWORD}
                       message={error === PROJECT_ERROR.NO_DATABASE_PASSWORD && this.getErrorMessage(error)}>
              <TextField name='password'
                         disabled={loading}
                         value={database.password || ''}
                         variant='outlined'
                         type={this.state.showDatabasePassword ? 'text' : 'password'}
                         style={fieldStyles}
                         InputProps={{
                           endAdornment:
                             <InputAdornment position='end'>
                               <IconButton onClick={this.toggleShowDatabasePassword}>
                                 <div>
                                   {this.state.showDatabasePassword ? <FontAwesomeIcon icon={faEye} size='xs'/> :<FontAwesomeIcon icon={faEyeSlash} size='xs'/>}
                                 </div>
                               </IconButton>
                             </InputAdornment>
                         }}
                         onChange={this.handleProjectEnvironmentDatabasesUpdate.bind(this, environmentId)} />
            </FormField>
          </Grid>
          <Grid item style={{ marginTop: '12px' }} xs={6}>
            <FormControlLabel
              control={
                <Checkbox color='primary'
                          checked={!(database.allowedIP || database.allowedIP === '')}
                          onClick={this.toggleAllowAllIPs.bind(this, environmentId, database.allowedIP)} />
              }
              label='Allow all IPs'/>
              {
                !(database.allowedIP || database.allowedIP === '') &&
                <Tooltip title={OPEN_GRANTS_MESSAGE} placement='right'>
                  <div style={{display: 'inline'}}>
                    <FontAwesomeIcon icon={faInfoCircle} className='helpText'/>
                  </div>
                </Tooltip>
              }
          </Grid>
          {(database.allowedIP || database.allowedIP === '') &&
          <Grid item xs={6}>
            <FormField label='Allowed IP'>
              <TextField name='allowedIP'
                         disabled={loading}
                         value={database.allowedIP}
                         variant='outlined'
                         style={fieldStyles}
                         onChange={this.handleProjectEnvironmentDatabasesUpdate.bind(this, environmentId)}/>
            </FormField>
          </Grid>
          }
          <DatabaseCardCommandSelect projectName={projectName}
                                     environmentTitle={environmentTitle.toLowerCase()}
                                     databaseName={database.name.toLowerCase()} />
        </Grid>
      </Paper>
    )
  }

  private escapeSpecialCharacters = (str: string): string => {
    return str.replace(/([.*+?!"`~^$#&/@|(){}\\\[\]])/mg, '\\$1');
  }
}

const mapStateToProps: any = (state: IStoreState): any => ({
  project: state.selectedProject.project,
  error: state.selectedProject.error,
  loading: state.selectedProject.loading,
})

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  handleInputChange: (event: any): void => {
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
      payload: {
        key: event.target.name,
        value: event.target.value
      }
    })
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEnvironmentDatabasesCard)

