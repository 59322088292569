import React from 'react'

import {
    FormControl, FormHelperText, FormLabel,
    Grid, MenuItem,
    Select, Typography,
} from "@material-ui/core"
import { DropDownEntry } from "../../models/DropDownEntry";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export interface IDropDownProps {
    dropDownOptions?: DropDownEntry[]
    label?: string
    message?: string
    error?: boolean
    required?: boolean
    id?: string
    value?: DropDownEntry
    disabled?: boolean
    placeholder?: string
    handleChange?: (event: any) => void
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dropDownField: {
            marginTop: '11px'
        },
        helperText: {
            position: 'relative',
            left: '3px',
            top: '-8px'
        }
    }),
)
export const OutlinedDropDown: React.FC<IDropDownProps> = ({
     dropDownOptions,
     label,
     message = '',
     error = false,
     required = false,
     id,
     value= undefined,
     disabled= false,
     placeholder,
     handleChange,
    }) => {
    const styles = useStyles()
    const labelId: string = id + '-label'
    return (
        <Grid container justify='center' direction='column'>
            <Grid item className={styles.dropDownField} xs={12}>
                <FormControl variant='outlined' error={error} fullWidth={true}>
                    <FormLabel id={labelId}><Typography>{label}</Typography></FormLabel>
                    <Select disabled={disabled}
                            value={value?.value}
                            labelId={labelId}
                            id={id}
                            placeholder={placeholder ? placeholder : 'Enter ' + label}
                            onChange={handleChange}>
                        {dropDownOptions.map((option: DropDownEntry) => {
                            return <MenuItem value={option.value}>{option.label}</MenuItem>
                        })}
                    </Select>
                    {message && <FormHelperText className={styles.helperText}>{message}</FormHelperText>}
                </FormControl>
            </Grid>
        </Grid>
    )
}

