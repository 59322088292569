import {IActionConfigurationsState} from '../IStoreState'
import initialState from '../initialState'
import ActionTypes from '../actions'


export default function actionConfigurationsReducer(state: IActionConfigurationsState = initialState.actionConfigurations, action: any): IActionConfigurationsState {
  switch (action.type) {
    case ActionTypes.ACTION_CONFIGURATIONS_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ActionTypes.ACTION_CONFIGURATIONS_LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.actionConfigurations,
        loading: false,
        error: null
      }
    case ActionTypes.ACTION_CONFIGURATIONS_LOAD_FAIL:
      return {
        ...state,
        data: [],
        loading: false,
        error: true
      }
    default:
      return state
  }
}
