import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { InitialDeploymentNotificationConfig } from '../project-details.config'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        deployInfo: {
            color: theme.palette.primary.dark,
        },
        deployButton: {
            backgroundColor: theme.palette.primary.dark,
        },
        deployError: {
            color: theme.palette.error.main,
        },
    }),
)

export function resolveIcon(config: InitialDeploymentNotificationConfig): IconDefinition {
    return config.isError ? faTimesCircle : faInfoCircle
}
