import {IProjectWizardState} from '../IStoreState'
import ActionTypes from '../actions'
import initialState from '../initialState'
import {assign, cloneDeep, get, max} from 'lodash'
import Project, {PROJECT_ERROR, PROJECT_TYPE} from '../../models/Project'

export default function projectWizardReducer(state: IProjectWizardState = initialState.projectWizard, action: any): IProjectWizardState {
  switch (action.type) {
    case ActionTypes.OPEN_PROJECT_WIZARD:
      return openProjectWizard(state, action)
    case ActionTypes.CLOSE_PROJECT_WIZARD:
      return assign(cloneDeep(initialState.projectWizard), {open: false})
    case ActionTypes.UPDATE_PROJECT_WIZARD_FIELD: {
      const updatedState: IProjectWizardState = cloneDeep(state)
      const {key, value} = action.payload
      updatedState.project[key] = value
      updatedState.unsavedChanges = true
      //On change, clear the error and errorMessage
      updatedState.error = ''
      updatedState.errorMessage = ''
      return updatedState
    }
    case ActionTypes.PROJECT_WIZARD_UPDATE_PROPERTY: {
      const updatedState: IProjectWizardState = cloneDeep(state)
      const {key, value} = action.payload
      updatedState[key] = value
      return updatedState
    }
    case ActionTypes.UPDATE_PROJECT_WIZARD_TYPE: {
      const updatedState: IProjectWizardState = cloneDeep(state)
      const type: PROJECT_TYPE = action.payload
      updatedState.project.type = type

      const isWorkflow: boolean = type === PROJECT_TYPE.WORKFLOW
      if (isWorkflow) {
        updatedState.project.domain = undefined
      }
      updatedState.project.hasCode = !isWorkflow
      updatedState.project.hasDatabases = !isWorkflow
      updatedState.project.hasAssets = !isWorkflow
      return updatedState
    }
    case ActionTypes.PROJECT_WIZARD_STEP_CHANGE:
      const {step} = action.payload
      const maxCompletedStep: number = state.completedSteps.length > 0 ? max(state.completedSteps) : -1
      if (step > maxCompletedStep) {
        const completedSteps: number[] = [...state.completedSteps, state.activeStep]
        return assign(cloneDeep(state), {activeStep: step, completedSteps})
      }
      return assign(cloneDeep(state), {activeStep: step})
    case ActionTypes.PROJECT_SAVE:
      return assign(cloneDeep(state), {loading: true})
    case ActionTypes.PROJECT_SAVE_SUCCESS:
      const {project} = action.payload
      return assign(cloneDeep(state), {project, cloneFromProject: undefined, loading: false, error: null, unsavedChanges: false, errorMessage: null})
    case ActionTypes.PROJECT_SAVE_FAIL:
      return handleError(state, action.payload)
    case ActionTypes.UPDATE_PERSISTED_PROJECT:
      const {persistedProject} = action.payload
      // Update import status of project
      const updatedProject: Project = state.project
      if (get(persistedProject, 'codeRepository.importRepository.status') && get(updatedProject, 'codeRepository.importRepository')) {
        updatedProject.codeRepository.importRepository.status = persistedProject.codeRepository.importRepository.status
      }
      return assign(cloneDeep(state), {project: updatedProject, persistedProject})
    case ActionTypes.PROJECT_WIZARD_OPEN_BRANCH_CONFIRMATION:
      const {confirmActionCreator} = action.payload
      return assign(cloneDeep(state), {branchConfirmation: true, confirmActionCreator})
    case ActionTypes.PROJECT_WIZARD_CLOSE_BRANCH_CONFIRMATION:
      return assign(cloneDeep(state), {branchConfirmation: false})
    case ActionTypes.PROJECT_WIZARD_RESET_DEFAULT:
      return initialState.projectWizard
    default:
      return state
  }
}

function openProjectWizard(state: IProjectWizardState, action: any): IProjectWizardState {
  const {project, cloneToNew} = action.payload
  if (!project) {
    return assign(cloneDeep(state), {open: true})
  }

  const updatedState: IProjectWizardState = cloneDeep(state)
  if (cloneToNew) {
    updatedState.cloneFromProject = project
    updatedState.project.type = project.type
  } else {
    updatedState.project = project
  }

  updatedState.open = true
  updatedState.loading = false
  return updatedState
}

function handleError(state: IProjectWizardState, payload: {error: PROJECT_ERROR, errorMessage: string | null}): IProjectWizardState {
  const {error, errorMessage} = payload
  let activeStep: number = state.activeStep
  switch (error) {
    case PROJECT_ERROR.PROJECT_EXISTS:
      activeStep = 0
      break
    case PROJECT_ERROR.PROJECT_DOES_NOT_HAVE_ENOUGH_ENVIRONMENTS:
      activeStep = 1
      break
    case PROJECT_ERROR.INVALID_BRANCH_NAME:
    case PROJECT_ERROR.INVALID_WEB_ROOT:
    case PROJECT_ERROR.DUPLICATE_BRANCH_NAME:
    case PROJECT_ERROR.DUPLICATE_REPOSITORY_PROJECT_NAME:
      activeStep = 2
      break
    case PROJECT_ERROR.DUPLICATE_DATABASE_NAMES:
    case PROJECT_ERROR.DUPLICATE_USER_NAMES:
    case PROJECT_ERROR.NO_DATABASE_USERNAME:
    case PROJECT_ERROR.NO_DATABASE_PASSWORD:
      activeStep = 3
      break
    case PROJECT_ERROR.ASSET_PATH_FOUND_IN_CODE_WEB_ROOT:
    case PROJECT_ERROR.DUPLICATE_ASSET_PATHS:
      activeStep = 4
      break
  }
  return assign(cloneDeep(state), {error, activeStep, loading: false, errorMessage})
}
