import React from 'react'
import Moment from 'react-moment'
import {Grid} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock, faUser} from '@fortawesome/free-solid-svg-icons'
import Nbsp from 'src/components/common/Nbsp'
import Pipeline from 'src/models/pipeline/Pipeline'
import './StatusCard.scss'

export interface ILastUpdatedStatusCardProps {
  pipeline: Pipeline,
  actionType: string,
  actionIcon: any
}

export interface ILastUpdatedStatusCardState {

}

class LastUpdateStatusCard extends React.Component<ILastUpdatedStatusCardProps, ILastUpdatedStatusCardState> {
  render(): React.ReactNode {
    const {pipeline, actionIcon, actionType} = this.props

    return (
      <Grid container className='full-height'>
        <Grid item sm={12}>
          <h5>Last Updated</h5>
        </Grid>
        <Grid item sm={12} className='statusCardBody'>
          <Grid container alignItems='center' justify='center'>
            <Grid item sm={12}>
              <Grid container direction='column' spacing={2}>
                <Grid item className='actionType'>
                  <FontAwesomeIcon icon={actionIcon}/> {actionType.toLowerCase()}
                </Grid>
                <Grid item className='actionDetails'>
                  <FontAwesomeIcon icon={faClock} className='xsIcon' size='xs'/><Nbsp/>
                  <Moment format='M/D/YY h:mma' date={pipeline.startTime}/>
                  <br/>
                  <FontAwesomeIcon icon={faUser} className='xsIcon' size='xs'/> {pipeline.createdBy}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default LastUpdateStatusCard
