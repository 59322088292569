import { createDefaultDatabaseGroup } from 'src/helpers/project/environment/database/env-database-transformation'
import { resolveDefaultDeploymentTargetGroupId } from 'src/helpers/shared'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import ProjectEnvironmentDatabases from 'src/models/ProjectEnvironmentDatabases'

const searchString = 'database'

export function createDefaultProjectEnvironmentDatabases(
  projectName: string,
  deploymentEnvironment: DeploymentEnvironment,
): ProjectEnvironmentDatabases {
  const deploymentTargetGroupId = resolveDefaultDeploymentTargetGroupId(deploymentEnvironment, searchString)
  const database = createDefaultDatabaseGroup(0, projectName, deploymentEnvironment)
  return new ProjectEnvironmentDatabases({
    deploymentTargetGroupId,
    initialized: false,
    databases: [database],
  })
}
