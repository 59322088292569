import React from 'react'
import GenericModal from '../../common/GenericModal'
import EditUser from './EditUser'
import CreateUser from './CreateUser'
import PropTypes from 'prop-types'
import {Grid} from '@material-ui/core'
import User from '../../../models/User'
import './EditUser.scss'

class UserModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: props.user,
      error: false,
    }
  }

  handleModalUserState = (newUser) => {
    this.props.handleNewUser(newUser)
    this.setState({user: newUser})
  }

  render() {

    return (
      <GenericModal className='edit-user-modal' open={this.props.openModal} onClose={this.props.handleModalClose}>
        <Grid container>
          <Grid item sm={12}>
            { this.state.user
              ?
              <EditUser
                user={this.state.user}
                handleModalClose={this.props.handleModalClose}
                handleSavedUser={this.props.handleSavedUser}
                handleDeletedUser={this.props.handleDeletedUser}/>
              :
              <CreateUser
                handleNewUser={this.handleModalUserState}
                handleModalClose={this.props.handleModalClose}
                users={this.props.users}
                />
            }
          </Grid>
        </Grid>
      </GenericModal>
    )
  }
}

UserModal.propTypes = {
  user: PropTypes.instanceOf(User),
  users:PropTypes.array.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  handleNewUser: PropTypes.func.isRequired,
  handleSavedUser: PropTypes.func.isRequired,
  handleDeletedUser: PropTypes.func.isRequired,
}

UserModal.defaultProps = {
  user: null,
  users:null
}

export default UserModal
