import React from 'react'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {find} from 'lodash'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronCircleLeft, faChevronCircleRight, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons'
import Project from '../../../../../models/Project'
import Bundle from '../../../../../models/Bundle'
import AssetGroup from '../../../../../models/AssetGroup'
import ProjectEnvironment from '../../../../../models/ProjectEnvironment'
import DeploymentEnvironment from '../../../../../models/DeploymentEnvironment'
import DeploymentTargetGroup from '../../../../../models/DeploymentTargetGroup'
import ProjectType, {DefaultAssetConfig} from '../../../../../models/ProjectType'
import ProjectEnvironmentAssets from '../../../../../models/ProjectEnvironmentAssets'
import DeploymentEnvironmentHelper from '../../../../../helpers/DeploymentEnvironmentHelper'
import ActionTypes from '../../../../../store/actions'
import IStoreState from '../../../../../store/IStoreState'
import PathUtils from '../../../../../helpers/PathUtils'
import ProjectHelper from '../../../../../helpers/ProjectHelper'
import MessageService from '../../../../../services/MessageService'
import FormField from '../../../../common/FormField'
import ConfirmDialog from '../../../../common/ConfirmDialog'
import ComboBox, {ComboBoxEntry} from '../../../../common/ComboBox'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Paper,
  Typography
} from '@material-ui/core'
import ProjectEnvironmentAssetsCard from './project-environment-assets-card.component'
import {
  createNewDefaultAssetGroupsAndSymlinks,
  deleteAssetGroupAndSymlinkByAssociationId
} from 'src/store/actions/project/update-environments.actions'
import {SectionLayout} from '../../shared'
import '../../../shared/project.scss'
import {ObjectID} from "bson";
import {createDefaultSymlink} from "../../../../../helpers/project/environment/link/env-link-transformation";
import ProjectEnvironmentCodeWebroot from "../../../../../models/ProjectEnvironmentCodeWebroot";
import SymLink from "../../../../../models/SymLink";

export interface IProjectEditAssetsProps {
  handleInputChange?: (event: any) => void
  project?: Project
  deploymentEnvironments?: DeploymentEnvironment[]
  error?: object
  loading?: boolean
  bundle?: Bundle
  projectTypes?: ProjectType[]
  createNewAssetGroupAndSymlink?: () => void
  deleteAssetGroupAndSymlink?: (id: number) => void
}

export interface IProjectEditAssetsState {
  activeAssociationGroup: number
  numberOfPages: number
  listOfAssetOptions: ComboBoxEntry[]
  confirmDelete: boolean
}

export class ProjectEditAssets extends React.Component<IProjectEditAssetsProps, IProjectEditAssetsState> {
  storedProjectEnvironments: ProjectEnvironment[]
  private readonly confirmDeleteMsg: string

  constructor(props: IProjectEditAssetsProps) {
    super(props)
    this.confirmDeleteMsg = MessageService.get('project.wizard.asset.confirm.delete')

    const firstEnvironmentAssets: ProjectEnvironmentAssets = props.project.projectEnvironments[0].projectEnvironmentAssets
    const initialAssetOptions: ComboBoxEntry[] = this.getInitialAssetOptions()
    this.state = {
      activeAssociationGroup: 0,
      numberOfPages: firstEnvironmentAssets ? firstEnvironmentAssets.assetGroups.length : 1,
      listOfAssetOptions: initialAssetOptions,
      confirmDelete: false,
    }
  }

  componentDidMount = (): void => {
    if (this.props.project.hasAssets) {
      this.initializeProjectEnvironmentsAssets()
    }
  }

  getInitialAssetOptions = (): ComboBoxEntry[] => {
    const { project } = this.props
    const listOfAssetOptions: ComboBoxEntry[] = []
    project.projectEnvironments.map((projEnv: ProjectEnvironment) => {
      if (project.hasAssets && !!projEnv.projectEnvironmentAssets) {
        projEnv.projectEnvironmentAssets.assetGroups.sort(
          (a: AssetGroup, b: AssetGroup) => a.associationId - b.associationId,
        )
        this.addOptions(projEnv, projEnv.projectEnvironmentAssets.assetGroups, listOfAssetOptions)
      }
    })

    return listOfAssetOptions
  }

  initializeProjectEnvironmentsAssets = (): void => {
    this.props.handleInputChange({
      target: {
        name: 'projectEnvironments',
        value: this.getInitialProjectEnvironmentsAssets(),
      },
    })
  }

  getInitialProjectEnvironmentsAssets = (): ProjectEnvironment[] => {
    const { projectEnvironments } = this.props.project
    const { listOfAssetOptions } = this.state
    const finishedProjectEnvironments: ProjectEnvironment[] = projectEnvironments.filter((projEnv: ProjectEnvironment) => projEnv.projectEnvironmentAssets)

    if (finishedProjectEnvironments.length === projectEnvironments.length) {
      projectEnvironments.forEach((projEnv: ProjectEnvironment) => {
        projEnv.projectEnvironmentAssets.assetGroups.sort((a: AssetGroup, b: AssetGroup) => a.associationId - b.associationId)
      })
      return projectEnvironments
    }

    return projectEnvironments
      .filter((projEnv: ProjectEnvironment) => !projEnv.projectEnvironmentAssets)
      .map(
        (projEnv: ProjectEnvironment, index: number): ProjectEnvironment => {
          const defaultDeploymentTargetGroup: DeploymentTargetGroup = this.getDefaultDeploymentTargetGroup(projEnv.environmentId)
          const newAssetGroups: AssetGroup[] = this.createAssetGroups(projEnv.environmentId, index)
          newAssetGroups.forEach((assetGroup) => {
            const webrootFull: ProjectEnvironmentCodeWebroot = ProjectHelper.getDefaultWebrootForEnvironment(this.props.project.name, projEnv.environmentId)
            const generatedSymLink = createDefaultSymlink(assetGroup, webrootFull.fullWebroot)
            assetGroup.symLinkId = generatedSymLink.symLinkId
            // SymLinks could be empty if we're adding an asset group for the first time.
            if (!projEnv.symLinks) {
              projEnv.symLinks = []
            }
            projEnv.symLinks.push(generatedSymLink)
          })
          this.addOptions(projEnv, newAssetGroups, listOfAssetOptions)
          this.setState({ listOfAssetOptions })
          return {
            ...projEnv,
            projectEnvironmentAssets: new ProjectEnvironmentAssets({
              assetGroups: [...newAssetGroups],
              deploymentTargetGroupId: defaultDeploymentTargetGroup.id,
            }),
          }
        },
      )
      .concat(finishedProjectEnvironments)
      .sort((a: ProjectEnvironment, b: ProjectEnvironment) => b.order - a.order)
  }

  // TODO: Requires refactor: same issue as getDefaultDeploymentTargetGroup() in project-databases.component.
  getDefaultDeploymentTargetGroup = (environmentId: string): DeploymentTargetGroup => {
    const deploymentEnvironment = DeploymentEnvironmentHelper.getDeploymentEnvironmentById(environmentId)
    return deploymentEnvironment.deploymentTargetGroups.find((group: DeploymentTargetGroup) => group.name.toLowerCase().includes('asset'))
  }

  getDefaultPath = (environmentId: string): string => {
    const { project } = this.props
    let webroot: string = null
    if (project.hasCode) {
      const projectEnvironment: ProjectEnvironment = project.projectEnvironments.find((projEnv: ProjectEnvironment) => projEnv.environmentId === environmentId)
      webroot = PathUtils.getParentDirPath(projectEnvironment?.projectEnvironmentCode?.webroot)
    }
    return ProjectHelper.getDefaultAssetPathForEnvironment(project.name, webroot, environmentId)
  }

  get propertyType(): ProjectType {
    const { project, projectTypes } = this.props
    return find(projectTypes, { value: project.type })
  }

  createAssetGroups = (environmentId: string, index: number): AssetGroup[] => {
    const assetGroups: AssetGroup[] = []
    const projectType: ProjectType = this.propertyType
    let path: string
    const defaultPath: string = this.getDefaultPath(environmentId)

    projectType.defaultAssetConfigurations.forEach((assetConfig: DefaultAssetConfig, assetIndex: number) => {
      path = defaultPath.concat(assetConfig.assetPathSuffix)
      const assetGroup: AssetGroup = this.createAssetGroupWithInitialInfo(
        environmentId,
        index,
        path,
        null,
        assetConfig.title,
      )
      assetGroup.associationId = assetIndex
      assetGroups.push(assetGroup)
    })

    this.setState({ numberOfPages: projectType.defaultAssetConfigurations.length })

    return assetGroups
  }

  createAssetGroupWithInitialInfo = (
    environmentId: string,
    index: number,
    defaultPath: string,
    assetGroupNumber?: number,
    assetTitle?: string,
  ): AssetGroup => {
    const { projectEnvironments } = this.props.project
    const { numberOfPages } = this.state

    const assetGroupTitle: string = this.generateAssetGroupTitle(assetTitle, assetGroupNumber)
    const assetGroupPath: string = this.generateAssetGroupPath(defaultPath, assetGroupNumber)
    const nextAssociationId: number = this.nextAssociationId(projectEnvironments, numberOfPages)

    return new AssetGroup({
      assetGroupId: new ObjectID().toHexString(),
      title: assetGroupTitle,
      path: assetGroupPath,
      associationId: nextAssociationId,
    })
  }

  generateAssetGroupTitle = (assetTitle: string, assetGroupNumber: number) => {
    let assetGroupTitle: string = !!assetTitle ? assetTitle : 'Assets'
    assetGroupTitle = (!!assetGroupNumber && assetGroupNumber > 1) && !assetTitle
      ? `${assetGroupTitle}_${assetGroupNumber}`
      : assetGroupTitle
    return assetGroupTitle
  }

  nextAssociationId = (projectEnvironments: ProjectEnvironment[], numberOfPages: number) => {
    const firstEnvironmentAssetGroup: ProjectEnvironmentAssets = projectEnvironments[0].projectEnvironmentAssets
    return !!firstEnvironmentAssetGroup
      ? firstEnvironmentAssetGroup.assetGroups[numberOfPages - 1].associationId + 1
      : 0
  }

  generateAssetGroupPath = (defaultPath: string, assetGroupNumber: number) => {
    let assetGroupPath: string = defaultPath
    assetGroupPath = (!!assetGroupNumber && assetGroupNumber > 1) ? `${assetGroupPath}_${assetGroupNumber}` : assetGroupPath
    return assetGroupPath
  }

  toggleNoAssets = (): void => {
    if (this.props.project.hasAssets) {
      this.storedProjectEnvironments = this.props.project.projectEnvironments
      this.props.handleInputChange({
        target: {
          name: 'hasAssets',
          value: false,
        },
      })
      this.props.handleInputChange({
        target: {
          name: 'projectEnvironments',
          value: this.props.project.projectEnvironments.map((env: ProjectEnvironment) => {
            const filteredSymlinks: SymLink[] = env.symLinks.filter((symLink: SymLink) => !symLink.assetGroupId)
            return { ...env, projectEnvironmentAssets: null, symLinks: filteredSymlinks }
          }),
        },
      })
    } else {
      this.props.handleInputChange({
        target: {
          name: 'hasAssets',
          value: true,
        },
      })
      if (!!this.storedProjectEnvironments) {
        this.props.handleInputChange({
          target: {
            name: 'projectEnvironments',
            value: this.storedProjectEnvironments,
          },
        })
      } else {
        this.initializeProjectEnvironmentsAssets()
      }
    }
  }

  setAssetGroupNames = (event: any): void => {
    const { activeAssociationGroup } = this.state
    const updatedProjectEnvironments: ProjectEnvironment[] = this.props.project.projectEnvironments
    updatedProjectEnvironments.forEach((projEnv: ProjectEnvironment) => {
      projEnv.projectEnvironmentAssets.assetGroups[activeAssociationGroup].title = event.target.value
    })
    this.props.handleInputChange({
      target: {
        name: 'projectEnvironments',
        value: updatedProjectEnvironments,
      },
    })
  }

  handleCreateNewEnvironmentAssetGroup = (assetGroupName?: string): void => {
    const { numberOfPages, listOfAssetOptions } = this.state
    const updatedProjectEnvironments: ProjectEnvironment[] = this.props.project.projectEnvironments
    this.props.project.hasAssets &&
      updatedProjectEnvironments.forEach((projEnv: ProjectEnvironment, index: number) => {
        const defaultPath: string = this.getDefaultPath(projEnv.environmentId)
        const assetGroup: AssetGroup = this.createAssetGroupWithInitialInfo(
          projEnv.environmentId,
          index,
          defaultPath,
          numberOfPages + 1,
          assetGroupName,
        )

        let defaultSymLinkFrom: string
        if (!projEnv.projectEnvironmentCode || !projEnv.projectEnvironmentCode?.webroot) {
          const webrootFull: ProjectEnvironmentCodeWebroot = ProjectHelper.getDefaultWebrootForEnvironment(this.props.project.name, projEnv.environmentId)
          defaultSymLinkFrom = `/${webrootFull.dir1}/${webrootFull.cascade}/${webrootFull.env}/${webrootFull.site}`
        } else {
          defaultSymLinkFrom = `${projEnv.projectEnvironmentCode?.webroot}`
        }
        const generatedSymLink = createDefaultSymlink(assetGroup, defaultSymLinkFrom)
        assetGroup.symLinkId = generatedSymLink.symLinkId
        projEnv.symLinks.push(generatedSymLink)

        projEnv.projectEnvironmentAssets.assetGroups.push(assetGroup)
        this.addOptions(projEnv, [assetGroup], listOfAssetOptions)
      })

    this.setState({ numberOfPages: numberOfPages + 1, activeAssociationGroup: numberOfPages, listOfAssetOptions })

    this.props.handleInputChange({
      target: {
        name: 'projectEnvironments',
        value: updatedProjectEnvironments,
      },
    })
  }

  handleDeleteEnvironmentAssetGroup = (): void => {
    const { activeAssociationGroup, numberOfPages, listOfAssetOptions } = this.state
    const { projectEnvironments, hasAssets } = this.props.project
    const updatedProjectEnvironments: ProjectEnvironment[] = projectEnvironments
    hasAssets &&
      updatedProjectEnvironments.forEach((projEnv: ProjectEnvironment) => {
        const symLinkId = projEnv.projectEnvironmentAssets.assetGroups[activeAssociationGroup].symLinkId
        const symLinkIndex = projEnv.symLinks.findIndex(symLink => symLink.symLinkId === symLinkId)
        projEnv.symLinks.splice(symLinkIndex, 1)
        projEnv.projectEnvironmentAssets.assetGroups.splice(activeAssociationGroup, 1)

        // Question: Should all the assocaitionId's be reset upon deletion?
        // The impact of this is that a naming convention mis-match arises when creating new asset groups with
        // the next highest association id appended to the end.
        // projEnv.projectEnvironmentAssets.assetGroups.forEach((asset: AssetGroup, index: number) => {
        //   asset.associationId = index
        // })
      })
    hasAssets && listOfAssetOptions.splice(activeAssociationGroup, 1)

    const currentActiveAssocGroup: number = activeAssociationGroup - 1 >= 0 ? activeAssociationGroup - 1 : 0

    this.setState({
      numberOfPages: numberOfPages - 1,
      activeAssociationGroup: currentActiveAssocGroup,
      listOfAssetOptions,
      confirmDelete: false,
    })
    this.props.handleInputChange({
      target: {
        name: 'projectEnvironments',
        value: updatedProjectEnvironments,
      },
    })
  }

  addOptions = (projEnv: ProjectEnvironment, assetsGroups: AssetGroup[], listOfAssetOptions: ComboBoxEntry[]): void => {
    DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projEnv.environmentId).isProd &&
      assetsGroups.forEach((assetsGroup: AssetGroup) => listOfAssetOptions.push(new ComboBoxEntry(assetsGroup.title, assetsGroup.associationId.toString())))
  }

  handleNext = (): void => {
    const { activeAssociationGroup, numberOfPages } = this.state
    const nextActiveStep: number = activeAssociationGroup === numberOfPages - 1 ? 0 : activeAssociationGroup + 1
    this.setState({
      activeAssociationGroup: nextActiveStep,
    })
  }

  handleBack = (): void => {
    const { activeAssociationGroup, numberOfPages } = this.state
    const nextActiveStep: number = activeAssociationGroup === 0 ? numberOfPages - 1 : activeAssociationGroup - 1
    this.setState({
      activeAssociationGroup: nextActiveStep,
    })
  }

  handleDeleteClick = (): void => {
    this.setState({ confirmDelete: true })
  }

  closeConfirmDeleteModal = (): void => {
    this.setState({ confirmDelete: false })
  }

  handleChange = (selectedEntry: ComboBoxEntry): void => {
    const firstEnvironmentAssetGroup: ProjectEnvironmentAssets = this.props.project.projectEnvironments[0]
      .projectEnvironmentAssets
    const selectedAssociationGroup: number = firstEnvironmentAssetGroup.assetGroups.findIndex(
      (assetGroup: AssetGroup) => assetGroup.associationId.toString() === selectedEntry.value,
    )
    this.setState({ activeAssociationGroup: selectedAssociationGroup })
  }

  createNewAssetGroup = () => {
    this.props.createNewAssetGroupAndSymlink()
    this.setState({ numberOfPages: this.state.numberOfPages + 1, activeAssociationGroup: this.state.numberOfPages})
  }

  removeAssetGroup = () => {
    const { activeAssociationGroup } = this.state
    const firstEnvironmentAssetGroup: ProjectEnvironmentAssets = this.props.project.projectEnvironments[0].projectEnvironmentAssets
    const associationId = firstEnvironmentAssetGroup.assetGroups[activeAssociationGroup].associationId
    this.props.deleteAssetGroupAndSymlink(associationId)
    const currentActiveAssocGroup: number = activeAssociationGroup - 1 >= 0 ? activeAssociationGroup - 1 : 0
    this.setState({
      numberOfPages: this.state.numberOfPages - 1,
      activeAssociationGroup: currentActiveAssocGroup,
      confirmDelete: false,
    })
  }

  render(): React.ReactNode {
    const { project, loading } = this.props
    const { activeAssociationGroup, numberOfPages, confirmDelete } = this.state

    // Note: The calculation of the combobox options were changed to this call.
    // The original component relies on the component's this.state.
    // Haven't looked at all the possible impacts of this change.
    const listOfAssetOptions = this.getInitialAssetOptions()
    const disableRemoveButton: boolean = numberOfPages <= 1
    return (
      <SectionLayout>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">{MessageService.get('project.wizard.step5.details')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={!project.hasAssets} onClick={this.toggleNoAssets} />}
                  label="This project does not use assets"
                />
              </Grid>
              {project.hasAssets && project.projectEnvironments[0].projectEnvironmentAssets && (
                <Grid item xs={12}>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify="center">
                        <Grid item xs={11}>
                          <Grid container spacing={2} justify="center" alignItems="center">
                            <Grid item xs={6}>
                              <ComboBox
                                creatable
                                label="Asset Group"
                                suggestions={listOfAssetOptions}
                                overrideValue={listOfAssetOptions[activeAssociationGroup]}
                                handleSelect={this.handleChange}
                                handleCreate={this.createNewAssetGroup}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                  <Grid container justify="space-between" alignContent="center" alignItems="center">
                                    <Grid item>
                                      <IconButton
                                        style={{ marginTop: '4px' }}
                                        title="Create New Asset"
                                        onClick={this.createNewAssetGroup}
                                        color="primary"
                                      >
                                        <FontAwesomeIcon icon={faPlus} size="sm" />
                                      </IconButton>
                                    </Grid>
                                    <Grid item>
                                      <IconButton
                                        style={{ marginTop: '4px' }}
                                        title="Delete Asset"
                                        onClick={this.handleDeleteClick}
                                        disabled={disableRemoveButton}
                                      >
                                        <FontAwesomeIcon icon={faTrash} size="xs" />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item className="right">
                                  <span>
                                    Asset group {activeAssociationGroup + 1} of {numberOfPages}
                                  </span>
                                </Grid>
                                <Grid item>
                                  {numberOfPages > 1 && (
                                    <IconButton onClick={this.handleBack} color="primary">
                                      <FontAwesomeIcon icon={faChevronCircleLeft} />
                                    </IconButton>
                                  )}
                                </Grid>
                                <Grid item>
                                  {numberOfPages > 1 && (
                                    <IconButton onClick={this.handleNext} color="primary">
                                      <FontAwesomeIcon icon={faChevronCircleRight} />
                                    </IconButton>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} justify="space-around" alignItems="center" alignContent="center">

                        <Grid item xs={11}>
                          <Paper elevation={4} className={'MuiPaper-root1'}>
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                              <Grid item xs={12}>
                                <Grid container spacing={4}>
                                  <Grid item xs={12} md={6}>
                                    <FormField label="Name">
                                      <FormLabel style={{ color: 'red' ,marginLeft: '35px'}}>*</FormLabel>
                                      <Input
                                        name="title"
                                        disabled={loading}
                                        value={
                                          project.projectEnvironments[0].projectEnvironmentAssets.assetGroups[
                                            activeAssociationGroup
                                          ].title
                                        }
                                        onChange={this.setAssetGroupNames}
                                      />
                                    </FormField>
                                  </Grid>
                                </Grid>
                                <Grid container direction="column" spacing={4} justify="center">
                                  {project.projectEnvironments
                                    .sort(
                                      (env1: ProjectEnvironment, env2: ProjectEnvironment) => env2.order - env1.order,
                                    )
                                    .map((projEnv: ProjectEnvironment, index: number) => {
                                      return !!projEnv.projectEnvironmentAssets ? (
                                        <Grid item xs={12} key={projEnv.environmentId} className={'project-asset-step'}>
                                          <ProjectEnvironmentAssetsCard
                                            assetGroup={
                                              projEnv.projectEnvironmentAssets.assetGroups[activeAssociationGroup]
                                            }
                                            deploymentTargetGroupId={
                                              projEnv.projectEnvironmentAssets.deploymentTargetGroupId
                                            }
                                            environmentId={projEnv.environmentId}
                                            symLinks={projEnv.symLinks}
                                            className={index < project.projectEnvironments.length - 1 ? 'project-card-step' : ''}
                                          />
                                        </Grid>
                                      ) : null
                                    })}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>

                      </Grid>
                    </Grid>
                    {confirmDelete && (
                      <ConfirmDialog
                        open
                        title={`Delete Asset?`}
                        message={this.confirmDeleteMsg}
                        onConfirm={this.removeAssetGroup}
                        onCancel={this.closeConfirmDeleteModal}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </SectionLayout>
    )
  }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IProjectEditAssetsProps): any => ({
  project: state.selectedProject.project,
  error: state.selectedProject.error,
  loading: state.selectedProject.loading,
  bundle: state.bundle.data,
  projectTypes: state.projectTypes.data,
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IProjectEditAssetsProps): any => ({
  handleInputChange: (event: any): void => {
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
      payload: {
        key: event.target.name,
        value: event.target.value,
      },
    })
  },
  createNewAssetGroupAndSymlink: () => {
    dispatch(createNewDefaultAssetGroupsAndSymlinks())
  },
  deleteAssetGroupAndSymlink: (associationId: number) => {
    dispatch(deleteAssetGroupAndSymlinkByAssociationId(associationId))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditAssets)
