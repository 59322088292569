import Project, { PROJECT_ERROR } from 'src/models/Project'

export interface ICreateProjectDataState {
  project: Project
  error: PROJECT_ERROR| string | undefined
  errorMessage: string | undefined
  loading: boolean
}

export const defaultCreateProjectDataState: ICreateProjectDataState = {
  project: undefined,
  error: undefined,
  errorMessage: undefined,
  loading: false,
}
