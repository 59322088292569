import { ObjectID } from 'bson'
import PathUtils from 'src/helpers/PathUtils'
import {
  appendNumberByAssociationId,
  replaceSpaceWithUnderscore,
  resolveDefaultDeploymentTargetGroupId,
} from 'src/helpers/shared'
import SymLink from 'src/models/SymLink'
import AssetGroup from 'src/models/AssetGroup'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import ProjectEnvironmentAssets from 'src/models/ProjectEnvironmentAssets'

const searchString = 'asset'

export function createDefaultProjectEnvironmentAssets(
  projectName: string,
  deploymentEnvironment: DeploymentEnvironment,
  prodAssetGroups: AssetGroup[],
  prodEnvName: string,
  hasCode: boolean,
  webroot?: string,
): ProjectEnvironmentAssets {
  const deploymentTargetGroupId = resolveDefaultDeploymentTargetGroupId(deploymentEnvironment, searchString)
  const environmentTitle = replaceSpaceWithUnderscore(deploymentEnvironment.title)
  const assetGroups = createDefaultAssetGroups(prodAssetGroups, environmentTitle, projectName, hasCode, webroot)
  return new ProjectEnvironmentAssets({
    deploymentTargetGroupId,
    assetGroups,
  })
}

export function createDefaultAssetGroups(
  assetGroups: AssetGroup[],
  environmentTitle: string,
  projectName: string,
  hasCode: boolean,
  webroot?: string,
): AssetGroup[] {
  return assetGroups.map((group: AssetGroup) => {
    return createDefaultAssetGroup(group, environmentTitle, projectName, hasCode, webroot)
  })
}

export function createDefaultAssetGroup(
  assetGroup: AssetGroup,
  environmentTitle: string,
  projectName: string,
  hasCode: boolean,
  webroot?: string,
): AssetGroup {
  const title = resolveDefaultAssetGroupTitle(assetGroup.associationId)
  const basePath = resolveAssetGroupBasePath(projectName, environmentTitle, hasCode, webroot)
  const path = appendAssetGroupTitle(basePath, title)
  const associationId = assetGroup.associationId
  const id = new ObjectID().toHexString()
  return new AssetGroup({
    assetGroupId: id,
    associationId,
    path,
    title,
  })
}

export function resolveDefaultAssetGroupTitle(associationId: number): string {
  const assetText = 'assets'
  if (associationId > 0) {
    return appendNumberByAssociationId(assetText, associationId)
  }
  return assetText
}

export function resolveAssetGroupBasePath(
  projectName: string,
  environmentTitle: string,
  hasCode: boolean,
  webroot?: string,
): string {
  if (hasCode && webroot) {
    return PathUtils.getParentDirPath(webroot)
  }
  const lowerCaseEnvironmentTitle = environmentTitle.toLowerCase()
  const lowerCaseProjectName = projectName.toLowerCase()
  return createDefaultAssetGroupBasePath(lowerCaseProjectName, lowerCaseEnvironmentTitle)
}

export function appendAssetGroupTitle(basePath: string, tail: string): string {
  return `${basePath}/${tail}`
}

export function createDefaultAssetGroupBasePath(projectName: string, environmentTitle: string): string {
  const prefix = '/var/www/cascade'
  return `${prefix}/${environmentTitle}/${projectName}`
}

export function replaceProductionEnvironmentInPath(
  path: string,
  environmentTitle: string,
  prodEnvName: string,
): string {
  const envName = environmentTitle.toLowerCase()
  return path.replace(prodEnvName, envName)
}

export function appendNewAssetGroupAndSymlinkForEnvironment(
  environmentAssets: ProjectEnvironmentAssets,
  newAssetGroup: AssetGroup,
): ProjectEnvironmentAssets {
  const newAssetGroups = [...environmentAssets.assetGroups, newAssetGroup]
  return new ProjectEnvironmentAssets({
    initialized: environmentAssets.initialized,
    deploymentTargetGroupId: environmentAssets.deploymentTargetGroupId,
    assetGroups: newAssetGroups,
  })
}

export function createDefaultAssetGroupWithNewAssociationId(
  associationId: number,
  environmentTitle: string,
  projectName: string,
  hasCode: boolean,
  webroot?: string,
): AssetGroup {
  const title = resolveDefaultAssetGroupTitle(associationId)
  const basePath = resolveAssetGroupBasePath(projectName, environmentTitle, hasCode, webroot)
  const path = appendAssetGroupTitle(basePath, title)
  const id = new ObjectID().toHexString()
  return new AssetGroup({
    assetGroupId: id,
    associationId,
    path,
    title,
  })
}

export function removeAssetGroupsAndSymlinksFromEnvironments(
  projectEnvironments: ProjectEnvironment[],
  associationId: number,
): ProjectEnvironment[] {
  const environmentsWithoutAssetGroupAndSymlink = projectEnvironments.map((env: ProjectEnvironment) => {
    const assetGroups = env.projectEnvironmentAssets.assetGroups
    const assetGroup = assetGroups.find((asset: AssetGroup) => asset.associationId === associationId)
    if (assetGroup) {
      const filteredAssets = env.projectEnvironmentAssets.assetGroups.filter((asset: AssetGroup) => {
        return asset.associationId !== associationId
      })

      const updatedAssets = new ProjectEnvironmentAssets({
        deploymentTargetGroupId: env.projectEnvironmentAssets.deploymentTargetGroupId,
        initialized: env.projectEnvironmentAssets.initialized,
        assetGroups: filteredAssets,
      })

      const filteredSymlinks = env.symLinks.filter((link: SymLink) => {
        return link.assetGroupId !== assetGroup.assetGroupId
      })

      return new ProjectEnvironment({
        projectEnvironmentAssets: updatedAssets,
        symLinks: filteredSymlinks,
        environmentId: env.environmentId,
        order: env.order,
        projectEnvironmentCode: env.projectEnvironmentCode,
        projectEnvironmentDatabases: env.projectEnvironmentDatabases,
      })
    }
    return env
  })

  return environmentsWithoutAssetGroupAndSymlink
}
