import { cloneDeep } from 'lodash'
import initialState from '../initialState'
import { ICreateProjectFormState } from './create-project-form.state'
import {
  CreateProjectFormActionTypes,
  SetCreateProjectFormProductionEnvironmentIdAction,
  SetCreateProjectFormSelectedEnvironmentsAction,
  SetCreateProjectIsSaveDisabledAction,
  CREATE_PROJECT_FORM_RESET_WORKFLOW,
  CREATE_PROJECT_FORM_SET_PROD_ENV_ID,
  CREATE_PROJECT_FORM_SET_SELECTED_ENVS,
  CREATE_PROJECT_FORM_SET_SELECTED_WORKFLOW,
  CREATE_PROJECT_FORM_SET_IS_SAVE_DISABLED,
  CREATE_PROJECT_FORM_SET_IS_BRANCH_CONFIRM_SHOWN,
} from './create-project-form.types'

export function createProjectFormReducer(
  state: ICreateProjectFormState = initialState.createProjectForm,
  action: CreateProjectFormActionTypes,
): ICreateProjectFormState {
  switch (action.type) {
    case CREATE_PROJECT_FORM_RESET_WORKFLOW: {
      return initialState.createProjectForm
    }
    case CREATE_PROJECT_FORM_SET_IS_SAVE_DISABLED: {
      const { payload } = action as SetCreateProjectIsSaveDisabledAction
      const updatedState = cloneDeep(state)
      updatedState.isSaveDisabled = payload
      return updatedState
    }
    case CREATE_PROJECT_FORM_SET_SELECTED_WORKFLOW: {
      const updatedState = cloneDeep(state)
      updatedState.selectedWorkflowType = action.payload
      return updatedState
    }
    case CREATE_PROJECT_FORM_SET_SELECTED_ENVS: {
      const updatedState = cloneDeep(state)
      const { payload } = action as SetCreateProjectFormSelectedEnvironmentsAction
      updatedState.selectedEnvironments = payload
      return updatedState
    }
    case CREATE_PROJECT_FORM_SET_PROD_ENV_ID: {
      const updatedState = cloneDeep(state)
      const { payload } = action as SetCreateProjectFormProductionEnvironmentIdAction
      updatedState.productionEnvironmentId = payload
      return updatedState
    }
    case CREATE_PROJECT_FORM_SET_IS_BRANCH_CONFIRM_SHOWN: {
      const updatedState = cloneDeep(state)
      const { payload } = action
      updatedState.isBranchConfirmationShown = payload
      return updatedState
    }
    default:
      return state
  }
}
