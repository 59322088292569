import Pipeline from '../models/pipeline/Pipeline'
import Action from '../models/pipeline/Action'
import PipelineService from './PipelineService'
import {PipelineTemplate} from "../models/pipeline/PipelineTemplate";

class HistoryService {
  private static flattenActionsFromPipelines(pipelines: Pipeline[]): Action[] {
    return pipelines.reduce((items: Action[], pipeline: Pipeline) => {
      items.push(...pipeline.actions.map((action: Action) => action))
      return items
    }, [])
  }

  async getHistoricActionsForProject(projectId: string): Promise<Action[]> {
    const pipelines: Pipeline[] = await PipelineService.getProjectPipelines(projectId)
    return HistoryService.flattenActionsFromPipelines(pipelines)
  }

  async getHistoricPipelinesForProject(projectId: string): Promise<Pipeline[]> {
    return  await PipelineService.getProjectPipelines(projectId)
  }

  async getHistoricPipelineTemplatesForProject(projectId: string): Promise<PipelineTemplate[]> {
    return  await PipelineService.getProjectPipelineTemplates(projectId)
  }

}

export default new HistoryService()


