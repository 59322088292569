import React from 'react'

import {Button, ListItemIcon} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faClone, faEdit, faPowerOff} from '@fortawesome/free-solid-svg-icons'
import Menu from '@material-ui/core/Menu/Menu'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Project from '../../models/Project'
import User from '../../models/User'
import IStoreState from '../../store/IStoreState'
import {connect} from 'react-redux'
import {UISref} from '@uirouter/react'
import {appStates} from '../../appStates'
import {MenuSection} from '../project/project-details/menu/section-menu.config'
import {CUSTOM} from '../../theme/lib/color'

export interface IProjectActionsMenuProps {
  project: Project
  handleEditClick: () => void
  handleDisableClick: () => void
  handleEnableClick: () => void
  handleCloneClick: () => void
  currentUser?: User
}

export interface IProjectActionsMenuState {
  openActionsList: boolean
}

export class ProjectActionsMenu extends React.Component<IProjectActionsMenuProps, IProjectActionsMenuState> {
  actionsListAnchor: React.RefObject<any> = React.createRef()

  constructor(props: IProjectActionsMenuProps) {
    super(props)
    this.state = {
      openActionsList: false
    }
  }

  handleActionsClick = (event: any): void => {
    if(event !== undefined){
      event.stopPropagation()
    }
    this.setState({openActionsList: this.state.openActionsList === true? false : true})
  }

  handleActionsClose = (event: any): void => {
    if(event !== undefined){
      event.stopPropagation()
    }
    this.setState({openActionsList: false})
  }

  handleEditClick = (event: any): void => {
    this.handleActionsClose(event)
    this.props.handleEditClick()
  }

  handleDisableClick = (event: any): void => {
    this.handleActionsClose(event)
    this.props.handleDisableClick()
  }

  handleEnableClick = (event: any): void => {
    this.handleActionsClose(event)
    this.props.handleEnableClick()
  }

  handleCloneClick = (event: any): void => {
    this.handleActionsClose(event)
    this.props.handleCloneClick()
  }

  
  render(): React.ReactNode {
    const {project, currentUser} = this.props
    const redirectAppState = appStates.PROJECT + '.' + appStates.PROJECT_DETAILS
    const params = { projectId: project.id, section: MenuSection.PROJECT_DETAILS }
    const menuItems: React.ReactNode[] = []
    if (currentUser.canEditProject(this.props.project.id)) {
      menuItems.push(
        <UISref to={redirectAppState} params={params} className="active">
          <MenuItem key='edit'>
            <ListItemIcon className={'muiListItemIconW'}><FontAwesomeIcon icon={faEdit}/></ListItemIcon>Edit
          </MenuItem>
        </UISref>)
    }
    if (currentUser.canCloneProjects() && !project.disabled) {
      menuItems.push(
        <MenuItem key='clone' onClick={this.handleCloneClick}>
          <ListItemIcon className={'muiListItemIconW'}><FontAwesomeIcon icon={faClone}/></ListItemIcon>Clone
        </MenuItem>
      )
    }
    if (currentUser.canDisableProjects()) {
      if (project.disabled) {
        menuItems.push(
          <MenuItem key='enable' onClick={this.handleEnableClick}>
            <ListItemIcon className={'muiListItemIconW'}><FontAwesomeIcon icon={faPowerOff}/></ListItemIcon>Enable
          </MenuItem>
        )
      } else {
        menuItems.push(
          <MenuItem key='disable' onClick={this.handleDisableClick}>
            <ListItemIcon className={'muiListItemIconW'}><FontAwesomeIcon icon={faPowerOff}/></ListItemIcon>Disable
          </MenuItem>
        )
      }
    }
    if (menuItems.length > 0) {
      return (
          <div className={'project-actionMenu'} onMouseLeave={this.handleActionsClose}>
            <Button ref={this.actionsListAnchor} onClick={this.handleActionsClick} style={{backgroundColor: CUSTOM.gray[200]}}>Actions
              <FontAwesomeIcon icon={faAngleDown} size='sm' style={{marginLeft: '10px', color: 'dodgerblue'}} className={'clickable'}/>
            </Button>
            <div className={'project-actionList'} hidden={!this.state.openActionsList}>
              {menuItems}
            </div>
          </div>
      )
    }
    return null
  }
}

const mapStateToProps: any = (state: IStoreState): any => ({
  currentUser: state.currentUser
})

export default connect(
  mapStateToProps,
)(ProjectActionsMenu)
