import { ObjectID } from 'bson'
import AssetGroup from 'src/models/AssetGroup'
import SymLink from 'src/models/SymLink'

export function createDefaultProjectEnvironmentSymlinks(assetGroups: AssetGroup[], webroot: string): SymLink[] {
  return assetGroups.map((assetGroup: AssetGroup) => {
    return createDefaultSymlink(assetGroup, webroot)
  })
}

export function createDefaultSymlink(assetGroup: AssetGroup, webroot: string): SymLink {
  const { path } = assetGroup
  const symlinkId = new ObjectID().toHexString()
  const splitString = 'assets'
  const pathArray = path.split(splitString)
  const tail = pathArray.length > 1 ? pathArray[1] : ''
  assetGroup.symLinkId = symlinkId
  const from = `${webroot}${tail}`
  return new SymLink({
    symLinkId: symlinkId,
    to: path,
    from,
    assetGroupId: assetGroup.assetGroupId
  })
}
