import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '540px',
      background: 'rgba(244,244,244, 0.4)',
      border: '1px dashed gray',
      borderRadius: theme.spacing(2),
    },
  }),
)

export const SectionPlaceholder: React.FC = () => {
  const styles = useStyles()
  return <div className={styles.container} />
}
