import {Action, Dispatch} from 'redux'
import ActionTypes from './index'
import ProjectType from '../../models/ProjectType'
import ProjectService from '../../services/ProjectService'

export function loadProjectTypes(): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.PROJECT_TYPES_LOAD})
    try {
      const projectTypes: ProjectType[] = await ProjectService.getProjectTypes()
      dispatch({type: ActionTypes.PROJECT_TYPES_LOAD_SUCCESS, payload: {projectTypes}})
    } catch (error) {
      dispatch({type: ActionTypes.PROJECT_TYPES_LOAD_FAIL, payload: {error}})
    }
  }
}
