import React from 'react'

import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'

import SnackbarService, {SNACKBAR_TYPES} from 'src/services/SnackbarService'

import GenericModal from 'src/components/common/GenericModal'
import Database from 'src/models/Database'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import Project from 'src/models/Project'
import Pipeline from 'src/models/pipeline/Pipeline'
import DatabaseSnapshotActionConfiguration from 'src/models/pipeline/DatabaseSnapshotActionConfiguration'
import Action from 'src/models/pipeline/Action'
import PipelineService from 'src/services/PipelineService'

const SNAPSHOT_CONFIRM_MESSAGE: string = 'The snapshot will be placed on your database machine with normal retention policies, usually 10 days. This job will fail if there is insufficient space available.'

interface IDatabaseSnapshotModalState {
  databaseName: string
}

interface IDatabaseSnapshotModalProps {
  environment: ProjectEnvironment,
  project: Project,
  handleModalClose: ()=>void,
  openModal: boolean
}

class DatabaseSnapshotModal extends React.Component<IDatabaseSnapshotModalProps, IDatabaseSnapshotModalState> {
  constructor(props: IDatabaseSnapshotModalProps) {
    super(props)
    this.state = {
      databaseName: props.environment.projectEnvironmentDatabases.databases[0].name,
    }
  }

  handleDatabaseChange = (event: any): void => this.setState({databaseName: event.target.value})

  closeModal = (): void => {
    this.setState({databaseName: null})
    this.props.handleModalClose()
  }

  createDatabaseSnapshot = async (): Promise<void> => {
    const {project, environment} = this.props

    this.closeModal()

    const pipeline: Pipeline = new Pipeline({
      projectId: project.id,
      actions: [
        new Action({
          actionConfiguration: new DatabaseSnapshotActionConfiguration({
            targetEnvironment: environment.environmentId,
            targetDeploymentTargetGroupId: project.getDatabaseTargetGroupIdByEnv(environment.environmentId),
            database: this.state.databaseName
          })
        })
      ]
    })

    try {
      await PipelineService.executePipeline(pipeline)
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Successfully started database snapshot.')
    } catch (error) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, error || 'Failed to start database snapshot!')
    }
  }

  render(): React.ReactNode {
    const { environment } = this.props
    return (
      <GenericModal open={this.props.openModal} onClose={this.closeModal}>
        <h2>Database Snapshot</h2>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4>Database</h4>
            <Select className='select' value={this.state.databaseName} onChange={this.handleDatabaseChange} name='databaseName'>
              {environment &&
                environment.projectEnvironmentDatabases.databases.map((db: Database) => <MenuItem key={db.name} value={db.name}>{db.name}</MenuItem>)
              }
            </Select>
            <FormHelperText>
              {this.state.databaseName || 'Select a database'}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            {SNAPSHOT_CONFIRM_MESSAGE}
          </Grid>
          <Grid item xs={12} className='modal-actions'>
            <Grid container spacing={8} justify='flex-end'>
              <Grid item>
                <Button onClick={this.closeModal} variant='contained' color='secondary' disableElevation>Cancel</Button>
              </Grid>
              <Grid item>
                <Button onClick={this.createDatabaseSnapshot} variant='contained' color='primary' disabled={!this.state.databaseName}>Create Snapshot</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GenericModal>
    )
  }
}

export default DatabaseSnapshotModal
