import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'
import { TextField, FormLabel, Grid, Paper, Typography, Checkbox, FormControlLabel, Divider } from '@material-ui/core'
import ConfirmDialog from 'src/components/common/ConfirmDialog'
import FormField from 'src/components/common/FormField'
import DeploymentEnvironmentHelper from 'src/helpers/DeploymentEnvironmentHelper'
import ProjectHelper from 'src/helpers/ProjectHelper'
import Branch from 'src/models/Branch'
import CodeRepository from 'src/models/CodeRepository'
import DeploymentTargetGroup from 'src/models/DeploymentTargetGroup'
import { IMPORT_REPOSITORY_STATUS } from 'src/models/ImportRepository'
import { PROJECT_ERROR } from 'src/models/Project'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import ProjectEnvironmentCode from 'src/models/ProjectEnvironmentCode'
import ProjectEnvironmentCodeWebroot from 'src/models/ProjectEnvironmentCodeWebroot'
import { WEB_SERVER } from 'src/models/WEB_SERVER'
import MessageService from 'src/services/MessageService'
import IStoreState from 'src/store/IStoreState'
import ImportRepositoryWidget from 'src/components/project/project-create/shared/import-repo.component'
import { ConnectedProjectEnvironmentCodeCard } from '../shared/connected-code-card.component'
import { createWebrootObject } from 'src/helpers/project/environment/code/env-code-transformation'
import {
  createProjectDataSave,
  createProjectDataSetEnvironments,
  createProjectDataSetProjectField,
} from 'src/store/create-project-data/create-project-data.actions'
import {
  createProjectFormSetIsBranchConfirmationShown,
  createProjectFormSetIsSaveDisabled,
} from 'src/store/create-project-form/create-project-form.actions'
import {
  PROJECT_CODE_REPOSITORY_FIELD,
  PROJECT_ENVIRONMENTS_FIELD,
  PROJECT_HAS_CODE_FIELD,
} from '../shared/project-field-names'
import { preInitializedProjectName } from 'src/store/create-project-form/create-project-form.helpers'

import '../../shared/project.scss'

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {}

export type OtherProjectCodeFormProps = PropsFromRedux & ComponentProps
export type OtherProjectCodeFormState = {
  importError: boolean
  disableImportFields: boolean
  isRepoInit: boolean
  webroots: Map<string, ProjectEnvironmentCodeWebroot>
}

export class OtherProjectCodeForm extends React.Component<OtherProjectCodeFormProps, OtherProjectCodeFormState> {
  storedCodeRepository: CodeRepository
  storedProjectEnvironments: ProjectEnvironment[]

  constructor(props: OtherProjectCodeFormProps) {
    super(props)
    this.state = {
      importError: false,
      disableImportFields: false,
      isRepoInit: false,
      webroots: new Map<string, ProjectEnvironmentCodeWebroot>(),
    }
  }

  componentDidMount() {
    this.props.setIsSaveDisabled(false)
    const { project } = this.props
    this.initializeWebroots()
    if (project?.hasCode) {
      if (!project?.codeRepository) {
        this.initializeCodeRepository()
      } else if (project?.codeRepository.importRepository && project?.codeRepository.importRepository.status) {
        if (project?.codeRepository.importRepository.status === IMPORT_REPOSITORY_STATUS.FAILURE) {
          this.setState({ importError: true })
        } else {
          this.setState({ disableImportFields: true })
        }
      } else {
        this.setState({ isRepoInit: true })
      }
      this.initializeProjectEnvironmentsCode()
    }
  }

  initializeWebroots = (): void => {
    const { webroots } = this.state
    const projectEnvironments = this.props.project?.projectEnvironments || []
    projectEnvironments.forEach((projEnv: ProjectEnvironment) => {
      if (projEnv.projectEnvironmentCode && projEnv.projectEnvironmentCode.webroot) {
        const webrootArr: string[] = projEnv.projectEnvironmentCode.webroot.split('/')
        webrootArr.splice(0, 1) // remove first index to account for leading '/'
        let abDirIndex: number = webrootArr.indexOf('ab')
        if (abDirIndex < 0) {
          abDirIndex = webrootArr.indexOf('cascade')
        }
        const dir1: string = webrootArr
          .slice(0, abDirIndex)
          .reduce((acc: string, dir: string) => (acc === '' ? dir : `${acc}/${dir}`), '')
        const dir2: string = webrootArr
          .slice(abDirIndex + 3, webrootArr.length)
          .reduce((acc: string, dir: string) => (acc === '' ? dir : `${acc}/${dir}`), '')
        webroots.set(
          projEnv.environmentId,
          new ProjectEnvironmentCodeWebroot({
            dir1,
            cascade: webrootArr[abDirIndex],
            env: webrootArr[abDirIndex + 1],
            site: webrootArr[abDirIndex + 2],
            dir2,
          }),
        )
        this.setState({ webroots })
      } else {
        webroots.set(
          projEnv.environmentId,
          ProjectHelper.getDefaultWebrootForEnvironment(this.cleanedProjectName(), projEnv.environmentId),
        )
        this.setState({ webroots })
      }
    })
  }

  initializeProjectEnvironmentsCode = (): void => {
    const projectEnvironments = this.props.project?.projectEnvironments || []
    const targetGroupName = 'code'
    const finishedProjectEnvironments: ProjectEnvironment[] = projectEnvironments.filter(
      (projEnv: ProjectEnvironment) => projEnv.projectEnvironmentCode,
    )
    if (finishedProjectEnvironments.length !== projectEnvironments.length) {
      const updatedProjectEnvironments: ProjectEnvironment[] = projectEnvironments
        .filter((projEnv: ProjectEnvironment) => !projEnv.projectEnvironmentCode)
        .map(
          (projEnv: ProjectEnvironment, index: number): ProjectEnvironment => {
            const defaultDeploymentTargetGroup: DeploymentTargetGroup =
              DeploymentEnvironmentHelper.getDeploymentEnvironmentById(
                projEnv.environmentId,
              ).deploymentTargetGroups.find((group: DeploymentTargetGroup) =>
                group.name.toLowerCase().includes(targetGroupName),
              ) ||
              DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projEnv.environmentId).deploymentTargetGroups[0]
            return {
              ...projEnv,
              projectEnvironmentCode: new ProjectEnvironmentCode({
                branch: new Branch({
                  name: 'master',
                  guarded: !DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projEnv.environmentId).isDev,
                }),
                deploymentTargetGroupId: defaultDeploymentTargetGroup.id,
                webroot: this.getFullWebroot(projEnv.environmentId),
                webServer: WEB_SERVER.APACHE_HTTPD,
              }),
            }
          },
        )
        .concat(finishedProjectEnvironments)
        .sort((a: ProjectEnvironment, b: ProjectEnvironment) => a.order - b.order)
      this.updateProjectEnvironments(updatedProjectEnvironments)
    }
  }

  initializeCodeRepository = (): void => {
    this.props.setProjectField(
      PROJECT_CODE_REPOSITORY_FIELD,
      new CodeRepository({
        gitHost: this.props.bundle.gitHost,
        group: this.cleanedProjectName(),
        name: this.cleanedProjectName(),
      }),
    )
  }

  cleanedProjectName = (): string => {
    const projectName = this.props.project?.name
    if (projectName) {
      return ProjectHelper.getCleanedProjectName(this.props.project.name)
    }
    return preInitializedProjectName
  }

  toggleNoCode = () => {
    if (this.props.project?.hasCode) {
      this.storedCodeRepository = this.props.project.codeRepository
      this.storedProjectEnvironments = this.props.project.projectEnvironments.map((env: ProjectEnvironment) => env)
      this.props.setProjectField(PROJECT_HAS_CODE_FIELD, false)
      this.props.setProjectField(
        PROJECT_ENVIRONMENTS_FIELD,
        this.props.project.projectEnvironments.map((env: ProjectEnvironment) => {
          return { ...env, projectEnvironmentCode: null }
        }),
      )
      this.props.setProjectField(PROJECT_CODE_REPOSITORY_FIELD, null)
    } else {
      this.props.setProjectField(PROJECT_HAS_CODE_FIELD, true)
      if (!!this.storedProjectEnvironments) {
        this.props.setProjectField(PROJECT_ENVIRONMENTS_FIELD, this.storedProjectEnvironments)
      } else {
        this.initializeProjectEnvironmentsCode()
      }
      if (!!this.storedCodeRepository) {
        this.props.setProjectField(PROJECT_CODE_REPOSITORY_FIELD, this.storedCodeRepository)
      } else {
        this.initializeCodeRepository()
      }
    }
  }

  handleCodeRepositoryUpdate = (event: any) => {
    const updateCodeRepository: CodeRepository = this.props.project?.codeRepository
    const key = event.target.name
    const value = event.target.value
    updateCodeRepository[key] = ProjectHelper.getCleanedProjectName(value)
    this.props.setProjectField(PROJECT_CODE_REPOSITORY_FIELD, updateCodeRepository)
  }

  getFullWebroot = (id: string): string => {
    return this.state.webroots.get(id).fullWebroot
  }

  updateProjectEnvironments = (projectEnvironments: ProjectEnvironment[]) => {
    this.props.setProjectEnvironments(projectEnvironments)
  }

  getRepositoryErrorMessage = (error: PROJECT_ERROR): string => {
    switch (error) {
      case PROJECT_ERROR.GIT_REPOSITORY_NOT_SAVED:
        return MessageService.get('project.wizard.error.code.repository.notSaved')
      case PROJECT_ERROR.DUPLICATE_REPOSITORY_PROJECT_NAME:
        return MessageService.get('project.wizard.error.code.repository.project.name.duplicate')
      case PROJECT_ERROR.DUPLICATE_REPOSITORY_GROUP:
        return MessageService.get('project.wizard.error.code.repository.group.duplicate')
      default:
        return null
    }
  }

  handleSaveProject = () => {
    this.props.saveProject()
    this.props.closeBranchConfirmation()
  }

  render() {
    const { project, error, loading, isBranchConfirmationShown, closeBranchConfirmation } = this.props
    const { importError, disableImportFields } = this.state
    const projectError = error as PROJECT_ERROR
    const description = MessageService.get('project.wizard.step3.details')
    const featureBranchConfirmationMessage = MessageService.get('project.wizard.code.feature.branch')
    const showImportRepositoryWidget = project ? project.hasCode : false
    const url = project
      ? `${project.codeRepository?.gitHost}/${project.codeRepository?.group}/${project.codeRepository?.name}.git`
      : ''
    const repoName = project?.codeRepository?.name
    const repoGroup = project?.codeRepository?.group
    const projectEnvironments = project ? project.projectEnvironments : []
    const showForm = project?.hasCode && project?.codeRepository
    const isChecked = !(project?.hasCode)
    return (
      <div>
        <ConfirmDialog
          title="Feature Branch Confirmation"
          onConfirm={this.handleSaveProject}
          onCancel={closeBranchConfirmation}
          message={featureBranchConfirmationMessage}
          open={isBranchConfirmationShown}
        />
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <FormLabel component="legend">{description}</FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox color="primary" checked={isChecked} onClick={this.toggleNoCode} />}
              label="This project does not use code"
            />
          </Grid>
          {showForm && (
            <>
              <Grid item>
                <Divider />
              </Grid>
              <Grid item container xs={12} spacing={3}>
                <Grid item xs={12} md={5}>
                  <RepositoryInfoCard
                    gitUrl={url}
                    repoName={repoName}
                    repoGroup={repoGroup}
                    error={projectError}
                    isLoading={loading}
                    handleUpdate={this.handleCodeRepositoryUpdate}
                    showImportRepoWidget={showImportRepositoryWidget}
                    getErrorMessage={this.getRepositoryErrorMessage}
                    importError={importError}
                    disableImportFields={disableImportFields}
                  />
                </Grid>
                <Grid item xs={12} md>
                  <EnvironmentCardsList
                    environments={projectEnvironments}
                    importError={importError}
                    disableImportFields={disableImportFields}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    )
  }
}

interface RepositoryInfoCardProps {
  gitUrl: string
  repoName: string
  repoGroup: string
  error: any
  getErrorMessage: any
  isLoading: boolean
  handleUpdate: any
  showImportRepoWidget: boolean
  disableImportFields: boolean
  importError: boolean
}

const RepositoryInfoCard: React.FC<RepositoryInfoCardProps> = ({
  gitUrl,
  repoName,
  repoGroup,
  isLoading,
  handleUpdate,
  error,
  getErrorMessage,
  showImportRepoWidget,
  disableImportFields,
  importError,
}) => {
  const inputStyle = { backgroundColor: 'white', marginTop: '50px', marginBottom: '10px' }
  return (
    <Paper elevation={2} style={{ padding: '1rem' }}>
      <Grid container direction="column">
        <Grid item xs={12}>
          <h5>Repository</h5>
        </Grid>
        <Grid item xs={12}>
          <FormField label="Group" error={!!getErrorMessage(error)} message={getErrorMessage(error)}>
            <TextField
              name="group"
              disabled={isLoading}
              value={repoGroup}
              onChange={handleUpdate}
              variant="outlined"
              style={inputStyle}
            />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <FormField label="Name" error={!!getErrorMessage(error)} message={getErrorMessage(error)}>
            <TextField
              name="name"
              disabled={isLoading}
              value={repoName}
              onChange={handleUpdate}
              variant="outlined"
              style={inputStyle}
            />
          </FormField>
        </Grid>
        <Grid item container direction="column" spacing={2} xs={12} style={{ marginTop: '1rem' }}>
          <Grid item>
            <FormLabel component="legend">Git URL</FormLabel>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ wordBreak: 'break-word' }}>
              {gitUrl}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {showImportRepoWidget && <ImportRepositoryWidget disabled={disableImportFields} importError={importError} />}
      </Grid>
    </Paper>
  )
}

interface EnvironmentCardsListProps {
  environments: ProjectEnvironment[]
  importError: boolean
  disableImportFields: boolean
}

const EnvironmentCardsList: React.FC<EnvironmentCardsListProps> = ({
  environments,
  importError,
  disableImportFields,
}) => {
  return (
    <Grid container direction="column" spacing={3} justify="center">
      {environments
        .sort((env1: ProjectEnvironment, env2: ProjectEnvironment) => env2.order - env1.order)
        .map((projEnv: ProjectEnvironment) => {
          const webrootObject = createWebrootObject(projEnv)
          const isDev = DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projEnv.environmentId).isDev
          return !!projEnv.projectEnvironmentCode ? (
            <Grid item xs={12} key={projEnv.environmentId}>
              <ConnectedProjectEnvironmentCodeCard
                isDev={isDev}
                webroot={webrootObject}
                projectEnvironment={projEnv}
                importError={importError}
                disableImportFields={disableImportFields}
              />
            </Grid>
          ) : null
        })}
    </Grid>
  )
}

const mapStateToProps = (state: IStoreState, ownProps: ComponentProps) => ({
  project: state.createProjectData.project,
  error: state.createProjectData.error,
  loading: state.createProjectData.loading,
  bundle: state.bundle.data,
  isBranchConfirmationShown: state.createProjectForm.isBranchConfirmationShown,
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ComponentProps) => ({
  handleInputChange: (event: { target: { name: string; value: any } }) => {
    const { name, value } = event.target
    dispatch(dispatch(createProjectDataSetProjectField(name, value)))
  },
  setProjectEnvironments: (environments: ProjectEnvironment[]) => {
    dispatch(createProjectDataSetEnvironments(environments))
  },
  setProjectField: (key: string, value: any) => {
    dispatch(createProjectDataSetProjectField(key, value))
  },
  saveProject: () => {
    dispatch(createProjectDataSave())
  },
  closeBranchConfirmation: () => {
    dispatch(createProjectFormSetIsBranchConfirmationShown(false))
  },
  setIsSaveDisabled: (value: boolean) => {
    dispatch(createProjectFormSetIsSaveDisabled(value))
  },
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedOtherProjectCodeForm = connector(OtherProjectCodeForm)
