/**
 * getEnumKeyByEnumValue - Allows reverse mapping on string valued enum
 *   NOTE: String valued enums do not automatically have a reverse mapping generated by typescript
 * @param enumObject : T (Where T is the enum type)
 * @param enumValue : string - value to search for in enumObject
 *
 * @returns Either the key in enumObject that represents the enumValue or null if it doesn't exist
 */
export function getEnumKeyByEnumValue<T extends {[index:string]:string}>(enumObject: T, enumValue: string): keyof T | null {
  const keys = Object.keys(enumObject).filter(key => enumObject[key] == enumValue)
  return keys.length > 0 ? keys[0] : null
}
