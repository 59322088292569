import React from 'react'
import { Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { PROJECT_TYPE } from 'src/models/Project'
import { TypeSelection } from '../forms'
import { Drupal8CreateWorkflow } from '../drupal8/create-drupal8-workflow.component'
import { FormLayout } from './form-layout.component'
import IStoreState from 'src/store/IStoreState'
import { startNewWorkflow } from 'src/store/create-project-form/create-project-form.actions'
import { Drupal7CreateWorkflow } from '../drupal7/create-drupal7-workflow.component'
import { WordpressCreateWorkflow } from '../wordpress/create-wordpress-workflow.component'
import { OtherCreateWorkflow } from '../other/create-other-workflow.component'
import { WorkflowCreateWorkflow } from '../workflow/create-workflow-workflow.component'
import {Drupal9CreateWorkflow} from '../drupal9/create-drupal9-workflow.component';

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {}
export type ProjectCreateSectionProps = PropsFromRedux & ComponentProps

export const ProjectCreateSection: React.FC<ProjectCreateSectionProps> = ({ selectedWorkflowType, startNewWorkflow }) => {
  const renderForm = (type: PROJECT_TYPE) => {
    switch (type) {
      case PROJECT_TYPE.DRUPAL_9:
        return <Drupal9CreateWorkflow />
      case PROJECT_TYPE.DRUPAL_8:
        return <Drupal8CreateWorkflow />
      case PROJECT_TYPE.DRUPAL_7:
        return <Drupal7CreateWorkflow />
      case PROJECT_TYPE.WORDPRESS:
        return <WordpressCreateWorkflow />
      case PROJECT_TYPE.OTHER:
        return <OtherCreateWorkflow />
      case PROJECT_TYPE.WORKFLOW:
        return <WorkflowCreateWorkflow />
      default:
        return <TypeSelection selectWorkflow={startNewWorkflow} />
    }
  }

  return <FormLayout>{renderForm(selectedWorkflowType)}</FormLayout>
}

const mapStateToProps = (state: IStoreState, ownProps: ComponentProps) => ({
  selectedWorkflowType: state.createProjectForm.selectedWorkflowType,
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ComponentProps) => ({
  startNewWorkflow: (type: PROJECT_TYPE) => {
    dispatch(startNewWorkflow(type))
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedProjectCreateSection = connector(ProjectCreateSection)

