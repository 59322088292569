import React from 'react'
import {Card, CardActions, CardContent, CardHeader, Grid} from '@material-ui/core'
import CopyDatabaseButton from './CopyDatabaseButton'
import DatabaseSnapshotButton from './DatabaseSnapshotButton'
import Project from 'src/models/Project'
import STATUS from 'src/models/pipeline/Status'
import ProjectEvent from 'src/models/pipeline/ProjectEvent'
import CancelButton from '../CancelButton'
import {ACTION_TYPE} from 'src/models/pipeline/Action'
import StatusCard from '../status/StatusCard'
import '../DeploymentTrack.scss'

export interface IEnvironmentDatabasesSectionProps {
  latestEvent?: ProjectEvent,
  handleCopyDatabaseClick: () => void,
  handleDatabaseSnapshotClick: () => void,
  environmentId: string,
  project: Project,
  onCancelPipeline: (pipelineId: string, actionType: ACTION_TYPE) => void
}

export interface IEnvironmentDatabasesSectionState {

}

class EnvironmentDatabasesSection extends React.Component<IEnvironmentDatabasesSectionProps, IEnvironmentDatabasesSectionState> {
  onCancelPipeline = (): void => {
    const {latestEvent, onCancelPipeline} = this.props

    onCancelPipeline(latestEvent.pipeline.id, latestEvent.action.actionConfiguration.actionType)
  }

  render(): React.ReactNode {
    const {project, environmentId, latestEvent, handleCopyDatabaseClick, handleDatabaseSnapshotClick} = this.props
    const {CANCELLING, PENDING, STARTED} = STATUS

    const pipelineActive: boolean = !!latestEvent && [PENDING, STARTED].includes(latestEvent.pipeline.status)
    const pipelineCancelling: boolean = !!latestEvent && latestEvent.pipeline.status === CANCELLING

    const showDBbuttons: boolean = !pipelineActive && !pipelineCancelling
    const showCancel: boolean = pipelineActive

    const initializationRequired: boolean = !project.environmentInitializedForDatabases(environmentId)
    const height = '180px'
    return (
      <Card className='full-height deployment-sub-section' elevation={2}>
        <CardHeader className='deploymentTrackSectionTitle' title='Databases'/>
        <CardContent className='deploymentTrackSectionContent' style={{ minHeight: height, maxHeight: height, height }}>
          {latestEvent && <StatusCard projectEvent={latestEvent}/>}
          {!latestEvent && 
          <Grid container justify='center' alignItems='center'>
            There are no recent actions
          </Grid>}
        </CardContent>
        <CardActions>
          <Grid container spacing={2} justify='flex-end' style={{ padding: '8px' }}>
            {showDBbuttons &&
            <Grid item>
              <CopyDatabaseButton project={project} environmentId={environmentId} handleCopyDatabaseClick={handleCopyDatabaseClick}/>
            </Grid>
            }
            {showDBbuttons &&
            <Grid item>
              <DatabaseSnapshotButton project={project} environmentId={environmentId}
                                      handleDatabaseSnapshotClick={handleDatabaseSnapshotClick}
                                      initializationRequired={initializationRequired}/>
            </Grid>
            }
            {showCancel &&
            <Grid item>
              <CancelButton handleCancelClick={this.onCancelPipeline} environmentId={environmentId} project={project}
                            latestEvent={latestEvent}/>
            </Grid>
            }
          </Grid>
        </CardActions>
      </Card>
    )
  }
}

export default EnvironmentDatabasesSection
