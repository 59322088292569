import { ObjectID } from 'bson'
import AssetGroup from 'src/models/AssetGroup'
import SymLink from 'src/models/SymLink'

export function createDefaultProjectEnvironmentSymlinks(assetGroups: AssetGroup[], webroot: string): SymLink[] {
  return assetGroups.map((assetGroup: AssetGroup) => {
    return createDefaultSymlink(assetGroup, webroot)
  })
}

export function createDefaultSymlink(assetGroup: AssetGroup, webroot: string): SymLink {
  const { path, title } = assetGroup

  const symlinkId = new ObjectID().toHexString()
  assetGroup.symLinkId = symlinkId
  const lowerCasedTitle = title.toLowerCase()
  const from = `${webroot}/${lowerCasedTitle}`
  return new SymLink({
    symLinkId: symlinkId,
    to: path,
    from,
    assetGroupId: assetGroup.assetGroupId
  })
}
