import React from 'react'
import Button from '@material-ui/core/Button'
import logo from '../../images/logo.png'
import keycloakService from '../../services/KeycloakService'
import { Grid } from '@material-ui/core'
import { SUPPORT_URL } from '../../config/links.config'
import './LoginPage.scss'

class LoginPage extends React.Component {
  onLoginClick(): void {
    keycloakService.login()
  }

  render(): React.ReactNode {
    return (
      <Grid container className='login-page' direction='column' alignItems='center' justify='center' wrap='nowrap'>
        <Grid item>
          <img src={logo} className='logo'/>
        </Grid>
        <Grid item className='login-btn'>
          <Button variant='contained' color='primary' onClick={this.onLoginClick} size='large'>Login</Button>
        </Grid>
        <Grid item>
          <Button variant='contained' color='primary' href={SUPPORT_URL} target='_blank' disableElevation>Contact Support</Button>
        </Grid>
      </Grid>
    )
  }
}

export default LoginPage
