import React from 'react'
import {
  TableCell,
  TableRow, Typography
} from '@material-ui/core'


export const TaskRow = (logs: string[]) => {

  return (
    <TableRow>
      <TableCell style={{background: 'black'}} colSpan={8}>
        <div style={{overflow: 'auto', padding: '10px'}}>
          {logs.map((log, i) => (
            <span key={i}>
              <Typography style={{
                whiteSpace: 'pre-line',
                color: 'whitesmoke',
                fontWeight: 700,
                fontSize: '14px',
                overflowWrap: 'normal',
                overflow: 'auto'
              }}>{log}</Typography>
              <br/>
              <br/>
            </span>
          ))}
        </div>

      </TableCell>
    </TableRow>
  )

}
