import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './production-placeholder-item.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

export interface ProductionPlaceholderItemProps {}

export const ProductionPlaceholderItem: React.FC<ProductionPlaceholderItemProps> = () => {
  const styles = useStyles()
  return (
    <div className={styles.container}>
      <Grid container alignContent="center" spacing={2}>
        <Grid item>
          <FontAwesomeIcon icon={faLock} size="lg" />
        </Grid>
        <Grid item>
          <Typography variant="body1" className={styles.titleText}>
            Production
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}
