import {Action, Dispatch} from 'redux'
import ActionTypes from './index'
import PipelineService from '../../services/PipelineService'
import {ActionConfiguration} from '../../models/pipeline/ActionConfiguration'


export class ActionConfigurationActions {
  static loadActionConfigurations(projectId: string): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.ACTION_CONFIGURATIONS_LOAD})
      try {
        const actionConfigurations: ActionConfiguration[] = await PipelineService.getProjectActionConfigurations(projectId)
        dispatch({type: ActionTypes.ACTION_CONFIGURATIONS_LOAD_SUCCESS, payload: {actionConfigurations}})
      } catch (error) {
        dispatch({type: ActionTypes.ACTION_CONFIGURATIONS_LOAD_FAIL, payload: {error}})
      }
    }
  }
}
