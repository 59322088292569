import React from 'react'
import { get } from 'lodash'
import { Dispatch } from 'redux'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCodeBranch, faExclamationCircle, faLock } from '@fortawesome/free-solid-svg-icons'
import { FormLabel, Grid, InputAdornment, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import CodeRepository from '../../../../../models/CodeRepository'
import Project, { PROJECT_ERROR } from '../../../../../models/Project'
import ProjectEnvironment from '../../../../../models/ProjectEnvironment'
import DeploymentTargetGroup from '../../../../../models/DeploymentTargetGroup'
import ProjectEnvironmentCodeWebroot from '../../../../../models/ProjectEnvironmentCodeWebroot'
import ActionTypes from '../../../../../store/actions'
import IStoreState from '../../../../../store/IStoreState'
import MessageService from '../../../../../services/MessageService'
import {onWebRootChange} from "../../../../../store/actions/selectedProjectActions"
import DeploymentEnvironmentHelper from '../../../../../helpers/DeploymentEnvironmentHelper'
import FormField from '../../../../common/FormField'

const PROTECTED_BRANCH_MESSAGE: string = 'This branch will be protected to prevent pushes and deletes outside of the workflow, except by project owners.'
const VERIFY_ASSETS_PATH_MESSAGE: string = 'Please verify the asset paths are updated in the "Assets" step.'

export interface IProjectEnvironmentCodeCardProps {
  handleInputChange?: (event: any) => void,
  project?: Project,
  error?: PROJECT_ERROR,
  loading?: boolean,
  className?:string,
  projectEnvironment: ProjectEnvironment,
  webroots: Map<string, ProjectEnvironmentCodeWebroot>,
  isDev: boolean,
  getFullWebroot: (id: string) => string
  disableImportFields: boolean
  importError: boolean
  onWebrootChanged?: (envId: string, oldWebroot: string, newWebroot: string) => void
}

export class ProjectEnvironmentCodeCard extends React.Component<IProjectEnvironmentCodeCardProps> {

  handleProjectEnvironmentCodeUpdate = (id: string, event: any): void => {
    const { project } = this.props
    const updatedProjectEnvironments: ProjectEnvironment[] = this.props.project.projectEnvironments
    const projectEnvironmentToUpdate: ProjectEnvironment = updatedProjectEnvironments.find((projEnv: ProjectEnvironment) => projEnv.environmentId === id)
    if (event.target.name === 'branch') {
      if (get(project, 'codeRepository.importRepository')) {
        this.updateImportBranchMapping(projectEnvironmentToUpdate.projectEnvironmentCode.branch.name, event.target.value)
      }
      projectEnvironmentToUpdate.projectEnvironmentCode.branch.name = event.target.value
    } else {
      projectEnvironmentToUpdate.projectEnvironmentCode[event.target.name] = event.target.value
    }
    this.updateProjectEnvironments(updatedProjectEnvironments)
  }

  updateImportBranchMapping = (oldAbBranchName: string, newAbBranchName: string): void => {
    const { project } = this.props
    const updatedCodeRepository: CodeRepository = project.codeRepository
    updatedCodeRepository.importRepository.abBranchToImportBranch
      .set(newAbBranchName, project.codeRepository.importRepository.abBranchToImportBranch.get(oldAbBranchName))
    const environmentsUsingOldBranchName: ProjectEnvironment[] = this.props.project.projectEnvironments
      .filter((projEnv: ProjectEnvironment) => get(projEnv, 'projectEnvironmentCode.branch.name') === oldAbBranchName)
    if (!environmentsUsingOldBranchName || environmentsUsingOldBranchName.length === 1) {
      // since project environments haven't been updated yet, the env being updated still uses the oldBranchName (thus the length === 1)
      updatedCodeRepository.importRepository.abBranchToImportBranch.delete(oldAbBranchName)
    }
    this.updateCodeRepository(updatedCodeRepository)
  }

  getErrorMessage = (error: PROJECT_ERROR): string => {
    switch (error) {
      case PROJECT_ERROR.INVALID_BRANCH_NAME:
        return MessageService.get('project.wizard.error.code.branch.invalid')
      case PROJECT_ERROR.DUPLICATE_BRANCH_NAME:
        return MessageService.get('project.wizard.error.code.branch.duplicate')
      case PROJECT_ERROR.INVALID_WEB_ROOT:
        return MessageService.get('project.wizard.error.code.webroot.invalid')
      case PROJECT_ERROR.INVALID_CONSECUTIVE_BRANCH:
        return MessageService.get('project.wizard.error.code.branch.invalid.consecutive')
      default:
        return null
    }
  }

  handleWebrootUpdate = (id: string, event: any): void => {
    const { projectEnvironment, webroots, getFullWebroot, onWebrootChanged } = this.props
    const oldWebroot: string = getFullWebroot(id)
    webroots.get(id)[event.target.name] = event.target.value
    const newWebroot: string = this.props.getFullWebroot(id)
    this.handleProjectEnvironmentCodeUpdate(id, { target: { name: 'webroot', value: newWebroot } })
    onWebrootChanged(projectEnvironment.environmentId, oldWebroot, newWebroot)
  }

  handleAbBranchToImportBranchUpdate = (event: any): void => {
    const updatedCodeRepository: CodeRepository = this.props.project.codeRepository
    updatedCodeRepository.importRepository.abBranchToImportBranch.set(event.target.name, event.target.value)
    this.updateCodeRepository(updatedCodeRepository)
    const projectEnvironments: any = this.props.project.projectEnvironments
    this.updateProjectEnvironments(projectEnvironments)
  }

  updateCodeRepository = (codeRepository: CodeRepository): void => {
    this.props.handleInputChange({
      target: {
        name: 'codeRepository',
        value: codeRepository
      }
    })
  }

  updateProjectEnvironments = (projectEnvironments: ProjectEnvironment[]): void => {
    this.props.handleInputChange({
      target: {
        name: 'projectEnvironments',
        value: projectEnvironments
      }
    })
  }

  render(): React.ReactNode {
    const { loading, projectEnvironment, webroots, project, error, disableImportFields, importError, className } = this.props
    const importRepository = project?.codeRepository?.importRepository
    const showImport: boolean = importRepository && !disableImportFields
    return (
      <Paper className={'project-environment-card MuiPaper-root1 project-card ' + className}>
        <Grid container spacing={0} justify='space-around' alignItems='center'>
          <Grid item xs={12}>
            <strong>{DeploymentEnvironmentHelper.getEnvironmentTitleById(projectEnvironment.environmentId)}</strong>
          </Grid>
          {showImport &&
            <Grid item xs={12} md={5} className={classNames('import-repository-branch-field', { 'import-error': importError })}>
              <FormField label='Import Branch/Tag'>
                <TextField
                  name={projectEnvironment.projectEnvironmentCode.branch.name}
                  disabled={loading}
                  value={importRepository.abBranchToImportBranch.get(projectEnvironment.projectEnvironmentCode.branch.name) || ''}
                  onChange={this.handleAbBranchToImportBranchUpdate} />
              </FormField>
            </Grid>
          }
          {showImport &&
            <Grid item>
              <FontAwesomeIcon icon={faArrowRight} />
            </Grid>
          }
          <Grid item xs={12} md={5} style={{ marginLeft: '-18px' }}>
            <FormField label='Branch Name' mandatory={true} error={error === PROJECT_ERROR.INVALID_BRANCH_NAME} message={error === PROJECT_ERROR.INVALID_BRANCH_NAME && this.getErrorMessage(error)}>
              <TextField
                name='branch'
                disabled={loading}
                value={projectEnvironment.projectEnvironmentCode.branch.name}
                variant='outlined'
                style={{ marginTop: '50px', marginBottom: '10px', backgroundColor: 'white' }}
                onChange={this.handleProjectEnvironmentCodeUpdate.bind(this, projectEnvironment.environmentId)}
                InputProps={{
                  endAdornment:
                    projectEnvironment.projectEnvironmentCode.branch.guarded &&
                    <InputAdornment position='end' className='adornment'>
                      <Tooltip title={PROTECTED_BRANCH_MESSAGE} placement='top' enterDelay={50}><FontAwesomeIcon icon={faLock} size='sm' /></Tooltip>
                    </InputAdornment>,
                  startAdornment: <InputAdornment position='start' className='adornment'><FontAwesomeIcon icon={faCodeBranch} /></InputAdornment>
                }}
              />

            </FormField>
          </Grid>
          <Grid container item xs={12} md={showImport ? 12 : 6}>
            <Grid item style={{ marginTop: '15px' }} xs={12}>
              <FormLabel>Deployment Group</FormLabel>
              <FormLabel style={{ color: 'red' ,marginLeft: '3px'}}>*</FormLabel>
            </Grid>
            <Grid item style={{ marginTop: '6px' }} xs={12}>
              <Select
                variant='outlined'
                disabled={loading}
                className={'Select-dd'}
                value={projectEnvironment.projectEnvironmentCode.deploymentTargetGroupId}
                onChange={this.handleProjectEnvironmentCodeUpdate.bind(this, projectEnvironment.environmentId)} inputProps={{ name: 'deploymentTargetGroupId' }}>
                {DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projectEnvironment.environmentId).deploymentTargetGroups.map((group: DeploymentTargetGroup) => {
                  return <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                })}
              </Select>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
              <FormLabel component='legend'>Webroot<FormLabel style={{ color: 'red' ,marginLeft: '3px'}}>*</FormLabel></FormLabel>

            </Grid>
            <Typography
              style={{ marginLeft: '4px', marginRight: '4px', marginTop: '10px' }}
              variant='h5'>
              / </Typography>
            <TextField
              name='dir1'
              disabled={loading}
              variant='standard'
              style={{ marginLeft: '4px', marginTop: '7px', marginBottom: '20px', width: '70px', backgroundColor: '#FFFFFF' }}
              value={webroots.get(projectEnvironment.environmentId).dir1}
              onChange={this.handleWebrootUpdate.bind(this, projectEnvironment.environmentId)} />
            <Typography
              style={{ marginLeft: '4px', marginRight: '4px', marginTop: '10px' }}
              variant='h5'>
              / </Typography>
            <Typography
              style={{ marginTop: '7px' }}
              variant='h6'>
              {webroots.get(projectEnvironment.environmentId).cascade}/{webroots.get(projectEnvironment.environmentId).env}
            </Typography>
            <Typography
              style={{ marginLeft: '4px', marginRight: '4px', marginTop: '10px' }}
              variant='h5'>
              / </Typography>
            <TextField
              variant='standard'
              style={{ marginTop: '7px', marginBottom: '20px', width: '70px', backgroundColor: '#FFFFFF' }}
              name='site'
              disabled={loading}
              value={webroots.get(projectEnvironment.environmentId).site}
              onChange={this.handleWebrootUpdate.bind(this, projectEnvironment.environmentId)} />
            <Typography
              style={{ marginLeft: '4px', marginRight: '4px', marginTop: '10px' }}
              variant='h5'>
              /</Typography>
            <TextField
              variant='standard'
              style={{ marginTop: '7px', marginBottom: '20px', width: '70px', backgroundColor: '#FFFFFF' }}
              name='dir2'
              disabled={loading}
              value={webroots.get(projectEnvironment.environmentId).dir2}
              onChange={this.handleWebrootUpdate.bind(this, projectEnvironment.environmentId)} />
            {error === PROJECT_ERROR.INVALID_WEB_ROOT && <FormField error={true} message={this.getErrorMessage(error)}><span /></FormField>}
          </Grid>
          {project.hasAssets &&
            <Grid item xs={12} className='asset-path-warning'>
              <FontAwesomeIcon icon={faExclamationCircle} /> {VERIFY_ASSETS_PATH_MESSAGE}
            </Grid>
          }
        </Grid>
      </Paper >
    )
  }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IProjectEnvironmentCodeCardProps): any => ({
  project: state.selectedProject.project,
  error: state.selectedProject.error,
  loading: state.selectedProject.loading,
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IProjectEnvironmentCodeCardProps): any => ({
  handleInputChange: (event: any): void => {
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
      payload: {
        key: event.target.name,
        value: event.target.value
      }
    })
  },
  onWebrootChanged: (envId: string, oldWebroot: string, newWebroot: string): void => dispatch(onWebRootChange(envId, oldWebroot, newWebroot)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEnvironmentCodeCard)

