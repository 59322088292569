import {ACTION_TYPE} from './Action'
import 'reflect-metadata'
import {assign} from 'lodash'
import {ActionConfiguration} from './ActionConfiguration'

class DatabaseCopyActionConfiguration extends ActionConfiguration {
  sourceDatabase: string
  targetDatabase: string

  constructor(props?: Partial<DatabaseCopyActionConfiguration>) {
    super()
    this.actionType = ACTION_TYPE.DATABASE_COPY

    props = props || {}

    const defaultProps: Partial<DatabaseCopyActionConfiguration> = {
      sourceDatabase: undefined,
      targetDatabase: undefined
    }
    assign(this, defaultProps, props)
  }

  isValid(): boolean {
    return false
  }
}

export default DatabaseCopyActionConfiguration
