import React from 'react'
import { Grid } from '@material-ui/core'
import { Dispatch } from 'redux'
import { DropResult } from 'react-beautiful-dnd'
import { connect, ConnectedProps } from 'react-redux'
import { removeSelectedEnvironment, reorderSelectedEnvironment } from 'src/store/create-project-form/create-project-form.actions'
import IStoreState from 'src/store/IStoreState'
import { DraggableEnvironmentsList, ProductionPlaceholderItem } from '.'
import { EnvironmentSelectionConfig } from '../drupal8/drupal8-environments.config'
import { useStyles } from './environments-selected-list.config'

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {}

export type SelectedEnvironmentsListProps = PropsFromRedux & ComponentProps

const SelectedEnvironmentsList: React.FC<SelectedEnvironmentsListProps> = ({ selectedEnvironments, move, remove }) => {
  const styles = useStyles()
  const updateSelectedListSync = (result: DropResult) => {
    move(result)
  }
  return (
    <div className={styles.container}>
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <DraggableEnvironmentsList
            updateOrder={updateSelectedListSync}
            remove={remove}
            droppableId="selectedEnvsColId"
            environments={selectedEnvironments}
          />
        </Grid>
      </Grid>
      <ProductionPlaceholderItem />
    </div>
  )
}

const mapStateToProps = (state: IStoreState, ownProps: ComponentProps) => ({
  selectedEnvironments: state.createProjectForm.selectedEnvironments,
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ComponentProps) => ({
  remove: (config: EnvironmentSelectionConfig) => {
    dispatch(removeSelectedEnvironment(config))
  },
  move: (result: DropResult) => {
    dispatch(reorderSelectedEnvironment(result))
  },
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedSelectedEnvironmentsList = connector(SelectedEnvironmentsList)


