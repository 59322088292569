import React from "react";
import {FormControl, Grid, IconButton, MenuItem, Select, TextField} from "@material-ui/core";
import {CopyTextClipboard} from "../../shared";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard} from "@fortawesome/free-solid-svg-icons";

interface IDatabaseCardCommandSelectProps {
  projectName: string,
  environmentTitle: string,
  databaseName: string
}

interface IDatabaseCardCommandSelectState {
  selectedValue:string,
  activeDatabaseName:string
}

export class DatabaseCardCommandSelect extends React.Component<IDatabaseCardCommandSelectProps, IDatabaseCardCommandSelectState> {

  constructor(props: IDatabaseCardCommandSelectProps) {
    super(props)
    this.state = {
      selectedValue: undefined,
      activeDatabaseName: this.props.databaseName
    }
  }

  render(): React.ReactNode {
    const {projectName, environmentTitle, databaseName} = this.props
    const {activeDatabaseName, selectedValue} = this.state
    const dbListCommand = `cascade list -t db -p ${projectName} -e ${environmentTitle} -g ${databaseName || ''}`
    const dbPushCommand = `cascade push -t db -p ${projectName} -e ${environmentTitle} -g ${databaseName || ''} -f (your file to upload) -i`
    const dbPullCommand = `cascade pull -t db -p ${projectName} -e ${environmentTitle} -d ${databaseName || ''}`

    let defaultSelect: string = dbListCommand
    if (!!selectedValue) {
      if (activeDatabaseName !== databaseName) {
        this.setState({
          activeDatabaseName: databaseName,
          selectedValue: defaultSelect
        })
      } else {
        defaultSelect = selectedValue
      }
    }

    return(
      <Grid item xs={12}>
        <FormControl variant='outlined'>
          <Select onChange={(e) => this.setState({selectedValue: e.target.value.toString()})}
                  value={defaultSelect}
                  className={'MuiSelect-database'}
                  inputProps={{ name: 'deploymentTargetGroupId' }}>
            <MenuItem value={dbListCommand}>List</MenuItem>
            <MenuItem value={dbPushCommand}>Push</MenuItem>
            <MenuItem value={dbPullCommand}>Pull</MenuItem>
          </Select>
        </FormControl>
        <TextField disabled={true}
                   value={defaultSelect}
                   onClick={() => CopyTextClipboard.copyToClipBoard(defaultSelect, "database")}
                   variant='outlined'
                   className={'MuiForm-Control'}
                   InputProps={{
                     endAdornment:
                       <IconButton className={'MuiIcon'}>
                         <div><FontAwesomeIcon icon={faClipboard} size='sm'/></div>
                       </IconButton>
                   }} />
      </Grid>
    )
  }

}
