import Project, { PROJECT_TYPE } from 'src/models/Project'
import {
  fullEditMenuEntries,
  MenuEntryConfig,
  MenuSection,
  viewOnlyEntries,
  workflowMenuEntries,
} from './menu/section-menu.config'
import { getEnumKeyByEnumValue } from '../../../helpers/Utilities'

export const defaultUnsavedChangesMessage =
  'This project has unsaved changes. Changes will be lost if you leave the page.'
export const defaultProjectSaveErrorMessage = 'An error occurred when attempting to save the project.'
export const initialDeploymentMessage = 'Your project has been created using the default settings, but has not been deployed yet. ' +
    'Edit advanced settings within each deployment group below or deploy your project.'
export const initialDeploymentErrorMessage = "Something went wrong attempting to perform the initial deployment of your project."
export const defaultEnvironmentNonActiveMessage = 'At least one environment does not have deployment targets. Please contact your Cascade Administrator.'

export interface SaveButtonConfig {
  showButton: boolean
  isDisabled: boolean
  tooltip: string
  onClick: () => void
}

export interface InitialDeploymentButtonConfig {
  message: string
  showButton: boolean
  isDisabled: boolean
  onClick: () => void
}

export interface InitialDeploymentNotificationConfig {
  message: string
  isShown: boolean
  isError: boolean
}

export interface ViewSourceCodeConfig {
  isShown: boolean
  url: string
  onClick: () => void
}

export interface NotificationConfig {
  message: string
  isShown: boolean
  isError: boolean
}

export function resolveMenuEntryConfigs(type: PROJECT_TYPE, hasEditPermission: boolean): MenuEntryConfig[] {
  if (hasEditPermission) {
    switch (type) {
      case PROJECT_TYPE.WORKFLOW:
        return workflowMenuEntries
      case PROJECT_TYPE.DRUPAL_7:
        return fullEditMenuEntries
      case PROJECT_TYPE.DRUPAL_8:
        return fullEditMenuEntries
      case PROJECT_TYPE.DRUPAL_9:
        return fullEditMenuEntries
      case PROJECT_TYPE.WORDPRESS:
        return fullEditMenuEntries
      case PROJECT_TYPE.OTHER:
        return fullEditMenuEntries
      default:
        return viewOnlyEntries
    }
  }
  return viewOnlyEntries
}

export function resolveProjectName(name: string | undefined): string {
  const defaultName = '...'
  return name ? name : defaultName
}

export function resolveShowSaveButton(activeSection: MenuSection): boolean {
  const saveSections = [
    MenuSection.PROJECT_DETAILS,
    MenuSection.ASSETS,
    MenuSection.CODE,
    MenuSection.ENVIRONMENT,
    MenuSection.LINKS,
    MenuSection.DATABASES,
  ]
  return saveSections.includes(activeSection)
}

export function resolveShowInitialDeployButton(activeSection: MenuSection): boolean {
  const initialDeploySections = [
    MenuSection.PROJECT_DETAILS,
    MenuSection.ASSETS,
    MenuSection.CODE,
    MenuSection.ENVIRONMENT,
    MenuSection.LINKS,
    MenuSection.DATABASES,
    MenuSection.OVERVIEW,
  ]
  return initialDeploySections.includes(activeSection)
}

export function resolveShowViewSourceCodeLink(project: Project): boolean {
  const projectHasSourceCodeurl = project?.codeRepository?.repoUrl && project?.codeRepository?.repoUrl.length > 0
  return project?.hasCode && project?.type !== PROJECT_TYPE.WORKFLOW && projectHasSourceCodeurl
}

export function resolveIsMenuCollapsed(activeSection: MenuSection): boolean {
  const closeSections = [MenuSection.OVERVIEW, MenuSection.PIPELINES]
  return closeSections.includes(activeSection)
}

export function resolveIsSaveDisabled(isLoading: boolean, id: string | undefined, hasNonactiveEnv: boolean = false): boolean {
  return isLoading || id == undefined || hasNonactiveEnv
}

export function resolveSaveButtonConfig(
  activeSection: MenuSection,
  selectedProject: Project,
  isLoading: boolean,
  hasNonactiveEnv: boolean,
  callback: () => void,
): SaveButtonConfig {
  const result = {
    showButton: resolveShowSaveButton(activeSection),
    isDisabled: resolveIsSaveDisabled(isLoading, selectedProject.id, hasNonactiveEnv),
    tooltip: hasNonactiveEnv ? defaultEnvironmentNonActiveMessage : '',
    onClick: callback,
  }
  return result
}

export function resolveIsInitialDeployDisabled(isLoading: boolean, id: string | undefined): boolean {
  return isLoading || id == undefined
}

export function resolveInitialDeployButtonConfig(
  activeSection: MenuSection,
  selectedProject: Project,
  projectEnvironment: String,
  isLoading: boolean,
  callback: () => void,
) : InitialDeploymentButtonConfig {
  const result = {
    //temp
    message: "Deploy " + projectEnvironment,
    showButton: resolveShowInitialDeployButton(activeSection),
    isDisabled: resolveIsInitialDeployDisabled(isLoading, selectedProject.id),
    onClick: callback,
  }
  return result
}

export function resolveViewSourceCodeConfig(project: Project, callback: () => void): ViewSourceCodeConfig {
  return {
    isShown: resolveShowViewSourceCodeLink(project),
    url: project?.codeRepository?.repoUrl,
    onClick: callback,
  }
}

export function isValidSection(entries: MenuEntryConfig[], section: string): boolean {
  const sections = entries.map((e) => e.section as string)
  return sections.includes(section)
}

export function resolveLandingSection(entries: MenuEntryConfig[], section: string): MenuSection {
  if (Array.isArray(entries) && entries.length > 0) {
    const sections = entries.map((e) => e.section)
    const targetSection = resolveMenuSection(section)
    const landingSection = targetSection && sections.includes(targetSection) ? targetSection : sections[0]
    return landingSection
  }
  return MenuSection.OVERVIEW
}

export function resolveMenuSection(section: string): MenuSection | null {
  const targetSectionKey = getEnumKeyByEnumValue(MenuSection, section)
  const result = MenuSection[targetSectionKey]
  return result ? result : null
}

export function resolveErrorMessage(errorMessage: string | null): string {
  return errorMessage ? errorMessage : defaultProjectSaveErrorMessage
}

export function resolveNotificationMessage(error: string | null, errorMessage: string | null): string {
  const isError = resolveIsError(error, errorMessage)
  if (isError) return resolveErrorMessage(errorMessage)
  return defaultUnsavedChangesMessage
}

export function resolveIsError(error: string | null, errorMessage: string | null): boolean {
  const isError = error || errorMessage ? true : false
  return isError
}

export function resolveNotificationConfig(
  hasChanges: boolean,
  error: string | null,
  errorMessage: string | null,
): NotificationConfig {
  const isError = resolveIsError(error, errorMessage)
  const message = resolveNotificationMessage(error, errorMessage)
  return {
    isShown: hasChanges,
    message,
    isError,
  }

}
export function resolveDeploymentErrorMessage(errorMessage: string | null): string {
  return errorMessage ? errorMessage : initialDeploymentErrorMessage
}

export function resolveInitialDeploymentNotificationMessage(error: string | null, errorMessage: string | null): string {
  const isError = resolveIsError(error, errorMessage)
  if (isError) return resolveDeploymentErrorMessage(errorMessage)
  return initialDeploymentMessage
}

export function resolveInitialDeploymentNotificationConfig(isDeployed: boolean, error: string | null, errorMessage: string | null): InitialDeploymentNotificationConfig {
  const isError = resolveIsError(error, errorMessage)
  const message = resolveInitialDeploymentNotificationMessage(error, errorMessage)
  return {
    isShown: !isDeployed,
    message,
    isError,
  }
}
