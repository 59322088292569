import {ACTION_TYPE} from './Action'
import 'reflect-metadata'
import {assign} from 'lodash'
import {ActionConfiguration} from './ActionConfiguration'


class DatabaseImportActionConfiguration extends ActionConfiguration {
  targetDatabase: string
  databaseToImportPath: string

  constructor(props?: Partial<DatabaseImportActionConfiguration>) {
    super()
    this.actionType = ACTION_TYPE.DATABASE_IMPORT

    props = props || {}

    const defaultProps: Partial<DatabaseImportActionConfiguration> = {
      targetDatabase: undefined,
      databaseToImportPath: undefined
    }
    assign(this, defaultProps, props)
  }

  isValid(): boolean {
    return false
  }
}

export default DatabaseImportActionConfiguration
