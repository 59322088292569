import initialState from '../initialState'
import ActionTypes from '../actions'
import {IDeploymentEnvironmentsState} from '../IStoreState'

export default function deploymentEnvironmentsReducer(state: IDeploymentEnvironmentsState = initialState.deploymentEnvironments, action: any): IDeploymentEnvironmentsState {
  switch (action.type) {
    case ActionTypes.DEPLOYMENT_ENVIRONMENTS_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ActionTypes.DEPLOYMENT_ENVIRONMENTS_LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.deploymentEnvironments,
        loading: false,
        error: null
      }
    case ActionTypes.DEPLOYMENT_ENVIRONMENTS_LOAD_FAIL:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}
