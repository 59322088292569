import axios, {AxiosResponse} from 'axios'
import {plainToClass} from 'class-transformer'
import DeploymentTargetGroup from '../models/DeploymentTargetGroup'
import {map} from 'lodash'

class DeploymentTargetGroupService {
  static DEPLOYMENT_TARGET_GROUP_API_ROOT:string = '/api/deploymentenvironment/v2/deploymentenvironments'

  async getDeploymentTargetGroup(environmentId: string, groupId: string): Promise<DeploymentTargetGroup> {
    const url: string = `${DeploymentTargetGroupService.DEPLOYMENT_TARGET_GROUP_API_ROOT}/${environmentId}/deploymenttargetgroups/${groupId}`
    const res: AxiosResponse<DeploymentTargetGroup> = await axios.get(url)
    return plainToClass(DeploymentTargetGroup, res.data)
  }

  async createDeploymentTargetGroup(environmentId: string, group: DeploymentTargetGroup): Promise<DeploymentTargetGroup> {
    const groupDTO: any = {
      name: group.name,
      deploymentTargetIds: map(group.deploymentTargets, 'id'),
      primaryDeploymentTargetId: group.primaryDeploymentTargetId,
      type: group.type
    }
    const url: string = `${DeploymentTargetGroupService.DEPLOYMENT_TARGET_GROUP_API_ROOT}/${environmentId}/deploymenttargetgroups`
    const res: AxiosResponse<DeploymentTargetGroup> = await axios.post(url, groupDTO)
    return plainToClass(DeploymentTargetGroup, res.data)
  }

  async updateDeploymentTargetGroup(environmentId: string, group: DeploymentTargetGroup): Promise<DeploymentTargetGroup> {
    const groupDTO: any = {
      id: group.id,
      name: group.name,
      deploymentTargetIds: map(group.deploymentTargets, 'id'),
      primaryDeploymentTargetId: group.primaryDeploymentTargetId,
      type: group.type
    }
    const url: string = `${DeploymentTargetGroupService.DEPLOYMENT_TARGET_GROUP_API_ROOT}/${environmentId}/deploymenttargetgroups/${group.id}`
    const res: AxiosResponse<DeploymentTargetGroup> = await axios.put(url, groupDTO)
    return plainToClass(DeploymentTargetGroup, res.data)
  }

  deleteDeploymentTargetGroup = (envId: string, targetGroupId:string ): Promise<any> => {
    return axios.delete(`${DeploymentTargetGroupService.DEPLOYMENT_TARGET_GROUP_API_ROOT}/${envId}/deploymenttargetgroups/${targetGroupId}`)
  }
}

export default new DeploymentTargetGroupService()
