const HISTORY_ITEM_TYPES: object = {
  CODE_MERGE: 'Code Merge',
  CODE_PROMOTE: 'Code Promotion',
  CODE_DEPLOY: 'Code Deploy',
  ASSET_MERGE: 'Asset Merge',
  DATABASE_COPY: 'Database Copy',
  DATABASE_SNAPSHOT: 'Database Snapshot',
  DATABASE_IMPORT: 'Database Import',
  FILE_TEMPLATE: 'File Template',
  SCRIPT: 'Script',
  SERVICE: 'Service Action'
}

export {HISTORY_ITEM_TYPES}
