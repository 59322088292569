enum STATUS {
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  CANCELLING = 'CANCELLING',
  CANCELLED = 'CANCELLED'
}

export default STATUS
