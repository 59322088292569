import {Tooltip} from '@material-ui/core'
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faCircleNotch, faExclamationCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import './LatestPipelineIcon.scss'
import STATUS from 'src/models/pipeline/Status'
import MessageService from 'src/services/MessageService'
import Pipeline from 'src/models/pipeline/Pipeline'

export interface ILatestPipelineIconProps {
  latestPipeline: Pipeline,
}

export class LatestPipelineIcon extends React.Component<ILatestPipelineIconProps> {
  render(): React.ReactNode {
    const {latestPipeline} = this.props

    if (!latestPipeline) {
      return null
    }

    let icon: any
    let tooltip: string
    let className: string
    let spin: boolean = false

    switch (latestPipeline.status) {
      case STATUS.SUCCESS:
        icon = faCheck
        tooltip = MessageService.get('pipeline.status.icon.success')
        className = 'success'
        break
      case STATUS.FAILURE:
        icon = faExclamationCircle
        tooltip = MessageService.get('pipeline.status.icon.failure')
        className = 'failure'
        break
      case STATUS.PENDING:
      case STATUS.STARTED:
        icon = faCircleNotch
        tooltip = MessageService.get('pipeline.status.icon.running')
        className = 'running'
        spin = true
        break
      case STATUS.CANCELLED:
        icon = faTimesCircle
        tooltip = MessageService.get('pipeline.status.icon.canceled')
        className = 'canceled'
        break
      case STATUS.CANCELLING:
        icon = faCircleNotch
        tooltip = MessageService.get('pipeline.status.icon.canceling')
        className = 'canceling'
        spin = true
        break
      default:
        return null
    }

    return (
      <Tooltip placement='top' title={tooltip} enterDelay={50}>
        <FontAwesomeIcon icon={icon} className={className} spin={spin}/>
      </Tooltip>
    )
  }
}

export default LatestPipelineIcon
