import React from 'react'
import {Button, Tooltip} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCode, faLevelDownAlt} from '@fortawesome/free-solid-svg-icons'
import Project from 'src/models/Project'
import {ACTION_TYPE} from 'src/models/pipeline/Action'
import {PermissionHelper} from 'src/helpers/PermissionHelper'
import NoPermissionButton from '../NoPermissionButton'
import '../DeploymentTrack.scss'
import DeploymentEnvironmentHelper from "../../../../../helpers/DeploymentEnvironmentHelper";
import ProjectHelper from "../../../../../helpers/ProjectHelper";

export interface IMergeCodeButtonProps {
  isTagBased: boolean
  handlePromoteClick: () => void
  handleMergeClick: () => void
  environmentId: string
  project: Project
}

class MergeCodeButton extends React.Component<IMergeCodeButtonProps> {
  get buttonText(): string {
    return this.props.isTagBased ? 'Promote' : 'Merge'
  }

  get buttonIcon(): any {
    return this.props.isTagBased ? faLevelDownAlt : faCode
  }

  handleClick = (): void => {
    const {isTagBased, handlePromoteClick, handleMergeClick} = this.props
    isTagBased ? handlePromoteClick() : handleMergeClick()
  }

  render(): React.ReactNode {
    const {isTagBased, project, environmentId} = this.props

    if (PermissionHelper.hasPermissionForAction(isTagBased ? ACTION_TYPE.CODE_PROMOTE : ACTION_TYPE.CODE_MERGE, project, environmentId)) {
      const envIsDisabled = !ProjectHelper.isProjectEnvironmentCodeActionValid(project, environmentId)
      return (
        <Tooltip title={envIsDisabled ? DeploymentEnvironmentHelper.getInactiveEnvironmentTooltipTitle(environmentId) : ''}>
          <span>
            <Button onClick={this.handleClick}
                    variant='contained'
                    color='primary'
                    disabled={envIsDisabled}>
              <FontAwesomeIcon icon={this.buttonIcon} className='buttonIcon'/> {this.buttonText}
            </Button>
          </span>
        </Tooltip>
      )
    }

    return <NoPermissionButton icon={this.buttonIcon} text={this.buttonText}/>
  }
}

export default MergeCodeButton
