import React from 'react'
import {Grid} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleNotch, faUser} from '@fortawesome/free-solid-svg-icons'
import Pipeline from 'src/models/pipeline/Pipeline'
import './StatusCard.scss'

export interface IPendingStatusCardProps {
  pipeline: Pipeline,
  actionType: string,
  actionIcon: any
}

export interface IPendingStatusCardState {

}

class PendingStatusCard extends React.Component<IPendingStatusCardProps, IPendingStatusCardState> {
  render(): React.ReactNode {
    const {actionIcon, actionType, pipeline} = this.props

    return (
      <Grid container className='full-height'>
        <Grid item sm={12}>
          <h5>In Progress</h5>
        </Grid>
        <Grid item sm={12} className='statusCardBody pending'>
          <Grid container justify='center' alignItems='stretch'>
            <Grid item sm={8}>
              <Grid container direction='column' justify="space-between">
                <Grid item className='actionType'>
                  <FontAwesomeIcon icon={actionIcon}/> {actionType.toLowerCase()}
                </Grid>
                <Grid item className='actionDetails'>
                  <br/>
                  <FontAwesomeIcon icon={faUser} className='xsIcon' size='xs'/> {pipeline.createdBy}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container wrap="nowrap" direction='column' alignItems='flex-end' justify='space-between' sm={4}>
              <Grid item sm={12}>
                <FontAwesomeIcon icon={faCircleNotch} size='2x' spin/>
              </Grid>
              <Grid item className='timer'>Pending...</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default PendingStatusCard
