import React from 'react'

import {TableRow, TableCell} from '@material-ui/core'
import DeploymentTarget from '../../../models/DeploymentTarget'
import DeploymentTargetActionsMenu from './DeploymentTargetActionsMenu'
import './DeploymentTargetsTableRow.scss'

export interface IDeploymentTargetsTableRowProps {
  item: DeploymentTarget,
  handleClick: any,
}

export class DeploymentTargetsTableRow extends React.Component<IDeploymentTargetsTableRowProps> {
  handleRowClick = (): void => {
    this.props.handleClick(this.props.item)
  }

  render(): React.ReactNode {
    const deploymentTarget:DeploymentTarget = this.props.item
    return (
      <TableRow>
        <TableCell padding='default'>{deploymentTarget.name}</TableCell>
        <TableCell padding='default'>{deploymentTarget.fqdn}</TableCell>
        <TableCell padding='default'>{deploymentTarget.remoteUser}@{deploymentTarget.ipAddress}:{deploymentTarget.port}</TableCell>
        <TableCell padding='default'>{deploymentTarget.escalationUser} ({deploymentTarget.escalationMethod})</TableCell>
        <TableCell padding='default'>{deploymentTarget.status.toString().replace(/_/g, ' ')}</TableCell>
        <TableCell className={'deploy-Row'} >
          <DeploymentTargetActionsMenu deploymentTarget={this.props.item} handleEditClick={this.handleRowClick}/>
        </TableCell>
      </TableRow>
    );

  }
}

export default DeploymentTargetsTableRow

