import {ACTION_TYPE} from './Action'
import 'reflect-metadata'
import {assign} from 'lodash'
import {ActionConfiguration} from './ActionConfiguration'

class AssetMergeActionConfiguration extends ActionConfiguration {
  sourceAssetPath: string
  targetAssetPath: string

  constructor(props?: Partial<AssetMergeActionConfiguration>) {
    super()
    this.actionType = ACTION_TYPE.ASSET_MERGE
    props = props || {}

    const defaultProps: Partial<AssetMergeActionConfiguration> = {
      sourceAssetPath: undefined,
      targetAssetPath: undefined
    }
    assign(this, defaultProps, props)
  }

  isValid(): boolean {
    return false
  }
}

export default AssetMergeActionConfiguration
