import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      fontWeight: 'bold',
    },
    nextButton: {
      textTransform: 'none',
    },
  }),
)

export interface EnvironmentSelectionConfig {
  id: string
  title: string
  isDev: boolean
  isProd: boolean
  order: number
  status: string
}

export function transformToEnvironmentSelectionConfig(env: DeploymentEnvironment): EnvironmentSelectionConfig {
  return {
    id: env.id,
    title: env.title,
    isProd: env.isProd,
    isDev: env.isDev,
    order: undefined,
    status: env.status
  }
}

export function resolveUnselectedEnvironmentConfigs(
  deploymentEnvironments: DeploymentEnvironment[],
  selectedEnvironmentConfigs: EnvironmentSelectionConfig[],
): EnvironmentSelectionConfig[] {
  const unselectedEnvironments = deploymentEnvironments.filter(
    (env) => !env.isProd && !selectedEnvironmentConfigs.find((config) => config.id === env.id),
  )
  return unselectedEnvironments.map((env) => transformToEnvironmentSelectionConfig(env))
}
