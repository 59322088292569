import DeploymentTarget, {
  DEPLOYMENT_TARGET_ERROR_CODE,
  DEPLOYMENT_TARGET_STATUS,
  ESCALATION_METHOD
} from '../../../models/DeploymentTarget'
import messageService from '../../../services/MessageService'

class DeploymentTargetWizardErrorHelper {
  static getErrorForCode(code: DEPLOYMENT_TARGET_ERROR_CODE | string): string {
    return DeploymentTargetWizardErrorHelper.getMessage(code.toString()) || code
  }

  static getErrorForTarget(deploymentTarget: DeploymentTarget): Map<string, string> {
    const errorFields: Map<string, string> = new Map<string, string>()
    const args: object = {
      ipAddress: deploymentTarget.ipAddress,
      port: deploymentTarget.port,
      abHost: window.location.host,
      remoteUser: deploymentTarget.remoteUser,
      escalationUser: deploymentTarget.escalationUser
    }
    switch (deploymentTarget.status) {
      case DEPLOYMENT_TARGET_STATUS.FAILED_IP_PORT_VERIFICATION:
        const ipPortMsg: string = DeploymentTargetWizardErrorHelper.getMessage(
          DEPLOYMENT_TARGET_STATUS.FAILED_IP_PORT_VERIFICATION.toString(), args)
        errorFields.set('fqdn', ipPortMsg)
        errorFields.set('ipAddress', ipPortMsg)
        errorFields.set('port', ipPortMsg)
        break
      case DEPLOYMENT_TARGET_STATUS.FAILED_IP_UNIQUE_CONSTRAINT:
        const ipUniqueConstraintMsg: string = DeploymentTargetWizardErrorHelper.getMessage(
          DEPLOYMENT_TARGET_STATUS.FAILED_IP_UNIQUE_CONSTRAINT.toString(), args)
        errorFields.set('ipAddress', ipUniqueConstraintMsg)
        break
      case DEPLOYMENT_TARGET_STATUS.FAILED_PASSWORD_LOGIN:
        errorFields.set('remotePassword', DeploymentTargetWizardErrorHelper.getMessage(
          DEPLOYMENT_TARGET_STATUS.FAILED_PASSWORD_LOGIN.toString(), args))
        break
      case DEPLOYMENT_TARGET_STATUS.FAILED_KEY_LOGIN:
        errorFields.set('privateKey', DeploymentTargetWizardErrorHelper.getMessage(
          DEPLOYMENT_TARGET_STATUS.FAILED_KEY_LOGIN.toString(), args))
        break
      case DEPLOYMENT_TARGET_STATUS.FAILED_ESCALATION_LOGIN:
        const escalationMethod: ESCALATION_METHOD = deploymentTarget.escalationMethod
        switch (escalationMethod) {
          case ESCALATION_METHOD.SUDO:
            errorFields.set('escalationUser',
              DeploymentTargetWizardErrorHelper.getMessage(
                `${DEPLOYMENT_TARGET_STATUS.FAILED_ESCALATION_LOGIN.toString()}_SUDO`, args))
            break
          case ESCALATION_METHOD.SU:
            const escalationMsg: string = DeploymentTargetWizardErrorHelper.getMessage(
              `${DEPLOYMENT_TARGET_STATUS.FAILED_ESCALATION_LOGIN.toString()}_SU`, args)
            errorFields.set('escalationUser', escalationMsg)
            errorFields.set('escalationPassword', escalationMsg)
            break
        }
        break
      case DEPLOYMENT_TARGET_STATUS.FAILED_INSTALL:
        errorFields.set('install', DeploymentTargetWizardErrorHelper.getMessage(
          DEPLOYMENT_TARGET_STATUS.FAILED_INSTALL.toString(), args))
        break
    }

    return errorFields
  }

  private static errorMessages: Map<string, string> = new Map([
    [DEPLOYMENT_TARGET_STATUS.FAILED_IP_PORT_VERIFICATION.toString(), 'admin.deploymenttarget.wizard.error.port'],
    [DEPLOYMENT_TARGET_STATUS.FAILED_IP_UNIQUE_CONSTRAINT.toString(), 'admin.deploymenttarget.wizard.error.ip.unique.constraint'],
    [DEPLOYMENT_TARGET_STATUS.FAILED_PASSWORD_LOGIN.toString(), 'admin.deploymenttarget.wizard.error.usernameLogin'],
    [DEPLOYMENT_TARGET_STATUS.FAILED_KEY_LOGIN.toString(), 'admin.deploymenttarget.wizard.error.privateKeyLogin'],
    [`${DEPLOYMENT_TARGET_STATUS.FAILED_ESCALATION_LOGIN.toString()}_SUDO`, 'admin.deploymenttarget.wizard.error.escalationLogin.sudo'],
    [`${DEPLOYMENT_TARGET_STATUS.FAILED_ESCALATION_LOGIN.toString()}_SU`, 'admin.deploymenttarget.wizard.error.escalationLogin.su'],
    [DEPLOYMENT_TARGET_STATUS.FAILED_INSTALL.toString(), 'admin.deploymenttarget.wizard.error.installation'],
    [DEPLOYMENT_TARGET_ERROR_CODE.INVALID_DEPLOYMENT_TARGET.toString(), 'admin.deploymenttarget.wizard.error.target.invalid'],
    [DEPLOYMENT_TARGET_ERROR_CODE.DEPLOYMENT_TARGET_EXISTS.toString(), 'admin.deploymenttarget.wizard.error.target.duplicate'],
    [DEPLOYMENT_TARGET_ERROR_CODE.DEPLOYMENT_TARGET_DOES_NOT_EXIST.toString(), 'admin.deploymenttarget.wizard.error.target.nonexistant'],
    [DEPLOYMENT_TARGET_ERROR_CODE.DUPLICATE_DEPLOYMENT_TARGET_NAME.toString(), 'admin.deploymenttarget.wizard.error.target.name.duplicate'],
  ])

  private static getMessage(key: string, args?: object): string {
    const messageKey: string = DeploymentTargetWizardErrorHelper.errorMessages.get(key)
    return messageKey ? messageService.get(messageKey, args) : messageKey
  }
}

export default DeploymentTargetWizardErrorHelper
