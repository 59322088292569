import { Button, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { PROJECT_TYPE } from 'src/models/Project'

export interface TypeSelectionProps {
  selectWorkflow: (type: PROJECT_TYPE) => void
}

/**
 * This Component is a placeholder for project type selection
 */
export const TypeSelection: React.FC<TypeSelectionProps> = ({ selectWorkflow }) => {
  return (
    <Grid container direction="column" spacing={3} style={{height: '400px'}}>
      <Grid item>
        <Typography variant="subtitle1">Select a Project Type:</Typography>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            onClick={() => selectWorkflow(PROJECT_TYPE.DRUPAL_7)}
          >
            Drupal 7
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            onClick={() => selectWorkflow(PROJECT_TYPE.DRUPAL_8)}
          >
            Drupal 8
          </Button>
        </Grid>
        <Grid item>
          <Button
              variant="outlined"
              color="primary"
              disableElevation
              onClick={() => selectWorkflow(PROJECT_TYPE.DRUPAL_9)}
          >
            Drupal 9
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            onClick={() => selectWorkflow(PROJECT_TYPE.WORDPRESS)}
          >
            Wordpress
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            onClick={() => selectWorkflow(PROJECT_TYPE.OTHER)}
          >
            Other
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            onClick={() => selectWorkflow(PROJECT_TYPE.WORKFLOW)}
          >
            Workflow
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
