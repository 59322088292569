export type PathParam = 'admin' | 'users' | 'environments' | 'deploymenttargets' | 'subscription' | ''

export type UIRouterStateNames = 'DASHBOARD' | 'PROJECT' | 'ADMIN' | 'ADMIN_ROOT'

export const routerStateNames: { [key: string]: UIRouterStateNames } = {
  DASHBOARD: 'DASHBOARD',
  PROJECT: 'PROJECT',
  ADMIN: 'ADMIN',
  ADMIN_ROOT: 'ADMIN_ROOT'
}

export interface NavEntryConfig {
  title: string 
  uiRouterState: UIRouterStateNames | null
  pathParam: PathParam
}

export const ADMIN_TAB_PATHS: { [key: string]: PathParam } = {
  ADMINISTRATION: 'admin',
  ENVIRONMENTS: 'environments',
  DEPLOYMENT_TARGET: 'deploymenttargets',
  USERS: 'users',
  SUBSCRIPTION: 'subscription'
}

export const menuEntries: { [key: string]: NavEntryConfig } = {
  PROJECTS: {
    title: 'My Projects',
    uiRouterState: routerStateNames.DASHBOARD,
    pathParam: '',
  },
  ADMIN: {
    title: 'Administration',
    uiRouterState: routerStateNames.ADMIN_ROOT,
    pathParam: '',
  },
  USERS: {
    title: 'Users',
    uiRouterState: routerStateNames.ADMIN,
    pathParam: ADMIN_TAB_PATHS.USERS,
  },
  ENVIRONMENTS: {
    title: 'Environments',
    uiRouterState: routerStateNames.ADMIN,
    pathParam: ADMIN_TAB_PATHS.ENVIRONMENTS,
  },
  DEPLOYMENT_TARGETS: {
    title: 'Deployment Targets',
    uiRouterState: routerStateNames.ADMIN,
    pathParam: ADMIN_TAB_PATHS.DEPLOYMENT_TARGET,
  },
  SUBSCRIPTION: {
    title: 'Subscription',
    uiRouterState: routerStateNames.ADMIN,
    pathParam: ADMIN_TAB_PATHS.SUBSCRIPTION,
  },
  ACCOUNT: {
    title: 'My Account',
    uiRouterState: null,
    pathParam: '',
  },
  SUPPORT: {
    title: 'Customer Support',
    uiRouterState: null,
    pathParam: '',
  },
  GITLAB: {
    title: 'GitLab',
    uiRouterState: null,
    pathParam: '',
  },
  LOGOUT: {
    title: 'Logout',
    uiRouterState: null,
    pathParam: '',
  },
}
