import React from 'react'
import {cloneDeep} from 'lodash'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import PipelineTriggerGroup from 'src/models/pipeline/PipelineTriggerGroup'
import PipelineTemplateEditorTriggerGroup from './PipelineTemplateEditorTriggerGroup'
import IStoreState from 'src/store/IStoreState'


export interface IPipelineTemplateEditorTriggerGroupListProps {
  pipelineTriggerGroupList: PipelineTriggerGroup[],
  pipelineTriggerGroupListChange: (pipelineTriggerGroupList: PipelineTriggerGroup[]) => void
}

export interface IPipelineTemplateEditorTriggerGroupListState {
}


export class PipelineTemplateEditorTriggerGroupList extends React.Component<IPipelineTemplateEditorTriggerGroupListProps, IPipelineTemplateEditorTriggerGroupListState> {
  constructor(props: IPipelineTemplateEditorTriggerGroupListProps) {
    super(props)
    this.state = {}
  }

  handleTriggerGroupChange: (oldTriggerGroup: PipelineTriggerGroup, newTriggerGroup: PipelineTriggerGroup) => void =
    (oldTriggerGroup: PipelineTriggerGroup, newTriggerGroup: PipelineTriggerGroup) => {
      const i: number = this.props.pipelineTriggerGroupList.indexOf(oldTriggerGroup)
      const duplicateTriggerGroupList: PipelineTriggerGroup[] = cloneDeep(this.props.pipelineTriggerGroupList)
      if (newTriggerGroup.triggers.length > 0) {
        duplicateTriggerGroupList[i] = i > -1 ? newTriggerGroup : oldTriggerGroup
      } else {
        duplicateTriggerGroupList.splice(i, 1)
      }
      this.props.pipelineTriggerGroupListChange(duplicateTriggerGroupList)
    }

  render = (): React.ReactNode => {
    const {} = this.state
    const {pipelineTriggerGroupList} = this.props


    return (
      pipelineTriggerGroupList.map((pipelineTriggerGroup: PipelineTriggerGroup, index: number) => (
        <PipelineTemplateEditorTriggerGroup key={index}
                                            pipelineTriggerGroup={pipelineTriggerGroup}
                                            handleTriggerGroupChange={this.handleTriggerGroupChange}/>
      ))
    )
  }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IPipelineTemplateEditorTriggerGroupListState): any => ({})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IPipelineTemplateEditorTriggerGroupListProps): any => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PipelineTemplateEditorTriggerGroupList)
