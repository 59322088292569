import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { differenceBy } from 'lodash'
import { Grid, Typography } from '@material-ui/core'
import IStoreState from '../../../../../store/IStoreState'
import Project from '../../../../../models/Project'
import ProjectEnvironment from '../../../../../models/ProjectEnvironment'
import DeploymentEnvironment from '../../../../../models/DeploymentEnvironment'
import DeploymentEnvironmentHelper from '../../../../../helpers/DeploymentEnvironmentHelper'
import { SectionLayout } from '../../shared'
import '../../../shared/project.scss'
import { updateProjectEnvironmentsAction } from 'src/store/actions/project/update-environments.actions'
import { useStyles } from "../../../project-create/drupal7/drupal7-environments.config";
import { UpdateConnectedSelectedEnvironmentsList } from "./environment-selected.component"
import { ConnectedUnselectedEnvironmentsDetailsList } from "./environment-unSelected.component";
import { setProjectSelectedEnvironmentsDetails } from "../../../../../store/update-project-data/project-details-environment.action";
import { EnvironmentSelectionConfig } from "../../../project-create/drupal8/drupal8-environments.config";

const DESCRIPTION: string = "Add, remove, or reorder your environments to begin defining your workflow. The development and production environments are required."

export interface IProjectEditEnvironmentsProps {
  handleInputChange?: (event: any) => void
  handleLoad?:(event:EnvironmentSelectionConfig[])=>void
  project?: Project
  deploymentEnvironments?: DeploymentEnvironment[]
  error?: object
  loading?: boolean
}

interface IProjectEditEnvironmentsState {
  deselectedProjectEnvironments: ProjectEnvironment[]
}

interface EnvironmentIdExtension {
  environmentId: string
  environment: DeploymentEnvironment
}

export class ProjectEditEnvironments extends React.Component<IProjectEditEnvironmentsProps, IProjectEditEnvironmentsState> {
  constructor(props: IProjectEditEnvironmentsProps) {
    super(props)
    this.state = {
      deselectedProjectEnvironments: [],
    }
  }

  componentDidMount() {
    if (!this.props.project.projectEnvironments) {
      this.initializeProjectEnvironments()
    } else {
      this.initializeDeselectedProjectEnvironments()
    }
  }

  initializeProjectEnvironments = (): void => {
    const initialProjectEnvironments: ProjectEnvironment[] = this.props.deploymentEnvironments
      .sort((env1: DeploymentEnvironment, env2: DeploymentEnvironment) => {
        if (env1.isDev || env2.isProd) {
          return -1
        }
        if (env1.isProd || env2.isDev) {
          return 1
        }
        return 0
      })
      .map((env: DeploymentEnvironment, index: number) => this.createProjectEnvFromDeploymentEnv(env, index))
    this.props.handleInputChange({
      target: {
        name: 'projectEnvironments',
        value: initialProjectEnvironments,
      },
    })
  }

  initializeDeselectedProjectEnvironments = (): void => {
    const projectEnvironments = this.props.project?.projectEnvironments || []
    const deploymentEnvironments = this.props.deploymentEnvironments?.map<EnvironmentIdExtension>((e) => {
      return { environmentId: e.id, environment: e }
    })
    const unselected = differenceBy<EnvironmentIdExtension, ProjectEnvironment>(
      deploymentEnvironments,
      projectEnvironments,
      'environmentId',
    )
    const converted = unselected.map((extension) => this.createProjectEnvFromDeploymentEnv(extension.environment))
    this.setState({ deselectedProjectEnvironments: converted })
  }

  createProjectEnvFromDeploymentEnv = (deploymentEnvironment: DeploymentEnvironment, order?: number): ProjectEnvironment => {
    return new ProjectEnvironment({
      environmentId: deploymentEnvironment.id,
      order,
      symLinks: [],
    })
  }

  render() {
    const { project } = this.props
    const spacing = 3
    if (project.projectEnvironments && project.projectEnvironments.length > 0) {
      const modifiedEnvironment: { isProd: boolean; isDev: boolean; id: string; title: string, order:number, status: string }[] = project.projectEnvironments.map((projectEnvironment: ProjectEnvironment, index: number) => {
        const deploymentEnvironment: DeploymentEnvironment = DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projectEnvironment.environmentId)
        return ({
          id: projectEnvironment.environmentId,
          title: deploymentEnvironment.title,
          order: projectEnvironment.order,
          isProd: projectEnvironment.environmentId.toLowerCase() === "prod" || deploymentEnvironment.title.toLowerCase() === "production",
          isDev: projectEnvironment.environmentId.toLowerCase() === "dev" || deploymentEnvironment.title.toLowerCase() === "development",
          status: deploymentEnvironment.status,
        })
      })
      this.props.handleLoad(modifiedEnvironment.filter(env => env.isProd !== true))
      return (
        <SectionLayout>
          <Grid container item direction="column" spacing={spacing} sm={12}>
            <SelectedList />
            <UnselectedList />
          </Grid>
        </SectionLayout>
      )
    }
    return null
  }
}

const mapStateToProps: any = (state: IStoreState): any => ({
  project: state.selectedProject.project,
  error: state.selectedProject.error,
  loading: state.selectedProject.loading,
  deploymentEnvironments: state.deploymentEnvironments.data,
})

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  handleInputChange: (event: { target: { name: string, value: ProjectEnvironment[] } }): void => {
    dispatch(updateProjectEnvironmentsAction(event.target.value))
  },
  handleLoad: (environments:EnvironmentSelectionConfig[]):void=>{
    dispatch(setProjectSelectedEnvironmentsDetails(environments))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditEnvironments)
interface SelectedListProps {}

const SelectedList: React.FC<SelectedListProps> = () => {
  const styles = useStyles()
  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1">{DESCRIPTION}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={styles.titleText}>
          Selected Environments
        </Typography>
      </Grid>
      <Grid item>
        <UpdateConnectedSelectedEnvironmentsList />
      </Grid>
    </Grid>
  )
}

interface UnselectedListProps {}

const UnselectedList: React.FC<UnselectedListProps> = () => {
  const styles = useStyles()
  return (
      <Grid container item direction="column" spacing={2}>
        <Grid item>
          <Typography variant="body1" className={styles.titleText}>
            Deselected Environment
          </Typography>
        </Grid>
        <Grid item>
          <ConnectedUnselectedEnvironmentsDetailsList />
        </Grid>
      </Grid>
  )
}
