class SNACKBAR_TYPES {
  static SUCCESS = 'SUCCESS'
  static FAILURE = 'FAILURE'
}

class SnackbarService {
  constructor() {
    this.state = {
      open: false,
      type: null,
      message: null,
    }
    this.onChangeSubscribers = []
  }

  show(type, message) {
    this.state = {
      open: true,
      type,
      message,
    }
    this.notifyOnChangeSubscribers()
  }

  hide() {
    this.state = {
      open: false,
      type: null,
      message: null,
    }
    this.notifyOnChangeSubscribers()
  }

  notifyOnChangeSubscribers() {
    this.onChangeSubscribers.forEach((handler) => handler(this.state))
  }

  subscribeToChanges(subscriber) {
    this.onChangeSubscribers.push(subscriber)
  }

  unsubscribeToChanges(subscriber) {
    let index = this.onChangeSubscribers.indexOf(subscriber)
    if (index > -1) {
      this.onChangeSubscribers = this.onChangeSubscribers.splice(index, 1)
    }
  }
}

export default new SnackbarService()
export {SNACKBAR_TYPES}
