import React from 'react'
import User from 'src/models/User'
import Pipeline from 'src/models/pipeline/Pipeline'
import {PipelineTemplate} from 'src/models/pipeline/PipelineTemplate'
import GenericTable, {sortDirections} from 'src/components/common/GenericTable'
import PipelineTemplateEventTableRow from './PipelineTemplateEventTableRow'
import PipelineTemplateEvent from './PipelineTemplateEvent'

export interface IPipelineTemplateTableProps {
  pipelineTemplates: PipelineTemplate[],
  latestPipelines: Pipeline[],
  currentUser: User,
  onRunPipeline: (pipeline: Pipeline) => void,
  onCancelPipeline: (pipelineId: string) => void
}

export interface IPipelineTemplateTableState {

}

export class PipelineTemplateTable extends React.Component<IPipelineTemplateTableProps, IPipelineTemplateTableState> {
  columns: any[] = [
    {id: 'lastRunIcon', numeric: false, disablePadding: true, label: '', sortable: false, order:sortDirections.ASC},
    {id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true, order:sortDirections.ASC},
    {id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true, order:sortDirections.ASC},
    {id: 'lastUpdated', numeric: false, disablePadding: false, label: 'Last Updated', sortable: true, order:sortDirections.ASC},
    {id: 'lastRun', numeric: false, disablePadding: false, label: 'Last Run', sortable: false, order:sortDirections.ASC},
  ]

  // method to compare updated.items to state.items in GenericTable component
  compareLatestPipeline = (updatedItems: any, currentItems: any) => {
    for (let updated of updatedItems) {
      for (let current of currentItems) {
        if (updated.pipelineTemplate.id === current.pipelineTemplate.id) {
          if (updated?.latestPipeline && current?.latestPipeline === undefined) {return false}
          if (updated?.latestPipeline?.id !== current?.latestPipeline?.id) {return false}
          if (updated?.latestPipeline?.status !== current?.latestPipeline?.status) {return false}
        }
      }
    }
    return true
  }

  render(): React.ReactNode {
    const {latestPipelines, pipelineTemplates, currentUser, onRunPipeline, onCancelPipeline} = this.props
    const pipelineTemplateEvents: PipelineTemplateEvent[] = pipelineTemplates.map((pipelineTemplate: PipelineTemplate) => {
      return new PipelineTemplateEvent({
        pipelineTemplate,
        latestPipeline: latestPipelines.find((pipeline: Pipeline) => pipeline.pipelineTemplateId === pipelineTemplate.id)
      })
    })
    return (
      <GenericTable columns={this.columns}
                    items={pipelineTemplateEvents}
                    order={sortDirections.ASC}
                    orderBy='name'
                    rowComponent={PipelineTemplateEventTableRow}
                    rowProps={{currentUser, onRunPipeline, onCancelPipeline}}
                    comparator={this.compareLatestPipeline}
                    componentField='pipelineTemplate'/>
    )
  }
}

export default PipelineTemplateTable
