import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyles } from './menu-item.config'
import { MenuEntryConfig, MenuSection } from './section-menu.config'
import { useSrefActive } from '@uirouter/react'
import { appStates } from 'src/appStates'

export interface MenuItemProps {
  showLabel: boolean
  isSelected: boolean
  config: MenuEntryConfig
  onClick: (section: MenuSection) => void
}

export const MenuItem: React.FC<MenuItemProps> = ({ config, onClick, isSelected, showLabel }) => {
  const styles = useStyles()
  const { section, label, IconDefinition } = config
  const sectionString = section.toString()
  return (
    <UILink to={appStates.PROJECT + '.' + appStates.PROJECT_DETAILS} params={{ section: sectionString }}>
      <ListItem
        button
        key={label}
        selected={isSelected}
        className={styles.menuItemContainer}
        data-toggle={"tooltip"}
        title={label}
      >
        <ListItemIcon>
          <FontAwesomeIcon icon={IconDefinition} className={styles.menuIcon} />
        </ListItemIcon>
        {showLabel && (
            <Typography display="inline" variant="body2" className={styles.menuLabel}>
              {label}
            </Typography>
        )}
      </ListItem>
    </UILink>
  )
}

interface UILinkProps {
  to: string
  params: { [key: string]: string }
  activeClassName?: string
}

const UILink: React.FC<UILinkProps> = ({ to, params = null, activeClassName = 'active', children }) => {
  const sref = useSrefActive(to, params, activeClassName)
  return (
    <a {...sref}>
      {children}
    </a>
  )
}
