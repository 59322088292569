import React from 'react'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {FormLabel, Grid, MenuItem, Select, Typography} from '@material-ui/core'
import '../shared/project.scss'
import Project, {PROJECT_ERROR, PROJECT_TYPE} from '../../../models/Project'
import ActionTypes from '../../../store/actions'
import IStoreState from '../../../store/IStoreState'
import MessageService from '../../../services/MessageService'
import {CreateFormTextField} from "../project-create/shared"
import {useStyles} from "../project-create/drupal7/drupal7-details.config"
import GitService from "../../../services/GitService";
import ProjectWizardActions from "../../../store/actions/projectWizardActions";

export interface IProjectCloneDetailsProps {
  handleInputChange?: (event: any) => void,
  project?: Project,
  cloneFromProject?: Project,
  error?: PROJECT_ERROR,
  loading?: boolean,
  updateProjectVisibility?: (event: boolean) => void
}

export class ProjectCloneDetails extends React.Component<IProjectCloneDetailsProps> {
  get stepDescription(): string {
    let stepDescription: string = MessageService.get('project.wizard.step1.details')
    const {cloneFromProject} = this.props
    if (cloneFromProject) {
      stepDescription = stepDescription + " Please note that groups and projects in GitLab must be public before they can be cloned."
    }
    return stepDescription
  }

  componentDidMount() {
    this.checkRepoVisibility()
  }

  checkRepoVisibility(): void {
    const projectToClone = this.props.cloneFromProject
    if (projectToClone.hasCode) {
      GitService.isRepositoryPublic(projectToClone.codeRepository)
        .then((res) => {
          this.props.updateProjectVisibility(res as boolean)
        })
        .catch(() => {})
    }
  }

  changeHandlerWithVisibilityCheck = (event: any) => {
    this.props.handleInputChange(event)
    this.checkRepoVisibility()
  }


  render(): React.ReactNode {
    const {project, cloneFromProject, error, loading} = this.props
    const changeHandlerWithVisibilityCheck = this.changeHandlerWithVisibilityCheck
    const showDomain: boolean = cloneFromProject?.type !== PROJECT_TYPE.WORKFLOW

    return (
      <Grid container spacing={4} justify='center' style={{background:'#ffffff',borderRadius:'4px'}}>
        <Grid item xs={12}>
          <FormLabel component='legend'>{this.stepDescription}</FormLabel>
        </Grid>
        <Grid item xs={12}>
          <CreateFormTextField
            textFieldLabel="Name *"
            name="name"
            value={project.name}
            helperText={error === PROJECT_ERROR.PROJECT_EXISTS && MessageService.get('project.wizard.error.details.name.duplicate')}
            onChange={changeHandlerWithVisibilityCheck}
            disabled={loading}
            autoComplete="off"
          />
        </Grid>
        {showDomain &&
        <Grid item xs={12}>
          <CreateFormTextField
            textFieldLabel="Domain *"
            name="domain"
            value={project.domain}
            tooltipMessage={MessageService.get('project.wizard.details.domain.tooltip')}
            helperText={error === PROJECT_ERROR.DOMAIN_CONTAINS_PROTOCOL && MessageService.get('project.wizard.error.details.domain.contains.protocol')}
            onChange={changeHandlerWithVisibilityCheck}
            disabled={loading}
            autoComplete="off"
          />
          <CloneProjectTypeField typeTitle={project.type} projectType={project.type} />
        </Grid>
        }
      </Grid>
    )
  }
}

const mapStateToProps: any = (state: IStoreState): any => {
  return {
    project: state.projectWizard.project,
    cloneFromProject: state.projectWizard.cloneFromProject,
    error: state.projectWizard.error,
    loading: state.projectWizard.loading
  }
}

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: any): any => ({
  handleInputChange: (event: any): void => {
    dispatch({
      type: ActionTypes.UPDATE_PROJECT_WIZARD_FIELD,
      payload: {
        key: event.target.name,
        value: event.target.value
      }
    })
  },
  updateProjectVisibility: (event: boolean): void => {
    dispatch(ProjectWizardActions.setProjectVisibility(event, dispatch))
  }
})

interface CloneProjectTypeFieldProps {
  projectType: PROJECT_TYPE
  typeTitle: string
}

const CloneProjectTypeField: React.FC<CloneProjectTypeFieldProps> = ({ projectType, typeTitle }) => {
  const styles = useStyles()
  const textFieldVariant = 'subtitle2'
  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography variant={textFieldVariant} className={styles.textFieldLabel}>
          Type
        </Typography>
      </Grid>
      <Grid item>
        <Select variant="outlined" disabled={true} value={projectType} inputProps={{ name: 'type' }} fullWidth>
          <MenuItem value={projectType}>{typeTitle}</MenuItem>
        </Select>
      </Grid>
    </Grid>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCloneDetails)
