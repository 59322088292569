import {ACTION_TYPE} from './Action'
import 'reflect-metadata'
import {assign} from 'lodash'
import {ActionConfiguration} from './ActionConfiguration'

class DatabaseSnapshotActionConfiguration extends ActionConfiguration {
  database: string

  constructor(props?: Partial<DatabaseSnapshotActionConfiguration>) {
    super()
    this.actionType = ACTION_TYPE.DATABASE_SNAPSHOT

    props = props || {}

    const defaultProps: Partial<DatabaseSnapshotActionConfiguration> = {
      database: undefined
    }
    assign(this, defaultProps, props)
  }

  isValid(): boolean {
    return false
  }
}

export default DatabaseSnapshotActionConfiguration
