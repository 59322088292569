import React from 'react'
import {Grid, IconButton, Tooltip} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCode, faCog, faDatabase, faFolder, faTrash} from '@fortawesome/free-solid-svg-icons'
import {faProductHunt} from '@fortawesome/free-brands-svg-icons'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'

import DeploymentTargetGroup, {DEPLOYMENT_TARGET_GROUP_TYPE} from '../../../models/DeploymentTargetGroup'
import './DeploymentEnvironmentsSection.scss'
import DeploymentTarget from '../../../models/DeploymentTarget'
import DeploymentTargetGroupService from '../../../services/DeploymentTargetGroupService'
import DeploymentTargetGroupPopoverMenu from './DeploymentTargetGroupPopoverMenu'
import MessageService from '../../../services/MessageService'
import ConfirmDialog from '../../common/ConfirmDialog'
import DeploymentEnvironmentHelper from '../../../helpers/DeploymentEnvironmentHelper'
import IStoreState from '../../../store/IStoreState'
import {
  deleteDeploymentTargetGroupById,
  updateDeploymentTargetGroup
} from '../../../store/actions/selectedEnvironmentActions'
import InLineEditableText from '../../common/InLineEditableText'
import Project from "../../../models/Project";
import ProjectEnvironment from "../../../models/ProjectEnvironment";
import CSS from 'csstype';


export interface IDeploymentTargetGroupDetailProps {
  targetGroup: DeploymentTargetGroup
  environmentId: string
  deleteDeploymentTargetGroupById?: (envId: string, targetGroupId: string) => void
  updateDeploymentTargetGroup?: (envId: string, updatedGroup: DeploymentTargetGroup) => void
  projects?: Project[]
  groups?: DeploymentTargetGroup[]
}

export interface IDeploymentTargetGroupDetailState {
  error: boolean
  loading: boolean
  currentTargetGroup: DeploymentTargetGroup
  openDeploymentTargetList: boolean
  confirmDelete: boolean
}

export class DeploymentTargetGroupDetail extends React.Component<IDeploymentTargetGroupDetailProps,
  IDeploymentTargetGroupDetailState> {

  deploymentTargetListAnchor: React.RefObject<any> = React.createRef()
  private readonly toolTipMessageForName: string
  private readonly toolTipDeleteDisabledMsg: string
  private readonly toolTipDeploymentTargetTypeMsg: string

  constructor(props: IDeploymentTargetGroupDetailProps) {
    super(props)

    this.toolTipMessageForName = MessageService.get('admin.deploymenttargetgroup.name.tooltip')
    this.toolTipDeleteDisabledMsg = MessageService.get('admin.deploymenttargetgroup.delete.disabled.tooltip')
    this.toolTipDeploymentTargetTypeMsg = 'Type: '

    this.state = {
      currentTargetGroup: undefined,
      error: false,
      loading: true,
      openDeploymentTargetList: false,
      confirmDelete: false,
    }
  }

  componentDidMount = (): void => {
    this.loadDeploymentGroup()
  }

  loadDeploymentGroup = async (): Promise<void> => {
    const {environmentId, targetGroup} = this.props
    try {
      const currentTargetGroup: DeploymentTargetGroup = await DeploymentTargetGroupService.getDeploymentTargetGroup(environmentId, targetGroup.id)
      this.setState({currentTargetGroup, loading: false})
    } catch (error) {
      this.setState({error: true, loading: false})
    }
  }

  displayDeploymentTargetGroupIcon = (type: DEPLOYMENT_TARGET_GROUP_TYPE): any => {
    if (type === DEPLOYMENT_TARGET_GROUP_TYPE.CODE) {
      return faCode
    } else if (type === DEPLOYMENT_TARGET_GROUP_TYPE.ASSET) {
      return faFolder
    } else if (type === DEPLOYMENT_TARGET_GROUP_TYPE.DATABASE) {
      return faDatabase
    }
  }

  get projectsUsingDeploymentTargetGroup(): string[] {
    const projectsUsingDeploymentTargetGroup: string[] = []
    this.props.projects.forEach((proj: Project) => {
      proj.projectEnvironments && proj.projectEnvironments.forEach((projEnv: ProjectEnvironment) => {
        this.props.groups.forEach((group: DeploymentTargetGroup) => {

          if (projEnv.projectEnvironmentAssets?.deploymentTargetGroupId === group.id
            && this.props.targetGroup.name == group.name) {
            projectsUsingDeploymentTargetGroup.push(`Assets: ${proj.name}`)
          }
          if (projEnv.projectEnvironmentCode?.deploymentTargetGroupId === group.id
            && this.props.targetGroup.name == group.name) {
            projectsUsingDeploymentTargetGroup.push(`Code: ${proj.name}`)
          }
          if (projEnv.projectEnvironmentDatabases?.deploymentTargetGroupId === group.id
            && this.props.targetGroup.name == group.name) {
            projectsUsingDeploymentTargetGroup.push(`Databases: ${proj.name}`)
          }
        })
      })
    })
    return projectsUsingDeploymentTargetGroup
  }

  toggleDeploymentTargetList = (event: any): void => {
    if (!this.state.openDeploymentTargetList) {
      this.setState({
        openDeploymentTargetList: true,
      })
    } else {
      this.closeDeploymentTargetList()
    }
  }

  closeDeploymentTargetList = async (): Promise<void> => {
    await this.loadDeploymentGroup()
    this.setState({
      openDeploymentTargetList: false,
    })
  }

  setName = (name: string): void => {
    const currentTargetGroup: DeploymentTargetGroup = this.state.currentTargetGroup
    currentTargetGroup.name = name
    this.setState({currentTargetGroup})
  }

  submitNameChange = (name: string): void => {
    const updatedGroup: DeploymentTargetGroup = this.props.targetGroup
    updatedGroup.name = name
    this.props.updateDeploymentTargetGroup(this.props.environmentId, updatedGroup)
  }

  get confirmDeleteMessage(): React.ReactNode {
    const {targetGroup} = this.props
    let messageTop: React.ReactNode
    let messageBottom: React.ReactNode

    const deleteDtgWarningProjectUsing = 'The following projects depend on this deployment group:'

    if (this.projectsUsingDeploymentTargetGroup.length) {
      messageTop = (
        <span>
          {deleteDtgWarningProjectUsing}
          <ul>{this.projectsUsingDeploymentTargetGroup.map((name: string) => <li key={name}
                                                                                 dangerouslySetInnerHTML={{__html: name}}/>)}</ul>
        </span>
      )
    } else {
      messageBottom = (
        <span>
            Are you sure you want to delete the<b> {targetGroup.name} </b> deployment group from the
            <b> {DeploymentEnvironmentHelper.getEnvironmentTitleById(this.props.environmentId)} </b> Deployment Environment?
          </span>
      )
    }
    return (
      <span>
        {messageTop}
        <br/>
        {messageBottom}
      </span>
    )

  }


  processDelete = (): void => {
    this.setState({confirmDelete: false})
    this.props.deleteDeploymentTargetGroupById(this.props.environmentId, this.props.targetGroup.id)
  }

  handleDeleteClick = (): void => {
    this.setState({confirmDelete: true})
  }

  closeConfirmDeleteModal = (): void => {
    this.setState({confirmDelete: false})
  }

  render(): React.ReactNode {
    const {environmentId} = this.props
    const {currentTargetGroup, openDeploymentTargetList, confirmDelete} = this.state
    const typeIconStyle: CSS.Properties = {
      paddingTop: '2px',
      paddingLeft: '10px'
    };
    const dtgNameStyle: CSS.Properties = {
      marginTop: '5px',
      paddingLeft: '9px'
    };

    if (currentTargetGroup) {
      const disableRemoveButton: boolean = !DeploymentEnvironmentHelper.deploymentGroupDeletable(environmentId)
      return (
        <Grid container spacing={1} direction='column' justify='center' wrap='nowrap' className='group-card'>
          <Grid item xs={12} style={{flex: 'none'}}>
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Grid container spacing={2} style={dtgNameStyle}>
                  <Grid>
                    <h5>
                      <InLineEditableText toolTipMessage={this.toolTipMessageForName}
                                          text={this.state.currentTargetGroup.name}
                                          handleSubmit={this.submitNameChange} handleChange={this.setName}/>
                    </h5>
                  </Grid>
                  <Grid className='dt-group-button' style={typeIconStyle}>
                    <Tooltip title={this.toolTipDeploymentTargetTypeMsg + currentTargetGroup.type}
                             placement='bottom-start'
                             enterDelay={500}>
                    <span ref={this.deploymentTargetListAnchor}>
                      <FontAwesomeIcon icon={this.displayDeploymentTargetGroupIcon(currentTargetGroup.type)}/>
                    </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={3}>
                <Grid container spacing={2} justify='space-between' alignItems='center'>
                  <Grid item xs={6} className='dt-group-button'>
                    <span ref={this.deploymentTargetListAnchor}>
                      <IconButton onClick={this.toggleDeploymentTargetList} title='Edit'>
                        <FontAwesomeIcon icon={faCog}/>
                      </IconButton>
                    </span>
                  </Grid>
                  <Grid item xs={6} className='dt-group-button'>
                    <Tooltip title={this.toolTipDeleteDisabledMsg} placement='top-start'
                             enterDelay={500} disableHoverListener={!disableRemoveButton}>
                      <span>
                        <IconButton onClick={this.handleDeleteClick} title='Delete' disabled={disableRemoveButton}>
                          <FontAwesomeIcon icon={faTrash}/>
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          <Grid item xs={12}>
            Deployment Targets
          </Grid>
          <Grid item xs={12}>
            {currentTargetGroup.deploymentTargets.length > 0 &&
            <Grid container spacing={1}>
              {currentTargetGroup.deploymentTargets.map((deploymentTarget: DeploymentTarget, index: number) => (
                <Grid item lg={4} md={6} sm={6} xs={12} key={index}>
                  <Grid container className='deployment-target-card full-height'>
                    <Grid item xs={12}>
                      <Grid container spacing={0} justify='space-between' alignItems='center'>
                        <Grid item>
                          <strong>{deploymentTarget.name}</strong>
                        </Grid>
                        <Grid item>
                          {currentTargetGroup.primaryDeploymentTargetId === deploymentTarget.id &&
                          <Tooltip title='This is the primary Deployment Target for this group.' enterDelay={50}>
                            <div>
                              <FontAwesomeIcon icon={faProductHunt} color='lightgrey'/>
                            </div>
                          </Tooltip>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className='helpText'>
                      {deploymentTarget.fqdn}<br/>
                      <i>{deploymentTarget.ipAddress}</i>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            }
            {currentTargetGroup.deploymentTargets.length === 0 &&
            <Grid container justify='center' alignItems='center' className='helpText full-height'>
              <Grid item>There are no targets in this group</Grid>
            </Grid>
            }
          </Grid>
          {confirmDelete &&
          <ConfirmDialog open title={`Delete ${currentTargetGroup.name} Group?`} message={this.confirmDeleteMessage}
                         disableConfirm={this.projectsUsingDeploymentTargetGroup.length > 0}
                         onConfirm={this.processDelete} onCancel={this.closeConfirmDeleteModal}/>
          }
          {openDeploymentTargetList &&
          <DeploymentTargetGroupPopoverMenu
            handleClose={this.closeDeploymentTargetList}
            anchorEl={this.deploymentTargetListAnchor.current}
            deploymentTargetGroup={currentTargetGroup}
            environmentId={environmentId}/>
          }
        </Grid>
      )
    }
    return null
  }
}

const mapStateToProps: any = (state: IStoreState): any => ({
  groups: state.selectedEnvironment.deploymentTargetGroups,
  projects: state.projects.data
})

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  deleteDeploymentTargetGroupById: (envId: string, targetGroupId: string): void => dispatch(deleteDeploymentTargetGroupById(envId, targetGroupId)),
  updateDeploymentTargetGroup: (envId: string, updatedGroup: DeploymentTargetGroup): void => dispatch(updateDeploymentTargetGroup(envId, updatedGroup)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentTargetGroupDetail)
