import Project from '../../models/Project'
import {Action, Dispatch} from 'redux'
import ActionTypes from '.'
import ProjectService from '../../services/ProjectService'
import PipelineService from '../../services/PipelineService'
import SnackbarService, {SNACKBAR_TYPES} from '../../services/SnackbarService'
import {
  defaultProjectSaveErrorMessage,
  initialDeploymentErrorMessage
} from 'src/components/project/project-details/project-details.config'
import { Pipeline } from 'src/models/pipeline/Pipeline'
import PipelineAction, {ACTION_TYPE} from 'src/models/pipeline/Action'
import AssetMergeActionConfiguration from "../../models/pipeline/AssetMergeActionConfiguration";
import DatabaseSnapshotActionConfiguration from "../../models/pipeline/DatabaseSnapshotActionConfiguration";
import {PIPELINE_TRIGGER_TYPE, PipelineTrigger} from 'src/models/pipeline/PipelineTrigger'
import {router} from "../../router";
import {appStates} from "../../appStates";

export default class ProjectActions {
  static loadProjects(): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.PROJECTS_LOAD})
      try {
        const projects: Project[] = await ProjectService.getProjects()
        dispatch({type: ActionTypes.PROJECTS_LOAD_SUCCESS, payload: {projects}})
      } catch (error) {
        dispatch({type: ActionTypes.PROJECTS_LOAD_FAIL, payload: {error}})
      }
    }
  }

  static saveNewProject(project: Project, cloneFromProjectId?: string, successAction?: () => any): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.PROJECT_SAVE})
      try {
        const savedProject: Project = await ProjectService.createProject(project, cloneFromProjectId)
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Your new project has been saved.')
        dispatch({type: ActionTypes.PROJECT_SAVE_SUCCESS, payload: {project: savedProject}})
        dispatch(ProjectActions.loadProjects())
        successAction && dispatch(successAction())
        dispatch({ type: ActionTypes.CLOSE_PROJECT_WIZARD })
        router.stateService.go(appStates.DASHBOARD)
      } catch (error) {
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to save your new project!')
        dispatch({type: ActionTypes.PROJECT_SAVE_FAIL, payload: {error}})
      }
    }
  }

  static updateProject(project: Project, successAction?: () => any): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.PROJECT_SAVE})
      try {
        const savedProject: Project = await ProjectService.updateProject(project)
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Your project has been updated.')
        dispatch({type: ActionTypes.SELECTED_PROJECT_SAVE_SUCCESS, payload: {project: savedProject}})
        dispatch(ProjectActions.loadProjects())
        successAction && dispatch(successAction())
      } catch (error) {
        const errorMessage = defaultProjectSaveErrorMessage
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to update your project!')
        dispatch({type: ActionTypes.SELECTED_PROJECT_SAVE_FAIL, payload: {error, errorMessage}})
      }
    }
  }

  static deployInitialProject(project: Project, successAction?: () => any): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.PROJECT_DEPLOY_INITIAL})
      try {

        let env = project.projectEnvironments.find(e => e.order === 0)
        let toEnv = project.projectEnvironments.find(e => e.order === 1)
        if (project.hasAssets) {
          //Deploy Assets
          for (const srcAssets of toEnv.projectEnvironmentAssets.assetGroups) {
            const assetPipeline: Pipeline = new Pipeline({
              projectId: project.id,
              actions: [
                new PipelineAction({
                  actionConfiguration: new AssetMergeActionConfiguration({
                    sourceEnvironment: toEnv.environmentId,
                    //Going from env 1 to 0
                    sourceDeploymentTargetGroupId: project.getAssetTargetGroupIdByEnv(toEnv.environmentId),
                    targetEnvironment: env.environmentId,
                    targetDeploymentTargetGroupId: project.getAssetTargetGroupIdByEnv(env.environmentId),
                    sourceAssetPath: srcAssets.path,
                    targetAssetPath: env.projectEnvironmentAssets.assetGroups.filter(ag => ag.associationId == srcAssets.associationId)[0].path
                  })
                })
              ]
            })
            try {
              await PipelineService.executePipeline(assetPipeline)
              SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Successfully started asset merge.')
            } catch (error) {
              SnackbarService.show(SNACKBAR_TYPES.FAILURE, error || 'Failed to start asset merge!')
            }
          }
        }

        if (project.hasDatabases) {
          //Deploy Database
          const databasePipeline: Pipeline = new Pipeline({
            projectId: project.id,
            actions: [
              new PipelineAction({
                actionConfiguration: new DatabaseSnapshotActionConfiguration({
                  targetEnvironment: env.environmentId,
                  targetDeploymentTargetGroupId: project.getDatabaseTargetGroupIdByEnv(env.environmentId),
                  database: env.projectEnvironmentDatabases.databases[0].name
                })
              })
            ]
          })
          try {
            await PipelineService.executePipeline(databasePipeline)
            SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Successfully started database snapshot.')
          } catch (error) {
            SnackbarService.show(SNACKBAR_TYPES.FAILURE, error || 'Failed to start database snapshot!')
          }
        }

        if (project.hasCode) {
          //Deploy Code
          const triggers: PipelineTrigger[] = [
            new PipelineTrigger({type: PIPELINE_TRIGGER_TYPE.ACTION_TYPE, value: ACTION_TYPE.CODE_DEPLOY}),
            new PipelineTrigger({type: PIPELINE_TRIGGER_TYPE.TARGET_ENV, value: env.environmentId})
          ]

          try {
            await PipelineService.triggerPipeline(project.id, triggers)
            SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Triggered Code Deploy Pipeline.')
          } catch (error) {
            SnackbarService.show(SNACKBAR_TYPES.FAILURE, error || 'Failed to trigger Code Deploy Pipeline!')
          }
        }

        //Show some success:
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Your project has started its initial deployment.')
        dispatch({type: ActionTypes.PROJECT_DEPLOY_INITIAL_SUCCESS,payload: {project: {project}}})
        successAction && dispatch(successAction())
        //dispatch(ProjectActions.loadProjects())
      } catch (error) {
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to start initial project deployment.')
        const errorMessage = initialDeploymentErrorMessage
        dispatch({type: ActionTypes.PROJECT_DEPLOY_INITIAL_FAIL, payload: {error, errorMessage}})
      }

    }
  }

  static disableProject(projectId: string): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.DISABLE_PROJECT})
      try {
        await ProjectService.disableProject(projectId)
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Your project has been disabled.')
        dispatch({type: ActionTypes.DISABLE_PROJECT_SUCCESS})
        dispatch(ProjectActions.loadProjects())
      } catch (error) {
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to disable your project!')
        dispatch({type: ActionTypes.DISABLE_PROJECT_FAIL, payload: {error}})
      }
    }
  }

  static enableProject(projectId: string): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.ENABLE_PROJECT})
      try {
        await ProjectService.enableProject(projectId)
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Your project has been enabled.')
        dispatch({type: ActionTypes.ENABLE_PROJECT_SUCCESS})
        dispatch(ProjectActions.loadProjects())
      } catch (error) {
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to enable your project!')
        dispatch({type: ActionTypes.ENABLE_PROJECT_FAIL, payload: {error}})
      }
    }
  }
}
