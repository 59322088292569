import React from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyles } from './breadcrumb.config'
import {Grid, Typography} from '@material-ui/core'
import {UISref} from "@uirouter/react";
import { menuEntries } from '../../../layout/header/header.config'
const variant = 'body1'
const divider = '/'

export interface BreadCrumbProps {
  context: string
  path: string
  icon: IconDefinition
  background?:string
  resetForm?:() => void
  applyPadding?:boolean
}

export const Breadcrumb: React.FC<BreadCrumbProps> = ({ icon, context, path ,background='Blue',resetForm,applyPadding=false}) => {
  const styles = useStyles()
  const { PROJECTS } = menuEntries
  return (
    <Grid container direction="row" alignItems="center" className={applyPadding?styles.padding:''}>
      {path === ''&& <div>
        <FontAwesomeIcon icon={icon} className={background === "Blue"?styles.icon:styles.icon2} size="2x" />
      </div>}
        {path === ''&& <Typography variant={variant} className={styles.context}>
        {context}
      </Typography>}
      {path !== ''&&<div onClick={resetForm}><UISref to={PROJECTS.uiRouterState}>
        <FontAwesomeIcon icon={icon} className={background === "Blue"?styles.icon:styles.icon2} size="2x" style={{cursor:'pointer'}}/>
      </UISref></div>}
        {path !== ''&&<UISref to={PROJECTS.uiRouterState}>
                <div className={background === "Blue"?styles.context:styles.context2} style={{cursor:'pointer'}} onClick={resetForm}>{context}</div>
        </UISref>}
      {path?.length > 0 &&
      <Typography variant={variant} className={background === "Blue"?styles.path:styles.path2}>

        {divider}
      </Typography>}
      <Typography variant={variant} className={background === "Blue"?styles.path:styles.path2}>
        {path}
      </Typography>
    </Grid>
  )
}
