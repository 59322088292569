import React from 'react'
import {Grid, IconButton} from '@material-ui/core'
import PipelineTrigger, {PIPELINE_TRIGGER_TYPE} from 'src/models/pipeline/PipelineTrigger'
import {cloneDeep} from 'lodash'
import IStoreState from 'src/store/IStoreState'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import Project from 'src/models/Project'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import DeploymentEnvironmentHelper from 'src/helpers/DeploymentEnvironmentHelper'
import {ACTION_TYPE} from 'src/models/pipeline/Action'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMinusCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import {OutlinedDropDown} from '../../../../../common/OutlinedDropDown'
import {DropDownEntry} from '../../../../../../models/DropDownEntry'
import '../Pipelines.scss'


export interface IPipelineTemplateEditorTriggerProps {
  trigger: PipelineTrigger,
  handleTriggerChange: (oldTrigger: PipelineTrigger, newTrigger: PipelineTrigger) => void
  handleAddTrigger?: () => void
  handleRemoveTrigger?: (trigger: PipelineTrigger) => void
  project?: Project
}

export interface IPipelineTemplateEditorTriggerState {
  typeOptions: DropDownEntry[]
  valueOptions: DropDownEntry[]
}


export class PipelineTemplateEditorTrigger extends React.Component<IPipelineTemplateEditorTriggerProps, IPipelineTemplateEditorTriggerState> {
  constructor(props: IPipelineTemplateEditorTriggerProps) {
    super(props)

    const typeOptions: DropDownEntry[] = Object.keys(PIPELINE_TRIGGER_TYPE)
    //@ts-ignore
      .map((pipelineTriggerType: PIPELINE_TRIGGER_TYPE) => new DropDownEntry(pipelineTriggerType, pipelineTriggerType))

    const valueOptions: DropDownEntry[] = this.getValueOptions(this.props.trigger.type)

    this.state = {
      typeOptions,
      valueOptions
    }
  }

  getValueOptions: (triggerType: PIPELINE_TRIGGER_TYPE) => DropDownEntry[] = (triggerType: PIPELINE_TRIGGER_TYPE) => {
    switch (triggerType) {
      case PIPELINE_TRIGGER_TYPE.ACTION_TYPE: // This dropdown is limited to the currently supported ActionTypes
        return [
          new DropDownEntry(ACTION_TYPE.CODE_DEPLOY, ACTION_TYPE.CODE_DEPLOY),
          new DropDownEntry(ACTION_TYPE.CODE_PROMOTE, ACTION_TYPE.CODE_PROMOTE),
          new DropDownEntry(ACTION_TYPE.CODE_MERGE, ACTION_TYPE.CODE_MERGE)
        ]

      case PIPELINE_TRIGGER_TYPE.SOURCE_ENV:
        return this.props.project.projectEnvironments.map((env: ProjectEnvironment) => new DropDownEntry(
          DeploymentEnvironmentHelper.getEnvironmentTitleById(env.environmentId), env.environmentId))

      case PIPELINE_TRIGGER_TYPE.TARGET_ENV:
        return this.props.project.projectEnvironments.map((env: ProjectEnvironment) => new DropDownEntry(
          DeploymentEnvironmentHelper.getEnvironmentTitleById(env.environmentId), env.environmentId))

      default:
        return []
    }
  }

  handleTriggerTypeChange: (e: any) => void = (e: any) => {
    const duplicateTrigger: PipelineTrigger = cloneDeep(this.props.trigger)
    duplicateTrigger.type = e.target.value
    duplicateTrigger.value = null
    this.props.handleTriggerChange(this.props.trigger, duplicateTrigger)
    this.setState({
      valueOptions: this.getValueOptions(duplicateTrigger.type)
    })
  }

  handleTriggerValueChange: (e: any) => void = (e: any) => {
    const duplicateTrigger: PipelineTrigger = cloneDeep(this.props.trigger)
    duplicateTrigger.value = e.target.value
    this.props.handleTriggerChange(this.props.trigger, duplicateTrigger)
  }


  handleRemoveTrigger: () => void = () => {
    this.props.handleRemoveTrigger && this.props.handleRemoveTrigger(this.props.trigger)
  }

  render = (): React.ReactNode => {
    const {typeOptions, valueOptions} = this.state
    const {trigger, handleAddTrigger} = this.props

    const selectedType: DropDownEntry = typeOptions.find((entry: DropDownEntry) => entry.value === trigger.type) || null
    const selectedValue: DropDownEntry = valueOptions.find((entry: DropDownEntry) => entry.value === trigger.value) || null

    const typeSelect: React.ReactNode = (
      <Grid item sm={5}>
        <OutlinedDropDown label='Trigger Type' value={selectedType} dropDownOptions={typeOptions}
                          handleChange={this.handleTriggerTypeChange}/>
      </Grid>
    )

    const valueSelect: React.ReactNode = (
      <Grid item sm={5}>
        <OutlinedDropDown label='Trigger Value' value={selectedValue} dropDownOptions={valueOptions}
                  handleChange={this.handleTriggerValueChange}/>
      </Grid>
    )

    const buttons: React.ReactNode = (
      <Grid item xs={2} id='trigger-group-add-remove-buttons' className='add-remove-buttons'>
        {!!this.handleRemoveTrigger &&
        <IconButton onClick={this.handleRemoveTrigger} title={'Remove Trigger'}>
          <FontAwesomeIcon icon={faMinusCircle} size='lg' className={'clickable'}/>
        </IconButton>
        }
        {!!handleAddTrigger &&
        <IconButton style={{marginBottom: '0px'}} title='Add Trigger'
                    onClick={handleAddTrigger}>
          <FontAwesomeIcon icon={faPlusCircle} size='lg' className={'clickable'}/>
        </IconButton>
        }
      </Grid>
    )

    return (
      <Grid container spacing={2} style={{paddingBottom: '1em'}}>
        {typeSelect}
        {valueSelect}
        {buttons}
      </Grid>
    )
  }
}


const mapStateToProps: any = (state: IStoreState, ownProps: IPipelineTemplateEditorTriggerState): any => ({
  project: state.selectedProject.project
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IPipelineTemplateEditorTriggerProps): any => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PipelineTemplateEditorTrigger)
