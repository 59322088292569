import {ScriptActionConfiguration} from 'src/models/pipeline/ScriptActionConfiguration'
import React from 'react'
import {Grid} from '@material-ui/core'
import {cloneDeep} from 'lodash'
import {ActionConfiguration} from 'src/models/pipeline/ActionConfiguration'
import {OutlinedTextField} from "../../../../../common/OutlinedTextField";

export interface IScriptActionConfigProps {
  actionConfiguration: ScriptActionConfiguration
  onActionConfigChanged: (actionConfiguration: ActionConfiguration)=> void
}

export class ScriptActionConfigEditor extends React.Component<IScriptActionConfigProps> {

  onScriptChange = (event: any): void => {
    const copiedSAC: ScriptActionConfiguration = cloneDeep(this.props.actionConfiguration)
    copiedSAC.scriptContents = event.target.value
    this.props.onActionConfigChanged(copiedSAC)
  }

  render(): React.ReactNode {
    const {actionConfiguration} = this.props

    return (
      <Grid>
        <h3>{actionConfiguration.actionType}</h3>
        <OutlinedTextField label='Script Contents' id='scriptContents' value={actionConfiguration.scriptContents}
                           handleChange={this.onScriptChange} multiLine={true}/>
      </Grid>
    )
  }
}

export default ScriptActionConfigEditor

