import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { FormStepConfig, FORM_STEP_TYPE } from '../forms/form.config'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    stepContainer: {
      marginBottom: theme.spacing(4),
    },
  }),
)

const WORKFLOW_DETAILS = 'Details'
const WORKFLOW_ENV = 'Environments'

export const workflowFormSteps: FormStepConfig[] = [
  {
    step: FORM_STEP_TYPE.DETAILS,
    name: WORKFLOW_DETAILS,
  },
  {
    step: FORM_STEP_TYPE.ENV,
    name: WORKFLOW_ENV,
  },
]
