import React from 'react'
import {connect} from 'react-redux'
import {Grid} from '@material-ui/core'
import IStoreState from '../../../../../store/IStoreState'
import {SectionLayout} from '../../shared'
import Project from '../../../../../models/Project'
import ProjectUsersDetails from "./project-users-details.component";


export interface ProjectUsersProps {
  project?: Project
}

export const ProjectUsers: React.FC<ProjectUsersProps> = ({project}) => {

  return (
    <SectionLayout>
      <Grid container>
        <Grid item sm={12} className="userBody">
          <ProjectUsersDetails project={project}/>
        </Grid>
      </Grid>
    </SectionLayout>
  )
}

const mapStateToProps: any = (state: IStoreState): any => ({
  project: state.selectedProject.project,
})

export default connect(mapStateToProps, null)(ProjectUsers)
