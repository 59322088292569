import { createProcessIO } from 'src/helpers/shared'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import { EnvironmentProcessIO } from '../environment-processor'
import { createDefaultProjectEnvironmentCode } from './env-code-transformation'
import { hasUndefinedProjectEnvironmentCode } from './env-code-validation'

export interface CodeProcessContext {
  index: number
  projectName: string
  deploymentEnvironment: DeploymentEnvironment
}

export function createProjectEnvironmentWithCodeDefaults(
  input: EnvironmentProcessIO,
  context: CodeProcessContext,
): EnvironmentProcessIO {
  const result = hasUndefinedProjectEnvironmentCode(input.value)
  if (result) {
    const env = input.value
    const { deploymentEnvironment, projectName } = context
    const projectEnvironmentCode = createDefaultProjectEnvironmentCode(env, deploymentEnvironment, projectName)
    const updatedEnvironment = new ProjectEnvironment({
      projectEnvironmentCode,
      order: env.order,
      environmentId: env.environmentId,
      projectEnvironmentAssets: env.projectEnvironmentAssets,
      projectEnvironmentDatabases: env.projectEnvironmentDatabases,
      symLinks: env.symLinks,
    })
    return createProcessIO(updatedEnvironment, [...input.errors])
  }
  return input
}
