import { cloneDeep } from 'lodash'
import initialState from '../initialState'
import { ICreateProjectDataState } from './create-project-data.state'
import {
  CreateProjectDataActionTypes,
  CreateProjectDataErrorAction,
  CreateProjectDataSetProjectAction,
  CreateProjectDataSetProjectEnvironmentsAction,
  CreateProjectDataSetProjectFieldAction,
  CREATE_PROJECT_DATA_ERROR,
  CREATE_PROJECT_DATA_LOADING,
  CREATE_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS,
  CREATE_PROJECT_DATA_SET_PROJECT_FIELD,
  CREATE_PROJECT_DATA_SUCCESS,
  CREATE_PROJECT_DATA_RESET,
} from './create-project-data.types'
import { CREATE_PROJECT_DATA_SET_PROJECT } from './create-project-data.types'

export function createProjectDataReducer(
  state: ICreateProjectDataState = initialState.createProjectData,
  action: CreateProjectDataActionTypes,
): ICreateProjectDataState {
  switch (action.type) {
    case CREATE_PROJECT_DATA_RESET: {
      return initialState.createProjectData
    }
    case CREATE_PROJECT_DATA_SET_PROJECT: {
      const { payload } = action as CreateProjectDataSetProjectAction
      const updatedState = cloneDeep(state)
      updatedState.project = payload
      return updatedState
    }
    case CREATE_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS: {
      const updatedState = cloneDeep(state)
      const { payload } = action as CreateProjectDataSetProjectEnvironmentsAction
      updatedState.project.projectEnvironments = payload
      return updatedState
    }
    case CREATE_PROJECT_DATA_SET_PROJECT_FIELD: {
      const updatedState = cloneDeep(state)
      const { payload } = action as CreateProjectDataSetProjectFieldAction
      const { field, value } = payload
      updatedState.project[field] = value
      return updatedState
    }
    case CREATE_PROJECT_DATA_LOADING: {
      const updatedState = cloneDeep(state)
      updatedState.loading = true
      updatedState.error = undefined
      updatedState.errorMessage = undefined
      return updatedState
    }
    case CREATE_PROJECT_DATA_ERROR: {
      const updatedState = cloneDeep(state)
      const { payload } = action as CreateProjectDataErrorAction
      const { error, errorMessage } = payload
      updatedState.loading = false
      updatedState.error = error
      updatedState.errorMessage = errorMessage
      return updatedState
    }
    case CREATE_PROJECT_DATA_SUCCESS: {
      const updatedState = cloneDeep(state)
      updatedState.loading = false
      updatedState.error = undefined
      updatedState.errorMessage = undefined
      return updatedState
    }
    default:
      return state
  }
}
