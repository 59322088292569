import DashboardPage from './components/dashboard/DashboardPage'
import AdminPage from './components/admin/AdminPage'
import { ProjectDetailsContainer } from './components/project/project-details/project-details.container'
import { ProjectDetailsScene } from './components/project/project-details/project-details.scene'
import { ProjectCreateScene } from './components/project/project-create/project-create.scene'
import { ProjectCloneScene }  from './components/project/project-clone/project-clone.scene'

export const appStates = {
  DASHBOARD: 'DASHBOARD',
  PROJECT: 'PROJECT',
  ADMIN: 'ADMIN',
  ADMIN_ROOT: 'ADMIN_ROOT',
  EDITPROJECT: 'EDITPROJECT',
  PROJECT_DETAILS: 'SECTION',
  PROJECT_CREATE: 'PROJECT_CREATE',
  PROJECT_CLONE: 'PROJECT_CLONE'
}

export const routerStates = [
  {
    name: appStates.DASHBOARD,
    url: '/',
    component: DashboardPage,
    resolve: [
      {
        token: 'currentUser',
        deps: ['$transition$'],
        resolveFn: (trans: any) => trans.params().currentUser,
      },
    ],
    params: {
      currentUser: {
        // @ts-ignore
        value: null,
      },
    },
  },
  {
    name: appStates.PROJECT,
    url: '/projects/:projectId',
    component: ProjectDetailsContainer,
    resolve: [
      {
        token: 'projectId',
        deps: ['$transition$'],
        resolveFn: (trans: any) => trans.params().projectId,
      },
    ],
    params: {
      projectId: {
        // @ts-ignore
        value: undefined,
      },
    },
  },
  {
    name: appStates.PROJECT + '.' + appStates.PROJECT_DETAILS,
    url: '/:section',
    component: ProjectDetailsScene,
    resolve: [
      {
        token: 'section',
        deps: ['$transition$'],
        resolveFn: (trans: any) => trans.params().section,
      },
    ],
    params: {
      section: {
        value: '',
      },
    },
  },
  {
    name: appStates.ADMIN,
    url: '/admin/:pathParam',
    component: AdminPage,
    resolve: [
      {
        token: 'currentUser',
        deps: ['$transition$'],
        resolveFn: (trans: any) => trans.params().currentUser,
      },
      {
        token: 'pathParam',
        deps: ['$transition$'],
        resolveFn: (trans: any) => trans.params().pathParam,
      },
    ],
    params: {
      currentUser: {
        // @ts-ignore
        value: null,
      },
    },
  },
  {
    name: appStates.ADMIN_ROOT,
    url: '/admin',
    component: AdminPage,
    resolve: [
      {
        token: 'currentUser',
        deps: ['$transition$'],
        resolveFn: (trans: any) => trans.params().currentUser,
      },
    ],
    params: {
      currentUser: {
        value: null,
      },
    },
  },
  {
    name: appStates.PROJECT_CREATE,
    url: '/project/create',
    component: ProjectCreateScene,
    resolve: [
      {
        token: 'currentUser',
        deps: ['$transition$'],
        resolveFn: (trans: any) => trans.params().currentUser,
      },
    ],
    params: {
      currentUser: {
        // @ts-ignore
        value: null,
      },
    },
  },
  {
    name: appStates.PROJECT_CLONE,
    url: '/project/clone',
    component: ProjectCloneScene,
    resolve: [
      {
        token: 'currentUser',
        deps: ['$transition$'],
        resolveFn: (trans: any) => trans.params().currentUser,
      },
    ],
    params: {
      currentUser: {
        // @ts-ignore
        value: null,
      },
    },
  },
]
