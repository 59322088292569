import PropTypes from 'prop-types'
import React from 'react'

class Nbsp extends React.Component {
  render() {
    let {count} = this.props

    let space = ''
    do {
      space = space.concat('\u00A0')
    } while (--count)

    return <span>{space}</span>
  }
}

Nbsp.propTypes = {
  count: PropTypes.number,
}

Nbsp.defaultProps = {
  count: 1,
}

export default Nbsp
