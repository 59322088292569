import React, { useState } from 'react'
import { wordpressFormSteps, useStyles } from './create-wordpress-workflow.config'
import { FormSteps } from '../shared/form-steps.component'
import { FORM_STEP_TYPE } from '../forms'
import { ConnectedWordpressEnvironmentsForm } from './wordpress-environments.component'
import { ConnectedWordpressDetailsForm } from './wordpress-details.component'
import { ConnectedCreateProjectCodeForm } from '../forms/code-form.component'

export type WordpressCreateWorkflowProps = {}

export const WordpressCreateWorkflow: React.FC<WordpressCreateWorkflowProps> = ({}) => {
  const styles = useStyles()
  const defaultStep = wordpressFormSteps[0].step
  const [activeStep, setActiveStep] = useState<FORM_STEP_TYPE>(defaultStep)
  return (
    <div className={styles.rootContainer}>
      <div className={styles.stepContainer}>
        <FormSteps activeStep={activeStep} formSteps={wordpressFormSteps} />
      </div>
      <WordpressCreateForm activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )
}

export interface WordpressCreateFormProps {
  activeStep: FORM_STEP_TYPE
  setActiveStep: (step: FORM_STEP_TYPE) => void
}

export const WordpressCreateForm: React.FC<WordpressCreateFormProps> = ({ activeStep, setActiveStep }) => {
  switch (activeStep) {
    case FORM_STEP_TYPE.DETAILS:
      return <ConnectedWordpressDetailsForm setActiveStep={setActiveStep} />
    case FORM_STEP_TYPE.ENV:
      return <ConnectedWordpressEnvironmentsForm setActiveStep={setActiveStep} />
    case FORM_STEP_TYPE.CODE:
      return <ConnectedCreateProjectCodeForm />
    default:
      return <ConnectedWordpressDetailsForm setActiveStep={setActiveStep} />
  }
}
