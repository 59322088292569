import ProjectHelper from 'src/helpers/ProjectHelper'
import {
  appendNumberByAssociationId,
  replaceSpaceWithUnderscore,
  resolveDefaultDeploymentTargetGroupId,
} from 'src/helpers/shared'
import Database, { DATABASE_TYPE } from 'src/models/Database'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import ProjectEnvironmentDatabases from 'src/models/ProjectEnvironmentDatabases'

const searchString = 'database'

export function createDefaultProjectEnvironmentDatabases(
  deploymentEnvironment: DeploymentEnvironment,
  projectName: string,
  associationIds: number[],
): ProjectEnvironmentDatabases {
  const deploymentTargetGroupId = resolveDefaultDeploymentTargetGroupId(deploymentEnvironment, searchString)
  const databases = associationIds.map((id: number) => {
    return createDefaultDatabaseGroup(id, projectName, deploymentEnvironment)
  })
  return new ProjectEnvironmentDatabases({
    databases,
    deploymentTargetGroupId,
  })
}

export function createDefaultDatabaseGroup(
  associationId: number,
  projectName: string,
  deploymentEnvironment: DeploymentEnvironment,
): Database {
  const envName = replaceSpaceWithUnderscore(deploymentEnvironment.title)
  const isProd = deploymentEnvironment.isProd || false
  const databaseName: string = resolveDatabaseName(projectName, envName, isProd, associationId)
  return new Database({
    associationId,
    name: databaseName,
    username: databaseName,
    password: ProjectHelper.getRandomDatabasePassword(),
    allowedIP: null,
    databaseType: DATABASE_TYPE.MYSQL,
  })
}

export function resolveDatabaseName(
  projectName: string,
  envName: string,
  isProd: boolean,
  associationId: number,
): string {
  projectName = removeSpecialCharacters(projectName)
  const databaseName = isProd ? projectName : createUnderscoreDatabaseName(projectName, envName, associationId)
  return databaseName.toLowerCase()
}

export function createUnderscoreDatabaseName(
  projectName: string,
  environmentTitle: string,
  associationId: number,
): string {
  const baseText = `${environmentTitle}_${projectName}`
  if (associationId > 0) {
    const databaseName = appendNumberByAssociationId(baseText, associationId)
    return databaseName.toLowerCase()
  }
  return baseText.toLowerCase()
}

function removeSpecialCharacters (str: string) {
  return str.replace(/([^a-z_0-9]+)/gi, '');
}
