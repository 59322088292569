import {Dispatch} from 'redux'
import {createMiddleware, MiddlewareFn, registerMiddleware} from '../store/middlewares'
import ProjectType from '../models/ProjectType'
import ActionTypes from '../store/actions'
import IStoreState from '../store/IStoreState'
import {PROJECT_TYPE} from '../models/Project'

class ProjectTypeHelper {
  static getProjectTypeDisplayByValue(value: PROJECT_TYPE): string {
    const type: ProjectType = ProjectTypeHelper.projectTypes.find((projectType: ProjectType) => value === projectType.value)
    return type ? type.displayName : null
  }

  static handleProjectTypesAction: MiddlewareFn = (action: any, state: IStoreState, next: Dispatch) => {
    if (action.type === ActionTypes.PROJECT_TYPES_LOAD_SUCCESS) {
      ProjectTypeHelper.projectTypes = action.payload.projectTypes
    }

    return next(action)
  }

  static getProjectTypes(): ProjectType[] {
    return ProjectTypeHelper.projectTypes
  }

  private static projectTypes: ProjectType[] = []
}

registerMiddleware(createMiddleware(ProjectTypeHelper.handleProjectTypesAction))

export default ProjectTypeHelper
