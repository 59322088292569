import React from 'react'
import PropTypes from 'prop-types'
import {Button, Tooltip} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFolderOpen} from '@fortawesome/free-solid-svg-icons'
import Project from '../../../../../models/Project'
import {ACTION_TYPE} from '../../../../../models/pipeline/Action'
import {PermissionHelper} from '../../../../../helpers/PermissionHelper'
import NoPermissionButton from '../NoPermissionButton'
import '../DeploymentTrack.scss'
import DeploymentEnvironmentHelper from "../../../../../helpers/DeploymentEnvironmentHelper";

export interface MergeAssetsButtonProps {
  project: Project
  environmentId: string
  handleMergeAssetsClick: any
  disableButtons: boolean
  initializationRequired: boolean
}

class MergeAssetsButton extends React.Component<MergeAssetsButtonProps, {}> {
  render() {
    const {project, environmentId, handleMergeAssetsClick, disableButtons, initializationRequired} = this.props
    const buttonText = initializationRequired ? 'Initialize' : 'Merge'

    if (PermissionHelper.hasPermissionForAction(ACTION_TYPE.ASSET_MERGE, project, environmentId)) {
      return (
        <Tooltip title={disableButtons ? DeploymentEnvironmentHelper.getInactiveEnvironmentTooltipTitle(environmentId) : ''}>
          <span>
            <Button onClick={handleMergeAssetsClick}
                    variant='contained'
                    color='primary'
                    disabled={disableButtons}>
              <FontAwesomeIcon icon={faFolderOpen} className='buttonIcon'/> {buttonText}
            </Button>
          </span>
        </Tooltip>
      )
    }

    return <NoPermissionButton icon={faFolderOpen} text={buttonText}/>
  }
}

// @ts-ignore
MergeAssetsButton.propTypes = {
  handleMergeAssetsClick: PropTypes.func.isRequired,
  environmentId: PropTypes.string.isRequired,
  project: PropTypes.instanceOf(Project).isRequired,
  initializationRequired: PropTypes.bool
}

// @ts-ignore
MergeAssetsButton.defaultProps = {
  initializationRequired: false
}

export default MergeAssetsButton
