import {Expose, Type} from 'class-transformer'
import {assign, find, keys} from 'lodash'
import 'reflect-metadata'
import ProjectEnvironment from './ProjectEnvironment'
import CodeRepository from './CodeRepository'

const MERGE_CODE_LINK_PREFIX: string = 'MERGE-CODE'
const CHECKOUT_CODE_LINK_PREFIX: string = 'CHECKOUT-CODE'
const MERGE_ASSETS_LINK_PREFIX: string = 'MERGE-ASSETS'
const COPY_DATABASE_LINK_PREFIX: string = 'COPY-DATABASE'
const DATABASE_SNAPSHOT_LINK_PREFIX: string = 'DATABASE-SNAPSHOT'
const FILE_TEMPLATE_LINK_PREFIX: string = 'FILE-TEMPLATE'

export enum PROJECT_TYPE {
  DRUPAL_7 = 'DRUPAL_7',
  DRUPAL_8 = 'DRUPAL_8',
  DRUPAL_9 = 'DRUPAL_9',
  WORDPRESS = 'WORDPRESS',
  OTHER = 'OTHER',
  WORKFLOW = 'WORKFLOW',
}

export enum PROJECT_STATUS {
  INCOMPLETE = 'INCOMPLETE',
  AWAITING_INITIAL_DEPLOYMENT = 'AWAITING_INITIAL_DEPLOYMENT',
  AWAITING_REPO_INITIALIZATION = 'AWAITING_REPO_INITIALIZATION',
  ACTIVE = 'ACTIVE'
}

export enum PROJECT_ERROR {
  PROJECT_NOT_FOUND = 'PROJECT_NOT_FOUND',
  GIT_REPOSITORY_NOT_SAVED = 'GIT_REPOSITORY_NOT_SAVED',
  PROJECT_EXISTS = 'PROJECT_EXISTS',
  NO_PROJECT_NAME = 'NO_PROJECT_NAME',
  NO_PROJECT_TYPE = 'NO_PROJECT_TYPE',
  DOMAIN_CONTAINS_PROTOCOL = 'DOMAIN_CONTAINS_PROTOCOL',
  DUPLICATE_USER_NAMES = 'DUPLICATE_USER_NAMES',
  DUPLICATE_DATABASE_NAMES = 'DUPLICATE_DATABASE_NAMES',
  DEV_ENVIRONMENT_DOES_NOT_EXIST = 'DEV_ENVIRONMENT_DOES_NOT_EXIST',
  PROD_ENVIRONMENT_DOES_NOT_EXISTS = 'PROD_ENVIRONMENT_DOES_NOT_EXISTS',
  BRANCH_DOES_NOT_EXIST = 'BRANCH_DOES_NOT_EXIST',
  NO_BRANCH_NAME = 'NO_BRANCH_NAME',
  NO_DEPLOYMENT_TARGET_ID_FOR_PROJECT_ENVIRONMENT_CODE = 'NO_DEPLOYMENT_TARGET_ID_FOR_PROJECT_ENVIRONMENT_CODE',
  NO_WEB_ROOT = 'NO_WEB_ROOT',
  PROJECT_DOES_NOT_HAVE_ENOUGH_ENVIRONMENTS = 'PROJECT_DOES_NOT_HAVE_ENOUGH_ENVIRONMENTS',
  USER_SERVICE_UNAVAILABLE = 'USER_SERVICE_UNAVAILABLE',
  DUPLICATE_ASSET_PATHS = 'DUPLICATE_ASSET_PATHS',
  ASSET_PATH_FOUND_IN_CODE_WEB_ROOT = 'ASSET_PATH_FOUND_IN_CODE_WEB_ROOT',
  NO_DATABASE_NAME = 'NO_DATABASE_NAME',
  NO_DATABASE_USERNAME = 'NO_DATABASE_USERNAME',
  NO_DATABASE_PASSWORD = 'NO_DATABASE_PASSWORD',
  PROJECT_ID_DOES_NOT_MATCH_UPDATED_PROJECT = 'PROJECT_ID_DOES_NOT_MATCH_UPDATED_PROJECT',
  INVALID_BRANCH_NAME = 'INVALID_BRANCH_NAME',
  INVALID_CONSECUTIVE_BRANCH = 'INVALID_CONSECUTIVE_BRANCH',
  DUPLICATE_BRANCH_NAME = 'DUPLICATE_BRANCH_NAME',
  DUPLICATE_REPOSITORY_PROJECT_NAME = 'DUPLICATE_REPOSITORY_PROJECT_NAME',
  DUPLICATE_REPOSITORY_GROUP = 'DUPLICATE_REPOSITORY_GROUP',
  INVALID_WEB_ROOT = 'INVALID_WEB_ROOT',
  NO_ASSET_GROUP_PATH = 'NO_ASSET_GROUP_PATH',
  NO_ASSET_GROUP_TITLE = 'NO_ASSET_GROUP_TITLE',
  ASSET_GROUP_HAS_NO_ASSOCIATION_ID = 'ASSET_GROUP_HAS_NO_ASSOCIATION_ID',
  DATABASE_HAS_NO_ASSOCIATION_ID = 'DATABASE_HAS_NO_ASSOCIATION_ID',
  NO_SYM_LINK_FROM_PATH = 'NO_SYM_LINK_FROM_PATH',
  NO_SYM_LINK_TO_PATH = 'NO_SYM_LINK_TO_PATH',
  SYM_LINK_FROM_PATH_NOT_WITHIN_WEB_ROOT = 'SYM_LINK_FROM_PATH_NOT_WITHIN_WEB_ROOT',
  NO_SYM_LINK_FOR_ASSET_PATH_OUTSIDE_WEB_ROOT = 'NO_SYM_LINK_FOR_ASSET_PATH_OUTSIDE_WEB_ROOT',
  SYM_LINK_FROM_PATH_FOUND_AS_AN_ENV_WEB_ROOT = 'SYM_LINK_FROM_PATH_FOUND_AS_AN_ENV_WEB_ROOT',
  SYM_LINK_TO_PATH_FOUND_AS_AN_ENV_WEB_ROOT = 'SYM_LINK_TO_PATH_FOUND_AS_AN_ENV_WEB_ROOT',
  SYM_LINK_TO_PATH_FOUND_AS_ASSET_GROUP_PATH = 'SYM_LINK_TO_PATH_FOUND_AS_ASSET_GROUP_PATH',
  SYM_LINK_FROM_PATH_FOUND_AS_FROM_PATH_IN_OTHER_SYM_LINK = 'SYM_LINK_FROM_PATH_FOUND_AS_FROM_PATH_IN_OTHER_SYM_LINK',
  SYM_LINK_TO_PATH_FOUND_AS_TO_PATH_IN_OTHER_SYM_LINK = 'SYM_LINK_TO_PATH_FOUND_AS_TO_PATH_IN_OTHER_SYM_LINK'
}


class Project {
  @Expose({name: '_id'})
  id: string
  name: string
  domain: string
  hasCode: boolean
  hasDatabases: boolean
  hasAssets: boolean
  disabled: boolean
  type: PROJECT_TYPE
  status: PROJECT_STATUS
  @Type(() => CodeRepository)
  codeRepository: CodeRepository
  @Type(() => ProjectEnvironment)
  projectEnvironments: ProjectEnvironment[]
  _links: object

  constructor(props?: Partial<Project>) {
    props = props || {}
    const defaultProps: Partial<Project> = {
      id: undefined,
      name: undefined,
      domain: undefined,
      type: undefined,
      status: undefined,
      codeRepository: undefined,
      projectEnvironments: [],
      hasCode: undefined,
      hasDatabases: undefined,
      hasAssets: undefined,
      disabled: undefined,
      _links: undefined
    }
    if (props.projectEnvironments) {
      props.projectEnvironments = props.projectEnvironments.map((envJSON: ProjectEnvironment): ProjectEnvironment => new ProjectEnvironment(envJSON))
    }
    assign(this, defaultProps, props)
  }

  environmentInitializedForCode(environmentId: string): boolean {
    return this.hasCode && this.getProjectEnvironmentForId(environmentId).projectEnvironmentCode.initialized
  }

  environmentInitializedForAssets(environmentId: string): boolean {
    return this.hasAssets && this.getProjectEnvironmentForId(environmentId).projectEnvironmentAssets.initialized
  }

  environmentInitializedForDatabases(environmentId: string): boolean {
    return this.hasDatabases && this.getProjectEnvironmentForId(environmentId).projectEnvironmentDatabases.initialized
  }

  canMergeCodeFrom(sourceEnv: string): boolean {
    return this.hasLinkForAuthPattern(`${MERGE_CODE_LINK_PREFIX}_${sourceEnv}_.+`)
  }

  canMergeCodeTo(targetEnv: string): boolean {
    return this.hasLinkForAuthPattern(`${MERGE_CODE_LINK_PREFIX}_.+_${targetEnv}`)
  }

  canDeployCode(targetEnv: string): boolean {
    return this.hasLinkForAuth(`${CHECKOUT_CODE_LINK_PREFIX}_${targetEnv}`)
  }

  canMergeAssetsFrom(sourceEnv: string): boolean {
    return this.hasLinkForAuthPattern(`${MERGE_ASSETS_LINK_PREFIX}_${sourceEnv}_.+`)
  }

  canMergeAssetsTo(targetEnv: string): boolean {
    return this.hasLinkForAuthPattern(`${MERGE_ASSETS_LINK_PREFIX}_.+_${targetEnv}`)
  }

  canCopyDatabaseFrom(sourceEnv: string): boolean {
    return this.hasLinkForAuthPattern(`${COPY_DATABASE_LINK_PREFIX}_${sourceEnv}_.+`)
  }

  canCopyDatabaseTo(targetEnv: string): boolean {
    return this.hasLinkForAuthPattern(`${COPY_DATABASE_LINK_PREFIX}_.+_${targetEnv}`)
  }

  canTakeDatabaseSnapshot(targetEnv: string): boolean {
    return this.hasLinkForAuth(`${DATABASE_SNAPSHOT_LINK_PREFIX}_${targetEnv}`)
  }

  canRunFileTemplate(targetEnv: string): boolean {
    return this.hasLinkForAuth(`${FILE_TEMPLATE_LINK_PREFIX}_${targetEnv}`)
  }

  hasLinkForAuth(linkForAuth: string): boolean {
    return !!this._links[linkForAuth]
  }

  hasLinkForAuthPattern(linkForAuthPattern: string): boolean {
    const linkRegexPattern: RegExp = new RegExp(linkForAuthPattern)
    return !!keys(this._links).filter((rel: string) => linkRegexPattern.test(rel)).length
  }

  canMergeEnvironments(sourceEnv: ProjectEnvironment, targetEnv: ProjectEnvironment): boolean {
    const firstTagBasedEnv: ProjectEnvironment = this.projectEnvironments
      .filter((env: ProjectEnvironment) => this.isEnvironmentTagBased(env))
      .sort((a: ProjectEnvironment, b: ProjectEnvironment) => a.order - b.order)[0]
    return !this.isEnvironmentTagBased(targetEnv) || firstTagBasedEnv.environmentId === targetEnv.environmentId
  }

  isEnvironmentTagBased(environment: ProjectEnvironment): boolean {
    let tagBased: boolean = false
    const previousEnv: ProjectEnvironment = this.projectEnvironments.find((projEnv: ProjectEnvironment) => projEnv.order === environment.order - 1)
    if (previousEnv && environment.projectEnvironmentCode.branch.name === previousEnv.projectEnvironmentCode.branch.name) {
      tagBased = true
    }
    const nextEnv: ProjectEnvironment = this.projectEnvironments.find((projEnv: ProjectEnvironment) => projEnv.order === environment.order + 1)
    if (nextEnv && environment.projectEnvironmentCode.branch.name === nextEnv.projectEnvironmentCode.branch.name) {
      tagBased = true
    }
    return tagBased
  }

  getAssetTargetGroupIdByEnv(environmentId: string): string {
    return this.getProjectEnvironmentForId(environmentId).projectEnvironmentAssets.deploymentTargetGroupId
  }

  getDatabaseTargetGroupIdByEnv(environmentId: string): string {
    return this.getProjectEnvironmentForId(environmentId).projectEnvironmentDatabases.deploymentTargetGroupId
  }

  getCodeDeploymentGroupIdByEnv(environmentId: string): string {
    return this.getProjectEnvironmentForId(environmentId).projectEnvironmentCode.deploymentTargetGroupId
  }

  private getProjectEnvironmentForId(environmentId: string): ProjectEnvironment {
    return find(this.projectEnvironments, {environmentId})
  }
}

export default Project
