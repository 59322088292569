import React from 'react'
import PropTypes from 'prop-types'

import {Button, Grid, IconButton, Switch} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes, faTrash} from '@fortawesome/free-solid-svg-icons'

import EditUserTabMenu from './EditUserTabMenu'
import UserService from '../../../services/UserService'
import messageService from '../../../services/MessageService'
import SnackbarService, {SNACKBAR_TYPES} from '../../../services/SnackbarService'

import {assign, findIndex, reduce} from 'lodash'
import User from '../../../models/User'
import ConfirmDialog from '../../common/ConfirmDialog.tsx'
import Nbsp from '../../common/Nbsp'
import {CUSTOM, PRIMARY} from "../../../theme/lib/color"
import {style} from '@material-ui/system'
import './EditUser.scss'

const SYSTEM_ROLE_NAME = 'agility_admin'

class EditUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rolesLoaded: false,
      roleGroupsLoaded: false,
      rolesByProject: {},
      systemRole: null,
      userHasSystemRole: false,
      userRolesAndRoleGroups: {},
      userRoleGroups: [],
      roleGroups: [],
      deleteUser: false,
      error: false,
    }

    this.handleDelete = this.handleDelete.bind(this)
  }

  componentWillMount = () => {
    this.loadUserRolesAndRoleGroups()
  }

  loadUserRolesAndRoleGroups = () => {
    UserService.getUserRolesAndRoleGroups(this.props.user.username)
      .then(this.processUserRolesAndRoleGroups)
      .catch(() => this.setState({error: true}))
  }

  processUserRolesAndRoleGroups = (userRolesAndRoleGroups) => {
    this.setState({userRolesAndRoleGroups: userRolesAndRoleGroups})
    this.loadRoles()
    this.loadRoleGroups()
  }

  loadRoles = () => {
    UserService.getRoles()
      .then(this.processRoles)
      .catch(() => this.setState({error: true, rolesLoading: false}))
  }

  loadRoleGroups = () => {
    UserService.getRoleGroups()
      .then(this.processGroups)
      .catch(() => this.setState({error: true, roleGroupsLoading: false}))
  }

  reloadData = () => {
    this.setState({error: false})
    this.loadUserRolesAndRoleGroups()
  }

  processRoles = (roles) => {
    let systemRole = null
    let userHasSystemRole = false
    let rolesByProject = roles.reduce((currentRolesByProject, role) => {
      if (!role.projectId && role.name === SYSTEM_ROLE_NAME) {
        systemRole = role
        userHasSystemRole = this.state.userRolesAndRoleGroups.roles.some((userRole) => userRole.id === role.id)
      } else {
        if (!currentRolesByProject[role.projectId]) {
          currentRolesByProject[role.projectId] = []
        }
        currentRolesByProject[role.projectId].push({
          role,
          userHasRole: this.state.userRolesAndRoleGroups.roles.some((userRole) => userRole.id === role.id),
          roleInGroup: this.state.userRolesAndRoleGroups.roleGroups.some((roleGroup) => roleGroup.roles.some((userRole) => userRole.id === role.id)),
        })
      }
      return currentRolesByProject
    }, {})

    this.setState({rolesByProject, systemRole, userHasSystemRole, roles, rolesLoaded: true})
  }

  processGroups = (groups) => {
    this.setState({roleGroups: groups})
    const userRoleGroups = groups.reduce((currentUserRoleGroups, group) => {
      currentUserRoleGroups.push({
        group,
        userHasGroup: this.state.userRolesAndRoleGroups.roleGroups.some((roleGroup) => roleGroup.id === group.id),
      })
      return currentUserRoleGroups
    }, [])

    this.setState({userRoleGroups, roleGroupsLoaded: true})
  }

  getUpdatedUserRoles = () => {
    return reduce(this.state.rolesByProject, (currentRolesArray, userRoles) => {
      const newRoles = userRoles
        .filter((userRole) => userRole.userHasRole)
        .map((userRole) => userRole.role)
      return currentRolesArray.concat(...newRoles)
    }, [])
  }

  getUpdatedUserRoleGroups = () => {
    return this.state.userRoleGroups
      .filter((userRoleGroup) => userRoleGroup.userHasGroup)
      .map((userRoleGroup) => userRoleGroup.group)
  }

  async handleDelete() {
    this.props.handleModalClose()
    try {
      const username = this.props.user.username
      await UserService.deleteUser(username)
      this.props.handleDeletedUser(username)
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'User deleted.')
    } catch (err) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to delete user!')
    }
  }

  toggleDeleteConfirmModal = () => {
    this.setState({deleteUser: !this.state.deleteUser})
  }

  handleSave = () => {
    const updatedUser = {
      username: this.props.user.username,
      roles: this.getUpdatedUserRoles(),
      roleGroups: this.getUpdatedUserRoleGroups(),
    }
    if (this.state.userHasSystemRole) {
      updatedUser.roles.push(this.state.systemRole)
    }
    UserService.updateUser(updatedUser)
      .then((savedUser) => {
        this.props.handleSavedUser(savedUser)
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'User saved.')
      })
      .catch(() => {
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to save user!')
      })
    this.props.handleModalClose()
  }

  handleAdminToggle = () => {
    let userRoleGroups = this.state.userRoleGroups
    let adminRoleGroupIndex = userRoleGroups.findIndex(roleGroup => roleGroup.group.id === "GLOBAL_ADMINISTRATOR_GROUP")
    userRoleGroups[adminRoleGroupIndex].userHasGroup = !this.state.userHasSystemRole
    this.setState({userHasSystemRole: !this.state.userHasSystemRole})
    this.updateRolesWithNewGroups(userRoleGroups)
  }

  handleCheckboxChange = (isChecked, projectId, roleName) => {
    const {rolesByProject} = this.state
    const roleToUpdate = rolesByProject[projectId].find((userRole) => userRole.role.name === roleName)
    if (roleToUpdate) {
      roleToUpdate.userHasRole = isChecked
    }
    this.setState({rolesByProject})
  }

  handleUserRoleGroupChange = (group) => {
    const {userRoleGroups} = this.state
    userRoleGroups.splice(findIndex(userRoleGroups, (userRoleGroup) => {
      return userRoleGroup.group.id === group.group.id
    }), 1, group)
    this.updateRolesWithNewGroups(userRoleGroups)
  }

  updateRolesWithNewGroups = (userRoleGroups) => {
    const rolesByProject = reduce(this.state.rolesByProject, (currentRolesByProject, userRoles, projectId) => {
      userRoles.forEach((userRole) => {
        userRole.roleInGroup = this.isRoleInGroups(userRole, userRoleGroups)
      })
      currentRolesByProject[projectId] = userRoles
      return currentRolesByProject
    }, {})
    this.setState({rolesByProject, userRoleGroups})
  }

  isRoleInGroups = (userRole, userRoleGroups) => {
    return userRoleGroups
      .filter((userRoleGroup) => userRoleGroup.userHasGroup)
      .some((group) => {
        return group.group.roles.some((role) => userRole.role.id === role.id)
      })
  }

  handleUserSelectAllChange = (roleName, selected) => {
    const rolesByProject = reduce(this.state.rolesByProject, (currentRolesByProject, userRoles, projectId) => {
      userRoles.forEach((userRole) => {
        userRole.userHasRole = roleName == userRole.role.name && !userRole.role.roleInGroup ? selected : userRole.userHasRole
      })
      currentRolesByProject[projectId] = userRoles
      return currentRolesByProject
    }, {})
    this.setState({rolesByProject})
  }

  render() {
    const sliderTextStyle = assign({color: PRIMARY["600"], fontSize: '12pt'}, style)
    const userTextStyle = assign({color: PRIMARY["600"], fontSize: '10pt'}, style)
    const sliderButtonStyle = assign({color: CUSTOM.gray["200"], fontSize: '12pt'}, style)
    const closeButtonStyle = assign({color: PRIMARY["600"], fontSize: '14pt'}, style)
    const deleteConfirmMessage = messageService.get('admin.users.deleteConfirmation', {
      username: this.props.user.username,
    })

    return (
      <div>
        <Grid container direction='column' wrap='nowrap'>
          <Grid item style={{flex: 'none'}}>
            <Grid container alignItems="center">
              <Grid item sm={8}>
                <Grid container alignItems="center">
                  <Grid item>
                    <h2 style={{
                      marginTop: '6px',
                      marginBottom: '12px'
                    }}>{this.props.user.name.givenName} {this.props.user.name.familyName}'s Projects <Nbsp/></h2>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={4} className='right'>
                <IconButton id='user-close-button'
                            style={closeButtonStyle} title='Close'
                            onClick={this.props.handleModalClose}>
                  <FontAwesomeIcon
                    icon={faTimes} size='sm'
                    className={'clickable'}/>
                </IconButton>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item sm={8}>
                <Grid container alignItems="center">
                  <Grid item>
                    <div style={userTextStyle}>{"Username: " + this.props.user.username}</div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={4} className='right'>
                {
                  this.state.systemRole &&
                  <span style={sliderTextStyle}>
                  <Switch style={sliderButtonStyle} checked={this.state.userHasSystemRole}
                          onChange={this.handleAdminToggle}/>System Admin
                </span>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} className='edit-user-item'>
            <EditUserTabMenu loading={!this.state.rolesLoaded || !this.state.roleGroupsLoaded}
                             user={this.props.user}
                             rolesByProject={this.state.rolesByProject}
                             handleCheckboxChange={this.handleCheckboxChange}
                             handleUserRoleGroupChange={this.handleUserRoleGroupChange}
                             handleUserSelectAllChange={this.handleUserSelectAllChange}
                             userRoleGroups={this.state.userRoleGroups}
                             reloadData={this.reloadData}
            />
          </Grid>

          <Grid item sm={12} style={{flex: 'none', marginTop: '1rem'}}>
            <Grid container>
              <Grid item sm={6}>
                <IconButton style={{marginBottom: '4px'}} title="Delete" onClick={this.toggleDeleteConfirmModal}>
                  <FontAwesomeIcon icon={faTrash} size='sm' style={{cursor: 'pointer', padding: '0'}}/>
                </IconButton>
              </Grid>
              <Grid item sm={6} className='right'>
                <Button onClick={this.handleSave} variant="contained" color="primary">Save</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ConfirmDialog
          open={this.state.deleteUser}
          message={deleteConfirmMessage}
          onConfirm={this.handleDelete}
          onCancel={this.toggleDeleteConfirmModal}
        />
      </div>

    )
  }
}

EditUser.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  handleSavedUser: PropTypes.func.isRequired,
  handleDeletedUser: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
}

EditUser.defaultProps = {
  name: {
    givenName: '',
    familyName: '',
  },
}

export default EditUser

