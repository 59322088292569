import 'reflect-metadata'
import {Type} from 'class-transformer'
import {assign} from 'lodash'
import PipelineTrigger from './PipelineTrigger'

export class PipelineTriggerGroup {
  @Type(() => PipelineTrigger)
  triggers: PipelineTrigger[]

  constructor(props?: Partial<PipelineTriggerGroup>) {
    props = props || {}

    const defaultProps: Partial<PipelineTriggerGroup> = {
      triggers: []
    }
    assign(this, defaultProps, props)
  }
}

export default PipelineTriggerGroup
