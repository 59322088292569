import React from 'react'
import UsersSection from './users/UsersSection'
import { Grid, Tab, Tabs } from '@material-ui/core'
import DeploymentTargetsSection from './deploymentTargets/DeploymentTargetsSection'
import User from '../../models/User'
import SubscriptionSection from './subscription/SubscriptionSection'
import DeploymentEnvironmentsSection from './deploymentEnvironments/DeploymentEnvironmentsSection'
import IStoreState from '../../store/IStoreState'
import { connect } from 'react-redux'
import { ADMIN_TAB_PATHS, PathParam } from '../layout/header/header.config'
import { Breadcrumb } from '../project/project-details/shared/breadcrumb.component'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { HeaderTabs } from '../layout/header/Header.component'
import ActionTypes from "../../store/actions";
import {Dispatch} from "redux";

export interface IAdminPageProps {
  currentUser?: User,
  pathParam?: string,
  setHeaderTab: () => void
}

export interface IAdminPageState {
  selectedTab: PathParam
}

function navigateToTab(tabPathParam: string): void {
  window.history.pushState(null, 'Title', '/admin/' + tabPathParam)
}

export class AdminPage extends React.Component<IAdminPageProps, IAdminPageState> {
  constructor(props: IAdminPageProps) {
    super(props)
    this.state = {
      selectedTab: null,
    }
  }

  componentWillMount() {
    const { currentUser, pathParam } = this.props
    const allowedTabs = resolveAllowedTabs(currentUser)
    const landingTab = resolveLandingTab(pathParam as PathParam, allowedTabs)
    
    if (landingTab) {
      this.setSelectedTab(landingTab)
    }
  }

  setSelectedTab = (tab: PathParam) => {
    this.setState({ selectedTab: tab })
    navigateToTab(tab)
  }

  render() {
    const { currentUser, setHeaderTab } = this.props
    const { selectedTab } = this.state
    const { ENVIRONMENTS, DEPLOYMENT_TARGET, USERS, SUBSCRIPTION } = ADMIN_TAB_PATHS
    const showEnvTab = currentUser.canManageDeploymentEnvironment()
    const showDeploymentTargetsTab = currentUser.canManageDeploymentTarget()
    const showUsersTab = currentUser.canManageUser()
    const showSubscriptionTab = currentUser.canManageBundle()
    const allowedTabs = resolveAllowedTabs(currentUser)
    const activeTabIndex = resolveActiveTabIndex(selectedTab, allowedTabs)

    return (
      <Grid container direction='column' className='page-wide'>
        <Grid item xs={12}>
          <Breadcrumb context={'Dashboard'} path={'Administration'} icon={faHome} background={'White'} resetForm={setHeaderTab}/>
          <h1>Administration</h1>
        </Grid>
        <Grid item xs={12}>
          <Tabs value={activeTabIndex} indicatorColor='primary' textColor='primary'>
            {showEnvTab &&
              <Tab label='Environments' onClick={() => this.setSelectedTab(ENVIRONMENTS)} />}
            {showDeploymentTargetsTab &&
              <Tab label='Deployment Targets' onClick={() => this.setSelectedTab(DEPLOYMENT_TARGET)} />}
            {showUsersTab &&
              <Tab label='Users' onClick={() => this.setSelectedTab(USERS)} />}
            {showSubscriptionTab &&
              <Tab label='Subscription' onClick={() => this.setSelectedTab(SUBSCRIPTION)} />}
          </Tabs>
        </Grid>
        <Grid item xs={12} className='tabContainer'>
          {(selectedTab === ENVIRONMENTS && showEnvTab) &&
            <DeploymentEnvironmentsSection />}
          {(selectedTab === DEPLOYMENT_TARGET && showDeploymentTargetsTab) &&
            <DeploymentTargetsSection currentUser={currentUser} />}
          {(selectedTab === USERS && showUsersTab) && <UsersSection />}
          {(selectedTab === SUBSCRIPTION && showSubscriptionTab) && <SubscriptionSection />}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IAdminPageProps): any => ({
  currentUser: state.currentUser
})

/**
 * The order of tabs being added corresponds to
 * the way the tabs are shown and rendered.
 */
function resolveAllowedTabs(currentUser: User): PathParam[] {
  const allowedTabs: PathParam[] = []
  if (currentUser.canManageDeploymentEnvironment()) {
    allowedTabs.push(ADMIN_TAB_PATHS.ENVIRONMENTS)
  }
  if (currentUser.canManageDeploymentTarget()) {
    allowedTabs.push(ADMIN_TAB_PATHS.DEPLOYMENT_TARGET)
  }
  if (currentUser.canManageUser()) {
    allowedTabs.push(ADMIN_TAB_PATHS.USERS)
  }
  if (currentUser.canManageBundle()) {
    allowedTabs.push(ADMIN_TAB_PATHS.SUBSCRIPTION)
  }
  return allowedTabs
}

function resolveLandingTab(pathParam: PathParam, allowedTabs: PathParam[]): PathParam {
  if (allowedTabs.includes(pathParam)) {
    return pathParam
  }
  return allowedTabs[0] || ''
}

function resolveActiveTabIndex(selectedTab: PathParam, allowedTabs: PathParam[]): number {
  const index = allowedTabs.indexOf(selectedTab)
  return index >= 0 ? index : 0
}

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  setHeaderTab: () => dispatch({type: ActionTypes.HEADER_SET_ACTIVE_TAB, payload: {tab: HeaderTabs.Projects}})
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPage)
