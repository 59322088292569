import React from 'react'
import { Box, Divider, List } from '@material-ui/core'
import { MenuItem } from './menu-item.component'
import { makeSettingBarStyles } from './menu.config'
import { MenuSection, MenuEntryType, MenuEntryConfig } from './section-menu.config'

const useStyles = makeSettingBarStyles('200px')

export interface MenuOpenProps {
  entries: MenuEntryConfig[]
  activeSection: MenuSection
  setActiveSection: (section: MenuSection) => void
}

export const MenuOpen: React.FC<MenuOpenProps> = ({ setActiveSection, activeSection, entries }) => {
  const classes = useStyles()
  const renderMenuItem = (config: MenuEntryConfig, key: any) => {
    const { type } = config
    switch (type) {
      case MenuEntryType.Entry:
        const isSelected = config.section === activeSection
        return (
          <MenuItem key={key} config={config} onClick={setActiveSection} isSelected={isSelected} showLabel={true} />
        )
      case MenuEntryType.Divider:
        return <Divider key={key} />
      default:
        return null
    }
  }
  return (
    <Box boxShadow={2} className={classes.root}>
      <List>
        {entries.map((config: MenuEntryConfig, i) => {
          return renderMenuItem(config, i)
        })}
      </List>
    </Box>
  )
}
