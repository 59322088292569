import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { SceneLayout } from './scene.layout'
import { Grid } from '@material-ui/core'
import { CUSTOM } from '../../../theme/lib/color/custom.color'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      backgroundColor: CUSTOM.blue[800],
      height: '405px',
      minHeight: '405px',
      paddingTop: theme.spacing(13),
    },
  }),
)
export const PageLayout: React.FC = ({ children }) => {
  const styles = useStyles()
  return (
    <>
      <Grid container direction="column" spacing={0}>
        <Grid item xs className={styles.banner}>
          <SceneLayout>{children}</SceneLayout>
        </Grid>
      </Grid>
    </>
  )
}
