import * as React from 'react'
import {Button, Grid} from '@material-ui/core'
import logo from './images/logo.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {SUPPORT_URL} from './config/links.config'
import './AppErrorBoundary.scss'

interface IAppErrorBoundaryProps {

}

interface IAppErrorBoundaryState {
  error: Error,
  info: React.ErrorInfo
}

class AppErrorBoundary extends React.Component<IAppErrorBoundaryProps, IAppErrorBoundaryState> {
  constructor(props: IAppErrorBoundaryProps) {
    super(props)
    this.state = {
      error: null,
      info: null,
    }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    // NOTE: This is a catch-all error boundary but we should add error boundaries at lower level components where possible
    // https://reactjs.org/docs/error-boundaries.html
    this.setState({error, info})
  }

  render(): React.ReactNode {
    if (this.state.error) {
      return (
        <div className='app-error-boundary'>
          <Grid container className='full-height' direction='column' spacing={5} alignItems='center' justify='center' wrap='nowrap'>
            <Grid item>
              <img src={logo} className='logo'/>
            </Grid>
            <Grid item>
              <FontAwesomeIcon icon={faExclamationTriangle} size='4x' />
            </Grid>
            <Grid item className='error-message'>
              Unfortunately an error has occurred while rendering the Cascade portal. Please refresh this page and try again.<br/>
              If you can reproduce this error, please contact support with steps to reproduce so we can resolve the error as soon as possible.
            </Grid>
            <Grid item>
              <Button variant='contained' color='secondary' href={SUPPORT_URL} target='_blank'>Contact Support</Button>
            </Grid>
          </Grid>
        </div>
      )
    } else {
      // Render the app as normal
      return this.props.children
    }
  }
}

export default AppErrorBoundary
