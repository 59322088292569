export class CopyTextClipboard {
  static copyToClipBoard(value:any, section:string) {
    let selectedText= value
    let textArea = document.createElement("textarea");
    textArea.value = selectedText.toString();
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      let successful = document.execCommand('copy');
    } catch (err) {
      console.log(`Copy ${section} Link error`,err);
    }
    document.body.removeChild(textArea);
  }
}
