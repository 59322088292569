import {createMuiTheme, Theme} from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'

const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#357CB7',
    },
    secondary: {
      main: '#C92E7F',
    },
    error: red,
  },
})

export default theme
