import {assign} from 'lodash'
import {Expose, Transform} from "class-transformer";

class SymLink {
  symLinkId: string
  from: string
  to: string
  assetGroupId: string

  constructor(props?: Partial<SymLink>) {
    props = props || {}

    const defaultProps: Partial<SymLink> = {
      symLinkId: undefined,
      from: undefined,
      to: undefined,
      assetGroupId: undefined,
    }
    assign(this, defaultProps, props)
  }
}

export default SymLink
