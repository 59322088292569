import React from 'react'
import PropTypes from 'prop-types'
import logo from '../../../images/favicons/favicon.png'
import { CurrentUserMenu } from './CurrentUserMenu.component'
import { ButtonBase } from '@material-ui/core'
import { UISref } from '@uirouter/react'
import { menuEntries } from './header.config'
import { Dropdown } from './Dropdown.component'
import User from '../../../models/User'
import {useSelector, useDispatch} from 'react-redux'
import IStoreState from '../../../store/IStoreState'
import {setHeaderTab} from '../../../store/actions/headerActions'
import '../../../styles/base/header.scss'

export interface HeaderProps {
  currentUser: User
}

export enum HeaderTabs {
  Projects,
  Admin,
  Users,
}

const activeStyleClass = 'active-btn'
const inactiveStyleClass = 'inactive-btn'

export const Header: React.FC<HeaderProps> = (props) => {
  const { currentUser } = props
  const { ADMIN, PROJECTS } = menuEntries
  const selectedTab = useSelector( (state:IStoreState) => state.header.selectedTab)
  const dispatch = useDispatch()
  const showProjectsLink = currentUser.canViewProjects()
  const showAdministrationLink = currentUser.canManageDeploymentTarget() || currentUser.canManageUser()
  const dashboardStyleClass = selectedTab === HeaderTabs.Projects ? activeStyleClass : inactiveStyleClass
  const adminStyleClass = selectedTab === HeaderTabs.Admin ? activeStyleClass : inactiveStyleClass
  return (
    <nav className='header'>
      <img className='header-logo' src={logo} />
      <Dropdown currentUser={currentUser} />
      <ul className='tabs'>
        {showProjectsLink &&
          <li>
            <UISref to={PROJECTS.uiRouterState}>
              <ButtonBase>
                <div className={dashboardStyleClass} onClick={() => dispatch(setHeaderTab(HeaderTabs.Projects))}>{PROJECTS.title}</div>
              </ButtonBase>
            </UISref>
          </li>
        }
        {showAdministrationLink &&
          <li>
            <UISref to={ADMIN.uiRouterState} params={{ currentUser }}>
              <ButtonBase>
                <div className={adminStyleClass} onClick={() => dispatch(setHeaderTab(HeaderTabs.Admin))}>{ADMIN.title}</div>
              </ButtonBase>
            </UISref>
          </li>
        }
      </ul>
      <CurrentUserMenu currentUser={currentUser} />
    </nav>
  )
}

// @ts-ignore
Header.propTypes = {
  currentUser: PropTypes.instanceOf(User).isRequired,
}
