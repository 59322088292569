import React from 'react'
import {FormLabel, Grid, Input} from '@material-ui/core'
import DeploymentTarget, {LOGIN_AUTH_TYPE} from '../../../models/DeploymentTarget'
import FormField from '../../common/FormField'

export interface IDeploymentTargetWizardStep2Props {
  handleDeploymentTargetPropertyChange: (key: string, value: any) => void,
  deploymentTarget: DeploymentTarget,
  disableFields: boolean,
  errorFields: Map<string, string>,
  isNewDeploymentTarget: boolean,
}

export interface IDeploymentTargetWizardStep2State {

}

export class DeploymentTargetWizardStep2 extends React.Component<IDeploymentTargetWizardStep2Props, IDeploymentTargetWizardStep2State> {

  static defaultProps: any = {
    isNewDeploymentTarget: false
  }

  handleInputChange = (event: any): void => {
    const target: any = event.target
    this.props.handleDeploymentTargetPropertyChange(target.name, target.value)
  }

  render(): React.ReactNode {
    const {deploymentTarget, errorFields, disableFields} = this.props

    return (
      <Grid container direction='column' spacing={2}>
        <Grid item sm={12}>
          {deploymentTarget.loginAuthType !== LOGIN_AUTH_TYPE.STORED &&
          <FormLabel component='legend'>Please enter the login credentials for the Deployment Target.</FormLabel>
          }
          {deploymentTarget.loginAuthType === LOGIN_AUTH_TYPE.STORED &&
          <FormLabel component='legend'>Your login credentials have been stored. To change this information select
            private key or password.</FormLabel>
          }
        </Grid>
        <Grid item sm={12}>
          {deploymentTarget.loginAuthType === LOGIN_AUTH_TYPE.PRIVATE_KEY &&
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField label='Private Key' error={errorFields.has('privateKey')} message={errorFields.get('privateKey')} mandatory={true}>
                <Input name='privateKey' disabled={disableFields} value={deploymentTarget.privateKey} onChange={this.handleInputChange}
                       multiline rows={5}/>
              </FormField>
            </Grid>
            <Grid item className='helpText' xs={12}>
              <p>
                To create an ssh key, run the following command:<br/>
                <i>ssh-keygen -t rsa -b 4096 -C your_email@example.com</i>
              </p>
              <p>
                When it prompts for a password, do not provide one. The command will generate two files,
                by default id_rsa and id_rsa.pub. You will need to paste the private key (id_rsa) here.
              </p>
            </Grid>
          </Grid>
          }
          {deploymentTarget.loginAuthType === LOGIN_AUTH_TYPE.PASSWORD &&
          <FormField label='Password' error={errorFields.has('remotePassword')} message={errorFields.get('remotePassword')} mandatory={true}>
            <Input name='remotePassword' type='password' disabled={disableFields} value={deploymentTarget.remotePassword}
                   onChange={this.handleInputChange}/>
          </FormField>
          }
        </Grid>
      </Grid>
    )
  }
}

export default DeploymentTargetWizardStep2
