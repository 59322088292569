import { assign, findKey, keys } from 'lodash'

const USER_MANAGEMENT: string = 'USER_MANAGEMENT'
const BUNDLE_MANAGEMENT: string = 'BUNDLE_MANAGEMENT'
const DEPLOYMENT_ENVIRONMENT_MANAGEMENT: string = 'DEPLOYMENT_ENVIRONMENT_MANAGEMENT'
const DEPLOYMENT_TARGET_MANAGEMENT: string = 'DEPLOYMENT_TARGET_MANAGEMENT'
const VIEW_PROJECT: string = 'VIEW-PROJECT'
const PROJECT_ADMINISTRATION: string = 'PROJECT_ADMINISTRATION'
const PROJECT_MANAGEMENT: string = 'PROJECT_MANAGEMENT'
const DEPLOYMENT_TARGET_ADMINISTRATION: string = 'DEPLOYMENT_TARGET_ADMINISTRATION'
const PIPELINE_ADMINISTRATION: string = 'PIPELINE_ADMINISTRATION'

export interface IUsersName {
  givenName: string
  familyName: string
}
class User {
  username: string
  name: IUsersName
  email: string
  roles: any[]
  roleGroups: any[]
  _links: object

  constructor(props?: Partial<User>) {
    props = props || {}
    const defaultProps: Partial<User> = {
      username: undefined,
      name: undefined,
      email: undefined,
      roles: [],
      roleGroups: [],
      _links: {}
    }

    assign(this, defaultProps, props)
  }

  canManageBundle(): boolean {
    return this.hasLinkForAuth(BUNDLE_MANAGEMENT)
  }

  canManageUser(): boolean {
    return this.hasLinkForAuth(USER_MANAGEMENT)
  }

  canAddProject(): boolean {
    return this.hasLinkForAuth(PROJECT_ADMINISTRATION)
  }

  canEditProject(projectId: string): boolean {
    return this.hasLinkForAuth(`${projectId}_${PROJECT_MANAGEMENT}`) || this.hasLinkForAuth(PROJECT_ADMINISTRATION)
  }

  canManageDeploymentEnvironment(): boolean {
    return this.hasLinkForAuth(DEPLOYMENT_ENVIRONMENT_MANAGEMENT)
  }

  canManageDeploymentTarget(): boolean {
    return this.hasLinkForAuth(DEPLOYMENT_TARGET_MANAGEMENT)
  }

  canViewProjects(): boolean {
    return !!findKey(keys(this._links), (link: string) => link.includes(VIEW_PROJECT))
  }

  canAdministerDeploymentTarget(): boolean {
    return this.hasLinkForAuth(DEPLOYMENT_TARGET_ADMINISTRATION)
  }

  canDisableProjects(): boolean {
    return this.hasLinkForAuth(PROJECT_ADMINISTRATION)
  }

  canCloneProjects(): boolean {
    return this.hasLinkForAuth(PROJECT_ADMINISTRATION)
  }

  canAdministerPipelinesForProject(projectId: string): boolean {
    return this.hasLinkForAuth(`${projectId}_${PIPELINE_ADMINISTRATION}`) || this.hasLinkForAuth(PIPELINE_ADMINISTRATION)
  }

  private hasLinkForAuth(linkForAuth: string): boolean {
    return !!this._links[linkForAuth]
  }
}

export default User
