import Project, {PROJECT_ERROR} from '../../../../../models/Project'
import React from 'react'
import {
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import DeploymentEnvironmentHelper from '../../../../../helpers/DeploymentEnvironmentHelper'
import ProjectEnvironment from '../../../../../models/ProjectEnvironment'
import ActionTypes from '../../../../../store/actions'
import FormField from '../../../../common/FormField'
import DeploymentTargetGroup from '../../../../../models/DeploymentTargetGroup'
import MessageService from '../../../../../services/MessageService'
import AssetGroup from '../../../../../models/AssetGroup'
import SymLink from "../../../../../models/SymLink";
import {UnderlinedTextField} from "../../shared";
import ProjectHelper from "../../../../../helpers/ProjectHelper";
import ProjectEnvironmentCodeWebroot from "../../../../../models/ProjectEnvironmentCodeWebroot";
import {TooltipLabel} from "../../../../common/TooltipLabel";
import {connect} from "react-redux";
import IStoreState from "../../../../../store/IStoreState";
import {Dispatch} from "redux";
import {ProjectEnvironmentAssetsCardCommandSelect} from "./project-environment-assets-card-command-select.component";

export interface IProjectEnvironmentAssetsCardProps {
  handleInputChange?: (event: any) => void,
  project?: Project,
  error?: PROJECT_ERROR,
  loading?: boolean,
  className?: string,
  assetGroup: AssetGroup,
  environmentId: string,
  deploymentTargetGroupId: string,
  symLinks: SymLink[] // TODO: Should this prop be removed
}

export class ProjectEnvironmentAssetsCard extends React.Component<IProjectEnvironmentAssetsCardProps> {

  handleProjectEnvironmentAssetsUpdate = (id: string, event: any): void => {
    const { assetGroup } = this.props
    const updatedProjectEnvironments: ProjectEnvironment[] = this.props.project.projectEnvironments
    const projectEnvironmentAssetsToUpdate: ProjectEnvironment = updatedProjectEnvironments.find((projEnv: ProjectEnvironment) => projEnv.environmentId === id)
    const assetGroupToUpdate: AssetGroup = projectEnvironmentAssetsToUpdate.projectEnvironmentAssets.assetGroups.find((ag: AssetGroup) => ag.associationId === assetGroup.associationId)

    let symLinkToUpdate: SymLink = projectEnvironmentAssetsToUpdate.symLinks.find((symLink: SymLink) => {
      if (symLink.symLinkId && assetGroup.symLinkId) {
        return symLink.symLinkId === assetGroup.symLinkId
      }
      return symLink.to === assetGroup.path
    })

    let webroot: string
    if (!projectEnvironmentAssetsToUpdate.projectEnvironmentCode) {
      webroot = ProjectHelper.getDefaultWebrootForEnvironment(this.props.project.name, projectEnvironmentAssetsToUpdate.environmentId).fullWebroot
    } else {
      webroot = projectEnvironmentAssetsToUpdate.projectEnvironmentCode.webroot
    }

    switch (event.target.name) {
      case 'deploymentTargetGroupId':
        projectEnvironmentAssetsToUpdate.projectEnvironmentAssets.deploymentTargetGroupId = event.target.value
        break;
      case 'from':
        // TODO: determine how this could be falsy
        if (symLinkToUpdate) {
          symLinkToUpdate[event.target.name] = `${webroot}/${event.target.value}`
        }

        break;
      case 'path':
        assetGroupToUpdate[event.target.name] = event.target.value
        // TODO: determine how this could be falsy
        if (symLinkToUpdate) {
          symLinkToUpdate['to'] = event.target.value
        }

        break;
    }

    this.props.handleInputChange({
      target: {
        name: 'projectEnvironments',
        value: updatedProjectEnvironments
      }
    })
  }

  reconcileAssetPathError = (error: PROJECT_ERROR): boolean => {
    return (error === PROJECT_ERROR.ASSET_PATH_FOUND_IN_CODE_WEB_ROOT || error === PROJECT_ERROR.DUPLICATE_ASSET_PATHS)
  }

  getAssetPathErrorMessage = (error: PROJECT_ERROR): string => {
    switch (error) {
      case PROJECT_ERROR.ASSET_PATH_FOUND_IN_CODE_WEB_ROOT:
        return MessageService.get('project.wizard.error.assets.path.conflict')
      case PROJECT_ERROR.DUPLICATE_ASSET_PATHS:
        return MessageService.get('project.wizard.error.assets.path.duplicate')
      default:
        return null
    }
  }

  render(): React.ReactNode {
    const { project, loading, error, environmentId, deploymentTargetGroupId, assetGroup, symLinks , className } = this.props
    const symLink = symLinks.find((link: SymLink) => link?.assetGroupId === assetGroup?.assetGroupId)
    const projectEnvironmentCode: any = this.props.project.projectEnvironments.find((projEnv: ProjectEnvironment) => projEnv.environmentId == environmentId).projectEnvironmentCode
    let linkDir: string
    let webroot: string

    if (!projectEnvironmentCode || !projectEnvironmentCode?.webroot) {
      const webrootFull: ProjectEnvironmentCodeWebroot = ProjectHelper.getDefaultWebrootForEnvironment(project.name, environmentId)
      webroot = webrootFull.fullWebroot
    } else {
      webroot = projectEnvironmentCode.webroot
    }

    if (symLink) {
      linkDir = symLink.from.replace(`${webroot}/`, '')
    } else {
      linkDir = `${assetGroup.title.toLowerCase()}`
    }

    const environmentTitle = DeploymentEnvironmentHelper.getEnvironmentTitleById(environmentId)
    const assetGroupTitle = assetGroup.title.toLowerCase()

    return (
      <Paper elevation={2} className={'project-environment-card input-white project-card ' + className}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item xs={12}>
            <strong>{environmentTitle}</strong>
          </Grid>

          <Grid item container wrap={'nowrap'} spacing={3}>
            <Grid item xs container direction={'column'} spacing={2}>
              <Grid item container direction={'column'}>
                <Grid item>
                  <TooltipLabel label={"Asset Path"} description={"The location within the webroot from where the project expects the assets to be served."}/>
                </Grid>
                <Grid item container alignItems={'center'}>
                  <Grid item>
                    <Tooltip title={webroot} placement='bottom' enterDelay={50}>
                      <b className='helpText'>&lt;WEBROOT&gt;</b>
                    </Tooltip>
                  </Grid>
                  <Grid item>/</Grid>
                  <Grid item xs>
                    <FormField error={!!this.getAssetPathErrorMessage(error)} message={this.getAssetPathErrorMessage(error)}>
                      <UnderlinedTextField
                        name='from'
                        value={linkDir}
                        disabled={loading}
                        onChange={this.handleProjectEnvironmentAssetsUpdate.bind(this, environmentId)} />
                    </FormField>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container direction={'column'}>
                <Grid item>
                  <TooltipLabel
                    label={"Asset Storage Mount"}
                    description={"Assets are stored outside of the docroot and linked from the Asset Path to the destination in this field. Useful for GlusterFS or NFS mounts."}/>
                </Grid>
                <Grid item>
                  <FormField error={this.reconcileAssetPathError(error)} message={this.getAssetPathErrorMessage(error)}>
                    <UnderlinedTextField
                      name='path'
                      disabled={loading}
                      value={assetGroup.path}
                      onChange={this.handleProjectEnvironmentAssetsUpdate.bind(this, environmentId)} />
                  </FormField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3} direction={'column'}>
              <Typography variant='subtitle1' noWrap>
                <FormLabel component='legend'>Deployment Group<FormLabel style={{ color: 'red' ,marginLeft: '2px'}}>*</FormLabel></FormLabel>
              </Typography>
              <FormControl variant='outlined' margin={'dense'} style={{width: '100%'}}>
                <Select
                  disabled={loading}
                  value={deploymentTargetGroupId}
                  onChange={this.handleProjectEnvironmentAssetsUpdate.bind(this, environmentId)}
                  inputProps={{ name: 'deploymentTargetGroupId' }}>
                  {DeploymentEnvironmentHelper.getDeploymentEnvironmentById(environmentId).deploymentTargetGroups.map((group: DeploymentTargetGroup) => {
                    return <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <ProjectEnvironmentAssetsCardCommandSelect
            projectName={project.name}
            environmentTitle={environmentTitle}
            assetGroupTitle={assetGroupTitle}
          />
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IProjectEnvironmentAssetsCardProps): any => ({
  project: state.selectedProject.project,
  error: state.selectedProject.error,
  loading: state.selectedProject.loading,
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IProjectEnvironmentAssetsCardProps): any => ({
  handleInputChange: (event: any): void => {
    dispatch({
      type: ActionTypes.SELECTED_PROJECT_FIELD_UPDATE,
      payload: {
        key: event.target.name,
        value: event.target.value
      }
    })
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEnvironmentAssetsCard)
