import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './form-step.config'

export interface FormStepProps {
  stepLabel: string
  isActive: boolean
}

export const FormStep: React.FC<FormStepProps> = ({ stepLabel, isActive }) => {
  const styles = useStyles()
  const highlightStyle = isActive ? styles.activeHighlight : styles.inactiveHighlight
  const rootStyle = isActive ? null : styles.inactiveStep
  return (
    <Grid container item xs direction="column" className={rootStyle} spacing={1}>
      <Grid item>
        <div className={highlightStyle} />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">{stepLabel}</Typography>
      </Grid>
    </Grid>
  )
}
