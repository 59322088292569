import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      backgroundColor: theme.palette.common.white,
      width: '100%',
    },
  }),
)

export interface UnderlinedTextFieldProps {
  name: string
  value: string
  disabled: boolean
  onChange: any
}

export const UnderlinedTextField: React.FC<UnderlinedTextFieldProps> = ({ name, value, disabled, onChange }) => {
  const styles = useStyles()
  return (
    <TextField
      className={styles.input}
      name={name}
      variant="standard"
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
  )
}
