import axios from 'axios'
import {pull} from 'lodash'
import keycloakService from './KeycloakService'

const USER_ACTIVITY_REFRESH_RATE = 60000
const UNAUTHENTICATED_RESPONSE_CODE = 401
const UNAUTHORIZED_RESPONSE_CODE = 403

class AuthService {
  constructor() {
    this.interceptorSubscribers = []
    this.setupResponseInterceptor()
    this.userActive = false
    this.handleUserActivityTokenRefresh()
  }

  setBearerToken(token) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token
  }

  setupResponseInterceptor() {
    axios.interceptors.response.use((res => res), this.handleResponseError)
  }

  handleResponseError = (error) => {
    if (error.response.status === UNAUTHENTICATED_RESPONSE_CODE) {
      this.notifyInterceptorSubscribers()
    } else if (error.response.status === UNAUTHORIZED_RESPONSE_CODE) {
      return Promise.reject('You do not have permission to take this action. If your role was recently changed, try logging out and back in.')
    } else if (error.response.data && error.response.data.errorCode) {
      return Promise.reject(error.response.data.errorCode)
    }
    return Promise.reject(null)
  }

  notifyInterceptorSubscribers() {
    this.interceptorSubscribers.forEach((handler) => handler())
  }

  subscribeToInterceptors(subscriber) {
    this.interceptorSubscribers.push(subscriber)
  }

  unsubscribeFromInterceptors(subscriber) {
    pull(this.interceptorSubscribers, subscriber)
  }

  handleUserActivityTokenRefresh() {
    setInterval(() => {
      if (this.userActive) {
        this.userActive = false
        this.updateToken()
      }
    }, USER_ACTIVITY_REFRESH_RATE)
  }

  updateToken() {
    try{
      const secTilTimeout = !!keycloakService.tokenParsed? keycloakService.tokenParsed.exp - Date.now() / 1000: 0

      keycloakService.updateToken(secTilTimeout).success((refreshed) => {
        if (refreshed) {
          this.setBearerToken(keycloakService.token)
        } else {
          console.warn('Could not refresh token')
        }
      })
    }
    catch (ex) {
      console.warn('Could not refresh token')
    }
  }
}

export default new AuthService()
