import React from 'react'
import Button from '@material-ui/core/Button'
import logo from '../../images/logo.png'
import Spinner from '../common/Spinner'
import { Grid } from '@material-ui/core'
import { SUPPORT_URL } from '../../config/links.config'
import './LoginPage.scss'

interface IKeycloakUnavailablePageProps {
  retry: ()=>void,
  busy: boolean
}

class KeycloakUnavailablePage extends React.Component<IKeycloakUnavailablePageProps> {
  static defaultProps: IKeycloakUnavailablePageProps = {
    retry: null,
    busy: true
  }

  render(): React.ReactNode {
    const { busy } = this.props
    return (
      <Grid container className='login-page' direction='column' alignItems='center' justify='center' wrap='nowrap'>
        <Grid item>
          <img src={logo} className='logo'/>
        </Grid>
        {!busy &&
          <Grid item className='keycloak-down'>
            <p>The sign-on authentication service is currently unavailable.</p>
            <p>Please try again later. If the problem persists, please contact support.</p>
            <br />
            <Button variant='contained' color='secondary' onClick={this.props.retry} size='large' disableElevation>Try Again</Button>
          </Grid>
        }
        {busy &&
          <Grid item className='busy-spinner'>
            <Spinner xlarge/>
          </Grid>
        }
        <Grid item>
          <Button variant='contained' color='primary' href={SUPPORT_URL} target='_blank' disableElevation>Contact Support</Button>
        </Grid>
      </Grid>
    )
  }
}

export default KeycloakUnavailablePage
