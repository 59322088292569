import React from 'react'
import Project from 'src/models/Project'
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {Button} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import NoPermissionButton from './NoPermissionButton'
import ProjectEvent from 'src/models/pipeline/ProjectEvent'
import {PermissionHelper} from 'src/helpers/PermissionHelper'
import {ACTION_TYPE} from 'src/models/pipeline/Action'
import './CancelButton.scss'

export interface ICancelButtonProps {
  latestEvent: ProjectEvent
  handleCancelClick: () => void
  environmentId: string
  project: Project
}

class CancelButton extends React.Component<ICancelButtonProps> {
  private buttonText: string = 'Cancel'

  handleClick = (): void => {
    this.props.handleCancelClick()
  }

  render(): React.ReactNode {
    const {project, environmentId, latestEvent} = this.props

    const actionType: ACTION_TYPE = latestEvent && latestEvent.action.actionConfiguration.actionType

    if (PermissionHelper.hasPermissionForAction(actionType, project, environmentId)) {
      return (
        <Button className='cancel-button' onClick={this.handleClick} variant='contained'>
          <FontAwesomeIcon icon={faTimesCircle} className='buttonIcon' /> {this.buttonText}
        </Button>
      )
    }

    return <NoPermissionButton icon={faTimesCircle} text={this.buttonText}/>
  }
}

export default CancelButton
