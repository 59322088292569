import {Action, Dispatch, Middleware, MiddlewareAPI} from 'redux'
import IStoreState from '../IStoreState'

export type MiddlewareFn = (action: any, state: IStoreState, next: Dispatch) => Action
type MiddlewareCreator = (middlewareFn: MiddlewareFn) => Middleware

const createMiddleware: MiddlewareCreator = (middlewareFn: MiddlewareFn): any => {
  return (api: MiddlewareAPI): any => {
    return (next: Dispatch): any => {
      return (action: Action): Action => {
        return middlewareFn(action, api.getState(), next)
      }
    }
  }
}

const appMiddlewares: Middleware[] = []

const registerMiddleware: (middleware: Middleware) => void = (middleware: Middleware): void => {
  appMiddlewares.push(middleware)
}

const getAppMiddlewares: () => Middleware[] = (): Middleware[] => {
  return appMiddlewares
}

export {
  createMiddleware,
  registerMiddleware,
  getAppMiddlewares
}
