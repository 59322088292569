import React from 'react'
import {PipelineTemplate} from 'src/models/pipeline/PipelineTemplate'
import {FormLabel, Grid} from '@material-ui/core'
import {cloneDeep} from 'lodash'
import {ActionConfiguration} from 'src/models/pipeline/ActionConfiguration'
import PipelineTriggerGroup from 'src/models/pipeline/PipelineTriggerGroup'
import IconButton from '@material-ui/core/IconButton'
import PipelineTrigger from 'src/models/pipeline/PipelineTrigger'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import MessageService from 'src/services/MessageService'
import Spinner from 'src/components/common/Spinner'
import RetryLoading from 'src/components/common/RetryLoading'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PipelineTemplateEditorTriggerGroupList from './PipelineTemplateEditorTriggerGroupList'
import {Dispatch} from 'redux'
import ActionTypes from 'src/store/actions'
import {PipelineTemplateActions} from 'src/store/actions/pipelineTemplateActions'
import {ActionConfigurationActions} from 'src/store/actions/actionConfigurationActions'
import {connect} from 'react-redux'
import GenericDialog from 'src/components/common/GenericDialog'
import IStoreState from 'src/store/IStoreState'
import {OutlinedTextField} from '../../../../../common/OutlinedTextField'
import {SaveCancelDialogActions} from '../../../../../common/SaveCancelDialogActions'
import {PipelineActionConfig} from './PipelineActionList.config'
import {PipelineActionSelectedList} from './PipelineActionList.component'
import DeploymentEnvironmentHelper from "../../../../../../helpers/DeploymentEnvironmentHelper";


export interface IPipelineTemplateEditorProps {
  pipelineTemplate?: PipelineTemplate
  onClose: () => void
  handleClose?: () => void
  handleSave?: (pipelineTemplate: PipelineTemplate) => void
  handleCreate?: (pipelineTemplate: PipelineTemplate) => void
  actionConfigurations?: ActionConfiguration[]
  loadProjectActionConfigurations?: (id: string) => void
  loading?: boolean
  actionConfigurationsError?: any,
  handleInputChange?: (event: any) => void,
  handleTriggerGroupsChange?: (triggerGroups: PipelineTriggerGroup[]) => void,
  errors?: Map<string, string>,
  touched?: boolean
}

export interface IPipelineTemplateEditorState {
}

export class PipelineTemplateEditor extends React.Component<IPipelineTemplateEditorProps, IPipelineTemplateEditorState> {
  componentWillMount(): void {
    this.loadProjectActionConfigurations()
  }

  loadProjectActionConfigurations = (): void => {
    const {loadProjectActionConfigurations, pipelineTemplate} = this.props

    loadProjectActionConfigurations(pipelineTemplate.projectId)
  }

  handleSaveClick = (): void => {
    const {pipelineTemplate, handleSave, handleCreate, handleClose, onClose} = this.props

    pipelineTemplate.id ? handleSave(pipelineTemplate) : handleCreate(pipelineTemplate)
    handleClose()
    onClose()
  }

  canSave = (): boolean => {
    const {touched, errors, pipelineTemplate} = this.props
    if (pipelineTemplate.actionConfigurations.filter((ac) => {
      if (ac.sourceEnvironment) {
        return !DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(ac.sourceEnvironment)
      }
      if (ac.targetEnvironment) {
        return !DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(ac.targetEnvironment)
      }
      return true
    }).length > 0) {
      return false
    }
    return touched && !errors.size
  }

  getSaveTooltip = (): string => {
    const {touched, errors, pipelineTemplate} = this.props
    const invalidEnvironments: Set<string> = new Set<string>()
    pipelineTemplate.actionConfigurations.map((ac: ActionConfiguration) => {
      if (ac.sourceEnvironment) {
        const sourceEnvironmentActive: boolean = DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(ac.sourceEnvironment)
        const sourceEnvTitle: string = DeploymentEnvironmentHelper.getEnvironmentTitleById(ac.sourceEnvironment)
        if (!sourceEnvironmentActive && !invalidEnvironments.has(sourceEnvTitle)) {
          invalidEnvironments.add(sourceEnvTitle)
        }
      }
      if (ac.targetEnvironment) {
        const targetEnvironmentActive: boolean = DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(ac.targetEnvironment)
        const targetEnvTitle: string = DeploymentEnvironmentHelper.getEnvironmentTitleById(ac.targetEnvironment)
        if (!targetEnvironmentActive && !invalidEnvironments.has(targetEnvTitle)) {
          invalidEnvironments.add(targetEnvTitle)
        }
      }
    })
    let saveMessage: string = ''
    const saveMessageSuffix: string = "Select a different action, resolve your environment, or contact your Cascade Administrator for assistance."
    if (invalidEnvironments.size > 0) {
      if (invalidEnvironments.size == 1) {
        saveMessage = `Action with Environment ${Array.from(invalidEnvironments)} is invalid.`
      } else {
        saveMessage = `Actions with Environments ${Array.from(invalidEnvironments)} are invalid.`
      }
      return `${saveMessage} ${saveMessageSuffix}`
    }
    return saveMessage
  }

  handleCancelClick = (): void => {
    const {handleClose, onClose} = this.props

    handleClose()
    onClose()
  }

  handleAddTriggerGroup: () => void = () => {
    const {pipelineTemplate, handleTriggerGroupsChange} = this.props

    const triggerGroups: PipelineTriggerGroup[] = cloneDeep(pipelineTemplate.pipelineTriggerGroups)
    triggerGroups.push(new PipelineTriggerGroup({triggers: [new PipelineTrigger()]}))
    handleTriggerGroupsChange(triggerGroups)
  }


  render = (): React.ReactNode => {
    const {
      actionConfigurations,
      errors,
      handleInputChange,
      loading,
      pipelineTemplate,
      actionConfigurationsError
    } = this.props
    const editing: boolean = !!pipelineTemplate && !!pipelineTemplate.id
    let actionConfigurationList: React.ReactNode
    const scheduleDisclaimer: string = 'Note: Pipelines are scheduled to run after a short delay according to the specified crontab interval.'

    const actions: React.ReactNode = <SaveCancelDialogActions name='pipeline-template-actions'
                                                              buttonCancelLabel='Cancel'
                                                              buttonSaveLabel='Save'
                                                              buttonSaveDisabled={!this.canSave()}
                                                              buttonSaveTooltip={this.getSaveTooltip()}
                                                              handleCancel={this.handleCancelClick}
                                                              handleSave={this.handleSaveClick}/>

    if (pipelineTemplate.actionConfigurations) {
      const pipelineActionSelected: PipelineActionConfig[] = pipelineTemplate.actionConfigurations.map((actionConfigurationType, index: number) => {
        return (
          {
            id: actionConfigurationType.id,
            title: actionConfigurationType.name,
            isSelectedAction: true
          }
        )

      })
      const suggestions: PipelineActionConfig[] = actionConfigurations
        .filter((config: ActionConfiguration) => {
          return !pipelineTemplate.actionConfigurations.find((existingConfig: ActionConfiguration) => config.id === existingConfig.id)
        })
        .map((config: ActionConfiguration) => {
          return (
            {
              id: config.id,
              title: config.name,
              isSelectedAction: false
            }
          )
        })
      actionConfigurationList =
        (
          <Grid container direction='column' spacing={2}>
            <Grid item sm={12}>
              <FormLabel component='legend'>{MessageService.get('pipeline.template.editor.details')}</FormLabel>
            </Grid>
            <Grid item sm={12}>
              <OutlinedTextField label='Pipeline Template Name'
                                 error={errors.has('name')}
                                 message={errors.get('name')}
                                 name='name'
                                 value={pipelineTemplate.name}
                                 handleChange={handleInputChange}/>
            </Grid>
            <Grid item sm={12}>
              <h3>Steps</h3>
              {loading && <Grid container justify='center'><Spinner large/></Grid>}
              {actionConfigurationsError && <RetryLoading message='Could not load action configurations.'
                                                          reloadData={this.loadProjectActionConfigurations}/>}
              {!actionConfigurationsError && !loading && (actionConfigurations && !actionConfigurations.length) &&
              <RetryLoading message='There were no action configurations'
                            reloadData={this.loadProjectActionConfigurations}/>
              }
            </Grid>
            <Grid item sm={12}>
              <PipelineActionSelectedList selectedAction={suggestions} isSelected={false} label={'Available Steps'}/>
              <PipelineActionSelectedList selectedAction={pipelineActionSelected} isSelected={true}
                                          label={'Active Steps'}/>
            </Grid>
          </Grid>
        )
    }

    const triggerGroups: React.ReactNode = (
      <Grid>
        <Grid container>
          <h3>Trigger Groups</h3>
          <IconButton id='ab-dt-add-button'
                      style={{marginTop: 'calc(0.5em - 10px)'}}
                      title='Create New Trigger Group'
                      onClick={this.handleAddTriggerGroup}>
            <FontAwesomeIcon icon={faPlus} size='sm' className={'clickable'}/>
          </IconButton>
        </Grid>
        <PipelineTemplateEditorTriggerGroupList
          pipelineTriggerGroupList={pipelineTemplate.pipelineTriggerGroups}
          pipelineTriggerGroupListChange={this.props.handleTriggerGroupsChange}/>
      </Grid>
    )


    const pipelineScheduler: React.ReactNode = (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <h3>Schedule</h3>
          <OutlinedTextField name='schedule'
                             value={pipelineTemplate.schedule}
                             handleChange={handleInputChange}
                             required={false}
                             error={errors.has('schedule')}
                             message={errors.get('schedule') ? errors.get('schedule') : scheduleDisclaimer}
                             label={'Cron expression'}
                             placeholder={"* * * * * *"}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    )

    return (
      <GenericDialog title={(editing ? 'Edit' : 'Create New') + ' Pipeline for Development'}
                     actions={actions}
                     disableBackdropClick={true}
                     onClose={this.handleCancelClick}
                     style={{overflow: 'hidden'}}>
        {actionConfigurationList}
        {triggerGroups}
        {pipelineScheduler}
      </GenericDialog>
    )
  }
}


const mapStateToProps: any = (state: IStoreState): any => ({
  pipelineTemplate: state.pipelineTemplateEditor.pipelineTemplate,
  actionConfigurations: state.actionConfigurations.data,
  loading: state.actionConfigurations.loading,
  actionConfigurationsError: state.actionConfigurations.error,
  errors: state.pipelineTemplateEditor.errors,
  touched: state.pipelineTemplateEditor.touched
})

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  handleClose: (): void => {
    dispatch({type: ActionTypes.PIPELINE_TEMPLATE_EDITOR_CLOSE})
  },
  handleSave: (pipelineTemplate: PipelineTemplate): void => dispatch(PipelineTemplateActions.savePipelineTemplate(pipelineTemplate)),
  handleCreate: (pipelineTemplate: PipelineTemplate): void => dispatch(PipelineTemplateActions.createPipelineTemplate(pipelineTemplate)),
  loadProjectActionConfigurations: (id: string): void => dispatch(ActionConfigurationActions.loadActionConfigurations(id)),
  handleInputChange: (event: any): void => {
    dispatch({
      type: ActionTypes.UPDATE_PIPELINE_TEMPLATE_EDITOR_FIELD,
      payload: {
        key: event.target.name,
        value: event.target.value
      }
    })
  },
  handleTriggerGroupsChange: (triggerGroups: PipelineTriggerGroup[]): void => {
    dispatch({
      type: ActionTypes.UPDATE_PIPELINE_TEMPLATE_EDITOR_FIELD,
      payload: {
        key: 'pipelineTriggerGroups',
        value: triggerGroups
      }
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(PipelineTemplateEditor)
