import { assign } from 'lodash'
import ImportRepository from './ImportRepository'
import {Type} from 'class-transformer'
import 'reflect-metadata'

export default class CodeRepository {
  gitHost: string
  group: string
  name: string
  @Type(() => ImportRepository)
  importRepository: ImportRepository
  repositoryId: string

  constructor(props?: Partial<CodeRepository>) {
    props = props || {}
    const defaultProps: Partial<CodeRepository> = {
      gitHost: undefined,
      group: undefined,
      name: undefined,
      importRepository: undefined,
      repositoryId: undefined
    }

    assign(this, defaultProps, props)
  }

  get repoUrl(): string {
    return `//${this.gitHost}/${this.group}/${this.name}`
  }

}
