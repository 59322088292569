import React from "react";
import {FormControl, Grid, IconButton, MenuItem, Select, TextField} from "@material-ui/core";
import {CopyTextClipboard} from "../../shared";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard} from "@fortawesome/free-solid-svg-icons";

interface IAssetsCardCommandSelectProps {
  projectName: string,
  environmentTitle: string,
  assetGroupTitle: string
}

interface IAssetsCardCommandSelectState {
  selectedValue: string,
  activeAssetGroupTitle: string
}

// TODO: This component and DatabaseCardCommandSelect are pretty much the same and should be refactored.
export class ProjectEnvironmentAssetsCardCommandSelect
  extends React.Component<IAssetsCardCommandSelectProps, IAssetsCardCommandSelectState> {

  constructor(props: IAssetsCardCommandSelectProps) {
    super(props)
    this.state = {
      selectedValue: undefined,
      activeAssetGroupTitle: this.props.assetGroupTitle
    }
  }

  render(): React.ReactNode {
    const {projectName, environmentTitle, assetGroupTitle} = this.props
    const {activeAssetGroupTitle, selectedValue} = this.state
    const listAssetCommand = `cascade list -t asset -p ${projectName} -e ${environmentTitle} -g ${assetGroupTitle}`
    const pushAssetCommand = `cascade push -t asset -p ${projectName} -e ${environmentTitle} -g ${assetGroupTitle} -f (your file to upload)`
    const pullAssetCommand = `cascade pull -t asset -p ${projectName} -e ${environmentTitle} -g ${assetGroupTitle} -d (file to download)`

    let defaultSelect: string = listAssetCommand
    if (!!selectedValue) {
      if (activeAssetGroupTitle !== assetGroupTitle) {
        this.setState({
          activeAssetGroupTitle: assetGroupTitle,
          selectedValue: defaultSelect
        })
      } else {
        defaultSelect = selectedValue
      }
    }

    return (
      <Grid item xs={12}>
        <FormControl variant='outlined'>
          <Select onChange={(e) => this.setState({selectedValue: e.target.value.toString()})}
                  value={defaultSelect}
                  className={'MuiSelect-database'}
                  inputProps={{name: 'deploymentTargetGroupId'}}>
            <MenuItem value={listAssetCommand}>List</MenuItem>
            <MenuItem value={pushAssetCommand}>Push</MenuItem>
            <MenuItem value={pullAssetCommand}>Pull</MenuItem>
          </Select>
        </FormControl>
        <TextField
          disabled={true}
          value={defaultSelect}
          onClick={() => CopyTextClipboard.copyToClipBoard(defaultSelect, "asset")}
          variant='outlined'
          className={'MuiForm-Control'}
          InputProps={{
            endAdornment: <IconButton className={'MuiIcon'}>
              <div>
                <FontAwesomeIcon icon={faClipboard} size='sm'/>
              </div>
            </IconButton>
          }}
        />
      </Grid>
    )
  }
}