import React from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { EnvironmentItem } from './environment-item.component'
import { EnvironmentSelectionConfig } from '../drupal8/drupal8-environments.config'
import { DroppableColumnWrapper } from './droppable-column-wrapper.component'

type ComponentProps = {
  droppableId: string
  environments: EnvironmentSelectionConfig[]
  updateOrder: (result: DropResult) => void
  remove: (config: EnvironmentSelectionConfig) => void
}

export type DraggableEnvironmentsListProps = ComponentProps

export const DraggableEnvironmentsList: React.FC<DraggableEnvironmentsListProps> = ({
  droppableId,
  environments,
  updateOrder,
  remove,
}) => {
  return (
    <DroppableColumnWrapper onDragEnd={updateOrder} droppableId={droppableId}>
      {environments.map((config, index) => {
        return <EnvironmentItem position={index} config={config} remove={remove} key={config.id} />
      })}
    </DroppableColumnWrapper>
  )
}
