import React from 'react'
import {Checkbox, TableCell, TableRow, Tooltip} from "@material-ui/core";

interface IProjectUsersTableRowProps {
  user: any,
  handleClick: any,
}

class ProjectUsersTableRow extends React.Component<IProjectUsersTableRowProps> {

  handleClick = (e: any) => {
    this.props.handleClick(e)
  }

  render(): React.ReactNode {

    const username: string = this.props.user.username
    const roleList = this.props.user.rolesList

    return (
      <TableRow hover>
        <TableCell>{username}</TableCell>

        {roleList.map((each: any, index: number) => {
          return (

            <TableCell key={index}>
              <Tooltip title={each.globalRole ? this.props.user.tooltip : ''}>
                <span>
                  <Checkbox
                    value={each.type}
                    name={username}
                    checked={each.value || each.globalRole}
                    onChange={this.handleClick}
                    color="primary"
                    disabled={each.globalRole}
                  />
                </span>
              </Tooltip>
            </TableCell>
          )
        })}
      </TableRow>
    )
  }
}

export default ProjectUsersTableRow
