import React from 'react'
import { Grid } from '@material-ui/core'
import { Dispatch } from 'redux'
import { DropResult } from 'react-beautiful-dnd'
import { connect, ConnectedProps } from 'react-redux'
import { reorderSelectedEnvironmentDetails, removeSelectedEnvironmentDetails } from 'src/store/update-project-data/project-details-environment.action'
import IStoreState from 'src/store/IStoreState'
import { DraggableEnvironmentsList, ProductionPlaceholderItem } from 'src/components/project/project-create/shared'
import { EnvironmentSelectionConfig } from './project-environment.config'
import { useStyles } from './environment-selected.config'

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {}

export type SelectedEnvironmentsDetailsListProps = PropsFromRedux & ComponentProps

const SelectedEnvironmentsListDetails: React.FC<SelectedEnvironmentsDetailsListProps> = ({ selectedEnvironments, move, remove }) => {
  const styles = useStyles()
  const updateSelectedListSync = (result: DropResult) => {
    move(result)
  }
  return (
    <div className={styles.container}>
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <DraggableEnvironmentsList updateOrder={updateSelectedListSync}
                                     remove={remove}
                                     droppableId="selectedEnvsColId"
                                     environments={selectedEnvironments.sort((a, b) => a.order - b.order)} />
        </Grid>
      </Grid>
      <ProductionPlaceholderItem />
    </div>
  )
}

const mapStateToProps = (state: IStoreState) => ({
  selectedEnvironments: state.selectedEnvironmentList !== undefined? state.selectedEnvironmentList.data:[],
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  remove: (config: EnvironmentSelectionConfig) => {
    dispatch(removeSelectedEnvironmentDetails(config))
  },
  move: (result: DropResult) => {
    dispatch(reorderSelectedEnvironmentDetails(result))
  },
})

const connector = connect(mapStateToProps, mapDispatchToProps)
export const UpdateConnectedSelectedEnvironmentsList = connector(SelectedEnvironmentsListDetails)