import {Type} from 'class-transformer'
import 'reflect-metadata'
import {assign} from 'lodash'
import DeploymentTarget from './DeploymentTarget'

export enum DEPLOYMENT_TARGET_GROUP_ERROR {
  DEPLOYMENT_TARGET_GROUP_NO_NAME = 'DEPLOYMENT_TARGET_GROUP_NO_NAME',
  DEPLOYMENT_TARGET_GROUP_DUPLICATE_NAME = 'DEPLOYMENT_TARGET_GROUP_DUPLICATE_NAME',
  DEPLOYMENT_TARGET_GROUP_NOT_FOUND = 'DEPLOYMENT_TARGET_GROUP_NOT_FOUND',
}

export enum DEPLOYMENT_TARGET_GROUP_TYPE {
  CODE = 'CODE',
  ASSET = 'ASSET',
  DATABASE = 'DATABASE'
}

class DeploymentTargetGroup {
  id: string
  name: string
  primaryDeploymentTargetId: string
  @Type(() => DeploymentTarget)
  deploymentTargets: DeploymentTarget[]
  type: DEPLOYMENT_TARGET_GROUP_TYPE

  constructor(props?: Partial<DeploymentTargetGroup>) {
    props = props || {}

    const defaultProps: Partial<DeploymentTargetGroup> = {
      id: undefined,
      name: undefined,
      primaryDeploymentTargetId: undefined,
      deploymentTargets: undefined,
      type: undefined
    }
    assign(this, defaultProps, props)
  }

}

export default DeploymentTargetGroup
