import * as React from 'react'
import './styles/base/main.scss' // Global styles
import './styles/base/common.scss' // Global styles
import CssBaseline from '@material-ui/core/CssBaseline';
import LoginPage from './components/login/LoginPage'
import KeycloakUnavailablePage from './components/login/KeycloakUnavailablePage'
import Routes from './routes'
import KeycloakSettings from './KeycloakSettings'
import KeycloakService from './services/KeycloakService'
import AuthService from './services/AuthService'
import {ThemeProvider} from '@material-ui/core/styles'
import GenericSnackbar from './components/GenericSnackbar'
import SnackbarService from './services/SnackbarService'
import UserService from './services/UserService'
import User from './models/User'
import MessageService from './services/MessageService'
import BundleService from './services/BundleService'
import Bundle from './models/Bundle'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import IStoreState from './store/IStoreState'
import ActionTypes from './store/actions'
import ProjectActions from './store/actions/projectActions'
import {loadDeploymentEnvironments} from './store/actions/deploymentEnvironmentActions'
import {loadProjectTypes} from './store/actions/projectTypeActions'
import {loadBundle} from './store/actions/bundleActions'
import {loadDeploymentTargets} from './store/actions/deploymentTargetActions'
import AppErrorBoundary from './AppErrorBoundary'
import { CascadeTheme } from './theme/lib/cascade.theme';
import CookieConsent from "react-cookie-consent";
import { linkReferences } from './config/links.config'
import Cookies from "universal-cookie"
import {throttle} from 'lodash'

const THROTTLE_WAIT: number = 2000

const KEYCLOAK_STATUS: any = {
  ERROR: 'ERROR',
  FETCHING: 'FETCHING',
  OK: 'OK',
}

export interface IAppProps {
  loadBundle?: () => void,
  loadProjects?: () => void,
  loadProjectTypes?: () => void,
  loadDeploymentEnvironments?: () => void,
  loadDeploymentTargets?: () => void,
  handleSuccessfulSignIn?: (user: User) => void,
  currentUser?: User
}

export interface IAppState {
  authenticated: boolean,
  keycloakInit: boolean,
  keycloakStatus: any,
  showCookieBar: boolean,
  snackbar: any,
}

export class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props)
    this.state = {
      authenticated: false,
      keycloakInit: false,
      keycloakStatus: null,
      showCookieBar: true,
      snackbar: {
        message: null,
        open: null,
        type: null
      },
    }
    SnackbarService.subscribeToChanges(this.onSnackbarChanged.bind(this))
    AuthService.subscribeToInterceptors(this.onUnauthenticatedResponseIntercepted.bind(this))
  }

  componentWillMount(): void {
    this.checkKeycloakServiceStatus()
  }

  componentWillUnmount(): void {
    SnackbarService.unsubscribeToChanges(this.onSnackbarChanged.bind(this))
    AuthService.unsubscribeFromInterceptors(this.onUnauthenticatedResponseIntercepted.bind(this))
  }

  checkKeycloakServiceStatus = (): void => {
    const url: string = KeycloakSettings.url
    this.setState({
      keycloakStatus: KEYCLOAK_STATUS.FETCHING,
    })
    fetch(url, {method: 'HEAD', mode: 'no-cors'})
      .then((response: Response) => {
        // response status 0 indicates an issue with CORS, which still indicates that the service is up
        // https://stackoverflow.com/questions/40182785/why-fetch-return-a-response-with-status-0
        if (response.status === 200 || response.status === 0) {
          this.setState({
            keycloakStatus: KEYCLOAK_STATUS.OK
          })
          this.initializeKeycloak()
        } else {
          this.setState({
            keycloakStatus: KEYCLOAK_STATUS.ERROR
          })
        }
      })
      .catch((err) => {
        this.setState({
          keycloakStatus: KEYCLOAK_STATUS.ERROR
        })
      })
  }

  onAppMouseOver = throttle(() => {
    AuthService.userActive = true
  }, THROTTLE_WAIT)

  render(): React.ReactNode {
    let component: React.ReactNode
    if (this.state.keycloakInit && !this.state.authenticated) {
      component = <LoginPage/>
    } else if (this.props.currentUser && this.state.keycloakInit && this.state.authenticated) {
      component = <Routes currentUser={this.props.currentUser}/>
    } else {
      component = <KeycloakUnavailablePage
        busy={this.state.keycloakStatus !== KEYCLOAK_STATUS.ERROR}
        retry={this.checkKeycloakServiceStatus}
      />
    }
    const { FEEDBACKLINK } = linkReferences
    return (
      <AppErrorBoundary>
        <ThemeProvider theme={CascadeTheme}>
          <CssBaseline />
          <div className={this.constructor.name} onMouseMoveCapture={this.onAppMouseOver}>
            {component}
            {this.state.snackbar.open && <GenericSnackbar
              open={this.state.snackbar.open}
              type={this.state.snackbar.type}
              message={this.state.snackbar.message}/>}
          </div>
          {this.state.showCookieBar && ( <CookieConsent buttonText={"Add your feedback"} containerClasses={"cookie-container"} cookieName={"MyCascade_Feedback"} expires={30} buttonClasses={"cookie-btn"}
                         enableDeclineButton declineButtonText={"Ignore"} declineButtonClasses={"cookie-btn-decline"} setDeclineCookie={false}
                         onDecline={() => {this.setState({ showCookieBar: false }); let tomorrow = new Date(); tomorrow.setDate(tomorrow.getDate() + 1); const cookies = new Cookies(); cookies.set("MyCascade_Feedback", false, { path: '/', expires: tomorrow})}}
                         onAccept={() => {window.open(FEEDBACKLINK.href, '_blank');}}>
            <a onClick={() => {window.open(FEEDBACKLINK.href, '_blank');}} className={"cookie-link"}>Please provide feedback on your Cascade experience. </a>
            After a one click rating you will be given a chance to provide comments.
          </CookieConsent> ) }
        </ThemeProvider>
      </AppErrorBoundary>
    )
  }

  private initializeMessageService = async (): Promise<void> => {
    const bundle: Bundle = await BundleService.getBundle()
    await MessageService.initMessageService(bundle.id)
  }

  private initializeKeycloak = (): void => {
    try {
      KeycloakService.init({onLoad: 'check-sso'}).success(this.postKeyCloakInitialized)
        .error((err) => {
          this.setState({
            authenticated: false,
            keycloakInit: true
          })
        })
    } catch (err) {
      // There is currently a issue in Keycloak.js that doesn't throw an error on init
      // https://issues.jboss.org/browse/KEYCLOAK-7187
      this.setState({
        authenticated: false,
        keycloakInit: false,
        keycloakStatus: KEYCLOAK_STATUS.ERROR
      })
    }
  }

  private onSnackbarChanged(snackbar: object): void {
    this.setState({snackbar})
  }

  private postKeyCloakInitialized = async (auth: any): Promise<void> => {
    AuthService.setBearerToken(KeycloakService.token)

    if (auth) {
      try {
        // @ts-ignore
        const user: User = await this.getUser(KeycloakService.idTokenParsed.preferred_username)
        this.props.handleSuccessfulSignIn(user)
        this.props.loadBundle()
        this.props.loadProjects()
        this.props.loadProjectTypes()
        this.props.loadDeploymentEnvironments()
        this.props.loadDeploymentTargets()
      } catch (err) {
        console.warn('Failed to load user')
        this.setState({ authenticated: false })
      }

      try {
        await this.initializeMessageService()
      } catch(err) {
        this.setState({})
      }
    }

    this.setState({
      authenticated: auth,
      keycloakInit: true,
    })
  }

  private onUnauthenticatedResponseIntercepted(): void {
    this.setState({
      authenticated: false
    })
  }

  private getUser(username: string): Promise<User> {
    return UserService.getUser(username)
  }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IAppProps): any => ({
  currentUser: state.currentUser
})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IAppProps): any => ({
  loadBundle: (): void => dispatch(loadBundle()),
  loadProjects: (): void => dispatch(ProjectActions.loadProjects()),
  loadProjectTypes: (): void => dispatch(loadProjectTypes()),
  loadDeploymentEnvironments: (): void => dispatch(loadDeploymentEnvironments()),
  loadDeploymentTargets: (): void => dispatch(loadDeploymentTargets()),
  handleSuccessfulSignIn: (currentUser: User): void => {
    dispatch({type: ActionTypes.SIGN_IN_SUCCESSFUL, payload: {currentUser}})
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
