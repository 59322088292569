import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inactiveStep: {
      opacity: 0.5,
    },
    activeHighlight: {
      backgroundColor: theme.palette.primary.main,
      height: '4px',
      borderRadius: '2px',
    },
    inactiveHighlight: {
      backgroundColor: theme.palette.grey[600],
      height: '4px',
      borderRadius: '2px',
    }
  }),
)
