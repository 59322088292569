import React from 'react'
import {Grid} from '@material-ui/core'
import {ProjectCreateBreadcrumb} from '../project-create/shared'
import {faFile, faHome} from '@fortawesome/free-solid-svg-icons'
import {SaveAndCancelButtons} from '../project-create/shared/save-and-cancel-buttons.component'
import {SaveAndCancelConfig} from './project-clone.scene'
import ActionTypes from '../../../store/actions'
import {Dispatch} from 'redux'
import {connect, ConnectedProps} from 'react-redux'
import IStoreState from 'src/store/IStoreState'
import {Breadcrumb} from "../project-details/shared";
import ProjectWizardActions from "../../../store/actions/projectWizardActions";
import ProjectActions from "../../../store/actions/projectActions";
import {router} from "../../../router";
import {appStates} from "../../../appStates";

const spacing = 3

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {}
export type ProjectCloneBannerProps = PropsFromRedux & ComponentProps


export const ProjectCloneBanner: React.FC<ProjectCloneBannerProps> = ({
                                                                        isSaveDisabled,
                                                                        pageName,
                                                                        isError,
                                                                        tooltip,
                                                                        handleDoneStep,
                                                                        handleClose
                                                                      }) => {
  const saveAndCancelConfig: SaveAndCancelConfig = {
    isSaveDisabled,
    isError,
    tooltip,
    handleSubmit: handleDoneStep,
    handleCancel: handleClose
  }

  return (
    <Grid container direction="column" wrap="nowrap" spacing={spacing}>
      <Breadcrumb context={'Dashboard'} path={pageName} icon={faHome} background={'Blue'} resetForm={handleClose}/>
      <Grid item container wrap="nowrap" justify="space-between" alignItems="center" style={{marginTop: '10px'}}>
        <Grid item>
          <ProjectCreateBreadcrumb path={pageName} icon={faFile}/>
        </Grid>
        <Grid item>
          <SaveAndCancelButtons config={saveAndCancelConfig}/>
        </Grid>
      </Grid>
    </Grid>
  )
}

const determineIfSaveIsDisabled = (state: IStoreState, ownProps: ComponentProps): boolean => {
  const name = state.projectWizard.project.name
  const domain = state.projectWizard.project.domain

  if (validField(name) && validField(domain)) {
    if (state.projectWizard.cloneFromProject?.hasCode) {
      return !state.projectWizard.isRepoPublic
    }
    return false
  }
  return true
}

const validField = (field: string): boolean => {
  return (!!field && field.trim() !== "" && field.length > 1)
}

const determinePageName = (state: IStoreState): string => {
  if (state.projectWizard.cloneFromProject !== undefined) {
    return `Clone Project ${state.projectWizard.cloneFromProject.name}`
  }

  if (state.projectWizard.project.id) {
    return 'Update Your Project'
  }

  return 'Add a New Project'
}

const setToolTipMsg = (state: IStoreState): string => {
  if (state.projectWizard?.cloneFromProject?.hasCode) {
    if (!state.projectWizard.isRepoPublic) {
      return 'Git repo must be set to Public'
    }
  }
  return ''
}

const setVisibilityError = (state: IStoreState): boolean => {
  if (state.projectWizard?.cloneFromProject?.hasCode) {
    return !state.projectWizard.isRepoPublic;
  }
  return false
}

const mapStateToProps = (state: IStoreState, ownProps: ComponentProps) => ({
  isSaveDisabled: determineIfSaveIsDisabled(state, ownProps),
  isError: setVisibilityError(state),
  pageName: determinePageName(state),
  tooltip: setToolTipMsg(state)
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ComponentProps) => ({
  handleDoneStep: () => {
    dispatch(ProjectWizardActions.cloneProject())
  },
  handleClose: (): void => {
    dispatch({type: ActionTypes.CLOSE_PROJECT_WIZARD})
    dispatch(ProjectActions.loadProjects())
    router.stateService.go(appStates.DASHBOARD)
  },
  checkVisibility: (value: boolean): void => {
    dispatch({type: ActionTypes.UPDATE_PROJECT_WIZARD_FIELD, payload: {"isRepoPublic": value}})
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedProjectCloneBanner = connector(ProjectCloneBanner)
