import initialState from '../initialState'
import ActionTypes from '../actions'
import {IProjectsState} from '../IStoreState'

export default function projectsReducer(state: IProjectsState = initialState.projects, action: any): IProjectsState {
  switch (action.type) {
    case ActionTypes.PROJECTS_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ActionTypes.PROJECTS_LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.projects,
        loading: false,
        error: null
      }
    case ActionTypes.PROJECTS_LOAD_FAIL:
      return {
        ...state,
        data: [],
        loading: false,
        error: true
      }
    default:
      return state
  }
}
