import {Action, Dispatch} from 'redux'
import ActionTypes from '.'
import DeploymentTarget from '../../models/DeploymentTarget'
import DeploymentTargetService from '../../services/DeploymentTargetService'

export function loadDeploymentTargets(): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.DEPLOYMENT_TARGETS_LOAD})
    try {
      const deploymentTargets: DeploymentTarget[] = await DeploymentTargetService.getDeploymentTargets(true)
      dispatch({type: ActionTypes.DEPLOYMENT_TARGETS_LOAD_SUCCESS, payload: {deploymentTargets}})
    } catch (error) {
      dispatch({type: ActionTypes.DEPLOYMENT_TARGETS_LOAD_FAIL, payload: {error}})
    }
  }
}

export function deleteDeploymentTargetById(id: string): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.DEPLOYMENT_TARGET_DELETE})
    try {
      await DeploymentTargetService.deleteDeploymentTarget(id)
      dispatch({type: ActionTypes.DEPLOYMENT_TARGET_DELETE_SUCCESS})
      dispatch(loadDeploymentTargets())
    } catch (error) {
      dispatch({type: ActionTypes.DEPLOYMENT_TARGET_DELETE_FAIL, payload: {error}})
    }
  }
}
