import React from 'react'
import PropTypes from 'prop-types'
import {Table, TableBody, Grid} from '@material-ui/core'
import TableHeader from './TableHeader'
import * as moment from 'moment'

export const sortDirections = {
  ASC: 'asc',
  DESC: 'desc',
}

class GenericTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: this.props.items,
      order: this.props.order,
      orderBy: this.props.orderBy,
      componentField: this.props.componentField
    }
  }

  componentDidMount = () => {
    this.sortItems(this.state.items, this.state.order, this.state.orderBy, this.state.componentField)
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.comparator) {
      if (!newProps.comparator(newProps.items, this.state.items)) {
        this.sortItems(newProps.items, this.state.order, this.state.orderBy, this.state.componentField)
      }
    } else {
      let {items} = this.state
      if (newProps.items !== items) {
        items = newProps.items
        this.setState({items})
      }
    }
  }

  handleRequestSort = (event, property, direction) => {
    this.updateOrder(direction, property)
  }

  updateOrder = (order, orderBy) => {
    order = order === sortDirections.DESC ? sortDirections.ASC : sortDirections.DESC

    this.props.columns.forEach((column) => {
      if (column.id === orderBy) {
        column.order = order
      }
    })

    this.sortItems(this.state.items, order, orderBy, this.state.componentField)
    this.setState({order, orderBy})
  }

  sortItems = (items, order, orderBy, componentField) => {

    items = order === sortDirections.DESC ?
      items.sort((a, b) => this.extractValue(a, items, orderBy, componentField) < this.extractValue(b, items, orderBy, componentField) ? 1 : -1) :
      items.sort((a, b) => this.extractValue(b, items, orderBy, componentField) < this.extractValue(a, items, orderBy, componentField) ? 1 : -1)

    this.setState({items})
  }

  extractValue = (index, items, orderBy, componentField) => {
    const dateFormat = ['YYYY-MM-DD HH:MM:SS', 'YYYY-MM-DD']

    if (componentField) {
      if (moment(items[0][componentField][orderBy], dateFormat).isValid()) {
        return moment(index[componentField][orderBy], dateFormat)?.valueOf()
      }
      return index[componentField][orderBy]?.toLowerCase()
    } else {
      if (moment(items[0][orderBy], dateFormat).isValid()) {
        return moment(index[orderBy], dateFormat)?.valueOf()
      }
      return index[orderBy]?.toLowerCase()
    }
  }

  handleRowClick = (item) => {
    this.props.handleRowClick && this.props.handleRowClick(item)
  }

  render() {
    return (
      this.state.items && this.state.items.length > 0 ? <Table>
        <TableHeader columns={this.props.columns} onRequestSort={this.handleRequestSort}
                     handleUserRoleGroupChange={this.props.handleUserRoleGroupChange}
                     order={this.state.order} orderBy={this.state.orderBy} {...this.props.headerProps}>

        </TableHeader>
        <TableBody>
          {this.state.items.map((item, index) => {
            const CustomRow = this.props.rowComponent
            return (
              <CustomRow key={index} item={item} handleRowActions={this.props.handleRowActions}
                         handleClick={this.handleRowClick} {...this.props.rowProps}/>
            )
          })}
        </TableBody>
      </Table> : <Grid container justify='center'>No matching users found</Grid>
    )
  }
}

GenericTable.propTypes = {
  items: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    label: PropTypes.string,
    sortable: PropTypes.bool,
  })).isRequired,
  filter: PropTypes.string,
  filterBy: PropTypes.arrayOf(PropTypes.string),
  handleRowClick: PropTypes.func,
  handleRowActions: PropTypes.object,
  rowComponent: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  headerProps: PropTypes.object,
  rowProps: PropTypes.object,
}

GenericTable.defaultProps = {
  filter: null,
  filterBy: null,
  headerProps: null,
  rowProps: null,
}

export default GenericTable
