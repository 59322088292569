import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Grid, Checkbox, Popper, Button, Paper } from '@material-ui/core'
import DeploymentTarget from '../../../models/DeploymentTarget'
import deploymentTargetService from '../../../services/DeploymentTargetService'
import './DeploymentEnvironmentsSection.scss'
import DeploymentTargetGroup from '../../../models/DeploymentTargetGroup'
import SnackbarService, { SNACKBAR_TYPES } from '../../../services/SnackbarService'
import { find, intersectionBy } from 'lodash'
import SearchField from '../../common/SearchField'
import IStoreState from '../../../store/IStoreState'
import { updateDeploymentTargetGroup } from '../../../store/actions/selectedEnvironmentActions'

export interface IDeploymentTargetGroupPopoverMenuProps {
  anchorEl: HTMLElement,
  deploymentTargetGroup: DeploymentTargetGroup,
  environmentId: string,
  handleClose: () => void,
  updateDeploymentTargetGroup?: (envId: string, updatedGroup: DeploymentTargetGroup) => void
}

export interface IDeploymentTargetGroupPopoverMenuState {
  filter: string,
  deploymentTargets: DeploymentTarget[],
  selectedDeploymentTargets: DeploymentTarget[],
  changed: boolean,
}

export class DeploymentTargetGroupPopoverMenu extends React.Component<IDeploymentTargetGroupPopoverMenuProps, IDeploymentTargetGroupPopoverMenuState> {

  constructor(props: IDeploymentTargetGroupPopoverMenuProps) {
    super(props)
    this.state = {
      filter: '',
      deploymentTargets: [],
      selectedDeploymentTargets: this.props.deploymentTargetGroup.deploymentTargets,
      changed: false,
    }
    this.loadDeploymentTargets()
  }

  loadDeploymentTargets = async (): Promise<void> => {
    const deploymentTargets: DeploymentTarget[] = await deploymentTargetService.getDeploymentTargets(true)
    const selectedDeploymentTargets: DeploymentTarget[] = intersectionBy(deploymentTargets, this.props.deploymentTargetGroup.deploymentTargets, 'id')
    this.setState({ deploymentTargets, selectedDeploymentTargets })
  }

  handleFilter = (event: any): void => {
    this.setState({ filter: event.target.value })
  }

  handleDeploymentTargetClick = (deploymentTarget: DeploymentTarget): void => {
    const { selectedDeploymentTargets } = this.state
    if (find(selectedDeploymentTargets, { id: deploymentTarget.id })) {
      selectedDeploymentTargets.splice(selectedDeploymentTargets.indexOf(deploymentTarget), 1)
    } else {
      selectedDeploymentTargets.push(deploymentTarget)
    }
    this.setState({ changed: true, selectedDeploymentTargets })
  }

  handleSaveClick = async (): Promise<void> => {
    const { deploymentTargetGroup, environmentId } = this.props
    const { selectedDeploymentTargets } = this.state

    try {
      await this.props.updateDeploymentTargetGroup(environmentId, new DeploymentTargetGroup({
        ...deploymentTargetGroup,
        deploymentTargets: selectedDeploymentTargets,
        primaryDeploymentTargetId: selectedDeploymentTargets[0] ? selectedDeploymentTargets[0].id : null
      }))
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Updated Deployment Target Group')
    } catch (e) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to update Deployment Target Group!')
    }
    this.props.handleClose()
  }

  allowSave = (): boolean => {
    if (this.state.changed) {
      return this.state.selectedDeploymentTargets.length < 1
    }
    return !this.state.changed
  }

  render(): React.ReactNode {
    return (
      <Popper open={true}
              anchorEl={this.props.anchorEl}
              placement='left-start'
              modifiers={{
                flip: { enabled: false }
              }}>
        <Paper>
          <Grid container className='edit-group-menu' spacing={2}>
            <Grid item xs={12}>
              <SearchField value={''} label='Filter Deployment Targets' onChange={this.handleFilter} />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {
                  this.state.deploymentTargets
                    .filter((deploymentTarget: DeploymentTarget) => deploymentTarget.name.toLowerCase().includes(this.state.filter.toLowerCase()))
                    .map((deploymentTarget: DeploymentTarget) => {
                      return (
                        <Grid item xs={12} key={deploymentTarget.id}>
                          <Checkbox onClick={this.handleDeploymentTargetClick.bind(this, deploymentTarget)}
                            checked={this.state.selectedDeploymentTargets.map((target: DeploymentTarget) => target.id).indexOf(deploymentTarget.id) > -1} color='primary' />
                          { deploymentTarget.name}
                        </Grid>
                      )
                    })
                }
              </Grid>
            </Grid>
            <Grid item xs={12} className='edit-group-menu-actions'>
              <Grid container justify='space-between'>
                <Grid item>
                  <Button size='small' variant='contained' color='secondary' onClick={this.props.handleClose} disableElevation>Cancel</Button>
                </Grid>
                <Grid item>
                  <Button size='small'
                          variant='contained'
                          color='primary'
                          onClick={this.handleSaveClick}
                          disabled={this.allowSave()}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Popper>
    )
  }
}

const mapStateToProps: any = (state: IStoreState): any => ({})

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  updateDeploymentTargetGroup: (envId: string, updatedGroup: DeploymentTargetGroup): void => dispatch(updateDeploymentTargetGroup(envId, updatedGroup)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentTargetGroupPopoverMenu)
