import React from 'react'
import PropTypes from 'prop-types'
import {Link, TableHead, TableSortLabel} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { StyledTableRow, StyledTableCell } from '../../../../dashboard/ProjectTable.config'
import { assign } from 'lodash'
import '../../../../common/TableHeader'
import Nbsp from "../../../../common/Nbsp";
import {PRIMARY} from "../../../../../theme/lib/color";

class ProjectUsersTableHeader extends React.Component {

  createSortHandler = (property,order) => (event) => {
    this.props.onRequestSort(event, property ,order)
  }

  getHeadingLabel(column) {
    const {order, orderBy} = this.props

    if (column.sortable) {
      return (
        <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
          <TableSortLabel active={true} direction={this.props.order} onClick={this.createSortHandler(column.id, order, orderBy)} className={orderBy === column.id? 'ActiveSort':''}>
            {column.label}
          </TableSortLabel>
        </Tooltip>
      )
    } else {
      return <TableSortLabel active={false} hideSortIcon={true}>{column.label}</TableSortLabel>
    }
  }

  handleSelectAllSelection = (label, value) => {
    this.props.onSelectAll(label, value)
  }


  render() {
    const {columns, style} = this.props
    const headerStyle = assign({textTransform: 'uppercase', fontSize: '14pt', paddingRight: '10px'}, style)
    const selectAllStyle = assign({color: PRIMARY["300"], fontSize: '8pt', fontWeight: 800}, style)
    const selectStyle = assign({color: PRIMARY["600"], fontSize: '8pt', fontWeight: 1000}, style)

    return (

      <TableHead>
        <StyledTableRow>
          {columns.map((column) => {
            return (

              <StyledTableCell key={column.id} numeric={column.numeric} padding={column.disablePadding ? 'none' : 'default'}
                               sortDirection={this.props.order} style={headerStyle}>
                {this.getHeadingLabel(column)}
                {column.id !== 'username' &&
                <div style={selectStyle}>Select:<Nbsp/>
                  <Link style={selectAllStyle} component="button" variant="body2"
                        onClick={this.handleSelectAllSelection.bind(this, column.label, true)}>All</Link><Nbsp/>|<Nbsp/>
                  <Link style={selectAllStyle} component="button" variant="body2"
                        onClick={this.handleSelectAllSelection.bind(this, column.label, false)}>None</Link>
                </div>
                }
              </StyledTableCell>

            )
          }, this)}
        </StyledTableRow>
      </TableHead>
    )
  }
}

ProjectUsersTableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    numeric: PropTypes.bool.isRequired,
    disablePadding: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    sortable: PropTypes.bool.isRequired,
  })).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  style: PropTypes.object,
  onSelectAll: PropTypes.func
}

ProjectUsersTableHeader.defaultProps = {
  style: {},
}

export default ProjectUsersTableHeader
