import { DataProcessIO, DataProcessorError, ProjectValidationError, createProcessIO } from 'src/helpers/shared'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import Project from 'src/models/Project'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import { createProjectEnvironmentWithNewAssetGroupAndSymlink } from './asset/env-asset-processor'
import { resolveProjectName, resolveDeploymentEnvironmentById } from '../../shared'
import { ProjectEnvironmentProcessConfig } from './environment-processor'

export interface ProjectProcessContext {
  project: Project
  deploymentEnvironments: DeploymentEnvironment[]
  config: ProjectEnvironmentProcessConfig
}

export type ProjectProcessIO = DataProcessIO<Project, DataProcessorError>

export type EnvironmentProcessIO = DataProcessIO<ProjectEnvironment, DataProcessorError>

export function createNextAssetGroupAndSymlinksForEnvironment(
  env: ProjectEnvironment,
  projectName: string,
  deploymentEnvironments: DeploymentEnvironment[],
  index: number,
): EnvironmentProcessIO {
  let output: DataProcessIO<ProjectEnvironment, DataProcessorError> = createProcessIO(env, [])
  const deploymentEnvironment = resolveDeploymentEnvironmentById(env.environmentId, deploymentEnvironments)
  const context = {
    index,
    projectName,
    deploymentEnvironment,
  }
  output = createProjectEnvironmentWithNewAssetGroupAndSymlink(output, context)
  return output
}

export function addNewAssetGroupAndSymlinks(
  input: ProjectProcessIO,
  context: Omit<ProjectProcessContext, 'config'>,
): ProjectProcessIO {
  const project = input.value
  if (project) {
    const projectName = resolveProjectName(project)
    const { deploymentEnvironments } = context
    const updatedEnvironments = project.projectEnvironments.map((env: ProjectEnvironment, index: number) => {
      const environmentOutput = createNextAssetGroupAndSymlinksForEnvironment(
        env,
        projectName,
        deploymentEnvironments,
        index
      )
      return environmentOutput.value
    })
    project.projectEnvironments = updatedEnvironments
    return createProcessIO(project, input.errors)
  }
  return createProcessIO(null, [...input.errors, ProjectValidationError.UNDEFINED_FUNC_INPUT])
}
