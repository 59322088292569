import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { CUSTOM } from 'src/theme/lib/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    textFieldLabel: { fontWeight: 'bold', color: CUSTOM.gray[800] },
    infoIcon: { color: CUSTOM.gray[800] },
  }),
)
