import React from 'react'
import { Grid, MenuItem, Select, Typography } from '@material-ui/core'
import { PROJECT_TYPE } from 'src/models/Project'
import { ConnectedCreateProjectDetailsForm, FORM_STEP_TYPE } from '../forms'
import { useStyles } from './drupal9-details.config'
import { Dispatch } from 'redux'
import {
  createProjectDataUpdateProjectCodeRepoByNameInput,
  createProjectDataUpdateProjectDatabaseNameByNameInput,
} from 'src/store/create-project-data/create-project-data.actions'
import { connect, ConnectedProps } from 'react-redux'
import IStoreState from 'src/store/IStoreState'
import { DetailsFormNextButton } from '../shared/details-form-next-button.component'

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {
  setActiveStep: (step: FORM_STEP_TYPE) => void
}
export type Drupal9FormProps = ComponentProps & PropsFromRedux

export const Drupal9DetailsForm: React.FC<Drupal9FormProps> = ({ projectName, projectDomain, setActiveStep, updateProjectDataRelatedToName }) => {
  const fieldSpacing = 3
  const goToNextStep = () => {
    updateProjectDataRelatedToName()
    const nextStep = FORM_STEP_TYPE.ENV
    setActiveStep(nextStep)
  }
  const isNextDisabled = !(projectName && projectDomain)
  return (
    <Grid container item direction="column" spacing={fieldSpacing} justify="space-between">
      <ConnectedCreateProjectDetailsForm />
      <ProjectTypeField typeTitle="Drupal 9" projectType={PROJECT_TYPE.DRUPAL_9} />
      <Grid item container justify="flex-end">
        <DetailsFormNextButton onClick={goToNextStep} disabled={isNextDisabled} />
      </Grid>
    </Grid>
  )
}

interface ProjectTypeFieldProps {
  projectType: PROJECT_TYPE
  typeTitle: string
}

const ProjectTypeField: React.FC<ProjectTypeFieldProps> = ({ projectType, typeTitle }) => {
  const styles = useStyles()
  const textFieldVariant = 'subtitle2'
  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography variant={textFieldVariant} className={styles.textFieldLabel}>
          Type
        </Typography>
      </Grid>
      <Grid item>
        <Select variant="outlined" disabled={true} value={projectType} inputProps={{ name: 'type' }} fullWidth>
          <MenuItem value={projectType}>{typeTitle}</MenuItem>
        </Select>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state: IStoreState) => ({
  projectName: state.createProjectData.project.name,
  projectDomain: state.createProjectData.project.domain,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProjectDataRelatedToName: () => {
    dispatch(createProjectDataUpdateProjectCodeRepoByNameInput())
    dispatch(createProjectDataUpdateProjectDatabaseNameByNameInput())
  },
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedDrupal9DetailsForm = connector(Drupal9DetailsForm)
