export default class PathUtils {
  static FORWARD_SLASH: string = '/'
  static NEW_LINE: string = '\n'

  static removeTrailingSlash(path: string): string {
    if (!path) {
      return ''
    }
    return path.endsWith(PathUtils.FORWARD_SLASH) ? path.slice(0, path.length - 1) : path
  }

  static getParentDirPath(path: string): string {
    const pathNoTrailingSlash: string = PathUtils.removeTrailingSlash(path)
    if (!pathNoTrailingSlash) {
      return ''
    }
    return pathNoTrailingSlash.slice(0, path.lastIndexOf(PathUtils.FORWARD_SLASH))
  }

  /**
   * wrapDirPath - Adds newlines to the input path after the "/" character within
   *               file paths at regular intervals denoted by width
   * @param path - The string containing the file path
   * @param width - The width at which to add newlines, but only at close by "/" characters
   */
  static wrapDirPath(path: string, width: number): string {
    const proximityComparer = .9
    let wrappedPath = ''
    let newLineIndices: number[] = []

    for(let i=0; i<path.length; i++) {
      if (path[i] === PathUtils.FORWARD_SLASH) {
        let div = i/width
        let divisibleByWidthOccurences: number = Math.floor(div)
        let proximity = div - divisibleByWidthOccurences

        //If the position of the "/" character is within 90% of the width then add a newline.
        //Also if we blow by the width without a newline yet then add one.
        if (proximity >= proximityComparer ||
            (divisibleByWidthOccurences > newLineIndices.length)) {
          newLineIndices.push(i)
        }
      }
    }

    if (newLineIndices.length > 0) {
      let splitValues = this.splitOn(path, ...newLineIndices)
      splitValues.forEach((value: string) => {
        wrappedPath += value + PathUtils.NEW_LINE
      })
    } else {
      wrappedPath = path
    }
    return wrappedPath.trim()
  }

  /**
   * splitOn - Given the input string slicable, returns an array of strings
   *           representing slicable split on indices - an array of indices
   *
   * @param slicable - String to split
   * @param indices - indices to split slicable on
   *
   * ex: slicable: "Some string to slice"
   *     indices: [3, 7]
   *     result: ['Some', ' str', 'ing to slice']
   */
  static splitOn(slicable: string, ...indices: number[]): string[] {
    return [0, ...indices].map((value, index, list) => slicable.slice(value, list[index + 1]));
  }
}
