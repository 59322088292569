import React from 'react'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import {PipelineTemplate} from 'src/models/pipeline/PipelineTemplate'
import {faEdit, faEllipsisV, faTrash} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {IconButton, Menu} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import IStoreState from 'src/store/IStoreState'
import ActionTypes from 'src/store/actions'
import ConfirmDialog from 'src/components/common/ConfirmDialog'
import {PipelineTemplateActions} from 'src/store/actions/pipelineTemplateActions'
import './PipelineTemplateActionsMenu.scss'

export interface IPipelineTemplateActionsMenuProps {
  pipelineTemplate: PipelineTemplate,
  handleEdit?: (pipelineTemplate: PipelineTemplate) => void,
  handleDelete?: (pipelineTemplate: PipelineTemplate) => void,
  loadProjectPipelineTemplates?: (id: string) => void
}

export interface IPipelineTemplateActionsMenuState {
  openActionsList: boolean,
  confirmDelete: boolean
}

export class PipelineTemplateActionsMenu extends React.Component<IPipelineTemplateActionsMenuProps, IPipelineTemplateActionsMenuState> {
  actionsListAnchor: React.RefObject<any> = React.createRef()

  constructor(props: IPipelineTemplateActionsMenuProps) {
    super(props)
    this.state = {
      openActionsList: false,
      confirmDelete: false
    }
  }

  handleActionsClick = (event: any): void => {
    if(event !== undefined){
      event.stopPropagation()
    }
    this.setState({openActionsList: this.state.openActionsList === true? false : true})
  }

  handleActionsClose = (event: any): void => {
    if(event !== undefined){
      event.stopPropagation()
    }
    this.setState({openActionsList: false})
  }

  processDelete = async (): Promise<void> => {
    await this.setState({confirmDelete: false})
    await this.props.handleDelete(this.props.pipelineTemplate)
    this.props.loadProjectPipelineTemplates(this.props.pipelineTemplate.projectId)
  }

  handleEditClick = (): void => {
    this.setState({openActionsList: false})
    this.props.handleEdit(this.props.pipelineTemplate)
  }

  handleDeleteClick = (): void => {
    this.setState({openActionsList: false, confirmDelete: true})
  }

  closeConfirmDeleteModal = (): void => {
    this.setState({confirmDelete: false})
  }

  render(): React.ReactNode {
    return (
      <div className={'pipeLine-actionMenu'} onMouseLeave={this.handleActionsClose}>
        <IconButton aria-haspopup='true' onClick={this.handleActionsClick}>
          <span ref={this.actionsListAnchor}><FontAwesomeIcon icon={faEllipsisV} size='xs'/></span>
        </IconButton>
        <div className={'pipeLine-actionList'} hidden={!this.state.openActionsList}>
          <MenuItem onClick={this.handleEditClick}>
            <ListItemIcon className={'muiListItemIconW'}><FontAwesomeIcon icon={faEdit}/></ListItemIcon>Edit
          </MenuItem>
          <MenuItem onClick={this.handleDeleteClick}>
            <ListItemIcon className={'muiListItemIconW'}><FontAwesomeIcon icon={faTrash}/></ListItemIcon>Delete
          </MenuItem>
        </div>
        {this.state.confirmDelete &&
        <ConfirmDialog open title={`Delete Pipeline?`} message={'Are you sure you want to delete this projectEvent?'}
                       onConfirm={this.processDelete} onCancel={this.closeConfirmDeleteModal}/>
        }
      </div>
    )
  }
}

const mapStateToProps: any = (state: IStoreState): any => ({})

const mapDispatchToProps: any = (dispatch: Dispatch, ownProps: IPipelineTemplateActionsMenuProps): any => ({
  handleEdit: (pipelineTemplate: PipelineTemplate): void => {
    dispatch({type: ActionTypes.PIPELINE_TEMPLATE_EDITOR_OPEN, payload: {pipelineTemplate}})
  },
  handleDelete: (pipelineTemplate: PipelineTemplate): void => dispatch(PipelineTemplateActions.deletePipelineTemplate(pipelineTemplate)),
  loadProjectPipelineTemplates: (id: string): void => dispatch(PipelineTemplateActions.loadPipelineTemplates(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PipelineTemplateActionsMenu)

