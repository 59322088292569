import React from 'react'
import PropTypes from 'prop-types'

import {TableCell, TableHead, TableRow, Link, Switch} from '@material-ui/core'
import {assign} from 'lodash'
import { PRIMARY} from "../../../theme/lib/color";
import { CUSTOM } from "../../../theme/lib/color";
import Nbsp from "../../common/Nbsp";

class EditUserTableHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      roleGroupsGlobal: []
    }
  }

  handleGlobalSelection = (groupId) => {
    const group = this.getGroupById(groupId)
    group.userHasGroup = !group.userHasGroup
    this.props.handleUserRoleGroupChange(group)
  }

  handleSelectAllSelection = (roleId, selected) => {
    this.props.handleUserSelectAllChange(roleId, selected)
  }

  getGroupById = (groupId) => {
    if (this.props.userRoleGroups && this.props.userRoleGroups.length > 0) {
      return this.props.userRoleGroups.find((group) => {
        return group.group.id === groupId
      })
    }

  }

  render() {
    const {columns, order, orderBy, style} = this.props
    const headerStyle = assign({color: PRIMARY["600"], fontSize: '12pt', paddingRight: '10px'}, style)
    const selectAllStyle = assign({color: PRIMARY["300"], fontSize: '12pt'}, style)
    const globalStyle = assign({color: PRIMARY["600"], fontSize: '12pt'}, style)
    const globalStyleChecked = assign({color: PRIMARY["600"], fontSize: '12pt',marginTop:'24px'}, style)
    const globalButtonStyle = assign({color: CUSTOM.gray["200"], fontSize: '12pt'}, style)
    return (
      <TableHead>
        <TableRow>
          {columns.map((column) => {
            const isChecked = this.getGroupById(column.groupId)?.userHasGroup || false
            return (
              <TableCell key={column.id} padding={column.disablePadding ? 'none' : 'default'}
                         sortDirection={orderBy === column.id ? order : false} style={headerStyle}>
                <div style={{color:CUSTOM.gray["800"],fontWeight:'900',fontSize:'12pt'}}>{column.label}</div>
                {column.selectAll && !isChecked &&
                <div>Select:<Nbsp/>
                  <Link style={selectAllStyle} component="button" variant="body2"
                        onClick={this.handleSelectAllSelection.bind(this, column.id, true)}>All</Link><Nbsp/>|<Nbsp/>
                  <Link style={selectAllStyle} component="button" variant="body2"
                        onClick={this.handleSelectAllSelection.bind(this, column.id, false)}>None</Link>
                </div>}
                {column.global &&
                <div style={isChecked ?globalStyleChecked:globalStyle}>
                  Global<Switch style={globalButtonStyle}
                                checked={isChecked}
                                onChange={this.handleGlobalSelection.bind(this, column.groupId)}/>
                </div>}
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EditUserTableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    numeric: PropTypes.bool.isRequired,
    disablePadding: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  style: PropTypes.object,
}

EditUserTableHeader.defaultProps = {
  style: {},
}

export default EditUserTableHeader
