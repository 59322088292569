import React from 'react'
import {FormControlLabel, FormLabel, Grid, Input, Radio, RadioGroup} from '@material-ui/core'
import DeploymentTarget, {LOGIN_AUTH_TYPE} from '../../../models/DeploymentTarget'
import FormField from '../../common/FormField'
import User from '../../../models/User'

export interface IDeploymentTargetWizardStep1Props {
  handleDeploymentTargetPropertyChange: (key: string, value: any) => void,
  deploymentTarget: DeploymentTarget,
  disableFields: boolean,
  errorFields: Map<string, string>,
  showIpField?: boolean,
  isNewDeploymentTarget: boolean,
  currentUser: User
}

export interface IDeploymentTargetWizardStep1State {

}

export class DeploymentTargetWizardStep1 extends React.Component<IDeploymentTargetWizardStep1Props, IDeploymentTargetWizardStep1State> {
  static defaultProps: any = {
    showIpField: false
  }

  handleInputChange = (event: any): void => {
    const target: any = event.target
    this.props.handleDeploymentTargetPropertyChange(target.name, target.value)
  }

  render(): React.ReactNode {
    const {deploymentTarget, disableFields, errorFields, isNewDeploymentTarget, showIpField, currentUser} = this.props

    return (
      <Grid container direction='column' spacing={2}>
        <Grid item sm={12}>
          <FormLabel component='legend'>Please enter the details of the Deployment Target you are adding.</FormLabel>
        </Grid>
        <Grid item sm={12}>
          <FormField label='Nickname' error={errorFields.has('name')} message={errorFields.get('name')} mandatory={true}>
            <Input name='name' disabled={disableFields} value={deploymentTarget.name} onChange={this.handleInputChange}/>
          </FormField>
        </Grid>
        <Grid item sm={12}>
          <FormField label='Hostname' error={errorFields.has('fqdn')} message={errorFields.get('fqdn')} mandatory={true}>
            <Input name='fqdn' disabled={disableFields} value={deploymentTarget.fqdn} onChange={this.handleInputChange}/>
          </FormField>
        </Grid>
        {showIpField &&
        <Grid item sm={12}>
          <FormField label='IP Address' error={errorFields.has('ipAddress')} message={errorFields.get('ipAddress')} mandatory={true}>
            <Input name='ipAddress' disabled={disableFields || !currentUser.canAdministerDeploymentTarget()} value={deploymentTarget.ipAddress} onChange={this.handleInputChange}/>
          </FormField>
        </Grid>
        }
        <Grid item sm={12}>
          <FormField label='Port' error={errorFields.has('port')} message={errorFields.get('port')} mandatory={true}>
            <Input name='port' disabled={disableFields} value={deploymentTarget.port} onChange={this.handleInputChange}/>
          </FormField>
        </Grid>
        <Grid item sm={12}>
          <FormField label='Remote User' error={errorFields.has('remoteUser')} message={errorFields.get('remoteUser')} mandatory={true}>
            <Input name='remoteUser' disabled={disableFields} value={deploymentTarget.remoteUser} onChange={this.handleInputChange}/>
          </FormField>
        </Grid>
        <Grid item sm={12}>
          <br/>
          <FormField error={errorFields.has('loginAuthType')} message={errorFields.get('loginAuthType')} mandatory={true}>
            <FormLabel component='legend'>Will you be using a password or a private key to login?</FormLabel>
            <RadioGroup aria-label='loginAuthType' name='loginAuthType' style={{display: 'inline-block'}} value={deploymentTarget.loginAuthType} onChange={this.handleInputChange}>
              {!isNewDeploymentTarget && !currentUser.canAdministerDeploymentTarget() &&
                <FormControlLabel value={LOGIN_AUTH_TYPE.STORED} disabled={disableFields} control={<Radio color='primary'/>} label='Use Stored Credentials'/>
              }
              <FormControlLabel value={LOGIN_AUTH_TYPE.PASSWORD} disabled={disableFields} control={<Radio color='primary'/>} label='Password'/>
              <FormControlLabel value={LOGIN_AUTH_TYPE.PRIVATE_KEY} disabled={disableFields} control={<Radio color='primary'/>} label='Private Key'/>
            </RadioGroup>
          </FormField>
        </Grid>
      </Grid>
    )
  }
}


export default DeploymentTargetWizardStep1
