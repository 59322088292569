import React from 'react'
import { Grid, IconButton } from '@material-ui/core'
import UsersTable from './UsersTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserModal from './UserModal'
import {findIndex, remove} from 'lodash'
import UserService from '../../../services/UserService'
import RetryLoading from '../../common/RetryLoading'
import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons'
import User from '../../../models/User'
import Spinner from '../../common/Spinner'
import SearchField from '../../common/SearchField'

export interface IUsersSectionProps { }

export interface IUsersSectionState {
  openUserModal: any,
  selectedUser: any,
  filter: string,
  users: User[],
  error: boolean,
  loading: boolean,
}

export class UsersSection extends React.Component<IUsersSectionProps, IUsersSectionState> {
  private filterKeys: string[] = ['username', 'name.givenName', 'name.familyName']

  constructor(props: IUsersSectionProps) {
    super(props)
    this.state = {
      openUserModal: null,
      selectedUser: null,
      filter: '',
      users: [],
      error: false,
      loading: true,
    }
  }

  componentWillMount(): void {
    this.loadUsers()
  }

  loadUsers = async (): Promise<void> => {
    try {
      const users: User[] = await UserService.getUsers()
      this.setState({users, loading: false, error: false})
    } catch (error) {
      this.setState({error: true, loading: false})
    }
  }

  reloadData = (): void => {
    this.setState({error: false})
    this.loadUsers()
  }

  handleFilterChange = (event: any): void => {
    this.setState({filter: event.target.value})
  }

  handleUserModalOpen = (): void => this.setState({selectedUser: null, openUserModal: true})
  handleUserModalClosed = (): void =>
      this.setState({selectedUser: null, openUserModal: false})


  handleNewUser = (newUser: User): void => {
    const {users} = this.state
    users.push(newUser)
    this.setState({
      users,
      selectedUser: newUser,
    })
  }

  handleSavedUser = (updatedUser: User): void => {
    const {users} = this.state
    users.splice(findIndex(users, {'username': updatedUser.username}), 1, updatedUser)
    this.setState({users})
  }

  handleDeletedUser = (username: string): void => {
    const {users} = this.state
    remove(users, {username})
    this.setState({users})
  }

  handleUserRowClick = (user: User): void => {
    this.setState({selectedUser: user, openUserModal: true})
  }

  render(): React.ReactNode {
    return (
        <Grid container>
          <Grid item sm={12}>
            <Grid container alignItems='center'>
              <Grid item sm={6}>
                <Grid container direction='row' alignItems='center' spacing={3}>
                  <Grid item>
                    <FontAwesomeIcon icon={faUser} style={{transform: 'scale(1.6)'}}/>
                  </Grid>
                  <Grid item>
                    <h2>Users</h2>
                  </Grid>
                  <Grid item>
                    <IconButton style={{marginBottom: '4px'}} title='Create New User'
                                onClick={this.handleUserModalOpen}>
                      <FontAwesomeIcon icon={faPlus} size='sm' className={'clickable'}/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6} className='right'>
                <SearchField value={this.state.filter} id={'editSearch'} onChange={this.handleFilterChange}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            {this.state.loading && <Grid container justify='center'><Spinner large/></Grid>}
            {this.state.error &&
            <RetryLoading message='Could not load users.' reloadData={this.reloadData}/>
            }
            {this.state.users.length === 0 && !this.state.error && !this.state.loading &&
            <RetryLoading message='Could not find any users. Add one using the plus (+) icon above or refresh the list.'
                          reloadData={this.reloadData}/>
            }
            {!this.state.error && this.state.users.length === 0 && this.state.filter && this.state.filter.length > 0 &&
            <Grid container justify='center'>No user match your search</Grid>}
            {!this.state.loading && !this.state.error && this.state.users && this.state.users.length > 0 &&
            <UsersTable
                filter={this.state.filter}
                filterBy={this.filterKeys}
                handleUserRowClick={this.handleUserRowClick}
                users={this.state.users}/>
            }
          </Grid>
          {this.state.openUserModal &&
          <UserModal
              handleModalClose={this.handleUserModalClosed}
              closeModal={this.handleUserModalClosed}
              user={this.state.selectedUser}
              users={this.state.users}
              openModal={this.state.openUserModal}
              handleNewUser={this.handleNewUser}
              handleSavedUser={this.handleSavedUser}
              handleDeletedUser={this.handleDeletedUser}
          />
          }
        </Grid>
    )
  }
}

export default UsersSection
