import {Action, Dispatch} from 'redux'
import ActionTypes from '.'
import DeploymentTargetGroupService from '../../services/DeploymentTargetGroupService'
import DeploymentTargetGroup from '../../models/DeploymentTargetGroup'
import DeploymentEnvironment from '../../models/DeploymentEnvironment'
import DeploymentEnvironmentService from '../../services/DeploymentEnvironmentService'
import SnackbarService, {SNACKBAR_TYPES} from '../../services/SnackbarService'
import MessageService from '../../services/MessageService'
import {loadDeploymentEnvironments} from './deploymentEnvironmentActions'

export function setSelectedEnvironment(id: string): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch(loadSelectedEnvironment(id))
  }
}

export function loadDeploymentTargetGroupsForSelectedEnvironment(environment: DeploymentEnvironment): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.SELECTED_ENVIRONMENT_GROUPS_LOAD})
    try {
      const deploymentTargetGroups: DeploymentTargetGroup[] = await Promise.all(
        environment.deploymentTargetGroups.map( (group: DeploymentTargetGroup) => {
          return DeploymentTargetGroupService.getDeploymentTargetGroup(environment.id, group.id)
        }))
      dispatch({type: ActionTypes.SELECTED_ENVIRONMENT_GROUPS_LOAD_SUCCESS, payload: {deploymentTargetGroups}})
    } catch (error) {
      dispatch({type: ActionTypes.SELECTED_ENVIRONMENT_GROUPS_LOAD_FAIL, payload: {error}})
    }
  }
}

export function loadSelectedEnvironment(id: string): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.SELECTED_ENVIRONMENT_LOAD})
    try {
      const environment: DeploymentEnvironment = await DeploymentEnvironmentService.getDeploymentEnvironmentById(id)
      dispatch({type: ActionTypes.SELECTED_ENVIRONMENT_LOAD_SUCCESS, payload: {environment}})
      dispatch(loadDeploymentTargetGroupsForSelectedEnvironment(environment))
    } catch (error) {
      dispatch({type: ActionTypes.SELECTED_ENVIRONMENT_LOAD_FAIL, payload: {error}})
    }
  }
}

export function createDeploymentTargetGroup(envId: string, groupToSave: DeploymentTargetGroup): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.DEPLOYMENT_TARGET_GROUP_CREATE})
    try {
      const savedGroup: DeploymentTargetGroup = await DeploymentTargetGroupService.createDeploymentTargetGroup(envId, groupToSave)
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, MessageService.get('admin.deploymenttargetgroup.success.create'))
      dispatch({type: ActionTypes.DEPLOYMENT_TARGET_GROUP_CREATE_SUCCESS, payload: {savedGroup}})
      dispatch(loadSelectedEnvironment(envId))
      dispatch(loadDeploymentEnvironments())
    } catch (error) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, MessageService.get('admin.deploymenttargetgroup.error.create'))
      dispatch({type: ActionTypes.DEPLOYMENT_TARGET_GROUP_CREATE_FAIL, payload: {error}})
    }
  }
}

export function updateDeploymentTargetGroup(envId: string, updatedGroup: DeploymentTargetGroup): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.DEPLOYMENT_TARGET_GROUP_UPDATE})
    try {
      const savedGroup: DeploymentTargetGroup = await DeploymentTargetGroupService.updateDeploymentTargetGroup(envId, updatedGroup)
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, MessageService.get('admin.deploymenttargetgroup.success.generic'))
      dispatch({type: ActionTypes.DEPLOYMENT_TARGET_GROUP_UPDATE_SUCCESS, payload: {savedGroup}})
      dispatch(loadSelectedEnvironment(envId))
      dispatch(loadDeploymentEnvironments())
    } catch (error) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, MessageService.get('admin.deploymenttargetgroup.error.generic'))
      dispatch({type: ActionTypes.DEPLOYMENT_TARGET_GROUP_UPDATE_FAIL, payload: {error}})
    }
  }
}

export function deleteDeploymentTargetGroupById(envId: string, targetGroupId:string): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.DEPLOYMENT_TARGET_GROUP_DELETE})
    try {
      await DeploymentTargetGroupService.deleteDeploymentTargetGroup(envId, targetGroupId)
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, MessageService.get('admin.deploymenttargetgroup.delete.success.generic'))
      dispatch({type: ActionTypes.DEPLOYMENT_TARGET_GROUP_DELETE_SUCCESS})
      dispatch(loadSelectedEnvironment(envId))
      dispatch(loadDeploymentEnvironments())
    } catch (error) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, MessageService.get('admin.deploymenttargetgroup.delete.error.generic'))
      dispatch({type: ActionTypes.DEPLOYMENT_TARGET_GROUP_DELETE_FAIL, payload: {error}})
    }
  }
}
