import React from 'react'
import ProjectEditCode from '../section/code/project-code.component'
import ProjectEditLinks from '../section/links/project-links.component'
import ProjectEditAssets from '../section/assets/project-assets.component'
import ProjectOverview from '../section/overview/project-overview.component'
import ProjectEditDetails from '../section/details/project-details.component'
import ProjectEditDatabase from '../section/databases/project-databases.component'
import ProjectEditEnvironment from '../section/environments/project-environments.component'
import ProjectUsers from '../section/users/project-users.component'
import { ProjectPipelines } from '../section/pipelines/project-overview-pipelines.component'
import { SectionPlaceholder } from './placeholder.component'
import { MenuSection } from '../menu/section-menu.config'

export interface InfoSectionProps {
  activeSection: MenuSection
  isDataLoaded: boolean
}

export const InfoSection: React.FC<InfoSectionProps> = ({ activeSection, isDataLoaded }) => {
  if (isDataLoaded) {
    switch (activeSection) {
      case MenuSection.PROJECT_DETAILS:
        return <ProjectEditDetails />
      case MenuSection.USERS:
        return <ProjectUsers />
      case MenuSection.ENVIRONMENT:
        return <ProjectEditEnvironment />
      case MenuSection.CODE:
        return <ProjectEditCode />
      case MenuSection.ASSETS:
        return <ProjectEditAssets />
      case MenuSection.LINKS:
        return <ProjectEditLinks />
      case MenuSection.DATABASES:
        return <ProjectEditDatabase />
      case MenuSection.OVERVIEW:
        return <ProjectOverview />
      case MenuSection.PIPELINES:
        return <ProjectPipelines />
      default:
        return <SectionPlaceholder />
    }
  }
  return <SectionPlaceholder />
}
