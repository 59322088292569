import React from 'react'
import {FormControl, InputLabel, FormHelperText, FormLabel} from '@material-ui/core'
import MessageService from '../../services/MessageService'
import './FormField.scss'

interface IFormFieldProps {
  label?: string,
  message?: string,
  error?: boolean,
  mandatory:boolean,
  children: any,
}

interface IFormFieldState {

}

class FormField extends React.Component<IFormFieldProps, IFormFieldState> {
  static defaultProps: Partial<IFormFieldProps> = {
    error: false
  }

  render(): React.ReactNode {
    const {label, message, error, mandatory,children} = this.props

    return (
      <FormControl error={error} className='form-field'>
        {label && <InputLabel>{MessageService.get(label)}{mandatory &&<FormLabel style={{ marginLeft: '3px',color: 'red'}}>*</FormLabel>}</InputLabel>}
        {children}
        {message && <FormHelperText className='form-field-helper-text' dangerouslySetInnerHTML={{__html: message}} />}
      </FormControl>
    )
  }
}

export default FormField;
