import initialState from '../initialState'
import ActionTypes from '../actions'
import {ISelectedEnvironmentState} from '../IStoreState'

export default function selectedEnvironmentReducer(state: ISelectedEnvironmentState = initialState.selectedEnvironment, action: any): ISelectedEnvironmentState {
  switch (action.type) {
    case ActionTypes.SELECTED_ENVIRONMENT_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ActionTypes.SELECTED_ENVIRONMENT_LOAD_SUCCESS:
      return {
        ...state,
        environment: action.payload.environment,
        loading: false,
        error: null
      }
    case ActionTypes.SELECTED_ENVIRONMENT_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case ActionTypes.SELECTED_ENVIRONMENT_GROUPS_LOAD:
      return {
        ...state,
        error: null
      }
    case ActionTypes.SELECTED_ENVIRONMENT_GROUPS_LOAD_SUCCESS:
      return {
        ...state,
        deploymentTargetGroups: action.payload.deploymentTargetGroups,
        error: null
      }
    case ActionTypes.SELECTED_ENVIRONMENT_GROUPS_LOAD_FAIL:
      return {
        ...state,
        error: action.payload.error
      }
    case ActionTypes.DEPLOYMENT_TARGET_GROUP_CREATE:
      return {
        ...state,
        error: null
      }
    case ActionTypes.DEPLOYMENT_TARGET_GROUP_CREATE_SUCCESS:
      return {
        ...state,
        error: null
      }
    case ActionTypes.DEPLOYMENT_TARGET_GROUP_CREATE_FAIL:
      return {
        ...state,
        error: action.payload.error
      }
    case ActionTypes.DEPLOYMENT_TARGET_GROUP_UPDATE:
      return {
        ...state,
        error: null
      }
    case ActionTypes.DEPLOYMENT_TARGET_GROUP_UPDATE_SUCCESS:
      return {
        ...state,
        error: null
      }
    case ActionTypes.DEPLOYMENT_TARGET_GROUP_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload.error
      }
    case ActionTypes.DEPLOYMENT_TARGET_GROUP_DELETE:
      return {
        ...state,
        error: null
      }
    case ActionTypes.DEPLOYMENT_TARGET_GROUP_DELETE_SUCCESS:
      return {
        ...state,
        error: null
      }
    case ActionTypes.DEPLOYMENT_TARGET_GROUP_DELETE_FAIL:
      return {
        ...state,
        error: action.payload.error
      }
    default:
      return state
  }
}
