import React from 'react'

import {IconButton, ListItemIcon} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faEllipsisV, faTrash} from '@fortawesome/free-solid-svg-icons'
import Menu from '@material-ui/core/Menu/Menu'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import DeploymentTarget from '../../../models/DeploymentTarget'
import IStoreState from '../../../store/IStoreState'
import {Dispatch} from 'redux'
import {deleteDeploymentTargetById} from '../../../store/actions/deploymentTargetActions'
import {connect} from 'react-redux'
import DeploymentEnvironment from '../../../models/DeploymentEnvironment'
import ConfirmDialog from '../../common/ConfirmDialog'
import './DeploymentTargetActionMenu.scss'

export interface IDeploymentTargetActionsMenuProps {
  deploymentTarget: DeploymentTarget,
  handleEditClick: any,
  deploymentEnvironments?: DeploymentEnvironment[],
  deleteDeploymentTargetById?: (id: string) => void
}

export interface IDeploymentTargetActionsMenuState {
  openActionsList: boolean
  confirmDelete: boolean
}

export class DeploymentTargetActionsMenu extends React.Component<IDeploymentTargetActionsMenuProps, IDeploymentTargetActionsMenuState> {
  actionsListAnchor: React.RefObject<any> = React.createRef()
  constructor(props: IDeploymentTargetActionsMenuProps) {
    super(props)
    this.state = {
      openActionsList: false,
      confirmDelete: false
    }
  }

  handleActionsClick = (event: any): void => {
    if(event !== undefined){
      event.stopPropagation()
    }
    this.setState({openActionsList: this.state.openActionsList === true? false : true})
  }

  handleActionsClose = (event: any): void => {
    if(event !== undefined){
      event.stopPropagation()
    }
    this.setState({openActionsList: false})
  }

  handleEditClick = (): void => {
    this.setState({openActionsList: false})
    this.props.handleEditClick()
  }

  handleDeleteClick = (): void => {
    this.setState({openActionsList: false, confirmDelete: true})
  }

  closeConfirmDeleteModal = (): void => {
    this.setState({confirmDelete: false})
  }

  processDelete = async (): Promise<void> => {
    this.props.deleteDeploymentTargetById(this.props.deploymentTarget.id)
  }

  get envTitlesToBeDeleted(): string[] {
    const envTitlesToBeDeleted: string[] = []
    this.props.deploymentEnvironments.forEach((env: DeploymentEnvironment) => {
      env.deploymentTargetGroups.forEach((nestedGroup: any) => {
        if (nestedGroup.deploymentTargetIds.includes(this.props.deploymentTarget.id)) {
          envTitlesToBeDeleted.push(`${env.title} &rarr; ${nestedGroup.name}`)
        }
      })
    })
    return envTitlesToBeDeleted
  }

  get confirmDeleteMessage(): React.ReactNode {
    const {deploymentTarget} = this.props

    let messageTop: React.ReactNode

    if (this.envTitlesToBeDeleted.length) {
      messageTop = (
        <span>
          The deployment target will be removed from the following environments:
          <ul>{this.envTitlesToBeDeleted.map((title: string) => <li key={title} dangerouslySetInnerHTML={{__html: title}} />)}</ul>
        </span>
      )
    } else {
      messageTop = <span>There are no environments using this deployment target<br/></span>
    }

    return (
      <span>
      {messageTop}
        <br/>
        <span>Are you sure want to delete the <b>{deploymentTarget.name}</b> deployment target?</span>
      </span>
    )
  }

  render(): React.ReactNode {
    const {deploymentTarget} = this.props
    return (
      <div className={'deploy-actionMenu'} onMouseLeave={this.handleActionsClose}>
        <IconButton aria-haspopup='true' onClick={this.handleActionsClick}>
          <span ref={this.actionsListAnchor}><FontAwesomeIcon icon={faEllipsisV} size='xs'/></span>
        </IconButton>
        <div className={'deploy-actionList'} hidden={!this.state.openActionsList}>
          <MenuItem onClick={this.handleEditClick}>
            <ListItemIcon className={'muiListItemIconW'}><FontAwesomeIcon icon={faEdit}/></ListItemIcon>Edit
          </MenuItem>
          <MenuItem onClick={this.handleDeleteClick}>
            <ListItemIcon className={'muiListItemIconW'}><FontAwesomeIcon icon={faTrash}/></ListItemIcon>Delete
          </MenuItem>
        </div>
        {this.state.confirmDelete &&
        <ConfirmDialog open message={this.confirmDeleteMessage} title={`Delete ${deploymentTarget.name}?`}
                       onConfirm={this.processDelete} onCancel={this.closeConfirmDeleteModal}/>
        }
      </div>
    )

  }
}

const mapStateToProps: any = (state: IStoreState): any => ({
  deploymentEnvironments: state.deploymentEnvironments.data,
})

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  deleteDeploymentTargetById: (id: string): void => dispatch(deleteDeploymentTargetById(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentTargetActionsMenu)

