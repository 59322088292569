export const PRIMARY = {
  50: '#EBF2F9',
  100: '#CCDCF0',
  200: '#A9C5E5',
  300: '#89AFDC',
  400: '#6697D1',
  500: '#4682C8',
  600: '#3269A9',
  700: '#275182',
  800: '#1B385A',
}
