import DeploymentTarget, {DEPLOYMENT_TARGET_STATUS, ESCALATION_METHOD, LOGIN_AUTH_TYPE} from '../../../models/DeploymentTarget'
import DeploymentTargetService from '../../../services/DeploymentTargetService'
import SnackbarService, {SNACKBAR_TYPES} from '../../../services/SnackbarService'
import statusMessageHelper from './DeploymentTargetStatusMessageHelper'
import DeploymentTargetWizardErrorHelper from './DeploymentTargetWizardErrorHelper'
import {cloneDeep} from 'lodash'

const POLL_RATE: number = 2000
const SUCCESS_DELAY: number = 1000

class DeploymentTargetWizardHelper {
  deploymentTarget: DeploymentTarget
  originalLoginAuthType: LOGIN_AUTH_TYPE
  originalRemotePassword: string
  originalPrivateKey: string
  stepNumber: number
  isNewDeploymentTarget: boolean
  submitting: boolean
  success: boolean
  errorFields: Map<string, string>
  wizardCloseSubscribers: Array<(() => void)>
  deploymentTargetSubscribers: Array<(() => void)>
  statusMessagesSubscribers: Array<(() => void)>
  pollTimeout: any
  shouldPoll: boolean
  messages: Map<DEPLOYMENT_TARGET_STATUS, string>
  canContinue: boolean
  canGoBack: boolean
  latestSuccessStatus: DEPLOYMENT_TARGET_STATUS
  showIp: boolean

  constructor() {
    this.stepNumber = 1
    this.clearErrors()
    this.wizardCloseSubscribers = []
    this.deploymentTargetSubscribers = []
    this.statusMessagesSubscribers = []
    this.messages = new Map([
      [DEPLOYMENT_TARGET_STATUS.PENDING_IP_PORT_VERIFICATION, null],
      [DEPLOYMENT_TARGET_STATUS.PENDING_PASSWORD_LOGIN, null],
      [DEPLOYMENT_TARGET_STATUS.PENDING_KEY_GENERATION, null],
      [DEPLOYMENT_TARGET_STATUS.PENDING_KEY_LOGIN, null],
      [DEPLOYMENT_TARGET_STATUS.PENDING_ESCALATION_LOGIN, null],
      [DEPLOYMENT_TARGET_STATUS.PENDING_INSTALL, null],
    ])
    this.canContinue = false
    this.canGoBack = false
    this.showIp = false
  }

  initializeDeploymentTarget = (deploymentTarget?: DeploymentTarget): DeploymentTarget => {
    this.isNewDeploymentTarget = !deploymentTarget.id
    this.showIp = !!deploymentTarget.id
    this.deploymentTarget = deploymentTarget
    this.originalLoginAuthType = deploymentTarget.loginAuthType
    this.deploymentTarget.status = DEPLOYMENT_TARGET_STATUS.INCOMPLETE
    this.checkCanContinue()
    this.updateMessages()
    return this.deploymentTarget
  }

  stopPolling = (): void => {
    this.shouldPoll = false
  }

  clearErrors(): void {
    this.errorFields = new Map<string, string>()
  }

  handleNextClick = async (): Promise<void> => {
    this.submitting = true
    this.clearErrors()
    this.notifyDeploymentTargetSubscribers()
    if (await this.saveDeploymentTarget()) {
      await this.pollStatusChanges()
    }
  }

  subscribeToWizardClose = (subscriber: () => void): void => {
    this.wizardCloseSubscribers.push(subscriber)
  }

  subscribeToDeploymentTarget = (subscriber: () => void): void => {
    this.deploymentTargetSubscribers.push(subscriber)
  }

  subscribeToStatusMessages = (subscriber: () => void): void => {
    this.statusMessagesSubscribers.push(subscriber)
  }

  unsubscribeFromWizardClose = (subscriber: () => void): void => {
    const index: number = this.wizardCloseSubscribers.indexOf(subscriber)
    if (index > -1) {
      this.wizardCloseSubscribers = this.wizardCloseSubscribers.splice(index, 1)
    }
  }

  unsubscribeFromDeploymentTarget = (subscriber: () => void): void => {
    const index: number = this.deploymentTargetSubscribers.indexOf(subscriber)
    if (index > -1) {
      this.deploymentTargetSubscribers = this.deploymentTargetSubscribers.splice(index, 1)
    }
  }

  unsubscribeFromStatusMessages = (subscriber: () => void): void => {
    const index: number = this.statusMessagesSubscribers.indexOf(subscriber)
    if (index > -1) {
      this.statusMessagesSubscribers = this.statusMessagesSubscribers.splice(index, 1)
    }
  }

  handleDeploymentTargetPropertyChange = (key: any, value: any): void => {
    this.deploymentTarget[key] = value
    this.checkCanContinue()
    this.checkCanGoBack()
    this.updateMessages()
    this.notifyDeploymentTargetSubscribers()
  }

  handleClose = async (): Promise<void> => {
    if (this.isNewDeploymentTarget && this.deploymentTarget.id && this.deploymentTarget.status !== DEPLOYMENT_TARGET_STATUS.READY) {
      await DeploymentTargetService.deleteDeploymentTarget(this.deploymentTarget.id)
    }
    if (this.isNewDeploymentTarget) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Deployment Target setup canceled.')
    }
    this.stopPolling()
    this.notifyWizardCloseSubscribers()
    this.resetWizardData()
    this.clearErrors()
  }

  handleBackClick = (): void => {
    this.processBackClick()
    this.clearErrors()
    this.updateMessages()
    this.checkCanContinue()
    this.notifyDeploymentTargetSubscribers()
  }

  checkCanContinue = (): void => {
    const {name, fqdn, port, loginAuthType, remotePassword, privateKey, escalationMethod,
      escalationUser, escalationPassword} = this.deploymentTarget
    this.canContinue = false
    if (!this.isNewDeploymentTarget &&
          // rerun validation
          name && fqdn && port &&
          ((loginAuthType === LOGIN_AUTH_TYPE.PASSWORD && remotePassword) ||
            (loginAuthType === LOGIN_AUTH_TYPE.PRIVATE_KEY && privateKey) ||
            (loginAuthType === LOGIN_AUTH_TYPE.STORED)) &&
          ((escalationMethod === ESCALATION_METHOD.SUDO && escalationUser) ||
            (escalationMethod === ESCALATION_METHOD.SU && escalationUser && escalationPassword) ||
            (escalationMethod === ESCALATION_METHOD.NONE)
          )) {
      this.canContinue = true
    } else if (this.isNewDeploymentTarget &&
        // step 1 validation
        (this.stepNumber === 1 &&(name && fqdn && port && loginAuthType)) ||
        // step 2 validation
        (this.stepNumber === 2 && (
          (loginAuthType === LOGIN_AUTH_TYPE.PASSWORD && remotePassword) ||
          (loginAuthType === LOGIN_AUTH_TYPE.PRIVATE_KEY && privateKey)
        )) ||
        // step 3 validation
        (this.stepNumber === 3 && (
          (escalationMethod === ESCALATION_METHOD.SUDO && escalationUser) ||
          (escalationMethod === ESCALATION_METHOD.SU && escalationUser && escalationPassword) ||
          escalationMethod === ESCALATION_METHOD.NONE
        ))) {
      this.canContinue = true
    }
  }

  private processBackClick = (): void => {
    if (this.stepNumber === 3 && this.originalLoginAuthType === LOGIN_AUTH_TYPE.PASSWORD) {
      delete this.deploymentTarget.escalationMethod
      delete this.deploymentTarget.remotePassword
      delete this.deploymentTarget.privateKey
      this.stepNumber = 1
    } else {
      this.stepNumber--
    }
    if (this.stepNumber === 1) {
      if (this.deploymentTarget.remotePassword) {
        this.originalRemotePassword = this.deploymentTarget.remotePassword
        delete this.deploymentTarget.remotePassword
      }
      if (this.deploymentTarget.privateKey) {
        this.originalPrivateKey = this.deploymentTarget.privateKey
        delete this.deploymentTarget.privateKey
      }
      this.deploymentTarget.status = DEPLOYMENT_TARGET_STATUS.INCOMPLETE
    } else if (this.stepNumber === 2) {
      delete this.deploymentTarget.escalationMethod
      this.deploymentTarget.loginAuthType = this.originalLoginAuthType
      this.deploymentTarget.remotePassword = this.originalRemotePassword
      if (this.originalLoginAuthType === LOGIN_AUTH_TYPE.PASSWORD) {
        delete this.deploymentTarget.privateKey
      }
      this.deploymentTarget.status = DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_IP_PORT_VERIFICATION
    }
  }

  private saveDeploymentTarget = async (): Promise<boolean> => {
    let saved: boolean = true
    try {
      let partialDeploymentTarget: DeploymentTarget
      if (!this.isNewDeploymentTarget) {
        partialDeploymentTarget = cloneDeep(this.deploymentTarget)
        this.originalLoginAuthType = this.deploymentTarget.loginAuthType
        partialDeploymentTarget.status = DEPLOYMENT_TARGET_STATUS.INCOMPLETE
        this.reconcileLoginAuthTypeDetails(partialDeploymentTarget)
        this.reconcileEscalationDetails(partialDeploymentTarget)
        await DeploymentTargetService.putDeploymentTarget(partialDeploymentTarget)
      } else {
        switch (this.stepNumber) {
          case 1:
            if (!this.deploymentTarget.id) {
              this.deploymentTarget = await DeploymentTargetService.createDeploymentTarget(this.deploymentTarget)
            } else {
              this.deploymentTarget.status = (await DeploymentTargetService.updateDeploymentTarget(cloneDeep(this.deploymentTarget))).status
            }
            this.originalLoginAuthType = this.deploymentTarget.loginAuthType
            break
          case 2:
            partialDeploymentTarget = new DeploymentTarget({id: this.deploymentTarget.id})
            if (this.deploymentTarget.loginAuthType === LOGIN_AUTH_TYPE.PRIVATE_KEY) {
              partialDeploymentTarget.privateKey = this.deploymentTarget.privateKey
            } else if (this.deploymentTarget.loginAuthType === LOGIN_AUTH_TYPE.PASSWORD) {
              partialDeploymentTarget.remotePassword = this.deploymentTarget.remotePassword
              this.originalRemotePassword = this.deploymentTarget.remotePassword
            }
            partialDeploymentTarget.status = DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_IP_PORT_VERIFICATION
            this.deploymentTarget.status = (await DeploymentTargetService.updateDeploymentTarget(partialDeploymentTarget)).status
            break
          case 3:
            partialDeploymentTarget = new DeploymentTarget({id: this.deploymentTarget.id})
            partialDeploymentTarget.escalationMethod = this.deploymentTarget.escalationMethod
            this.reconcileEscalationDetails(partialDeploymentTarget)
            partialDeploymentTarget.status = this.latestSuccessStatus
            this.deploymentTarget.status = (await DeploymentTargetService.updateDeploymentTarget(partialDeploymentTarget)).status
            break
        }
      }
    } catch (error) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, DeploymentTargetWizardErrorHelper.getErrorForCode(error))
      this.processError()
      saved = false
    }
    this.updateMessages()
    this.notifyDeploymentTargetSubscribers()
    return saved
  }

  private reconcileEscalationDetails = (partialDeploymentTarget: DeploymentTarget): void => {
    switch (partialDeploymentTarget.escalationMethod) {
      case ESCALATION_METHOD.SUDO:
        partialDeploymentTarget.escalationUser = this.deploymentTarget.escalationUser
        partialDeploymentTarget.escalationPassword = null
        break
      case ESCALATION_METHOD.SU:
        partialDeploymentTarget.escalationUser = this.deploymentTarget.escalationUser
        if (partialDeploymentTarget.escalationPassword === DeploymentTarget.ESCALATION_PASSWORD_STORED) {
          partialDeploymentTarget.escalationPassword = null
        } else {
          partialDeploymentTarget.escalationPassword = this.deploymentTarget.escalationPassword
        }
        break
      case ESCALATION_METHOD.NONE:
        partialDeploymentTarget.escalationUser = null
        partialDeploymentTarget.escalationPassword = null
        break
    }
  }

  private reconcileLoginAuthTypeDetails = (partialDeploymentTarget: DeploymentTarget): void => {

    switch (partialDeploymentTarget.loginAuthType) {
      case LOGIN_AUTH_TYPE.PASSWORD:
        partialDeploymentTarget.privateKey = null
        break
      case LOGIN_AUTH_TYPE.PRIVATE_KEY:
        partialDeploymentTarget.remotePassword = null
        break
      case LOGIN_AUTH_TYPE.STORED:
        partialDeploymentTarget.privateKey = null
        partialDeploymentTarget.remotePassword = null
        break
    }
  }

  private updateMessages = (): void => {
    this.messages.forEach((value: string, status: DEPLOYMENT_TARGET_STATUS) => {
      this.messages.set(status, statusMessageHelper.getMessage(status, this.deploymentTarget))
    })
    if (this.deploymentTarget.loginAuthType === LOGIN_AUTH_TYPE.PRIVATE_KEY) {
      this.messages.set(DEPLOYMENT_TARGET_STATUS.PENDING_PASSWORD_LOGIN, null)
      this.messages.set(DEPLOYMENT_TARGET_STATUS.PENDING_KEY_GENERATION, null)
    }
    if (this.deploymentTarget.escalationMethod === ESCALATION_METHOD.NONE) {
      this.messages.set(DEPLOYMENT_TARGET_STATUS.PENDING_ESCALATION_LOGIN, null)
    }
    this.notifyStatusMessagesSubscribers()
  }

  private handleSuccessfulStep = (): void => {
    this.success = true
    this.stopPolling()
    this.notifyDeploymentTargetSubscribers()
    setTimeout(() => {
      this.processNextStep()
    }, SUCCESS_DELAY)
  }

  private handleFinish = (): void => {
    this.success = true
    this.stopPolling()
    this.notifyDeploymentTargetSubscribers()
    setTimeout(() => {
      if (!this.isNewDeploymentTarget) {
        this.handleEditFinished()
      } else {
        this.handleStep3Finished()
      }
    }, SUCCESS_DELAY)
  }

  private processNextStep = (): void => {
    this.stepNumber++
    if (this.stepNumber === 2) {
      this.showIp = true
      if (this.deploymentTarget.loginAuthType === LOGIN_AUTH_TYPE.PASSWORD && this.originalRemotePassword) {
        this.deploymentTarget.remotePassword = this.originalRemotePassword
      }
      if (this.deploymentTarget.loginAuthType === LOGIN_AUTH_TYPE.PRIVATE_KEY && this.originalPrivateKey) {
        this.deploymentTarget.privateKey = this.originalPrivateKey
      }
    }
    if (this.stepNumber === 3) {
      this.deploymentTarget.escalationMethod = ESCALATION_METHOD.SUDO
      this.deploymentTarget.escalationUser = 'root'
    }
    this.success = false
    this.clearErrors()
    this.submitting = false
    this.checkCanContinue()
    this.notifyDeploymentTargetSubscribers()
  }

  private processError = (): void => {
    this.errorFields = DeploymentTargetWizardErrorHelper.getErrorForTarget(this.deploymentTarget)
    this.success = false
    this.submitting = false
    this.showIp = true
    this.stopPolling()
    this.notifyStatusMessagesSubscribers()
    this.notifyDeploymentTargetSubscribers()
  }

  private handleStep3Finished = (): void => {
    this.handleClose()
    SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Deployment Target added.')
  }

  private handleEditFinished = (): void => {
    this.handleClose()
    SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Deployment Target updated.')
  }

  private notifyWizardCloseSubscribers = (): void => {
    this.wizardCloseSubscribers.forEach((handler: () => void) => handler())
  }

  private notifyDeploymentTargetSubscribers = (): void => {
    this.deploymentTargetSubscribers.forEach((handler: (deploymentTarget: DeploymentTarget) => void) => handler(this.deploymentTarget))
  }

  private notifyStatusMessagesSubscribers = (): void => {
    this.statusMessagesSubscribers.forEach((handler: (messages: Map<DEPLOYMENT_TARGET_STATUS, string>) => void) => handler(this.messages))
  }

  private checkCanGoBack = (): void => {
    this.canGoBack = !this.submitting
  }

  private pollStatusChanges = async (): Promise<any> => {
    this.shouldPoll = true
    await this.processPolling()
    if (this.shouldPoll) {
      await new Promise((resolve: any): any => {
        this.pollTimeout = setTimeout(() => {
          resolve(this.pollStatusChanges())
        }, POLL_RATE)
      })
    } else if (this.pollTimeout) {
      clearTimeout(this.pollTimeout)
    }
  }

  private processPolling = async (): Promise<void> => {
    const updatedDeploymentTarget: DeploymentTarget = await DeploymentTargetService.getDeploymentTarget(this.deploymentTarget.id)
    this.deploymentTarget.status = updatedDeploymentTarget.status
    if (!this.deploymentTarget.ipAddress && updatedDeploymentTarget.ipAddress) {
      this.deploymentTarget.ipAddress = updatedDeploymentTarget.ipAddress
    }
    this.deploymentTarget.loginAuthType = this.originalLoginAuthType
    this.setLatestSuccessStatus()
    if (this.isNewDeploymentTarget) {
      if ((this.stepNumber === 1 && (this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_IP_PORT_VERIFICATION ||
            this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.PENDING_KEY_LOGIN)) ||
        (this.stepNumber === 2 && this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_KEY_LOGIN)) {
        this.handleSuccessfulStep()
      } else if (this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.READY) {
        this.handleFinish()
      } else if ((this.stepNumber === 1 && (this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_IP_PORT_VERIFICATION ||
        this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_IP_UNIQUE_CONSTRAINT)) ||
        (this.stepNumber === 2 &&
          (this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_PASSWORD_LOGIN ||
            this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_KEY_GENERATION ||
            this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_KEY_LOGIN)) ||
        (this.stepNumber === 3 &&
          (this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_ESCALATION_LOGIN ||
            this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_INSTALL))) {
        this.processError()
      }
    } else {
      if (this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.READY) {
        this.handleFinish()
      } else if (this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_IP_PORT_VERIFICATION ||
        this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_IP_UNIQUE_CONSTRAINT ||
        this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_PASSWORD_LOGIN ||
        this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_KEY_GENERATION ||
        this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_KEY_LOGIN ||
        this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_ESCALATION_LOGIN ||
        this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.FAILED_INSTALL) {
        this.processError()
      }
    }
    this.notifyDeploymentTargetSubscribers()
    this.updateMessages()
  }

  private resetWizardData = (): void => {
    this.stepNumber = 1
    this.success = false
    this.submitting = false
    this.canGoBack = false
    this.deploymentTarget = null
    this.deploymentTargetSubscribers = []
    this.statusMessagesSubscribers = []
    this.messages = new Map([
      [DEPLOYMENT_TARGET_STATUS.PENDING_IP_PORT_VERIFICATION, null],
      [DEPLOYMENT_TARGET_STATUS.PENDING_PASSWORD_LOGIN, null],
      [DEPLOYMENT_TARGET_STATUS.PENDING_KEY_GENERATION, null],
      [DEPLOYMENT_TARGET_STATUS.PENDING_KEY_LOGIN, null],
      [DEPLOYMENT_TARGET_STATUS.PENDING_ESCALATION_LOGIN, null],
      [DEPLOYMENT_TARGET_STATUS.PENDING_INSTALL, null],
    ])
    this.originalLoginAuthType = null
    this.originalPrivateKey = null
    this.originalRemotePassword = null
    this.showIp = false
  }

  private setLatestSuccessStatus = (): void => {
    if (this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_IP_PORT_VERIFICATION ||
      this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_PASSWORD_LOGIN ||
      this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_KEY_GENERATION ||
      this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_KEY_LOGIN ||
      this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_ESCALATION_LOGIN ||
      this.deploymentTarget.status === DEPLOYMENT_TARGET_STATUS.SUCCESSFUL_INSTALL) {
      this.latestSuccessStatus = this.deploymentTarget.status
    }
  }
}

export default new DeploymentTargetWizardHelper()
export {DeploymentTargetWizardHelper}
