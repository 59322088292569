import React from 'react'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import SnackbarService, {SNACKBAR_TYPES} from 'src/services/SnackbarService'

import GenericModal from 'src/components/common/GenericModal'
import DeploymentEnvironmentHelper from 'src/helpers/DeploymentEnvironmentHelper'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import Project from 'src/models/Project'
import AssetGroup from 'src/models/AssetGroup'
import ConfirmDialog from 'src/components/common/ConfirmDialog'
import MessageService from 'src/services/MessageService'
import Pipeline from 'src/models/pipeline/Pipeline'
import AssetMergeActionConfiguration from 'src/models/pipeline/AssetMergeActionConfiguration'
import Action from 'src/models/pipeline/Action'
import PipelineService from 'src/services/PipelineService'

export interface IMergeAssetsModalState {
  fromEnvId: string,
  toEnvId: string,
  fromAssetPath: string,
  fromAssetAssocId: number,
  toAssetPath: string,
  toAssetAssocId: number,
  isCrossPollination: boolean,
}

export interface IMergeAssetsModalProps {
  environment: ProjectEnvironment,
  environments: ProjectEnvironment[],
  project: Project,
  handleModalClose: ()=>void,
  openModal: boolean
}

export class MergeAssetsModal extends React.Component<IMergeAssetsModalProps, IMergeAssetsModalState> {
  constructor(props: IMergeAssetsModalProps) {
    super(props)

    const toEnv: ProjectEnvironment = props.project.projectEnvironments.find((env: ProjectEnvironment) => env.order === props.environment.order + 1)

    this.state = {
      fromEnvId: props.environment.environmentId,
      toEnvId: toEnv ? toEnv.environmentId : null,
      fromAssetPath: props.environment.projectEnvironmentAssets.assetGroups[0].path,
      fromAssetAssocId: props.environment.projectEnvironmentAssets.assetGroups[0].associationId,
      toAssetPath: toEnv ? toEnv.projectEnvironmentAssets.assetGroups[0].path : null,
      toAssetAssocId: toEnv ? toEnv.projectEnvironmentAssets.assetGroups[0].associationId : null,
      isCrossPollination: false,
    }
  }

  handleChange = (event: any): void => {
    if (event.target.name === 'toAssetPath') {
      const {toEnvId} = this.state
      const projectEnv: ProjectEnvironment = this.props.project.projectEnvironments.find((env: ProjectEnvironment) => env.environmentId === toEnvId)
      const toAssetAssocId: number = projectEnv.projectEnvironmentAssets.assetGroups.find((assetGroup: AssetGroup) => assetGroup.path === event.target.value).associationId
      this.setState({toAssetAssocId})
    } else if (event.target.name === 'fromAssetPath') {
      const {fromEnvId} = this.state
      const projectEnv: ProjectEnvironment = this.props.project.projectEnvironments.find((env: ProjectEnvironment) => env.environmentId === fromEnvId)
      const fromAssetAssocId: number = projectEnv.projectEnvironmentAssets.assetGroups.find((assetGroup: AssetGroup) => assetGroup.path === event.target.value).associationId
      this.setState({fromAssetAssocId})
    }

    this.setState({[event.target.name]: event.target.value} as any)
  }

  handleFromEnvChange = (event: any): void => {
    const newFromEnvId: string = event.target.value
    if (event.target.value === this.state.toEnvId) {
      this.setState({fromEnvId: newFromEnvId, fromAssetPath: null, toEnvId: null, toAssetPath: null, toAssetAssocId: null})
    } else {
      this.setState({fromEnvId: newFromEnvId, fromAssetPath: null})
    }
  }

  handleToEnvChange = (event: any): void => {
    this.setState({toEnvId: event.target.value, toAssetPath: null})
  }

  handleConfirmModalClose = ():void => this.setState({isCrossPollination: false})

  handleCopyClick = (): void => {
    const {fromAssetAssocId, toAssetAssocId} = this.state

    if (fromAssetAssocId !== toAssetAssocId) {
      this.setState({isCrossPollination: true})
    } else {
      this.createAssetDeployment()
    }
  }

  createAssetDeployment = async (): Promise<void> => {
    const {project} = this.props
    const {toEnvId, fromEnvId, fromAssetPath, toAssetPath} = this.state

    this.props.handleModalClose()

    const pipeline: Pipeline = new Pipeline({
      projectId: project.id,
      actions: [
        new Action({
          actionConfiguration: new AssetMergeActionConfiguration({
            sourceEnvironment: fromEnvId,
            sourceDeploymentTargetGroupId: project.getAssetTargetGroupIdByEnv(fromEnvId),
            targetEnvironment: toEnvId,
            targetDeploymentTargetGroupId: project.getAssetTargetGroupIdByEnv(toEnvId),
            sourceAssetPath: fromAssetPath,
            targetAssetPath: toAssetPath
          })
        })
      ]
    })

    try {
      await PipelineService.executePipeline(pipeline)
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Successfully started asset merge.')
    } catch (error) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, error || 'Failed to start asset merge!')
    }
  }

  render(): React.ReactNode {
    const {project, environment, environments, openModal, handleModalClose} = this.props
    const {fromEnvId, toEnvId, fromAssetPath, toAssetPath, isCrossPollination} = this.state
    let crossPollinationWarning: string

    if (environment) {
      const fromAssetPathFormHelperText: string = fromEnvId ? (fromAssetPath ? null : 'Select an asset group') : 'No environment selected'
      const toAssetPathFormHelperText: string = toEnvId ? (toAssetPath ? null : 'Select an asset group') : 'No environment selected'

      if (isCrossPollination) {
        crossPollinationWarning = MessageService.get('project.action.asset.merge.crossPollination.warning', {
          fromAssetPath,
          toAssetPath,
          fromEnvTitle: DeploymentEnvironmentHelper.getEnvironmentTitleById(fromEnvId),
          toEnvTitle: DeploymentEnvironmentHelper.getEnvironmentTitleById(toEnvId)
        })
      }
      return (
        <Grid>
          <GenericModal open={openModal} onClose={handleModalClose}>
            <h2>Merge Assets</h2>
            <Grid container>
              <Grid item xs={12}>
                <h4>From</h4>
                <Select className='select' value={fromEnvId} onChange={this.handleFromEnvChange} name='fromEnvId'>
                  {environments
                    .filter((env: ProjectEnvironment) => project.canMergeAssetsFrom(env.environmentId))
                    .map((env: ProjectEnvironment) => <MenuItem key={env.environmentId} value={env.environmentId}>{DeploymentEnvironmentHelper.getEnvironmentTitleById(env.environmentId)}</MenuItem>)
                  }
                </Select>
                <Select style={{marginTop: '0.5rem'}} className='select' value={fromAssetPath} onChange={this.handleChange} name='fromAssetPath'>
                  {fromEnvId &&
                    project.projectEnvironments.find((env: ProjectEnvironment) => env.environmentId === fromEnvId)
                    .projectEnvironmentAssets.assetGroups.map((assetGroup: AssetGroup, i: number) => <MenuItem key={`${i}:${assetGroup.title}`} value={assetGroup.path}>{assetGroup.title}</MenuItem>)
                  }
                </Select>
                <FormHelperText>{fromAssetPathFormHelperText}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <h4>Into</h4>
                <Select className='select' value={toEnvId} onChange={this.handleToEnvChange} name='toEnvId'>
                  {environments
                    .filter((env: ProjectEnvironment) => env.environmentId !== fromEnvId && project.canMergeAssetsTo(env.environmentId))
                    .map((env: ProjectEnvironment) => <MenuItem key={env.environmentId} value={env.environmentId}>{DeploymentEnvironmentHelper.getEnvironmentTitleById(env.environmentId)}</MenuItem>)
                  }
                </Select>
                <Select style={{marginTop: '0.5rem'}} className='select' value={toAssetPath} onChange={this.handleChange}
                  name='toAssetPath' disabled={!toEnvId}>
                  {toEnvId &&
                    project.projectEnvironments.find((env: ProjectEnvironment) => env.environmentId === toEnvId)
                    .projectEnvironmentAssets.assetGroups.map((assetGroup: AssetGroup) => <MenuItem key={assetGroup.title} value={assetGroup.path}>{assetGroup.title}</MenuItem>)}
                </Select>
                <FormHelperText>{toAssetPathFormHelperText}</FormHelperText>
              </Grid>
              <Grid item xs={12} className='modal-actions'>
                <Grid container spacing={8} justify='flex-end'>
                  <Grid item>
                    <Button onClick={handleModalClose} variant='contained' color='secondary' disableElevation>Cancel</Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={this.handleCopyClick} variant='contained' color='primary' disabled={!fromAssetPath || !toAssetPath}>Merge</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </GenericModal>

          {isCrossPollination && <ConfirmDialog
            title={'Confirm Cross Asset Copy'}
            open={isCrossPollination}
            message={crossPollinationWarning}
            onConfirm={this.createAssetDeployment}
            onCancel={this.handleConfirmModalClose}
          />}
        </Grid>
      )
    } else {
      return null
    }
  }
}

export default MergeAssetsModal
