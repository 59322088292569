import React from 'react'
import {FormLabel, Grid, Typography} from '@material-ui/core'
import { Dispatch } from 'redux'
import { DropResult } from 'react-beautiful-dnd'
import { connect, ConnectedProps } from 'react-redux'
import IStoreState from 'src/store/IStoreState'
import {DraggablePipelineActionList} from './draggablePipelineActionList.component'
import { PipelineActionConfig } from './PipelineActionList.config'
import { useStyles } from './draggablePipelineAction.config'
import {ActionConfiguration} from "../../../../../../models/pipeline/ActionConfiguration";
import {cloneDeep} from "lodash";
import ActionTypes from "../../../../../../store/actions";
import {PipelineTemplate} from "../../../../../../models/pipeline/PipelineTemplate";
import {arrayMove} from "react-sortable-hoc";

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {
    selectedAction :PipelineActionConfig[],
    isSelected:boolean,
    label:string
}

export type ISelectedActionDetailsListProps = PropsFromRedux & ComponentProps

const SelectedActionDetailsListDetails: React.FC<ISelectedActionDetailsListProps> = ({selectedAction, isSelected, move, remove, pipelineTemplate, add ,actionConfigurations, label}) => {
    const styles = useStyles()
    const updateSelectedListSync = (result: DropResult) => {
        move(result,pipelineTemplate)
    }
    const updateSelectedList = (result: DropResult) => {
        add(result,pipelineTemplate,actionConfigurations)
    }
    return (
        <div className={isSelected ?styles.selectedWidth:styles.unselectedWidth}>
            <FormLabel component='legend' className={styles.labelMargin}>{label}</FormLabel>
            {!!selectedAction && selectedAction.length > 0?<div className={isSelected ?styles.selectedContainer:styles.unselectedContainer}>
                <Grid item container direction="column" spacing={2}>
                    <Grid item>
                        <DraggablePipelineActionList
                        updateOrder={isSelected? updateSelectedListSync:updateSelectedList}
                        remove={remove}
                        droppableId="selectedPipelineActionColId"
                        actionList={selectedAction}
                        pipelineTemplate={pipelineTemplate}
                        isSelected={isSelected}
                        />
                    </Grid>
                </Grid>
            </div>:
                <Grid container justify={'center'} alignItems="center" alignContent="center" className={isSelected ?styles.selectedContainer:styles.unselectedContainer}>
                    <Grid item>
                        <Typography variant="body1">
                            {isSelected?'Drop your steps here':''}
                        </Typography>
                    </Grid>
                </Grid>
            }
        </div>
    )
}

const mapStateToProps = (state: IStoreState, ownProps: ComponentProps) => ({
    pipelineTemplate: state.pipelineTemplateEditor.pipelineTemplate,
    actionConfigurations: state.actionConfigurations.data,
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ComponentProps) => ({
    remove: (config: PipelineActionConfig,pipelineTemplate: PipelineTemplate) => {
        try{
            if (pipelineTemplate.actionConfigurations.length > 1) {
                let actionConfigurations: ActionConfiguration[] = cloneDeep(pipelineTemplate.actionConfigurations)
                actionConfigurations = actionConfigurations.filter((ac: ActionConfiguration) => ac.id !== config.id)
                dispatch({
                    type: ActionTypes.UPDATE_PIPELINE_TEMPLATE_EDITOR_FIELD,
                    payload: {
                        key: 'actionConfigurations',
                        value: actionConfigurations
                    }
                })
            }
        }
        catch (ex){

        }
    },
    move: (result: DropResult,pipelineTemplate: PipelineTemplate) => {
        try{
            let actionConfigurations: ActionConfiguration[] = cloneDeep(pipelineTemplate.actionConfigurations)
            const { source, destination } = result
            actionConfigurations = arrayMove(actionConfigurations, source.index, destination.index)
            dispatch({
                type: ActionTypes.UPDATE_PIPELINE_TEMPLATE_EDITOR_FIELD,
                payload: {
                    key: 'actionConfigurations',
                    value: actionConfigurations
                }
            })
        }
        catch (ex){

        }
    },
    add: (result: DropResult,pipelineTemplate: PipelineTemplate,actionConfigurations:ActionConfiguration[])=>{
        try {
            const { draggableId, source, destination } = result
            if (!pipelineTemplate.actionConfigurations.find((config: ActionConfiguration) => config.id === draggableId) && destination === null) {
                const pipelineTemplateActionConfigurations: ActionConfiguration[] = cloneDeep(pipelineTemplate.actionConfigurations)
                const actionConfigToAdd: ActionConfiguration = actionConfigurations.find((config: ActionConfiguration) => config.id === draggableId)
                pipelineTemplateActionConfigurations.push(actionConfigToAdd)
                dispatch({
                    type: ActionTypes.UPDATE_PIPELINE_TEMPLATE_EDITOR_FIELD,
                    payload: {
                        key: 'actionConfigurations',
                        value: pipelineTemplateActionConfigurations
                    }
                })
            }
        }
        catch (ex){

        }
    }
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const PipelineActionSelectedList = connector(SelectedActionDetailsListDetails)



