import React from 'react'
import PropTypes from 'prop-types'
import { Header } from './components/layout/header/Header.component'
import { UIRouter, UIView } from '@uirouter/react'
import { routerStates } from './appStates'
import User from './models/User'
import { router } from './router'

class Routes extends React.Component {
  constructor(props) {
    super(props)
    routerStates.forEach(state => router.stateRegistry.register(state))
  }
  render() {
    return (
      <UIRouter router={router}>
        <div>
          <Header currentUser={this.props.currentUser}/>
          <UIView/>
        </div>
      </UIRouter>
    )
  }
}

Routes.propTypes = {
  currentUser: PropTypes.instanceOf(User).isRequired,
}

export default Routes
