import React from 'react'
import IStoreState from '../../../store/IStoreState'
import {connect} from 'react-redux'
import GenericDialog from '../../common/GenericDialog'
import DeploymentTargetWizardStep1 from './DeploymentTargetWizardStep1'
import DeploymentTargetWizardStep2 from './DeploymentTargetWizardStep2'
import DeploymentTargetWizardStep3 from './DeploymentTargetWizardStep3'
import {Button, DialogActions, Grid} from '@material-ui/core'
import DeploymentTarget from '../../../models/DeploymentTarget'
import CheckIcon from '@material-ui/icons/Check'
import NavigateNext from '@material-ui/icons/NavigateNext'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeploymentTargetStatusPanel from './DeploymentTargetStatusPanel'
import './DeploymentTargetWizard.scss'
import wizardHelper from './DeploymentTargetWizardHelper'
import User from '../../../models/User'

export interface IDeploymentTargetWizardProps {
  deploymentTarget: DeploymentTarget
  currentUser: User
}

export interface IDeploymentTargetWizardState {
  deploymentTarget: DeploymentTarget
}

export class DeploymentTargetWizard extends React.Component<IDeploymentTargetWizardProps,IDeploymentTargetWizardState> {
  static defaultProps: any = {
    deploymentTarget: null,
  }

  constructor(props:IDeploymentTargetWizardProps) {
    super(props)
    this.state = {
      deploymentTarget: wizardHelper.initializeDeploymentTarget(props.deploymentTarget)
    }
    //@ts-ignore
    wizardHelper.subscribeToDeploymentTarget(this.handleDeploymentTargetUpdated.bind(this))
  }

  handleDeploymentTargetUpdated = (deploymentTarget: DeploymentTarget): void => {
    this.setState({deploymentTarget})
  }

  componentWillUnmount(): void {
    wizardHelper.stopPolling()
    //@ts-ignore
    wizardHelper.unsubscribeFromDeploymentTarget(this.handleDeploymentTargetUpdated.bind(this))
  }

  render(): React.ReactNode {
    const {errorFields, stepNumber, isNewDeploymentTarget, submitting, success, canContinue, showIp} = wizardHelper
    const {deploymentTarget} = this.state

    const actions: React.ReactNode =
      <DialogActions>
        <Grid container className='actionsContainer' alignItems='center'>
          <Grid item sm={2}>
            <Button className="cancel" onClick={wizardHelper.handleClose} variant='contained' disableElevation color='secondary'>Cancel</Button>
          </Grid>
          <Grid item sm={10}>
            <Grid container spacing={8} justify='flex-end' alignItems='center'>
              <Grid item className='back'>
                {isNewDeploymentTarget &&
                <Button className={stepNumber === 1 ? 'right':  'cancel right'} onClick={wizardHelper.handleBackClick}
                  variant='contained' color='secondary' disabled={stepNumber === 1 || submitting} disableElevation>Back</Button>
                }
              </Grid>
              <Grid item>
                <Button className='right' onClick={wizardHelper.handleNextClick} disabled={!canContinue} variant='contained' color='primary'>
                  {stepNumber === 3 || !isNewDeploymentTarget ? <span>Done</span> : <span>Next</span>}
                  {submitting
                    ? success
                      ? <CheckIcon className='wizardButtonIcon'/>
                      : <CircularProgress size={24} style={{marginLeft: '0.5rem', color: '#fff'}}/>
                    : <NavigateNext className='wizardButtonIcon'/>
                  }
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    return (
      <GenericDialog style={{overflow:'hidden'}} onClose={wizardHelper.handleClose} actions={actions} disableBackdropClick={true}
        title={isNewDeploymentTarget ? 'Add Deployment Target' : 'Edit Deployment Target'} showClose={true}>

        <Grid container className='wizard-body' spacing={5}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              {(stepNumber === 1 || !isNewDeploymentTarget) &&
                <Grid item sm={12}>
                  <DeploymentTargetWizardStep1 handleDeploymentTargetPropertyChange={wizardHelper.handleDeploymentTargetPropertyChange}
                    deploymentTarget={deploymentTarget} disableFields={submitting} errorFields={errorFields}
                    showIpField={showIp} isNewDeploymentTarget={isNewDeploymentTarget} currentUser={this.props.currentUser}/>
                </Grid>
              }
              {(stepNumber === 2 || !isNewDeploymentTarget) &&
                <Grid item sm={12}>
                  <DeploymentTargetWizardStep2 handleDeploymentTargetPropertyChange={wizardHelper.handleDeploymentTargetPropertyChange}
                    deploymentTarget={deploymentTarget} disableFields={submitting} errorFields={errorFields}
                    isNewDeploymentTarget={isNewDeploymentTarget}/>
                </Grid>
              }
              {(stepNumber === 3 || !isNewDeploymentTarget) &&
                <Grid item sm={12}>
                  <DeploymentTargetWizardStep3 handleDeploymentTargetPropertyChange={wizardHelper.handleDeploymentTargetPropertyChange}
                    deploymentTarget={deploymentTarget} disableFields={submitting} errorFields={errorFields}/>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={4} style={{borderLeft: 'solid 1px #ddd', overflow:'hidden'}}>
            <DeploymentTargetStatusPanel submitting={submitting} isNewDeploymentTarget={isNewDeploymentTarget}/>
          </Grid>
        </Grid>
      </GenericDialog>
    )
  }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IDeploymentTargetWizardProps): any => ({
  currentUser: state.currentUser
})

export default connect(
  mapStateToProps,
)(DeploymentTargetWizard)
