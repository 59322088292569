import React from 'react'
import PropTypes from 'prop-types'
import {Modal} from '@material-ui/core'

import './modal.scss'

class GenericModal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <div className={`${this.props.className} modalBody`} style={this.props.style}>
          {this.props.children}
        </div>
      </Modal>
    )
  }
}

GenericModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  style: PropTypes.object,
}

GenericModal.defaultProps = {
  style: {},
}

export default GenericModal
