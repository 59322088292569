import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles'
import {TableCell, TableRow} from '@material-ui/core'

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.grey['200'],
      color: theme.palette.common.black,
    }
  })
)(TableCell)

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      }
    }
  })
)(TableRow)

export const useStyles = makeStyles( {
  table: {
    backgroundColor: 'white',
    borderRadius: '8px'
  }
})
