import { EnvironmentSelectionConfig } from 'src/components/project/project-create/drupal8/drupal8-environments.config'
import { PROJECT_TYPE } from 'src/models/Project'

export const CREATE_PROJECT_FORM_RESET_WORKFLOW = 'CREATE_PROJECT_FORM_RESET_WORKFLOW'
export const CREATE_PROJECT_FORM_SET_SELECTED_WORKFLOW = 'CREATE_PROJECT_FORM_SET_SELECTED_WORKFLOW'
export const CREATE_PROJECT_FORM_SET_SELECTED_ENVS = 'CREATE_PROJECT_FORM_SET_SELECTED_ENVS'
export const CREATE_PROJECT_FORM_SET_PROD_ENV_ID = 'CREATE_PROJECT_FORM_SET_PROD_ENV_ID'
export const CREATE_PROJECT_FORM_SET_IS_SAVE_DISABLED = 'CREATE_PROJECT_FORM_SET_IS_SAVE_DISABLED'
export const CREATE_PROJECT_FORM_SET_IS_BRANCH_CONFIRM_SHOWN = 'CREATE_PROJECT_FORM_SET_IS_BRANCH_CONFIRM_SHOWN'

export interface SetCreateProjectIsSaveDisabledAction {
  type: typeof CREATE_PROJECT_FORM_SET_IS_SAVE_DISABLED
  payload: boolean
}

export interface ResetCreateProjectWorkflowAction {
  type: typeof CREATE_PROJECT_FORM_RESET_WORKFLOW
  payload: undefined
}

export interface SetCreateProjectFormSelectedWorkflowAction {
  type: typeof CREATE_PROJECT_FORM_SET_SELECTED_WORKFLOW
  payload: PROJECT_TYPE | null
}

export interface SetCreateProjectFormSelectedEnvironmentsAction {
  type: typeof CREATE_PROJECT_FORM_SET_SELECTED_ENVS
  payload: EnvironmentSelectionConfig[]
}

export interface SetCreateProjectFormProductionEnvironmentIdAction {
  type: typeof CREATE_PROJECT_FORM_SET_PROD_ENV_ID
  payload: string
}

export interface CreateProjectFormSetIsBranchConfirmationShown {
  type: typeof CREATE_PROJECT_FORM_SET_IS_BRANCH_CONFIRM_SHOWN
  payload: boolean
}

export type CreateProjectFormActionTypes =
  | ResetCreateProjectWorkflowAction // TODO: Test
  | SetCreateProjectFormSelectedWorkflowAction
  | SetCreateProjectFormSelectedEnvironmentsAction
  | SetCreateProjectFormProductionEnvironmentIdAction
  | SetCreateProjectIsSaveDisabledAction // TODO: Test
  | CreateProjectFormSetIsBranchConfirmationShown // TODO: Test
