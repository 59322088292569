import React from 'react'
import {Grid, IconButton} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faTrash} from '@fortawesome/free-solid-svg-icons'
import DeploymentEnvironment from '../../../models/DeploymentEnvironment'
import DeploymentTargetGroup from '../../../models/DeploymentTargetGroup'
import './DeploymentEnvironmentsSection.scss'
import DeploymentTargetGroupDetail from './DeploymentTargetGroupDetail'
import ConfirmDialog from '../../common/ConfirmDialog'
import MessageService from '../../../services/MessageService'
import DeploymentEnvironmentHelper from '../../../helpers/DeploymentEnvironmentHelper'
import LoadingOverlay from '../../common/LoadingOverlay'
import InLineEditableText from '../../common/InLineEditableText'
import IStoreState from '../../../store/IStoreState'
import {Dispatch} from 'redux'
import {updateDeploymentEnvironmentById} from '../../../store/actions/deploymentEnvironmentActions'
import {createDeploymentTargetGroup, loadSelectedEnvironment} from '../../../store/actions/selectedEnvironmentActions'
import {connect} from 'react-redux'
import CreateDeploymentTargetGroupDialog from './CreateDeploymentTargetGroupDialog'
import RetryLoading from '../../common/RetryLoading'
import Project from '../../../models/Project'
import ProjectEnvironment from '../../../models/ProjectEnvironment'

export interface IDeploymentEnvironmentDetailProps {
  environment?: DeploymentEnvironment
  environments?: DeploymentEnvironment[]
  groups?: DeploymentTargetGroup[]
  error?: any
  loading?: boolean
  selectEnvironment?: (id: string)=>void
  updateEnvironment?: (id: string, env: DeploymentEnvironment)=>void
  deleteEnvironment: (id: string)=>void
  addGroupToEnvironment?: (id: string, group: DeploymentTargetGroup)=>void
  reloadSelectedEnvironment?: (id: string)=>void
  projects?: Project[]
}

export interface IDeploymentEnvironmentDetailState {
  title: string
  showNewGroupDialog: boolean
  showDeleteConfirmationDialog: boolean
}

export class DeploymentEnvironmentDetail extends React.Component <IDeploymentEnvironmentDetailProps, IDeploymentEnvironmentDetailState> {

  private readonly toolTipMessageForTitle: string

  constructor(props: IDeploymentEnvironmentDetailProps) {
    super(props)

    this.toolTipMessageForTitle = MessageService.get('admin.deploymentenvironment.title.tooltip')

    this.state = {
      title: props.environment.title,
      showNewGroupDialog: false,
      showDeleteConfirmationDialog: false
    }
  }

  componentDidUpdate(prevProps: Readonly<IDeploymentEnvironmentDetailProps>): void {
    if (prevProps.environment.title !== this.props.environment.title) {
      this.setState({title: this.props.environment.title})
    }
  }

  openAddGroupDialog = (): void => {
    this.setState({showNewGroupDialog: true})
  }

  closeAddGroupDialog = (): void => {
    this.setState({showNewGroupDialog: false})
  }

  openDeleteConfirmDialog = (): void => {
    this.setState({showDeleteConfirmationDialog: true})
  }

  closeDeleteConfirmDialog = (): void => {
    this.setState({showDeleteConfirmationDialog: false})
  }

  isProdOrDevEnvById = (id: string): boolean => {
    if (id) {
      const fullEnv: DeploymentEnvironment = DeploymentEnvironmentHelper.getDeploymentEnvironmentById(id)
      return fullEnv && (fullEnv.isProd || fullEnv.isDev)
    } else {
      return false
    }
  }

  handleNewGroup = (group: DeploymentTargetGroup, addToAllEnvironments: boolean): void => {
    const {environment, environments} = this.props
    if (addToAllEnvironments) {
      environments.forEach((env: DeploymentEnvironment) => env.id !== environment.id && this.props.addGroupToEnvironment(env.id, group))
      setTimeout(() => {
        this.props.addGroupToEnvironment(environment.id, group)
      }, 500)
    } else {
      this.props.addGroupToEnvironment(environment.id, group)
    }
  }

  handleDelete = async (): Promise<void> => {
    const {environment, deleteEnvironment} = this.props
    this.closeDeleteConfirmDialog()
    await deleteEnvironment(environment.id)
  }

  handleTitleChange = (newTitle: string): void => {
    this.setState({title: newTitle})
  }

  submitEnvironmentTitleChange = (title: string): void => {
    if (!DeploymentEnvironmentHelper.validateEnvironmentTitle(title)) {
      title = DeploymentEnvironmentHelper.sanitizeEnvironmentTitle(title)
    }

    this.props.updateEnvironment(this.props.environment.id, new DeploymentEnvironment({title}))
    setTimeout(() => {
      this.props.reloadSelectedEnvironment(this.props.environment.id)
    }, 500)
  }

  get projectsUsingEnvironment(): string[] {
    const projectsUsingEnvironment: string[] = []
    this.props.projects.forEach((proj: Project) => {
      proj.projectEnvironments && proj.projectEnvironments.forEach((projEnv: ProjectEnvironment) => {
        if (projEnv.environmentId === this.props.environment.id) {
          projectsUsingEnvironment.push(`${proj.name}`)
        }
      })
    })
    return projectsUsingEnvironment
  }


  get confirmDeleteMessage(): React.ReactNode {
    let messageTop: React.ReactNode
    let messageBottom: React.ReactNode

    const deleteEnvWarningProjectUsing:string = MessageService.get('admin.deploymentenvironment.delete.warning.projects', {envTitle: this.props.environment.title})
    const deleteEnvWarning:string = MessageService.get('admin.deploymentenvironment.delete.warning', {envTitle: this.props.environment.title})

    if (this.projectsUsingEnvironment.length) {
      messageTop = (
        <span>
          {deleteEnvWarningProjectUsing}
          <ul>{this.projectsUsingEnvironment.map((name: string) => <li key={name} dangerouslySetInnerHTML={{__html: name}} />)}</ul>
        </span>
      )
    } else {
      messageBottom = (
        <span>{deleteEnvWarning}</span>
      )
    }

    return (
      <span>
        {messageTop}
        <br/>
        {messageBottom}
      </span>
    )
  }

  render(): React.ReactNode {
    const {environment, loading, error, groups, reloadSelectedEnvironment} = this.props
    const showRemoveButton: boolean = !this.isProdOrDevEnvById(environment.id)
    return (
      <Grid container spacing={2} className='loading-overlay-parent deployment-environment-detail'>
        {loading && <LoadingOverlay/>}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container justify='space-between' alignItems='center'>
                <Grid item>
                  <h3>
                    <InLineEditableText toolTipMessage={this.toolTipMessageForTitle} text={this.state.title}
                                        handleSubmit={this.submitEnvironmentTitleChange} handleChange={this.handleTitleChange}/>
                  </h3>
                </Grid>
                <Grid item>
                  {showRemoveButton &&
                    <IconButton className='remove-env-button' title='Delete' onClick={this.openDeleteConfirmDialog}>
                      <FontAwesomeIcon icon={faTrash} size='xs'/>
                    </IconButton>
                  }
                  {!showRemoveButton &&
                    <IconButton style={{opacity: 0}} disabled/>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <h5>Deployment Groups</h5>
                </Grid>
                <Grid item>
                  <IconButton onClick={this.openAddGroupDialog}>
                    <FontAwesomeIcon icon={faPlus} size='xs'/>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems='stretch'>
            {groups.length === 0 && <RetryLoading message={MessageService.get('admin.deploymenttargetgroup.none')} reloadData={reloadSelectedEnvironment}/>}
            {groups
              .sort((a: DeploymentTargetGroup, b: DeploymentTargetGroup) => a.name.localeCompare(b.name))
              .map((targetGroup: DeploymentTargetGroup) => (
                <Grid item md={4} sm={6} xs={12} key={targetGroup.id}>
                  <DeploymentTargetGroupDetail targetGroup={targetGroup} environmentId={environment.id}/>
                </Grid>
            ))}
          </Grid>
        </Grid>
        <ConfirmDialog
          title={`Delete ${environment.title} Environment?`}
          open={this.state.showDeleteConfirmationDialog}
          message={this.confirmDeleteMessage}
          onConfirm={this.handleDelete}
          onCancel={this.closeDeleteConfirmDialog}
          disableConfirm={this.projectsUsingEnvironment.length > 0}
        />
        {this.state.showNewGroupDialog &&
          <CreateDeploymentTargetGroupDialog onClose={this.closeAddGroupDialog} handleNewGroup={this.handleNewGroup}
                                             handleCancel={this.closeAddGroupDialog} loading={loading} error={error}/>
        }
      </Grid>
    )
  }
}

const mapStateToProps: any = (state: IStoreState): any => ({
  environments: state.deploymentEnvironments.data,
  environment: state.selectedEnvironment.environment,
  groups: state.selectedEnvironment.deploymentTargetGroups,
  error: state.selectedEnvironment.error,
  loading: state.selectedEnvironment.loading,
  projects: state.projects.data
})

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  updateEnvironment: (id: string, env: DeploymentEnvironment): void => dispatch(updateDeploymentEnvironmentById(id, env)),
  addGroupToEnvironment: (envId: string, group: DeploymentTargetGroup): void => dispatch(createDeploymentTargetGroup(envId, group)),
  reloadSelectedEnvironment: (id: string): void => dispatch(loadSelectedEnvironment(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentEnvironmentDetail)

