import React from 'react'
import {Grid, DialogActions, Button} from '@material-ui/core'

import GenericDialog from './GenericDialog'

interface IConfirmDialogProps {
  onConfirm: (event?: any) => void,
  onCancel: (event?: any) => void,
  message: React.ReactNode,
  open: boolean,
  title?: string,
  disableConfirm?: boolean
}

class ConfirmDialog extends React.Component<IConfirmDialogProps> {
  handleConfirmClick = (event: any): void => {
    this.props.onConfirm(event)
  }

  handleCancelClick = (event: any): void => {
    this.props.onCancel(event)
  }

  get confirmMessage(): React.ReactNode {
    const {message} = this.props
    const messageIsString: boolean = (typeof message === 'string')
    if (messageIsString) {
      return <span dangerouslySetInnerHTML={{__html: message as string}}/>
    }

    return <span>{message}</span>
  }

  render(): React.ReactNode {
    const {title, disableConfirm} = this.props
    const actions: React.ReactNode =
      <DialogActions>
        <Grid container spacing={2} className='actionsContainer' justify='flex-end'>
          <Grid item>
            <Button onClick={this.handleCancelClick} variant='contained' color='secondary' disableElevation>Cancel</Button>
          </Grid>
          { !disableConfirm &&
            <Grid item>
              <Button onClick={this.handleConfirmClick} variant='contained'
                      color='primary'>Confirm</Button>
            </Grid>
          }
        </Grid>
      </DialogActions>

    return (
      <GenericDialog open={this.props.open} onClose={this.handleCancelClick} actions={actions}
                     title={title}>
        <Grid container>
          <Grid item xs={12}>
            {this.confirmMessage}
          </Grid>
        </Grid>
      </GenericDialog>
    )
  }
}

export default ConfirmDialog
