import React from 'react'
import PropTypes from 'prop-types'
import {TableRow, TableCell, Checkbox} from '@material-ui/core'

const ROLE_ORDER = {
  ADMIN: {order: 0},
  MANAGER: {order: 1},
  DEVELOPER: {order: 2},
  DEPLOYER: {order: 3},
  OBSERVER: {order: 4},
}

class RoleSelectionRow extends React.Component {
  handleCheckboxChange = (roleName, event, checked) => {
    this.props.handleCheckboxChange(checked, this.props.item.projectId, roleName)
  }

  sortRoles = (userRoles) => {
    return userRoles.sort((userRole1, userRole2) => {
      return ROLE_ORDER[userRole1.role.name.toUpperCase()].order - ROLE_ORDER[userRole2.role.name.toUpperCase()].order
    })
  }

  render() {
    return (
      <TableRow style={{background : this.props.rowBackgroundColor}}>
        <TableCell className='edit-user-row'>{this.props.item.projectName}</TableCell>
        {this.sortRoles(this.props.item.projectRoles).map((userRole, index) => {
          return (
            <TableCell key={index} padding='none'>
              <Checkbox
                color="primary"
                checked={userRole.userHasRole || userRole.roleInGroup}
                disabled={userRole.roleInGroup}
                onChange={this.handleCheckboxChange.bind(this, userRole.role.name)}/>
            </TableCell>
          )
        })}
      </TableRow>
    )
  }
}

RoleSelectionRow.propTypes = {
  item: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectRoles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      userHasRole: PropTypes.bool,
      roleInGroup: PropTypes.bool,
    })).isRequired,
  }).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
}

export default RoleSelectionRow
