import Pipeline from '../models/pipeline/Pipeline'
import axios, {AxiosPromise, AxiosResponse} from 'axios'
import {classToPlain, plainToClass} from 'class-transformer'
import moment from 'moment'
import {pull} from 'lodash'
import {ActionConfiguration} from '../models/pipeline/ActionConfiguration'
import Action, {ACTION_TYPE} from '../models/pipeline/Action'
import AssetMergeActionConfiguration from '../models/pipeline/AssetMergeActionConfiguration'
import CodeDeployActionConfiguration from '../models/pipeline/CodeDeployActionConfiguration'
import CodeMergeActionConfiguration from '../models/pipeline/CodeMergeActionConfiguration'
import CodePromoteActionConfiguration from '../models/pipeline/CodePromoteActionConfiguration'
import DatabaseCopyActionConfiguration from '../models/pipeline/DatabaseCopyActionConfiguration'
import DatabaseSnapshotActionConfiguration from '../models/pipeline/DatabaseSnapshotActionConfiguration'
import PipelineTrigger from '../models/pipeline/PipelineTrigger'
import {PipelineTemplate} from '../models/pipeline/PipelineTemplate'
import {FileTemplateActionConfiguration} from '../models/pipeline/FileTemplateActionConfiguration'
import {ScriptActionConfiguration} from '../models/pipeline/ScriptActionConfiguration'
import ProjectEvent from '../models/pipeline/ProjectEvent'
import {ServiceActionConfiguration} from '../models/pipeline/ServiceActionConfiguration'

class PipelineService {
  static PROJECT_RESOURCE_ROOT: string = '/projects'
  static PIPELINES_API_ROOT: string = '/api/pipeline/v1'
  static PIPELINES_RESOURCE_ROOT: string = '/pipelines'
  static PIPELINE_TEMPLATES_RESOURCE_ROOT: string = '/pipelinetemplates'
  static ACTION_CONFIGURATION_RESOURCE_ROOT: string = '/actionconfigurations'
  onLatestProjectActionUpdatedSubscribers: any[]

  constructor() {
    this.onLatestProjectActionUpdatedSubscribers = []
  }

  async getProjectPipelines(projectId: string): Promise<Pipeline[]> {
    const res: any = await axios.get(this.getPipelinesUrl(projectId))
    return plainToClass(Pipeline, res.data)
  }

  async postProjectActionConfiguration(actionConfiguration: ActionConfiguration): Promise<ActionConfiguration> {
    const res: any = await axios.post(
      `${PipelineService.PIPELINES_API_ROOT}${PipelineService.PROJECT_RESOURCE_ROOT}/${actionConfiguration.projectId}${PipelineService.ACTION_CONFIGURATION_RESOURCE_ROOT}`,
      classToPlain(actionConfiguration)
    )
    return this.deserializeActionConfiguration(res.data)
  }


  async putProjectActionConfiguration(actionConfiguration: ActionConfiguration): Promise<ActionConfiguration> {
    const res: any = await axios.put(
      `${PipelineService.PIPELINES_API_ROOT}${PipelineService.PROJECT_RESOURCE_ROOT}/${actionConfiguration.projectId}${PipelineService.ACTION_CONFIGURATION_RESOURCE_ROOT}/${actionConfiguration.id}`,
      classToPlain(actionConfiguration)
    )
    return this.deserializeActionConfiguration(res.data)
  }

  deleteProjectActionConfiguration(actionConfiguration: ActionConfiguration): AxiosPromise {
    return axios.delete(`${PipelineService.PIPELINES_API_ROOT}${PipelineService.PROJECT_RESOURCE_ROOT}/${actionConfiguration.projectId}${PipelineService.ACTION_CONFIGURATION_RESOURCE_ROOT}/${actionConfiguration.id}`,
      classToPlain(actionConfiguration)
    )
  }

  async getProjectActionConfigurations(projectId: string): Promise<ActionConfiguration[]> {
    const res: any = await axios.get(this.getActionConfigurationsByProjectIdUrl(projectId))
    return res.data.map((actionConfig: ActionConfiguration) => this.deserializeActionConfiguration(actionConfig))
  }

  async getProjectPipelineTemplates(projectId: string): Promise<PipelineTemplate[]> {
    const res: any = await axios.get(this.getPipelineTemplatesByProjectIdUrl(projectId))
    return plainToClass(PipelineTemplate, res.data)
  }

  async putProjectPipelineTemplate(pipelineTemplate: PipelineTemplate): Promise<PipelineTemplate> {
    pipelineTemplate.systemCanModify = false
    const res: any = await axios.put(
      this.putProjectPipelineTemplateByProjectIdUrl(pipelineTemplate.projectId, pipelineTemplate.id), classToPlain(pipelineTemplate))
    return plainToClass(PipelineTemplate, res.data as PipelineTemplate)
  }

  async createProjectPipelineTemplate(pipelineTemplate: PipelineTemplate): Promise<PipelineTemplate> {
      pipelineTemplate.systemCanModify = false
      const res: any = await axios.post(
          this.postProjectPipelineTemplateByProjectIdUrl(pipelineTemplate.projectId), classToPlain(pipelineTemplate))
      return plainToClass(PipelineTemplate, res.data as PipelineTemplate)
  }

  deleteProjectPipelineTemplate(projectId: string, pipelineTemplateId: string): AxiosPromise {
    return axios.delete(this.deleteProjectPipelineTemplateByProjectIdUrl(projectId, pipelineTemplateId))
  }

  async executePipeline(pipeline: Pipeline): Promise<Pipeline> {
    const res: AxiosResponse<Pipeline> = await axios.post(this.getPipelinesUrl(pipeline.projectId), classToPlain(pipeline))
    this.notifyProjectActionsSubscribers()
    return plainToClass(Pipeline, res.data)
  }

  async triggerPipeline(projectId: string, triggers: PipelineTrigger[]): Promise<any> {
    await axios.post(this.getPipelineTriggerGroupUrl(projectId), triggers)
    this.notifyProjectActionsSubscribers()
  }

  async cancelPipeline(pipelineId: string): Promise<Pipeline> {
    const url: string = `${PipelineService.PIPELINES_API_ROOT}${PipelineService.PIPELINES_RESOURCE_ROOT}/${pipelineId}/cancel`
    const res: AxiosResponse<Pipeline> = await axios.post(url)
    this.notifyProjectActionsSubscribers()
    return plainToClass(Pipeline, res.data)
  }

  async getMostRecentCodeDeployment(projectId: string, targetEnv: string): Promise<ProjectEvent> {
    return this.getLatestEventForActionAndEnv(projectId, ACTION_TYPE.CODE_DEPLOY, targetEnv)
  }

  async getMostRecentCodeMerge(projectId: string, targetEnv: string): Promise<ProjectEvent> {
    return this.getLatestEventForActionAndEnv(projectId, ACTION_TYPE.CODE_MERGE, targetEnv)
  }

  async getMostRecentCodePromotion(projectId: string, targetEnv: string): Promise<ProjectEvent> {
    return this.getLatestEventForActionAndEnv(projectId, ACTION_TYPE.CODE_PROMOTE, targetEnv)
  }

  async getMostRecentAssetMerge(projectId: string, targetEnv: string): Promise<ProjectEvent> {
    return this.getLatestEventForActionAndEnv(projectId, ACTION_TYPE.ASSET_MERGE, targetEnv)
  }

  async getMostRecentDatabaseCopy(projectId: string, targetEnv: string): Promise<ProjectEvent> {
    return this.getLatestEventForActionAndEnv(projectId, ACTION_TYPE.DATABASE_COPY, targetEnv)
  }

  async getMostRecentDatabaseImport(projectId: string, targetEnv: string): Promise<ProjectEvent> {
    return this.getLatestEventForActionAndEnv(projectId, ACTION_TYPE.DATABASE_IMPORT, targetEnv)
  }

  async getMostRecentDatabaseSnapshot(projectId: string, sourceEnv: string): Promise<ProjectEvent> {
    return this.getLatestEventForActionAndEnv(projectId, ACTION_TYPE.DATABASE_SNAPSHOT, sourceEnv)
  }

  async getMostRecentPipelineFromTemplate(projectId: string, pipelineTemplateId: string): Promise<Pipeline> {
    const res: AxiosResponse<Pipeline> = await axios.get(this.getLatestPipelineUrl(projectId), {
      params: {pipelineTemplateId}
    })
    return plainToClass(Pipeline, res.data)
  }

  notifyProjectActionsSubscribers(): void {
    this.onLatestProjectActionUpdatedSubscribers.forEach((handler: any) => handler())
  }

  subscribeToProjectActions(subscriber: any): void {
    this.onLatestProjectActionUpdatedSubscribers.push(subscriber)
  }

  unsubscribeFromProjectActions(subscriber: any): void {
    pull(this.onLatestProjectActionUpdatedSubscribers, subscriber)
  }

  private getPipelinesUrl(projectId: string): string {
    return `${PipelineService.PIPELINES_API_ROOT}${PipelineService.PROJECT_RESOURCE_ROOT}/${projectId}${PipelineService.PIPELINES_RESOURCE_ROOT}`
  }

  private getPipelineTriggerGroupUrl(projectId: string): string {
    return `${PipelineService.PIPELINES_API_ROOT}${PipelineService.PROJECT_RESOURCE_ROOT}/${projectId}${PipelineService.PIPELINES_RESOURCE_ROOT}/triggers`
  }

  private getActionConfigurationsByProjectIdUrl(projectId: string): string {
    return `${PipelineService.PIPELINES_API_ROOT}${PipelineService.PROJECT_RESOURCE_ROOT}/${projectId}${PipelineService.ACTION_CONFIGURATION_RESOURCE_ROOT}`
  }

  private getPipelineTemplatesByProjectIdUrl(projectId: string): string {
    return `${PipelineService.PIPELINES_API_ROOT}${PipelineService.PROJECT_RESOURCE_ROOT}/${projectId}${PipelineService.PIPELINE_TEMPLATES_RESOURCE_ROOT}`
  }

  private putProjectPipelineTemplateByProjectIdUrl(projectId: string, pipelineTemplateId: string): string {
    return `${PipelineService.PIPELINES_API_ROOT}${PipelineService.PROJECT_RESOURCE_ROOT}/${projectId}${PipelineService.PIPELINE_TEMPLATES_RESOURCE_ROOT}/${pipelineTemplateId}`
  }

  private postProjectPipelineTemplateByProjectIdUrl(projectId: string): string {
        return `${PipelineService.PIPELINES_API_ROOT}${PipelineService.PROJECT_RESOURCE_ROOT}/${projectId}${PipelineService.PIPELINE_TEMPLATES_RESOURCE_ROOT}`
  }

  private deleteProjectPipelineTemplateByProjectIdUrl(projectId: string, pipelineTemplateId: string): string {
    return `${PipelineService.PIPELINES_API_ROOT}${PipelineService.PROJECT_RESOURCE_ROOT}/${projectId}${PipelineService.PIPELINE_TEMPLATES_RESOURCE_ROOT}/${pipelineTemplateId}`
  }

  private getLatestPipelineUrl(projectId: string): string {
    return `${this.getPipelinesUrl(projectId)}/latest`
  }

  private async getLatestEventForActionAndEnv(projectId: string, actionType: ACTION_TYPE, environmentId: string): Promise<ProjectEvent> {
    const res: AxiosResponse<Pipeline> = await axios.get(this.getLatestPipelineUrl(projectId), {
      params: {
        actionType,
        environmentId
      }
    })
    const pipeline: Pipeline = plainToClass(Pipeline, res.data)
    const latestAction: Action = this.getLatestActionFromPipeline(actionType, pipeline)
    return new ProjectEvent(latestAction, pipeline)
  }

  // Handles cases where two of the same type of action are in the same projectEvent
  private getLatestActionFromPipeline(actionType: ACTION_TYPE, pipeline: Pipeline): Action {
    const actions: Action[] = pipeline.actions.filter((action: Action) => action.actionConfiguration.actionType === actionType)

    let latestAction: Action = actions[0]
    if (actions.length) {
      actions.forEach((action: Action) => {
        if (moment(action.startTime).isAfter(latestAction.startTime)) {
          latestAction = action
        }
      })
    }

    return latestAction
  }

  private deserializeActionConfiguration(actionConfiguration: ActionConfiguration): ActionConfiguration {
    switch (actionConfiguration.actionType) {
      case ACTION_TYPE.ASSET_MERGE:
        return plainToClass(AssetMergeActionConfiguration, actionConfiguration)
      case ACTION_TYPE.CODE_DEPLOY:
        return plainToClass(CodeDeployActionConfiguration, actionConfiguration)
      case ACTION_TYPE.CODE_MERGE:
        return plainToClass(CodeMergeActionConfiguration, actionConfiguration)
      case ACTION_TYPE.CODE_PROMOTE:
        return plainToClass(CodePromoteActionConfiguration, actionConfiguration)
      case ACTION_TYPE.DATABASE_COPY:
        return plainToClass(DatabaseCopyActionConfiguration, actionConfiguration)
      case ACTION_TYPE.DATABASE_SNAPSHOT:
        return plainToClass(DatabaseSnapshotActionConfiguration, actionConfiguration)
      case ACTION_TYPE.FILE_TEMPLATE:
        return plainToClass(FileTemplateActionConfiguration, actionConfiguration)
      case ACTION_TYPE.SCRIPT:
        return plainToClass(ScriptActionConfiguration, actionConfiguration)
      case ACTION_TYPE.SERVICE:
        return plainToClass(ServiceActionConfiguration, actionConfiguration)
        default:
        throw new Error(`No Action Configuration to pull Action Type from`)

    }
  }
}

export default new PipelineService()
