import initialState from '../initialState'
import ActionTypes from '../actions'
import {ISelectedProjectState} from '../IStoreState'
import {assign, cloneDeep} from "lodash";
import {PROJECT_ERROR} from "../../models/Project";

export default function selectedProjectReducer(state: ISelectedProjectState = initialState.selectedProject, action: any): ISelectedProjectState {
  switch (action.type) {
    case ActionTypes.SELECTED_PROJECT_LOAD:
      return {
        ...state,
        loading: true
      }
    case ActionTypes.SELECTED_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.payload.project,
        persistedProject: action.payload.persistedProject,
      }
    case ActionTypes.SELECTED_PROJECT_FAIL:
      return {
        ...state,
        loading: false,
        project: null,
        error: action.payload.error
      }
    case ActionTypes.PERSISTED_PROJECT_SUCCESS:
      return {
        ...state,
        persistedProject: action.payload.project
      }
    case ActionTypes.PROJECT_DEPLOY_INITIAL:
      return assign(cloneDeep(state), {loading: true})
    case ActionTypes.PROJECT_DEPLOY_INITIAL_SUCCESS:
      const {deployedProject} = action.payload.project
      return assign(cloneDeep(state), {deployedProject, loading: true, initialDeploy: true})
    case ActionTypes.PROJECT_DEPLOY_INITIAL_FAIL:
      return handleError(state, action.payload)
    case ActionTypes.SELECTED_PROJECT_RESET_DEFAULT:
      return initialState.selectedProject
    case ActionTypes.SELECTED_PROJECT_FIELD_UPDATE:
      const updatedState: ISelectedProjectState = cloneDeep(state)
      const {key, value} = action.payload
      updatedState.project[key] = value
      updatedState.unsavedChanges = true
      return updatedState
    case ActionTypes.SELECTED_PROJECT_SAVE_SUCCESS:
      const {project} = action.payload
      return assign(cloneDeep(state), {project, cloneFromProject: undefined, loading: false, error: null, unsavedChanges: false, errorMessage: null})
    case ActionTypes.SELECTED_PROJECT_SAVE_FAIL:
      return handleError(state, action.payload)
    case ActionTypes.SELECTED_PROJECT_EVENTS_LOAD_SUCCESS:
      return {
        ...state,
        latestEvents: action.payload.latestEvents
      }
    case ActionTypes.SELECTED_PROJECT_EVENTS_LOAD_FAIL:
      return {
        ...state,
        latestEvents: [],
        error: action.payload.error
      }
    case ActionTypes.SELECTED_PROJECT_OPEN_BRANCH_CONFIRMATION:
      const {confirmActionCreator} = action.payload
      return assign(cloneDeep(state), {branchConfirmation: true, confirmActionCreator})
    case ActionTypes.SELECTED_PROJECT_CLOSE_BRANCH_CONFIRMATION:
      return assign(cloneDeep(state), {branchConfirmation: false})
    case ActionTypes.SELECTED_PROJECT_EVENTS_LOAD:
    default:
      return state
  }
}

function handleError(state: ISelectedProjectState, payload: {error: PROJECT_ERROR, errorMessage: string | null}): ISelectedProjectState {
  const {error, errorMessage} = payload
  return assign(cloneDeep(state), {error, loading: false, errorMessage})
}
