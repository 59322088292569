import React from 'react'
import {SERVICE_ACTION, ServiceActionConfiguration} from 'src/models/pipeline/ServiceActionConfiguration'
import {Grid} from '@material-ui/core'
import {cloneDeep} from 'lodash'
import {ActionConfiguration} from 'src/models/pipeline/ActionConfiguration'
import {ServiceActionType} from 'src/models/pipeline/ServiceActionType'
import { find } from 'lodash';
import {OutlinedTextField} from "../../../../../common/OutlinedTextField";
import {OutlinedDropDown} from "../../../../../common/OutlinedDropDown";
import {DropDownEntry} from "../../../../../../models/DropDownEntry";

export interface IServiceActionConfigProps {
  actionConfiguration: ServiceActionConfiguration
  onActionConfigChanged: (actionConfiguration: ActionConfiguration)=> void
}

export interface IServiceActionConfigEditorState {
  actionConfiguration: ServiceActionConfiguration
  serviceActionTypeOptions: DropDownEntry[]
}

export class ServiceActionConfigEditor extends React.Component<IServiceActionConfigProps, IServiceActionConfigEditorState/*, IActionConfigEditorState*/> {

  private readonly serviceTypeOptions: ServiceActionType[] = [
    new ServiceActionType({id: SERVICE_ACTION.START, value: 'Start'}),
    new ServiceActionType({id: SERVICE_ACTION.STOP, value: 'Stop'}),
    new ServiceActionType({id: SERVICE_ACTION.RESTART, value: 'Restart'}),
    new ServiceActionType({id: SERVICE_ACTION.RELOAD, value: 'Reload'})
  ]

  constructor(props: IServiceActionConfigProps) {
    super(props)

    const actionOptions: DropDownEntry[] = this.serviceTypeOptions.map((actionType: ServiceActionType) => {
      return new DropDownEntry(actionType.value, actionType.id)
    })

    this.state = {
      actionConfiguration: props.actionConfiguration,
      serviceActionTypeOptions: actionOptions
    }
  }

  onServiceNameChange = (event: any): void => {
    const copiedSAC: ServiceActionConfiguration = cloneDeep(this.props.actionConfiguration)
    copiedSAC.serviceName = event.target.value
    this.props.onActionConfigChanged(copiedSAC)
  }

  handleSelect = (event: any): void => {
    const selectedEntry: DropDownEntry = event.target
    const copiedSAC: ServiceActionConfiguration = cloneDeep(this.props.actionConfiguration)
    copiedSAC.serviceAction = selectedEntry.value as SERVICE_ACTION
    this.props.onActionConfigChanged(copiedSAC)
  }

  render(): React.ReactNode {
    const {actionConfiguration} = this.props

    let selectedServiceAction: DropDownEntry
    if (actionConfiguration && actionConfiguration.serviceAction ) {
      selectedServiceAction = find(this.state.serviceActionTypeOptions, (typeOption: DropDownEntry) => {
        return typeOption.value === actionConfiguration.serviceAction.toString()
      })
    }

    return (
        <Grid item xs={12}>
          <h3>{actionConfiguration.serviceName}</h3>
          <OutlinedTextField label='Service Name' id='service-name' value={actionConfiguration.serviceName}
                             handleChange={this.onServiceNameChange} multiLine={true}/>

          <OutlinedDropDown label='Service Step' id='serive-action' value={selectedServiceAction}
                    dropDownOptions={this.state.serviceActionTypeOptions} handleChange={this.handleSelect}
                            placeholder='Please select the Service type'/>
        </Grid>
    )
  }
}

export default ServiceActionConfigEditor

