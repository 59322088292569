import {combineReducers, Reducer} from 'redux'
import projectWizard from './projectWizard'
import currentUser from './currentUser'
import environments from './deploymentEnvironments'
import deploymentEnvironments from './deploymentEnvironments'
import projects from './projects'
import projectTypes from './projectTypes'
import selectedProject from './selectedProject'
import selectedEnvironment from './selectedEnvironment'
import deploymentTargets from './deploymentTargets'
import bundle from './bundle'
import actionConfigurations from './actionConfigurations'
import pipelineTemplates from './pipelineTemplates'
import pipelineTemplateEditor from './pipelineTemplateEditor'
import history from './history'
import pipelines from './pipelines'
import header from './header'
import selectedEnvironmentList from './selectedEnvironmentList'
import { createProjectFormReducer } from '../create-project-form/create-project-form.reducer'
import { createProjectDataReducer } from '../create-project-data/create-project-data.reducer'

const rootReducer: Reducer = combineReducers({
  createProjectForm: createProjectFormReducer,
  createProjectData: createProjectDataReducer,
  currentUser,
  environments,
  projects,
  projectTypes,
  selectedProject,
  projectWizard,
  deploymentEnvironments,
  selectedEnvironment,
  selectedEnvironmentList,
  deploymentTargets,
  bundle,
  actionConfigurations,
  pipelineTemplates,
  pipelineTemplateEditor,
  history,
  pipelines,
  header,
})

export default rootReducer
