import {ActionConfiguration} from 'src/models/pipeline/ActionConfiguration'
import React from 'react'
import {IconButton} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Menu from '@material-ui/core/Menu/Menu'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import IStoreState from 'src/store/IStoreState'
import {connect} from 'react-redux'
import {faEdit, faEllipsisV, faTrash} from '@fortawesome/free-solid-svg-icons'
import ConfirmDialog from 'src/components/common/ConfirmDialog'
import './ActionConfigurationActionsMenu.scss'

export interface IActionConfigurationActionsMenuProps {
  actionConfiguration: ActionConfiguration,
  handleEditClick: any
  handleDeleteClick: any
}

export interface IActionConfigurationActionsMenuState {
  openActionsList: boolean,
  confirmDelete: boolean
}

export class ActionConfigurationActionsMenu extends React.Component<IActionConfigurationActionsMenuProps, IActionConfigurationActionsMenuState> {
  actionsListAnchor: React.RefObject<any> = React.createRef()

  constructor(props: IActionConfigurationActionsMenuProps) {
    super(props)
    this.state = {
      openActionsList: false,
      confirmDelete: false
    }
  }

  handleActionsClick = (event: any): void => {
    if(event !== undefined){
      event.stopPropagation()
    }
    this.setState({openActionsList: this.state.openActionsList === true? false : true})
  }

  handleActionsClose = (event: any): void => {
    if(event !== undefined){
      event.stopPropagation()
    }
    this.setState({openActionsList: false})
  }

  handleEditClick = (): void => {
    this.setState({openActionsList: false})
    this.props.handleEditClick()
  }

  handleDeleteClick = (): void => {
    this.setState({openActionsList: false, confirmDelete: true})
  }

  handleDeleteConfirmClick = (): void => {
    this.props.handleDeleteClick()
    this.setState({confirmDelete: false})
  }

  closeConfirmDeleteModal = (): void => {
    this.setState({confirmDelete: false})
  }

  render(): React.ReactNode {
    const actionConfiguration: any = this.props.actionConfiguration
    const {confirmDelete} = this.state
    return (
      <div className={'action-actionMenu'} onMouseLeave={this.handleActionsClose}>
        <IconButton aria-haspopup='true' onClick={this.handleActionsClick}>
          <span ref={this.actionsListAnchor}><FontAwesomeIcon icon={faEllipsisV} size='xs'/></span>
        </IconButton>
        <div className={'action-actionList'} hidden={!this.state.openActionsList}>
          {actionConfiguration.hasUserEditor === true &&
          <MenuItem onClick={this.handleEditClick}>
            <ListItemIcon className={'muiListItemIconW'}><FontAwesomeIcon icon={faEdit}/></ListItemIcon>Edit
          </MenuItem>
          }
          <MenuItem onClick={this.handleDeleteClick}>
            <ListItemIcon className={'muiListItemIconW'}><FontAwesomeIcon icon={faTrash}/></ListItemIcon>Delete
          </MenuItem>
        </div>
        {confirmDelete &&
        <ConfirmDialog open title='Delete Action?' message='Are you sure you want to delete this Action?'
                       onConfirm={this.handleDeleteConfirmClick} onCancel={this.closeConfirmDeleteModal}/>
        }
      </div>
    )
  }
}

const mapStateToProps: any = (state: IStoreState): any => ({
  actionConfigurations: state.actionConfigurations.data

})

export default connect(
  mapStateToProps
)(ActionConfigurationActionsMenu)


