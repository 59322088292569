import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'
import { addSelectedEnvironment } from 'src/store/create-project-form/create-project-form.actions'
import IStoreState from 'src/store/IStoreState'
import { EnvironmentSelectionConfig, resolveUnselectedEnvironmentConfigs } from '../drupal8/drupal8-environments.config'
import { UnselectedEnvironmentItem } from './environment-unselected-item.component'
import { Grid } from '@material-ui/core'
import { useStyles } from './environments-unselected-list.config'

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = {}

type UnselectedEnvironmentsListProps = PropsFromRedux & ComponentProps

export const UnselectedEnvironmentsList: React.FC<UnselectedEnvironmentsListProps> = ({ unselected, add }) => {
  const styles = useStyles()
  return (
    <div className={styles.container}>
      <Grid container spacing={2}>
        {unselected.map((config, index) => {
          return (
            <Grid item key={index}>
              <UnselectedEnvironmentItem config={config} add={add} />
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

const mapStateToProps = (state: IStoreState, ownProps: ComponentProps) => ({
  unselected: resolveUnselectedEnvironmentConfigs(
    state.deploymentEnvironments.data,
    state.createProjectForm.selectedEnvironments,
  ),
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ComponentProps) => ({
  add: (config: EnvironmentSelectionConfig) => {
    dispatch(addSelectedEnvironment(config))
  },
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ConnectedUnselectedEnvironmentsList = connector(UnselectedEnvironmentsList)
