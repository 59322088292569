import ProjectEnvironmentAssets from './ProjectEnvironmentAssets'
import ProjectEnvironmentCode from './ProjectEnvironmentCode'
import ProjectEnvironmentDatabases from './ProjectEnvironmentDatabases'
import 'reflect-metadata'
import {assign} from 'lodash'
import SymLink from './SymLink'
import {Type} from 'class-transformer'

export default class ProjectEnvironment {
  environmentId: string
  order: number
  projectEnvironmentAssets: ProjectEnvironmentAssets
  projectEnvironmentCode: ProjectEnvironmentCode
  projectEnvironmentDatabases: ProjectEnvironmentDatabases
  @Type(() => SymLink)
  symLinks: SymLink[]

  constructor(props?: Partial<ProjectEnvironment>) {
    props = props || {}
    const defaultProps: Partial<ProjectEnvironment> = {
      environmentId: undefined,
      projectEnvironmentAssets: undefined,
      projectEnvironmentCode: undefined,
      projectEnvironmentDatabases: undefined,
      symLinks: undefined
    }
    if (props.projectEnvironmentAssets) {
      props.projectEnvironmentAssets = new ProjectEnvironmentAssets(props.projectEnvironmentAssets)
    }
    if (props.projectEnvironmentCode) {
      props.projectEnvironmentCode = new ProjectEnvironmentCode(props.projectEnvironmentCode)
    }
    if (props.projectEnvironmentDatabases) {
      props.projectEnvironmentDatabases = new ProjectEnvironmentDatabases(props.projectEnvironmentDatabases)
    }
    assign(this, defaultProps, props)
  }
}
