import axios, {AxiosResponse} from 'axios'
import DeploymentEnvironment from '../models/DeploymentEnvironment'
import {plainToClass} from 'class-transformer'

class DeploymentEnvironmentService {
  static DEPLOYMENT_ENVIRONMENT_API_ROOT:string = '/api/deploymentenvironment/v2/deploymentenvironments'

  async getDeploymentEnvironments(): Promise<DeploymentEnvironment[]> {
    const res: AxiosResponse<DeploymentEnvironment[]> = await axios.get(DeploymentEnvironmentService.DEPLOYMENT_ENVIRONMENT_API_ROOT)
    return res.data.map((deploymentEnvironmentJSON : DeploymentEnvironment) => plainToClass(DeploymentEnvironment, deploymentEnvironmentJSON))
  }

  async getDeploymentEnvironmentById(id: string): Promise<DeploymentEnvironment> {
    const res: AxiosResponse<DeploymentEnvironment> = await axios.get(`${DeploymentEnvironmentService.DEPLOYMENT_ENVIRONMENT_API_ROOT}/${id}`)
    return plainToClass(DeploymentEnvironment, res.data)
  }

  async patchDeploymentEnvironment(id: string, deploymentEnvironment: DeploymentEnvironment): Promise<DeploymentEnvironment> {
    const res: AxiosResponse<DeploymentEnvironment> = await axios.patch(`${DeploymentEnvironmentService.DEPLOYMENT_ENVIRONMENT_API_ROOT}/${id}`, deploymentEnvironment)
    return plainToClass(DeploymentEnvironment, res.data)
  }

  async createDeploymentEnvironment(deploymentEnvironment: DeploymentEnvironment, environmentIdToClone?: string): Promise<DeploymentEnvironment> {
    const res: AxiosResponse<DeploymentEnvironment> = await axios.post(DeploymentEnvironmentService.DEPLOYMENT_ENVIRONMENT_API_ROOT, deploymentEnvironment, {params: {environmentIdToClone}})
    return plainToClass(DeploymentEnvironment, res.data)
  }

  async deleteEnvironment(id: string): Promise<void> {
    const res: AxiosResponse = await axios.delete(`${DeploymentEnvironmentService.DEPLOYMENT_ENVIRONMENT_API_ROOT}/${id}`)
    return res.data
  }

  async updateEnvironment(id: string, environment: DeploymentEnvironment): Promise<DeploymentEnvironment> {
    const res: AxiosResponse<DeploymentEnvironment> = await axios.patch(`${DeploymentEnvironmentService.DEPLOYMENT_ENVIRONMENT_API_ROOT}/${id}`, environment)
    return plainToClass(DeploymentEnvironment, res.data)
  }
}

export default new DeploymentEnvironmentService()
