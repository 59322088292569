import { DropResult } from 'react-beautiful-dnd'
import { Dispatch } from 'redux'
import {
  EnvironmentSelectionConfig,
  transformToEnvironmentSelectionConfig,
} from 'src/components/project/project-create/drupal8/drupal8-environments.config'
import { PROJECT_TYPE } from 'src/models/Project'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import {
  createProjectDataReset,
  createProjectDataSave,
  createProjectDataSetProject,
  createProjectDataGenerateEnvironmentDefaultData,
  createProjectDataReorderProjectEnvironmentsBySelectedEnvironments,
} from '../create-project-data/create-project-data.actions'
import { CreateProjectDataActionTypes } from '../create-project-data/create-project-data.types'
import IStoreState from '../IStoreState'
import {
  CreateProjectFormActionTypes,
  CREATE_PROJECT_FORM_RESET_WORKFLOW,
  CREATE_PROJECT_FORM_SET_IS_BRANCH_CONFIRM_SHOWN,
  CREATE_PROJECT_FORM_SET_PROD_ENV_ID,
  CREATE_PROJECT_FORM_SET_SELECTED_ENVS,
  CREATE_PROJECT_FORM_SET_SELECTED_WORKFLOW,
  CREATE_PROJECT_FORM_SET_IS_SAVE_DISABLED,
} from './create-project-form.types'
import {
  createDrupal7Project,
  createDrupal8Project,
  createDrupal9Project,
  createNewProjectEnvironment,
  createOtherProject,
  createWordpressProject,
  createWorkflowProject,
} from './create-project-form.helpers'

export function createProjectFormReset(): CreateProjectFormActionTypes {
  return {
    type: CREATE_PROJECT_FORM_RESET_WORKFLOW,
    payload: undefined,
  }
}

export function setCreateProjectSelectedWorkflow(type: PROJECT_TYPE | null): CreateProjectFormActionTypes {
  return {
    type: CREATE_PROJECT_FORM_SET_SELECTED_WORKFLOW,
    payload: type,
  }
}

export function createProjectFormSetIsSaveDisabled(value: boolean): CreateProjectFormActionTypes {
  return {
    type: CREATE_PROJECT_FORM_SET_IS_SAVE_DISABLED,
    payload: value,
  }
}

export function setCreateProjectSelectedEnvironments(
  configs: EnvironmentSelectionConfig[],
): CreateProjectFormActionTypes {
  return {
    type: CREATE_PROJECT_FORM_SET_SELECTED_ENVS,
    payload: configs,
  }
}

export function setCreateProjectProductionEnvId(id: string | undefined): CreateProjectFormActionTypes {
  return {
    type: CREATE_PROJECT_FORM_SET_PROD_ENV_ID,
    payload: id,
  }
}

export function createProjectFormSetIsBranchConfirmationShown(isShown: boolean): CreateProjectFormActionTypes {
  return {
    type: CREATE_PROJECT_FORM_SET_IS_BRANCH_CONFIRM_SHOWN,
    payload: isShown,
  }
}

export function startNewWorkflow(type: PROJECT_TYPE): any {
  return async (dispatch: Dispatch<CreateProjectFormActionTypes>, getState: () => IStoreState): Promise<void> => {
    switch (type) {
      case PROJECT_TYPE.DRUPAL_9: {
        dispatch(initializeStoreDataForDrupal9())
        return
      }
      case PROJECT_TYPE.DRUPAL_8: {
        dispatch(initializeStoreDataForDrupal8())
        return
      }
      case PROJECT_TYPE.DRUPAL_7: {
        dispatch(initializeStoreDataForDrupal7())
        return
      }
      case PROJECT_TYPE.WORDPRESS: {
        dispatch(initializeStoreDataForWordpress())
        return
      }
      case PROJECT_TYPE.WORKFLOW: {
        dispatch(initializeStoreDataForWorkflow())
        return
      }
      case PROJECT_TYPE.OTHER: {
        dispatch(initializeStoreDataForOther())
        return
      }
      default:
        dispatch(initializeStoreDataForOther())
        return
    }
  }
}

export function initializeStoreDataForDrupal9(): any {
  return createThunkToInitializeProject(PROJECT_TYPE.DRUPAL_9, createDrupal9Project)
}

export function initializeStoreDataForDrupal8(): any {
  return createThunkToInitializeProject(PROJECT_TYPE.DRUPAL_8, createDrupal8Project)
}

export function initializeStoreDataForDrupal7(): any {
  return createThunkToInitializeProject(PROJECT_TYPE.DRUPAL_7, createDrupal7Project)
}

export function initializeStoreDataForWordpress(): any {
  return createThunkToInitializeProject(PROJECT_TYPE.WORDPRESS, createWordpressProject)
}

export function initializeStoreDataForOther(): any {
  return createThunkToInitializeProject(PROJECT_TYPE.OTHER, createOtherProject)
}

export function initializeStoreDataForWorkflow(): any {
  return createThunkToInitializeProject(PROJECT_TYPE.WORKFLOW, createWorkflowProject)
}

export function addSelectedEnvironment(config: EnvironmentSelectionConfig): any {
  return async (dispatch: Dispatch<CreateProjectFormActionTypes>, getState: () => IStoreState): Promise<void> => {
    const appState = getState()
    const selectedEnvironments: EnvironmentSelectionConfig[] = [
      ...appState.createProjectForm.selectedEnvironments,
      config,
    ]
    dispatch(setCreateProjectSelectedEnvironments(selectedEnvironments))
    dispatch(createProjectDataReorderProjectEnvironmentsBySelectedEnvironments())
  }
}

export function removeSelectedEnvironment(config: EnvironmentSelectionConfig): any {
  return async (dispatch: Dispatch<CreateProjectFormActionTypes>, getState: () => IStoreState): Promise<void> => {
    const appState = getState()
    const selectedEnvironments: EnvironmentSelectionConfig[] = appState.createProjectForm.selectedEnvironments.filter(
      (envConfig) => config.id !== envConfig.id,
    )
    dispatch(setCreateProjectSelectedEnvironments(selectedEnvironments))
    dispatch(createProjectDataReorderProjectEnvironmentsBySelectedEnvironments())
  }
}

export function reorderSelectedEnvironment(result: DropResult): any {
  return async (dispatch: Dispatch<CreateProjectFormActionTypes>, getState: () => IStoreState): Promise<void> => {
    const { source, destination } = result
    if (destination && source) {
      const hasNewIndex = !(destination.droppableId === source.droppableId && destination.index === source.index)
      if (hasNewIndex) {
        const appState = getState()
        const reorderedSelectedEnvironments: EnvironmentSelectionConfig[] = [
          ...appState.createProjectForm.selectedEnvironments,
        ]
        const removed = reorderedSelectedEnvironments.splice(source.index, 1)
        reorderedSelectedEnvironments.splice(destination.index, 0, ...removed)
        dispatch(setCreateProjectSelectedEnvironments(reorderedSelectedEnvironments))
        dispatch(createProjectDataReorderProjectEnvironmentsBySelectedEnvironments())
      }
    }
  }
}

export function createProjectFormSubmit(): any {
  return async (dispatch: Dispatch<CreateProjectFormActionTypes>, getState: () => IStoreState): Promise<void> => {
    const appState = getState()
    const project = appState.createProjectData.project

    if (project.hasCode) {
      const branchNamesSet = new Set(
        project.projectEnvironments?.map((env) => env.projectEnvironmentCode?.branch?.name || ''),
      )
      if (branchNamesSet.size > 1) {
        dispatch(createProjectFormSetIsBranchConfirmationShown(true))
      } else {
        dispatch(createProjectDataSave())
      }
    } else {
      dispatch(createProjectDataSave())
    }
  }
}

function createThunkToInitializeProject(type: PROJECT_TYPE, createProjectFunction: any): any {
  return async (
    dispatch: Dispatch<CreateProjectFormActionTypes | CreateProjectDataActionTypes>,
    getState: () => IStoreState,
  ): Promise<void> => {
    const selectedEnvConfigs: EnvironmentSelectionConfig[] = []
    const projectEnvironments: ProjectEnvironment[] = []
    const appState = getState()
    const deploymentEnvironments = appState.deploymentEnvironments.data
    let prodEnvId = undefined
    if (deploymentEnvironments) {
      deploymentEnvironments.forEach((env) => {
        if (env.isProd) {
          const projectEnvironment = createNewProjectEnvironment(env.id, 1)
          projectEnvironments.push(projectEnvironment)
          prodEnvId = env.id
        } else if (env.isDev) {
          const config = transformToEnvironmentSelectionConfig(env)
          selectedEnvConfigs.push(config)
          const projectEnvironment = createNewProjectEnvironment(env.id, 0)
          projectEnvironments.push(projectEnvironment)
        } else {}
      })
      dispatch(createProjectFormReset())
      dispatch(createProjectDataReset())
      dispatch(setCreateProjectSelectedWorkflow(type))
      dispatch(setCreateProjectProductionEnvId(prodEnvId))
      const gitHost = appState.bundle.data.gitHost
      const baseProject = createProjectFunction(gitHost, projectEnvironments)
      dispatch(createProjectDataSetProject(baseProject))
      dispatch(setCreateProjectSelectedEnvironments(selectedEnvConfigs))
      dispatch(createProjectDataGenerateEnvironmentDefaultData())
    }
  }
}
