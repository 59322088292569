import {ActionConfiguration} from 'src/models/pipeline/ActionConfiguration'
import React from 'react'
import {Grid} from '@material-ui/core'
import GenericDialog from 'src/components/common/GenericDialog'
import {ACTION_TYPE} from 'src/models/pipeline/Action'
import {ActionType} from 'src/models/pipeline/ActionType'
import FileTemplateActionConfigEditor from './FileTemplateActionConfigEditor'
import {FileTemplateActionConfiguration} from 'src/models/pipeline/FileTemplateActionConfiguration'
import {cloneDeep} from 'lodash'
import PipelineService from 'src/services/PipelineService'
import IStoreState from 'src/store/IStoreState'
import {IPipelineTemplateSectionProps} from '../pipelineTemplates/PipelineTemplateSection'
import {connect} from 'react-redux'
import Project from 'src/models/Project'
import SnackbarService, {SNACKBAR_TYPES} from 'src/services/SnackbarService'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import DeploymentEnvironmentHelper from 'src/helpers/DeploymentEnvironmentHelper'
import DeploymentTargetGroup from 'src/models/DeploymentTargetGroup'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import ScriptActionConfigEditor from './ScriptActionConfigEditor'
import {ScriptActionConfiguration} from 'src/models/pipeline/ScriptActionConfiguration'
import { find } from 'lodash';
import {ServiceActionConfiguration} from 'src/models/pipeline/ServiceActionConfiguration'
import ServiceActionConfigEditor from './ServiceActionConfigEditor'
import {OutlinedTextField} from '../../../../../common/OutlinedTextField'
import { DropDownEntry } from '../../../../../../models/DropDownEntry'
import {OutlinedDropDown} from '../../../../../common/OutlinedDropDown'
import {SaveCancelDialogActions} from '../../../../../common/SaveCancelDialogActions'

export interface IActionConfigEditorProps {
  onClose: (actionConfiguration?: ActionConfiguration) => void,
  actionConfiguration?: ActionConfiguration
  project?: Project
  environments?: DeploymentEnvironment[]
}

export interface IActionConfigEditorState {
  actionConfiguration: ActionConfiguration
  selectedGroupOptions: DropDownEntry[]
  actionTypeOptions: DropDownEntry[]
  selectedEnvironmentOptions: DropDownEntry[]
}

export class ActionConfigEditor extends React.Component<IActionConfigEditorProps, IActionConfigEditorState> {

  private readonly actionTypes: ActionType[] = [
    new ActionType({value: ACTION_TYPE.DATABASE_SNAPSHOT, displayName: 'Database Snapshot'}),
    new ActionType({value: ACTION_TYPE.DATABASE_COPY, displayName: 'Database Copy'}),
    new ActionType({value: ACTION_TYPE.ASSET_MERGE, displayName: 'Asset Merge'}),
    new ActionType({value: ACTION_TYPE.CODE_DEPLOY, displayName: 'Code Deploy'}),
    new ActionType({value: ACTION_TYPE.CODE_PROMOTE, displayName: 'Code Promote'}),
    new ActionType({value: ACTION_TYPE.CODE_MERGE, displayName: 'Code Merge'}),
    new ActionType({value: ACTION_TYPE.FILE_TEMPLATE, displayName: 'File Template', hasUserEditor: true}),
    new ActionType({value: ACTION_TYPE.SCRIPT, displayName: 'Script', hasUserEditor: true}),
    new ActionType({value: ACTION_TYPE.SERVICE, displayName: 'Service', hasUserEditor: true})
  ]

  constructor(props: IActionConfigEditorProps) {
    super(props)
    let selectedGroupO: DropDownEntry[]
    const selectableActionT: ActionType[] = this.actionTypes.filter((it: ActionType): boolean => it.hasUserEditor)

    const actionOptions: DropDownEntry[] = selectableActionT.map((actionType: ActionType) => {
      return new DropDownEntry(actionType.displayName, actionType.value)
    })

    if (this.props.actionConfiguration) {
      const mySelectedEnv: DeploymentEnvironment = this.props.environments.find((env: DeploymentEnvironment) => env.id === this.props.actionConfiguration.targetEnvironment)
      const selectableGroupO: DeploymentTargetGroup[] = mySelectedEnv ? mySelectedEnv.deploymentTargetGroups : null
      selectedGroupO = selectableGroupO.map((projectDeploymentGroup: DeploymentTargetGroup) => {
        return new DropDownEntry(projectDeploymentGroup.name, projectDeploymentGroup.id)
      })
    }

    const envOptions: DropDownEntry[] = this.props.project.projectEnvironments.map((projectEnvironment: ProjectEnvironment) => {
      const envTitle: string = DeploymentEnvironmentHelper.getEnvironmentTitleById(projectEnvironment.environmentId)
      return new DropDownEntry(envTitle, projectEnvironment.environmentId)
    })

    this.state = {
      actionConfiguration: props.actionConfiguration,
      actionTypeOptions: actionOptions,
      selectedGroupOptions: selectedGroupO,
      selectedEnvironmentOptions: envOptions
    }
  }

  cancel = (): void => {
    this.props.onClose()
  }

  onSave = async (): Promise<void> => {
    if (this.canSubmit()) {
      try {
        await PipelineService.postProjectActionConfiguration(this.state.actionConfiguration)
        this.props.onClose(this.state.actionConfiguration)
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Step Configuration created')
      } catch (e) {
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Step Configuration creation failed.')
      }
    }
  }

  onEdit = async (): Promise<void> => {
    if (this.canSubmit()) {
      try {
        await PipelineService.putProjectActionConfiguration(this.state.actionConfiguration)
        this.props.onClose(this.state.actionConfiguration)
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Step Configuration edited')
      } catch (e) {
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Step Configuration edit failed.')
      }
    }
  }

  canSubmit = (): boolean => {
    const {actionConfiguration} = this.state

    if (actionConfiguration?.sourceEnvironment) {
      if (!DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(actionConfiguration.sourceEnvironment)) {
        return false
      }
    }

    if (actionConfiguration?.targetEnvironment) {
      if (!DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(actionConfiguration.targetEnvironment)) {
        return false
      }
    }

    return actionConfiguration && actionConfiguration.isValid()
  }

  getSubmitTooltip = (): string => {
    const {actionConfiguration} = this.state
    if (!actionConfiguration || !actionConfiguration.isValid()) {
      return 'All fields are required.'
    }
    if (actionConfiguration?.sourceEnvironment) {
      if (!DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(actionConfiguration.sourceEnvironment)) {
        return this.getSubmitTooltipInvalidEnvironmentHelper(actionConfiguration.sourceEnvironment)
      }
    }

    if (actionConfiguration?.targetEnvironment) {
      if (!DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(actionConfiguration.targetEnvironment)) {
        return this.getSubmitTooltipInvalidEnvironmentHelper(actionConfiguration.targetEnvironment)
      }
    }
    return ''
  }

  getSubmitTooltipInvalidEnvironmentHelper = (environmentId: string): string => {
    return `Cannot create Step due to Environment ${DeploymentEnvironmentHelper.getEnvironmentTitleById(environmentId)} being invalid. Resolve your environment or contact your Cascade Administrator for assistance.`
  }

  handleSelect = (event: any): void => {
    const selectedEntry: DropDownEntry = event.target
    const copiedAC: ActionConfiguration = this.makeActionConfiguration(selectedEntry.value as ACTION_TYPE)
    copiedAC.name = ""
    copiedAC.targetEnvironment = undefined
    copiedAC.targetDeploymentTargetGroupId = undefined
    this.onActionConfigChanged(copiedAC)
  }

  handleEnvSelect = (event: any): void => {
    const selectedEntry: DropDownEntry = event.target
    const copiedAC: ActionConfiguration = cloneDeep(this.state.actionConfiguration)
    copiedAC.targetEnvironment = selectedEntry.value
    const mySelectedEnv: DeploymentEnvironment = this.props.environments.find((env: DeploymentEnvironment) => env.id === copiedAC.targetEnvironment)
    const selectableGroupO: DeploymentTargetGroup[] = mySelectedEnv.deploymentTargetGroups
    const selectedGroupO: DropDownEntry[] = selectableGroupO.map((projectDeploymentGroup: DeploymentTargetGroup) => {
      return new DropDownEntry(projectDeploymentGroup.name, projectDeploymentGroup.id)
    })

    this.setState({actionConfiguration: copiedAC, selectedGroupOptions: selectedGroupO})
  }

  handleGroupSelect = (event: any): void => {
    const selectedEntry: DropDownEntry = event.target
    const copiedAC: ActionConfiguration = cloneDeep(this.state.actionConfiguration)
    copiedAC.targetDeploymentTargetGroupId = selectedEntry.value
    this.setState({actionConfiguration: copiedAC})
  }

  makeActionConfiguration = (actionType: ACTION_TYPE): ActionConfiguration => {
    switch (actionType) {
      case ACTION_TYPE.FILE_TEMPLATE:
        return new FileTemplateActionConfiguration({
          templateVariables: new Map([['', '']]),
          projectId: this.props.project.id
        })
      case ACTION_TYPE.SCRIPT:
        return new ScriptActionConfiguration({projectId: this.props.project.id})
      case ACTION_TYPE.SERVICE:
        return new ServiceActionConfiguration({projectId: this.props.project.id})
      default:
        throw new Error(`Step Type ${actionType} is not supported by the editor.`)
    }
  }

  onActionNameChange = (event: any): void => {
    const copiedActionConfig: ActionConfiguration = cloneDeep(this.state.actionConfiguration)
    copiedActionConfig.name = event.target.value
    this.onActionConfigChanged(copiedActionConfig)
  }

  onActionConfigChanged = (actionConfiguration: ActionConfiguration): void => {
    this.setState({actionConfiguration})
  }

  getSpecificActionEditor = (): React.ReactNode => {
    const {actionConfiguration} = this.state

    if (actionConfiguration) {
      switch (actionConfiguration.actionType) {
        case ACTION_TYPE.FILE_TEMPLATE :
          return (
            // TODO: Test to make sure you're getting back instance of this component
            <FileTemplateActionConfigEditor onActionConfigChanged={this.onActionConfigChanged}
                                            actionConfiguration={this.state.actionConfiguration as FileTemplateActionConfiguration}/>
          )
        case ACTION_TYPE.SCRIPT :
          return (
            <ScriptActionConfigEditor onActionConfigChanged={this.onActionConfigChanged}
                                      actionConfiguration={actionConfiguration as ScriptActionConfiguration}/>
          )
        case ACTION_TYPE.SERVICE :
          return (
            <ServiceActionConfigEditor onActionConfigChanged={this.onActionConfigChanged}
                                       actionConfiguration={actionConfiguration as ServiceActionConfiguration}/>
          )
        default:
          throw new Error(`Step Type ${actionConfiguration.actionType} is not supported by the editor.`)
      }
    }
    return null
  }

  render(): React.ReactNode {
    const {actionConfiguration} = this.state
    const editing: boolean = !!actionConfiguration && !!actionConfiguration.id
    const actions: React.ReactNode =
      <SaveCancelDialogActions name='action-config-actions'
                               handleCancel={this.cancel}
                               handleSave={editing ? this.onEdit : this.onSave}
                               buttonSaveDisabled={!this.canSubmit()}
                               buttonSaveTooltip={this.getSubmitTooltip()}
                               buttonCancelLabel='Cancel'
                               buttonSaveLabel={editing ? 'Save' : 'Create'}/>

    let selectedActionType: DropDownEntry
    if (actionConfiguration) {
      selectedActionType = find(this.state.actionTypeOptions, (typeOption: DropDownEntry) => {
        return typeOption.value === actionConfiguration.actionType.toString()
      })
    }
    let selectedTargetEnvironment: DropDownEntry
    if (actionConfiguration && actionConfiguration.targetEnvironment) {
      selectedTargetEnvironment = find(this.state.selectedEnvironmentOptions, (typeOption: DropDownEntry) => {
        return typeOption.value === actionConfiguration.targetEnvironment.toString()
      })
    }
    else {
      selectedTargetEnvironment = new DropDownEntry("", "")
    }

    let selectedDeploymentGroup: DropDownEntry
    if (actionConfiguration && actionConfiguration.targetDeploymentTargetGroupId) {
      selectedDeploymentGroup = find(this.state.selectedGroupOptions, (typeOption: DropDownEntry) => {
        return typeOption.value === actionConfiguration.targetDeploymentTargetGroupId.toString()
      })
    }
    else {
      selectedDeploymentGroup = new DropDownEntry("", "")
    }

    return (
      <GenericDialog title={(editing ? 'Edit' : 'Create New') + ' Step'}
                     onClose={this.cancel}
                     actions={actions}
                     style={{overflow: 'hidden'}}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <OutlinedDropDown label='Step Type'
                              id='actionType' value={selectedActionType}
                              dropDownOptions={this.state.actionTypeOptions}
                              disabled={editing}
                              handleChange={this.handleSelect}/>
          </Grid>

          {actionConfiguration &&
          <Grid item xs={12}>
            <OutlinedTextField name='ActionName'
                               id='actionName'
                               label='Step Name'
                               required={true}
                               value={actionConfiguration.name}
                               handleChange={this.onActionNameChange}/>
            <Grid item xs={12}>
              <OutlinedDropDown id='target-environment'
                                label='Target Environment'
                                handleChange={this.handleEnvSelect}
                                required={true}
                                disabled={editing}
                                value={selectedTargetEnvironment}
                                dropDownOptions={this.state.selectedEnvironmentOptions}/>
            </Grid>

            {actionConfiguration.targetEnvironment &&
            <Grid item xs={12}>
              <OutlinedDropDown label='Deployment Group'
                                id='deployment-group'
                                value={selectedDeploymentGroup}
                                dropDownOptions={this.state.selectedGroupOptions}
                                handleChange={this.handleGroupSelect}/>
            </Grid>
            }
          </Grid>
          }
          <Grid item xs={12}>
            {this.getSpecificActionEditor()}
          </Grid>
        </Grid>
      </GenericDialog>
    )
  }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IPipelineTemplateSectionProps): any => ({
  project: state.selectedProject.project,
  environments: state.deploymentEnvironments.data
})

export default connect(mapStateToProps)(ActionConfigEditor)