import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            boxShadow:'0px 2px 4px -1px #dde3eb, 0px 4px 5px 0px #dde3eb, 0px 1px 10px 0px #dde3eb',
            borderRadius:'4px',
            padding: theme.spacing(2),
            minHeight: '88px',
        },
        textLabel: {},
    }),
)
