import 'reflect-metadata'
import {Type} from 'class-transformer'
import {assign} from 'lodash'
import STATUS from './Status'

class Task {
  log: string
  estimatedDuration: number
  status: STATUS
  @Type(() => Date)
  startTime: Date
  @Type(() => Date)
  endTime: Date
  taskName: string
  order: number

  constructor(props?: Partial<Task>) {
    props = props || {}

    const defaultProps: Partial<Task> = {
      log: undefined,
      estimatedDuration: undefined,
      status: undefined,
      startTime: undefined,
      endTime: undefined,
      taskName: undefined,
      order: undefined
    }
    assign(this, defaultProps, props)
  }
}

export default Task
