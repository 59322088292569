import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItemContainer: {
      minHeight: '48px',
      maxHeight: '48px',
      height: '48px',
    },
    menuIcon: {
      color: theme.palette.primary.main,
    },
    menuLabel: {
      color: theme.palette.grey[800],
    },
  }),
)
