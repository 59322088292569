import React from 'react'
import {Divider, FormControlLabel, FormLabel, Grid, Input, Radio, RadioGroup} from '@material-ui/core'
import DeploymentTarget, {ESCALATION_METHOD} from '../../../models/DeploymentTarget'
import FormField from '../../common/FormField'

export interface IDeploymentTargetWizardStep3Props {
  handleDeploymentTargetPropertyChange: (key: string, value: any) => void,
  deploymentTarget: DeploymentTarget,
  disableFields: boolean,
  errorFields: Map<string, string>
}

export interface IDeploymentTargetWizardStep3State {

}

export class DeploymentTargetWizardStep3 extends React.Component<IDeploymentTargetWizardStep3Props, IDeploymentTargetWizardStep3State> {
  handleEscalationPasswordClick = (): void => {
    this.props.deploymentTarget.escalationPassword = ''
    this.props.handleDeploymentTargetPropertyChange('escalationPassword', '')
  }

  handleInputChange = (event: any): void => {
    const target: any = event.target
    this.props.handleDeploymentTargetPropertyChange(target.name, target.value)
  }

  render(): React.ReactNode {
    const {deploymentTarget, errorFields, disableFields} = this.props

    return (
      <Grid container direction='column' spacing={2}>
        <Grid item sm={12}>
          <FormLabel component='legend'>How should the user should escalate to a privileged user?</FormLabel>
        </Grid>
        <Grid item sm={12}>
          <FormField error={errorFields.has('escalationMethod')} message={errorFields.get('escalationMethod')}>
            <RadioGroup name='escalationMethod' value={deploymentTarget.escalationMethod} onChange={this.handleInputChange}>
              <FormControlLabel value={ESCALATION_METHOD.SUDO} disabled={disableFields} control={<Radio color='primary'/>}
                                label={<i>sudo</i>}/>
              <FormControlLabel value={ESCALATION_METHOD.SU} disabled={disableFields} control={<Radio color='primary'/>}
                                label={<i>su</i>}/>
              <FormControlLabel value={ESCALATION_METHOD.NONE} disabled={this.props.disableFields} control={<Radio color='primary'/>}
                                label='None'/>
            </RadioGroup>
          </FormField>
        </Grid>
        <Grid item sm={12}>
          <Divider/>
        </Grid>
        <Grid item sm={12}>
          {deploymentTarget.escalationMethod === ESCALATION_METHOD.SUDO &&
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FormField label='Escalation User' error={errorFields.has('escalationUser')} message={errorFields.get('escalationUser')} mandatory={true}>
                <Input name='escalationUser' disabled={disableFields} value={deploymentTarget.escalationUser}
                       onChange={this.handleInputChange}/>
              </FormField>
            </Grid>
            <Grid item sm={12}>
              <p className='helpText'>
                Hint: Add the line <i>&lt;nonescalationusername&gt; ALL=(ALL) NOPASSWD:ALL</i><br/>
                in the file <i>/etc/sudoers.d/&lt;nonescalationusername&gt;</i>
              </p>
            </Grid>
          </Grid>
          }
          {deploymentTarget.escalationMethod === ESCALATION_METHOD.SU &&
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FormField label='Escalation User' error={errorFields.has('escalationUser')} message={errorFields.get('escalationUser')} mandatory={true}>
                <Input name='escalationUser' disabled={disableFields} value={deploymentTarget.escalationUser}
                       onChange={this.handleInputChange}/>
              </FormField>
            </Grid>
            <Grid item sm={12}>
              <FormField label='Escalation Password' error={errorFields.has('escalationPassword')}
                         message={errorFields.get('escalationPassword')} mandatory={true}>
                <Input name='escalationPassword' disabled={disableFields} value={deploymentTarget.escalationPassword}
                       onClick={this.handleEscalationPasswordClick}
                       type='password' onChange={this.handleInputChange}/>
              </FormField>
            </Grid>
            <Grid item sm={12}>
              <p className='helpText'>
                Warning: Password will be stored in plain text.<br/>
                (This is not compatible with most security compliance frameworks)
              </p>
            </Grid>
          </Grid>
          }
          {deploymentTarget.escalationMethod === ESCALATION_METHOD.NONE &&
          <Grid item sm={12}>
            <p className='helpText'>
              Do not forget to set <i>PermitRootLogin yes</i><br/>
              in <i>/etc/ssh/sshd_config</i> and restart ssh.
            </p>
            <p className='helpText'>
              Warning: Enabling Root Login is not compatible<br/>
              with most security compliance frameworks.
            </p>
          </Grid>
          }
          <FormField error={errorFields.has('install')} message={errorFields.get('install')} />
        </Grid>
      </Grid>
    )
  }
}

export default DeploymentTargetWizardStep3
