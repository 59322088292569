import {ACTION_TYPE} from '../../../../../../models/pipeline/Action';
import PathUtils from '../../../../../../helpers/PathUtils';

/**
 * formatName - Adds newlines after the "/" withing file paths at regular intervals
 * @param nameIn
 */
export function formatName(nameIn: string, actionType: ACTION_TYPE, width: number): string {
    if (actionType != ACTION_TYPE.FILE_TEMPLATE) {
        return nameIn
    }
    return PathUtils.wrapDirPath(nameIn, width)
}
