import React from 'react'
import {
  Collapse,
  Table,
  TableCell,
  TableRow
} from '@material-ui/core'
import Moment from 'react-moment'
import Pipeline from "../../../../../models/pipeline/Pipeline";
import {faCheck, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "@mui/material";
import ActionTable from "./ActionTable.component";


interface IPipelineRowProps {
  pipeline: Pipeline
  index: number
  pipelineName: string
}

interface IPipelineRowState {
  pipeline: Pipeline,
  showActions: boolean,
  index: number,
  pipelineName: string
}


class PipelineRow extends React.Component<IPipelineRowProps, IPipelineRowState> {
  constructor(props: IPipelineRowProps) {
    super(props)
    this.state = {
      pipeline: props.pipeline,
      showActions: false,
      index: props.index,
      pipelineName: props.pipelineName
    }
  }

  handleOpenPipeline = (value: boolean): void => {
    this.setState({showActions: value})
  }

  statusIcon = (status: string) => {

    let styles = {
      color: 'darkred'
    }

    let iconValue: any = faExclamationCircle
    if (status === 'SUCCESS') {
      iconValue = faCheck
      styles.color = 'green'
    }
    return <FontAwesomeIcon icon={iconValue} style={styles}/>
  }


  render = (): React.ReactNode => {
    const {showActions, pipeline, pipelineName} = this.state

    return (
      <React.Fragment>
        <TableRow hover>
          <TableCell padding='default'>
            {this.statusIcon(pipeline.status)}
          </TableCell>

          <TableCell padding='none'>
            {pipelineName}
          </TableCell>

          <TableCell padding='none'>
            {pipeline.startTime && (
              <>
                <Moment format='hh:mm:ss a' date={pipeline.startTime}/><br/>
                <Moment format='MM-DD-YYYY' date={pipeline.startTime}/>
              </>
            )}
          </TableCell>

          <TableCell padding='none'>
            {pipeline.endTime && (
              <>
                <Moment format='hh:mm:ss a' date={pipeline.endTime}/><br/>
                <Moment format='MM-DD-YYYY' date={pipeline.endTime}/>
              </>
            )}
          </TableCell>

          <TableCell padding='none'>
            {pipeline?.actions.length ? pipeline.actions[0].actionConfiguration.updatedBy : ''}
          </TableCell>

          <TableCell align={'center'}>
            <Button
              aria-label="expand row"
              size="small"
              onClick={() => this.handleOpenPipeline(!this.state.showActions)}
              variant={'contained'}
              color={'inherit'}
            >
              {showActions ? 'Hide Details' : 'View Details'}
            </Button>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{padding: 0, borderBottomColor: 'black', borderBottomWidth: 'thin'}} colSpan={6}>

            {pipeline?.actions.length > 0 &&
            <Collapse in={showActions} timeout="auto" unmountOnExit>
              <Table aria-label={'pipeline-actions'}>
                {pipeline?.actions.length && <ActionTable actions={pipeline.actions}/>}
              </Table>
            </Collapse>
            }

          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

}

export default PipelineRow
