import { assign } from 'lodash'
import 'reflect-metadata'
import {Type} from 'class-transformer'

export enum IMPORT_REPOSITORY_STATUS {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  IMPORTING = 'IMPORTING'
}

export default class ImportRepository {
  url: string
  @Type(() => Map)
  abBranchToImportBranch: Map<string, string>
  keepHistory: boolean
  username: string
  password: string
  status: IMPORT_REPOSITORY_STATUS

  constructor(props?: Partial<ImportRepository>) {
    props = props || {}
    const defaultProps: Partial<ImportRepository> = {
      url: undefined,
      abBranchToImportBranch: undefined,
      keepHistory: undefined,
      username: undefined,
      password: undefined,
      status: undefined
    }
    assign(this, defaultProps, props)
  }
}
