import {createProcessIO, DataProcessIO, DataProcessorError, ProjectValidationError} from 'src/helpers/shared'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import Project from 'src/models/Project'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import {AssetProcessContext, createProjectEnvironmentWithAssetDefaults} from './asset/env-asset-processor'
import {CodeProcessContext, createProjectEnvironmentWithCodeDefaults} from './code/env-code-processor'
import {createProjectEnvironmentWithDatabaseDefaults, DatabaseProcessContext} from './database/env-database-processor'
import {resolveDeploymentEnvironmentById, resolveProjectName} from '../../shared'
import {createProjectEnvironmentWithDefaultSymlinks, LinkProcessContext} from './link/env-link-processor'

export interface ProjectEnvironmentProcessConfig {
  code: boolean
  asset: boolean
  database: boolean
  links: boolean
}

export interface ProjectProcessContext {
  project: Project
  deploymentEnvironments: DeploymentEnvironment[]
  config: ProjectEnvironmentProcessConfig
}

export type ProjectProcessIO = DataProcessIO<Project, DataProcessorError>

export type EnvironmentProcessIO = DataProcessIO<ProjectEnvironment, DataProcessorError>

export function createProjectEnvironmentDefaults(
  env: ProjectEnvironment,
  projectName: string,
  deploymentEnvironments: DeploymentEnvironment[],
  inputProject: Project,
  config: ProjectEnvironmentProcessConfig,
  index: number,
): EnvironmentProcessIO {
  const { code, asset, database, links } = config
  let output: DataProcessIO<ProjectEnvironment, DataProcessorError> = createProcessIO(env, [])
  const deploymentEnvironment = resolveDeploymentEnvironmentById(env.environmentId, deploymentEnvironments)
  const prodEnv = deploymentEnvironments.find((env) => {
    return env.isProd
  })
  if (code) {
    const codeInput = output
    const codeContext: CodeProcessContext = {
      index,
      projectName,
      deploymentEnvironment,
    }
    output = createProjectEnvironmentWithCodeDefaults(codeInput, codeContext)
  }
  if (asset) {
    const assetInput = output
    if (prodEnv) {
      const prodEnvName = prodEnv.title
      const prodProjectEnvironment = inputProject.projectEnvironments.find((env) => {
        return env.environmentId === prodEnv.id
      })
      const prodAssetGroups = prodProjectEnvironment?.projectEnvironmentAssets?.assetGroups || []
      const assetContext: AssetProcessContext = {
        index,
        projectName,
        deploymentEnvironment,
        prodAssetGroups,
        prodEnvName,
      }
      output = createProjectEnvironmentWithAssetDefaults(assetInput, assetContext)
    }
  }
  if (database) {
    const databaseInput = output
    if (prodEnv) {
      const prodProjectEnvironment = inputProject.projectEnvironments.find((env) => {
        return env.environmentId === prodEnv.id
      })
      const prodDatabaseAssociationIds: number[] = [] 
      prodProjectEnvironment?.projectEnvironmentDatabases?.databases?.forEach((database) => {
        prodDatabaseAssociationIds.push(database.associationId)
      })
      
      const databaseContext: DatabaseProcessContext = {
        index,
        projectName,
        deploymentEnvironment,
        associationIds: prodDatabaseAssociationIds,
      }
      output = createProjectEnvironmentWithDatabaseDefaults(databaseInput, databaseContext)
    }
  }
  if (links) {
    const linksInput = output
    const linksContext: LinkProcessContext = {
      index,
      projectName,
      deploymentEnvironment,
    }
    output = createProjectEnvironmentWithDefaultSymlinks(linksInput, linksContext)
  }

  return output
}

export function transformProjectEnvironments(
  input: ProjectProcessIO,
  context: ProjectProcessContext,
): ProjectProcessIO {
  const project = input.value
  if (project) {
    const projectName = resolveProjectName(project)
    const { deploymentEnvironments, config } = context
    project.projectEnvironments = project.projectEnvironments.map((env: ProjectEnvironment, index: number) => {
      const environmentOutput = createProjectEnvironmentDefaults(
        env,
        projectName,
        deploymentEnvironments,
        project,
        config,
        index,
      )
      return environmentOutput.value
    })
    return createProcessIO(project, input.errors)
  }
  return createProcessIO(null, [...input.errors, ProjectValidationError.UNDEFINED_FUNC_INPUT])
}
