import React, { Component } from 'react'
import PropTypes from 'prop-types'
import keycloakService from '../../../services/KeycloakService'
import { Fade, Menu, MenuItem } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faLifeRing } from '@fortawesome/free-solid-svg-icons'
import { faGitlab } from '@fortawesome/free-brands-svg-icons'
import { menuEntries } from './header.config'
import User from '../../../models/User'
import { linkReferences } from '../../../config/links.config'
import '../../../styles/base/header.scss'
import BundleService from '../../../services/BundleService'
import Bundle from '../../../models/Bundle'

const iconStyles: any = { cursor: 'pointer', marginLeft: '10px' }

interface IAnchorConfig {
  vertical: 'top' | 'center' | 'bottom' | number
  horizontal: 'left' | 'center' | 'right' | number
}

const anchorConfig: { [key: string]: IAnchorConfig } = {
  origin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transform: {
    vertical: 'top',
    horizontal: 'center',
  }
}

export interface ICurrentUserMenuProps {
  currentUser: User
}

export interface ICurrentUserMenuState {
  anchorEl: HTMLElement | null
  bundle: Bundle | null
}

export class CurrentUserMenu extends Component<ICurrentUserMenuProps, ICurrentUserMenuState> {
  constructor(props: ICurrentUserMenuProps) {
    super(props)
    this.state = {
      anchorEl: null,
      bundle:null
    }
  }
  componentDidMount() {
    this.loadBundle();
  }
  async loadBundle() {
    try {
      const bundle = await BundleService.getBundle()
      this.setState({bundle})
    } catch (error) {
      console.warn("Failed to load resource bundle")
    }
  }

  onLogOut = (): void => {
    keycloakService.logout()
  }

  openMenu = (event: React.MouseEvent<HTMLDivElement>): void => {
    this.setState({ anchorEl: event.currentTarget })
  }

  closeMenu = (): void => {
    this.setState({ anchorEl: null })
  }

  goToAccount = (): void => {
    // @ts-ignore
    window.open(keycloakService.idTokenParsed.iss + '/account')
    this.closeMenu()
  }

  render(): React.ReactNode {
    const { anchorEl } = this.state
    const { currentUser } = this.props
    const displayName = resolveDisplayName(currentUser)
    const { ACCOUNT, LOGOUT, SUPPORT, GITLAB } = menuEntries
    const { SUPPORTLINK } = linkReferences
    return (
      <div>
        <div className='menu-icon'>
          <div className='headerItem'>
            <a href={this.state.bundle ? "https://" + this.state.bundle.gitHost : ""} target='_blank' rel='noreferrer'>
              {GITLAB.title}
              <FontAwesomeIcon
                icon={faGitlab}
                size='lg'
                style={iconStyles}
              />
            </a>
          </div>
          <div className='headerItem'>
            <a href={SUPPORTLINK.href} target='_blank' rel='noreferrer'>
              {SUPPORT.title}
              <FontAwesomeIcon
                icon={faLifeRing}
                size='lg'
                style={iconStyles}
              />
            </a>
          </div>
          <div className='headerItem' onClick={this.openMenu}>
            {displayName}
            <FontAwesomeIcon
              icon={faUserCircle}
              size='lg'
              style={iconStyles}
            />
          </div>
        </div>
        <Menu
          id='fade-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.closeMenu}
          TransitionComponent={Fade}
          getContentAnchorEl={null}
          anchorOrigin={anchorConfig.origin}
          transformOrigin={anchorConfig.transform}
        >
          <MenuItem onClick={this.goToAccount}>{ACCOUNT.title}</MenuItem>
          <MenuItem onClick={this.onLogOut}>{LOGOUT.title}</MenuItem>
        </Menu>
      </div>
    )
  }
}

function resolveDisplayName(currentUser: User): string {
  return `${currentUser?.name?.givenName} ${currentUser?.name?.familyName}`
}

// @ts-ignore
CurrentUserMenu.propTypes = {
  currentUser: PropTypes.instanceOf(User).isRequired,
}
