export const PROJECT_ACTION_TYPES: any = {
  CODE_MERGE: {displayName: 'MERGE'},
  CODE_PROMOTE: {displayName: 'PROMOTE'},
  CODE_DEPLOY: {displayName: 'DEPLOY'},
  ASSET_MERGE: {displayName: 'MERGE'},
  DATABASE_COPY: {displayName: 'COPY'},
  DATABASE_SNAPSHOT: {displayName: 'SNAPSHOT'},
  DATABASE_IMPORT: {displayName: 'IMPORT'}
}

export const PROJECT_PAGE_ACTIONS: any = {
  CHECKOUT: 'CHECKOUT',
  CLONE: 'CLONE',
  PREVIEW: 'PREVIEW',
  MERGE_CODE: 'MERGE_CODE',
  COPY_DATABASE: 'COPY_DATABASE',
  MERGE_ASSETS: 'MERGE_ASSETS',
  DATABASE_SNAPSHOT: 'DATABASE_SNAPSHOT',
  DATABASE_IMPORT: 'DATABASE_IMPORT',
  DEPLOY_CODE: 'DEPLOY_CODE',
  PROMOTE_CODE: 'PROMOTE_CODE',
  CANCEL_PIPELINE: 'CANCEL_PIPELINE'
}
