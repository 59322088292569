import { createProcessIO, replaceSpaceWithUnderscore } from 'src/helpers/shared'
import AssetGroup from 'src/models/AssetGroup'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import { hasUndefinedProjectEnvironmentCode } from '../code/env-code-validation'
import { EnvironmentProcessIO } from '../environment-processor'
import { createDefaultSymlink } from '../link/env-link-transformation'
import { appendNewAssetGroupAndSymlinkForEnvironment, createDefaultAssetGroupWithNewAssociationId, createDefaultProjectEnvironmentAssets } from './env-asset-transformation'
import { hasUndefinedProjectEnvironmentAssets } from './env-asset-validation'

export interface AssetProcessContext {
  index: number
  projectName: string
  deploymentEnvironment: DeploymentEnvironment
  prodAssetGroups: AssetGroup[]
  prodEnvName: string
}

export function createProjectEnvironmentWithAssetDefaults(
  input: EnvironmentProcessIO,
  context: AssetProcessContext,
): EnvironmentProcessIO {
  const result = hasUndefinedProjectEnvironmentAssets(input.value)
  if (result) {
    const env = input.value
    const { deploymentEnvironment, projectName, prodAssetGroups, prodEnvName } = context
    const hasCode = !hasUndefinedProjectEnvironmentCode(env)
    const webroot = env?.projectEnvironmentCode?.webroot || ''
    const projectEnvironmentAssets = createDefaultProjectEnvironmentAssets(
      projectName,
      deploymentEnvironment,
      prodAssetGroups,
      prodEnvName,
      hasCode,
      webroot,
    )
    const updatedEnvironment = new ProjectEnvironment({
      projectEnvironmentAssets,
      order: env.order,
      environmentId: env.environmentId,
      projectEnvironmentCode: env.projectEnvironmentCode,
      projectEnvironmentDatabases: env.projectEnvironmentDatabases,
      symLinks: env.symLinks,
    })
    return createProcessIO(updatedEnvironment, input.errors)
  }
  return input
}

export function createProjectEnvironmentWithNewAssetGroupAndSymlink(
  input: EnvironmentProcessIO,
  context: any
): EnvironmentProcessIO {
  const env = input.value
  const { projectName, deploymentEnvironment } = context
  const hasCode = !hasUndefinedProjectEnvironmentCode(env)
  const webroot = env.projectEnvironmentCode.webroot || '/'
  const environmentTitle = replaceSpaceWithUnderscore(deploymentEnvironment.title)
  const highestAssociationId = Math.max(...env.projectEnvironmentAssets.assetGroups.map((ag) => ag.associationId))
  const nextAssociationId = highestAssociationId + 1
  const newAssetGroup = createDefaultAssetGroupWithNewAssociationId(
    nextAssociationId,
    environmentTitle,
    projectName,
    hasCode,
    webroot,
  )
  const newSymlink = createDefaultSymlink(newAssetGroup, webroot)
  const updatedSymlinks = [...env.symLinks, newSymlink]
  const updatedProjEnvAssets = appendNewAssetGroupAndSymlinkForEnvironment(
    env.projectEnvironmentAssets,
    newAssetGroup
  )

  const updatedEnvironment = new ProjectEnvironment({
    projectEnvironmentAssets: updatedProjEnvAssets,
    symLinks: updatedSymlinks,
    order: env.order,
    environmentId: env.environmentId,
    projectEnvironmentCode: env.projectEnvironmentCode,
    projectEnvironmentDatabases: env.projectEnvironmentDatabases,
  })
  
  return createProcessIO(updatedEnvironment, input.errors)
}
