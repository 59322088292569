import { assign } from 'lodash'

export default class Branch {
  name: string
  guarded: boolean

  constructor(props?: Partial<Branch>) {
    props = props || {}

    this.name = undefined
    this.guarded = undefined

    assign(this, props)
  }
}