import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import RetryLoading from '../../../../common/RetryLoading'
import LoadingOverlay from '../../../../common/LoadingOverlay'
import Project from '../../../../../models/Project'
import IStoreState from '../../../../../store/IStoreState'
import HistoryActions from '../../../../../store/actions/historyActions'
import PipelineHistoryTable from "./PipelineHistoryTable";
import Pipeline from "../../../../../models/pipeline/Pipeline";
import {PipelineTemplate} from "../../../../../models/pipeline/PipelineTemplate";

const errorMessage = 'There was an error loading history for this project'
const generalMessage = 'There is no history for this for this project'


export interface IProjectPipelineHistoryProps {
  project?: Project
  loading?: boolean
  error?: any
  pipelineTemplateError?: boolean
  pipelines?: Pipeline[]
  pipelineTemplates?: PipelineTemplate[]
  loadProjectPipelineHistory?: (projectId: string) => void
  loadProjectPipelineTemplateHistory?: (projectId: string) => void
  clearPipelineTemplatesHistory?: () => void
  clearPipelineHistory?: () => void
}

export interface IProjectPipelineHistoryState {}


export class ProjectPipelineHistory extends React.Component<IProjectPipelineHistoryProps, IProjectPipelineHistoryState> {

  componentDidMount = (): void => {
    this.loadStateForPipelineHistory()
  }

  loadStateForPipelineHistory = (): void => {
    this.props.loadProjectPipelineHistory(this.props.project.id)
    this.props.loadProjectPipelineTemplateHistory(this.props.project.id)
  }


  componentWillUnmount = (): void => {
    this.clearPipelineHistoryState()
  }

  clearPipelineHistoryState = (): void => {
    this.props.clearPipelineTemplatesHistory()
    this.props.clearPipelineHistory()
  }

  hasNoData = (): boolean => {
    const { error, pipelines} = this.props
    return !error && !!pipelines && !pipelines.length
  }

  hasData = () => {
    const { pipelines, pipelineTemplates } = this.props
    return pipelines && !!pipelines.length && pipelineTemplates && pipelineTemplates.length
  }

  render = () => {
    const { loading, error, pipelines, pipelineTemplates, pipelineTemplateError } = this.props
    const hasData = this.hasData()
    const hasNoData = this.hasNoData()

    return (
      <div className="loading-overlay-parent" style={{ marginTop: '0.5rem', minHeight: '150px'}}>
        {loading && <LoadingOverlay />}
        {(pipelineTemplateError || error) && <RetryLoading message={errorMessage} reloadData={this.loadStateForPipelineHistory} />}
        {hasNoData && <RetryLoading message={generalMessage} reloadData={this.loadStateForPipelineHistory} />}
        {hasData && <PipelineHistoryTable pipelines={pipelines} pipelineTemplates={pipelineTemplates} />}
      </div>
    )
  }
}

const mapStateToProps: any = (state: IStoreState): any => ({
  project: state.selectedProject.project,
  loading: state.pipelines.loading,
  items: state.history.data,
  error: state.pipelines.error,
  pipelineTemplateError: state.pipelineTemplates.error,
  pipelines: state.pipelines.data,
  pipelineTemplates: state.pipelineTemplates.data
})

const mapDispatchToProps: any = (dispatch: Dispatch): any => ({
  loadProjectPipelineHistory: (projectId: string): void => dispatch(HistoryActions.loadProjectPipelineHistory(projectId)),
  loadProjectPipelineTemplateHistory: (projectId: string): void => dispatch(HistoryActions.loadProjectPipelineTemplatesHistory(projectId)),
  clearPipelineTemplatesHistory: (): void => dispatch(HistoryActions.clearPipelineTemplates()),
  clearPipelineHistory: (): void => dispatch(HistoryActions.clearPipelines())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPipelineHistory)
