import React from 'react'
import {Button, Grid, Tooltip, Typography} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SaveButtonConfig, NotificationConfig } from '../project-details.config'
import { SectionLayout } from './section.layout'
import { useStyles, resolveIcon } from './save-notification.config'

export interface SaveNotificationProps {
  saveConfig: SaveButtonConfig
  config: NotificationConfig
}

export const SaveNotification: React.FC<SaveNotificationProps> = ({ saveConfig, config }) => {
  const styles = useStyles()
  const renderSaveButton = () => {
    if (saveConfig.showButton) {
      return (
        <Tooltip title={saveConfig.isDisabled ? saveConfig.tooltip : ''}>
          <span>
            <Button
              onClick={saveConfig.onClick}
              disabled={saveConfig.isDisabled}
              variant="contained"
              color="primary"
              disableElevation
            >
              Save
            </Button>
          </span>
        </Tooltip>
      )
    }
    return null
  }

  const resolveStyle = (config: NotificationConfig) => {
    return config.isError ? styles.error : styles.info
  }

  const icon = resolveIcon(config)
  const messageStyle = resolveStyle(config)
  return (
    <SectionLayout>
      <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap" spacing={2}>
        <Grid item xs>
          <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
            <Grid item>
              <FontAwesomeIcon size="lg" icon={icon} className={messageStyle} />
            </Grid>
            <Grid item>
              <Typography variant="body1" className={messageStyle}>
                {config.message}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>{renderSaveButton()}</Grid>
      </Grid>
    </SectionLayout>
  )
}
