import { cloneDeep } from 'lodash'
import { Dispatch } from 'react'
import { ProjectValidationError, createProcessIO, replaceSpaceWithUnderscore } from 'src/helpers/shared'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import Project from 'src/models/Project'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import ProjectService from 'src/services/ProjectService'
import SnackbarService, { SNACKBAR_TYPES } from 'src/services/SnackbarService'
import IStoreState from '../IStoreState'
import { createNewProjectEnvironment } from './create-project-data.helpers'
import {
  CreateProjectDataActionTypes,
  CREATE_PROJECT_DATA_SET_PROJECT,
  CREATE_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS,
  CREATE_PROJECT_DATA_SET_PROJECT_FIELD,
  CREATE_PROJECT_DATA_LOADING,
  CREATE_PROJECT_DATA_SUCCESS,
  CREATE_PROJECT_DATA_ERROR,
  CREATE_PROJECT_DATA_RESET,
} from './create-project-data.types'
import SymLink from 'src/models/SymLink'
import { createProjectFormReset } from '../create-project-form/create-project-form.actions'
import { CreateProjectFormActionTypes } from '../create-project-form/create-project-form.types'
import { resolveDatabaseName } from 'src/helpers/project/environment/database/env-database-transformation'
import { preInitializedProjectName } from '../create-project-form/create-project-form.helpers'
import ProjectHelper from 'src/helpers/ProjectHelper'
import PathUtils from 'src/helpers/PathUtils'
import { CreateProjectProcessContext } from 'src/helpers/create-project-workflow-processors/uninitialized-project-processor'
import { transformProjectEnvironmentsForCreateProjectWorkflow } from 'src/helpers/create-project-workflow-processors/uninitialized-project-processor'
import ProjectActions from '../actions/projectActions'

const successMessage = 'The project has been saved.'
const failureMessage = 'Failed to save the project!'

export function createProjectDataReset(): CreateProjectDataActionTypes {
  return {
    type: CREATE_PROJECT_DATA_RESET,
    payload: undefined,
  }
}

export function createProjectDataSetProject(project: Project): CreateProjectDataActionTypes {
  return {
    type: CREATE_PROJECT_DATA_SET_PROJECT,
    payload: project,
  }
}

export function createProjectDataSetProjectField(field: string, value: any): CreateProjectDataActionTypes {
  return {
    type: CREATE_PROJECT_DATA_SET_PROJECT_FIELD,
    payload: { field, value },
  }
}

export function createProjectDataSetEnvironments(environments: ProjectEnvironment[]): CreateProjectDataActionTypes {
  return {
    type: CREATE_PROJECT_DATA_SET_PROJECT_ENVIRONMENTS,
    payload: environments,
  }
}

export function createProjectDataLoading(): CreateProjectDataActionTypes {
  return {
    type: CREATE_PROJECT_DATA_LOADING,
    payload: undefined,
  }
}

export function createProjectDataSuccess(): CreateProjectDataActionTypes {
  return {
    type: CREATE_PROJECT_DATA_SUCCESS,
    payload: undefined,
  }
}

export function createProjectDataError(error: string, message: string): CreateProjectDataActionTypes {
  return {
    type: CREATE_PROJECT_DATA_ERROR,
    payload: { error, errorMessage: message },
  }
}

export function createProjectDataSave(): any {
  return async (dispatch: Dispatch<CreateProjectDataActionTypes | CreateProjectFormActionTypes>, getState: () => IStoreState): Promise<void> => {
    dispatch(createProjectDataLoading())
    try {
      const appState = getState()
      const newProject = appState.createProjectData.project
      await ProjectService.createProject(newProject)
      dispatch(createProjectDataSuccess())
      showSuccessMessage()
      // TODO: What to do after successful save? Navigation? Reset form for now.
      dispatch(createProjectFormReset()) // Reset form data before resetting project data
      dispatch(createProjectDataReset())
      dispatch(ProjectActions.loadProjects())
    } catch (error) {
      dispatch(createProjectDataError(error, failureMessage))
      showFailureMessage()
    }
  }
}

export function createProjectDataReorderProjectEnvironmentsBySelectedEnvironments(): any {
  return async (dispatch: Dispatch<CreateProjectDataActionTypes>, getState: () => IStoreState): Promise<void> => {
    /* Reorder project environments (except Production envs, added at the end) based on selected environments order */
    const appState = getState()
    const project = appState.createProjectData.project
    const prodEnvId = appState.createProjectForm.productionEnvironmentId
    const reorderedProjectEnvironments = appState.createProjectForm.selectedEnvironments.map((config, index) => {
      const projEnv = project.projectEnvironments.find(e => e.environmentId === config.id)
      
      /* return project environment or return a new project enviornment object */
      if (projEnv) {
        projEnv.order = index
        return projEnv
      } 

      return createNewProjectEnvironment(config.id, index)
    })

    /* Find production environment */
    const prodEnv = project.projectEnvironments.find(e => e.environmentId === prodEnvId)

    /* if prod env is found */
    if (prodEnv) {
      prodEnv.order = reorderedProjectEnvironments.length
      reorderedProjectEnvironments.push(prodEnv)
    } else if (prodEnvId) {
      const newProdEnv = createNewProjectEnvironment(prodEnvId, reorderedProjectEnvironments.length)
      reorderedProjectEnvironments.push(newProdEnv)
    } 
    dispatch(createProjectDataSetEnvironments(reorderedProjectEnvironments))
    dispatch(createProjectDataGenerateEnvironmentDefaultData())
  }
}

export function createProjectDataGenerateEnvironmentDefaultData(): any {
  return async (dispatch: Dispatch<CreateProjectDataActionTypes>, getState: () => IStoreState): Promise<void> => {
    const appState = getState()
    const project: Project = cloneDeep(appState.createProjectData.project)
    const projectTypes = appState.projectTypes.data
    const projectTypeConfig = projectTypes.find((pt) => pt.value === project.type)
    const deploymentEnvironments: DeploymentEnvironment[] = cloneDeep(appState.deploymentEnvironments.data)
    const config = {
      code: project.hasCode,
      asset: project.hasAssets,
      database: project.hasDatabases,
      links: project.hasAssets,
    }
    const errors: ProjectValidationError[] = []
    const input = createProcessIO(project, errors)
    const context: CreateProjectProcessContext = {
      config,
      deploymentEnvironments,
      projectTypeConfig,
      project,
    }
    const output = transformProjectEnvironmentsForCreateProjectWorkflow(input, context)
    const projectEnvironmentsWithDefaults = output.value.projectEnvironments
    dispatch(createProjectDataSetEnvironments(projectEnvironmentsWithDefaults))
  }
}

// TODO: Test
export function createProjectDataUpdateProjectCodeRepoByNameInput(): any {
  return async (dispatch: Dispatch<CreateProjectDataActionTypes>, getState: () => IStoreState): Promise<void> => {
    const appState = getState()
    const project = cloneDeep(appState.createProjectData.project)
    const projectName = ProjectHelper.getCleanedProjectName(project.name)
    
    /* Update CodeRepository on Project name change */
    project.codeRepository.name = projectName
    project.codeRepository.group = projectName

    const updatedEnvironments = project.projectEnvironments.map((env) => {
      /* Update Assets on Project name change */
      if (env.projectEnvironmentAssets?.assetGroups?.length > 0) {
        const updatedAssets = env.projectEnvironmentAssets.assetGroups.map((asset) => {
          const newAssetPath = asset.path.replace(preInitializedProjectName, projectName)
          asset.path = newAssetPath
          return asset
        })
        env.projectEnvironmentAssets.assetGroups = updatedAssets
      }
      
      /* Update Symlinks on Project name change */
      if (env.symLinks?.length > 0) {
        const updatedSymlinks = env.symLinks.map((link) => {
          const updatedFrom = link.from.replace(preInitializedProjectName, projectName)
          link.from = updatedFrom
          const updatedTo = link.to.replace(preInitializedProjectName, projectName)
          link.to = updatedTo
          return link
        })
        env.symLinks = updatedSymlinks
      }

      /* Update Webroot on Project name change */
      if (env.projectEnvironmentCode?.webroot) {
        const newWebroot = env.projectEnvironmentCode.webroot.replace(preInitializedProjectName, projectName)
        env.projectEnvironmentCode.webroot = newWebroot
      }

      return env
    })

    project.projectEnvironments = updatedEnvironments

    dispatch(createProjectDataSetProject(project))
  }
}

// TODO: Test
export function createProjectDataUpdateProjectDatabaseNameByNameInput(): any {
  return async (dispatch: Dispatch<CreateProjectDataActionTypes>, getState: () => IStoreState): Promise<void> => {
    const appState = getState()
    const deploymentEnvs = appState.deploymentEnvironments.data
    const project = cloneDeep(appState.createProjectData.project)

    const projectName = ProjectHelper.getCleanedProjectName(project.name)

    const updatedEnvironments = project.projectEnvironments.map((env) => {
      if (env.projectEnvironmentDatabases) {
        const deploymentEnv = deploymentEnvs.find((de) => de.id === env.environmentId)
        const envTitle = replaceSpaceWithUnderscore(deploymentEnv.title)
        const isProd = deploymentEnv.isProd
        const databaseName = resolveDatabaseName(projectName, envTitle, isProd, 0)
        const updatedDatabases = env.projectEnvironmentDatabases?.databases.map((database) => {
          database.name = databaseName
          database.username = databaseName
          return database
        })
        env.projectEnvironmentDatabases.databases = updatedDatabases
      }
      return env
    })
    project.projectEnvironments = updatedEnvironments
    dispatch(createProjectDataSetProject(project))
  }
}

// TODO: test
export function createProjectDataUpdateProjectDataOnWebrootChange(envId: string, oldWebroot: string, newWebroot: string): any {
  return async (dispatch: Dispatch<CreateProjectDataActionTypes>, getState: () => IStoreState): Promise<void> => {
    const appState = getState()
    const updatedProjectEnvironments: ProjectEnvironment[] = [...appState.createProjectData.project.projectEnvironments]
    const updatedEnv: ProjectEnvironment = updatedProjectEnvironments.find(
      (projEnv: ProjectEnvironment) => projEnv.environmentId === envId,
    )
    if (
      updatedEnv &&
      updatedEnv.symLinks &&
      updatedEnv.symLinks.length > 0
    ) {
      updatedEnv.symLinks.forEach((symLink: SymLink) => {
        const newBasePath = PathUtils.getParentDirPath(newWebroot)
        const oldBasePath = PathUtils.getParentDirPath(oldWebroot)
        const updatedSymlinkTo = symLink.to.replace(oldBasePath, newBasePath)
        symLink.to = updatedSymlinkTo
        const updatedSymlinkFrom = symLink.from.replace(oldWebroot, newWebroot)
        symLink.from = updatedSymlinkFrom
      })
      updatedEnv.projectEnvironmentAssets.assetGroups.forEach((asset) => {
        const newBasePath = PathUtils.getParentDirPath(newWebroot)
        const oldBasePath = PathUtils.getParentDirPath(oldWebroot)
        const updatedAssetPath = asset.path.replace(oldBasePath, newBasePath)
        asset.path = updatedAssetPath
      })
      dispatch(createProjectDataSetEnvironments(updatedProjectEnvironments))
    }
  }
}

function showSuccessMessage() {
  SnackbarService.show(SNACKBAR_TYPES.SUCCESS, successMessage)
}

function showFailureMessage() {
  SnackbarService.show(SNACKBAR_TYPES.FAILURE, failureMessage)
}
