import React from 'react'
import {Button, DialogActions, Grid, Tooltip} from '@material-ui/core'

export interface ISaveCancelDialogProps {
  name: string
  buttonCancelLabel?: string
  buttonSaveLabel?: string
  buttonSaveDisabled?: boolean
  buttonSaveTooltip?: string
  handleCancel: (event: any) => void
  handleSave: (event: any) => void
}

export const SaveCancelDialogActions: React.FC<ISaveCancelDialogProps> = ({
       name= '',
       buttonCancelLabel,
       buttonSaveLabel,
       buttonSaveDisabled = false,
       buttonSaveTooltip,
       handleCancel,
       handleSave
     }) => {

  return (
    <DialogActions>
      <Grid container className='actionsContainer' alignItems='center' justify='flex-end' spacing={2}>
        <Grid item>
          <Button id={name + '-da-cancel-button'} onClick={handleCancel} variant='outlined' disableElevation>{buttonCancelLabel}</Button>
        </Grid>
        <Grid item>
          <Tooltip title={buttonSaveTooltip ? buttonSaveTooltip : ''}>
            <span>
              <Button id={name + '-da-save-button'}
                      disabled={buttonSaveDisabled}
                      onClick={handleSave}
                      variant='contained'
                      color='primary'>
                {buttonSaveLabel}
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </DialogActions>
  )
}

