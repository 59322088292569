import {cloneDeep} from 'lodash'
import Project from '../models/Project'
import ProjectEnvironment from '../models/ProjectEnvironment'
import ProjectHelper from './ProjectHelper'
import Database from '../models/Database'
import AssetGroup from '../models/AssetGroup'
import SymLink from '../models/SymLink'
import CodeRepository from '../models/CodeRepository'
import ImportRepository from '../models/ImportRepository'
import PathUtils from './PathUtils'

export default class ProjectCloner {

  static cloneProject(newProject: Project, cloneFromProject: Project): Project {
    const toClone: Project = cloneDeep(cloneFromProject)

    newProject.hasCode = toClone.hasCode
    newProject.hasDatabases = toClone.hasDatabases
    newProject.hasAssets = toClone.hasAssets
    newProject.projectEnvironments = toClone.projectEnvironments.map((projectEnvironment: ProjectEnvironment): ProjectEnvironment => {
      let oldWebroot: string = null
      const webroot: string = ProjectHelper.getDefaultWebrootForEnvironment(newProject.name, projectEnvironment.environmentId).fullWebroot

      if (projectEnvironment.projectEnvironmentCode) {
        oldWebroot = projectEnvironment.projectEnvironmentCode.webroot
        projectEnvironment.projectEnvironmentCode.webroot = webroot
        projectEnvironment.projectEnvironmentCode.currentTag = undefined
        projectEnvironment.projectEnvironmentCode.initialized = false
        projectEnvironment.projectEnvironmentCode.latestDeploymentPaths = []
      }

      if (projectEnvironment.projectEnvironmentDatabases) {
        projectEnvironment.projectEnvironmentDatabases.initialized = false
        projectEnvironment.projectEnvironmentDatabases.databases = projectEnvironment.projectEnvironmentDatabases.databases.map((db: Database): Database => {
          const oldDefaultDbName: string = ProjectHelper.getDefaultDatabaseNameForEnvironment(toClone.name, projectEnvironment.environmentId)
          const newDefaultDbName: string = ProjectHelper.getDefaultDatabaseNameForEnvironment(newProject.name, projectEnvironment.environmentId)
          db.name = db.name.replace(oldDefaultDbName, newDefaultDbName)
          db.username = db.username.replace(oldDefaultDbName, newDefaultDbName)
          db.password = ProjectHelper.getRandomDatabasePassword()
          return db
        })
      }

      if (projectEnvironment.projectEnvironmentAssets) {
        projectEnvironment.projectEnvironmentAssets.initialized = false
        projectEnvironment.projectEnvironmentAssets.assetGroups = projectEnvironment.projectEnvironmentAssets.assetGroups.map((assetGroup: AssetGroup): AssetGroup => {
          const newWebroot: string = projectEnvironment.projectEnvironmentCode ? webroot : null
          let path: string = assetGroup.path
          if (newWebroot && oldWebroot) {
            const oldAssetRootPath: string = PathUtils.getParentDirPath(oldWebroot)
            const newAssetRootPath: string = PathUtils.getParentDirPath(newWebroot)
            path = assetGroup.path.replace(oldAssetRootPath, newAssetRootPath)
          }
          assetGroup.path = path
          return assetGroup
        })
      }

      projectEnvironment.symLinks = projectEnvironment.symLinks.reduce((newLinks: SymLink[], symLink: SymLink) => {
        if (!symLink.assetGroupId) {
          symLink.from = symLink.from.replace(oldWebroot, webroot) // Assumes project env code is not null - most symlink logic does
          newLinks.push(symLink)
        }
        return newLinks
      }, [])

      return projectEnvironment
    })

    if (newProject.hasCode) {
      newProject.codeRepository = new CodeRepository({
        gitHost: toClone.codeRepository.gitHost,
        group: ProjectHelper.getCleanedProjectName(newProject.name),
        name: ProjectHelper.getCleanedProjectName(newProject.name),
        importRepository: new ImportRepository({
          url: `http:${toClone.codeRepository.repoUrl}.git`,
          abBranchToImportBranch: toClone.projectEnvironments.reduce((branchMap: Map<string, string>, projEnv: ProjectEnvironment) => {
            branchMap.set(projEnv.projectEnvironmentCode.branch.name, projEnv.projectEnvironmentCode.branch.name)
            return branchMap
          }, new Map<string, string>()),
          keepHistory: true
        })
      })
    }

    return newProject
  }

}
