import {Action, Dispatch} from 'redux'
import ActionTypes from './index'
import BundleService from '../../services/BundleService'
import Bundle from '../../models/Bundle'

export function loadBundle(): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.BUNDLE_LOAD})
    try {
      const bundle: Bundle = await BundleService.getBundle()
      dispatch({type: ActionTypes.BUNDLE_LOAD_SUCCESS, payload: {bundle}})
    } catch (error) {
      dispatch({type: ActionTypes.BUNDLE_LOAD_FAIL, payload: {error}})
    }
  }
}
