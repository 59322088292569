import {Action, Dispatch} from 'redux'
import ActionTypes from './index'
import PipelineService from '../../services/PipelineService'
import SnackbarService, {SNACKBAR_TYPES} from '../../services/SnackbarService'
import Pipeline from '../../models/pipeline/Pipeline'
import {PipelineTemplateActions} from "./pipelineTemplateActions";

export default class PipelineActions {

  static executePipeline(pipeline: Pipeline): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.EXECUTE_PIPELINE_LOAD})
      try {
        await PipelineService.executePipeline(pipeline)
        dispatch({type: ActionTypes.EXECUTE_PIPELINE_LOAD_SUCCESS})
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, `Pipeline was started`)
      } catch (error) {
        dispatch({type: ActionTypes.EXECUTE_PIPELINE_LOAD_FAIL, payload: {error}})
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, error || 'There was an error running the pipeline')
      }
    }
  }

  static loadLatestPipelinesForPipelineTemplates(projectId: string, pipelineTemplateIds: string[]): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.LATEST_PIPELINES_LOAD})
      try {
        const pipelines: Pipeline[] = await Promise.all(
          pipelineTemplateIds.map((pipelineTemplateId: string) => PipelineService.getMostRecentPipelineFromTemplate(projectId, pipelineTemplateId).catch(() => null))
        )
        dispatch({type: ActionTypes.LATEST_PIPELINES_LOAD_SUCCESS, payload: {pipelines: pipelines.filter((pipeline: Pipeline) => !!pipeline)}})
      } catch (error) {
        dispatch({type: ActionTypes.LATEST_PIPELINES_LOAD_FAIL, payload: {error}})
      }
    }
  }

  static cancelPipeline(pipelineId: string,projectId:string): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.CANCEL_PIPELINE_LOAD})
      try {
        await PipelineService.cancelPipeline(pipelineId)
        dispatch({type: ActionTypes.CANCEL_PIPELINE_LOAD_SUCCESS})
        dispatch(PipelineTemplateActions.loadPipelineTemplates(projectId))

        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Triggered Pipeline Cancellation.')
      } catch (error) {
        dispatch({type: ActionTypes.CANCEL_PIPELINE_LOAD_FAIL, payload: {error}})
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, error || 'Failed to trigger Pipeline Cancellation!')
      }
    }
  }
}
