import React, { useState } from 'react'
import { workflowFormSteps, useStyles } from './create-workflow-workflow.config'
import { FormSteps } from '../shared/form-steps.component'
import { ConnectedWorkflowEnvironmentsForm } from './workflow-environments.component'
import { ConnectedWorkflowDetailsForm } from './workflow-details.component'
import { FORM_STEP_TYPE } from '../forms'

export type WorkflowCreateWorkflowProps = {}

export const WorkflowCreateWorkflow: React.FC<WorkflowCreateWorkflowProps> = ({}) => {
  const styles = useStyles()
  const defaultStep = workflowFormSteps[0].step
  const [activeStep, setActiveStep] = useState<FORM_STEP_TYPE>(defaultStep)
  return (
    <div className={styles.rootContainer}>
      <div className={styles.stepContainer}>
        <FormSteps activeStep={activeStep} formSteps={workflowFormSteps} />
      </div>
      <WorkflowCreateForm activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )
}

export interface WorkflowCreateFormProps {
  activeStep: FORM_STEP_TYPE
  setActiveStep: (step: FORM_STEP_TYPE) => void
}

export const WorkflowCreateForm: React.FC<WorkflowCreateFormProps> = ({ activeStep, setActiveStep }) => {
  switch (activeStep) {
    case FORM_STEP_TYPE.DETAILS:
      return <ConnectedWorkflowDetailsForm setActiveStep={setActiveStep} />
    case FORM_STEP_TYPE.ENV:
      return <ConnectedWorkflowEnvironmentsForm setActiveStep={setActiveStep} />
    default:
      return <ConnectedWorkflowDetailsForm setActiveStep={setActiveStep} />
  }
}
