import {Action, Dispatch} from 'redux'
import ActionTypes from '.'
import DeploymentEnvironment from '../../models/DeploymentEnvironment'
import DeploymentEnvironmentService from '../../services/DeploymentEnvironmentService'
import {setSelectedEnvironment} from './selectedEnvironmentActions'
import SnackbarService, {SNACKBAR_TYPES} from '../../services/SnackbarService'
import IStoreState from '../IStoreState'

export function loadDeploymentEnvironments(): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.DEPLOYMENT_ENVIRONMENTS_LOAD})
    try {
      const deploymentEnvironments: DeploymentEnvironment[] = await DeploymentEnvironmentService.getDeploymentEnvironments()
      const filteredEnvs: DeploymentEnvironment[] = deploymentEnvironments.filter((e) => e.order !== undefined || e.order !== null)
      dispatch({type: ActionTypes.DEPLOYMENT_ENVIRONMENTS_LOAD_SUCCESS, payload: {deploymentEnvironments: filteredEnvs}})
    } catch (error) {
      dispatch({type: ActionTypes.DEPLOYMENT_ENVIRONMENTS_LOAD_FAIL, payload: {error}})
    }
  }
}

export function createDeploymentEnvironment(newEnvironment: DeploymentEnvironment, environmentIdToClone?: string): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.DEPLOYMENT_ENVIRONMENT_SAVE})
    try {
      const savedEnvironment: DeploymentEnvironment = await DeploymentEnvironmentService.createDeploymentEnvironment(newEnvironment, environmentIdToClone)
      dispatch({type: ActionTypes.DEPLOYMENT_ENVIRONMENT_SAVE_SUCCESS, payload: {savedEnvironment}})
      dispatch(loadDeploymentEnvironments())
      dispatch(setSelectedEnvironment(savedEnvironment.id))
    } catch (error) {
      dispatch({type: ActionTypes.DEPLOYMENT_ENVIRONMENT_SAVE_FAIL, payload: {error}})
    }
  }
}

export function deleteDeploymentEnvironmentById(id: string): any {
  return async (dispatch: Dispatch<Action>,  getState: () => IStoreState): Promise<any> => {
    const { data } = getState().deploymentEnvironments
    const newIndex: number = data.findIndex((env:DeploymentEnvironment) => env.id === id) + 1
    dispatch({type: ActionTypes.DEPLOYMENT_ENVIRONMENT_DELETE})
    try {
      dispatch(setSelectedEnvironment(data[newIndex].id))
      await DeploymentEnvironmentService.deleteEnvironment(id)
      dispatch({type: ActionTypes.DEPLOYMENT_ENVIRONMENT_DELETE_SUCCESS})
      dispatch(loadDeploymentEnvironments())
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Successfully deleted Deployment Environment.')
    } catch (error) {
      dispatch({type: ActionTypes.DEPLOYMENT_ENVIRONMENT_DELETE_FAIL, payload: {error}})
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to delete Deployment Environment!')
    }
  }
}

export function updateDeploymentEnvironmentById(id: string, updatedFields: DeploymentEnvironment): any {
  return async (dispatch: Dispatch<Action>): Promise<any> => {
    dispatch({type: ActionTypes.DEPLOYMENT_ENVIRONMENT_UPDATE})
    try {
      const updatedEnvironment: DeploymentEnvironment = await DeploymentEnvironmentService.updateEnvironment(id, updatedFields)
      dispatch({type: ActionTypes.DEPLOYMENT_ENVIRONMENT_UPDATE_SUCCESS, payload: {updatedEnvironment}})
      dispatch(loadDeploymentEnvironments())
    } catch (error) {
      dispatch({type: ActionTypes.DEPLOYMENT_ENVIRONMENT_UPDATE_FAIL, payload: {error}})
    }
  }
}
