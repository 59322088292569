export const SUPPORT_URL = 'https://service.contegix.com/csm'
export const FEEDBACK_URL = 'https://delighted.com/t/XY1ZGjSZ'

export const linkReferences: any = {
  SUPPORTLINK: {
    href: SUPPORT_URL
  },
  FEEDBACKLINK: {
    href: FEEDBACK_URL
  },
}
