import {Dispatch} from 'redux'
import {createMiddleware, MiddlewareFn, registerMiddleware} from '../store/middlewares'
import DeploymentEnvironment, {DEPLOYMENT_ENVIRONMENT_STATUS} from '../models/DeploymentEnvironment'
import IStoreState from '../store/IStoreState'
import ActionTypes from '../store/actions'
import Project from "../models/Project"

class DeploymentEnvironmentHelper {
  static INVALID_TITLE_CHARS_REGEXP: RegExp = /[~`!#$%\^&*+=\-\[\]\\';.,/{}|\\":<>\ \?]/g
  static REPLACEMENT_CHAR: string = '_'
  private static deploymentEnvironments: DeploymentEnvironment[] = []

  static getEnvironmentTitleById(id: string): string {
    const env:DeploymentEnvironment = DeploymentEnvironmentHelper.getDeploymentEnvironmentById(id)
    return env ? env.title : ''
  }

  static handleDeploymentEnvironmentsAction: MiddlewareFn = (action: any, state: IStoreState, next: Dispatch) => {
    if (action.type === ActionTypes.DEPLOYMENT_ENVIRONMENTS_LOAD_SUCCESS) {
      DeploymentEnvironmentHelper.deploymentEnvironments = action.payload.deploymentEnvironments
    }

    return next(action)
  }

  static getDeploymentEnvironments(): DeploymentEnvironment[] {
    return DeploymentEnvironmentHelper.deploymentEnvironments
  }

  static getDeploymentEnvironmentById(id: string): DeploymentEnvironment {
    return DeploymentEnvironmentHelper.deploymentEnvironments.find((env: DeploymentEnvironment) => env.id === id)
  }

  static getDeploymentEnvironmentStatusById(id: string): string {
    return DeploymentEnvironmentHelper.getDeploymentEnvironmentById(id).status
  }

  static isDeploymentEnvironmentActiveById(id: string): boolean {
    return DeploymentEnvironmentHelper.getDeploymentEnvironmentStatusById(id) == DEPLOYMENT_ENVIRONMENT_STATUS.ACTIVE
  }

  static getInactiveEnvironmentTooltipTitle(id: string): string {
    const title = this.getEnvironmentTitleById(id)
    return `Environment "${title}" is not active. Check your deployment environment configuration or contact a Cascade administrator.`
  }

  static validateEnvironmentTitle(title: string): boolean {
    return title && !this.INVALID_TITLE_CHARS_REGEXP.test(title)
  }

  static sanitizeEnvironmentTitle(title: string): string {
    return title.replace(this.INVALID_TITLE_CHARS_REGEXP, this.REPLACEMENT_CHAR)
  }

  static containsNonActiveEnvironments = (project: Project): boolean => {
    let nonActiveEnvironmentFound = false
    let projectEnvironmentIds = project.projectEnvironments ? project.projectEnvironments.map(it => it.environmentId) : []
    for (let value of projectEnvironmentIds.values()) {
      if (!nonActiveEnvironmentFound && !DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(value)) {
        return true
      }
    }

    return false
  }

  static deploymentGroupDeletable(environmentId: string): boolean {
    return this.getDeploymentEnvironmentById(environmentId).deploymentTargetGroups.length > 1
  }
}

registerMiddleware(createMiddleware(DeploymentEnvironmentHelper.handleDeploymentEnvironmentsAction))

export default DeploymentEnvironmentHelper
export const defaultEnvironmentNonActiveMessage = 'At least one environment does not have deployment targets. Please contact your Cascade Administrator.'