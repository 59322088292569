import {assign, cloneDeep} from 'lodash'

export enum LOGIN_AUTH_TYPE  {
  PRIVATE_KEY = 'PRIVATE_KEY',
  PASSWORD = 'PASSWORD',
  STORED = 'STORED',
}

export enum ESCALATION_METHOD {
  SUDO = 'SUDO',
  SU = 'SU',
  NONE = 'NONE',
}

export enum DEPLOYMENT_TARGET_STATUS {
  INCOMPLETE = 'INCOMPLETE',
  PENDING_IP_PORT_VERIFICATION = 'PENDING_IP_PORT_VERIFICATION',
  PENDING_PASSWORD_LOGIN = 'PENDING_PASSWORD_LOGIN',
  PENDING_KEY_GENERATION = 'PENDING_KEY_GENERATION',
  PENDING_KEY_LOGIN = 'PENDING_KEY_LOGIN',
  PENDING_ESCALATION_LOGIN = 'PENDING_ESCALATION_LOGIN',
  PENDING_INSTALL = 'PENDING_INSTALL',
  FAILED_IP_PORT_VERIFICATION = 'FAILED_IP_PORT_VERIFICATION',
  FAILED_IP_UNIQUE_CONSTRAINT = 'FAILED_IP_UNIQUE_CONSTRAINT',
  FAILED_PASSWORD_LOGIN = 'FAILED_PASSWORD_LOGIN',
  FAILED_KEY_GENERATION = 'FAILED_KEY_GENERATION',
  FAILED_KEY_LOGIN = 'FAILED_KEY_LOGIN',
  FAILED_ESCALATION_LOGIN = 'FAILED_ESCALATION_LOGIN',
  FAILED_INSTALL = 'FAILED_INSTALL',
  SUCCESSFUL_IP_PORT_VERIFICATION = 'SUCCESSFUL_IP_PORT_VERIFICATION',
  SUCCESSFUL_PASSWORD_LOGIN = 'SUCCESSFUL_PASSWORD_LOGIN',
  SUCCESSFUL_KEY_GENERATION = 'SUCCESSFUL_KEY_GENERATION',
  SUCCESSFUL_KEY_LOGIN = 'SUCCESSFUL_KEY_LOGIN',
  SUCCESSFUL_ESCALATION_LOGIN = 'SUCCESSFUL_ESCALATION_LOGIN',
  SUCCESSFUL_INSTALL = 'SUCCESSFUL_INSTALL',
  READY = 'READY'
}

export enum DEPLOYMENT_TARGET_ERROR_CODE {
  INVALID_DEPLOYMENT_TARGET = 'INVALID_DEPLOYMENT_TARGET',
  DEPLOYMENT_TARGET_EXISTS = 'DEPLOYMENT_TARGET_EXISTS',
  DEPLOYMENT_TARGET_DOES_NOT_EXIST = 'DEPLOYMENT_TARGET_DOES_NOT_EXIST',
  DUPLICATE_DEPLOYMENT_TARGET_NAME = 'DUPLICATE_DEPLOYMENT_TARGET_NAME'
}

class DeploymentTarget {

  static ESCALATION_PASSWORD_STORED: string = 'ESCALATION_PASSWORD_STORED'
  id: string
  name: string
  fqdn: string
  ipAddress: string
  port: number
  remoteUser: string
  remotePassword: string
  privateKey: string
  escalationMethod: ESCALATION_METHOD
  escalationUser: string
  escalationPassword: string
  loginAuthType: LOGIN_AUTH_TYPE
  status: DEPLOYMENT_TARGET_STATUS

  constructor(props?: Partial<DeploymentTarget>) {
    props = props || {}
    const defaultProps: Partial<DeploymentTarget> = {
      id: undefined,
      name: undefined,
      fqdn: undefined,
      ipAddress: undefined,
      port: undefined,
      remoteUser: undefined,
      remotePassword: undefined,
      privateKey: undefined,
      escalationMethod: undefined,
      escalationUser: undefined,
      escalationPassword: undefined,
      loginAuthType: undefined,
      status: undefined,
    }
    assign(this,defaultProps, props)
    this.setEscalationPasswordToStored()
  }

  setEscalationPasswordToStored(): void {
    if (this.escalationMethod === ESCALATION_METHOD.SU) {
      this.escalationPassword = DeploymentTarget.ESCALATION_PASSWORD_STORED
    }
  }

  clone(): DeploymentTarget {
    return cloneDeep(this)
  }
}

export default DeploymentTarget
