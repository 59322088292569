import { makeStyles, Theme, createStyles } from "@material-ui/core"

const borderRadius = '4px'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectedContainer: {
            border: '1px solid #ece7e7',
            borderRadius,
            minHeight: '340px',
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        unselectedContainer: {
            backgroundColor: '#f8f8f9',
            borderRadius,
            minHeight: '340px',
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        selectedWidth: {
            width:'49%',
            float:'left'
        },
        unselectedWidth: {
            width:'49%',
            marginRight:'2%',
            float:'left',
        },
        labelMargin: {
            marginBottom:'10px'
        }
    }),

)
