import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons'
import {FormLabel, Grid, Card, CardContent, IconButton, Paper, Tooltip} from '@material-ui/core'
import SymLink from '../../../../../models/SymLink'
import {PROJECT_ERROR} from '../../../../../models/Project'
import MessageService from '../../../../../services/MessageService'
import ProjectEnvironment from '../../../../../models/ProjectEnvironment'
import DeploymentEnvironmentHelper from '../../../../../helpers/DeploymentEnvironmentHelper'
import FormField from '../../../../common/FormField'
import {UnderlinedTextField} from '../../shared/underlined-text-input.component'
import {UnderlinedInput} from '../../shared/underlined-form-input.component'

interface IProjectEnvironmentLinksCardProps {
  projectEnvironment: ProjectEnvironment,
  loading: boolean,
  className?: string,
  handleProjectEnvironmentUpdate: (projEnv: ProjectEnvironment) => void,
  error?: PROJECT_ERROR
}

class ProjectEnvironmentLinksCard extends React.Component<IProjectEnvironmentLinksCardProps> {
  handleAddLink = (): void => {
    const updatedProjectEnv: ProjectEnvironment = this.props.projectEnvironment
    if (!updatedProjectEnv.symLinks) {
      updatedProjectEnv.symLinks = []
    }
    updatedProjectEnv.symLinks.push(new SymLink({from: this.props.projectEnvironment.projectEnvironmentCode.webroot + '/', to: ''}))
    this.props.handleProjectEnvironmentUpdate(updatedProjectEnv)
  }

  handleLinkUpdate = (linkIndex: number, event: any): void => {
    let key: string = event.target.name
    let value: string = event.target.value
    if (key === 'linkDir') {
      key = 'from'
      value = `${this.props.projectEnvironment.projectEnvironmentCode.webroot}/${value}`
    }
    const updatedProjectEnv: ProjectEnvironment = this.props.projectEnvironment
    updatedProjectEnv.symLinks.filter((link: SymLink) => !(link.assetGroupId))[linkIndex][key] = value
    this.props.handleProjectEnvironmentUpdate(updatedProjectEnv)
  }

  handleLinkRemoval = (linkIndexToRemove: number): void => {
    const updatedProjectEnv: ProjectEnvironment = this.props.projectEnvironment
    const assetGroupLink = updatedProjectEnv.symLinks? updatedProjectEnv.symLinks.filter((link: SymLink) => (link.assetGroupId)) : []
    const nonAssetGroupLink = updatedProjectEnv.symLinks ? updatedProjectEnv.symLinks.filter((link: SymLink) => !(link.assetGroupId)) : []
    if(updatedProjectEnv.symLinks){
      updatedProjectEnv.symLinks = nonAssetGroupLink
    }
    updatedProjectEnv.symLinks.splice(linkIndexToRemove, 1)
    if(assetGroupLink && assetGroupLink.length > 0){
      const concatenatedSymLinks = updatedProjectEnv.symLinks.concat(assetGroupLink)
      updatedProjectEnv.symLinks = concatenatedSymLinks
    }
    this.props.handleProjectEnvironmentUpdate(updatedProjectEnv)
  }

  get toPathError(): string {
    const {error} = this.props
    switch (error) {
      case PROJECT_ERROR.SYM_LINK_TO_PATH_FOUND_AS_AN_ENV_WEB_ROOT:
        return MessageService.get('project.wizard.error.link.to.matches.webroot')
      case PROJECT_ERROR.SYM_LINK_TO_PATH_FOUND_AS_ASSET_GROUP_PATH:
        return MessageService.get('project.wizard.error.link.to.matches.assetGroup.path')
      case PROJECT_ERROR.SYM_LINK_TO_PATH_FOUND_AS_TO_PATH_IN_OTHER_SYM_LINK:
        return MessageService.get('project.wizard.error.link.to.duplicate')
      default:
        return null
    }
  }

  linkCards = (projectEnvironment: any): JSX.Element[] => {
    const { loading, error } = this.props
    return projectEnvironment.symLinks.filter((link: SymLink) => !(link.assetGroupId)).map((link: SymLink, i: number) => {
      const linkDir: string = link.from.replace(`${projectEnvironment.projectEnvironmentCode.webroot}/`, '')
      const toPathError: string = this.toPathError
      return (
        <Grid item xs={12} key={i} >
          <Grid container alignItems='center' spacing={4}>
            <Grid item xs={11} className='link-card'>
              <Card variant="outlined" elevation={0} className={'project-link-step'}>
                <CardContent>
                  <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={6}>
                      <Grid container alignItems='center'>
                        <Grid item xs={12}>
                          <FormLabel component='legend'>From<FormLabel style={{ color: 'red' ,marginLeft: '5px'}}>*</FormLabel></FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems='center' wrap='nowrap'>
                            <Grid item>
                              <Tooltip title={projectEnvironment.projectEnvironmentCode.webroot} placement='bottom' enterDelay={50}>
                                <b className='helpText'>&lt;WEBROOT&gt;</b>
                              </Tooltip>
                            </Grid>/
                            <Grid item xs>
                              <UnderlinedInput>
                                <UnderlinedTextField 
                                  name='linkDir'
                                  disabled={loading}
                                  value={linkDir}
                                  onChange={this.handleLinkUpdate.bind(this, i)} />
                              </UnderlinedInput>
                            </Grid>
                          </Grid>
                        </Grid>
                        {error === PROJECT_ERROR.SYM_LINK_FROM_PATH_FOUND_AS_FROM_PATH_IN_OTHER_SYM_LINK &&
                          <Grid item xs={12}><FormField error={true} message={MessageService.get('project.wizard.error.link.from.duplicate')}><span/></FormField></Grid>
                        }
                      </Grid>
                    </Grid>
                    <Grid item><FontAwesomeIcon icon={faArrowRight} size='sm'/></Grid>
                    <Grid item xs={5}>
                      <UnderlinedInput label='To' error={!!toPathError} message={toPathError} >

                        <UnderlinedTextField
                          name='to'
                          value={link.to}
                          disabled={loading}
                          onChange={this.handleLinkUpdate.bind(this, i)} />

                      </UnderlinedInput>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <IconButton className='remove-link-btn' color='primary' onClick={this.handleLinkRemoval.bind(this, i)}>
                <FontAwesomeIcon icon={faTimes} size='sm'/>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }

  render(): React.ReactNode {
    const { projectEnvironment, className } = this.props
    return (
      <Paper className={'project-environment-card MuiPaper-root1 project-card ' + className}>
        <Grid container spacing={2} justify='center'>
          <Grid item xs={12}>
            <strong>{DeploymentEnvironmentHelper.getEnvironmentTitleById(projectEnvironment.environmentId)}</strong>
          </Grid>
          { projectEnvironment.symLinks && projectEnvironment.symLinks.filter((link: SymLink) => !(link.assetGroupId)).length === 0 &&
            <Grid item xs={12}><Grid container justify='center'>This environment is not using any links.</Grid></Grid>
          }
          { projectEnvironment.symLinks && projectEnvironment.symLinks.filter((link: SymLink) => !(link.assetGroupId)).length > 0 && this.linkCards(projectEnvironment) }
          <Grid item xs={12}>
            <Grid container justify='flex-end' style={{paddingRight: '0.2rem'}}>
              <IconButton
                className='add-link-btn'
                color='primary'
                disabled={!projectEnvironment?.projectEnvironmentCode?.webroot}
                onClick={this.handleAddLink}>
                <FontAwesomeIcon icon={faPlus} size='sm'/>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default ProjectEnvironmentLinksCard
