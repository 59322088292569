import React, {ReactNode} from 'react'
import {Dialog, DialogContent, DialogTitle, Grid, IconButton} from '@material-ui/core'
import './GenericDialog.scss'
import LoadingOverlay from './LoadingOverlay'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

interface IGenericDialogProps {
  actions: React.ReactNode,
  disableBackdropClick?: boolean,
  fullWidth: boolean
  icon?: ReactNode
  loading?: boolean
  maxWidth?: any,
  onClose: (event?: any) => void,
  open?: boolean,
  scrollType?: any,
  style?: object,
  title: string,
  showClose?:boolean
}

interface IGenericDialogState {
  deleteUser: boolean
}

class GenericDialog extends React.Component
  <IGenericDialogProps, IGenericDialogState>  {

  static defaultProps: any = {
    open: true,
    style: {},
    scrollType: 'body',
    maxWidth: 'md',
    disableBackdropClick: false,
    fullWidth: true,
    loading: false,
    showClose:false
  }

  constructor(props: IGenericDialogProps) {
    super(props)
  }

  render(): React.ReactNode {
    const dialogChildren: React.ReactNode = (

      <div className='dialog loading-overlay-parent'>
        {this.props.loading &&
        <LoadingOverlay/>
        }
        <DialogTitle>
          <Grid container justify={'space-between'}>
            <Grid item>
              {this.props.title}
            </Grid>
            <Grid item>
              {this.props.icon}
            </Grid>
            {this.props.showClose &&<IconButton className='remove-link-btn' color='primary' style={{float:'right',padding:'7px',color:'#54646c'}} onClick={this.props.onClose}>
              <FontAwesomeIcon icon={faTimes} size='sm'/>
            </IconButton>}
          </Grid>

        </DialogTitle>
        <DialogContent>
          <div style={this.props.style}>
            {this.props.children}
          </div>
        </DialogContent>
        {this.props.actions}
      </div>
    )
    let dialogComponent: any
    this.props.disableBackdropClick
      ? dialogComponent = <Dialog open={this.props.open} onClose={this.props.onClose} scroll={this.props.scrollType}
        fullWidth={this.props.fullWidth} maxWidth={this.props.maxWidth} disableBackdropClick>{dialogChildren}</Dialog>
      : dialogComponent = <Dialog open={this.props.open} onClose={this.props.onClose} scroll={this.props.scrollType}
        fullWidth={this.props.fullWidth} maxWidth={this.props.maxWidth}>{dialogChildren}</Dialog>
    return dialogComponent
  }
}

export default GenericDialog
