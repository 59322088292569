import * as React from 'react'
import CreatableSelect from 'react-select/creatable'
import './ComboBox.scss'
import {FormLabel, Grid} from '@material-ui/core'
import Select from 'react-select'
import {ActionMeta} from 'react-select'
import theme from '../../theme'

export class ComboBoxEntry {
  label: string
  value: string

  constructor(label: string, value?: string) {
    this.label = label
    this.value = !!value ? value : label
  }
}

const customStyles: any = {
  option: (provided: any, {isFocused, isSelected}: any): any => ({
    ...provided,
    backgroundColor: isSelected ? theme.palette.primary.main : isFocused ? theme.palette.grey['200'] : null
  }),
  menuPortal: (provided: any): any => ({...provided, zIndex: 1600}),
  valueContainer: (provided: any): any => ({...provided, paddingLeft: 0}),
  indicatorSeparator: (): any => ({display: 'none'})
}

export interface IComboBoxProps {
  handleSelect: (selectedEntry: ComboBoxEntry) => void
  creatable?: boolean
  clearable?: boolean
  handleCreate?: (value: string) => void
  suggestions?: ComboBoxEntry[]
  label?: string
  placeholder?: string,
  overrideValue?: ComboBoxEntry,
  disabled?: boolean
}

class ComboBox extends React.Component<IComboBoxProps> {
  static defaultProps: IComboBoxProps = {
    handleSelect: null,
    handleCreate: null,
    creatable: false,
    clearable: false,
    suggestions: [],
    label: null,
    placeholder: 'Select an option',
    overrideValue: undefined,
    disabled: false
  }

  onChange = (entry: ComboBoxEntry, actionMeta: ActionMeta<any>): void => {
    if (actionMeta.action === 'select-option') {
      this.props.handleSelect(entry)
    } else if (actionMeta.action === 'create-option') {
      this.props.handleCreate(entry.value)
    }
  }

  render(): React.ReactNode {
    const {suggestions, placeholder, label, creatable, clearable, overrideValue, disabled} = this.props

    return (
      <Grid container>
        {label && <Grid item xs={12}><FormLabel className='form-label' component='legend'>{label}</FormLabel></Grid>}
        <Grid item xs={12}>

          {creatable &&
          <CreatableSelect isSearchable
                           value={overrideValue}
                           className='combo-box'
                           isDisabled={disabled}
                           isClearable={clearable}
                           options={suggestions}
                           onChange={this.onChange}
                           // @ts-ignore
                           createOptionPosition={'last'}
                           placeholder={placeholder}
                           styles={customStyles}
                           menuPortalTarget={document.body}/>
          }

          {!creatable &&
          <Select isSearchable
                  className='combo-box'
                  onChange={this.onChange}
                  isClearable={clearable}
                  options={suggestions}
                  isDisabled={disabled}
                  value={overrideValue}
                  placeholder={placeholder}
                  styles={customStyles}
                  menuPortalTarget={document.body}/>
          }
        </Grid>
      </Grid>
    )
  }
}

export default ComboBox

