import {Action, Dispatch} from 'redux'
import ActionTypes from './index'
import PipelineService from '../../services/PipelineService'
import {PipelineTemplate} from '../../models/pipeline/PipelineTemplate'
import SnackbarService, {SNACKBAR_TYPES} from '../../services/SnackbarService'

export class PipelineTemplateActions {
  static loadPipelineTemplates(projectId: string): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.PIPELINE_TEMPLATES_LOAD})
      try {
        const pipelineTemplates: PipelineTemplate[] = await PipelineService.getProjectPipelineTemplates(projectId)
        dispatch({type: ActionTypes.PIPELINE_TEMPLATES_LOAD_SUCCESS, payload: {pipelineTemplates}})
      } catch (error) {
        dispatch({type: ActionTypes.PIPELINE_TEMPLATES_LOAD_FAIL, payload: {error}})
      }
    }
  }

  static savePipelineTemplate(pipelineTemplate: PipelineTemplate): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.PIPELINE_TEMPLATE_SAVE})
      try {
        await PipelineService.putProjectPipelineTemplate(pipelineTemplate)
        dispatch({type: ActionTypes.PIPELINE_TEMPLATE_SAVE_SUCCESS})
        dispatch(this.loadPipelineTemplates(pipelineTemplate.projectId))
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Pipeline Saved')
      } catch (error) {
        dispatch({type: ActionTypes.PIPELINE_TEMPLATE_SAVE_FAIL})
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to save Pipeline')
      }
    }
  }

  static createPipelineTemplate(pipelineTemplate: PipelineTemplate): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.PIPELINE_TEMPLATE_SAVE})
      try {
        await PipelineService.createProjectPipelineTemplate(pipelineTemplate)
        dispatch({type: ActionTypes.PIPELINE_TEMPLATE_SAVE_SUCCESS})
        dispatch(this.loadPipelineTemplates(pipelineTemplate.projectId))
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Pipeline Created')
      } catch (error) {
        dispatch({type: ActionTypes.PIPELINE_TEMPLATE_SAVE_FAIL})
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to create Pipeline')
      }
    }
  }

  static deletePipelineTemplate(pipelineTemplate: PipelineTemplate): any {
    return async (dispatch: Dispatch<Action>): Promise<any> => {
      dispatch({type: ActionTypes.PIPELINE_TEMPLATE_DELETE})
      try {
        await PipelineService.deleteProjectPipelineTemplate(pipelineTemplate.projectId, pipelineTemplate.id)
        dispatch({type: ActionTypes.PIPELINE_TEMPLATE_DELETE_SUCCESS})
        dispatch(PipelineTemplateActions.loadPipelineTemplates(pipelineTemplate.projectId))
        SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Pipeline deleted')
      } catch (error) {
        dispatch({type: ActionTypes.PIPELINE_TEMPLATE_DELETE_FAIL})
        SnackbarService.show(SNACKBAR_TYPES.FAILURE, 'Failed to delete Pipeline')
      }
    }
  }
}
