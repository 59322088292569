import {assign} from 'lodash'

class FTACVar {
  key: string
  value: string

  constructor(props?: Partial<FTACVar>) {
    props = props || {}


    const defaultProps: Partial<FTACVar> = {
      key: undefined,
      value: undefined
    }
    assign(this, defaultProps, props)
  }
}
export default FTACVar
