import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { CUSTOM } from 'src/theme/lib/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeSectionText: {
      color: theme.palette.common.white,
    },
    inactiveSectionText: {
      color: CUSTOM.breadcrumbFadedBlue,
    },
    linkText: {
      color: theme.palette.common.white,
      textDecoration: 'underline',
    },
    linkIcon: {
      color: theme.palette.common.white,
    },
  }),
)
