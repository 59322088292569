import React from 'react'
import Moment from 'react-moment'
import {Grid} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock, faExclamationCircle, faUser} from '@fortawesome/free-solid-svg-icons'
import Nbsp from 'src/components/common/Nbsp'
import Pipeline from 'src/models/pipeline/Pipeline'
import './StatusCard.scss'

export interface IFailureStatusCardProps {
  pipeline: Pipeline,
  actionType: string,
  actionIcon: any
}

export interface IFailureStatusCardState {

}

class FailureStatusCard extends React.Component<IFailureStatusCardProps, IFailureStatusCardState> {
  render(): React.ReactNode {
    const {pipeline, actionIcon, actionType} = this.props

    return (
      <Grid container className='full-height'>
        <Grid item sm={12}>
          <h5>Failure</h5>
        </Grid>
        <Grid item sm={12} className='statusCardBody failure'>
          <Grid container alignItems='center' justify='center'>
            <Grid item sm={10}>
              <Grid container direction='column' spacing={2}>
                <Grid item className='actionType'>
                  <FontAwesomeIcon icon={actionIcon}/> {actionType.toLowerCase()}
                </Grid>
                <Grid item className='actionDetails'>
                  {pipeline.startTime &&
                  <span>
                    <FontAwesomeIcon icon={faClock} className='xsIcon' size='xs'/><Nbsp/>
                    <Moment format='M/D/YY h:mma' date={pipeline.startTime}/>
                  </span>
                  }
                  <br/>
                  <FontAwesomeIcon icon={faUser} className='xsIcon' size='xs'/> {pipeline.createdBy}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2}>
              <FontAwesomeIcon icon={faExclamationCircle} size='2x'/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default FailureStatusCard
