import React from 'react'
import StatusCard from '../status/StatusCard'
import MergeAssetsButton from './MergeAssetsButton'
import {Card, CardActions, CardContent, CardHeader, Grid} from '@material-ui/core'
import Project from 'src/models/Project'
import '../DeploymentTrack.scss'
import STATUS from 'src/models/pipeline/Status'
import ProjectEvent from 'src/models/pipeline/ProjectEvent'
import CancelButton from '../CancelButton'
import {ACTION_TYPE} from 'src/models/pipeline/Action'
import DeploymentEnvironmentHelper from "../../../../../helpers/DeploymentEnvironmentHelper";

export interface IEnvironmentAssetsSectionProps {
  latestEvent?: ProjectEvent,
  handleMergeAssetsClick: () => void,
  environmentId: string,
  project: Project,
  onCancelPipeline: (pipelineId: string, actionType: ACTION_TYPE) => void
}

export interface IEnvironmentAssetsSectionState {

}

class EnvironmentAssetsSection extends React.Component<IEnvironmentAssetsSectionProps, IEnvironmentAssetsSectionState> {
  onCancelPipeline = (): void => {
    const {latestEvent, onCancelPipeline} = this.props

    onCancelPipeline(latestEvent.pipeline.id, latestEvent.action.actionConfiguration.actionType)
  }

  render(): React.ReactNode {
    const {project, environmentId, latestEvent, handleMergeAssetsClick} = this.props
    const {CANCELLING, PENDING, STARTED} = STATUS

    const pipelineActive: boolean = !!latestEvent && [PENDING, STARTED].includes(latestEvent.pipeline.status)
    const pipelineCancelling: boolean = !!latestEvent && latestEvent.pipeline.status === CANCELLING

    const showAssetMerge: boolean = !pipelineActive && !pipelineCancelling
    const showCancel: boolean = pipelineActive

    const initializationRequired: boolean = !project.environmentInitializedForAssets(environmentId)
    const height = '180px'

    const envIsDisabled = !DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(environmentId)
    return (
      <Card className='full-height deployment-sub-section' elevation={2}>
        <CardHeader className='deploymentTrackSectionTitle' title='Assets'/>
        <CardContent style={{ minHeight: height, maxHeight: height, height: height }}>
          {latestEvent &&
          <StatusCard projectEvent={latestEvent}/>
          }
          {!latestEvent &&
          <Grid container justify='center' alignItems='center'>
            There are no recent actions
          </Grid>
          }
        </CardContent>
        <CardActions>
          <Grid container spacing={2} justify='flex-end' style={{padding: '8px'}}>
            {showAssetMerge &&
            <Grid item>
              <MergeAssetsButton project={project}
                                 environmentId={environmentId}
                                 handleMergeAssetsClick={handleMergeAssetsClick}
                                 initializationRequired={initializationRequired}
                                 disableButtons={envIsDisabled} />
            </Grid>
            }
            {showCancel &&
            <Grid item>
              <CancelButton handleCancelClick={this.onCancelPipeline}
                            environmentId={environmentId}
                            project={project}
                            latestEvent={latestEvent}/>
            </Grid>
            }
          </Grid>
        </CardActions>
      </Card>
    )
  }
}

export default EnvironmentAssetsSection
