import * as React from 'react'
import Project, {PROJECT_STATUS} from '../../models/Project'
import './ProjectStatusIcon.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Tooltip} from '@material-ui/core'
import {faCircle, faCloudUploadAlt, faDownload, faExclamationTriangle,} from '@fortawesome/free-solid-svg-icons'
import MessageService from '../../services/MessageService'
import {IMPORT_REPOSITORY_STATUS} from '../../models/ImportRepository'

export interface IProjectStatusIconProps {
  project: Project
  tooltipPosition?: 'bottom' | 'left' | 'right' | 'top' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end'
  overrideTooltip?: string
}

export class ProjectStatusIcon extends React.Component<IProjectStatusIconProps> {
  render(): React.ReactNode {
    const {project, overrideTooltip, tooltipPosition} = this.props
    let icon: any
    let tooltip: string
    let style: string

    if (project.disabled) {
      return null
    } else if (project.status === PROJECT_STATUS.ACTIVE) {
      icon = faCircle
      tooltip = MessageService.get('project.status.icon.active')
      style = 'project-status-icon-active'
    } else if (project.status === PROJECT_STATUS.AWAITING_INITIAL_DEPLOYMENT) {
      icon = faCloudUploadAlt
      tooltip = MessageService.get('project.status.icon.awaiting.initial.deployment')
      style = 'project-status-icon-awaiting'
    } else if (project.status === PROJECT_STATUS.INCOMPLETE) {
      icon = faExclamationTriangle
      tooltip = MessageService.get('project.status.icon.incomplete')
      style = 'project-status-icon-incomplete'
    } else if (project.status === PROJECT_STATUS.AWAITING_REPO_INITIALIZATION && project.codeRepository && project.codeRepository.importRepository) {
      if (project.codeRepository.importRepository.status !== IMPORT_REPOSITORY_STATUS.FAILURE) {
        icon = faDownload
        tooltip = MessageService.get('project.status.icon.importing')
        style = 'project-status-icon-importing'
      } else {
        icon = faDownload
        tooltip = MessageService.get('project.status.icon.import.error')
        style = 'project-status-icon-failure'
      }
    }

    return (
      <Tooltip placement={tooltipPosition || 'top'} title={overrideTooltip || tooltip} enterDelay={50}>
        <div><FontAwesomeIcon icon={icon} className={style}/></div>
      </Tooltip>
    )
  }
}

export default ProjectStatusIcon
