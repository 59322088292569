import {ACTION_TYPE} from '../models/pipeline/Action'
import Project from '../models/Project'

export class PermissionHelper {
  static hasPermissionForAction(actionType: ACTION_TYPE, project: Project, environmentId: string): boolean {
    switch (actionType) {
      case ACTION_TYPE.ASSET_MERGE:
        return project.canMergeAssetsFrom(environmentId) || project.canMergeAssetsTo(environmentId)
      case ACTION_TYPE.CODE_DEPLOY:
        return project.canDeployCode(environmentId)
      case ACTION_TYPE.CODE_PROMOTE:
      case ACTION_TYPE.CODE_MERGE:
        return project.canMergeCodeFrom(environmentId) || project.canMergeCodeTo(environmentId)
      case ACTION_TYPE.DATABASE_SNAPSHOT:
        return project.canTakeDatabaseSnapshot(environmentId)
      case ACTION_TYPE.DATABASE_COPY:
        return project.canCopyDatabaseFrom(environmentId) || project.canCopyDatabaseTo(environmentId)
      default:
        return false // NOTE: Only supporting actions that explicitly have a UI for the user. Add other conditions as necessary
    }
  }
}
