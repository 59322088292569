export enum ProjectValidationError {
  DEFAULT = 'DEFAULT',
  UNDEFINED_FUNC_INPUT = 'UNDEFINED_FUNC_INPUT',
}

export type DataProcessorError = ProjectValidationError

export interface DataProcessIO<T, E> {
  value: T
  errors: E[]
}

export type DataProcessor<T, C> = (input: T, context: C) => T

export interface DataProcessorConfig {
  collectErrors: boolean
}

export const buildProcess = <T, E, C>(config: DataProcessorConfig, pipes?: DataProcessor<DataProcessIO<T, E>, C>[]) => {
  return (input: DataProcessIO<T, E>, context: C) => {
    let processorIO: DataProcessIO<T, E> = input
    for (let func of pipes) {
      const result = func(processorIO, context)
      if (config.collectErrors) {
        processorIO = {
          value: result.value,
          errors: [...processorIO.errors, ...result.errors],
        }
      } else {
        processorIO = result
        if (result.errors.length > 0) break
      }
    }
    return processorIO
  }
}

export function createProcessIO<T, E>(value: T, errors: E[]): DataProcessIO<T, E> {
  return { value, errors }
}
