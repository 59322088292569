import { assign } from 'lodash'
import {WEB_SERVER} from './WEB_SERVER'
import Branch from './Branch'

export default class ProjectEnvironmentCode {
  branch: Branch
  currentTag: string
  deploymentTargetGroupId: string
  webroot: string
  webServer: WEB_SERVER
  initialized: boolean
  latestDeploymentPaths: string[]
  latestFailedDeploymentPaths: string[]
  deploymentsToKeep: number
  failedDeploymentsToKeep: number

  constructor(props?: Partial<ProjectEnvironmentCode>) {
    props = props || {}
    const defaultProps: Partial<ProjectEnvironmentCode> = {
      branch: undefined,
      currentTag: undefined,
      deploymentTargetGroupId: undefined,
      webroot: undefined,
      webServer: undefined,
      initialized: undefined,
      latestDeploymentPaths: undefined,
      latestFailedDeploymentPaths: undefined,
      deploymentsToKeep: undefined,
      failedDeploymentsToKeep: undefined
    }

    assign(this, defaultProps, props)
  }
}
