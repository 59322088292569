export default class TimerHelper {
  static getTime(timeInSeconds: number): string {
    const hours: number = Math.floor(timeInSeconds / 3600)
    timeInSeconds = timeInSeconds - hours * 3600
    const minutes: number = Math.floor(timeInSeconds / 60)
    const seconds: number = timeInSeconds - minutes * 60
    const hourString: string = Math.round(hours).toString().padStart(2, '0')
    const minuteString: string = Math.round(minutes).toString().padStart(2, '0')
    const secondString: string = Math.round(seconds).toString().padStart(2, '0')
    return `${hourString}:${minuteString}:${secondString}`
  }
}
