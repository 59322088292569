import {Type} from 'class-transformer'
import 'reflect-metadata'
import AssetGroup from './AssetGroup'
import { assign } from 'lodash'

export default class ProjectEnvironmentAssets {
  deploymentTargetGroupId: string
  @Type(() => AssetGroup)
  assetGroups: AssetGroup[]
  initialized: boolean

  constructor(props?: Partial<ProjectEnvironmentAssets>) {
    props = props || {}
    const defaultProps: Partial<ProjectEnvironmentAssets> = {
      deploymentTargetGroupId: undefined,
      assetGroups: undefined,
      initialized: undefined
    }
    assign(this, defaultProps, props)
  }
}
