import React from 'react'
import {TableCell, TableRow} from '@material-ui/core'
import {ActionConfiguration} from 'src/models/pipeline/ActionConfiguration'
import ActionConfigurationActionsMenu from './ActionConfigurationActionsMenu'
import Moment from 'react-moment'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import IStoreState from 'src/store/IStoreState'
import {IPipelineTemplateSectionProps} from '../pipelineTemplates/PipelineTemplateSection'
import {connect} from 'react-redux'
import {formatName} from './ActionConfigurationTableRow.config'
import {ACTION_TYPE} from '../../../../../../models/pipeline/Action';
import './ActionConfigurationTableRow.scss'

export interface IActionConfigurationTableRowProps {
  item: ActionConfiguration,
  handleClick: any
  handleRowActions: any
  environments?: DeploymentEnvironment[]
}

export class ActionConfigurationTableRow extends React.Component<IActionConfigurationTableRowProps> {
  handleRowClick = (): void => {
    this.props.handleClick(this.props.item)
  }


  handleRowDeleteClick = (): void => {
    this.props.handleRowActions.delete(this.props.item)
  }

  render(): React.ReactNode {
    const actionConfiguration: ActionConfiguration = this.props.item
    const deploymentEnvironment: DeploymentEnvironment = this.props.environments.find((env: DeploymentEnvironment) => env.id === actionConfiguration.targetEnvironment)
    const sourceEnvironment: DeploymentEnvironment = this.props.environments.find((env: DeploymentEnvironment) => env.id === actionConfiguration.sourceEnvironment)
    const actionConfigurationName: string = formatName(actionConfiguration.name, actionConfiguration.actionType, 20)
    return (
      <TableRow>
        { actionConfiguration?.actionType && actionConfiguration?.actionType === ACTION_TYPE.FILE_TEMPLATE &&
          <TableCell padding='default' data-title={actionConfiguration.name} className='tooltip actionRow'>{actionConfigurationName}</TableCell>}
        { actionConfiguration?.actionType && actionConfiguration?.actionType !== ACTION_TYPE.FILE_TEMPLATE &&
          <TableCell padding='default' >{actionConfigurationName}</TableCell>}
        <TableCell padding='default'>{actionConfiguration.actionType}</TableCell>
        <TableCell padding='default'>{sourceEnvironment && sourceEnvironment.title}</TableCell>
        <TableCell padding='default'>{deploymentEnvironment && deploymentEnvironment.title}</TableCell>
        <TableCell padding='default'>{actionConfiguration.updatedBy}</TableCell>
        <TableCell padding='default'><Moment format='M/D/YY h:mma' date={actionConfiguration.lastUpdated}/></TableCell>
        <TableCell padding='none'>
          <ActionConfigurationActionsMenu actionConfiguration={this.props.item} handleEditClick={this.handleRowClick}
                                          handleDeleteClick={this.handleRowDeleteClick}/>
        </TableCell>
      </TableRow>
    )
  }
}

const mapStateToProps: any = (state: IStoreState, ownProps: IPipelineTemplateSectionProps): any => ({
  environments: state.deploymentEnvironments.data
})
export default connect(mapStateToProps)(ActionConfigurationTableRow)
