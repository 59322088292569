import React from 'react'
import {DialogActions, Grid, Input, Switch} from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import FormField from '../../common/FormField'
import GenericDialog from '../../common/GenericDialog'
import DeploymentTargetGroup, {
  DEPLOYMENT_TARGET_GROUP_ERROR,
  DEPLOYMENT_TARGET_GROUP_TYPE
} from '../../../models/DeploymentTargetGroup'
import MessageService from '../../../services/MessageService'
import Select from 'react-select'

interface ICreateDeploymentTargetGroupDialogProps {
  onClose: ()=>void
  handleCancel: ()=>void
  handleNewGroup: (group: DeploymentTargetGroup, all: boolean)=>void
  loading: boolean
  error: DEPLOYMENT_TARGET_GROUP_ERROR
}

interface ICreateDeploymentTargetGroupDialogState {
  addToAllEnvs: boolean
  newGroup: DeploymentTargetGroup
}

class CreateDeploymentTargetGroupDialog extends React.Component<ICreateDeploymentTargetGroupDialogProps, ICreateDeploymentTargetGroupDialogState> {
  constructor(props: ICreateDeploymentTargetGroupDialogProps) {
    super(props)
    this.state = {
      addToAllEnvs: true,
      newGroup: new DeploymentTargetGroup()
    }
  }

  toggleAddToAllEnvs = (): void => {
    this.setState({addToAllEnvs: !this.state.addToAllEnvs})
  }

  handleInputChange = (event: any): void => {
    const {newGroup} = this.state
    newGroup.name = event.target.value
    this.setState({newGroup})
  }

  handleTypeChange = (event: any): void => {
    const {newGroup} = this.state
    newGroup.type = event.value
    this.setState({newGroup})
  }

  handleNewGroup = (event: any): void => {
    this.props.handleNewGroup(this.state.newGroup, this.state.addToAllEnvs)
    this.props.onClose()
  }

  get canContinue(): boolean {
    return !!this.state.newGroup.name && !!this.state.newGroup.type
  }

  render(): React.ReactNode {
    const {addToAllEnvs, newGroup} = this.state
    const {loading, handleCancel, error} = this.props
    const deploymentTargetGroupTypes = [
      { value: 'CODE', label:  DEPLOYMENT_TARGET_GROUP_TYPE.CODE},
      { value: 'ASSET', label:  DEPLOYMENT_TARGET_GROUP_TYPE.ASSET },
      { value: 'DATABASE', label:  DEPLOYMENT_TARGET_GROUP_TYPE.DATABASE}
    ]

    return (
      <GenericDialog open title='Create New Deployment Target Group' onClose={this.props.onClose} actions={
        <DialogActions>
          <Grid container spacing={2} className='actionsContainer' justify='flex-end'>

            <Grid container direction='column' spacing={4}>
              <Grid item>
                <FormField label='Deployment Target Group Name' error={error === DEPLOYMENT_TARGET_GROUP_ERROR.DEPLOYMENT_TARGET_GROUP_DUPLICATE_NAME}
                           message={error === DEPLOYMENT_TARGET_GROUP_ERROR.DEPLOYMENT_TARGET_GROUP_DUPLICATE_NAME
                           && MessageService.get('admin.deploymenttargetgroup.error.name.duplicate')}>
                  <Input name='name'
                         disabled={loading}
                         value={newGroup.name}
                         onChange={this.handleInputChange}
                         autoFocus={true}
                  />
                </FormField>
              </Grid>

              <Grid item>
                <Select isSearchable={true}
                        className='CreateDeploymentTargetGroupDialog'
                        options={deploymentTargetGroupTypes}
                        onChange={this.handleTypeChange}
                        placeholder={'Select Type'}
                        autoFocus={false}
                />
              </Grid>

              <Grid item>
                <Switch checked={addToAllEnvs} onChange={this.toggleAddToAllEnvs}/>Add to all environments
              </Grid>
            </Grid>


            <Grid item>
              <Button onClick={handleCancel} variant='contained' color='secondary' disableElevation>Cancel</Button>
            </Grid>
            <Grid item>
              <Button onClick={this.handleNewGroup} variant='contained' color='primary' disabled={!this.canContinue}>Create</Button>
            </Grid>
          </Grid>
        </DialogActions>
      }>
      </GenericDialog>
    )
  }
}

export default CreateDeploymentTargetGroupDialog
