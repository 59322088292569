import 'reflect-metadata'
import {Type} from 'class-transformer'
import {assign} from 'lodash'
import {ActionConfiguration} from './ActionConfiguration'
import {ACTION_TYPE} from './Action'

export class FileTemplateActionConfiguration extends ActionConfiguration {
  templatePath: string
  @Type(() => Map)
  templateVariables: Map<string, string>
  hasUserEditor: true

  constructor(props?: Partial<FileTemplateActionConfiguration>) {
    super(props)
    props = props || {}

    const defaultProps: Partial<FileTemplateActionConfiguration> = {
      templatePath: undefined,
      templateVariables: new Map(),
      actionType: ACTION_TYPE.FILE_TEMPLATE,
      hasUserEditor: true
    }
    assign(this, defaultProps, props)
  }

  hasFullyPopulatedMapFields(): boolean {
    let hasPopulatedMapFields: boolean = this.templateVariables.size > 0
    if (hasPopulatedMapFields) {
      this.templateVariables.forEach((value: string, key: string) => {
        if (!value || !key) {
          hasPopulatedMapFields = false
          return
        }
      })
    }
    return hasPopulatedMapFields
  }

  // TODO: Need to test. Including creating a spec test
  isValid(): boolean {
    return this.templatePath && this.templatePath.startsWith('/') && this.hasFullyPopulatedMapFields()
  }
}
