import {CUSTOM} from "../../../../../theme/lib/color";

export const defaultEnvironmentNonActiveMessage = 'At least one environment does not have deployment targets. Please contact your Cascade Administrator.'

export interface ButtonConfig {
  showTooltip: boolean
  showButton: boolean
  isDisabled: boolean
  tooltip: string
  onClick: (e: any) => void
  text: string
  style: any
}

export interface NotificationConfig {
  message: string
  isShown: boolean
  isError: boolean
}

export const useStyles = {
  resetButton: {
    backgroundColor: CUSTOM.blue[900],
    textTransform: 'none',
  },
  saveButton: {
    textTransform: 'none',
    style: {
      marginLeft: '10px'
    }
  },
  userTitleBar: {
    component: {
      marginTop: '10px',
      marginBottom: '20px'
    },
    title: {
      icon: {
        color: '#54646c',
        display: 'inline'
      },
      text: {
        display: 'inline',
        marginLeft: '7px'
      }
    },
    buttonGroup: {
      marginLeft: '12px'
    }
  }
}
