import React, { useState } from 'react'
import { drupal9FormSteps, useStyles } from './create-drupal9-workflow.config'
import { FormSteps } from '../shared/form-steps.component'
import {ConnectedDrupal9EnvironmentsForm} from './drupal9-environments.component'
import { ConnectedDrupal9DetailsForm } from './drupal9-details.component'
import { FORM_STEP_TYPE } from '../forms'
import { ConnectedCreateProjectCodeForm } from '../forms/code-form.component'

export type Drupal9CreateWorkflowProps = {}

export const Drupal9CreateWorkflow: React.FC<Drupal9CreateWorkflowProps> = ({}) => {
  const styles = useStyles()
  const defaultStep = drupal9FormSteps[0].step
  const [activeStep, setActiveStep] = useState<FORM_STEP_TYPE>(defaultStep)
  return (
    <div className={styles.rootContainer}>
      <div className={styles.stepContainer}>
        <FormSteps activeStep={activeStep} formSteps={drupal9FormSteps} />
      </div>
      <Drupal9CreateForm activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )
}

export interface Drupal9CreateFormProps {
  activeStep: FORM_STEP_TYPE
  setActiveStep: (step: FORM_STEP_TYPE) => void
}

export const Drupal9CreateForm: React.FC<Drupal9CreateFormProps> = ({ activeStep, setActiveStep }) => {
  switch (activeStep) {
    case FORM_STEP_TYPE.DETAILS:
      return <ConnectedDrupal9DetailsForm setActiveStep={setActiveStep} />
    case FORM_STEP_TYPE.ENV:
      return <ConnectedDrupal9EnvironmentsForm setActiveStep={setActiveStep} />
    case FORM_STEP_TYPE.CODE:
      return <ConnectedCreateProjectCodeForm />
    default:
      return <ConnectedDrupal9DetailsForm setActiveStep={setActiveStep} />
  }
}
