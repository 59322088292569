import React from 'react'
import Moment from 'react-moment'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlay, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {IconButton, TableCell, TableRow, Tooltip} from '@material-ui/core'
import PipelineTemplateActionsMenu from './PipelineTemplateActionsMenu'
import PipelineTemplateEvent from './PipelineTemplateEvent'
import LatestPipelineIcon from './latestPipeline/LatestPipelineIcon'
import LatestPipelineRunInfo from './latestPipeline/LatestPipelineRunInfo'
import User from 'src/models/User'
import Pipeline from 'src/models/pipeline/Pipeline'
import STATUS from 'src/models/pipeline/Status'
import './PipelineTemplateEventTableRow.scss'
import DeploymentEnvironmentHelper from "../../../../../../helpers/DeploymentEnvironmentHelper";

export interface IPipelineTemplateTableRowProps {
  item: PipelineTemplateEvent,
  key: number,
  handleClick: any,
  handleEdit: any,
  currentUser: User,
  onRunPipeline?: (pipeline: Pipeline) => void,
  onCancelPipeline?: (pipelineId: string) => void
}

export class PipelineTemplateEventTableRow extends React.Component<IPipelineTemplateTableRowProps> {
  onRunPipeline = (): void => {
    const {item: {pipelineTemplate}, onRunPipeline} = this.props

    onRunPipeline(pipelineTemplate.toPipeline())
  }

  onCancelPipeline = (): void => {
    const {item: {latestPipeline}, onCancelPipeline} = this.props

    onCancelPipeline(latestPipeline.id)
  }

  getRunnablePipelineTooltip = (): string => {
    const {item: {pipelineTemplate}} = this.props
    const invalidActionConfigurations = pipelineTemplate.actionConfigurations.filter((ac) => {
      if (ac.sourceEnvironment) {
        return !DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(ac.sourceEnvironment)
      }
      if (ac.targetEnvironment) {
        return !DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(ac.targetEnvironment)
      }
      return true
    })
    const pipelineTemplateContainsInactiveEnvironment: boolean = invalidActionConfigurations.length > 0
    if (pipelineTemplateContainsInactiveEnvironment) {
      return 'Unable to Run Pipeline: Contains Actions with invalid Environments. Please update your Actions, resolve the invalid Environments, or contact your Cascade Administrator for assistance.'
    }
    return 'Run Pipeline'
  }

  render(): React.ReactNode {
    const {item: {pipelineTemplate, latestPipeline}, currentUser} = this.props

    const {PENDING, STARTED, CANCELLING} = STATUS

    const isRunning: boolean = !!latestPipeline && (latestPipeline.status === PENDING || latestPipeline.status === STARTED)
    const isRunnable: boolean = !latestPipeline || (!!latestPipeline && !isRunning && latestPipeline.status !== CANCELLING)

    let acs = pipelineTemplate.actionConfigurations.filter((ac) => {
      if (ac.sourceEnvironment) {
        return !DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(ac.sourceEnvironment)
      }
      if (ac.targetEnvironment) {
        return !DeploymentEnvironmentHelper.isDeploymentEnvironmentActiveById(ac.targetEnvironment)
      }
      return true
    })
    const pipelineTemplateContainsInactiveEnvironment: boolean = acs.length > 0
    const classes: string = classNames('pipeline-template-event-row', {'running': isRunning})

    return (
      <TableRow className={classes} hover>
        <TableCell padding='default' className='last-run-icon'><LatestPipelineIcon latestPipeline={latestPipeline}/></TableCell>
        <TableCell padding='default'>{pipelineTemplate.name}</TableCell>
        <TableCell padding='default'>{pipelineTemplate.updatedBy}</TableCell>
        <TableCell padding='default'><Moment format='M/D/YY h:mma' date={pipelineTemplate.lastUpdated}/></TableCell>
        <TableCell padding='default'><LatestPipelineRunInfo latestPipeline={latestPipeline}/></TableCell>
        <TableCell padding='none'>
          {
            isRunning &&
            <Tooltip placement='top' title='Cancel Pipeline' enterDelay={50}>
              <IconButton onClick={this.onCancelPipeline}>
                <FontAwesomeIcon icon={faTimesCircle} className='cancel-pipeline' size='xs'/>
              </IconButton>
            </Tooltip>
          }
          {
            isRunnable &&
            <Tooltip placement='top' title={this.getRunnablePipelineTooltip()} enterDelay={50}>
              <span>
                <IconButton onClick={this.onRunPipeline} disabled={pipelineTemplateContainsInactiveEnvironment}>
                  <FontAwesomeIcon icon={faPlay} size='xs'/>
                </IconButton>
              </span>
            </Tooltip>
          }
        </TableCell>
        {
          currentUser.canAdministerPipelinesForProject(pipelineTemplate.projectId) &&
          <TableCell padding='none'>
            <PipelineTemplateActionsMenu pipelineTemplate={pipelineTemplate}/>
          </TableCell>
        }
      </TableRow>
    )
  }
}

export default PipelineTemplateEventTableRow
