import React from 'react'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCodeBranch, faLock } from '@fortawesome/free-solid-svg-icons'
import {
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import FormField from 'src/components/common/FormField'
import DeploymentEnvironmentHelper from 'src/helpers/DeploymentEnvironmentHelper'
import CodeRepository from 'src/models/CodeRepository'
import DeploymentTargetGroup from 'src/models/DeploymentTargetGroup'
import Project, { PROJECT_ERROR } from 'src/models/Project'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import ProjectEnvironmentCodeWebroot from 'src/models/ProjectEnvironmentCodeWebroot'
import MessageService from 'src/services/MessageService'
import { CODE_REPOSITORY_IMPORT_REPOSITORY_FIELD, PROJECT_CODE_REPOSITORY_FIELD, PROJECT_ENVIRONMENT_CODE_FIELD, PROJECT_ENVIRONMENT_CODE_WEBROOT_FIELD } from './project-field-names'
import { DANGER, PRIMARY } from 'src/theme/lib/color'

const PROTECTED_BRANCH_MESSAGE: string =
  'This branch will be protected to prevent pushes and deletes outside of the workflow, except by project owners.'

export interface IProjectEnvironmentCodeCardProps {
  project?: Project
  error?: PROJECT_ERROR
  loading?: boolean
  projectEnvironment: ProjectEnvironment
  webroot: ProjectEnvironmentCodeWebroot
  isDev: boolean
  disableImportFields: boolean
  importError: boolean
  handleInputChange?: (event: any) => void
  setProjectField?: (key: string, value: any) => void
  setProjectEnvironments?: (environments: ProjectEnvironment[]) => void
  onWebrootChanged?: (envId: string, oldWebroot: string, newWebroot: string) => void
}

export class ProjectEnvironmentCodeCard extends React.Component<IProjectEnvironmentCodeCardProps> {
  handleProjectEnvironmentCodeUpdate = (id: string, event: any) => {
    const { project } = this.props
    const updatedProjectEnvironments: ProjectEnvironment[] = [...this.props.project.projectEnvironments]
    const projectEnvironmentToUpdate: ProjectEnvironment = updatedProjectEnvironments.find(
      (projEnv: ProjectEnvironment) => projEnv.environmentId === id,
    )
    if (event.target.name === 'branch') {
      if (get(project, `${PROJECT_CODE_REPOSITORY_FIELD}.${CODE_REPOSITORY_IMPORT_REPOSITORY_FIELD}`)) {
        this.updateImportBranchMapping(
          projectEnvironmentToUpdate.projectEnvironmentCode.branch.name,
          event.target.value,
        )
      }
      projectEnvironmentToUpdate.projectEnvironmentCode.branch.name = event.target.value
    } else {
      projectEnvironmentToUpdate.projectEnvironmentCode[event.target.name] = event.target.value
      
    }
    this.props.setProjectEnvironments(updatedProjectEnvironments)
  }

  updateImportBranchMapping = (oldAbBranchName: string, newAbBranchName: string) => {
    const { project } = this.props
    const updatedCodeRepository: CodeRepository = project.codeRepository
    updatedCodeRepository.importRepository.abBranchToImportBranch.set(
      newAbBranchName,
      project.codeRepository.importRepository.abBranchToImportBranch.get(oldAbBranchName),
    )
    const environmentsUsingOldBranchName: ProjectEnvironment[] = this.props.project.projectEnvironments.filter(
      (projEnv: ProjectEnvironment) => get(projEnv, `${PROJECT_ENVIRONMENT_CODE_FIELD}.branch.name`) === oldAbBranchName,
    )
    if (!environmentsUsingOldBranchName || environmentsUsingOldBranchName.length === 1) {
      // since project environments haven't been updated yet, the env being updated still uses the oldBranchName (thus the length === 1)
      updatedCodeRepository.importRepository.abBranchToImportBranch.delete(oldAbBranchName)
    }
    this.updateCodeRepository(updatedCodeRepository)
  }

  getErrorMessage = (error: PROJECT_ERROR): string => {
    switch (error) {
      case PROJECT_ERROR.INVALID_BRANCH_NAME:
        return MessageService.get('project.wizard.error.code.branch.invalid')
      case PROJECT_ERROR.DUPLICATE_BRANCH_NAME:
        return MessageService.get('project.wizard.error.code.branch.duplicate')
      case PROJECT_ERROR.INVALID_WEB_ROOT:
        return MessageService.get('project.wizard.error.code.webroot.invalid')
      case PROJECT_ERROR.INVALID_CONSECUTIVE_BRANCH:
        return MessageService.get('project.wizard.error.code.branch.invalid.consecutive')
      default:
        return null
    }
  }

  handleWebrootUpdate = (id: string, event: any) => {
    const { projectEnvironment, webroot, onWebrootChanged } = this.props
    const oldWebroot: string = webroot.fullWebroot
    webroot[event.target.name] = event.target.value
    const newWebroot: string = webroot.fullWebroot
    this.handleProjectEnvironmentCodeUpdate(id, { target: { name: PROJECT_ENVIRONMENT_CODE_WEBROOT_FIELD, value: newWebroot } })
    onWebrootChanged(projectEnvironment.environmentId, oldWebroot, newWebroot)
  }

  handleAbBranchToImportBranchUpdate = (event: any) => {
    const updatedCodeRepository: CodeRepository = this.props.project.codeRepository
    updatedCodeRepository.importRepository.abBranchToImportBranch.set(event.target.name, event.target.value)
    this.updateCodeRepository(updatedCodeRepository)
  }

  updateCodeRepository = (codeRepository: CodeRepository) => {
    this.props.setProjectField(PROJECT_CODE_REPOSITORY_FIELD, codeRepository)
  }

  render() {
    const { loading, projectEnvironment, webroot, project, error, disableImportFields, importError } = this.props
    const importRepository = project?.codeRepository?.importRepository
    const showImport: boolean = importRepository && !disableImportFields
    const isGuarded = projectEnvironment.projectEnvironmentCode.branch.guarded
    const outlineColor = importError ? DANGER[500] : PRIMARY[500]
    const tagInfoStyles = {border: `2px solid ${outlineColor}`, borderRadius: '4px'}
    return (
      <Paper className="project-environment-card">
        <Grid container spacing={2} alignItems="center" alignContent="center" justify="center">
          <Grid item xs={12} md={12}>
            <h5>{DeploymentEnvironmentHelper.getEnvironmentTitleById(projectEnvironment.environmentId)}</h5>
          </Grid>
          {showImport && (
            <Grid
              item
              xs={12}
              md={5}
              style={tagInfoStyles}
            >
              <FormField label="Import Branch/Tag">
                <TextField
                  name={projectEnvironment.projectEnvironmentCode.branch.name}
                  disabled={loading}
                  variant="outlined"
                  style={{ marginTop: '50px', marginBottom: '10px', backgroundColor: 'white' }}
                  value={
                    importRepository.abBranchToImportBranch.get(
                      projectEnvironment.projectEnvironmentCode.branch.name,
                    ) || ''
                  }
                  onChange={this.handleAbBranchToImportBranchUpdate}
                />
              </FormField>
            </Grid>
          )}
          {showImport && (
            <Grid item>
              <FontAwesomeIcon icon={faArrowRight} />
            </Grid>
          )}
          <Grid item xs={12} md>
            <FormField
              label="Branch Name"
              error={error === PROJECT_ERROR.INVALID_BRANCH_NAME}
              message={error === PROJECT_ERROR.INVALID_BRANCH_NAME && this.getErrorMessage(error)}
            >
              <TextField
                name="branch"
                disabled={loading}
                value={projectEnvironment.projectEnvironmentCode.branch.name}
                variant="outlined"
                style={{ marginTop: '50px', marginBottom: '10px', backgroundColor: 'white' }}
                onChange={this.handleProjectEnvironmentCodeUpdate.bind(this, projectEnvironment.environmentId)}
                InputProps={{
                  endAdornment: isGuarded && (
                    <InputAdornment position="end" className="adornment">
                      <Tooltip title={PROTECTED_BRANCH_MESSAGE} placement="top" enterDelay={50}>
                        <div>
                          <FontAwesomeIcon icon={faLock} size="sm" />
                        </div>
                      </Tooltip>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start" className="adornment">
                      <FontAwesomeIcon icon={faCodeBranch} />
                    </InputAdornment>
                  ),
                }}
              />
            </FormField>
          </Grid>
          <Grid container item xs={12} md={showImport ? 12 : 6}>
            <Grid item style={{ marginTop: '15px' }} xs={12}>
              <FormLabel>Deployment Group</FormLabel>
            </Grid>
            <Grid item style={{ marginTop: '6px' }} xs={12}>
              <Select
                variant="outlined"
                disabled={loading}
                style={{ backgroundColor: 'white', width: '100%' }}
                value={projectEnvironment.projectEnvironmentCode.deploymentTargetGroupId}
                onChange={this.handleProjectEnvironmentCodeUpdate.bind(this, projectEnvironment.environmentId)}
                inputProps={{ name: 'deploymentTargetGroupId' }}
              >
                {DeploymentEnvironmentHelper.getDeploymentEnvironmentById(
                  projectEnvironment.environmentId,
                ).deploymentTargetGroups.map((group: DeploymentTargetGroup) => {
                  return (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
              <FormLabel component="legend">Webroot</FormLabel>
            </Grid>
            <Typography style={{ marginLeft: '4px', marginRight: '4px', marginTop: '10px' }} variant="h5">
              /{' '}
            </Typography>
            <TextField
              name="dir1"
              disabled={loading}
              variant="standard"
              style={{
                marginLeft: '4px',
                marginTop: '7px',
                marginBottom: '20px',
                width: '70px',
                backgroundColor: '#FFFFFF',
              }}
              value={webroot.dir1}
              onChange={this.handleWebrootUpdate.bind(this, projectEnvironment.environmentId)}
            />
            <Typography style={{ marginLeft: '4px', marginRight: '4px', marginTop: '10px' }} variant="h5">
              /{' '}
            </Typography>
            <Typography style={{ marginTop: '7px' }} variant="h6">
              {webroot.cascade}/{webroot.env}
            </Typography>
            <Typography style={{ marginLeft: '4px', marginRight: '4px', marginTop: '10px' }} variant="h5">
              /{' '}
            </Typography>
            <TextField
              variant="standard"
              style={{ marginTop: '7px', marginBottom: '20px', width: '70px', backgroundColor: '#FFFFFF' }}
              name="site"
              disabled={loading}
              value={webroot.site}
              onChange={this.handleWebrootUpdate.bind(this, projectEnvironment.environmentId)}
            />
            <Typography style={{ marginLeft: '4px', marginRight: '4px', marginTop: '10px' }} variant="h5">
              /
            </Typography>
            <TextField
              variant="standard"
              style={{ marginTop: '7px', marginBottom: '20px', width: '70px', backgroundColor: '#FFFFFF' }}
              name="dir2"
              disabled={loading}
              value={webroot.dir2}
              onChange={this.handleWebrootUpdate.bind(this, projectEnvironment.environmentId)}
            />
            {error === PROJECT_ERROR.INVALID_WEB_ROOT && (
              <FormField error={true} message={this.getErrorMessage(error)}>
                <span />
              </FormField>
            )}
          </Grid>
        </Grid>
      </Paper>
    )
  }
}
