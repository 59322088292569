import axios from 'axios'
import CodeRepository from "../models/CodeRepository";


class GitService {

  static TASK_GIT_API_ROOT: string = '/api/git/v1/repositories'
  static TASK_GIT_VISIBILITY_ENDPOINT = '/ispublic'

  async isRepositoryPublic(repository: CodeRepository): Promise<Boolean> {
    const res: any = await axios.post(`${GitService.TASK_GIT_API_ROOT}${(GitService.TASK_GIT_VISIBILITY_ENDPOINT)}`, repository)
    return res.data
  }

}

export default new GitService()
