import CodeRepository from 'src/models/CodeRepository'
import Project, { PROJECT_TYPE } from 'src/models/Project'
import ProjectEnvironment from 'src/models/ProjectEnvironment'

export const preInitializedProjectName = 'default-project-name'

export function createDrupal9Project(gitHost: string, projectEnvironments: ProjectEnvironment[]): Project {
  const codeRepository = new CodeRepository({
    name: '',
    group: '',
    gitHost,
  })
  return new Project({
    name: '',
    domain: '',
    hasCode: true,
    hasAssets: true,
    hasDatabases: true,
    type: PROJECT_TYPE.DRUPAL_9,
    codeRepository,
    projectEnvironments,
  })
}

export function createDrupal8Project(gitHost: string, projectEnvironments: ProjectEnvironment[]): Project {
  const codeRepository = new CodeRepository({
    name: '',
    group: '',
    gitHost,
  })
  return new Project({
    name: '',
    domain: '',
    hasCode: true,
    hasAssets: true,
    hasDatabases: true,
    type: PROJECT_TYPE.DRUPAL_8,
    codeRepository,
    projectEnvironments,
  })
}

export function createDrupal7Project(gitHost: string, projectEnvironments: ProjectEnvironment[]): Project {
  const codeRepository = new CodeRepository({
    name: '',
    group: '',
    gitHost,
  })
  return new Project({
    name: '',
    domain: '',
    hasCode: true,
    hasAssets: true,
    hasDatabases: true,
    type: PROJECT_TYPE.DRUPAL_7,
    codeRepository,
    projectEnvironments,
  })
}

export function createWordpressProject(gitHost: string, projectEnvironments: ProjectEnvironment[]): Project {
  const codeRepository = new CodeRepository({
    name: '',
    group: '',
    gitHost,
  })
  return new Project({
    name: '',
    domain: '',
    hasCode: true,
    hasAssets: true,
    hasDatabases: true,
    type: PROJECT_TYPE.WORDPRESS,
    codeRepository,
    projectEnvironments,
  })
}

export function createOtherProject(gitHost: string, projectEnvironments: ProjectEnvironment[]): Project {
  const codeRepository = new CodeRepository({
    name: '',
    group: '',
    gitHost,
  })
  return new Project({
    name: '',
    domain: '',
    hasCode: true,
    hasAssets: false,
    hasDatabases: false,
    type: PROJECT_TYPE.OTHER,
    codeRepository,
    projectEnvironments,
  })
}

export function createWorkflowProject(gitHost: string, projectEnvironments: ProjectEnvironment[]): Project {

  return new Project({
    name: '',
    domain: '',
    hasCode: false,
    hasAssets: false,
    hasDatabases: false,
    type: PROJECT_TYPE.WORKFLOW,
    projectEnvironments,
  })
}

export function createNewProjectEnvironment(environmentId: string, order: number): ProjectEnvironment {
  return new ProjectEnvironment({
    environmentId,
    order,
  })
}
