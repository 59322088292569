import {ActionConfiguration} from './ActionConfiguration'
import {assign} from 'lodash'
import {ACTION_TYPE} from './Action'

export enum SERVICE_ACTION {
  START = 'START',
  STOP = 'STOP',
  RESTART = 'RESTART',
  RELOAD = 'RELOAD'
}

export class ServiceActionConfiguration extends ActionConfiguration {
  serviceName: string
  serviceAction: SERVICE_ACTION

  constructor(props?: Partial<ServiceActionConfiguration>) {
    super(props)
    props = props || {}

    const defaultProps: Partial<ServiceActionConfiguration> = {
      serviceName: null,
      serviceAction: null,
      actionType: ACTION_TYPE.SERVICE,
      hasUserEditor: true
    }
    assign(this, defaultProps, props)
  }

  isValid(): boolean {
    return !!this.serviceName && !!this.serviceAction
  }
}
