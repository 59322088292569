import {Type} from 'class-transformer'
import 'reflect-metadata'
import Database from './Database'
import { assign } from 'lodash'

export default class ProjectEnvironmentDatabases {
  deploymentTargetGroupId: string
  @Type(() => Database)
  databases: Database[]
  initialized: boolean

  constructor(props?: Partial<ProjectEnvironmentDatabases>) {
    props = props || {}
    const defaultProps: Partial<ProjectEnvironmentDatabases> = {
      deploymentTargetGroupId: undefined,
      databases: undefined,
      initialized: undefined
    }
    assign(this, defaultProps, props)
  }
}
