import React from 'react'
import { faMinusCircle, faGripVertical, faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@material-ui/core'
import { CUSTOM } from 'src/theme/lib/color'
import { PipelineActionConfig } from './PipelineActionList.config'
import { useStyles } from './action-item.config'
import { Draggable } from 'react-beautiful-dnd'
import {PipelineTemplate} from "../../../../../../models/pipeline/PipelineTemplate";
import PathUtils from "../../../../../../helpers/PathUtils";

type ComponentProps = {
    position: number
    isSelected:boolean
    config: PipelineActionConfig
    pipelineTemplate: PipelineTemplate
    actionLength: number
    remove: (config: PipelineActionConfig,pipelineTemplate: PipelineTemplate) => void
}

export type StepsItemProps = ComponentProps

export const StepsItem: React.FC<StepsItemProps> = ({ config, position, isSelected, pipelineTemplate, remove, actionLength }) => {
    const styles = useStyles()
    const deselectEnvironment = () => {
        remove(config, pipelineTemplate)
    }
    const renderRemoveIcon = () => {
        if (actionLength > 1) {
            return (
                <Grid item className={styles.iconWidth}>
                    <div onClick={deselectEnvironment}>
                        <FontAwesomeIcon icon={faMinusCircle} size="lg" color={CUSTOM.blue[600]}/>
                    </div>
                </Grid>
            )
        }
        return null
    }
    return (
        actionLength > 1 || !isSelected ? <Draggable draggableId={config.id} index={position}>
            {(provided) => {
                return (
                    <div
                        className={styles.container}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                    >
                        <Grid container alignItems="center" alignContent="center" spacing={2} wrap="nowrap" >
                            <Grid item className={styles.iconWidth}>
                                <FontAwesomeIcon icon={isSelected ?faArrowsAltV:faGripVertical} size="lg" color={CUSTOM.blue[600]}/>
                            </Grid>
                            <Grid item className={styles.textWidth}>
                                <Typography variant="body1" className={styles.titleText}>
                                    {PathUtils.wrapDirPath(config.title, 20)}
                                </Typography>
                            </Grid>
                            {config.isSelectedAction ? renderRemoveIcon() : ""}
                        </Grid>
                    </div>
                )
            }}
        </Draggable> : <div
            className={styles.container}
        >
            <Grid container alignItems="center" alignContent="center" spacing={2} wrap="nowrap">
                <Grid item>
                    <FontAwesomeIcon icon={isSelected ?faArrowsAltV:faGripVertical} size="lg" color={CUSTOM.blue[600]}/>
                </Grid>
                <Grid item>
                    <Typography variant="body1" className={styles.titleText}>
                        {PathUtils.wrapDirPath(config.title, 20)}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

