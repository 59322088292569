import {PipelineTemplate} from 'src/models/pipeline/PipelineTemplate'
import Pipeline from 'src/models/pipeline/Pipeline'
import {assign} from 'lodash'

export default class PipelineTemplateEvent {
  pipelineTemplate: PipelineTemplate
  latestPipeline: Pipeline

  constructor(props?: Partial<PipelineTemplateEvent>) {
    props = props || {}

    assign(this, props)
  }
}
