import React from 'react'

import GenericModal from 'src/components/common/GenericModal'
import DeploymentTargetGroupService from 'src/services/DeploymentTargetGroupService'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import DeploymentTarget from 'src/models/DeploymentTarget'
import DeploymentTargetGroup from 'src/models/DeploymentTargetGroup'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import DeploymentEnvironmentHelper from 'src/helpers/DeploymentEnvironmentHelper'
import { CUSTOM } from 'src/theme/lib/color'
import { IconButton} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

interface IPreviewModalState {
  deploymentTargets: DeploymentTarget[]
}

interface IPreviewModalProps {
  environment: ProjectEnvironment,
  projectUrl: string,
  handleModalClose: () => void,
  openModal: boolean,
}


class PreviewModal extends React.Component<IPreviewModalProps, IPreviewModalState> {
  constructor(props: IPreviewModalProps) {
    super(props)
    this.state = {
      deploymentTargets: []
    }
  }

  componentWillMount = (): void => {
    this.loadDeploymentTargetGroup()
  }

  loadDeploymentTargetGroup = async (): Promise<void> => {
    const {environment} = this.props
    const groupId: string = environment.projectEnvironmentCode.deploymentTargetGroupId
    const currentTargetGroup: DeploymentTargetGroup = await DeploymentTargetGroupService.getDeploymentTargetGroup(environment.environmentId, groupId)
    this.setState({deploymentTargets: currentTargetGroup.deploymentTargets})
  }

  getDomainUrlsForTargets = (projectEnvironment: ProjectEnvironment, projectUrl: string, deploymentTargets: DeploymentTarget[]): string[] => {
    const deploymentEnvironment: DeploymentEnvironment = DeploymentEnvironmentHelper.getDeploymentEnvironmentById(projectEnvironment.environmentId)
    const urlRoot: string = `http://${deploymentEnvironment.deploymentTitle}.${projectUrl}`
    return deploymentTargets.map((target: DeploymentTarget) => `${urlRoot}.${target.fqdn}`)
  }

  render = (): React.ReactNode => {
    const {environment, projectUrl, openModal, handleModalClose} = this.props
    const {deploymentTargets} = this.state

    if (!!environment) {
      return (
        <GenericModal open={openModal} onClose={handleModalClose}>
          <div>
            <IconButton className='remove-link-btn' color='primary' style={{float:'right',padding:'7px'}} onClick={handleModalClose}>
              <FontAwesomeIcon icon={faTimes} size='sm'/>
            </IconButton>
            <h2 style={{width:'80%'}}>Preview</h2>
            <h4>Deployment Targets</h4>
            {this.getDomainUrlsForTargets(environment, projectUrl, deploymentTargets).map((url: string, index: number) => {
              const displayText: string = `Deployment Target ${index + 1} (${deploymentTargets[index].name})`
              return (
                <div key={index}>
                  {
                    projectUrl ?
                      <a href={url} style={{ color: CUSTOM.blue[600] }} target='_blank'><p>{displayText}</p></a>
                      :
                      displayText
                  }
                </div>
              )
            })}
          </div>

        </GenericModal>
      )
    } else {
      return null
    }
  }
}

export default PreviewModal
