import Action from './Action'
import Pipeline from './Pipeline'

export class ProjectEvent {
  action: Action
  pipeline: Pipeline

  constructor(action: Action, pipeline: Pipeline) {
    this.action = action
    this.pipeline = pipeline
  }
}

export default ProjectEvent
