import {ActionConfiguration} from './ActionConfiguration'
import 'reflect-metadata'
import {assign} from 'lodash'
import {Type} from 'class-transformer'
import AssetMergeActionConfiguration from './AssetMergeActionConfiguration'
import CodeDeployActionConfiguration from './CodeDeployActionConfiguration'
import CodeMergeActionConfiguration from './CodeMergeActionConfiguration'
import CodePromoteActionConfiguration from './CodePromoteActionConfiguration'
import DatabaseCopyActionConfiguration from './DatabaseCopyActionConfiguration'
import DatabaseSnapshotActionConfiguration from './DatabaseSnapshotActionConfiguration'
import Action, {ACTION_TYPE} from './Action'
import {FileTemplateActionConfiguration} from './FileTemplateActionConfiguration'
import {ScriptActionConfiguration} from './ScriptActionConfiguration'
import {ServiceActionConfiguration} from './ServiceActionConfiguration'
import PipelineTriggerGroup from './PipelineTriggerGroup'
import Pipeline from './Pipeline'

type ActionConfigurationType = AssetMergeActionConfiguration | CodeDeployActionConfiguration | CodeMergeActionConfiguration |
  CodePromoteActionConfiguration | DatabaseCopyActionConfiguration | DatabaseSnapshotActionConfiguration | FileTemplateActionConfiguration |
  ScriptActionConfiguration | ServiceActionConfiguration

export class PipelineTemplate {
  id: string
  name: string
  updatedBy: string
  projectId: string
  @Type(() => Date)
  lastUpdated: Date
  systemCanModify: boolean
  @Type(() => ActionConfiguration, {
    discriminator: {
      property: 'actionType',
      subTypes: [
        {value: AssetMergeActionConfiguration, name: ACTION_TYPE.ASSET_MERGE},
        {value: CodeDeployActionConfiguration, name: ACTION_TYPE.CODE_DEPLOY},
        {value: CodeMergeActionConfiguration, name: ACTION_TYPE.CODE_MERGE},
        {value: CodePromoteActionConfiguration, name: ACTION_TYPE.CODE_PROMOTE},
        {value: DatabaseCopyActionConfiguration, name: ACTION_TYPE.DATABASE_COPY},
        {value: DatabaseSnapshotActionConfiguration, name: ACTION_TYPE.DATABASE_SNAPSHOT},
        {value: FileTemplateActionConfiguration, name: ACTION_TYPE.FILE_TEMPLATE},
        {value: ScriptActionConfiguration, name: ACTION_TYPE.SCRIPT},
        {value: ServiceActionConfiguration, name: ACTION_TYPE.SERVICE}
      ]
    }
  })
  actionConfigurations: ActionConfigurationType[]
  pipelineTriggerGroups: PipelineTriggerGroup[]
  schedule: string

  constructor(props?: Partial<PipelineTemplate>) {
    props = props || {}

    const defaultProps: Partial<PipelineTemplate> = {
      name: undefined,
      updatedBy: undefined,
      actionConfigurations: [],
      pipelineTriggerGroups: [],
      schedule: undefined
    }
    assign(this, defaultProps, props)
  }

  toPipeline(): Pipeline {
    return new Pipeline({
      projectId: this.projectId,
      actions: this.actionConfigurations.map((config: ActionConfigurationType) => new Action({actionConfiguration: config})),
      pipelineTemplateId: this.id
    })
  }
}



