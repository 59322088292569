import React from 'react'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import SnackbarService, {SNACKBAR_TYPES} from 'src/services/SnackbarService'
import GenericModal from 'src/components/common/GenericModal'
import DeploymentEnvironmentHelper from 'src/helpers/DeploymentEnvironmentHelper'
import Database from 'src/models/Database'
import ProjectEnvironment from 'src/models/ProjectEnvironment'
import Project from 'src/models/Project'
import ConfirmDialog from 'src/components/common/ConfirmDialog'
import MessageService from 'src/services/MessageService'
import DeploymentEnvironment from 'src/models/DeploymentEnvironment'
import Pipeline from 'src/models/pipeline/Pipeline'
import DatabaseCopyActionConfiguration from 'src/models/pipeline/DatabaseCopyActionConfiguration'
import Action from 'src/models/pipeline/Action'
import PipelineService from 'src/services/PipelineService'

export interface ICopyDatabaseModalState {
  fromEnvId: string,
  toEnvId: string,
  fromDatabaseName: string,
  fromDatabaseAssocId: number,
  toDatabaseName: string
  toDatabaseAssocId: number,
  isProdCopy: boolean,
  isCrossPollination: boolean,
}

export interface ICopyDatabaseModalProps {
  environment: ProjectEnvironment,
  environments: ProjectEnvironment[],
  project: Project,
  handleModalClose: ()=>void,
  openModal: boolean
}

export class CopyDatabaseModal extends React.Component<ICopyDatabaseModalProps, ICopyDatabaseModalState> {
  private readonly prodWarning: string

  constructor(props: ICopyDatabaseModalProps) {
    super(props)

    const toEnv: ProjectEnvironment = props.project.projectEnvironments.find((env: ProjectEnvironment) => env.order === props.environment.order - 1)
    this.prodWarning = MessageService.get('project.action.database.copy.prod.warning')

    this.state = {
      fromEnvId: props.environment.environmentId,
      toEnvId: toEnv ? toEnv.environmentId : null,
      fromDatabaseName: props.environment.projectEnvironmentDatabases.databases[0].name,
      fromDatabaseAssocId: props.environment.projectEnvironmentDatabases.databases[0].associationId,
      toDatabaseName: toEnv ? toEnv.projectEnvironmentDatabases.databases[0].name : null,
      toDatabaseAssocId: toEnv ? toEnv.projectEnvironmentDatabases.databases[0].associationId : null,
      isProdCopy: false,
      isCrossPollination: false,
    }
  }

  handleChange = (event: any): void => {
    if (event.target.name === 'toDatabaseName') {
      const {toEnvId} = this.state
      const projectEnv: ProjectEnvironment = this.props.project.projectEnvironments.find((env: ProjectEnvironment) => env.environmentId === toEnvId)
      const toDatabaseAssocId: number = projectEnv.projectEnvironmentDatabases.databases.find((db: Database) => db.name === event.target.value).associationId
      this.setState({toDatabaseAssocId})
    } else if (event.target.name === 'fromDatabaseName') {
      const {fromEnvId} = this.state
      const projectEnv: ProjectEnvironment = this.props.project.projectEnvironments.find((env: ProjectEnvironment) => env.environmentId === fromEnvId)
      const fromDatabaseAssocId: number = projectEnv.projectEnvironmentDatabases.databases.find((db: Database) => db.name === event.target.value).associationId
      this.setState({fromDatabaseAssocId})
    }

    this.setState({[event.target.name]: event.target.value} as any)
  }

  handleFromEnvChange = (event: any): void => {
    const newFromEnvId: string = event.target.value
    if (event.target.value === this.state.toEnvId) {
      this.setState({fromEnvId: newFromEnvId, fromDatabaseName: null, fromDatabaseAssocId: null, toEnvId: null, toDatabaseName: null, toDatabaseAssocId: null})
    } else {
      this.setState({fromEnvId: newFromEnvId, fromDatabaseName: null})
    }
  }

  handleToEnvChange = (event: any): void => {
    this.setState({toEnvId: event.target.value, toDatabaseName: null, toDatabaseAssocId: null})
  }

  handleConfirmModalClose = ():void => this.setState({isProdCopy: false, isCrossPollination: false})

  handleCopyClick = async (): Promise<void> => {
    const {fromEnvId, fromDatabaseAssocId, toDatabaseAssocId} = this.state
    const fromEnv: DeploymentEnvironment = DeploymentEnvironmentHelper.getDeploymentEnvironmentById(fromEnvId)
    let initCopyRequest: boolean = true

    if (fromEnv.isProd) {
      this.setState({isProdCopy: true})
      initCopyRequest = false
    }

    if (fromDatabaseAssocId !== toDatabaseAssocId) {
      this.setState({isCrossPollination: true})
      initCopyRequest = false
    }

    if (initCopyRequest) {
      await this.handleCopyRequest()
    }
  }

  handleCopyRequest = async () : Promise<void> => {
    const {project, handleModalClose} = this.props
    const {fromEnvId, toEnvId, fromDatabaseName, toDatabaseName} = this.state

    this.handleConfirmModalClose()

    const pipeline: Pipeline = new Pipeline({
      projectId: project.id,
      actions: [
        new Action({
          actionConfiguration: new DatabaseCopyActionConfiguration({
            sourceEnvironment: fromEnvId,
            sourceDeploymentTargetGroupId: project.getDatabaseTargetGroupIdByEnv(fromEnvId),
            targetEnvironment: toEnvId,
            targetDeploymentTargetGroupId: project.getDatabaseTargetGroupIdByEnv(toEnvId),
            sourceDatabase: fromDatabaseName,
            targetDatabase: toDatabaseName
          })
        })
      ]
    })

    try {
      await PipelineService.executePipeline(pipeline)
      SnackbarService.show(SNACKBAR_TYPES.SUCCESS, 'Successfully started database copy.')
    } catch (error) {
      SnackbarService.show(SNACKBAR_TYPES.FAILURE, error || 'Failed to start database copy!')
    }
    handleModalClose()
  }

  render(): React.ReactNode {
    const {project, environment, environments, openModal, handleModalClose} = this.props
    const {fromDatabaseName, fromEnvId, toEnvId, toDatabaseName, isProdCopy, isCrossPollination} = this.state
    let crossPollinationWarning: string

    if (environment !== null) {
      if (isCrossPollination) {
        crossPollinationWarning = MessageService.get('project.action.database.copy.crossPollination.warning', {
          fromDatabaseName,
          toDatabaseName,
          fromEnvTitle: DeploymentEnvironmentHelper.getEnvironmentTitleById(fromEnvId),
          toEnvTitle: DeploymentEnvironmentHelper.getEnvironmentTitleById(toEnvId)
        })

        if (isProdCopy) {
          crossPollinationWarning += '\n' + this.prodWarning
        }
      }

      const fromDatabaseFormHelperText: string = fromEnvId ? (fromDatabaseName ? null : 'Select a database') : 'No environment selected'
      const toDatabaseFormHelperText: string = toEnvId ? (toDatabaseName ? null : 'Select a database') : 'No environment selected'
      return (
        <Grid>
          <GenericModal open={openModal} onClose={handleModalClose}>
            <h2>Copy Database</h2>
            <Grid container>
              <Grid item xs={12}>
                <h4>From</h4>
                <Select className='select' value={fromEnvId} onChange={this.handleFromEnvChange} name='fromEnvId'>
                  {environments
                    .filter((env: ProjectEnvironment) => project.canCopyDatabaseFrom(env.environmentId))
                    .map((env: ProjectEnvironment) => <MenuItem key={env.environmentId} value={env.environmentId}>{DeploymentEnvironmentHelper.getEnvironmentTitleById(env.environmentId)}</MenuItem>)
                  }
                </Select>
                <br/>
                <Select style={{marginTop: '0.5rem'}} className='select' value={fromDatabaseName} onChange={this.handleChange} name='fromDatabaseName'>
                  {fromEnvId &&
                    project.projectEnvironments.find((env: ProjectEnvironment) => env.environmentId === fromEnvId)
                    .projectEnvironmentDatabases.databases.map((db: Database) => <MenuItem key={db.name} value={db.name}>{db.name}</MenuItem>)}
                </Select>
                <FormHelperText>{fromDatabaseFormHelperText}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <h4>Into</h4>
                <Select className='select' value={toEnvId} onChange={this.handleToEnvChange} displayEmpty name='toEnvId'>
                  {environments
                    .filter((env: ProjectEnvironment) => env.environmentId !== fromEnvId && project.canCopyDatabaseTo(env.environmentId))
                    .map((env: ProjectEnvironment) => <MenuItem key={env.environmentId} value={env.environmentId}>{DeploymentEnvironmentHelper.getEnvironmentTitleById(env.environmentId)}</MenuItem>)
                  }
                </Select>
                <br/>
                <Select style={{marginTop: '0.5rem'}} className='select' value={toDatabaseName} onChange={this.handleChange} name='toDatabaseName' disabled={!toEnvId}>
                  {toEnvId &&
                    project.projectEnvironments.find((env: ProjectEnvironment) => env.environmentId === toEnvId)
                    .projectEnvironmentDatabases.databases.map((db: Database) => <MenuItem key={db.name} value={db.name}>{db.name}</MenuItem>)}
                </Select>
                <FormHelperText>{toDatabaseFormHelperText}</FormHelperText>
              </Grid>
              <Grid item xs={12} className='modal-actions'>
                <Grid container spacing={8} justify='flex-end'>
                  <Grid item>
                    <Button onClick={handleModalClose} variant='contained' color='secondary' disableElevation>Cancel</Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={this.handleCopyClick} variant='contained' color='primary' disabled={!fromDatabaseName || !toDatabaseName}>Copy</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </GenericModal>

          {!isCrossPollination && isProdCopy && <ConfirmDialog
              title={'Confirm Production Copy'}
              open={isProdCopy}
              message={this.prodWarning}
              onConfirm={this.handleCopyRequest}
              onCancel={this.handleConfirmModalClose}
          />}

          {isCrossPollination && !isProdCopy && <ConfirmDialog
            title={'Confirm Cross Database Copy'}
            open={isCrossPollination}
            message={crossPollinationWarning}
            onConfirm={this.handleCopyRequest}
            onCancel={this.handleConfirmModalClose}
          />}

          {isCrossPollination && isProdCopy && <ConfirmDialog
            title={'Confirm Cross Production Database Copy'}
            open={isProdCopy}
            message={crossPollinationWarning}
            onConfirm={this.handleCopyRequest}
            onCancel={this.handleConfirmModalClose}
          />}
        </Grid>
      )
    } else {
      return null
    }
  }
}

export default CopyDatabaseModal

