import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { UIView } from '@uirouter/react'
import { loadProjectById } from '../../../store/actions/selectedProjectActions'
import ActionTypes from '../../../store/actions'
import { PageLayout } from 'src/components/layout/shared'

export interface ProjectDetailsSceneProps {
  projectId: string
}

export const ProjectDetailsContainer: React.FC<ProjectDetailsSceneProps> = ({ projectId }) => {
  const dispatch = useDispatch()
  const resetData: () => void = () => {
    dispatch({ type: ActionTypes.SELECTED_PROJECT_RESET_DEFAULT })
  }

  useEffect(() => {
    dispatch(loadProjectById(projectId))
    return resetData
  }, [projectId])

  return (
    <PageLayout>
      <UIView />
    </PageLayout>
  )
}
